import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import {
  ApiProfilesService,
  ApiSchoolsService,
  EUserTags,
  IFilterClasses,
  Stands,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  FiltersPromotestCoursesService,
  IDateFilterFormat,
  IPriceFilterFormat,
  PricesFilterTypes,
} from './filters-courses/filters-promotest-courses.service';
import { MenuService } from '../menus/menu/menu.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { SearchClasses } from './all-event-promotest.interfaces';

@Component({
  selector: 'prf-all-events-promotest',
  templateUrl: './all-events-promotest.component.html',
  styleUrls: ['./all-events-promotest.component.scss'],
})
export class AllEventsPromotestComponent extends UnsubscribeComponent implements OnInit {
  userRole: string;

  resultsSSid: string;
  resultsRoutingUrl: string;
  recommendedTalents: any[] = [];

  testRecommends: any[] = [];
  public allPartnersCourses: Array<any> = [];
  public allPartnersCoursesView: Array<any> = [];

  dataLoaded: boolean = false;
  isVGuser: boolean = false;

  filters: IFilterClasses = {};

  isMobile: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private overlayService: OverlayBusyService,
    private apiSchoolsService: ApiSchoolsService,
    private filtersService: FiltersPromotestCoursesService,
    private apiProfilesService: ApiProfilesService,
    private webStorageService: WebStorageService,
    private menuService: MenuService,
  ) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.isVGuser = this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString();
    this.menuService.isMobileScreen$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.overlayService.show();
    this.checkPromotestResults();
    this.filtersService
      .getCoursesFilters()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(filters => {
          this.filters = filters;
          //check and reject null/undefined values
          const filtersVerified: IFilterClasses = {};
          Object.keys(filters).forEach(key => {
            filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
          });
          return this.processLoadCourses(filtersVerified);
        }),
      )
      .subscribe(_ => {
        this.dataLoaded = true;
        this.overlayService.hide();
      });
  }

  processLoadCourses(filtersVerified): Observable<any> {
    return this.loadCourses(filtersVerified).pipe(
      takeUntil(this.unsubscribe),
      mergeMap((courses: any) => {
        //по умолчанию сортировка по возрастанию цены
        this.allPartnersCourses = this.createRecommendedCoursesArray(courses, this.recommendedTalents).sort((a, b) =>
          this.calculateRealPrice(a) > this.calculateRealPrice(b) ? 1 : -1,
        );

        return this.filtersService.getPriceFilter().pipe(
          takeUntil(this.unsubscribe),
          mergeMap((priceFilter: IPriceFilterFormat) => {
            if (priceFilter) {
              switch (priceFilter.value) {
                case PricesFilterTypes.BY_ASCENDING:
                  this.allPartnersCourses = this.allPartnersCourses.sort((a, b) =>
                    this.calculateRealPrice(a) > this.calculateRealPrice(b) ? 1 : -1,
                  );
                  break;
                case PricesFilterTypes.BY_DESCENDING:
                  this.allPartnersCourses = this.allPartnersCourses.sort((a, b) =>
                    this.calculateRealPrice(a) < this.calculateRealPrice(b) ? 1 : -1,
                  );
                  break;
              }
            }
            this.allPartnersCoursesView = this.allPartnersCourses;
            return this.filtersService.getDateFilter().pipe(
              takeUntil(this.unsubscribe),
              tap((dateFilter: IDateFilterFormat) => {
                if (dateFilter) {
                  this.allPartnersCoursesView = this.allPartnersCourses.filter(course => {
                    if (course.startDate) {
                      const courseStartDate = new Date(course.startDate);
                      return courseStartDate.getMonth() != dateFilter.value - 1 ? false : true;
                    }
                    return true;
                  });
                } else {
                  this.allPartnersCoursesView = this.allPartnersCourses;
                }
              }),
            );
          }),
        );
      }),
    );
  }

  loadCourses(filters: IFilterClasses): Observable<SearchClasses> {
    Object.assign(filters, {
      stand: Stands.Talent,
      regionId: this.webStorageService.get(StorageKeys.RegionId),
      municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
    });

    filters.stand = Stands.Talent;
    return this.apiSchoolsService.getFilteredClasses(filters).pipe(
      takeUntil(this.unsubscribe),
      map(response => response.classes),
    );
  }

  createRecommendedCoursesArray(coursesByFilters: any[], talents?: any[]): any[] {
    let coursesArray = [];
    let coursesThreeCoincidence = [];
    let coursesTwoCoincidence = [];
    let coursesOneCoincidence = [];

    if (talents && talents.length) {
      //сравниваем таланты по name, т.к. id не совпадает; вопрос, почему??
      //courses with 3 talents coincidence
      coursesThreeCoincidence = coursesByFilters.filter(course => {
        return talents.every(talent => course.talents.includes(talent.name));
      });
      coursesArray = coursesArray.concat(coursesThreeCoincidence);
      //courses with 2 talents coincidence
      coursesTwoCoincidence = coursesByFilters.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 2) {
          return true;
        } else {
          return false;
        }
      });
      coursesArray = coursesArray.concat(coursesTwoCoincidence);
      //courses with 1 talents coincidence
      coursesOneCoincidence = coursesByFilters.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 1) {
          return true;
        } else {
          return false;
        }
      });
      coursesArray = coursesArray.concat(coursesOneCoincidence);
      return coursesArray;
    } else {
      return coursesByFilters;
    }
  }

  navigateNewTest() {
    return this.router.navigate(['/parent/mosrupromo']);
  }

  calculateRealPrice(course: any) {
    const discount = +course.unitDiscount;
    const price = +course.unitPrice;
    if (course.isPercent) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }

  returnToResults() {
    if (this.isVGuser) {
      return this.resultsSSid
        ? this.router.navigate(['/vorobievi-gori/promotest-results/' + this.resultsSSid])
        : this.resultsRoutingUrl
        ? this.router.navigate([this.resultsRoutingUrl])
        : this.router.navigate(['/vorobievi-gori/mosrupromo']);
    } else {
      return this.resultsSSid
        ? this.router.navigate(['/promotest-results/' + this.resultsSSid])
        : this.resultsRoutingUrl
        ? this.router.navigate([this.resultsRoutingUrl])
        : this.router.navigate(['/parent/mosrupromo']);
    }
  }

  checkPromotestResults() {
    this.apiProfilesService
      .getSharedResults(this.webStorageService.get(StorageKeys.UserId))
      .pipe(
        takeUntil(this.unsubscribe),
        tap(sharedResults => {
          let promotestResults: any[] = [];
          if (sharedResults.length > 0) {
            promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);
            this.resultsRoutingUrl = promotestResults.length > 0 ? '/promotest-results' : '/parent/mosrupromo';
          }
        }),
      )
      .subscribe();
  }

  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }
}
