import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-admin-content-loader',
  templateUrl: './admin-content-loader.component.html',
  styleUrls: ['./admin-content-loader.component.scss'],
})
export class AdminContentLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
