import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { ITalentsFilter } from 'app/shared/interfaces/ifilterclasses.interface';
import { IFilterInternships, StorageKeys, WebStorageService } from '@profilum-library';

@Injectable()
export class InternshipsFiltersService {
  private internshipsFilters = new BehaviorSubject<IFilterInternships>({});
  private talentsFilters = new BehaviorSubject<ITalentsFilter>({});
  public schoolID: string = '';

  constructor(private webStorageService: WebStorageService) {}

  getInternshipsFilters(): Observable<IFilterInternships> {
    return this.internshipsFilters.asObservable();
  }

  setInternshipsFilter(filters: any, schoolID?: any) {
    if (schoolID) {
      this.schoolID = schoolID;
    }
    this.internshipsFilters.next(filters);
  }

  getTalentsFilters(): Observable<ITalentsFilter> {
    return this.talentsFilters.asObservable();
  }

  setTalentsFilter(filters: any) {
    this.talentsFilters.next(filters);
  }

  public setRegionMunicipalityFilters(filters: IFilterInternships) {
    const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
    const municipalityId: string = this.webStorageService.get(StorageKeys.MunicipalityId);
    const userRole: string = this.webStorageService.get(StorageKeys.UserRole);
    if (userRole === 'admin') {
      const adminLevel: string = this.webStorageService.get(StorageKeys.AdminLevel);
      switch (adminLevel) {
        case AdminAccessLevel.MUNICIPALITY:
          {
            filters.regionId = regionId;
            filters.municipalityId = municipalityId;
          }
          break;
        case AdminAccessLevel.REGION:
          {
            filters.regionId = regionId;
          }
          break;
      }
    } else {
      filters.regionId = regionId;
      filters.municipalityId = municipalityId;
    }
    return filters;
  }

  getTitleByNumber(n, titles: string[]) {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
  }
}
