import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Gender } from 'app/shared/enums/gender.enums';
import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import {
  ApiUsersService,
  B2gSaasService,
  IAddUserRequest,
  IAddUserResponse,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

interface IUser {
  firstName: string;
  lastName: string;
  middleName: string;
  registrationCode: string;
  schoolClassId: number | null;
  schoolId: number | null;
  schoolClassNumber: number | undefined;
  schoolClassLetter: string | undefined;
  regionId: string | undefined;
  municipalityId: string | undefined;
  gender: Gender;
  role: string;
}

@Component({
  selector: 'prf-teacher-add-students',
  templateUrl: './teacher-add-students.component.html',
  styleUrls: ['./teacher-add-students.component.scss'],
})
export class TeacherAddStudentsComponent extends UnsubscribeComponent {
  @Input() teacherClass: any;
  @ViewChild('header') header: ElementRef;
  public pupilsForm: UntypedFormGroup;
  public users: any = [];
  public submitted: boolean;
  public newPupils: any = [];
  public errors: number[] = [];
  public isFixed: boolean = false;
  public saving: boolean = false;
  private _pupilsList: any;

  @Input()
  set pupilsList(val: any) {
    this._pupilsList = val;

    let array = [];
    if (this.pupilsList.length > 0) {
      array = this.pupilsList.map(user => this.createPupil(user));
    }

    this.pupilsForm = this.formBuilder.group({
      users: this.formBuilder.array(array),
    });
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: UntypedFormBuilder,
    private teacherService: TeacherPanelService,
    private apiUsersService: ApiUsersService,
    private b2gSaasService: B2gSaasService,
    private webStorageService: WebStorageService,
  ) {
    super();
  }

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    if (this.header) {
      const sibling = this.header.nativeElement.nextSibling;

      this.header.nativeElement.style.width = sibling.offsetWidth + 'px';

      const head = this.document.getElementsByTagName('prf-page-header')[0];

      if (!this.isFixed) {
        if (head.getBoundingClientRect().height <= window.pageYOffset) {
          this.isFixed = true;
          this.header.nativeElement.style.width = sibling.offsetWidth + 'px';
          sibling.style.marginTop = this.header.nativeElement.offsetHeight + 20 + 'px';
        }
      } else {
        if (head.getBoundingClientRect().height + 5 > window.pageYOffset) {
          this.isFixed = false;
          sibling.style.marginTop = 0;
        }
      }
    }
  }

  get pupilsList(): any {
    return this._pupilsList;
  }

  get formDataArray(): AbstractControl {
    return <UntypedFormArray>this.pupilsForm.get('users');
  }

  public createPupil(user: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: new UntypedFormControl(user.firstName, [Validators.required]),
      lastName: new UntypedFormControl(user.lastName, [Validators.required]),
      middleName: new UntypedFormControl(user.middleName),
      registrationCode: user.registrationCode,
      isActivated: user.isActivated,
      gender: user.gender === Gender.Female ? Gender.Female : Gender.Male,
      schoolClassId: user.schoolClassId,
      schoolId: user.schoolClassId,
      schoolClassNumber: this.teacherClass?.number,
      schoolClassLetter: this.teacherClass?.letter,
      regionId: this.webStorageService.get(StorageKeys.RegionId),
      municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
    });
  }

  public addPupil(): void {
    const user = this.newUser();

    this.newPupils.push(user);
    this.users = this.pupilsForm.get('users') as UntypedFormArray;
    this.users.push(this.createPupil(user));
    this.submitted = false;
  }

  public submit(): void {
    this.submitted = true;

    if (this.pupilsForm.valid && !this.saving) {
      this.saving = true;

      // Обновление только измененных учеников
      if (this.pupilsForm.get('users').touched) {
        for (let i = 0; i < this.pupilsList.length; i++) {
          if (
            this.pupilsList[i].firstName !== this.pupilsForm.value.users[i].firstName ||
            this.pupilsList[i].lastName !== this.pupilsForm.value.users[i].lastName ||
            this.pupilsList[i].middleName !== this.pupilsForm.value.users[i].middleName ||
            this.pupilsList[i].gender !== this.pupilsForm.value.users[i].gender
          ) {
            this.pupilsList[i].firstName = this.pupilsForm.value.users[i].firstName;
            this.pupilsList[i].lastName = this.pupilsForm.value.users[i].lastName;
            this.pupilsList[i].middleName = this.pupilsForm.value.users[i].middleName;
            this.pupilsList[i].gender = this.pupilsForm.value.users[i].gender;
            this.b2gSaasService
              .updateUserInfo(this.pupilsList[i])
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(resp => {
                if (!resp) {
                  this.errors.push(i);
                }
              });
          }
        }
      }

      let currentObervable$: Observable<any>;

      // Создание новых учеников
      if (this.pupilsForm.value.users.length > this.pupilsList.length) {
        const diff = this.pupilsList.length - this.pupilsForm.value.users.length;
        const dataForNewPupil = this.pupilsForm.value.users.slice(diff);

        // todo: check it!
        currentObervable$ = this.createNewPupils(dataForNewPupil).pipe(
          tap(_newErrors => {
            this.errors.concat(_newErrors);
          }),
        );
      } else {
        currentObervable$ = of(null);
      }

      currentObervable$.pipe(takeUntil(this.unsubscribe)).subscribe(r => {
        this.saving = false;

        if (this.errors.length == 0) {
          this.hideEditPupils();
          this.teacherService.closePupilPage();
          this.teacherService.pupilsEdited.next({ pupilsEdited: true });
        }
      });
    } else {
      return;
    }
  }

  public newUser(): IUser {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
      registrationCode: '',
      schoolClassId: this.teacherClass && this.teacherClass.id ? this.teacherClass.id : null,
      schoolId: this.teacherClass && this.teacherClass.schoolId ? this.teacherClass.schoolId : null,
      schoolClassNumber: this.teacherClass?.number,
      schoolClassLetter: this.teacherClass?.letter,
      regionId: this.webStorageService.get(StorageKeys.RegionId),
      municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
      gender: Gender.Male,
      role: 'pupil',
    };
  }

  public hideEditPupils(): void {
    if (!this.saving) {
      this.teacherService.closeAddPupils();
    }
  }

  public isMale(gender: string): boolean {
    return gender === Gender.Male;
  }

  public setMale(pupil): void {
    pupil.value.gender = Gender.Male;
  }

  public setFemale(pupil): void {
    pupil.value.gender = Gender.Female;
  }

  public createNewPupils(pupils: any): Observable<number[]> {
    const newPupilsArray$: Observable<IAddUserResponse>[] = pupils.map((el, index) => {
      const pupil: IAddUserRequest = {
        firstName: pupils[index].firstName,
        lastName: pupils[index].lastName,
        middleName: pupils[index].middleName,
        gender: pupils[index].gender,
        role: pupils[index].role,
        schoolClassIds: pupils[index]?.schoolClassId ? [pupils[index].schoolClassId] : [],
        schoolId: pupils[index].schoolClassId,
        schoolClassNumber: pupils[index].schoolClassNumber,
        schoolClassLetter: pupils[index].schoolClassLetter,
        regionId: pupils[index].regionId,
        municipalityId: pupils[index].municipalityId,
      };

      return this.b2gSaasService.addUser(pupil);
    });

    return observableForkJoin(newPupilsArray$).pipe(
      switchMap((newPupilsArray: Array<IAddUserResponse>) => {
        const newErrors: number[] = [];

        newPupilsArray.forEach((newPupil: IAddUserResponse, index: number) => {
          if (!newPupil) {
            newErrors.push(index);
          }
        });

        return of(newErrors);
      }),
    );
  }

  public removePupil(index: number): void {
    const pupil = this.pupilsList[index];
    const control = <UntypedFormArray>this.pupilsForm.controls['users'];

    // Удаление control для данного ученика
    control.removeAt(index);

    // не зарегистрирован
    if (pupil && !pupil.isActivated) {
      this.apiUsersService
        .removeUnregisteredPupilTeachers(pupil.userId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.pupilsList.splice(index, 1);
          }
        });
    } else {
      this.apiUsersService
        .removePupilTeachers(pupil?.userId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.pupilsList.splice(index, 1);
          }
        });
    }
  }

  public getImageUrl(user: any): string {
    return user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }

  public trackByFn(index, item): number {
    return item.value.registrationCode;
  }
}
