import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalHandlerService {
  private $isShowSideModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private $isShowCenterModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private $confirmButton: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private $centerModalConfig: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public sideModalTemplate: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject<TemplateRef<any>>(undefined);

  public toggleSideModal(isOpen: boolean): void {
    this.$isShowSideModal.next(isOpen);
  }

  public toggleCenterModal(isOpen): void {
    this.$isShowCenterModal.next(isOpen);
  }

  public getShowSideModal(): BehaviorSubject<boolean> {
    return this.$isShowSideModal;
  }

  public getShowCenterModal(): BehaviorSubject<boolean> {
    return this.$isShowCenterModal;
  }

  public setCenterModalConfig(config: any): void {
    this.$centerModalConfig.next(config);
  }

  public getCenterModalConfig(): BehaviorSubject<any> {
    return this.$centerModalConfig;
  }

  public setConfirmButton(confirmation: string): void {
    this.$confirmButton.next(confirmation);
  }

  public getConfirmButton(): BehaviorSubject<any> {
    return this.$confirmButton;
  }

  public setStaticBody(): void {
    document.getElementsByTagName('body')[0].classList.add('fixed-body');
  }

  public removeStaticBody(): void {
    document.getElementsByTagName('body')[0].classList.remove('fixed-body');
  }
}
