import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ApiUsersService, YandexMetricsService, YmItems } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'prf-download-pupils-accesses',
  templateUrl: './download-pupils-accesses.component.html',
  styleUrls: ['./download-pupils-accesses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadPupilsAccessesComponent extends UnsubscribeComponent {
  @Input() schoolClassId: string;
  public loading: boolean = false;

  constructor(
    private apiUsersService: ApiUsersService,
    private changeDetectorRef: ChangeDetectorRef,
    private yandexMetricsService: YandexMetricsService,
  ) {
    super();
  }

  public downloadAccesses(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.apiUsersService
      .getDataUnregisteredTeachers(this.schoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          const file = new File([blob], 'Доступы_для_учеников', {
            type: response.headers.get('content-type'),
          });
          saveAs(file);
          this.yandexMetricsService.reachGoal(YmItems.T_Class_DownloadAccess);
          this.timer = setTimeout(() => {
            this.loading = false;
            this.changeDetectorRef.detectChanges();
          }, 500);
        },
        () => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  }
}
