<ng-container *ngIf="pupils && dataFetched">
  <ng-container *ngIf="messagePopUpShow">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="close" (click)="closeMessagePopUp()">
          <mat-icon svgIcon="close" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
        </div>
        <div class="popup-title">Отправка результатов родителям</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">
              <span> Вы можете отправить результаты диагностики с&nbsp;рекомендациями по&nbsp;профессиям и мероприятиям ДО </span>
            </div>
            <div class="popup-text _2">
              <span class="text-link">
                <a href="./assets/docs/For_parent_results.pdf" target="_blank"> Пример отправляемых результатов </a>
              </span>
            </div>
            <div class="popup-text _3">
              <span>
                Результаты будут отправлены родителям, уже зарегистрированным в системе. Если родители учеников еще не зарегистрированы -
                предложите им присоединиться и пройти диагностику о своем ребенке
              </span>
            </div>
            <a class="button w-button popup-button" (click)="sendMessagesAll()">Отправить</a>
          </div>
          <div class="content-pic">
            <div class="pic-container">
              <img src="./profilum-assets/images/control-panel/director/sendmessage-pic.png" alt="Results" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="class-head-column button-column shared-results-button">
    <a class="button w-button message-button" (click)="showMessagePopUp()"> Отправить результаты родителям </a>
  </div>

  <div>
    <!--    <div class="return-button">-->
    <!--      <a (click)="routingMain()" class="button white-button w-button">{{-->
    <!--        'BUTTONS.PREVIOUS' | translate-->
    <!--      }}</a>-->
    <!--    </div>-->
    <div [ngClass]="showEditPupilsComponent ? 'hide' : '' || showEditClassComponent ? 'hide' : ''">
      <div class="content-row">
        <div class="content-schooladmin">
          <!--          <div class="class-number">-->
          <!--            <h1>-->
          <!--              {{-->
          <!--                'SHARED.CLASS_WITH_LETTER'-->
          <!--                  | translate: {number: schoolClass.number, letter: schoolClass.letter}-->
          <!--              }}-->
          <!--            </h1>-->
          <!--          </div>-->
          <!--          <div class="teacher-container">-->
          <!--            <a-->
          <!--              class="class-photo w-inline-block"-->
          <!--              *ngIf="teacher"-->
          <!--              [ngStyle]="{-->
          <!--                'background-image': teacher.imagePath ? 'url(' + teacher.imagePath + ')' : ''-->
          <!--              }"-->
          <!--            >-->
          <!--            </a>-->
          <!--            <div class="teacher-container-item">-->
          <!--              <div class="teacher-container-lastName" *ngIf="teacher">-->
          <!--                <span>{{ teacher.lastName }} {{ teacher.firstName }} {{ teacher.middleName }}</span>-->
          <!--              </div>-->
          <!--              <small>Классный руководитель</small>-->
          <!--            </div>-->
          <!--            <div class="teacher-contacts">-->
          <!--              <a>{{ teacher?.phoneNumber }}</a>-->
          <!--              <a>{{ teacher?.email }}</a>-->
          <!--            </div>-->
          <!--            <div class="teacher-code-registration" *ngIf="teacher?.registrationCode">-->
          <!--              <a>-->
          <!--                <span>{{ 'SHARED.REGISTRATION_CODE' | translate }} — </span>-->
          <!--                <span class="code">{{ teacher.registrationCode }}</span>-->
          <!--              </a>-->
          <!--              <small class="activated" *ngIf="teacher.isActivated">-->
          <!--                {{ 'SHARED.REGISTER_COMPLETED' | translate }}-->
          <!--              </small>-->
          <!--              <small *ngIf="!teacher.isActivated">Не зарегистрирован</small>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="devider"></div>-->
          <div class="class-div" *ngIf="pupils.length > 0">
            <div class="students-edit">
              <h1>Ученики</h1>
            </div>
            <div class="class-header _2">
              <div class="class-row class-header-row-3">
                <div class="class-column _9"></div>
                <div class="class-column _4">
                  <div>{{ pupils.length }} учеников</div>
                </div>
                <div class="class-column _8">
                  <div>Профессия</div>
                </div>
                <div class="class-column _6">
                  <div>Тест прошел</div>
                  <div class="class-test-row">
                    <div class="class-test-column mobile-hidden">
                      <div>Дата последнего теста</div>
                    </div>
                    <div class="class-test-column">
                      <div>Родитель</div>
                    </div>
                    <div class="class-test-column">
                      <div>Ученик</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="schooladmin-list-block _2">
              <div class="students-list">
                <!--Pupil-->
                <div
                  class="class-list-item-2"
                  *ngFor="let pupil of pupils; let i = index"
                  [ngClass]="pupils.length === i ? 'no-underline' : ''"
                  [class.last-selected]="pupil.userId === pupilId"
                >
                  <div>
                    <div class="class-row-2">
                      <!--Number-->
                      <div class="class-column _9">
                        <div class="class-header-number _2">{{ i + 1 }}</div>
                      </div>

                      <!--Check registration-->
                      <!--if registrated-->
                      <div class="class-row-div" *ngIf="pupil.isActivated; else notRegistered">
                        <div class="class-column _7">
                          <div class="class-column _2 _10">
                            <!--Avatar-->
                            <div class="class-photo-column">
                              <a
                                (click)="navigateSelectedPupil(pupil)"
                                class="class-photo w-inline-block"
                                [ngStyle]="{
                                  'background-image': 'url(' + getImageUrl(pupil) + ')'
                                }"
                              ></a>
                              <!-- <a class="class-photo w-inline-block"></a> -->
                            </div>
                          </div>
                          <!--details-->
                          <div class="class-column _4">
                            <div class="student-name">
                              <a (click)="navigateSelectedPupil(pupil)" class="student-name-link">
                                {{ pupil.firstName }} {{ pupil.lastName }}
                              </a>
                            </div>
                            <!--<div class="student-online-status">Была только что???</div>-->
                          </div>
                        </div>
                        <!-- Professions -->
                        <div class="class-column _8">
                          <div class="prof-row">
                            <ng-container *ngIf="favoriteProfessions && pupil.favoriteProfessions.length > 0">
                              <div class="prof-icon" *ngFor="let prof of pupil.favoriteProfessions | slice : 0 : 2">
                                <img
                                  src="{{ getImagePath(prof) }}"
                                  class="student-prof-icon"
                                  (mouseenter)="showElement($event)"
                                  (mouseleave)="hideElement($event)"
                                />
                                <div class="info-popup stud-prof">
                                  <p>{{ prof?.name }}</p>
                                  <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-prof" />
                                </div>
                              </div>
                            </ng-container>

                            <!--<div class="prof-icon">-->
                            <!--<img src="./assets/images/scheme-icon.svg"-->
                            <!--class="student-prof-icon"-->
                            <!--(mouseenter)="showElement($event)"-->
                            <!--(mouseleave)="hideElement($event)"-->
                            <!--&gt;-->
                            <!--<div class="info-popup stud-prof">-->
                            <!--<p>Оператор каршеринга</p>-->
                            <!--<img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-prof">-->
                            <!--</div>-->
                            <!--</div>-->
                            <div class="prof-icon num" *ngIf="pupil.favoriteProfessions.length > 2">
                              {{ '+' + (pupil.favoriteProfessions.length - 2) }}
                            </div>
                          </div>
                        </div>
                        <!--Test-->
                        <div class="class-column _6">
                          <div class="class-test-row">
                            <!--last-test-date-->
                            <div class="class-test-column test mobile-hidden">
                              <a class="last-test-date">{{ pupil.lastTestDate | date : 'dd/MM/yyyy' }}</a>
                            </div>
                            <!--Parent-test-->
                            <div class="class-test-column test">
                              <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                            </div>
                            <!--Pupil-Test-->
                            <div class="class-test-column test" *ngIf="!isTestOld(pupil.lastTestDate)">
                              <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                            </div>
                            <div class="class-test-column test" *ngIf="isTestOld(pupil.lastTestDate)">
                              <div
                                class="test-check"
                                (mouseenter)="showElement($event)"
                                (mouseleave)="hideElement($event)"
                                [class.old]="pupil.lastSessionCompleted"
                              ></div>
                              <div class="info-popup test-date" [class.disabled]="!pupil.lastSessionCompleted || !isTestOld">
                                <p>Данные диагностики могли устареть.</p>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="class-test-row">

                              <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                              <div class="class-test-column test" *ngIf="pupil.lastParentSessionCompleted === true; else testNotCompleted">
                                <div class="test-check complete"></div>
                              </div>

                              <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                              <div class="class-test-column test" *ngIf="pupil.lastSessionCompleted === true; else testNotCompleted">
                                <div class="test-check complete"></div>
                              </div>
                            </div> -->
                        </div>

                        <ng-template #testNotCompleted>
                          <div class="test-check"></div>
                        </ng-template>

                        <!--if last class class-line no-underline-->
                        <div class="class-line" [ngClass]="pupils.length === i ? 'no-underline' : ''"></div>
                      </div>

                      <!--if NOT registrated-->
                      <ng-template #notRegistered>
                        <div class="class-row-div">
                          <div class="class-column _7">
                            <div class="class-column _2 _10">
                              <div class="class-photo-column">
                                <a class="class-photo no-photo w-inline-block"></a>
                              </div>
                            </div>
                            <div class="class-column _4">
                              <div class="student-name">
                                <a class="student-name-link disabled_link">{{ pupil.firstName }} {{ pupil.lastName }}</a>
                              </div>
                            </div>
                          </div>
                          <div class="class-column _10">
                            <span class="status__not-activated" (click)="showResetPasswordPopup(pupil)">Не вошел в систему</span>
                          </div>
                          <div class="class-line"></div>
                        </div>
                      </ng-template>

                      <!--ContexMenu-->
                      <!--<div class="class-dropdown w-dropdown">
                          <div class="tabs-dropdown-toggle w-dropdown-toggle" (click)="nav.classList.toggle('w&#45;&#45;open')">
                            <img src="./assets/images/dots-dd.svg" class="tabs-dropdown-icon">
                          </div>
                          <nav class="class-dropdown-list w-dropdown-list" #nav>
                            <div class="class-dd-bg">
                              <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 1</a>
                              <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 2</a>
                            </div>
                          </nav>
                        </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--if NOT HAVE pupils -->
          <div class="class-div" *ngIf="pupils.length == 0">
            <div class="empty-class">
              <div class="empty-class-item">
                <img src="./profilum-assets/images/control-panel/logo-empty.png" alt="" />
                <div class="info">В данном классе пока нет учеников</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<prf-reset-password-popup
  *ngIf="resetPasswordPopup"
  [pupil]="notEnteredPupil"
  [isDiagnosticClass]="isDiagnosticClass"
  (closeModal)="showResetPasswordPopup()"
></prf-reset-password-popup>
