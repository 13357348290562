import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import {
  ResultsObjecttypeChartAndBarsComponent,
} from './results-objecttype-chart-and-bars/results-objecttype-chart-and-bars.component';
import { ResultsAnswersBarsComponent } from './results-answers-bars/results-answers-bars.component';

@NgModule({
  declarations: [ResultsObjecttypeChartAndBarsComponent, ResultsAnswersBarsComponent],
  imports: [CommonModule, BaseChartDirective],
  providers: [provideCharts(withDefaultRegisterables())],
  exports: [ResultsObjecttypeChartAndBarsComponent, ResultsAnswersBarsComponent],
})
export class ResultsDataPresentationModule {}
