import { booleanAttribute, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

type TGGender = 'M' | 'F';

@Component({
  selector: 'prf-pupil-gender-toggle',
  templateUrl: './pupil-gender-toggle.component.html',
  styleUrls: ['./pupil-gender-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PupilGenderToggleComponent),
      multi: true,
    },
  ],
})
export class PupilGenderToggleComponent {
  @Input({transform: booleanAttribute}) genderLocked: boolean;

  private _currentGender: TGGender;
  private _genders: TGGender[] = ['M', 'F'];

  _gendersView = [
    { key: 'F', name: 'Женский' },
    { key: 'M', name: 'Мужской' },
  ];
  propagateChange = (gender: TGGender) => {};
  propagateTouch = (gender: TGGender) => {};

  writeValue(gender: TGGender): void {
    this.currentGender = gender;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public get currentGender(): TGGender {
    return this._currentGender;
  }

  public set currentGender(gender: TGGender) {
    this._currentGender = gender;
    this.propagateChange(gender);
    this.propagateTouch(gender);
  }

  public switchGender(gender: TGGender): void {
    this.currentGender = gender;
  }

  public toggleNext(): void {
    this.currentGender = this._genders[(this._genders.indexOf(this.currentGender) + 1) % 2];
  }
}
