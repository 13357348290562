import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import {
  ApiProfilesService,
  ApiUsersService,
  AppSettingsService,
  LocationEnum,
  StorageKeys,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';

@Component({
  selector: 'prf-children-add',
  templateUrl: './children-add.component.html',
  styleUrls: ['./children-add.component.scss'],
})
export class ChildrenAddComponent {
  @Input() userProfile: any = {};

  @Input()
  set open(val: boolean) {
    this.isShow = val;

    setTimeout(_ => {
      this.inp1.nativeElement.focus();
    }, 100);
  }

  @Output() closed = new EventEmitter<boolean>();
  @Output() binding = new EventEmitter<boolean>();
  @Output() showRegWindow = new EventEmitter<boolean>();

  @ViewChild('inp1') inp1: ElementRef;
  @ViewChild('inp2') inp2: ElementRef;
  @ViewChild('inp3') inp3: ElementRef;
  @ViewChild('inp4') inp4: ElementRef;
  @ViewChild('inp5') inp5: ElementRef;
  @ViewChild('inp6') inp6: ElementRef;

  public isShow: boolean = true;
  public code: string = '';
  public child: any = null;
  public codeLength: number = 6;
  public loading: boolean = false;
  public userId: string = '';
  public activeLocation: LocationEnum = LocationEnum.BASE;

  constructor(
    private renderer: Renderer2,
    private apiUsersService: ApiUsersService,
    private apiProfilesService: ApiProfilesService,
    private appSettings: AppSettingsService,
    private utilsService: UtilsService,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
  ) {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.activeLocation = this.appSettings.currentLocation;
  }

  public closeModal() {
    this.isShow = false;
    this.closed.emit(true);
  }

  public addChild() {
    if (this.child && this.child.userId) {
      this.apiProfilesService
        .bindChild(this.userId, this.child.userId)
        .pipe(take(1))
        .subscribe(_ => {
          this.yandexMetricsService.reachGoal(YmItems.ADD_PUPIL_BY_CODE);
          this.closeModal();
          this.bindingChild();
        });
    }
  }

  private bindingChild() {
    this.webStorageService.set(StorageKeys.SkipCaching, true);
    this.binding.emit(true);
  }

  public onKeyDown(e: any, inpCurrent: any, inpPrevious: any) {
    if (e.code === 'Backspace' && inpPrevious) {
      if (this.renderer.selectRootElement(inpCurrent).value === '') {
        this.renderer.selectRootElement(inpPrevious).focus();
      }
    }
  }

  // public onKeyUp(e: any) {
  //   this.prepareCode();
  // }

  // public onKeyPress(e: any, inpCurrent: any, inpNext: any) {
  //   if (e.inputType !== 'insertFromPaste') {
  //     setTimeout(() => {
  //       this.renderer.selectRootElement(inpCurrent).value = e.key;
  //     }, 1);
  //   }
  // }

  public onInsertData(e: any, inpCurrent: any, inpNext: any) {
    if (e.inputType === 'insertFromPaste') {
      const insertValue = e.target.value;

      if (insertValue && insertValue.length > 1) {
        for (let i = 0; i < insertValue.length; i++) {
          switch (i) {
            case 0:
              this.inp1.nativeElement.value = insertValue[i];
              this.inp1.nativeElement.focus();
              break;
            case 1:
              this.inp2.nativeElement.value = insertValue[i];
              this.inp2.nativeElement.focus();
              break;
            case 2:
              this.inp3.nativeElement.value = insertValue[i];
              this.inp3.nativeElement.focus();
              break;
            case 3:
              this.inp4.nativeElement.value = insertValue[i];
              this.inp4.nativeElement.focus();
              break;
            case 4:
              this.inp5.nativeElement.value = insertValue[i];
              this.inp5.nativeElement.focus();
              break;
            case 5:
              this.inp6.nativeElement.value = insertValue[i];
              this.inp6.nativeElement.focus();
              break;
          }
        }
      }
    }
    this.prepareCode();

    if (e.inputType !== 'deleteContentBackward' && inpNext) {
      this.renderer.selectRootElement(inpNext).focus();
    }
  }

  private prepareCode() {
    this.code =
      this.inp1.nativeElement.value +
      this.inp2.nativeElement.value +
      this.inp3.nativeElement.value +
      this.inp4.nativeElement.value +
      this.inp5.nativeElement.value +
      this.inp6.nativeElement.value;

    this.findChildren();
  }

  private findChildren() {
    if (this.code.length === this.codeLength) {
      this.loading = true;

      this.apiUsersService.getUserInfoByCodeParents(this.code).subscribe(response => {
        if (response.status === 'Success') {
          this.child = response;
        } else {
          if (response.comment.includes('not found')) {
            this.utilsService.openSnackBar('Код не найден', 'error');
          }

          this.child = null;
        }

        this.loading = false;
      });
    } else {
      this.child = null;
    }
  }

  public showRegPage() {
    this.showRegWindow.emit(true);
  }

  protected readonly LocationEnum = LocationEnum;
}
