import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiFavoritesService,
  ApiSchoolsService,
  CalculationService,
  EmptyGuid,
  FavoritesDataHandlerService,
  IFilterClasses,
  IUserInfo,
  Stands,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { COMPLEXITYS } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import {
  MetroColors,
} from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AGE_DATA, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { ClassesFormatTypes } from '../../../../enums/courses-types.enum';

@Component({
  selector: 'prf-promotest-course-details',
  templateUrl: './promotest-course-details.component.html',
  styleUrls: ['./promotest-course-details.component.scss'],
})
export class PromotestCourseDetailsComponent extends UnsubscribeComponent implements OnInit {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherCourses: any = [];
  similarCourses: any = [];
  favoritesCourses: any;
  courseEnrolls: any;
  userData: IUserInfo;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';

  // isEnrolled: boolean = false;
  dataFetched: boolean = false;
  // popUpConfirming: boolean = false;
  // addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  metroColors: MetroColors = new MetroColors();

  fromString = '';
  beforeString = '';
  yearsString = '';

  constructor(
    private meta: Meta,
    private calculationService: CalculationService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private apiFavoritesService: ApiFavoritesService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private apiSchoolsService: ApiSchoolsService,
    private router: Router,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = translations['SHARED.FROM'];
        this.beforeString = translations['SHARED.BEFORE'];
        this.yearsString = translations['COURCES.CATALOG.YEARS'];
      });
    if (this.userRole.includes('admin')) {
      this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    }
    this.userData = this.userDataHandlerService.getUserInfo();
    this.userRole = this.userData.role;
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.apiSchoolsService.getCourseByHrid(this.hrid).pipe(
      tap(data => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate([this.backButtonUrl()]);
        }
        this.course = data.class;
        const type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        const startTime = DateHelper.toDayJs(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }
      }),
    );
  }

  loadOtherCourses(): Observable<any> {
    if (this.course.institutionId && this.course.institutionId !== EmptyGuid) {
      const filter: IFilterClasses = {
        classesFormat: [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse],
        stand: Stands.Talent,
        from: 0,
        size: 4,
        municipalityId: this.userData.municipalityId,
      };
      if (this.isCourse) {
        filter.classesFormat.push(
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        );
      }
      const firstFilter = { ...filter };
      const secondFilter = { ...filter };
      firstFilter.institutionId = this.course.institutionId;
      secondFilter.courses = this.course.courses;
      secondFilter.size = 13;
      return forkJoin([
        this.apiSchoolsService.getElasticFilteredClasses(firstFilter),
        this.apiSchoolsService.getElasticFilteredClasses(secondFilter),
      ]).pipe(
        tap(([otherCourses, similarCourses]) => {
          this.otherCourses = otherCourses.filter(d => d.id !== this.course.id);
          this.similarCourses = similarCourses.filter(d => d.id !== this.course.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  loadFavorites(): Observable<any> {
    return of(() => {
      this.favoritesCourses = this.favoritesDataHandlerService.getFavoriteClasses();

      if (this.favoritesCourses) {
        const productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);

        this.isFavorite = productIds.length > 0;
      }
    });
  }

  goToCourse() {
    const url = 'www.mos.ru/pgu/ru/application/dogm/077060701/#step_1';
    return this.router.navigate([]).then(() => {
      window.open('https://' + url, '_blank');
    });
  }

  getAges() {
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return (
        this.fromString +
        ' ' +
        this.course.minAgeAudience +
        ' ' +
        this.beforeString +
        ' ' +
        this.course.maxAgeAudience +
        ' ' +
        this.yearsString
      );
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.fromString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.calculationService.calculateRealPrice(this.course);
  }

  setTitles() {
    this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.title = translations['COURCES.CATALOG.COURCES'];
        this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
        this.isCourse = true;
      });
  }

  backButtonUrl() {
    return '/vorobievi-gori/partners-courses-all';
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'teacher':
        return true;
      default:
        return false;
    }
  }

  checkCourseEdit(course): boolean {
    let isEditable: boolean = false;
    const isDataEquivalentToLocalStorage: boolean =
      course.regionId === this.webStorageService.get(StorageKeys.RegionId) &&
      course.municipalityId === this.webStorageService.get(StorageKeys.MunicipalityId) &&
      course.institutionId === this.webStorageService.get(StorageKeys.SchoolId);
    isEditable =
      this.userRole == 'admin' ||
      (this.userRole == 'schooladmin' && isDataEquivalentToLocalStorage) ||
      (this.userRole == 'director' && isDataEquivalentToLocalStorage);
    return isEditable;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }
}
