<div class="profession-dropdown-container">
  <prf-dropdown-component
    [isSorting]="true"
    [dropdownClasses]="SORTING_CONFIG['names'].isActive && 'highlighted'"
    [selectedOption]="SORTING_CONFIG['names'].activeOption"
    [dropdownItems]="SORTING_CONFIG['names'].options"
    (updateValue)="itemSelect($event)"
  ></prf-dropdown-component>

  <prf-dropdown-component
    [isSorting]="true"
    [dropdownClasses]="SORTING_CONFIG['relevance'].isActive && 'highlighted'"
    [selectedOption]="SORTING_CONFIG['relevance'].activeOption"
    [dropdownItems]="SORTING_CONFIG['relevance'].options"
    (updateValue)="itemSelect($event)"
  ></prf-dropdown-component>

  <prf-dropdown-component
    [isSorting]="true"
    [dropdownClasses]="SORTING_CONFIG['salaries'].isActive && 'highlighted'"
    [selectedOption]="SORTING_CONFIG['salaries'].activeOption"
    [dropdownItems]="SORTING_CONFIG['salaries'].options"
    (updateValue)="itemSelect($event)"
  ></prf-dropdown-component>
</div>
