<ng-template [ngIf]="isFirstSession">
  <div class="widget" [class.small]="!isFirstSession">
    <div class="widget-wrapper">
      <h3 class="widget-title">Прежде чем начать, добавьте учеников. Для этого отправьте приглашение их родителям</h3>
      <p class="widget-desc">
        Скопируйте приглашение и отправьте его в <br />
        «родительский» чат WhatsApp, «ВКонтакте», Viber, <br />
        Telegram или другим удобным способом.
      </p>
      <button class="button widget-button" (click)="copyPupilRegLink()">Скопировать приглашение для родителей</button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!isFirstSession && !isActive && !isMOStend">
  <div class="widget_small">
    <div class="widget-wrapper">
      <span class="widget-title"> Отправьте приглашение родителям, чтобы они добавили ваших учеников в систему. </span>
      <button class="button widget-button" (click)="copyPupilRegLink()">Скопировать приглашение для родителей</button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="course">
  <div *ngIf="showCourse; else activeLes" class="widget-course" [class.active]="isActive">
    <div class="widget-course-text">
      <h3 class="widget-course-title">{{ course.name }}</h3>
      <span class="widget-course-subtitle" *ngIf="!isActive">
        {{ sectionsCount }} разделов {{ lessonsCount }}
        {{ declOfNum(lessonsCount, ['занятие', 'занятия', 'занятий']) }}
      </span>
      <p class="widget-course-desc" [innerHTML]="course.description"></p>
    </div>

    <ng-template [ngIf]="isFirstSession && !isActive">
      <div class="widget-course-tooltip">
        <span class="icon-tooltip">i</span>
        <span>Материалы появятся после того, как вы скопируете приглашение для родителей</span>
      </div>
    </ng-template>

    <ng-template [ngIf]="!isFirstSession && isActive">
      <div class="button-wrapper">
        <button (click)="goToLesson()" class="button widget-button">Начать</button>
      </div>
    </ng-template>
  </div>

  <ng-template #activeLes>
    <div *ngIf="course.activeLesson.lesson" class="widget-course widget-course_green">
      <div class="widget-course-text">
        <h3 class="widget-course-title">
          Вы остановились на занятии {{ course.activeLesson.lesson.number }}<br />
          {{ '«' + course.activeLesson.lesson.name + '»' }}
        </h3>
        <p class="widget-course-desc" [innerHTML]="course.activeLesson.lesson.description"></p>
      </div>

      <div class="button-wrapper">
        <button (click)="goToLesson()" class="button widget-button">Перейти к занятию</button>
      </div>
    </div>
  </ng-template>
</ng-template>

<prf-class-courses-chapters
  *ngIf="course && !isFirstSession"
  [isActive]="isActive"
  [chaptersData]="course.parsedLessons"
  [goToLesson]="goToLessonSubject$"
></prf-class-courses-chapters>

<ng-template [ngIf]="popup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <div class="popup-share">
        <a (click)="closePopup()" class="close-lb w-inline-block">
          <img src="./profilum-assets/images/landing/close-lb.svg" alt="" />
        </a>
        <div class="popup-share-header">
          <span class="icon-done"></span>
          <h3 class="popup-share-title">Приглашение для {{ classInfo.className }} класса скопировано</h3>
        </div>
        <div class="popup-share-content">
          <p class="popup-share-text">
            Здравствуйте, {{ isUserFromSalesB2S ? '' : 'уважаемые' }} родители {{ classInfo.className }} класса!
          </p>
          <p class="popup-share-text">
            {{ isUserFromSalesB2S ? texts.SALES_B2S : texts.DEFAULT }}
          </p>
          <p class="popup-share-text">
            {{ classInfo.shortLink ? classInfo.shortLink : classInfo.parentRegistrationLink }}
          </p>
        </div>
        <div class="popup-share-buttons">
          <button class="button green-btn" type="button" (click)="closePopup()">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
