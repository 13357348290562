import { Component, OnInit } from '@angular/core';
import { ApiAdminsService, ApiProfessionsService, Company, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {
  companyId: string;
  company: Company;
  subscription: any;
  companyProfessionNames: string[] = [];
  professions: any;

  constructor(
    private apiAdminsService: ApiAdminsService,
    private apiProfessionService: ApiProfessionsService,
    private webStorageService: WebStorageService,
  ) {}

  ngOnInit() {
    this.companyId = this.webStorageService.get(StorageKeys.CompanyId);
    this.subscription = this.apiAdminsService.getCompany(this.companyId).subscribe(data => {
      if (data) {
        this.company = data.company;
        this.getProfessionsNames();
      }
    });
  }

  private async getProfessionsNames() {
    this.apiProfessionService.getProfessionsByIds(this.company.professions).subscribe(result => {
      if (result) {
        this.companyProfessionNames = result.map(x => x.name);
      }
    });
  }
}
