<div class="catalog-events-row" *ngIf="currentCourses">
  <ng-container *ngIf="searches?.length == 0">
    <ng-container *ngIf="currentCourses.length > 0; else emptyCourses">
      <div class="events-column" [class.short-view]="viewValue" *ngFor="let course of currentCourses">
        <prf-event-course-card [course]="course" [viewValue]="viewValue"></prf-event-course-card>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="searches?.length > 0">
    <div class="events-column" [class.short-view]="viewValue" *ngFor="let course of searches">
      <prf-event-course-card [course]="course" [viewValue]="viewValue"></prf-event-course-card>
    </div>
  </ng-container>
</div>

<ng-template #emptyCourses>
  <div class="event-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" *ngIf="isCourses" />
      <img src="./profilum-assets/images/menu/grey/Events.svg" width="80" class="no-profession-icon" *ngIf="!isCourses" />
    </div>
    <div class="event-empty-block-text">{{ 'SHARED.FOR_YOU_REQUEST_NOT_COURSES' | translate }}</div>
  </div>
</ng-template>
