import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isArray } from 'util';

@Component({
  selector: 'prf-dropdown-new',
  templateUrl: './dropdown-new.component.html',
  styleUrls: ['./dropdown-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownNewComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class DropdownNewComponent implements ControlValueAccessor {
  @Input()
  set options(data: { name: string; data: any }[]) {
    if (isArray(data)) {
      data = data.sort((a, b) => {
        const nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //сортируем строки по возрастанию
          return -1;
        if (nameA > nameB) return 1;
        return 0; // Никакой сортировки
      });
    }
    this._options = isArray(data) ? data : this._options;
    this.cd.markForCheck();
  }
  _options: { name: string; data: any }[] = [];
  _currentControl: any = null;
  control = new UntypedFormControl();
  filteredOptions: Observable<{ name: string; data: any }[]>;
  isFind: boolean = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef && !this.eRef?.nativeElement.contains(event.target)) {
      if (!this.control.value && this.isFind) {
        this.writeValue('');
      }
      this.isFind = false;
    }
  }

  isFocused(findInput) {
    return findInput === document.activeElement;
  }

  constructor(private eRef: ElementRef, private cd: ChangeDetectorRef, matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('arrow', sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/filters/arrow.svg'));
    matIconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/filters/close.svg'));
  }

  propagateChange = (control: any) => {};
  propagateTouch = (control: any) => {};
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  writeValue(value: any): void {
    const valueView = value && value.hasOwnProperty('name') ? value.name : value;

    this.control.setValue(valueView);
    this.filteredOptions = of(this._options);
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((formValue: string) => this._filter(this.control.value ? this.control.value : formValue)),
    );
    this.currentControl = value;
    this.cd.markForCheck();
  }

  get currentControl() {
    return this._currentControl;
  }
  set currentControl(control: any) {
    this._currentControl = control;
    this.propagateChange(control);
    this.propagateTouch(control);
  }

  private _filter(value: string): { name: string; data: any }[] {
    const filterValue = value ? value.toLowerCase() : value;

    const res: any = this._options.filter(option => option.name.toLowerCase().includes(filterValue));
    return res;
  }

  public toggleInput(event) {
    this.isFind = !this.isFind;
    event.stopPropagation();
  }

  public resetWithTimeout(timeout, callback) {
    this.control.reset();

    if (callback) {
      timer(timeout)
        .pipe(take(1))
        .subscribe(() => {
          callback();
          this.writeValue('');
        });
    }
  }
}
