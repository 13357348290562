import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtLoginRoutingModule } from './jwt-routing.module';
import { JwtLoginComponent } from './jwtlogin/jwt-login.component';

@NgModule({
  declarations: [JwtLoginComponent],
  imports: [CommonModule, JwtLoginRoutingModule],
  exports: [JwtLoginComponent],
})
export class JwtModule {}
