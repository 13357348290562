export const COURSES_TYPES = [
  { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple' },
  { value: 'Excursion', viewValue: 'Экскурсия', color: '' },
  { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple' },
  { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange' },
  { value: 'Action', viewValue: 'Акция', color: '' },
  { value: 'Festival', viewValue: 'Фестиваль', color: '' },
  { value: 'Meeting', viewValue: 'Встреча', color: 'yellow' },
  { value: 'Competition', viewValue: 'Конкурс', color: 'orange' },
  { value: 'Profproba', viewValue: 'Профпроба', color: 'blue' },
  { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green' },
];

export const COURSES_FORMAT_DATA = [
  { value: 'Online', viewValue: 'Онлайн' },
  { value: 'Offline', viewValue: 'Оффлайн' },
  { value: 'Mixed', viewValue: 'Смешанный' },
];
//TODO: переработать
export const COURSES_FORMAT_DATA_NEW = {
  isActive: false,
  defaultOption: { name: 'Формат', data: null },
  activeOption: { name: 'Формат', data: null },
  options: [
    { name: 'Онлайн', data: { value: 'Online' } },
    { name: 'Оффлайн', data: { value: 'Offline' } },
    { name: 'Смешанный', data: { value: 'Mixed' } },
  ],
};

export const COURSES_TYPES_DATA_NEW = {
  isActive: false,
  defaultOption: { name: 'Тип', data: { value: 'ShortCourse', color: 'purple' } },
  activeOption: { name: 'Тип', data: { value: 'ShortCourse', color: 'purple' } },
  options: [
    { name: 'Краткосрочный курс', data: { value: 'ShortCourse', color: 'purple' } },
    { name: 'Долгосрочный курс', data: { value: 'LongCourse', color: 'orange' } },
  ],
};

export const AGE_DATA = [
  {
    label: '0+',
    value: 'ZeroPlus',
  },
  {
    label: '4 - 6',
    value: 'FourSix',
  },
  {
    label: '7 - 10',
    value: 'SevenTen',
  },
  {
    label: '11 - 13',
    value: 'ElevenThirteen',
  },
  {
    label: '14 - 16',
    value: 'FourteenSixteen',
  },
  {
    label: '16+',
    value: 'SixteenPlus',
  },
];
export const COMPLEXITYS = [
  { value: 'Introductory', viewValue: 'Начальный' },
  { value: 'Base', viewValue: 'Базовый' },
  { value: 'InDepth', viewValue: 'Продвинутый' },
  { value: 'PreProfessional', viewValue: 'Предпрофессиональный' },
];

export const TEXT_MASK = {
  phoneMask: ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  newPhoneMask: ['+', '7', ' ', /[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
  dateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  dateMaskWithDots: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
};

export const ServerErrorMessageOld: string = '👎 Ошибка на сервере, попробуйте позже';
export const ServerErrorMessage: string = 'Что-то пошло не так, обновите страницу';
export const FormErrorMessage: string = '👎 Произошла ошибка, проверьте правильность данных';

export const phonePattern: string = '+# (###) ###-##-##';
