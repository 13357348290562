<div class="prf-open-registration-page">
  <div class="prf-open-registration">
    <ng-template [ngIf]="currentStep !== steps.Step0" [ngIfElse]="step0">
      <div *ngIf="stepsData.get(currentStep) as currentStepData" class="prf-open-registration-header">
        <div *ngIf="currentStep !== steps.Step4" class="prf-open-registration-header-back-button">
          <i (click)="toPreviousStep()"></i>
        </div>
        <span class="prf-open-registration-header-title">Шаг {{ currentStepData.orderNum }}/{{ stepsCount }}</span>
        <span class="prf-open-registration-header-step">{{ currentStepData.title }}</span>
      </div>
      <ng-container [ngSwitch]="currentStep">
        <prf-registering-parent
          *ngSwitchCase="steps.Step1"
          (nextStep)="currentStep = $event"
          [parentRegistrationData]="parentRegistrationData"
          class="prf-open-registration-body"
        ></prf-registering-parent>
        <prf-registering-child
          *ngSwitchCase="steps.Step2"
          (nextStep)="currentStep = $event"
          [parentRegistrationData]="parentRegistrationData"
          class="prf-open-registration-body"
        ></prf-registering-child>
        <prf-verifying-personal-data
          *ngSwitchCase="steps.Step3"
          (nextStep)="currentStep = $event"
          [parentRegistrationData]="parentRegistrationData"
          class="prf-open-registration-body"
        ></prf-verifying-personal-data>
        <prf-sharing-invitation
          *ngSwitchCase="steps.Step4"
          [parentRegistrationData]="parentRegistrationData"
          class="prf-open-registration-body"
        ></prf-sharing-invitation>
      </ng-container>
    </ng-template>
    <ng-template #step0>
      <prf-start-page
        (phoneChange)="setParentPhone($event)"
        (nextStep)="currentStep = $event"
        [parentRegistrationData]="parentRegistrationData"
        class="prf-open-registration-body"
      ></prf-start-page>
    </ng-template>
  </div>
  <prf-page-footer *ngIf="!isMobile"></prf-page-footer>
</div>
