<section class="adminDO">
  <prf-admin-menu [itemsHide]="['landing', 'users', 'professions', 'centers', 'cities', 'regions', 'municipalities']"></prf-admin-menu>

  <div class="content-section">
    <div class="container">
      <prf-page-header [title]="title"></prf-page-header>

      <ng-container>
        <router-outlet></router-outlet>
      </ng-container>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>
