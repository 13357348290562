<div class="slide-number">{{ slideNum }} {{ 'COMMON.OF' | translate }} {{ slidesNumber }}</div>
<div class="text-container">
  <h1 class="title">{{ data.title }}</h1>
  <p class="descr">{{ data.description }}</p>
</div>
<div
  *ngIf="!showSlideWithSubsections"
  class="agile-bubble-box-container"
  [ngClass]="{
    'slide-positive': data.sectionType == 'Positive',
    'slide-negative': data.sectionType == 'Negative'
  }"
>
  <mat-selection-list #elements [(ngModel)]="selectedAnswerNames" (ngModelChange)="onModelChangeElements($event)" name="elements">
    <mat-list-option
      class="grow"
      (click)="checked(element)"
      [class.checked]="element.active"
      *ngFor="let element of visibleAnswers"
      [value]="element.text"
      [selected]="element.sectionId === data.sectionId"
    >
      {{ element.text }}
    </mat-list-option>
  </mat-selection-list>
</div>

<div
  *ngIf="showSlideWithSubsections"
  class="subsections-container bubbles"
  [ngClass]="{
    'slide-positive': data.sectionType == 'Positive',
    'slide-negative': data.sectionType == 'Negative'
  }"
>
  <mat-selection-list name="sectionElements">
    <div *ngFor="let section of screenSections">
      <div class="leaf section circle checkboxes" (click)="selectSection(section)">
        {{ section.name }}
      </div>
      <mat-list-option
        class="grow leaf subsection circle checkboxes"
        (click)="checkedSubsection(element)"
        *ngFor="let element of section.fields"
        [value]="element.text"
        [ngClass]="{
          hidden: section.isOpened == false,
          selected: element.sectionId == data.sectionId
        }"
      >
        {{ element.text }}
      </mat-list-option>
    </div>
  </mat-selection-list>
</div>
