import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ApiPlayerService,
  AppSettingsService,
  EUserTags,
  IUserInfo,
  RamStorageService,
  StorageKeys,
  Storages,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { PageHeaderService } from '../../page-header/page-header.service';
import { MenuService } from './menu.service';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { UserRoles } from 'app/shared/enums/userroles.enum';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { MenuItems } from 'app/shared/enums/menu.enums';
import { TeacherPanelService } from '../../../../pages/control-panel/teacher/teacher-panel.service';
import { RootScopeService } from '../../../services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})

export class MenuComponent extends UnsubscribeComponent implements OnInit {
  @Input() root: string = '/';
  @Input() test: boolean;
  @Input() showLessonsMenuItem: boolean = true;
  private userInfo: IUserInfo;
  public selectedMenuItem: MenuItems | null = null;
  public menuItems = MenuItems;
  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';
  public currentUrl: string = '';
  public userRole: string = '';
  public isAnalytics: boolean = false;
  public showCourses: boolean = true;
  public showEvents: boolean = true;
  public dictionary: DictionaryType;
  public showInternships: boolean = true;
  public showMosruAbout: boolean = false;
  public url: string;
  public headerButtonsArray: { name: string; url: string }[] = [];
  public isMOStend: boolean = false;
  public isVGuser: boolean = false;
  public mosruUser: boolean = false;
  // Переменные временно скрывают тест на ценности и стажировки
  public isTestReady: boolean = false;
  public isInternshipsReady: boolean = false;
  private session: string;
  private userId: string = '';
  private sessionList: IHistory[];
  private regionId: string = '';
  private testInfo: any;
  private moscowRegionId: string = AppSettingsService.settings.moscowRegion
    ? AppSettingsService.settings.moscowRegion.moscowRegionId
    : null;
  private moscowMunicipalityId: string = AppSettingsService.settings.moscowRegion
    ? AppSettingsService.settings.moscowRegion.moscowMunicipalityid
    : null;
  private isMobile: boolean = false;

  constructor(
    private headerService: PageHeaderService,
    private menuService: MenuService,
    private apiPlayerService: ApiPlayerService,
    private teacherService: TeacherPanelService,
    private router: Router,
    private webStorageService: WebStorageService,
    private ramStorageService: RamStorageService,
    private userActionsService: UserActionsService,
    private rootScopeService: RootScopeService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.init();
  }

  get showHeaderButtons(): boolean {
    return this.isVGuser || this.mosruUser;
  }

  get isB2cPupil(): boolean {
    const b2cRegionId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.regionId : null;

    const b2cMunicipalityId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.municipalityId : null;

    return (
      this.userInfo.role === 'pupil' &&
      this.webStorageService.get(StorageKeys.RegionId) === b2cRegionId &&
      this.webStorageService.get(StorageKeys.MunicipalityId) === b2cMunicipalityId
    );
  }

  public ngOnInit(): void {
    this.setHeaderButtonsMenu();
    this.session = this.webStorageService.get(StorageKeys.TestSessionId);
    this.mosruUser = this.webStorageService.get(StorageKeys.Tag) === 'MosRu';
    this.isMobile = window.innerWidth <= 767;
    this.menuService.setMobileScreen(this.isMobile);

    this.detectUrlChange();
    if (this.root === '/') {
      this.root = this.userDataHandlerService.getUserInfo().role;
    }

    this.ramStorageService
      .get(StorageKeys.SelectedMenuItem, Storages.Local)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedMenuItem: MenuItems) => {
        this.selectedMenuItem = selectedMenuItem;
      });
  }

  public routingMotivationTest(): void {
    if (this.userRole === 'pupil') {
      this.apiPlayerService
        .getSessionsList(this.userId)
        .pipe(
          switchMap(r => {
            this.sessionList = r;
            if (this.sessionList) {
              return this.apiPlayerService.regionTestInfo(this.regionId).pipe(
                tap(testInfo => {
                  this.testInfo = testInfo;
                  let motivationTestSessions: any[] = [];

                  switch (this.userRole) {
                    case UserRoles.pupil:
                      motivationTestSessions = this.sessionList.filter(el => el.screeningTestId == TESTS_VARIANTS_IDS.motivationtest);
                      break;
                    default:
                      break;
                  }
                  motivationTestSessions = motivationTestSessions
                    .filter(session => session.completed)
                    .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
                  const lastSessionCompleted = motivationTestSessions[0];

                  if (lastSessionCompleted && lastSessionCompleted.sessionId) {
                    this.router.navigate(['/motivation-test-results/', lastSessionCompleted.sessionId]);
                  } else {
                    this.router.navigate(['/motivation-test']);
                  }
                }),
              );
            } else {
              return of(null);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  public routingValuesTest(): void {
    if (this.userRole === 'pupil') {
      this.apiPlayerService
        .getSessionsList(this.userId)
        .pipe(
          tap(r => {
            this.sessionList = r;
            if (this.sessionList) {
              let valuesTestSessions: any[] = [];

              switch (this.userRole) {
                case UserRoles.pupil:
                  valuesTestSessions = this.sessionList.filter(el => el.screeningTestId == TESTS_VARIANTS_IDS.valuesTest);
                  break;
                default:
                  break;
              }
              valuesTestSessions = valuesTestSessions
                .filter(session => session.completed)
                .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
              const lastSessionCompleted = valuesTestSessions[0];

              if (lastSessionCompleted && lastSessionCompleted.sessionId) {
                this.router.navigate(['/values-test-results/', lastSessionCompleted.sessionId]);
              } else {
                this.router.navigate(['/values-test']);
              }
            } else {
              return of(null);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  @HostListener('window:scroll', [])
  public onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    event.target.innerWidth;
    this.isMobile = event.target.innerWidth <= 767;
    this.menuService.setMobileScreen(this.isMobile);
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu(): void {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile(): void {
    this.headerService.closeProfile();
    this.teacherService.closePupilPage();
  }

  public selectMainTeacher(): void {
    this.userActionsService.log(YmItems.T_Lessons);
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/teacher']);
  }

  public selectMain(): void {
    this.hideProfile();
    this.hideMobileMenu();
    if (this.userRole === 'teacher') {
      this.userActionsService.log(YmItems.T_Class);
      this.router.navigate(['/teacher-class-results']);
      return;
    }
    this.router.navigate(['/' + this.root]);
  }

  public selectMainAnonymous(): void {
    this.hideMobileMenu();
    this.router.navigate(['/']);
  }

  public selectAnalytics(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['director/analytics']);
  }

  public selectSlices(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['director/slices']);
  }

  public selectProfile(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/profile']);
  }

  public selectProfessions(): void {
    this.hideProfile();
    this.hideMobileMenu();

    if (this.userRole === 'teacher') {
      this.userActionsService.log(YmItems.T_Professions);
    }

    this.router.navigate(['/professions']);
  }

  public selectCourses(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/courses']);
  }

  public selectEvents(): void {
    this.hideMobileMenu();
    this.router.navigate(['/events']);
  }

  public selectInternships(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/internships']);
  }

  public selectNews(): void {
    this.hideMobileMenu();
    this.router.navigate(['/news']);
  }

  public selectPortfolio(): void {
    this.hideMobileMenu();
    this.router.navigate(['/portfolio']);
  }

  public selectResults(): void {
    this.hideMobileMenu();

    if (this.root == 'pupil') {
      if (this.session && this.session.length > 0) {
        this.router.navigate(['/results']);
      } else {
        this.checkTests();
      }
    } else if (this.root == 'teacher') {
      this.userActionsService.log(YmItems.T_Results);
      this.router.navigate(['/results']);
    } else {
      this.menuService.openResults();
    }
  }

  public selectHistory(): void {
    this.hideMobileMenu();
    this.router.navigate(['/pupil-tests-history']);
  }

  public selectPromoAction(): void {
    this.hideMobileMenu();
    this.router.navigate(['/mosruabout']);
  }

  private setHeaderButtonsMenu(): void {
    this.url = this.router.url;

    if (this.isVGuser) {
      if (this.url.includes('partners-courses-all')) {
        this.headerButtonsArray = [
          { name: 'Результаты теста', url: '/vorobievi-gori/promotest-results' },
          { name: 'Пройти тест', url: '/vorobievi-gori/mosrupromo' },
        ];
      } else if (this.url.includes('promotest-results')) {
        this.headerButtonsArray = [
          { name: 'Курсы', url: '/vorobievi-gori/partners-courses-all' },
          { name: 'Пройти тест', url: '/vorobievi-gori/mosrupromo' },
        ];
      }
    } else if (this.mosruUser) {
      if (this.url.includes('promotest-results')) {
        this.headerButtonsArray = [
          { name: 'Партнеры', url: '/partners-courses-all' },
          { name: 'Пройти тест', url: '/parent/mosrupromo' },
          { name: 'Об акции', url: '/mosruabout' },
        ];
      } else if (this.url.includes('partners-courses-all')) {
        this.headerButtonsArray = [
          { name: 'Результаты теста', url: '/promotest-results' },
          { name: 'Пройти тест', url: '/parent/mosrupromo' },
          { name: 'Об акции', url: '/mosruabout' },
        ];
      } else if (this.url.includes('mosruabout')) {
        this.headerButtonsArray = [
          { name: 'Партнеры', url: '/partners-courses-all' },
          { name: 'Пройти тест', url: '/parent/mosrupromo' },
          { name: 'Результаты теста', url: '/promotest-results' },
        ];
      }
    }
  }

  private checkTests(): void {
    const sessionId = this.webStorageService.get(StorageKeys.TestSessionId);
    const sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);

    if (sessionStatus == 'Uncompleted test' && sessionId) {
      this.router.navigate(['/test-na-professiyu', sessionId]);
    } else {
      this.router.navigate(['/test-na-professiyu']);
    }
  }

  private init(): void {
    this.dictionary = this.rootScopeService.getDictionary();
    this.imagePath = this.webStorageService.get(StorageKeys.ImagePath);
    this.firstName = this.webStorageService.get(StorageKeys.FirstName);
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.isVGuser = this.userInfo.tag === EUserTags[EUserTags.VorobieviGori].toString();
    this.mosruUser = this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.MosRu].toString();
    this.userId = this.userInfo.userId;
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);

    if (this.imagePath === null || this.imagePath === 'null' || this.imagePath === '') {
      this.imagePath = './profilum-assets/images/icons/no-photo.svg';
    }

    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.isMOStend = location.origin.includes('mosreg');

    this.userActionsService.setInitializationTime([YmItems.T_Lessons, YmItems.T_Class, YmItems.T_Results, YmItems.T_Professions]);
  }

  private detectUrlChange(): void {
    this.isAnalytics = this.router.url.includes('director/analytics');
    this.showCourses = !(this.userRole === 'parent' || this.userRole === 'teacher') && !this.isMOStend;
    this.showEvents = !(this.userRole === 'parent' || this.userRole === 'teacher');
    this.showInternships = !(this.userRole === 'parent' && this.webStorageService.get(StorageKeys.RegionId) === this.moscowRegionId);
    this.showMosruAbout =
      this.userRole === 'parent' &&
      ((this.webStorageService.get(StorageKeys.RegionId) === this.moscowRegionId &&
        this.webStorageService.get(StorageKeys.MunicipalityId) === this.moscowMunicipalityId) ||
        this.webStorageService.get(StorageKeys.Tag) === 'MosRu');
    this.currentUrl = this.router.url.split('/')[1];
    this.currentUrl = '/' + this.currentUrl;
    if (this.currentUrl.includes('#')) {
      this.currentUrl = this.router.url.split('#')[0];
    }
  }
}
