<div class="pupil-welcome-page">
  <div class="pupil-welcome">
    <img class="pupil-welcome-logo" src="./profilum-assets/images/registration/profilum_logo_medium.svg" alt="PROFILUM" />
    <prf-show-messages
      [messages]="messages"
      [buttons]="buttons"
      [delay]="600"
      [endMessageNumber]="3"
      [startMessageNumber]="0"
      [confetti]="true"
      (buttonAction)="buttonsActions()"
    >
    </prf-show-messages>
  </div>
</div>
