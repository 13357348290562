import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticNotificationComponent } from './static-notification.component';

@NgModule({
  declarations: [StaticNotificationComponent],
  imports: [CommonModule],
  exports: [StaticNotificationComponent],
})
export class StaticNotificationModule {}
