import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/modules/material.module';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { PagesModule } from 'app/pages/pages.module';
import { LandingMosoblRoutingModule } from './landing-mosobl-routing.module';
import { LandingMosoblComponent } from './landing-mosobl/landing-mosobl.component';
import { LoginMosOblComponent } from './login-mosobl/login-mosobl.component';
import {
  CodeRegistrationMosOblComponent,
} from './registration-mosobl/code-registration/code-registration-mosobl.component';
import {
  OpenRegistrationMosOblComponent,
} from './registration-mosobl/open-registration/open-registration-mosobl.component';
import { RegistrationMosOblComponent } from './registration-mosobl/registration-mosobl.component';
import {
  RegistrationPupilMosOblComponent,
} from './registration-mosobl/registration-pupil/registration-pupil-mosobl.component';
import { TokenauthMosOblComponent } from './tokenauth-mosobl/tokenauth-mosobl.component';
import { UpdatePupilMosOblComponent } from './login-mosobl/update-pupil-mosobl/update-pupil-mosobl.component';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';

@NgModule({
  imports: [MaterialModule, ReactiveFormsModule, PagesModule, SharedModule, LandingMosoblRoutingModule, SwiperDirective],
  declarations: [
    LandingMosoblComponent,
    LoginMosOblComponent,
    RegistrationMosOblComponent,
    CodeRegistrationMosOblComponent,
    OpenRegistrationMosOblComponent,
    RegistrationPupilMosOblComponent,
    TokenauthMosOblComponent,
    UpdatePupilMosOblComponent,
  ],
  exports: [LandingMosoblComponent],
  providers: [FilterByNamePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingMosoblModule {}
