import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { StorageKeys, WebStorageService } from '@profilum-library';
import { StorageKeys as UserStorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

import { ITryingOnStageShowedSteps, TryingOnSteps } from './profession-trying-on-steps.interfaces';

@Component({
  selector: 'prf-profession-trying-on-steps',
  templateUrl: './profession-trying-on-steps.component.html',
  styleUrls: ['./profession-trying-on-steps.component.scss'],
})
export class ProfessionTryingOnStepsComponent extends UnsubscribeComponent implements OnInit {
  public tryingOnSteps: typeof TryingOnSteps = TryingOnSteps;
  public currentStepPupil: number = 0;
  public currentStepTeacher: number = 0;
  public _newStepPupil: number;
  public _newStepTeacher: number;
  @Input() public stepTeacher: number = 1;
  @Input() public stepPupil: number = 1;

  constructor(
    private webStorageService: WebStorageService,
    private userStorageService: UserStorageService,
    private overlayBusyService: OverlayBusyService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.userStorageService
      .get(UserStorageKeys.TryingOnStageShowedSteps)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tryingOnStageShowedSteps: ITryingOnStageShowedSteps) => {
        this.overlayBusyService.hide();
        this.webStorageService.set(StorageKeys.TryingOnStageShowedSteps, tryingOnStageShowedSteps);
        this.currentStepPupil = tryingOnStageShowedSteps?.pupilSteps ?? 0;
        this.currentStepTeacher = tryingOnStageShowedSteps?.teacherSteps ?? 0;

        if (this.stepTeacher > this.currentStepTeacher) {
          this.timer = setTimeout(() => {
            this._newStepTeacher = this.stepTeacher;
          }, 0);
        }
        if (this.stepPupil > this.currentStepPupil) {
          this.timer = setTimeout(() => {
            this._newStepPupil = this.stepPupil - 1;
          }, 0);
        }
        this.userStorageService.set(UserStorageKeys.TryingOnStageShowedSteps, {
          teacherSteps: this.stepTeacher,
          pupilSteps: this.stepPupil,
        });
        this.webStorageService.set(StorageKeys.TryingOnStageShowedSteps, { teacherSteps: this.stepTeacher, pupilSteps: this.stepPupil });
      });
  }
}
