import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaComponent } from './captcha.component';
import { SharedModule } from 'app/shared/shared.module';
import { ResetButtonModule } from 'app/shared/common-components/prf-form/reset-button/reset-button.module';
import { DynamicLabelModule } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.module';

@NgModule({
  declarations: [CaptchaComponent],
  imports: [CommonModule, SharedModule, ResetButtonModule, DynamicLabelModule],
  exports: [CaptchaComponent],
})
export class CaptchaModule {}
