import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import {
  ApiFavoritesService,
  ApiPlayerService,
  ApiProfessionsService,
  ApiProfilesService,
  FavoritesDataHandlerService,
  UserDataHandlerService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-teacher-student-page',
  templateUrl: './teacher-student-page.component.html',
  styleUrls: ['./teacher-student-page.component.scss'],
})
export class TeacherStudentPageComponent extends UnsubscribeComponent implements OnInit {
  @Input() pupil: any;
  portfolios: any;
  favoriteProfessions: any = [];
  favoritesClasses: any = [];
  activeProfession: any;
  detailsMenu = [
    { key: 'results', name: 'Результат теста' },
    { key: 'professions', name: '' },
    { key: 'favorites', name: 'Избранное' },
    { key: 'portfolio', name: 'Портфолио' },
    { key: 'profile', name: 'Профиль' },
  ];
  selectedMenuItem: any = this.detailsMenu[0];
  userRole: string;
  public pupilInfo: {
    userId: string;
    userRole: string;
    firstName: string;
    regionId: string;
    municipalityId: string;
  };

  public sessionList: IHistory[];
  public motivationLastSessionId: string;

  public isLoaded: boolean = false;
  private isSchoolStaff: boolean = false;

  constructor(
    private goalsService: GoalsService,
    private apiFavoritesService: ApiFavoritesService,
    private apiProfilesService: ApiProfilesService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private apiPlayerService: ApiPlayerService,
    private apiProfessionsService: ApiProfessionsService,
    private userDataHandlerService: UserDataHandlerService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.getTranslation('HEADER.PROF')
      .pipe(take(1))
      .subscribe(translation => (this.detailsMenu[1].name = translation));
    this.userRole = userDataHandlerService.getUserInfo().role;
    if (this.userRole === 'tutor') {
      this.detailsMenu.splice(1, 0, { key: 'motivation', name: 'Учебная мотивация' });
      this.detailsMenu[0].name = 'Таланты';
    }
  }

  public ngOnInit(): void {
    this.pupilInfo = {
      userId: this.pupil.userId,
      userRole: 'pupil',
      firstName: this.pupil.firstName,
      regionId: this.pupil.regionId,
      municipalityId: this.pupil.municipalityId,
    };

    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => {
          return this.loadHistoryTests();
        }),
      )
      .subscribe(() => {
        this.isLoaded = true;
      });

    this.favoritesClasses = this.favoritesDataHandlerService.getFavoriteClasses().getValue();
    const favoritesProfessionsIds = this.favoritesDataHandlerService
      .getFavoriteProfessions()
      .getValue()
      .map(el => el.productId);

    let currentObservable$: Observable<any> = of(null);

    if (favoritesProfessionsIds.length) {
      currentObservable$ = this.apiProfessionsService
        .getElasticProfessionsByFilters({ ids: favoritesProfessionsIds, isVisible: true })
        .pipe(
          tap(r => {
            this.favoriteProfessions = r;
          }),
        );
    }

    currentObservable$
      .pipe(
        switchMap(() => {
          this.activeProfession = this.favoriteProfessions[0];

          return this.apiProfilesService.getPortfolio(this.pupil.userId).pipe(tap(r => (this.portfolios = r)));
        }),
      )
      .subscribe();

    this.isSchoolStaff = this.userRole === 'director' || this.userRole === 'schooladmin' || this.userRole === 'teacher';

    if (this.isSchoolStaff) {
      this.detailsMenu = [
        { key: 'results', name: 'Результат теста' },
        { key: 'portfolio', name: 'Портфолио' },
      ];
      this.selectedMenuItem = this.detailsMenu[0];
    }
  }

  private loadHistoryTests(): Observable<any> {
    return this.apiPlayerService.getSessionsList(this.pupil.userId).pipe(
      tap(r => {
        this.sessionList = r;
        const motivationSessions = this.sessionList
          .filter(session => session.screeningTestId === TESTS_VARIANTS_IDS.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

        this.motivationLastSessionId = motivationSessions && motivationSessions.length ? motivationSessions[0]!.sessionId : null;
      }),
    );
  }

  public setActiveProfession(profession: any): void {
    this.activeProfession = profession;
  }

  public selectMenuItem(menuItem: any): void {
    return (this.selectedMenuItem = menuItem);
  }

  public portfoliosChange(portfolios: any): void {
    return (this.portfolios = portfolios);
  }

  private getTranslation(key: string): Observable<string> {
    return this.translateService.get(key);
  }
}
