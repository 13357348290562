<prf-header [headerData]="startPageData.header" />
<prf-banner [bannerData]="startPageData.banner" class="standard-block first-block-indent block-bottom-indent" />
<prf-information-for-different-users
  [informationForDifferentUsers]="startPageData.informationForDifferentUsersCard"
  class="standard-block block-bottom-indent"
/>
<prf-extracurricular-activities
  [extraCurricularActivities]="startPageData.extracurricularActivities"
  class="standard-block block-bottom-indent"
/>
<prf-diagnostics-of-interests
  [diagnosticsOfInterestsData]="startPageData.diagnosticsOfInterests"
  class="standard-block block-bottom-indent"
/>
<prf-profession-catalog [professionCatalogData]="startPageData.professionCatalog" class="standard-block block-bottom-indent" />
<prf-footer [footerData]="startPageData.footer" />
