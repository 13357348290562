import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgStyle } from '@angular/common';
import { ITeacherGroup } from '../teacherPanel.interface';

@Component({
  selector: 'prf-teacher-group-card',
  standalone: true,
  templateUrl: './teacher-group-card.component.html',
  styleUrls: ['./teacher-group-card.component.scss'],
  imports: [
    NgStyle
  ]
})
export class TeacherGroupCardComponent {
  public _group: ITeacherGroup;
  @Input()
  public set group(group: ITeacherGroup) {
    if (!group.groupName.includes('группа')) {
      group.groupName = group.groupName.replace(' ', ' - ') + ' группа';
    }
    this._group = group;
  }

  @Output() private showGroup: EventEmitter<string> = new EventEmitter<string>();

  public showDetailGroup(): void {
    this.showGroup.emit(this._group.id);
  }
}
