<ng-template [ngIf]="!small" [ngIfElse]="smallWidget">
  <div class="access-banner">
    <div class="access-banner-text">
      <div class="access-banner-title">Скачайте доступы учеников</div>
      <div class="access-banner-desc">
        По ссылке — документ, в котором инструкция и код для каждого ученика. Скачайте, распечатайте и раздайте детям.
      </div>
    </div>
    <div class="access-banner-button">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #smallWidget>
  <div class="widget-wrapper">
    <div class="widget-button">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #button>
  <button class="button purple-btn" (click)="getDataUnregistered()" [class.inactiv]="loading">
    <ng-template [ngIf]="!loading" [ngIfElse]="loader">Скачать доступы</ng-template>
  </button>
</ng-template>

<!-- Loader -->
<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>
