import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export const ParamsMeanings = {
  fieldName: 'Предмет',
  authorName: 'Учебник',
  year: 'Год издания',
  class: 'Класс',
  chapter: 'Глава',
  paragraph: 'Параграф',
};

@Component({
  selector: 'prf-task-page',
  templateUrl: './task-page.component.html',
  styleUrls: ['./task-page.component.scss'],
})
export class TaskPageComponent implements OnInit {
  routeParams: {
    paramName: string;
    paramValue: any;
  }[] = [];

  activeTimer: boolean = false;
  timer$ = new Subject<number>();

  redirectUrl = 'https://hw.lecta.ru/teacher';

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      Object.keys(params).forEach(key => {
        this.routeParams.push({
          paramName: ParamsMeanings[key],
          paramValue: params[key],
        });
      });
      this.startTimer(15);
    });
  }

  startTimer(seconds: number) {
    let count = seconds;
    this.activeTimer = true;
    timer(0, 1000)
      .pipe(
        take(count),
        tap(r => {
          count > 0 ? --count : 0;
          this.timer$.next(count);
          if (!count) {
            this.activeTimer = false;
            this.changeLocation();
          }
        }),
      )
      .subscribe();
  }

  changeLocation() {
    window.location.href = this.redirectUrl;
  }
}
