<div class="lb-block-invite-welcome">
  <div class="invite-parent-row">
    <div class="add-child-column">
      <h3 class="h3-invite-parent">{{ 'SHARED.SMALL_BUSINESS' | translate }}</h3>
      <p class="add-child-text">Для регистрации скопируйте этот код и передайте ребёнку</p>
      <div class="child-codes">
        <div class="info-div">
          <div class="info-icon" (mouseover)="showToolTipe = true" (mouseout)="showToolTipe = false"></div>
          <div class="info-popup" *ngIf="showToolTipe">
            <p>Этот код является паролем при регистрации.</p>
            <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </div>
        <div class="profile-code" *ngFor="let child of children">
          <div>Код ребенка</div>
          <div class="code-text">{{ child.registrationCode }}</div>
        </div>
      </div>
    </div>

    <img src="./profilum-assets/images/illustrations/Parent.svg" alt="" class="invite-parent-image add-child-img" />
  </div>
</div>
