<div class="events-filters adm">
  <prf-courses-filter-date></prf-courses-filter-date>

  <prf-courses-filter-types *ngIf="userRole !== 'director' && userRole !== 'schooladmin'"></prf-courses-filter-types>

  <prf-courses-filter-group></prf-courses-filter-group>

  <prf-courses-filter-territory *ngIf="territoryFilters"></prf-courses-filter-territory>

  <prf-courses-filter-format></prf-courses-filter-format>

  <!-- <prf-courses-filter-institution></prf-courses-filter-institution> -->
</div>
