<div class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.ADDING' | translate }}</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Формат*</label>
        </div>
        <div class="create-form-column">
          <div class="create-form-column" #ddFormat>
            <div class="professions-dropdown w-dropdown">
              <div
                class="professions-dropdown-toggle w-dropdown-toggle w-dd-width"
                [class.error-field-dd]="submitted && selectedFormat == undefined"
                [class.active]="selectedFormat"
                (click)="typesFormats.classList.toggle('w--open')"
                (prfClickOutside)="typesFormats.classList.remove('w--open')"
              >
                <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                <div>{{ checkFormatsHeader() }}</div>
              </div>
              <nav class="dd-prof-filter-list w-dropdown-list" #typesFormats>
                <div class="dd-prof-filter-div">
                  <div class="scroll-block">
                    <div class="prof-categories-links">
                      <a class="prof-categories-link w-inline-block" *ngFor="let type of classesFormats">
                        <div class="category-text-link" (click)="setFormat(type); typesFormats.classList.remove('w--open')">
                          {{ type.viewValue }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <ng-container *ngIf="submitted && selectedFormat == undefined">
            <mat-error *ngIf="selectedFormat == undefined">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <a
        class="event-image w-inline-block"
        (mouseover)="img = './profilum-assets/images/icons/add-prof.svg'"
        (mouseleave)="img = './profilum-assets/images/icons/achieves/add-achievement-icon.svg'"
        (click)="upload.click()"
        [style.background-image]="'url(' + f.mainImagePath.value + ')'"
      >
        <div class="add-event-image">
          <div class="profession-icon">
            <img [src]="img" src="./profilum-assets/images/icons/achieves/add-achievement-icon.svg" class="icon-initial-state" />
          </div>
          <div>Добавить фото</div>
        </div>
      </a>
      <input type="file" (change)="uploadLogoCourse($event)" #upload hidden />
      <div class="event-image-text">Рекомендуем загружать качественные фото. Его увидят тысячи учеников и родителей.</div>
      <h2 class="event-h2">Основная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && f.name.errors"
            maxlength="256"
            name="name"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="hrid">HRID курса*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="(submitted && f.hrid.errors) || (submitted && !checkhrid)"
            maxlength="256"
            name="hrid"
            placeholder="HRID курса"
            id="hrid"
            formControlName="hrid"
          />
          <ng-container *ngIf="submitted && f.hrid.errors">
            <mat-error *ngIf="f.hrid.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
          <ng-container *ngIf="submitted && !checkhrid">
            <mat-error *ngIf="!checkhrid">
              {{ 'SHARED.HRID_IS_BUSY' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="userRole !== 'schooladmin'">
        <div class="create-form-row">
          <div class="create-form-column _1">
            <label for="region" class="create-form-label">{{ 'SHARED.REGION' | translate }}*</label>
            <div class="div-space"></div>
          </div>
          <mat-form-field class="create-form-column">
            <mat-select
              placeholder="{{ 'SHARED.REGION' | translate }}"
              class="create-event-field w-select"
              [class.error-field]="submitted && f.region.errors"
              formControlName="region"
              name="region"
              id="region"
            >
              <mat-option (click)="selectRegion(region)" [value]="region" *ngFor="let region of allRegions$ | async">{{
                region.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="create-form-row" *ngIf="f.region.value.id && f.region.value.id != defaultRegion">
          <div class="create-form-column _1">
            <label for="municipality" class="create-form-label">Муниципалитет</label>
            <div class="div-space"></div>
          </div>
          <mat-form-field class="create-form-column">
            <mat-select
              placeholder="Муниципалитет"
              class="create-event-field w-select"
              [class.error-field]="submitted && f.municipality.errors"
              formControlName="municipality"
              name="municipality"
              id="municipality"
            >
              <mat-option [value]="municipality" (click)="clearMunicipality()">Не выбран</mat-option>
              <mat-option [value]="municipality" *ngFor="let municipality of municipalitiesByRegion$ | async">{{
                municipality.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="institution">Организатор*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && f.institution.errors"
            maxlength="256"
            name="institution"
            data-name="institution"
            id="institution"
            formControlName="institution"
            placeholder="Организатор"
          />
          <ng-container *ngIf="submitted && f.institution.errors">
            <mat-error *ngIf="f.institution.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="shortDescription">Краткое описание*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <div class="info-popup" *ngIf="showInfoPopup">
              <p>В кратком описании есть ограничение по символам 5000</p>
              <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
        </div>
        <div class="create-form-column">
          <textarea
            class="create-event-field w-input vertical-resize"
            id="shortDescription"
            name="shortDescription"
            maxlength="5000"
            [class.error-field]="submitted && f.shortDescription.errors"
            placeholder="Краткое описание"
            formControlName="shortDescription"
          >
          </textarea>
          <ng-container *ngIf="submitted && f.shortDescription.errors">
            <mat-error *ngIf="f.shortDescription.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание*</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            [class.error-field]="submitted && f.description.errors"
            id="description"
            name="description"
            placeholder="Описание"
            formControlName="description"
          >
          </quill-editor>
          <ng-container *ngIf="submitted && f.description.errors">
            <mat-error *ngIf="f.description.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Тематика*</label>
        </div>
        <div class="create-form-column">
          <prf-select-themes (dataSelectThemes)="dataThemes($event)"></prf-select-themes>
          <ng-container *ngIf="submitted && selectedThemes == undefined">
            <mat-error *ngIf="selectedThemes == undefined">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Талант*</label>
        </div>
        <div class="create-form-column">
          <prf-select-talents (dataSelectTalents)="dataTalents($event)"></prf-select-talents>
          <ng-container *ngIf="submitted && selectedTalents == undefined">
            <mat-error *ngIf="selectedTalents == undefined">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Сложность*</label>
        </div>
        <div>
          <div class="create-form-column" #ddComplexity>
            <div class="professions-dropdown w-dropdown">
              <div
                class="professions-dropdown-toggle w-dropdown-toggle"
                [class.error-field-dd]="submitted && selectedComplexity == undefined"
                [class.active]="selectedComplexity"
                (click)="typesComplexitys.classList.toggle('w--open')"
                (prfClickOutside)="typesComplexitys.classList.remove('w--open')"
              >
                <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                <div>{{ checkComplexityHeader() }}</div>
              </div>
              <nav class="dd-prof-filter-list w-dropdown-list" #typesComplexitys>
                <div class="dd-prof-filter-div">
                  <div class="scroll-block">
                    <div class="prof-categories-links">
                      <a class="prof-categories-link w-inline-block" *ngFor="let type of complexitys">
                        <div class="category-text-link" (click)="setComplexity(type); typesComplexitys.classList.remove('w--open')">
                          {{ type.viewValue }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <ng-container *ngIf="submitted && selectedComplexity == undefined">
            <mat-error *ngIf="selectedComplexity == undefined">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Рекомендуемый возраст*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showAgeInfo = true" (mouseout)="showAgeInfo = false"></div>
            <div class="info-popup" *ngIf="showAgeInfo">
              <p>Если нет ограничения по возрасту, оставьте поле "до" пустым</p>
              <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle add-event" />
            </div>
          </div>
        </div>
        <div class="create-form-column">
          <div class="create-event-row">
            <div class="create-event-row">
              <label class="create-form-label" for="minAgeAudience">от</label>
              <input
                class="create-event-field time w-input"
                type="number"
                [class.error-field]="submitted && f.minAgeAudience.errors"
                min="0"
                max="120"
                name="minAgeAudience"
                data-name="minAgeAudience"
                id="minAgeAudience"
                [(ngModel)]="currentMinAge"
                (change)="diffAges()"
                formControlName="minAgeAudience"
              />
            </div>
            <div class="create-event-row">
              <label class="create-form-label" for="maxAgeAudience">до</label>
              <input
                class="create-event-field time w-input"
                type="number"
                min="0"
                max="120"
                name="maxAgeAudience"
                data-name="maxAgeAudience"
                id="maxAgeAudience"
                [(ngModel)]="currentMaxAge"
                (change)="diffAges()"
                formControlName="maxAgeAudience"
              />
            </div>
          </div>
          <ng-container *ngIf="submitted && f.minAgeAudience.errors">
            <mat-error *ngIf="f.minAgeAudience.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Формат курса*</label>
        </div>
        <div class="create-form-column">
          <div class="create-form-column" #ddFormatCourse>
            <div class="professions-dropdown w-dropdown">
              <div
                class="professions-dropdown-toggle w-dropdown-toggle"
                [class.active]="selectedDelivery"
                [class.error-field-dd]="submitted && selectedDelivery == undefined"
                (click)="formatCourse.classList.toggle('w--open')"
                (prfClickOutside)="formatCourse.classList.remove('w--open')"
              >
                <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                <div>{{ checkDeliveryHeader() }}</div>
              </div>
              <nav class="dd-prof-filter-list w-dropdown-list" #formatCourse>
                <div class="dd-prof-filter-div">
                  <div class="scroll-block">
                    <div class="prof-categories-links">
                      <a class="prof-categories-link w-inline-block" *ngFor="let format of delivery">
                        <div class="category-text-link" (click)="setDelivery(format); formatCourse.classList.remove('w--open')">
                          {{ format.viewValue }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <ng-container *ngIf="submitted && selectedDelivery == undefined">
            <mat-error *ngIf="selectedDelivery == undefined">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-event-row">
          <div class="create-form-column _1">
            <label class="create-form-label" for="startDate">Дата проведения*</label>
          </div>
          <div>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="startDate"
              class="create-event-field date w-input"
              [class.error-field]="submitted && f.startDate.errors"
              maxlength="256"
              name="startDate"
              data-name="startDate"
              id="startDate"
              #myDate
              [value]="class.startDate | date : 'yyyy-MM-dd'"
              (input)="class.startDate = parseDate($event.target.value)"
            />
            <ng-container *ngIf="submitted && f.startDate.errors">
              <mat-error *ngIf="f.startDate.errors.required">
                {{ 'SHARED.FORM.FILL_INPUT' | translate }}
              </mat-error>
            </ng-container>
          </div>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="create-form-column">
          <div class="create-event-row">
            <label class="create-form-label" for="startTime">Время начала</label>
            <input
              class="create-event-field time w-input"
              type="text"
              [class.error-field]="submitted && f.startTime.errors"
              [textMask]="{ mask: timeMask }"
              maxlength="256"
              name="startTime"
              data-name="startTime"
              id="startTime"
              formControlName="startTime"
            />
          </div>
          <ng-container *ngIf="(submitted && f.startTime.errors) || (submitted && f.startDate.errors)">
            <mat-error *ngIf="f.startTime.errors?.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
            <mat-error *ngIf="f.startDate.errors?.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label" for="unitPrice">Стоимость*</label>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="number"
              [class.error-field]="submitted && f.unitPrice.errors"
              class="create-event-field cost w-input"
              maxlength="256"
              name="unitPrice"
              data-name="unitPrice"
              id="unitPrice"
              formControlName="unitPrice"
            />
            <div class="cost-symbol">{{ 'COMMON.MONEY_SYMBOL' | translate }}</div>
          </div>
          <div class="create-event-text">Если бесплатно, поставьте 0</div>
          <ng-container *ngIf="submitted && f.unitPrice.errors">
            <mat-error *ngIf="f.unitPrice.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Тип промокода</label>
        </div>
        <div class="create-form-column">
          <div class="create-form-column" #ddPromoType>
            <div class="professions-dropdown w-dropdown">
              <div
                class="professions-dropdown-toggle w-dropdown-toggle w-dd-width"
                (click)="promoTypes.classList.toggle('w--open')"
                (prfClickOutside)="promoTypes.classList.remove('w--open')"
              >
                <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                <div>{{ checkPromocodeTypesHeader() }}</div>
              </div>
              <nav class="dd-prof-filter-list w-dropdown-list" #promoTypes>
                <div class="dd-prof-filter-div">
                  <div class="filter-list-row">
                    <ng-container *ngIf="selectedPromoType; else notSelected">
                      <a class="clear-categories show" (click)="deselectPromoType()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
                    </ng-container>
                  </div>
                  <div class="scroll-block">
                    <div class="prof-categories-links">
                      <a class="prof-categories-link w-inline-block" *ngFor="let type of promocodeTypes">
                        <div class="category-text-link" (click)="setPromoType(type); promoTypes.classList.remove('w--open')">
                          {{ type.viewValue }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="create-form-row _2" *ngIf="selectedPromoType != null">
        <div class="create-form-column _1">
          <label class="create-form-label" for="promocode">Промокод*</label>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="text"
              class="create-event-field w-input"
              [class.error-field]="submitted && f.promocode.errors"
              maxlength="256"
              name="hrid"
              placeholder="Промокод"
              id="promocode"
              formControlName="promocode"
            />
          </div>
        </div>
      </div>
      <div class="create-form-row _2" *ngIf="selectedPromoType != null">
        <div class="create-form-column _1">
          <label class="create-form-label" for="unitDiscount">Размер скидки*</label>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="number"
              [class.error-field]="submitted && f.unitDiscount.errors"
              class="create-event-field cost w-input"
              maxlength="256"
              name="unitDiscount"
              data-name="unitDiscount"
              id="unitDiscount"
              formControlName="unitDiscount"
            />
            <div *ngIf="!selectedPromoType.value" class="cost-symbol">
              {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
            </div>
            <div *ngIf="selectedPromoType.value" class="cost-symbol">
              {{ 'COMMON.PERCENT_SYMBOL' | translateByDefault }}
            </div>
          </div>
          <ng-container *ngIf="submitted && f.unitDiscount.errors">
            <mat-error *ngIf="f.unitDiscount.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <h2 class="event-h2">Дополнительная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Адрес проведения</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="address"
            data-name="address"
            id="address"
            formControlName="address"
            placeholder="Адрес проведения"
          />
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Ближайшее метро</label>
        </div>
        <prf-select-metro (dataSelectMetro)="dataMetro($event)"></prf-select-metro>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="url">Ссылка</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="url"
            data-name="url"
            id="url"
            formControlName="url"
            placeholder="Ссылка"
          />
        </div>
      </div>
      <input (click)="submit()" type="submit" value="Добавить курс" class="button green-btn w-button" />
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
<ng-template #notSelected>
  <div class="filter-list-heading">{{ checkPromocodeTypesHeader() }}</div>
  <a class="clear-categories">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
</ng-template>
