<!-- <prf-menu></prf-menu> -->
<div class="page-forparent" *ngIf="dataFetched" #pdfTable>
  <div class="forparent-container">
    <!-- <prf-page-header [title]="'Результаты теста'"></prf-page-header> -->

    <div class="page-title">{{ childName }} - карта талантов</div>

    <div class="forparent-evaluations forparent-section text-block">
      Уважаемый {{ parentName }}! Во-первых, спасибо, что доверяете нам развитие своего ребенка. Мы рады представить Вашему вниманию первую
      Карту талантов Вашего ребенка.

      <br /><br />
      В нашей школе мы следим не только за успеваемостью учеников, но и практикуем индивидуальный подход. Поэтому мы провели тестирование,
      чтобы понять, какими основными талантами, интересами и навыками обладает Ваш ребенок. С результатами Вы можете ознакомиться ниже.
    </div>

    <div class="forparent-attainments forparent-section">
      <div class="column-header _talents">
        <div class="column-title">Таланты</div>
        <div class="column-text">
          Каждый ребенок талантлив! Для нас талант - пересечение между способностями ребенка и его зонами интереса. {{ childName }} может
          развиваться в одном из направлений или задействовать все три свои сильные стороны для достижения успеха.
        </div>
      </div>
      <div class="column-values _talents">
        <div class="values-item" *ngFor="let talent of talents">
          <div class="item-image">
            <img src="{{ talent.imageUrl }}" />
          </div>
          <div class="item-name">{{ talent.name }}</div>
        </div>
      </div>
      <div class="column-header _interests">
        <div class="column-title">Интересы и предпочтения</div>
        <div class="column-text">
          Ниже представлены топ-3 интереса Вашего ребенка. Это те направления, где он готов развиваться без дополнительных стимулов.
          Правильная работа с интересами может помочь нам достичь высоких результатов и в школьной программе.
        </div>
      </div>
      <div class="column-values _interests">
        <div class="values-item" *ngFor="let interest of interests | slice : 0 : maxInterests">
          <div class="item-name">
            {{ interest.name }}
          </div>
          <div class="item-percent">{{ interest.results[0].transformedValue | number : '2.0-0' }}/100</div>
        </div>
      </div>
      <div class="column-header _skills">
        <div class="column-title">Навыки и способности</div>
        <div class="column-text">
          Здесь указаны направления и типы задач, в которых ребенок чувствует себя максимально успешным и уверенным. Это то, что у него
          хорошо получается. Эта уверенность может проявляться в школьных предметах и в жизненных ситуациях. Нам важно усиливать эти
          способности, ведь именно они могут стать фундаментом для дальнейшего успеха и личностного роста.
        </div>
      </div>
      <div class="column-values _skills">
        <div class="values-item" *ngFor="let skill of skillsAttainment | slice : 0 : maxSkills">
          <div class="item-name">
            {{ skill.name }}
          </div>
          <div class="item-percent">{{ skill.results[0].transformedValue | number : '2.0-0' }}/100</div>
        </div>
      </div>
    </div>

    <div class="forparent-details forparent-section">
      <div class="details-text">
        Более детальную расшифровку результатов тестирования Вы сможете увидеть в личном кабинете сервиса «‎Карта талантов Подмосковья».
        Пройдите тестирование в системе и сравните свои результаты с результатами Вашего ребенка. Это поможет Вам лучше его понять и
        развиваться вместе.
      </div>
      <div class="details-info">
        <div class="details-link">
          <a routerLink="/registration-parent" class="button w-button">Тестирование для родителя</a>
        </div>
        <div class="details-code">Код вашего ребенка - {{ childCode }}</div>
      </div>
    </div>

    <div class="forparent-courses forparent-section">
      <div class="section-title">Рекомендованные кружки</div>
      <div class="text-block">
        Мы собрали, проанализировали все вышеперечисленные данные и подобрали увлекательные и доступные для Вас курсы, которые помогут
        раскрыть его потенциал.
        <br /><br />
        Запишитесь прямо сейчас — достаточно кликнуть на понравившийся Вам курс. Или ознакомьтесь с полным каталогом курсов в Вашем городе,
        перейдя по ссылке.
      </div>

      <div class="courses-container">
        <prf-course-card *ngFor="let program of recommendedCourses" [course]="program" [favouritesAccess]="false"></prf-course-card>
      </div>
    </div>

    <div class="forparent-professions forparent-section">
      <div class="section-title">Рекомендованные профессии</div>
      <div class="text-block">
        Мы думаем, что в будущем таланты, которыми обладает {{ childName }}, позволят ему построить успешную карьеру в одной из следующих
        индустрий.
      </div>
      <div class="professions-container">
        <!-- <div class="field-block" *ngFor="let field of professionsByResults"> -->
        <div class="field-block" *ngFor="let field of fieldsSeted">
          <div class="field-container">
            <div class="field-image">
              <!-- <img
                  *ngIf="field.professions && field.professions.length"
                  src="{{ field.professions[0].mainImagePath }}"
                  alt="Sphere image"
                /> -->
              <img src="{{ field.imageUrl }}" alt="Sphere image" />
            </div>
            <div class="field-title">
              {{ field.name }}
            </div>

            <!-- <div class="professions-list">
              <div
                class="profession-item"
                *ngFor="let profession of field.professions | slice: 0:maxProfessions"
              >
                {{ profession.name }}
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="professions-text text-block">
        В личном кабинете системы «Карты талантов Подмосковья» Вы сможете найти более подробную информацию о профессиях и направлениях
        развития вашего ребенка, а так же узнать о востребованных профессиях и ключевых работодателях в нашем муниципалитете.
      </div>
    </div>

    <div class="forparent-keytext">
      Потенциал ребенка - ключ к его мотивации. Именно она поможет добиться высоких результатов в учебе и стать успешным в будущем.
    </div>

    <div class="forparent-subscription text-block">
      С наилучшими пожеланиями,<br />
      {{ directorName }}<br />
      Директор {{ schoolName }}
    </div>

    <!-- <prf-page-footer></prf-page-footer> -->
  </div>
</div>
