<ng-container>
  <mat-drawer-container class="container">
    <mat-drawer class="navigation" mode="side" opened="true">
      <mat-button-toggle-group class="navigation-group" value="navStatistic" #group="matButtonToggleGroup">
        <mat-button-toggle value="navStatistic">{{ 'SHARED.STATISTIC' | translate }}</mat-button-toggle>
        <mat-button-toggle value="navSettings">{{ 'SHARED.SETTINGS' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-drawer>
    <mat-drawer-content>
      <section class="cityadmin" *ngIf="group.value === 'navStatistic'">
        <div class="city">
          <img src="./profilum-assets/images/control-panel/director/school.svg" alt="" />
          <h1 (click)="_statisticFlag = !_statisticFlag">{{ 'SHARED.STATISTIC' | translate }} по Москве</h1>
          <div *ngIf="_statisticFlag">
            <p>Количество школ: {{ _cityStatistic.schoolsCount }}</p>
            <p>Количество классов: {{ _cityStatistic.classesCount }}</p>
            <p>Количество учеников: {{ _cityStatistic.pupilsCount }}</p>
            <div *ngIf="_questions">
              <prf-question-bar [questions]="_questions"></prf-question-bar>
            </div>
          </div>
        </div>
      </section>
      <section class="section" *ngIf="group.value === 'navSettings'">
        <prf-settings></prf-settings>
      </section>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>
