import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { AdminDOPanelComponent } from './admin-do-panel.component';
import { AdminDORoutingModule } from './admin-do-routing.module';

@NgModule({
  declarations: [AdminDOPanelComponent],
  imports: [CommonModule, AdminDORoutingModule, SharedModule],
})
export class AdminDOModule {}
