import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Gender } from 'app/shared/enums/gender.enums';
import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ApiAdminsService, ApiTemporaryService, IUpdateRegistrationCode } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { PupilAccountData } from '../schooladmin.model';

@Component({
  selector: 'prf-schooladmin-add-students',
  templateUrl: './schooladmin-add-students.component.html',
  styleUrls: ['./schooladmin-add-students.component.scss'],
})
export class SchooladminAddStudentsComponent extends UnsubscribeComponent implements OnInit {
  @Output() showEditPupils = new EventEmitter();
  @Input() schoolClass;
  private _pupils: any[];
  @Input() teacherClass: any;
  @Input() schoolId: string;
  @Input() currentClassName: string;
  @ViewChild('modal') modal: any;
  @ViewChild('modalDelete') modalDelete: any;

  private pupilsListOrigin = [];
  public pupilsForm: UntypedFormGroup;
  private users: any = [];
  public submitted: boolean;
  private newPupils: any = [];
  private errors: number[] = [];
  private saveInProcess: boolean = false;
  public pupilFullName: string;
  private isChanged: boolean = false;

  public classList = [];

  private selectedPupilEmail: string = '';
  private selectedDestinationClass: string = '';
  private selectedPupilIndex: number;
  private selectedNumber: string;

  @Input() set pupils(val: any) {
    if (val !== undefined) {
      this._pupils = val;
      this.pupilsListOrigin = val;
    }
  }

  get pupils() {
    return this._pupils;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiAdminsService: ApiAdminsService,
    private utilsService: UtilsService,
    private teacherService: TeacherPanelService,
    private dialog: MatDialog,
    private apiTemporaryService: ApiTemporaryService,
  ) {
    super();
  }

  ngOnInit(): void {
    let array = [];
    if (this.pupils.length > 0) {
      array = this.pupils.map(user => this.createPupil(user));
    } else {
      const user = this.newUser();
      this.newPupils.push(user);
      array.push(this.createPupil(user));
    }

    this.pupilsForm = this.formBuilder.group({
      users: this.formBuilder.array(array),
    });

    this.apiAdminsService
      .getSchoolClassesExtended(this.schoolId)
      .pipe(
        tap(classes => {
          this.classList = classes.filter(el => this.currentClassName !== el.number + el.letter).map(el => el.number + el.letter);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private createPupil(user: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: new UntypedFormControl(user.firstName, [Validators.required]),
      lastName: new UntypedFormControl(user.lastName, [Validators.required]),
      middleName: new UntypedFormControl(user.middleName),
      registrationCode: user.registrationCode,
      gender: user.gender,
      email: user.email,
      phone: user.phoneNumber?.replace(/\D/g, ''),
      isActivated: user.isActivated,
    });
  }

  public addPupil(): void {
    const user = this.newUser();
    this.newPupils.push(user);
    this.users = this.pupilsForm.get('users') as UntypedFormArray;
    this.users.push(this.createPupil(user));
    this.submitted = false;
  }

  public submit(): void {
    this.submitted = true;
    if (this.pupilsForm.valid && !this.saveInProcess) {
      this.saveInProcess = true;
      // Обновление только измененных учеников
      if (this.pupilsForm.get('users').touched) {
        for (let i = 0; i < this.pupils.length; i++) {
          if (!this.pupils[i].isActivated) {
            // можно редактировать данные только для неавторизованных пользователей
            this.updatePupil(i, this.pupils[i], this.pupilsForm.value.users[i]);
          }
        }
      }

      this.apiTemporaryService
        .refreshSchoolClass(this.schoolClass.id, this.schoolId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          r => {
            this.saveInProcess = false;
            if (this.errors.length == 0) {
              this.showEditPupils.emit(this.isChanged);
            }
          },
          err => {
            this.saveInProcess = false;
          },
        );
    } else {
      return;
    }
  }

  private updatePupil(pupilIndex, currentPupil, updatedPupil) {
    if (
      currentPupil.firstName !== updatedPupil.firstName ||
      currentPupil.lastName !== updatedPupil.lastName ||
      currentPupil.middleName !== updatedPupil.middleName ||
      currentPupil.gender !== updatedPupil.gender
    ) {
      currentPupil.firstName = updatedPupil.firstName;
      currentPupil.lastName = updatedPupil.lastName;
      currentPupil.middleName = updatedPupil.middleName;
      currentPupil.gender = updatedPupil.gender;
      this.apiAdminsService
        .updateRegistrationCode(this.createUpdateRegistrationCode(currentPupil))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(resp => {
          if (!resp) {
            this.errors.push(pupilIndex);
          }
        });
    }
  }

  private createUpdateRegistrationCode(currentPupil: any): IUpdateRegistrationCode {
    return {
      userId: currentPupil.userId,
      schoolId: this.schoolId,
      firstName: currentPupil.firstName,
      lastName: currentPupil.lastName,
      middleName: currentPupil.middleName,
      gender: currentPupil.gender,
    };
  }

  private newUser() {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
      registrationCode: '',
      gender: Gender.Male,
      schoolClassId: this.teacherClass.id,
      schoolId: this.teacherClass.schoolId,
      role: 'pupil',
    };
  }

  public isMale(gender: string): boolean {
    return gender === Gender.Male;
  }

  public setMale(pupil): void {
    pupil.value.gender = Gender.Male;
  }

  public setFemale(pupil): void {
    pupil.value.gender = Gender.Female;
  }

  public removePupil(): void {
    const index = this.selectedPupilIndex;
    const pupil = this.pupils[index];
    const control = <UntypedFormArray>this.pupilsForm.controls['users'];
    // Удаление control для данного ученика
    control.removeAt(index);

    if (pupil && !pupil.isActivated) {
      this.apiAdminsService
        .removeUnregisteredPupil(pupil.userId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          r => {
            this.utilsService.openSnackBar('👌 Ученик удален', 'success');
            this.pupils.splice(index, 1);
            this.newPupils.pop();
            this.isChanged = true;
          },
          catchError(err => {
            this.utilsService.openSnackBar('👎 Произошла ошибка', 'error');
            return throwError(err);
          }),
        );
    }

    if (pupil && pupil.isActivated) {
      this.apiAdminsService
        .removePupil(pupil?.userId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          if (res.status == 'Success') {
            this.pupils.splice(index, 1);
            this.newPupils.pop();
            this.isChanged = true;
          }
        });
    } else {
      this.pupils.splice(index, 1);
      this.newPupils.pop();
    }
  }

  public trackByFn(index: number, item: any): string {
    return item.value.registrationCode;
  }

  get formDataArray() {
    return <UntypedFormArray>this.pupilsForm.get('users');
  }

  public openConfirmTransfer(pupil: PupilAccountData, selectedClass: string, index: number): void {
    this.pupilFullName = `${pupil.firstName} ${pupil.lastName}`;
    this.selectedPupilEmail = pupil.email;
    this.selectedNumber = pupil.phone;
    this.selectedDestinationClass = selectedClass;
    this.selectedPupilIndex = index;
    this.dialog.open(this.modal);
  }

  public openConfirmDelete(index: number): void {
    this.selectedPupilIndex = index;
    this.dialog.open(this.modalDelete);
  }

  public transferToAnotherClass(): void {
    this.apiAdminsService
      .transferPupil(this.selectedPupilEmail, this.selectedNumber, this.currentClassName, this.selectedDestinationClass)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        const control = <UntypedFormArray>this.pupilsForm.controls['users'];
        const index: number = this.selectedPupilIndex;
        control.removeAt(index);
        this.pupils.splice(index, 1);
        this.newPupils.pop();
        this.utilsService.openSnackBar(
          `Ученик ${this.pupilFullName} успешно переведен в ${this.selectedDestinationClass} класс`,
          'success',
        );
        // this.schoolAdminService.needCall = true;
        this.isChanged = true;
      });
  }

  public showClassesList(event): void {
    event.target.nextElementSibling.classList.toggle('show');
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target): void {
    const dropdownButton = document.querySelectorAll('.transfer-button-wrapper');
    if (dropdownButton) {
      dropdownButton.forEach(element => {
        if (!element.contains(target)) {
          const dropdown = element.querySelector('.classes-wrapper');
          dropdown?.classList.remove('show');
        }
      });
    }
  }
}
