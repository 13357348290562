import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { ApiUsersService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { AdminClassService } from '../../../../shared/services/admin-class.service';

@Component({
  selector: 'prf-tutor-pupils',
  templateUrl: './tutor-pupils.component.html',
  styleUrls: ['./tutor-pupils.component.scss'],
})
export class TutorPupilsComponent extends UnsubscribeComponent implements OnInit {
  public loadedClass: boolean = false;

  public pupils: any[] = [];
  public selectedPupil: any;

  showPupilPage: boolean = false;

  constructor(
    private overlayService: OverlayBusyService,
    private adminClassService: AdminClassService,
    private router: Router,
    private apiUsersService: ApiUsersService,
  ) {
    super();
  }

  ngOnInit() {
    this.overlayService.show();
    this.adminClassService
      .getPupilPage()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(data => {
          this.showPupilPage = data.showPupilPage;
          if (!this.showPupilPage) {
            return this.apiUsersService.getPupilsByTutorTeachers().pipe(
              takeUntil(this.unsubscribe),
              tap(pupils => {
                if (pupils && pupils.length) {
                  this.pupils = pupils;
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }
  getImagePath(job: any) {
    if (job.mainImagePath) {
      job.mainImagePath = job.mainImagePath.replace('/content/images/professions/default_icons/', './profilum-assets/images/profession/');
    } else {
      job.mainImagePath = './profilum-assets/images/illustrations/petard.png';
    }
    return job.mainImagePath;
  }

  isTestOld(lastTestDate: Date): boolean {
    const dateCheck = new Date();
    const dateCompare = new Date(lastTestDate);
    dateCheck.setMonth(dateCheck.getMonth() - 3);
    if (dateCompare > dateCheck) {
      return false;
    }
    return true;
  }

  showElement(event: any) {
    event.target.nextSibling.style.display = 'block';
  }

  hideElement(event: any) {
    event.target.nextSibling.style.display = 'none';
  }

  showSelectedPupil(pupil: any) {
    this.selectedPupil = pupil;
    this.adminClassService.showPupilPage();
  }

  routingBack() {
    this.adminClassService
      .getPupilPage()
      .pipe(take(1))
      .subscribe(pupilPage => {
        if (pupilPage.showPupilPage) {
          this.adminClassService.closePupilPage();
        } else {
          this.router.navigate(['/tutor']);
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.adminClassService.closePupilPage();
  }
}
