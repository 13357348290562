import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

import { IYmParams, YandexMetricsService, YmItems } from '@profilum-library';
import { NgZoneService } from '@profilum-logic-services/ng-zone-service/ng-zone-service.service';

@Directive({
  selector: '[prfYmReachGoal]',
})
export class YmReachGoalDirective implements AfterViewInit, OnDestroy {
  @Input('ymItemTarget') target: YmItems;
  @Input('ymItemParams') params: IYmParams;
  private isDisabled: boolean = false;
  @Input('ymItemDisabled') set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
    }
  }

  constructor(private elementRef: ElementRef, private ngZoneService: NgZoneService, private yandexMetricsService: YandexMetricsService) {}

  public ngAfterViewInit(): void {
    const element = this.elementRef?.nativeElement as HTMLElement;

    if (this.target && element) {
      this.ngZoneService.runOutsideZone(() => {
        element.addEventListener('click', this.sendYmMetrics);
      });
    }
  }

  public ngOnDestroy(): void {
    (this.elementRef?.nativeElement as HTMLElement)?.removeEventListener('click', this.sendYmMetrics);
  }

  private sendYmMetrics = (event: Event) => {
    if (!this.isDisabled && !(event.target as HTMLElement).hasAttribute('disabled')) {
      this.yandexMetricsService.reachGoal(this.target, this.params);
    }
  };
}
