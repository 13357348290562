import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum NotificationType {
  Common = '',
  Warning = 'warning',
}

@Component({
  selector: 'prf-notification-new',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationNewComponent {
  @Input() type: NotificationType;
  @Input() content: string;
  @Input() iconSrc: string = '';
}
