import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IVariant {
  id: number;
  text: string;
  selected: boolean;
}

@Component({
  selector: 'prf-discover-intro-recommendations',
  templateUrl: './discover-intro-recommendations.component.html',
  styleUrls: ['./discover-intro-recommendations.component.scss'],
})
export class DiscoverIntroRecommendationsComponent {
  @Input() professionName: string = '';
  @Output() forward = new EventEmitter<IVariant[]>();
  @Output() back = new EventEmitter<void>();

  public readonly introVariants: IVariant[] = [
    {
      id: 0,
      text: 'Книги',
      selected: false,
    },
    {
      id: 1,
      text: 'Фильмы',
      selected: false,
    },
    {
      id: 2,
      text: 'Экскурсия на место работы',
      selected: false,
    },
    {
      id: 3,
      text: 'Выставки, фестивали',
      selected: false,
    },
    {
      id: 4,
      text: 'Видео в соцсетях, блогеры',
      selected: false,
    },
    {
      id: 5,
      text: 'Информация в интернете',
      selected: false,
    },
    {
      id: 6,
      text: 'Кружки, секции, курсы',
      selected: false,
    },
    {
      id: 7,
      text: 'Мастер-классы',
      selected: false,
    },
    {
      id: 8,
      text: 'Уроки, исследования, проекты в школе',
      selected: false,
    },
    {
      id: 9,
      text: 'Занятия для школьников в вузах и колледжах',
      selected: false,
    },
    {
      id: 10,
      text: 'Профильные смены в лагере',
      selected: false,
    },
    {
      id: 11,
      text: 'Стажировка, практика, подработка по профессии',
      selected: false,
    },
    {
      id: 12,
      text: 'Игры, квесты',
      selected: false,
    },
    {
      id: 13,
      text: 'Разговор с представителем профессии',
      selected: false,
    },
    {
      id: 14,
      text: 'Беседа с тьютором, карьерным консультантом',
      selected: false,
    },
    {
      id: 15,
      text: 'Симуляторы, тренажёры',
      selected: false,
    },
  ];

  private MAX_AVAILABLE_AMOUNT = 3;
  private selectedVariantCounter = 0;

  public get name(): string {
    return this.professionName ? ' ' + this.professionName : '';
  }

  public get isValid(): boolean {
    return this.selectedVariantCounter > 0 && this.selectedVariantCounter <= this.MAX_AVAILABLE_AMOUNT;
  }

  public selectAnswer(index: number): void {
    const variant = this.introVariants[index];

    if (variant.selected) {
      variant.selected = !variant.selected;
      this.selectedVariantCounter -= 1;
      return;
    }
    if (this.selectedVariantCounter === this.MAX_AVAILABLE_AMOUNT) {
      // ? Добавить оповещение, что превышено количество возможных выбранных ответов
      return;
    }

    variant.selected = !variant.selected;
    this.selectedVariantCounter += 1;
  }

  public forwardButtonClick(): void {
    if (this.isValid) {
      this.forward.emit(this.introVariants.filter(variant => variant.selected));
    }
  }
}
