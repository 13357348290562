import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpService } from '@profilum-library';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class PageHeaderService {
  private showProfile = new BehaviorSubject({ showProfile: false });
  private showMobileMenu = new BehaviorSubject({ showMobileMenu: false });

  previousUrl: string;
  currentUrl: string;

  parentMessage$ = new Subject<any>();

  constructor(private http: HttpService, private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  openProfile() {
    this.showProfile.next({ showProfile: true });
  }

  closeProfile() {
    this.showProfile.next({ showProfile: false });
  }

  getProfileState(): Observable<any> {
    return this.showProfile.asObservable();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  toggleMobileMenu(bool: boolean) {
    this.showMobileMenu.next({ showMobileMenu: bool });
  }
  getMobileMenuState(): Observable<any> {
    return this.showMobileMenu.asObservable();
  }

  sendParentMessage(value) {
    this.parentMessage$.next(value);
  }
}
