import { Component, OnDestroy, OnInit } from '@angular/core';

import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

@Component({
  selector: 'prf-overlay-busy',
  templateUrl: './overlay-busy.component.html',
  styleUrls: ['./overlay-busy.component.scss'],
})
export class OverlayBusyComponent implements OnInit, OnDestroy {
  public showLoader: boolean = false;
  public text: string = 'COMMON.OVERLAY.LOADING';

  constructor(private overlayBusyService: OverlayBusyService) {}

  public ngOnDestroy(): void {
    this.overlayBusyService.unregister();
  }

  public ngOnInit(): void {
    this.overlayBusyService.register({
      show: (text: string) => (this.showLoader = true) && (this.text = text || 'COMMON.OVERLAY.LOADING'),
      hide: () => (this.showLoader = false),
    });
  }
}
