import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import {
  ApiAdminsService,
  ApiSchoolsService,
  EmptyGuid,
  IFilterClasses,
  IInstitution,
  Stands,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  CoursesFiltersService,
} from '../../../../../../shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';

export const PAGE_SIZE: number = 6;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-events-courses-archive',
  templateUrl: './events-courses-archive.component.html',
  styleUrls: ['./events-courses-archive.component.scss'],
})
export class EventsCoursesArchiveComponent implements OnInit {
  @Input() isCourses: boolean;
  @Input() viewValue;
  @Input() searches;
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  filters: IFilterClasses;
  courses: Array<any> = [];
  dataFetched: boolean = false;
  titleName: string;
  currentCourses: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  currentInstitution: IInstitution;
  loadStopper: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private filtersService: CoursesFiltersService,
    private overlayService: OverlayBusyService,
    private apiSchoolsService: ApiSchoolsService,
    private apiAdminsService: ApiAdminsService,
    private webStorageService: WebStorageService,
    private router: Router,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();
    let currentObservable$: Observable<any>;
    if (this.userRole == 'adminDO') {
      currentObservable$ = this.apiAdminsService.getInstitution().pipe(
        tap(institution => {
          this.currentInstitution = institution;
        }),
      );
    } else {
      currentObservable$ = of(null);
    }
    currentObservable$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(() => {
          this.filtersService.setCoursesFilter({});
          return this.filtersService.getCoursesFilters().pipe(
            mergeMap(data => {
              this.filters = data;
              // Блок для избежания ошибок с повторными запросами и фильтрами
              this.currentCourses = [];
              this.loadStopper = false;
              this.fromTo.currentPage = 0;

              return this.loadCourses();
            }),
          );
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCourses(): Observable<any> {
    let classesArchivedObservable$: Observable<any>;

    const path = this.router.routerState.snapshot.url;
    const filters = Object.assign({}, this.filters);
    if (this.filters && !this.filters.classesFormat) {
      if (path === '/events') {
        this.titleName = 'мероприятий';
        filters.classesFormat = [
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        ];
      }
      if (path === '/courses') {
        this.titleName = 'курсов';
        filters.classesFormat = [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse];
      }
    }
    this.filtersService.setRegionMunicipalityFilters(filters);
    if (!this.loadStopper) {
      filters.size = this.fromTo.size;
      filters.from = this.fromTo.from;
      filters.stand = Stands.Talent;
      filters.isArchived = true;
      classesArchivedObservable$ = this.apiSchoolsService.getFilteredClasses(filters).pipe(
        tap(data => {
          let courses = data.classes;
          this.loadStopper = !!(courses.length == 0 || courses.length < PAGE_LOAD);

          if (this.url === '/admin/courses/mine-courses') {
            courses = courses.filter(d => d.institutionId === EmptyGuid);
          } else if (this.url === '/adminDO/mine-courses' || this.url === '/adminDO/courses/mine-courses') {
            courses = courses.filter(d => d.institutionId === this.currentInstitution.id);
          }
          if (courses) {
            for (const item in courses) {
              if (courses) {
                this.currentCourses.push(courses[item]);
              }
            }
          }
          this.dataFetched = true;
        }),
      );
    } else {
      classesArchivedObservable$ = of(null);
    }
    return classesArchivedObservable$;
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.loadMore();
      this.cardsToShow += PAGE_SIZE;
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadCourses().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
