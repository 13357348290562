<button
  type="button"
  class="prf-button {{ type }} {{ heightType }} {{ radiusType }}"
  (click)="emitClick.emit()"
  [ngClass]="{
    compact: isCompact,
    loading: isLoading,
    transition: isTransition,
    'full-width': isFullWidth,
    'width-auto': isWidthAuto,
    'bold-text': isButtonTitleBold,
    'no-padding': isWithoutPadding,
    'cofo-sans-font': useCofoSansFont,
  }"
  [disabled]="disabled"
>
  <svg *ngIf="icon && !isLoading" class="button-icon" [ngClass]="{ 'right-icon': isIconRight }" xmlns="http://www.w3.org/2000/svg">
    <use [attr.href]="iconsPath + icon"></use>
  </svg>
  <span *ngIf="!isLoading; else loader">{{ buttonTitle }}</span>
</button>

<ng-template #loader>
  <div class="loader">
    <span class="loader-element"></span>
    <span class="loader-element"></span>
    <span class="loader-element"></span>
  </div>
</ng-template>
