import { Component, Input, OnInit } from '@angular/core';

import { CourseMaterialsTheme, ICourseMaterialsSection } from '../../../course-materials/model';
import { ILessonMaterial } from '../../interfaces/class-courses.interfaces';

@Component({
  selector: 'prf-teacher-materials-modal',
  templateUrl: './teacher-materials-modal.component.html',
  styleUrls: ['./teacher-materials-modal.component.scss'],
})
export class TeacherMaterialsModalComponent implements OnInit {
  public materialsCount: number = 0;
  protected readonly CourseMaterialsTheme = CourseMaterialsTheme;

  @Input() public lesson: ILessonMaterial;

  public ngOnInit(): void {
    this.lesson.materials.sections.forEach((section: ICourseMaterialsSection): void => {
      this.materialsCount += section.items.length;
    });
  }
}
