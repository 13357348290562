import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  ApiAdminsService,
  B2gSaasService,
  ICompletedTestsCount,
  ISchoolClass,
  IUserInfo,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ISchoolStatistic } from 'app/shared/interfaces/Ischoolstatistic.interface';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-schooladmin-classes',
  templateUrl: './schooladmin-classes.component.html',
  styleUrls: ['./schooladmin-classes.component.scss'],
})
export class SchooladminClassesComponent extends UnsubscribeComponent implements OnInit {
  @Output() showClass = new EventEmitter();

  public userRole: string = '';
  public userRestriction: boolean = false;
  private userData: IUserInfo;
  private schoolClasses: ISchoolClass[] = [];
  public teachers: Array<any> = [];
  public mergingArrays: Array<any> = [];
  private classesCompletedTestsCount: Array<ICompletedTestsCount> = [];
  public isFixed: boolean = false;
  public loadedClasses: boolean = false;
  public showDeletePopUp: boolean = false;
  public classSelectedToDelete: any = null;

  public showAction: boolean = false;

  public editClass: boolean = false;
  public selectedTeacher: ITeacher;
  public selectedClass: ISchoolClass;
  public isTestStatisticsShown: boolean = false;

  public _schoolStatisticFlag: boolean = false;
  public schoolStatistic: ISchoolStatistic;
  private callRequest$: Observable<boolean>;
  private isChanged: boolean = false;
  public tooltipTitleState = {
    show: false,
    index: null,
  };

  constructor(
    private meta: Meta,
    private apiAdminsService: ApiAdminsService,
    private webStorageService: WebStorageService,
    private overlayService: OverlayBusyService,
    private b2gSaasService: B2gSaasService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Класс школьного администратора' });
    this.userData = userDataHandlerService.getUserInfo();
    this.userRole = this.userData.role;
    this.userRestriction = userDataHandlerService.isUserRestricted();
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.isChanged) {
        this.isChanged = true;
      }
    });
    this.getClassesList();
  }

  private getClassesList(): void {
    this.overlayService.show();
    this.apiAdminsService
      .getCompletedTestsCount(this.userData.schoolId)
      .pipe(
        switchMap(completedTestsCount => {
          // список классов
          return this.apiAdminsService.getSchoolClassesExtended(this.userData.schoolId).pipe(
            switchMap(schoolClassesExtended => {
              // учителей класса
              return this.apiAdminsService.getTeachers(this.userData.schoolId).pipe(
                switchMap((teachers: any) => {
                  // статистика школы
                  return this.apiAdminsService.getSchoolStatistic(this.userData.schoolId).pipe(
                    tap((schoolStatistic: any) => {
                      this.classesCompletedTestsCount = completedTestsCount;
                      this.schoolClasses = schoolClassesExtended;
                      this.teachers = teachers;
                      this.schoolStatistic = schoolStatistic;
                      this._schoolStatisticFlag = true;

                      // объединяем массивы в один для удобства
                      this.mergingArrays = this.schoolClasses.map(a => {
                        const objectTwo = this.teachers.find(b => b.teacherClassesIds.includes(a.id));
                        if (objectTwo) {
                          Object.assign(a, objectTwo);
                        }
                        return a;
                      });

                      this.mergingArrays.sort((classA, classB) => {
                        // сортируем по классу
                        if (classA.number < classB.number) return -1;
                        if (classA.number > classB.number) return 1;
                        // сортируем по букве
                        if (classA.letter < classB.letter) return -1;
                        if (classA.letter > classB.letter) return 1;
                      });

                      // дописываем количество завершенных тестов по каждому классу
                      this.mergingArrays.forEach(item => {
                        const schoolClassTestsCount = this.classesCompletedTestsCount.find(c => c.schoolClassId === item.id);
                        item.completedTestsCount = schoolClassTestsCount != null ? schoolClassTestsCount.count : 0;
                      });

                      this.loadedClasses = true;
                    }),
                    catchError(err => {
                      this.utilsService.openSnackBar(ServerErrorMessage, 'error');
                      return throwError(err);
                    }),
                  );
                }),
              );
            }),
          );
        }),
      )
      .subscribe(
        () => this.overlayService.hide(),
        () => {
          this.overlayService.hide();
        },
      );
  }

  public routeToClass(event: any, classId: string): void {
    if (event.target.classList.value !== 'action_button' || event.target.parentElement.classList.value !== 'action_button') {
      this.router.navigate(['./schooladmin/class', classId]);
    }
  }

  public selectSchoolClassToDelete(schoolClass, event): void {
    event.stopImmediatePropagation();
    this.showDeletePopUp = true;
    this.classSelectedToDelete = schoolClass;
  }

  public closeDeletePopUp(): void {
    this.showDeletePopUp = false;
    this.classSelectedToDelete = null;
  }

  public deleteSchoolClass(schoolClassId: string): void {
    // res возвращает true или false
    this.apiAdminsService
      .removeSchoolClass(schoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.mergingArrays = this.mergingArrays.filter(p => p.id !== schoolClassId);
        this.utilsService.openSnackBar('👌 Класс удален', 'success');
        setTimeout(() => (this.showDeletePopUp = false), 500);
      });
  }

  public showActionWithClass(event: any): void {
    event.stopImmediatePropagation();
    this.checkClick(event.target);
    const actionBox = event.target.nextElementSibling;
    actionBox?.classList.add('show');
  }

  public showEditClass(classInfo: any): void {
    this.selectedTeacher = this.teachers.find((teacher: ITeacher) => {
      return (teacher.teacherId && classInfo.teacherId && teacher.teacherId === classInfo.teacherId) || teacher.userId === classInfo.userId;
    });
    this.selectedClass = {
      number: classInfo?.number,
      letter: classInfo?.letter,
      pupilsCount: classInfo?.totalPupilsCount,
      id: classInfo?.id,
    };

    this.editClass = true;
  }

  public hideEditClass(): void {
    this.editClass = false;
    this.getClassesList();
  }

  public showTooltipTitle(index: number): void {
    this.tooltipTitleState.show = true;
    this.tooltipTitleState.index = index;
  }

  public hideTooltipTitle(): void {
    this.tooltipTitleState.show = false;
    this.tooltipTitleState.index = null;
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target): void {
    const dropdownButton = document.querySelectorAll('.dropdown-wrapper');
    if (dropdownButton) {
      dropdownButton.forEach(element => {
        if (!element.contains(target)) {
          const dropdown = element.querySelector('.action_box');
          dropdown?.classList.remove('show');
        }
      });
    }
  }

  public routeToCreateClass(): void {
    this.router.navigate(['/schooladmin/new-class']);
  }
}
