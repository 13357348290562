<div class="welcome-section teacher-welc">
  <div class="welcome-row teacher-welc">
    <div class="welcome-column teacher-welc">
      <h2 class="welcome-h2">{{ 'SHARED.WELCOME' | translate }}!</h2>
      <p class="welcome-text teacher-welc">
        <span class="ent-4">Это страница вашего класса. </span>
        После регистрации учеников родителями они появятся в списке ниже.
      </p>
    </div>

    <img
      src="./profilum-assets/images/illustrations/teacher-welcome.png"
      width="497"
      srcset="
        ./profilum-assets/images/illustrations/teacher-welcome-p-500.png 500w,
        ./profilum-assets/images/illustrations/teacher-welcome-p-800.png 800w,
        ./profilum-assets/images/illustrations/teacher-welcome.png       994w
      "
      sizes="(max-width: 479px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 59vw, 497px"
      class="welcome-image teacher-welc"
    />
  </div>
</div>
