import { Directive, inject, OnInit } from '@angular/core';
import { ModalConfig } from '../../../ui-kit/components/modal/center-modal';
import { ModalHandlerService } from '../../../ui-kit/services/modal-handler/modal-handler.service';
import { AppSettingsService, StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Directive({
  selector: '[prfSuspensionMessage]',
  standalone: true,
})
export class SuspensionMessageDirective extends UnsubscribeComponent implements OnInit {
  private modalHandlerService = inject(ModalHandlerService);
  private translateService = inject(TranslateService);
  private webStorageService = inject(WebStorageService);
  private userDataHandlerService = inject(UserDataHandlerService);
  private archivedRegions = AppSettingsService.settings.archivedRegions ?? [];
  private showNotificationMessage = this.webStorageService.get<boolean>(StorageKeys.ShowSuspensionMessage);

  public ngOnInit() {
    if (this.showNotificationMessage) {
      const regionId = this.userDataHandlerService.getUserInfo().regionId;

      this.archivedRegions.includes(regionId) && this.setNotificationModal();
      this.webStorageService.set(StorageKeys.ShowSuspensionMessage, false);
    }
  }

  private setNotificationModal() {
    this.translateService
      .get('SUSPENSION_MESSAGE')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translation: string) => {
        const config: ModalConfig = {
          title: '',
          content: translation,
          iconColor: 'blue',
        };
        this.modalHandlerService.setCenterModalConfig(config);
        this.modalHandlerService.toggleCenterModal(true);
      });
  }
}
