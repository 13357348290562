<label for="circleBar" class="label">
  <div class="chart-title" *ngIf="circleTitle">
    {{ circleTitle }}
    <mat-icon *ngIf="circlebarToolTip && circlebarToolTip.length" svgIcon="info" [tp]="tooltip" [tpPlacement]="'right'"></mat-icon>
  </div>
  <section id="circleBar">
    <prf-progress-circle
      *ngIf="professionsMatching"
      [color]="'#FF5722'"
      [Percents]="professionsMatching"
      [maxPercents]="100"
      [name]="chartsProfMatchingFull"
      [tooltipText]="chartsProfMatchingTooltip"
    ></prf-progress-circle>

    <prf-progress-circle
      *ngIf="profNavigationIndex"
      [color]="'#36CA75'"
      [Percents]="profNavigationIndex"
      [maxPercents]="100"
      [name]="chartsProfNavFull"
      [tooltipText]="chartsProfnavTooltip"
    ></prf-progress-circle>

    <prf-education-circle-bar
      *ngIf="institutionIndexes && institutionIndexes.length > 0"
      [colorSet]="['#36CA75', '#7852FB', '#FF5722']"
      [data]="institutionIndexes"
      [name]="chartsEduFull"
    ></prf-education-circle-bar>
  </section>
</label>

<ng-template #tooltip>
  <prf-tooltip>{{ circlebarToolTip }}</prf-tooltip>
</ng-template>
