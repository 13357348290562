<ng-template [ngIf]="!widget" [ngIfElse]="widgetBlock">
  <div class="share-banner">
    <div class="share-banner-text">
      <div class="share-banner-title">{{ title }}</div>
      <div class="share-banner-desc" *ngIf="pupilsLength !== 1; else shortBanner">
        Скопируйте приглашение и отправьте его в «родительский» чат WhatsApp, «ВКонтакте», Viber, Telegram или другим удобным способом.
        Когда родители зарегистрируют детей, список класса вы увидите на этой странице.
      </div>
    </div>
    <div class="share-banner-button">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #widgetBlock>
  <div class="widget-wrapper" [class.small]="smallWidget">
    <div class="widget-title" *ngIf="!smallWidget">Кого-то не хватает?</div>
    <div class="widget-desc" *ngIf="!smallWidget">Скопируйте приглашение и отправьте родителям</div>
    <div class="widget-button">
      <ng-template [ngTemplateOutlet]="button"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #button>
  <button class="button green-btn" (click)="showSharePopup = !showSharePopup">Скопировать приглашение</button>
</ng-template>

<ng-template [ngIf]="showSharePopup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <ng-template [ngIf]="!linkCopied">
        <div class="popup-share">
          <div class="popup-share-header">
            <h3 class="popup-share-title">Приглашение для родителей</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">Здравствуйте, уважаемые родители!</p>
            <p class="popup-share-text">Для нашего класса бесплатно проводят тест на профориентацию.</p>
            <p class="popup-share-text">
              В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете
              посмотреть результаты.
            </p>
            <p class="popup-share-text">
              ❗️ Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.
            </p>
            <p class="popup-share-text">{{ schortRegLink }}</p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" (click)="copyPupilRegLink()">Скопировать приглашение</button>
            <button class="button cancel-btn" (click)="closePopup()">Отменить</button>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="successSend">
        <div class="popup-share done-popup">
          <div class="popup-share-header">
            <span class="popup-share-done">Готово</span>
            <h3 class="popup-share-title">Приглашение скопировано</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">
              Отправьте его в «родительский» чат и<br />
              дождитесь, когда ученики появятся в списке
            </p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" (click)="closePopup('done')">Понятно</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<!-- Loader -->
<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #shortBanner>
  <div class="share-banner-desc-short">По вашей ссылке добавляют детей. Если в списке не все, отправьте приглашение повторно.</div>
</ng-template>
