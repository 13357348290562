<section prfSuspensionMessage>
  <prf-menu [root]="'teacher'"></prf-menu>

  <div class="content-section">
    <div class="container">
      <prf-page-header [title]="''" [editButton]="false" [showClassDropdown]="teacherHasNoClasses$ | async"></prf-page-header>
      <div *ngIf="isKruzhkiVariant" class="container__title">Кружок: «Творчество как профессия»</div>
      <div class="widget" *ngIf="isFirstSession">
        <div class="widget-wrapper">
          <h3 class="widget-title">Добро пожаловать в Профилум!</h3>
          <p class="widget-desc">
            Вы зарегистрировались в системе. Выберите {{ dictionary.ClassAccusative }}, добавьте {{ dictionary.PupilsGenitive }} и
            посмотрите материалы курса карьерной грамотности.
          </p>
        </div>
      </div>
      <prf-restriction-banner *ngIf="userRestriction"></prf-restriction-banner>
      <ng-container *ngIf="!isKruzhkiVariant; else KruzhkiTemplate">
        <div class="class-list-wrapper">
          <div class="title-wrapper">
            <h3 class="title" [class.no-tabs-spacing]="!usefulMaterials?.sections?.length">Материалы курсов по профориентации</h3>
          </div>
          <div *ngIf="usefulMaterials?.sections?.length && classes?.length" class="tabs-section">
            <div
              *ngFor="let tab of TabsEnum | keyvalue"
              (click)="selectTab(tab.value)"
              class="tab"
              [ngClass]="{ active: activeTab === tab.value }"
            >
              {{ tab.value }}
            </div>
          </div>
          <div class="no-classes-banner-container" *ngIf="teacherHasNoClasses$ | async">
            <prf-teacher-with-no-classes-banner></prf-teacher-with-no-classes-banner>
          </div>
          <ng-container #content></ng-container>
        </div>
      </ng-container>
      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>

<ng-template [ngIf]="(isShowingPopup$ | async) === true">
  <prf-teacher-welcome-popup (isClosedPopup)="closePopup()"></prf-teacher-welcome-popup>
</ng-template>

<ng-template #classCardsTemplate>
  <div class="class-cards__wrapper">
    <ng-template ngFor let-classData [ngForOf]="classes">
      <prf-teacher-class-card
        class="class-card__wrapper"
        [ngClass]="{ 'full-size': classes.length === 1 }"
        [classData]="classData"
        [isOneCard]="classes.length === 1"
        (classClickEmitter)="handleClassCardClick($event)"
      ></prf-teacher-class-card>
    </ng-template>
  </div>
</ng-template>

<!--<ng-template #forParentsTemplate>
  <prf-course-materials
    [notificationText]="'В материалах для родителей есть презентации к родительским собраниям и важная информация о профориентации.'"
    [isNotificationShown]="isFirstSession"
    [noMaterialsText]="'Скоро здесь появятся материалы для родительских собраний и другие материалы для родителей о профориентации.'"
    [materials]="forParentsMaterialsMock"
  ></prf-course-materials>
</ng-template>-->

<ng-template #usefulTemplate>
  <prf-course-materials
    [notificationText]="'В полезных материалах находятся инструкции и рекомендации для преподавателей'"
    [noMaterialsText]="'Скоро здесь появятся полезные материалы  о профориентации для учителей.'"
    [isNotificationHided]="isUsefulTabTeacherNotificationHided"
    [materials]="usefulMaterials"
  ></prf-course-materials>
</ng-template>

<ng-template #KruzhkiTemplate>
  <prf-teacher-groups-banner></prf-teacher-groups-banner>
  <h3 class="teacher-groups__title">Занятия</h3>
  <div class="teacher-groups">
    <ng-container *ngFor="let group of groups">
      <prf-teacher-group-card [group]="group" (showGroup)="showDetailGroup($event)"></prf-teacher-group-card>
    </ng-container>
  </div>
</ng-template>
