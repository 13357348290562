<ng-container [ngSwitch]="schoolPortalUser">
  <ng-container *ngSwitchCase="loginCasesSP.LOGIN">
    <div class="login-section">
      <div class="login-container">
        <a routerLink="/" class="login-logo w-inline-block">
          <img class="login-logo-img" src="./assets/images/mo-landing/mo-logo.svg" alt="Profilum logo" />
        </a>

        <div class="tabs login w-tabs">
          <div class="tabs-content w-tab-content">
            <div class="w-tab-pane w--tab-active">
              <div class="tab-pane login">
                <div class="user-data-form-block w-form">
                  <form [formGroup]="form" (keyup.enter)="onClickLogin()" autocomplete="false">
                    <div class="login-form-row">
                      <label for="login" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}</label>
                      <div class="login-field-column">
                        <input
                          type="email"
                          autocomplete="false"
                          class="login-field w-input"
                          maxlength="70"
                          name="login"
                          data-name="login"
                          id="login"
                          [class.error-field]="!loginForm.login['focused'] && isLoginInvalid"
                          (focusin)="loginForm.login['focused'] = true"
                          (focusout)="loginForm.login['focused'] = false"
                          formControlName="login"
                        />
                        <mat-error *ngIf="!loginForm.login['focused'] && isLoginInvalid">
                          {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="login-form-row">
                      <label for="password" class="login-label">{{ 'SHARED.FORM.PASSWORD' | translate }}</label>
                      <div class="login-field-column">
                        <input
                          type="password"
                          autocomplete="false"
                          class="login-field w-input"
                          maxlength="25"
                          name="password"
                          data-name="password"
                          id="password"
                          [class.error-field]="!loginForm.password['focused'] && isPasswordInvalid"
                          (focusin)="loginForm.password['focused'] = true"
                          (focusout)="loginForm.password['focused'] = false"
                          formControlName="password"
                        />
                        <mat-error *ngIf="isPasswordInvalid || passFailed">
                          {{ 'LOGIN_KZ.INCORRECT_PASSWORD' | translate }}
                        </mat-error>
                        <div class="under-field-text">
                          {{ 'LOGIN_KZ.ACCESS_CODE' | translate }}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="w-center-content button-container">
                  <button
                    (click)="animateLogin()"
                    [ngClass]="{ disabled: isAccessDenied }"
                    [class.btn-waiting]="buttonStates.waiting"
                    [class.btn-activated]="buttonStates.activated"
                    [class.btn-activate]="!buttonStates.active"
                    class="login-button button-animate btn-activate"
                    id="btnActivation"
                    type="button"
                  >
                    <span class="btn-icon"></span>
                    <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.ENTRANCE' | translate">{{
                      'LOGIN_KZ.ENTER' | translate
                    }}</span>
                  </button>

                  <button class="login-button login-button_mes" type="button" (click)="mesPortalReg()">
                    <span class="schoolPortal-text">{{ 'SHARED.LOGIN_WITH_MES' | translate }}</span>
                  </button>
                </div>
                <!--                <a class="schoolPortal-button" (click)="schoolPortalReg()">-->
                <!--                  <span class="schoolPortal-text">{{ 'SHARED.LOGIN_WITH_SP' | translate }}</span>-->
                <!--                </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="loginCasesSP.REGISTER">
    <!-- регистрация ученика с ШП -->
    <ng-container *ngIf="SPUserData.role === 'pupil'">
      <div class="login-section">
        <div class="login-container">
          <a routerLink="/" class="login-logo w-inline-block">
            <img class="login-logo-img" src="./assets/images/mo-landing/mo-logo.svg" alt="Profilum logo" />
          </a>

          <div class="tabs login w-tabs">
            <div class="tab-pane login">
              <div class="user-data-form-block w-form">
                <prf-registration-pupil-mosobl [SPUserData]="SPUserData"></prf-registration-pupil-mosobl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- регистрация родителя с ШП -->
    <ng-container *ngIf="SPUserData.role === 'parent'">
      <prf-open-registration-mosobl [SPUserData]="SPUserData"></prf-open-registration-mosobl>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="loginCasesSP.UPDATE">
    <!-- обновление ученика с ШП -->
    <ng-container *ngIf="SPUserData.role === 'pupil'">
      <div class="login-section">
        <div class="login-container">
          <a routerLink="/" class="login-logo w-inline-block">
            <img class="login-logo-img" src="./assets/images/mo-landing/mo-logo.svg" alt="Profilum logo" />
          </a>

          <div class="tabs login w-tabs">
            <div class="tab-pane login">
              <div class="user-data-form-block w-form">
                <prf-update-pupil-mosobl [SPUserData]="SPUserData"></prf-update-pupil-mosobl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
