<div class="navbar adm">
  <div class="logo-div">
    <a (click)="selectMain()" class="home-link w-inline-block" [class.mo-logo]="isMOStend"></a>
  </div>
  <div *ngIf="adminLevel !== 'GLOBAL'">
    <a
      class="menu-link adm school-2 w-inline-block"
      *ngIf="checkItemHide('landing')"
      [routerLinkActive]="['w--current']"
      [class.w--current]="routeActive"
      [routerLink]="['/' + userInfo.role + '/' + homeURL]"
    >
      <div>{{ home }}</div>
    </a>
    <a
      class="menu-link adm professions w-inline-block"
      *ngIf="checkItemHide('professions')"
      [class.w--current]="professionActive"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/professions']"
    >
      <div>{{ 'HEADER.PROF' | translate }}</div>
    </a>
    <a
      class="menu-link adm courses w-inline-block"
      *ngIf="checkItemHide('courses')"
      [class.w--current]="innerRoute"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/courses']"
    >
      <div>Курсы и мероприятия</div>
    </a>

    <a
      class="menu-link adm users w-inline-block"
      *ngIf="checkItemHide('users')"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/users']"
    >
      <div>Пользователи</div>
    </a>
  </div>
  <!-- global admin -->
  <div class="menu" *ngIf="adminLevel === 'GLOBAL'">
    <a
      class="menu-link adm school-2 w-inline-block"
      *ngIf="checkItemHide('landing')"
      [routerLinkActive]="['w--current']"
      [class.w--current]="routeActive"
      [routerLink]="['/' + userInfo.role + '/' + homeURL]"
    >
      <div>{{ home }}</div>
    </a>

    <a
      class="menu-link adm _catalog w-inline-block"
      [matMenuTriggerFor]="afterMenu2"
      (menuOpened)="menuOpened()"
      [class.w--current]="
        router.url.includes('profession') ||
        router.url.includes('courses') ||
        router.url.includes('events') ||
        router.url.includes('universit') ||
        router.url.includes('specialt')
      "
    >
      Каталог
    </a>

    <mat-menu #afterMenu2="matMenu" xPosition="after">
      <button *ngIf="checkItemHide('courses')" mat-menu-item [routerLink]="['/' + userInfo.role + '/courses']">Курсы</button>
      <button *ngIf="checkItemHide('events')" mat-menu-item [routerLink]="['/' + userInfo.role + '/events']">Мероприятия</button>
      <button *ngIf="checkItemHide('professions')" mat-menu-item [routerLink]="['/' + userInfo.role + '/professions']">
        {{ 'HEADER.PROF' | translate }}
      </button>
      <button *ngIf="checkItemHide('universities')" mat-menu-item [routerLink]="['/' + userInfo.role + '/universities']">ВУЗы</button>
      <button *ngIf="checkItemHide('specialties')" mat-menu-item [routerLink]="['/' + userInfo.role + '/specialties']">Специальности</button>
    </mat-menu>

    <a
      class="menu-link adm companies w-inline-block"
      *ngIf="checkItemHide('centers') || adminRole === 'admin'"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/centers']"
    >
      <div>Центры ДО</div>
    </a>
    <a
      class="menu-link internships w-inline-block"
      *ngIf="isInternshipsReady"
      [class.w--current]="internshipActive"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/internships']"
    >
      <div>Стажировки</div>
    </a>
    <a
      class="menu-link adm users w-inline-block"
      *ngIf="checkItemHide('users')"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/users']"
    >
      <div>Пользователи</div>
    </a>

    <a
      class="menu-link adm _local_derivations w-inline-block"
      [matMenuTriggerFor]="afterMenu"
      (menuOpened)="menuOpened()"
      [class.w--current]="router.url.includes('/regions') || router.url.includes('/municipalities') || router.url.includes('/cities')"
    >
      Образования
    </a>
    <mat-menu #afterMenu="matMenu" xPosition="after">
      <button *ngIf="checkItemHide('regions') || adminRole === 'admin'" mat-menu-item [routerLink]="['/' + userInfo.role + '/regions']">
        {{ 'SHARED.REGIONS' | translate }}
      </button>
      <button
        *ngIf="checkItemHide('municipalities') || adminRole === 'admin'"
        mat-menu-item
        [routerLink]="['/' + userInfo.role + '/municipalities']"
      >
        Муниципалитеты
      </button>
      <button *ngIf="checkItemHide('cities') || adminRole === 'admin'" mat-menu-item [routerLink]="['/' + userInfo.role + '/cities']">Города</button>
    </mat-menu>

    <a
      class="menu-link adm system w-inline-block"
      *ngIf="checkItemHide('system') || adminRole === 'admin'"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/' + userInfo.role + '/system']"
    >
      <div>Система</div>
    </a>

    <!--<a class="menu-link adm users w-inline-block"
       [routerLinkActive]="['w&#45;&#45;current']"
       [routerLink]="['./news']">
      <div>Новости</div>
    </a>-->
  </div>
</div>

<!---------------------- mobile menu --------------------------------->
<div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
  <div
    [ngStyle]="{
      transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
    }"
    class="mobile-menu-container"
    style="transform-style: preserve-3d"
  >
    <div class="mobile-menu-header">
      <div class="mobile-menu-button" (click)="hideMobileMenu()">
        <div class="mobile-menu-icon-div">
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
        </div>
      </div>
      <div class="mobile-menu-logo-div">
        <a (click)="selectMain()" class="mobile-menu-logo-link w-inline-block w--current" [class.mo-logo]="isMOStend">
          <img
            src="{{
              isMOStend ? './profilum-assets/images/logos/profilum-logo.svg' : './profilum-assets/images/dashboard/profilum-new-logo.svg'
            }}"
            class="profilum-mobile-logo"
            alt="Profilum logo"
          />
        </a>
      </div>
    </div>
    <div class="mobile-user-div">
      <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
        <div class="user-dropdown-toggle">
          <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
          <div class="user-name mob">
            {{ firstName && !isMunicipalityAdmin ? firstName : 'Мой профиль' }}
          </div>
        </div>
      </a>
    </div>

    <div *ngIf="adminLevel !== 'GLOBAL'">
      <a
        class="menu-link school-2 w-inline-block"
        *ngIf="checkItemHide('landing')"
        [routerLinkActive]="['w--current']"
        [class.w--current]="routeActive"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/' + homeURL]"
      >
        <div>{{ home }}</div>
      </a>
      <a
        class="menu-link professions w-inline-block"
        *ngIf="checkItemHide('professions')"
        [class.w--current]="professionActive"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/professions']"
      >
        <div>{{ 'HEADER.PROF' | translate }}</div>
      </a>
      <a
        class="menu-link courses w-inline-block"
        *ngIf="checkItemHide('courses')"
        [class.w--current]="innerRoute"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/courses']"
      >
        <div>Курсы</div>
      </a>
      <a
        class="menu-link courses w-inline-block"
        *ngIf="checkItemHide('events')"
        [class.w--current]="innerRoute"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/events']"
      >
        <div>Мероприятия</div>
      </a>
      <a
        class="menu-link users w-inline-block"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        *ngIf="checkItemHide('users')"
        [routerLinkActive]="['w--current']"
        [routerLink]="['/' + userInfo.role + '/users']"
      >
        <div>Пользователи</div>
      </a>
    </div>
    <!-- global admin mobile menu -->
    <div class="menu-list-mobile" *ngIf="adminLevel === 'GLOBAL'">
      <a
        class="menu-link school-2 w-inline-block"
        *ngIf="checkItemHide('landing')"
        [routerLinkActive]="['w--current']"
        [class.w--current]="routeActive"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/' + homeURL]"
      >
        <div>{{ home }}</div>
      </a>

      <a
        #menuTrigger="matMenuTrigger"
        class="menu-link adm _catalog w-inline-block"
        [matMenuTriggerFor]="afterMenu2"
        (menuOpened)="menuOpened()"
        [class.w--current]="
          router.url.includes('profession') ||
          router.url.includes('cours') ||
          router.url.includes('universit') ||
          router.url.includes('specialt')
        "
      >
        Каталог
      </a>

      <mat-menu #afterMenu2="matMenu" xPosition="after">
        <button
          *ngIf="checkItemHide('courses')"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/courses']"
        >
          Курсы и мероприятия
        </button>
        <button
          *ngIf="checkItemHide('professions')"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/professions']"
        >
          {{ 'HEADER.PROF' | translate }}
        </button>

        <button
          *ngIf="checkItemHide('universities')"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/universities']"
        >
          ВУЗы
        </button>
        <button
          *ngIf="checkItemHide('specialties')"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/specialties']"
        >
          Специальности
        </button>
      </mat-menu>

      <a
        class="menu-link companies w-inline-block"
        *ngIf="checkItemHide('centers') || adminRole === 'admin'"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/centers']"
      >
        <div>Центры ДО</div>
      </a>
      <a
        class="menu-link internships w-inline-block"
        *ngIf="isInternshipsReady"
        [class.w--current]="internshipActive"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/internships']"
      >
        <div>Стажировки</div>
      </a>
      <a
        class="menu-link users w-inline-block"
        *ngIf="checkItemHide('users')"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/users']"
      >
        <div>Пользователи</div>
      </a>

      <a
        #menuTrigger2="matMenuTrigger"
        class="menu-link adm _local_derivations w-inline-block"
        [matMenuTriggerFor]="afterMenu"
        (menuOpened)="menuOpened()"
        [class.w--current]="router.url.includes('/regions') || router.url.includes('/municipalities') || router.url.includes('/cities')"
      >
        Образования
      </a>
      <mat-menu #afterMenu="matMenu" xPosition="after">
        <button
          *ngIf="checkItemHide('regions') || adminRole === 'admin'"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/regions']"
        >
          {{ 'SHARED.REGIONS' | translate }}
        </button>
        <button
          *ngIf="checkItemHide('municipalities') || adminRole === 'admin'"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/municipalities']"
        >
          Муниципалитеты
        </button>
        <button
          *ngIf="checkItemHide('cities') || adminRole === 'admin'"
          mat-menu-item
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/' + userInfo.role + '/cities']"
        >
          Города
        </button>
      </mat-menu>

      <a
        class="menu-link system w-inline-block"
        *ngIf="checkItemHide('system') || adminRole === 'admin'"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/' + userInfo.role + '/system']"
      >
        <div>Система</div>
      </a>
      <!--<a class="menu-link adm users w-inline-block"
        [routerLinkActive]="['w&#45;&#45;current']"
        [routerLink]="['./news']">
        <div>Новости</div>
      </a>-->
    </div>
  </div>
</div>
