import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService, B2gSaasService, EUserTags, StorageKeys, WebStorageService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { PlayerService } from 'app/pages/player/player.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IResult, IResultsData } from '../results.interface';
import { ResultsService } from '../results.service';
import { Location } from '@angular/common';
import { ChartConfiguration } from 'chart.js';
import { ValuesResultsTexts } from './values-results-const';
import { MatIconRegistry } from '@angular/material/icon';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { MobileInfo } from '../../../shared/interfaces/imobiledata.interface';

@Component({
  selector: 'prf-values-results-page',
  templateUrl: './values-results-page.component.html',
  styleUrls: ['./values-results-page.component.scss'],
})
export class ValuesResultsPageComponent implements OnInit {
  public userRole: string = '';
  public urlSessionId: string = '';
  public userId: string = '';
  public regionId: string = '';
  public municipalityId: string = '';

  public userProfile: any = {};
  public children: any[] = [];
  public childrenTested: any[] = [];
  private selectedChild: any = {};

  public results: any;
  public valuesResults: any[] = [];
  public highValuesResults: any[] = [];
  public middleValuesResults: any[] = [];
  public lowValuesResults: any[] = [];
  public chartData: ChartConfiguration;
  public resultsArray: Object[] = [];

  nullResults: boolean = false;
  dataLoaded: boolean = false;
  isVGuser: boolean = false;
  isMobile: boolean = false;

  // переменные для мобильной отрисовки
  mobileOpened: boolean = false;
  mobileData: MobileInfo;
  mobileImages: string[];
  mobileDataColor: string;
  lastPosition: {
    x: number;
    y: number;
  };

  hightLevelRangeString = '70-100%';
  middleLevelRangeString = '40-70%';
  lowLevelRangeString = '0-39%';

  highRangeMinimum = 70;
  middleRangeMinimum = 40;

  colorGreen = '#5ed591';
  colorYellow = '#FFDD46';
  colorRed = '#FF4B32';

  // проверка языка для регионов
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  valueDescriptionConfig: any;
  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  @ViewChild('valueCard') valueCard: ElementRef;

  resultsContainer: HTMLElement;

  constructor(
    private activatedRoute: ActivatedRoute,
    public element: ElementRef,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private resultsService: ResultsService,
    private location: Location,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private playerService: PlayerService,
    private cd: ChangeDetectorRef,
    private webStorageService: WebStorageService,
    private b2gSaasService: B2gSaasService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    this.municipalityId = this.webStorageService.get(StorageKeys.MunicipalityId);
    this.webStorageService.clear(StorageKeys.TestType);
    this.isVGuser = this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString();
  }

  ngOnInit() {
    this.overlayService.show();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
        this.getSessionResults(this.urlSessionId);
      }
    });
    this.isMobile = window.screen.width <= 768;
  }

  getSessionResults(sessionId) {
    this.dataLoaded = false;
    const getResultsObject = {
      regionId: this.regionId,
      municipalityId: this.municipalityId,
      sessionIds: [sessionId],
      role: this.userRole,
    };

    this.b2gSaasService
      .getResultPage(getResultsObject)
      .pipe(
        tap((results: IResultsData) => {
          if (results && results.results) {
            this.results = results.results;
            this.parseResults(this.results);
          }
        }),
      )
      .subscribe(() => {
        this.dataLoaded = true;
        this.overlayService.hide();
        this.cd.markForCheck();
        // if (!this.isMobile) {
        //   setTimeout(() => {
        //     this.changeMotivSection(0);
        //   }, 50);
        // }
      });
  }

  parseResults(results: IResult[]) {
    if (results && results.length) {
      let resultsData: {
        name: string;
        value: number;
      }[];
      resultsData = results
        .map(result => {
          return {
            name: result.name,
            value: result.results[0].transformedValue,
          };
        })
        .sort((a, b) => b.value - a.value);
      const labels: string[] = [];
      const valuesDataSet: number[] = [];
      const backgroundColors: string[] = [];
      const tooltips: string[] = [];

      const getShortDescription = valueName => {
        const textDataBlock = ValuesResultsTexts.find(el => el.name === valueName);
        return textDataBlock ? textDataBlock.shortDescription : null;
      };
      const getAdvice = (valueName, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.name === valueName);
        return value >= this.highRangeMinimum
          ? textDataBlock.data.hightValue.tooltipText
          : value >= this.middleRangeMinimum
          ? textDataBlock.data.middleValue.tooltipText
          : textDataBlock.data.lowValue.tooltipText;
      };
      const getAboutText = (valueName, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.name === valueName);
        return value >= this.highRangeMinimum
          ? textDataBlock.data.hightValue.about
          : value >= this.middleRangeMinimum
          ? textDataBlock.data.middleValue.about
          : textDataBlock.data.lowValue.about;
      };
      const getAboutMyselfText = (valueName, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.name === valueName);
        return value >= this.highRangeMinimum
          ? textDataBlock.data.hightValue.aboutSelf
          : value >= this.middleRangeMinimum
          ? textDataBlock.data.middleValue.aboutSelf
          : textDataBlock.data.lowValue.aboutSelf;
      };
      const getPayAttentionText = (valueName, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.name === valueName);
        return value >= this.highRangeMinimum
          ? textDataBlock.data.hightValue.payAttention
          : value >= this.middleRangeMinimum
          ? textDataBlock.data.middleValue.payAttention
          : textDataBlock.data.lowValue.payAttention;
      };

      resultsData.forEach(valueResults => {
        labels.push(valueResults.name);
        valuesDataSet.push(valueResults.value);
        if (valueResults.value >= this.highRangeMinimum) {
          backgroundColors.push(this.colorGreen);
          this.highValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.name),
            aboutText: getAboutText(valueResults.name, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.name, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.name, valueResults.value),
            advice: getAdvice(valueResults.name, valueResults.value),
            descriptionOpened: null,
          });
        } else if (valueResults.value >= this.middleRangeMinimum) {
          backgroundColors.push(this.colorYellow);
          this.middleValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.name),
            aboutText: getAboutText(valueResults.name, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.name, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.name, valueResults.value),
            advice: getAdvice(valueResults.name, valueResults.value),
            descriptionOpened: null,
          });
        } else {
          backgroundColors.push(this.colorRed);
          this.lowValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.name),
            aboutText: getAboutText(valueResults.name, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.name, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.name, valueResults.value),
            advice: getAdvice(valueResults.name, valueResults.value),
            descriptionOpened: null,
          });
        }
      });

      if (this.isMobile) {
        this.setMobileChart(labels, valuesDataSet, backgroundColors);
      }

      this.setChart(valuesDataSet, labels, backgroundColors);
    }
  }

  setChart(valuesDataSet, labels, backgroundColors) {
    const self = this;
    this.chartData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            type: 'bar',
            backgroundColor: backgroundColors,
            data: valuesDataSet,
            // barThickness: 0.9,
            // borderWidth: 5,
            // borderColor: '#000000',
            // borderCapStyle: 'round',
            // categoryPercentage: 90,
            // maxBarThickness: 100,
            // barPercentage: 0.1,
            // tooltipText: 'тест текст',
            // radius: 20,
            // hitRadius: 20,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        aspectRatio: 0.5,
        maintainAspectRatio: false,
        // responsiveAnimationDuration: 500,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'point',
            backgroundColor: 'rgba(1, 51, 112, 0.8)',
            displayColors: false,
          },
        },
        scales: {
          x: {
            position: 'bottom',
            beginAtZero: true,
            suggestedMax: 100,
            grid: {
              display: true,
            },
            ticks: {
              font: {
                family: 'Avenirnextcyr',
                size: 10,
              },
              color: '#353535',
              callback: function (tick) {
                tick = String(tick);
                return tick + '%';
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: 'Avenirnextcyr',
                size: 14,
                weight: 'normal',
              },
              color: '#333333',
            },
            afterFit: function (scale) {
              scale.width = 150;
            },
          },
        },
        onHover(e) {
          const point = this.getElementAtEvent(e);
          const el = document.getElementsByTagName('canvas')[0];
        },
      },
    };
  }

  // мобильный график
  setMobileChart(labels: string[], values: number[], backgroundColors: string[]): Object[] {
    let resultItem: {
      name: string;
      value: number;
      backgroundColor: string;
    };

    for (let i = 0; i < labels.length; i++) {
      resultItem = {
        name: labels[i],
        value: Math.round(values[i]),
        backgroundColor: backgroundColors[i],
      };
      this.resultsArray.push(resultItem);
    }
    return this.resultsArray;
  }

  openDescription(valuesResultsArray, index, el, event: MouseEvent) {
    this.resultsContainer = document.getElementsByClassName('result-container')[0] as HTMLElement;
    if (!this.isMobile) {
      [this.highValuesResults, this.middleValuesResults, this.lowValuesResults].forEach(resultsArray => {
        if (resultsArray === valuesResultsArray) {
          resultsArray.forEach((el, i) => (el.descriptionOpened = i != index ? false : true));
        } else {
          resultsArray.forEach(el => (el.descriptionOpened = false));
        }
      });
    } else {
      // mobile
      this.lastPosition = {
        x: event.pageX,
        y: event.pageY,
      };

      switch (valuesResultsArray) {
        case this.highValuesResults:
          this.mobileDataColor = this.colorGreen;
          break;
        case this.middleValuesResults:
          this.mobileDataColor = this.colorYellow;
          break;
        case this.lowValuesResults:
          this.mobileDataColor = this.colorRed;
          break;
        default:
          break;
      }
      this.mobileOpened = true;
      window.scrollTo(0, 0);
      this.mobileData = valuesResultsArray[index];
    }
    this.setImages(valuesResultsArray, index, el);
  }

  openMobile(item: Object, type: string, event: MouseEvent) {
    this.lastPosition = {
      x: event.pageX,
      y: event.pageY,
    };

    switch (type) {
      case 'high':
        this.mobileDataColor = this.colorGreen;
        break;
      case 'middle':
        this.mobileDataColor = this.colorYellow;
        break;
      case 'low':
        this.mobileDataColor = this.colorRed;
        break;
      default:
        break;
    }

    this.mobileOpened = true;
    window.scrollTo(0, 0);

    return (this.mobileData = item);
  }

  setImages(valuesResultsArray, index, el) {
    const cardContainer = el.parentNode.firstChild.nextSibling;
    const textDataBlock = ValuesResultsTexts.find(el => el.name === valuesResultsArray[index].name);
    this.mobileImages = textDataBlock.imageUrls;

    const cardValue = valuesResultsArray[index].value;
    // let imageUrl =
    //   cardValue >= this.highRangeMinimum
    //     ? textDataBlock.data.hightValue.imageUrl
    //     : cardValue >= this.middleRangeMinimum
    //     ? textDataBlock.data.middleValue.imageUrl
    //     : textDataBlock.data.lowValue.imageUrl;
    if (!this.isMobile) {
      cardContainer.children[0].children[0].children[0].children[0].children[0].children[0].setAttribute(
        'style',
        `background: #9375fb url('${textDataBlock.imageUrls[0]}') center bottom no-repeat; background-size: contain;`,
      );
      cardContainer.children[0].children[0].children[0].children[0].children[1].children[0].setAttribute(
        'style',
        `background: #496df6 url('${textDataBlock.imageUrls[1]}') center bottom no-repeat; background-size: contain;`,
      );
      cardContainer.children[0].children[0].children[0].children[0].children[2].children[0].setAttribute(
        'style',
        `background: #e48d6b url('${textDataBlock.imageUrls[2]}') center bottom no-repeat; background-size: contain;`,
      );
    } else {
    }
  }

  closeDescriptions() {
    [this.highValuesResults, this.middleValuesResults, this.lowValuesResults].forEach(resultsArray => {
      resultsArray.forEach(el => (el.descriptionOpened = null));
    });
  }

  closeMobile() {
    this.mobileOpened = false;
    this.scrollToPosition();
  }

  scrollToPosition() {
    const topPositon = this.lastPosition.y - 300;
    setTimeout(() => {
      window.scrollTo({ top: topPositon, behavior: 'smooth' });
    }, 0);
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
    if (this.isMobile && window.screen.width > 768) {
      this.isMobile = false;
    }
  }

  checkLeftOffset(element): number {
    const resultsContainer = document.getElementsByClassName('result-container')[0] as HTMLElement;
    const resultsContainerRect = resultsContainer.getBoundingClientRect();
    const cardElement = element as HTMLElement;
    const cardElementRect = cardElement.getBoundingClientRect();

    const offset = Math.abs(resultsContainerRect.left - cardElementRect.left);
    return offset;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.webStorageService.set(StorageKeys.RedirectTestType, ETestTypes.VALUES_TEST.toString());
  }

  downloadFullReport() {}
}
