import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PrfImage } from '../../components/image/image.types';

@Component({
  selector: 'prf-image',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() image: PrfImage;
  @Input() height?: string;
  @Input() width?: string;
  @Input() priority?: boolean = false;
}
