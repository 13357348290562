import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DescriptionComponent } from '../../../../components/description/description.component';
import { H3Component } from '../../../../components/h3/h3.component';
import {
  PrfExtraCurricularActivityCard,
} from '../../../../components/extracurricular-activities/extracurricular-activity-cards.types';

@Component({
  selector: 'prf-extracurricular-activity-card',
  standalone: true,
  imports: [CommonModule, H3Component, DescriptionComponent, NgOptimizedImage],
  templateUrl: './extracurricular-activity-card.component.html',
  styleUrl: './extracurricular-activity-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtracurricularActivityCardComponent {
  @Input() card: PrfExtraCurricularActivityCard;
}
