<div class="prf-popup-overlay">
  <div class="prf-popup">
    <a (click)="closePopup()" class="close-lb w-inline-block">
      <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия модального окна" />
    </a>
    <div class="prf-header prf-header_icon">
      <img class="popup-share-confirmed-icon" src="./profilum-assets/images/common/confirm-reset.svg" alt="" />
      <h3 class="prf-popup-title">Вы зарегистрировались! Осталось пригласить родителей</h3>
    </div>
    <div class="prf-popup-content">
      <p class="prf-popup-text">
        Выберите нужный класс, скопируйте и отправьте приглашение в "родительский" чат в тот мессенджер, где вы общаетесь с ними
      </p>
    </div>

    <div class="prf-popup-buttons prf-popup-buttons_column">
      <button type="button" (click)="closePopup()" class="prf-popup-button prf-popup-button_teacher">Продолжить</button>
    </div>
  </div>
</div>
