import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResultsService } from '../results.service';
import {
  ApiProfessionsService,
  ApiSchoolsService,
  ApiSearchService,
  B2gSaasService,
  IFilterClasses,
  IProfession,
  IQuestionary,
  Stands,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IResult } from '../results.interface';
import { MAX_COUNT_REQUEST, MAX_COURSES_ONPAGE, QUESTIONS } from './results-open.constants';

export interface IProfessionsByResults {
  field: IResult;
  professions: {
    secondaryEducation: Array<IProfession>;
    higherEducation: Array<IProfession>;
  };
  tab: string;
}

@Component({
  selector: 'prf-results-open',
  templateUrl: './results-open.component.html',
  styleUrls: ['./results-open.component.scss'],
})
export class ResultsOpenComponent extends UnsubscribeComponent implements OnInit {
  public sessionId: string = null;
  public municipalityId: string = null;
  public regionId: string = null;

  public professionsByResults: Array<IProfessionsByResults> = [];
  public professionsAll: Array<IProfession> = [];
  public coursesByResults: Array<any> = [];
  public questions = QUESTIONS;

  public form: UntypedFormGroup;
  public buttonBlocked: boolean = false;
  public sent: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private resultsService: ResultsService,
    private apiSearchService: ApiSearchService,
    private b2gSaasService: B2gSaasService,
    private apiProfessionService: ApiProfessionsService,
    private apiSchoolsService: ApiSchoolsService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params && params.sessionId) {
        this.sessionId = params.sessionId;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      agreementWithResults: new UntypedFormControl('', Validators.required),
      takeRecommendation: new UntypedFormControl('', Validators.required),
      testOrNot: new UntypedFormControl('', Validators.required),
    });
  }

  public sendForm(): void {
    if (this.form.valid) {
      const feedBackAnswer = {
        [this.questions.agreementWithResults]: this.form.value.agreementWithResults,
        [this.questions.takeRecommendation]: this.form.value.takeRecommendation,
        [this.questions.testOrNot]: this.form.value.testOrNot,
      };

      const surveyMo: IQuestionary = {
        openResultsMoQuestionary: {
          sessionId: this.sessionId,
          municipalityId: this.municipalityId,
          regionId: this.regionId,
          questionsAnswers: feedBackAnswer,
        },
      };
      this.b2gSaasService
        .sendSurveyForm(surveyMo)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(({ status }) => {
          if (status === 'Success') {
            this.buttonBlocked = true;
            this.sent = true;
          }
        });
    }
  }

  public setResults(results): void {
    if (results) {
      this.getProfessionsAll()
        .pipe(
          switchMap(() => {
            return this.b2gSaasService.GetRegionInfoBySession(this.sessionId);
          }),
          switchMap(res => {
            this.municipalityId = res.municipalityId;
            this.regionId = res.regionId;
            return this.getByResult(results.results);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  private getProfessionsAll(): Observable<IProfession[]> {
    return this.apiProfessionService.getElasticProfessionsByFilters({ isVisible: true }).pipe(
      tap(r => {
        this.professionsAll = r;

        this.professionsAll = this.professionsAll.map(p => {
          p.mainImagePath = p.mainImagePath ? './profilum-assets/images/profession/' + p.mainImagePath.substring(42) : null;
          return p;
        });
      }),
    );
  }

  private getByResult(results): Observable<any> {
    if (results && results.length > 0) {
      // берем результаты ребенка для рекомендаций профессий
      const childResultsIndex = results[0].results.indexOf(results[0].results.find(el => el.sessionId === this.sessionId));

      // проверка, что ребенок прошел тест
      if (childResultsIndex > -1) {
        const fieldsList = results
          .filter(d => d.objectType === 'Field')
          .sort((a, b) => {
            if (a.results[childResultsIndex] && b.results[childResultsIndex]) {
              return a.results[childResultsIndex]['transformedValue'] > b.results[childResultsIndex]['transformedValue'] ? -1 : 1;
            }
          });

        let professions = results
          .filter(d => d.objectType === 'Profession' && d.results.length)
          .sort((a, b) => {
            if (a.results[childResultsIndex] && b.results[childResultsIndex]) {
              return a.results[childResultsIndex]['transformedValue'] > b.results[childResultsIndex]['transformedValue'] ? -1 : 1;
            }
          });

        // фильтрация массива по имени на дублирующиеся профессии
        professions = professions.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);

        const professionsListByFields: Array<any> = [];

        for (let i = 0; i < 3; i++) {
          professionsListByFields.push({
            field: fieldsList[i],
            professions: professions.filter(p => this.FilterFields(p.data, fieldsList[i].name)),
          });
        }

        const professionsListByFieldsDetail: Array<any> = [];

        professionsListByFields.forEach(plf => {
          const professions = plf.professions.map(p => {
            const profession = this.professionsAll.filter(pa => pa.id === p.id);
            return profession && profession.length ? profession[0] : p;
          });

          professionsListByFieldsDetail.push({
            field: plf.field,
            professions: professions,
          });
        });
        let professionsListByFieldsDetailEducation: Array<any> = [];

        professionsListByFieldsDetail.forEach(plfd => {
          const higherEducation = plfd.professions.filter(p => p.education && p.education.some(e => e.level === 3 && e.isPreferred));
          const secondaryEducation = plfd.professions.filter(p => p.education && p.education.some(e => e.level === 2 && e.isPreferred));

          professionsListByFieldsDetailEducation.push({
            tab: '',
            field: plfd.field,
            professions: {
              secondaryEducation: secondaryEducation,
              higherEducation: higherEducation,
            },
          });
        });

        professionsListByFieldsDetailEducation = professionsListByFieldsDetailEducation.map(row => {
          row.tab = row.professions.higherEducation.length ? 'higherEducation' : 'secondaryEducation';
          return row;
        });

        this.professionsByResults = professionsListByFieldsDetailEducation;

        const resultsCoursesList = results
          .filter(d => d.objectType === 'Course')
          .sort((a, b) => {
            if (a.results[childResultsIndex] && b.results[childResultsIndex]) {
              return a.results[childResultsIndex]['transformedValue'] > b.results[childResultsIndex]['transformedValue'] ? -1 : 1;
            }
          });

        const topCourses = resultsCoursesList.slice(0, 6);

        let filters: IFilterClasses = {};
        const coursesRequestsArray$: Observable<any>[] = [];

        if (resultsCoursesList && resultsCoursesList.length) {
          topCourses.forEach(element => {
            filters = {
              courses: [element.name],
              from: 0,
              size: MAX_COUNT_REQUEST,
              municipalityId: this.municipalityId,
              regionId: this.regionId,
              stand: Stands.Talent,
            };
            coursesRequestsArray$.push(this.apiSchoolsService.getElasticFilteredClasses(filters));
          });

          return forkJoin(coursesRequestsArray$).pipe(
            tap(([first, second, third, fourth, fifth, sixth]) => {
              const arr = new Array(3).fill(null);
              const abc = [...first, ...second, ...third, ...fourth, ...fifth, ...sixth];

              if (abc.length > MAX_COURSES_ONPAGE) {
                this.coursesByResults = arr.map((el, idx) => {
                  if (idx === 0) {
                    return first.shift() ?? second.shift() ?? third.shift() ?? fourth.shift() ?? fifth.shift() ?? sixth.shift();
                  }

                  if (idx === 1) {
                    return second.shift() ?? third.shift() ?? fourth.shift() ?? fifth.shift() ?? sixth.shift() ?? first.shift();
                  }

                  if (idx === 2) {
                    return third.shift() ?? fourth.shift() ?? fifth.shift() ?? sixth.shift() ?? first.shift() ?? second.shift();
                  }
                });

                return;
              } else if (abc.length === 3) {
                this.coursesByResults = abc;
                return;
              } else {
                this.coursesByResults = abc;
                this.getCoursesMore(this.coursesByResults.length, resultsCoursesList.slice(3, MAX_COURSES_ONPAGE)).then(
                  coursesArrayMore => {
                    this.coursesByResults = [...this.coursesByResults, ...coursesArrayMore];
                    this.coursesByResults.splice(MAX_COURSES_ONPAGE);

                    // если нет курсов по сферам, то запрашиваем без сфер
                    if (this.coursesByResults.length < MAX_COURSES_ONPAGE) {
                      const countCoursesAdd = MAX_COURSES_ONPAGE - this.coursesByResults.length;
                      filters.courses = [];
                      filters.size = countCoursesAdd;

                      this.apiSchoolsService
                        .getElasticFilteredClasses(filters)
                        .pipe(
                          tap((moreCourses: any) => {
                            this.coursesByResults = [...this.coursesByResults, ...moreCourses];
                          }),
                          takeUntil(this.unsubscribe),
                        )
                        .subscribe();
                    }
                  },
                );
                return;
              }
            }),
          );
        } else {
          return this.apiSearchService.searchCourses().pipe(
            switchMap(res => {
              const allCourses = res.sort(() => Math.random() - 0.5).slice(0, 6);

              allCourses.forEach(element => {
                const filtersCourses: IFilterClasses = {
                  courses: [element.name],
                  from: 0,
                  size: MAX_COUNT_REQUEST,
                  municipalityId: this.municipalityId,
                  regionId: this.regionId,
                  stand: Stands.Talent,
                };
                coursesRequestsArray$.push(this.apiSchoolsService.getElasticFilteredClasses(filtersCourses));
              });
              filters.courses = [...allCourses.map(talent => talent.name)];

              return forkJoin(coursesRequestsArray$).pipe(
                switchMap((coursesArrays: any[]) => {
                  let summaryCoursesArray = [];
                  coursesArrays.forEach(array => (summaryCoursesArray = [...summaryCoursesArray, ...array]));
                  this.coursesByResults = summaryCoursesArray.slice(0, MAX_COURSES_ONPAGE);

                  if (this.coursesByResults.length < MAX_COURSES_ONPAGE) {
                    const moreCoursesNumber = MAX_COURSES_ONPAGE - this.coursesByResults.length;
                    const moreCoursesFilters: IFilterClasses = {
                      from: 0,
                      size: moreCoursesNumber,
                      municipalityId: this.municipalityId,
                      regionId: this.regionId,
                      stand: Stands.Talent,
                    };
                    return this.apiSchoolsService.getElasticFilteredClasses(moreCoursesFilters).pipe(
                      tap((moreCourses: any) => {
                        this.coursesByResults = [...this.coursesByResults, ...moreCourses];
                      }),
                    );
                  } else {
                    return of(null);
                  }
                }),
              );
            }),
          );
        }
      }
    } else {
      return of(null);
    }
  }

  async getCoursesMore(count, array): Promise<IFilterClasses[]> {
    let coursesArrayCount = count;
    let inc = 0;
    let coursesArray = [];

    const filtersCourses: IFilterClasses = {
      from: 0,
      size: MAX_COUNT_REQUEST,
      municipalityId: this.municipalityId,
      regionId: this.regionId,
      stand: Stands.Talent,
    };

    do {
      filtersCourses.courses = [array[inc].name];
      await this.apiSchoolsService
        .getElasticFilteredClasses(filtersCourses)
        .pipe(
          tap((courses: any) => {
            if (courses.length) {
              coursesArrayCount += courses.length;
              coursesArray = [...coursesArray, ...courses];
            }
          }),
        )
        .toPromise();
      inc++;
    } while (coursesArrayCount < MAX_COURSES_ONPAGE);

    return coursesArray;
  }

  private FilterFields(dataFields, fieldName): boolean {
    for (const i in dataFields.fieldNames) {
      if (dataFields.fieldNames[i] === fieldName) {
        return true;
      }
    }
    return false;
  }

  public removeTags(test: string): string {
    if (test && test.length) {
      return test.replace(/<p>/gi, '').replace(/<\/p>/gi, '');
    }

    return '';
  }
}
