<div class="prf-image-cropper">
  <div class="image-cropper" #cropperContainer>
    <div class="circle-mask" #cropperFrame>
      <div class="corner top-left"></div>
      <div class="corner top-right"></div>
      <div class="corner bottom-left"></div>
      <div class="corner bottom-right"></div>
    </div>
  </div>
</div>
