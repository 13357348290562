import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'prf-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent {
  @Input() color: string = '#FFD600';
  percents: number;
  @Input() set Percents(val: number) {
    this.percents = val;
    this.init();
  }
  @Input() maxPercents: number = 100;
  @Input() name: string = '';
  @Input() tooltipText: string = '';

  isEmpty: boolean = false;

  constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/info.svg'));
  }

  init() {
    this.isEmpty = (typeof this.percents !== 'number' && this.percents < 0) || !this.percents;
  }
}
