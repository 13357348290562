import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prf-notification-course-materials',
  templateUrl: './notification-course-materials.component.html',
  styleUrls: ['./notification-course-materials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCourseMaterialsComponent {
  @Input({ required: true }) public text: string;

  @Output() public closeNotificationEmitter: EventEmitter<void> = new EventEmitter<void>();

  public hideNotification(): void {
    this.closeNotificationEmitter.emit();
  }
}
