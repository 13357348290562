import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import {
  InternshipsFiltersService,
} from '../../../../pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { ProfessionFilter } from '@profilum-library';

@Component({
  selector: 'prf-profession-filter',
  templateUrl: './profession-filter.component.html',
  styleUrls: ['./profession-filter.component.scss'],
})
export class ProfessionFilterComponent {
  @Input() set categoryList(val: Array<ProfessionFilter>) {
    if (val) {
      this._categoryList = val.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }

  get categoryList() {
    return this._categoryList;
  }

  @Output() selectedCategory = new EventEmitter<ProfessionFilter>();

  public isOpen: boolean = false;
  public selectedCategoryItem: ProfessionFilter = new ProfessionFilter();
  public filterName: string = '';
  private _categoryList: ProfessionFilter[] = [];

  @ViewChild('container') container: ElementRef;

  @HostListener('document:click', ['$event.target'])
  clickedOutside(targetElement) {
    if (this.container && !this.container?.nativeElement.contains(targetElement)) {
      this.isOpen = false;
    }
  }

  constructor(private internshipsFiltersService: InternshipsFiltersService, private filtersService: CoursesFiltersService) {}

  public clearFilterName($event: Event) {
    this.filterName = '';
    $event.preventDefault();
    $event.stopPropagation();
  }

  public onClickClear() {
    this.selectedCategoryItem = new ProfessionFilter();
    this.filterName = '';
    this.categoryList.forEach(_item => (_item.selected = false));
    this.selectedCategory.emit(this.selectedCategoryItem);
    this.isOpen = false;
  }

  public onSelectItem(item: ProfessionFilter) {
    item.selected = !item.selected;
    this.categoryList.forEach(_item => (_item != item ? (_item.selected = false) : null));
    this.selectedCategoryItem = this.categoryList.find(_item => _item.selected === true);
    this.selectedCategory.emit(this.selectedCategoryItem);
    this.isOpen = false;
  }

  getFilterTitle(n: number) {
    let categoryTitles = ['категория', 'категории', 'категорий'];
    return n + ' ' + this.internshipsFiltersService.getTitleByNumber(n, categoryTitles);
  }
}
