import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H3Component } from '../../../../components/h3/h3.component';
import { PrfSliderCard } from '../../../../components/diagnostics-of-interests/diagnostics-of-interests.types';
import { ImageComponent } from '../../../../components/image/image.component';

@Component({
  selector: 'prf-diagnostics-of-interests-card',
  standalone: true,
  imports: [CommonModule, H3Component, ImageComponent],
  templateUrl: './diagnostics-of-interests-card.component.html',
  styleUrl: './diagnostics-of-interests-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsOfInterestsCardComponent {
  @Input() card: PrfSliderCard;
}
