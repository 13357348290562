<div class="choose-prof">
  <prf-prof-navigation
    [amount]="professions.length"
    [counter]="counter"
    [title]="title"
    [tabs]="tabs"
    (selectNewTab)="onSelectNewTab($event)"
  >
  </prf-prof-navigation>
  <div class="profession-card_wrapper" *ngIf="!loadingTinder; else loadingTemplate">
    <prf-prof-card
      class="profession-card card-snapshot"
      [cardData]="professionSnapshot"
      [loading]="loading"
      [ngClass]="{
        'profession-card_positive-selection': currentProfessionFavourite === 'like',
        'profession-card_negative-selection': currentProfessionFavourite === 'dislike'
      }"
    >
    </prf-prof-card>
    <prf-prof-card #professionCard class="profession-card scrollable-container" [cardData]="currentProfession" [loading]="loading">
    </prf-prof-card>
  </div>

  <prf-prof-actions
    class="actions"
    [counter]="professionIndex"
    [favourite]="currentProfession?.isFavourite"
    (action)="onAction($event)"
    (sendBack)="onSendBack()"
  >
  </prf-prof-actions>
</div>

<ng-template #loadingTemplate>
  <div class="loading-tinder"></div>
</ng-template>

<prf-notification-popup
  *ngIf="modalNotification"
  [headerText]="modalNotification.HEADER_TEXT"
  [mainText]="modalNotification.MAIN_TEXT"
  [mainButtonText]="modalNotification.MAIN_BUTTON_TEXT"
  [secondaryButtonText]="modalNotification.SECONDARY_BUTTON_TEXT"
  [mainButtonColor]="modalNotification.MAIN_BUTTON_COLOR"
  (buttonAction)="toggleNotification()"
>
</prf-notification-popup>
