<div class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
    </a>

    <div class="tabs login w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a [routerLink]="['/prosvetlogin']" class="tab-link login w-inline-block w-tab-link">
          <div>{{ 'SHARED.ENTRANCE' | translate }}</div>
          <img src="./profilum-assets/images/icons/angle.png" width="20" class="angle" />
        </a>
        <a [routerLink]="['/prosvetregistration']" class="tab-link login w-inline-block w-tab-link w--current">
          <div>{{ 'SHARED.REGISTRATION' | translate }}</div>
          <img src="./profilum-assets/images/icons/angle2.png" width="20" class="angle _2" />
        </a>
      </div>

      <div class="w-tab-pane w--tab-active">
        <div class="tab-pane login">
          <div class="user-data-form-block w-form">
            <form [formGroup]="form" name="wf-form-Login" autocomplete="off">
              <div class="login-form-row reg-form">
                <div class="login-form-column">
                  <a routerLink="/prosvetregistration" class="reg-form-back-button">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
                </div>
                <div class="login-field-column reg-form">
                  <img src="./profilum-assets/images/icons/reg-parent.svg" class="registration-link-image reg-form" />
                  <div class="registration-link-text reg-form">Родитель</div>
                </div>
              </div>
              <div class="login-form-row">
                <label for="lastName" class="login-label">{{ 'SHARED.FORM.FAMILY' | translate }}</label>
                <div class="login-field-column">
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.lastName.errors"
                    [attr.disabled]="f.lastName.status === 'DISABLED' ? true : null"
                    maxlength="25"
                    name="lastName"
                    data-name="lastName"
                    id="lastName"
                    formControlName="lastName"
                  />
                  <ng-container *ngIf="submitted && f.lastName.errors">
                    <mat-error *ngIf="f.lastName.errors.required">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="firstName" class="login-label">{{ 'SHARED.FORM.NAME' | translate }}</label>
                <div class="login-field-column">
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.firstName.errors"
                    [attr.disabled]="f.firstName.status === 'DISABLED' ? true : null"
                    maxlength="25"
                    name="firstName"
                    data-name="firstName"
                    id="firstName"
                    formControlName="firstName"
                  />
                  <ng-container *ngIf="submitted && f.firstName.errors">
                    <mat-error *ngIf="f.firstName.errors.required">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="middleName" class="login-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</label>
                <div class="login-field-column">
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.middleName.errors"
                    [attr.disabled]="f.middleName.status === 'DISABLED' ? true : null"
                    maxlength="25"
                    name="middleName"
                    data-name="middleName"
                    id="middleName"
                    formControlName="middleName"
                  />
                  <ng-container *ngIf="submitted && f.middleName.errors">
                    <mat-error *ngIf="f.middleName.errors.required">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="phone" class="login-label">{{ 'SHARED.FORM.PHONE' | translate }}</label>
                <div class="login-field-column">
                  <input
                    type="tel"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.phone.errors"
                    [attr.disabled]="f.phone.status === 'DISABLED' ? true : null"
                    name="phone"
                    data-name="phone"
                    id="phone"
                    [textMask]="{ mask: mask }"
                    formControlName="phone"
                  />
                  <ng-container *ngIf="submitted && f.phone.errors">
                    <mat-error *ngIf="f.phone.errors.maxLength"> Номер телефона слишком длинный </mat-error>
                    <mat-error *ngIf="f.phone.errors.pattern"> Проверьте правильность номера телефона </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="email" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}</label>
                <div class="login-field-column">
                  <input
                    type="email"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="(submitted && f.email.errors) || (submitted && duplicateUserName)"
                    [attr.disabled]="f.email.status === 'DISABLED' ? true : null"
                    maxlength="70"
                    name="email"
                    data-name="email"
                    id="email"
                    formControlName="email"
                    (focusout)="checkFormatEmail($event)"
                  />
                  <ng-container>
                    <mat-error *ngIf="!checkEmail">
                      {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
                    </mat-error>
                    <mat-error *ngIf="submitted && duplicateUserName">
                      {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.EMAIL_EXIST' | translate }}
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row" *ngIf="!prosvetUserRegistration">
                <label for="password" class="login-label">{{ 'SHARED.FORM.PASSWORD' | translate }}</label>
                <div class="form-full-width">
                  <div class="login-field-column" (focusout)="focusOutErrorChecking()" (focusin)="focusOutPasswordErrors = false">
                    <input
                      *ngIf="isMaskedPassword"
                      autocomplete="new-password"
                      type="password"
                      class="login-field w-input short-right"
                      [class.error-field]="submitted && f.password.errors"
                      maxlength="25"
                      name="password"
                      data-name="password"
                      id="password"
                      formControlName="password"
                      (ngModelChange)="isValidPassword($event)"
                      (focus)="popupPassword.style.display = 'block'"
                      (focusout)="popupPassword.style.display = 'none'"
                    />
                    <input
                      *ngIf="!isMaskedPassword"
                      autocomplete="new-password"
                      type="text"
                      class="login-field w-input short-right"
                      [class.error-field]="submitted && f.password.errors"
                      maxlength="25"
                      name="password"
                      data-name="password"
                      id="passwordUnMask"
                      formControlName="password"
                      (ngModelChange)="isValidPassword($event)"
                      (focus)="popupPassword.style.display = 'block'"
                      (focusout)="popupPassword.style.display = 'none'"
                    />
                    <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                    <div
                      class="info-popup recommendation w-hidden-small w-hidden-tiny hide-popup"
                      #popupPassword
                      [style.display]="isNotValidPassword ? 'block' : 'none'"
                    >
                      <p>Требования к паролю (не менее)</p>
                      <ol class="info-exacting">
                        <li class="info-pswrd-icon info-popup-pswrd" [class.not-correct]="isCharactersError">
                          {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.6_CHARACTERS' | translate }}
                        </li>
                        <li class="info-pswrd-icon info-popup-letter" [class.not-correct]="isLetterError">1 строчная буква</li>
                        <li class="info-pswrd-icon info-popup-digit" [class.not-correct]="isNumberError">1 цифра</li>
                      </ol>
                      <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                    </div>
                  </div>
                  <div class="error-field-container" *ngIf="focusOutPasswordErrors">
                    <div>{{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_FORMAT' | translate }}</div>
                    <ul>
                      <li *ngIf="isCharactersError">
                        {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.LESS_6_CHARACTERS' | translate }}
                      </li>
                      <li *ngIf="isLetterError">
                        {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_LOWERCASE_LETTER' | translate }}
                      </li>
                      <li *ngIf="isNumberError">
                        {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_DIGIT' | translate }}
                      </li>
                      <li *ngIf="isWhiteSpaceError">
                        {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_CHARACTERS' | translate }}
                      </li>
                      <li *ngIf="isRusLettersError">
                        {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.RUS_LETTERS' | translate }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <prf-dropdown-with-search
                [formControl]="form.controls['city']"
                [label]="'SHARED.FORM.CITY' | translate"
                [options]="cities"
                [isError]="submitted && f.city.errors"
              >
              </prf-dropdown-with-search>
              <div class="terms">
                <div class="checkbox-terms-grn" [class.checked-terms-grn]="personalTerms" (click)="personalTerms = !personalTerms"></div>
                <span class="terms-text"
                  >{{ 'SHARED.AGREEMENT.ACCEPT_TEXT' | translate }}
                  <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank">{{
                    'SHARED.AGREEMENT.USER_CONFIRMATION' | translate
                  }}</a>
                </span>
              </div>

              <div class="w-center-content">
                <a
                  (click)="animateLogin()"
                  [ngClass]="{ disabled: !isAccessAllowed }"
                  [class.btn-waiting]="buttonWaiting"
                  [class.btn-activated]="buttonActivated"
                  [class.btn-activate]="!buttonActivate"
                  class="button-animate btn-activate"
                  id="btnActivation"
                >
                  <span class="btn-icon"></span>
                  <span class="btn-text" data-wait="Подождите" data-after="Зарегистрированы">{{ 'SHARED.REGISTER' | translate }}</span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
