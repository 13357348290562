import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RectangleViewComponent } from '../rectangle-view/rectangle-view.component';
import { ReplaySubject } from 'rxjs';
import { SharedModule } from '../../../../shared/shared.module';
import { SlideService } from '../../../../shared/services/slide.service';
import { ActivatedRoute, Params } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ETestTypesSteps } from '../../../../shared/enums/testtypes.enum';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { QuestionTypes } from '../collections/prf-tests.collections';
import { IAnswerInfo, ISectionInfo } from '../interfaces/prf-tests.interface';
import { IStorageHelper, StorageHelper, StorageKeys, Storages } from '@profilum-library';

@Component({
  selector: 'prf-rectangle-box',
  standalone: true,
  imports: [CommonModule, RectangleViewComponent, SharedModule],
  templateUrl: './rectangle-box.component.html',
  styleUrls: ['./rectangle-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RectangleBoxComponent extends UnsubscribeComponent implements OnInit {
  @Input() public sectionInfo: ISectionInfo;
  @Input() public hideHeader: boolean = false;
  @Input() public limitSelections: number = 0;
  public updateSelections: ReplaySubject<string> = new ReplaySubject<string>(1);
  public slideNum: number;
  public slidesNumber: string = '';
  private readonly storageHelper: IStorageHelper = new StorageHelper(Storages.Local);

  constructor(private slideService: SlideService, private activatedRoute: ActivatedRoute) {
    super();
    if (!this.hideHeader) {
      this.slidesNumber = this.storageHelper.get(StorageKeys.SlidesNumber);
      this.slidesNumber = this.slidesNumber ? this.slidesNumber : ETestTypesSteps.DEFAULT_STANDART.toString();
    }
  }

  public ngOnInit(): void {
    if (!this.hideHeader) {
      this.activatedRoute.params
        .pipe(
          map((params: Params) => params['ssid'] as string),
          takeUntil(this.unsubscribe),
        )
        .subscribe((sessionId: string) => {
          this.slideNum = this.slideService.getCurrenSlideNumber(this.sectionInfo.sectionId, sessionId);
        });
    }
  }

  public selectAnswer(event: IAnswerInfo): void {
    this.selectLimitedCountOfAnswers(event);

    if (this.sectionInfo.questionType === QuestionTypes.Single) {
      this.sectionInfo.elements?.forEach((element: IAnswerInfo) => {
        if (event.answerId !== element.answerId && element.sectionId === this.sectionInfo.sectionId) {
          element.sectionId = null;
          element.sectionType = null;
          this.updateSelections.next(element.answerId);
        }
      });
    }
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }

  public selectLimitedCountOfAnswers(event: IAnswerInfo): void {
    if (!(this.limitSelections > 0)) {
      return;
    }

    const selectedAnswers: IAnswerInfo[] = this.sectionInfo.elements?.filter((element: IAnswerInfo) => {
      return element.sectionId === this.sectionInfo.sectionId;
    });

    if (selectedAnswers?.length > this.limitSelections) {
      event.sectionId = null;
      event.sectionType = null;
      this.updateSelections.next(event.answerId);
    }
  }
}
