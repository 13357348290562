<div *ngIf="showProfessionsChoices" class="choices-professions">
  <ul class="choices-professions__list scrollable-container">
    <li
      *ngFor="let choosesProfession of choosesProfessions; let index = index"
      (click)="setActiveField(index)"
      [ngClass]="{ 'choices-professions__list_active': activeFieldIndex === index }"
    >
      <span [title]="choosesProfession.fieldName" class="field-name truncation">
        {{ choosesProfession.fieldName }}
      </span>
      <span
        class="field-counter"
        *ngIf="choosesProfession.assignedPupilsCount"
        [innerText]="choosesProfession.assignedPupilsCount > 0 ? choosesProfession.assignedPupilsCount : ''"
      ></span>
      <div
        class="choices-professions__list-not-passed"
        [class.choices-professions__list-not-passed-active]="activeFieldIndex === index"
        *ngIf="passedLessonsByFieldId[choosesProfession.fieldId] && !choosesProfession.assignedPupilsCount"
      ></div>
      <div class="choices-professions__list-new-passing-counter" *ngIf="choosesProfession.newPupilsCount > 0">
        {{ choosesProfession.newPupilsCount }}
      </div>
    </li>
  </ul>
  <div class="choices-professions__body scrollable-container">
    <div class="choices-professions__body__title">
      {{ choosesProfessions[activeFieldIndex].fieldName }}
      <div *ngIf="choosesProfessions[activeFieldIndex].assignedPupilsCount" class="choices-counter">
        <span class="choices-counter__counter">
          <strong [innerText]="choosesProfessions[activeFieldIndex].assignedPupilsCount"></strong>
          из {{ choosesProfessions[activeFieldIndex].allPupils }}</span
        >
        <span
          [innerText]="choosesProfessions[activeFieldIndex].assignedPupilsCount === 1 ? 'сделал выбор' : 'сделали выбор'"
          class="choices-counter__desc"
        ></span>
      </div>
    </div>
    <div class="choices-professions__body__support-text">
      <div
        *ngIf="
          choosesProfessions[activeFieldIndex].assignedPupilsCount > 0 &&
          choosesProfessions[activeFieldIndex].professionsCounts[0]?.count === 0
        "
        class="support-text no-choices"
      >
        {{ dictionary.Pupils | titlecase }} не выбрали профессии из этой отрасли
      </div>
      <div *ngIf="!passedLessonsByFieldId[choosesProfessions[activeFieldIndex].fieldId]" class="support-text no-passed">
        Задание не выдано
      </div>
      <div
        *ngIf="
          passedLessonsByFieldId[choosesProfessions[activeFieldIndex].fieldId] &&
          choosesProfessions[activeFieldIndex].assignedPupilsCount === 0
        "
        class="support-text__no-results"
      >
        <img src="/profilum-assets/images/icons/passed-not-started.svg" />
        Выдано. Результаты — после прохождения задания и подсчёта статистики.
      </div>
    </div>
    <div
      *ngFor="let professionsCount of choosesProfessions[activeFieldIndex].professionsCounts; let index = index"
      [ngStyle]="{ display: !seeMore && index > 5 ? 'none' : null }"
      class="choices-professions__body__graphs"
    >
      <div class="choices-professions__body__graphs__progress-bar__container">
        <span class="choices-professions__body__graphs__progress-bar__label">{{ professionsCount.name }}</span>
        <div class="choices-professions__body__graphs__progress-bar">
          <div
            class="choices-professions__body__graphs__progress-bar__counter"
            [style.width]="((professionsCount.count / choosesProfessions[activeFieldIndex].allPupils) * 100).toString() + '%'"
          ></div>
        </div>
      </div>
      <div
        class="choices-counter"
        [innerText]="
          professionsCount.count +
          ' ' +
          declOfNum(professionsCount.count, [dictionary.Pupil, dictionary.PupilGenitive, dictionary.PupilsGenitive])
        "
        [class.choices-counter_active]="professionsCount.count > 0"
      ></div>
    </div>
    <span *ngIf="!seeMore; else collapse" (click)="seeMoreProfessions()" class="choices-professions__body__collapse-button collapsed">
      Показать больше
    </span>
    <ng-template #collapse>
      <span (click)="seeLessProfessions()" class="choices-professions__body__collapse-button"> Свернуть </span>
    </ng-template>
  </div>
</div>
