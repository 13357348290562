import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminClassService {
  private showPupil = new BehaviorSubject({ showPupilPage: false });

  public responseCache = new Map();
  public clearCacheTeacher: boolean = false;

  showPupilPage() {
    this.showPupil.next({ showPupilPage: true });
  }

  closePupilPage() {
    this.showPupil.next({ showPupilPage: false });
  }

  getPupilPage(): Observable<{ showPupilPage: boolean }> {
    return this.showPupil.asObservable();
  }
}
