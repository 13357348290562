<div *ngIf="loadedCategory" class="prof-mobile-catalog">
  <ng-container *ngIf="selectProfession">
    <div class="profession">
      <div class="profession-container">
        <prf-profession-mobile
          [profession]="selectProfession"
          [favorite]="!!favourites[selectProfession.id]"
          [professionTryingOnStage]="!!professionTryingOnStage"
          [recommended]="recommended[selectProfession.id] && !isBvb"
          [capabilities]="recommendedCapabilities[selectProfession.id] && !isBvb"
          (closeProfession)="showDetailProfession($event)"
          (updateFavoriteProfession)="updateFavorites()"
        ></prf-profession-mobile>
      </div>
      <div class="profession-buttons">
        <button class="profession-buttons-back" (click)="showDetailProfession(null)">Назад</button>
        <button
          *ngIf="!favourites[selectProfession.id] && !professionTryingOnStage"
          class="profession-buttons-like"
          (click)="updateFavorites()"
        >
          <img src="./profilum-assets/images/common/prof-favourite.svg" class="profession-buttons-like-img" />
          Нравится
        </button>
        <button
          *ngIf="!!favourites[selectProfession.id] && !professionTryingOnStage"
          class="profession-buttons-dislike"
          (click)="updateFavorites()"
        >
          <img src="./profilum-assets/images/common/prof-dislike.svg" class="profession-buttons-like-img" />
          Не нравится
        </button>
      </div>
    </div>
  </ng-container>
  <div class="prof-mobile-catalog-container" *ngIf="!showBvbRecommendations; else bvbRecommendations">
    <div class="prof-mobile-catalog-header">
      <h3 class="prof-mobile-catalog-header-title">Каталог профессий</h3>
    </div>
    <ng-container *ngIf="!showFilters; else filters">
      <div class="prof-mobile-catalog-header-actions">
        <prf-professions-mobile-search
          class="prof-mobile-catalog-header-actions-search"
          [searchClear]="searchClear"
          (searchesData)="dataSearch($event)"
        ></prf-professions-mobile-search>
        <img
          class="prof-mobile-catalog-header-actions-filter"
          src="./profilum-assets/images/profession/filter.svg"
          (click)="showFilter()"
        />
      </div>
      <div class="prof-mobile-catalog-header-filters">
        <div class="selected_capabilities" *ngIf="filtersState.capabilities">
          Способности
          <img
            class="prof-mobile-catalog-header-like-close"
            src="./profilum-assets/images/common/close-white.svg"
            (click)="closeRecommended('capabilities')"
          />
        </div>
        <div class="selected_interests" *ngIf="filtersState.interests">
          Интересы
          <img
            class="prof-mobile-catalog-header-like-close"
            src="./profilum-assets/images/common/close-white.svg"
            (click)="closeRecommended('interests')"
          />
        </div>
        <div class="prof-mobile-catalog-header-like" *ngIf="filtersState.favourite">
          <img class="prof-mobile-catalog-header-like-img" src="./profilum-assets/images/common/like-filter.svg" />
          <img
            class="prof-mobile-catalog-header-like-close"
            src="./profilum-assets/images/common/close-like.svg"
            (click)="closeOnlyFavorites()"
          />
        </div>
        <div class="prof-mobile-catalog-header-filter" *ngIf="filtersState.category && filtersState.appliedCategories.length">
          {{
            filtersState.appliedCategories.length === 1
              ? filtersState.appliedCategories[0].name
              : 'Отрасли (' + filtersState.appliedCategories.length + ')'
          }}
          <img
            src="./profilum-assets/images/common/close-filter.svg"
            class="prof-mobile-catalog-header-filter-close"
            (click)="resetFilters()"
          />
        </div>
      </div>
      <ng-container *ngIf="(!professionListFiltered || professionListFiltered.length === 0) && loading; else professionsList">
        <prf-mobile-loaders class="loaders"></prf-mobile-loaders>
      </ng-container>

      <ng-template #professionsList>
        <ng-container *ngIf="isSearchList">
          <ng-container *ngIf="searchList.length || (searchCategoryList.length && !applyFilters.favourite); else emptySearch">
            <ng-container *ngIf="searchList.length">
              <div class="prof-mobile-catalog-header-length">
                {{ searchList.length }}
                {{ searchList.length | numerals : ['профессия', 'профессии', 'профессий'] }}
              </div>
              <div class="prof-list_wrapper scrollable-container">
                <div class="prof-list">
                  <prf-profession-mobile-card
                    *ngFor="let profession of searchList"
                    [favourite]="!!favourites[profession.id]"
                    [recommended]="recommended[profession.id] && !isBvb"
                    [capabilities]="recommendedCapabilities[profession.id] && !isBvb"
                    [profession]="profession"
                    [professionTryingOnStage]="professionTryingOnStage"
                    (updateFavourites)="updateFavorites($event)"
                    (showProfession)="showDetailProfession($event)"
                    [profCatalog]="true"
                  >
                  </prf-profession-mobile-card>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="searchCategoryList.length && !applyFilters.favourite">
              <div class="prof-mobile-catalog-header-length">
                {{ searchCategoryList.length }}
                {{ searchCategoryList.length | numerals : ['отрасль', 'отрасли', 'отраслей'] }}
              </div>
              <div class="prof-list-wrapper scrollable-container">
                <div *ngFor="let category of searchCategoryList" class="prof-list">
                  <div class="prof-list-title" (click)="openCategory(category)">
                    <img src="./profilum-assets/images/common/arrow-prof.svg" [ngClass]="{ opened: !category.isOpen }" />
                    <div class="prof-list-title-text">{{ category.name }}</div>
                  </div>
                  <div *ngIf="category.isOpen" class="prof-list-cards">
                    <prf-profession-mobile-card
                      *ngFor="let profession of professionsByCategory[category.name]"
                      [favourite]="!!favourites[profession.id]"
                      [recommended]="recommended[profession.id] && !isBvb"
                      [capabilities]="recommendedCapabilities[profession.id] && !isBvb"
                      [professionTryingOnStage]="professionTryingOnStage"
                      [profession]="profession"
                      (updateFavourites)="updateFavorites($event)"
                      (showProfession)="showDetailProfession($event)"
                      [profCatalog]="true"
                    >
                    </prf-profession-mobile-card>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isSearchList">
          <div class="prof-mobile-catalog-header-length">
            {{ isFilters ? sortProfessionsList.length : professionListFiltered.length }}
            {{
              isFilters
                ? (sortProfessionsList.length | numerals : ['профессия', 'профессии', 'профессий'])
                : (professionListFiltered.length | numerals : ['профессия', 'профессии', 'профессий'])
            }}
          </div>
          <div class="prof-list-wrapper scrollable-container">
            <div *ngFor="let category of isFilters ? currentFilterCategory : categoryList" class="prof-list">
              <div class="prof-list-title" (click)="openCategory(category)">
                <img src="./profilum-assets/images/common/arrow-prof.svg" [ngClass]="{ opened: !category.isOpen }" />
                <div class="prof-list-title-text">{{ category.name }}</div>
              </div>
              <div *ngIf="category.isOpen" class="prof-list-cards">
                <prf-profession-mobile-card
                  *ngFor="let profession of isFilters ? currentFilterProfByCategory[category.name] : professionsByCategory[category.name]"
                  [favourite]="!!favourites[profession.id]"
                  [recommended]="recommended[profession.id] && !isBvb"
                  [capabilities]="recommendedCapabilities[profession.id] && !isBvb"
                  [professionTryingOnStage]="professionTryingOnStage"
                  [profession]="profession"
                  (updateFavourites)="updateFavorites($event)"
                  (showProfession)="showDetailProfession($event)"
                  [profCatalog]="true"
                >
                </prf-profession-mobile-card>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #emptySearch>
        <div class="empty">
          <div class="empty-text">Ничего не нашли по твоему запросу. Попробуй еще раз</div>
          <button class="empty-button" (click)="clearSearch()">Давай!</button>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <ng-template #filters>
    <div class="select-filters" *ngIf="showFilters">
      <div class="select-filters-title">
        <div class="select-filters-title-name">Фильтры</div>
        <img class="select-filters-title-img" src="./profilum-assets/images/common/close-filter.svg" (click)="showFilter()" />
      </div>
      <div class="select-filters__container">
        <div class="select-filters-category scrollable-container">
          <ng-container *ngIf="!isBvb && (sessionIdsCapabilities || sessionIdsInterests)">
            <div class="select-filters-category-name">Рекомендовано диагностикой</div>
            <div class="select-filters-category-recommended">
              <div
                *ngIf="sessionIdsCapabilities"
                class="select-filters-category-recommended-tab"
                [class.selected_capabilities]="applyFilters.capabilities"
                (click)="showWithCapabilities()"
              >
                Способности
              </div>
              <div
                *ngIf="sessionIdsInterests"
                class="select-filters-category-recommended-tab"
                [class.selected_interests]="applyFilters.interests"
                (click)="showWithInterests()"
              >
                Интересы
              </div>
            </div>
          </ng-container>
          <div class="select-filters-category-favourites" (click)="showOnlyFavorites()">
            <div class="select-filters-category-name">Показывать только выбранное</div>
            <div class="select-filters-category-icon" [class.select-filters-category-icon_favourite]="applyFilters.favourite"></div>
          </div>
          <div class="select-filters-category-name">Отрасли</div>
          <div class="select-filters-section">
            <div
              class="select-filters-section-element"
              (click)="addCategoryFilter(category)"
              *ngFor="let category of categoryList"
              [ngClass]="{
                selected: category.selected
              }"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showFilters" class="select-filters-buttons">
        <div class="select-filters-buttons-reset" (click)="closeFiltersWithReset()">Сбросить</div>
        <div class="select-filters-buttons-apply" (click)="closeWithApply()">Применить</div>
      </div>
    </div>
  </ng-template>
  <ng-template #bvbRecommendations>
    <prf-discover-intro-recommendations
      [professionName]="eventProf.profession.name"
      (forward)="createClassesFormat($event)"
      (back)="closeBvbRecommendation()"
    ></prf-discover-intro-recommendations>
  </ng-template>
  <ng-container *ngIf="!showFilters && !showBvbRecommendations && !selectProfession">
    <prf-pupil-menu-bottom
      *ngIf="!isBvb; else backToProfile"
      [root]="'profession'"
      [onbording]="onbording"
      class="prof-mobile-catalog-menu"
      [imagePath]="userInfo.imagePath"
      [initials]="userInitials"
    ></prf-pupil-menu-bottom>
  </ng-container>
  <ng-template #backToProfile>
    <div class="prof-mobile-catalog-back" routerLink="/discover-professions-mobile/bvb-profile">
      <div class="prof-mobile-catalog-back-button">
        <div class="prof-mobile-catalog-back-text">Вернуться в профиль</div>
      </div>
    </div>
  </ng-template>
  <div class="onbording-section" *ngIf="onbording">
    <div class="onbording">
      <img class="onbording-image" src="./profilum-assets/images/icons/onboarding-dots-third.svg" alt="" />
      <div class="onbording-main">
        <div class="onbording-main-title">Библиотека профессий</div>
        <div class="onbording-main-text">
          Здесь собрана информация по всем-всем профессиям. Чтобы не растеряться при первом знакомстве и быстрее ориентироваться потом —
          воспользуйся фильтром по отраслям.
        </div>
      </div>
      <button class="onbording-button" (click)="goToLessons()">Начать</button>
    </div>
    <div class="onbording-pointers">
      <div class="onbording-pointer"></div>
      <div class="onbording-pointer"></div>
      <div class="onbording-pointer">
        <img src="./profilum-assets/images/icons/arrows/onboarding-arrow-down.svg" class="onbording-pointer-image" />
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <prf-pupil-common-loader></prf-pupil-common-loader>
</ng-template>
