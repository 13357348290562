import { Component, HostListener } from '@angular/core';
import { take } from 'rxjs/operators';
import { B2gSaasService, INews } from '@profilum-library';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 400; // [px]

@Component({
  selector: 'prf-news-lenta',
  templateUrl: './news-lenta.component.html',
  styleUrls: ['./news-lenta.component.scss'],
})
export class NewsLentaComponent {
  public loaded: boolean = false;
  public newsList: Array<INews> = [];
  public newsListByCol: Array<Array<INews>> = [];
  public page: number = 0;

  private loadedAllData: boolean = false;
  private loading: boolean = false;

  constructor(private b2gSaasService: B2gSaasService) {
    this.loadNewsList();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM) {
      if (!this.loading && !this.loadedAllData) {
        this.page++;
        this.loadNewsList();
      }
    }
  }

  private loadNewsList() {
    this.loading = true;

    this.b2gSaasService
      .getNews(PAGE_SIZE * this.page, PAGE_SIZE * this.page + PAGE_SIZE, '')
      .pipe(take(1))
      .subscribe(data => {
        data.forEach(n => this.newsList.push(n));

        this.newsListByCol[0] = this.newsList.filter((n, i) => i % 2 === 0);
        this.newsListByCol[1] = this.newsList.filter((n, i) => i % 2 !== 0);

        this.loadedAllData = data.length < PAGE_SIZE;
        this.loading = false;
        this.loaded = true;
      });
  }
}
