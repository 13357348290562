import { NgModule } from '@angular/core';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { PlayerService } from 'app/pages/player/player.service';
import { SharedModule } from 'app/shared/shared.module';
import { HttpService } from '@profilum-library';
import { D3ToolsService } from 'app/shared/services/d3tools.service';
import { SlideService } from 'app/shared/services/slide.service';
import { AboutTestComponent } from './about-test/about-test.component';
import { AgileBubbleBoxContainerComponent } from './agile-bubble-box-container/agile-bubble-box-container.component';
import { AgileBubbleBoxComponent } from './agile-bubble-box/agile-bubble-box.component';
import { AnketaComponent } from './anketa/anketa.component';
import { BubbleBoxContainerComponent } from './bubble-box-container/bubble-box-container.component';
import { ChildInfoComponent } from './child-info/child-info.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SelectTestTypeIntComponent } from './select-test-type/select-test-type-int/select-test-type-int.component';
import { SelectTestTypeComponent } from './select-test-type/select-test-type.component';
import {
  SelectUserStatusIntComponent,
} from './select-user-status/select-user-status-int/select-user-status-int.component';
import { SelectUserStatusComponent } from './select-user-status/select-user-status.component';
import { TellAboutComponent } from './tell-about/tell-about.component';
import { RouterModule } from '@angular/router';
import { CampTestModule } from 'app/pages/player/camp-test/camp-test.module';
import {
  CampTestAgileBubbleBoxComponent,
} from 'app/pages/player/camp-test/camp-test-agile-bubble-box/camp-test-agile-bubble-box.component';
import { AnketaBvbComponent } from './anketa-bvb/anketa-bvb.component';
import { SupportTextModule } from 'app/shared/common-components/support-text/support-text.module';
import {
  AnketaBvbVisuallyImpairedComponent,
} from 'app/pages/player/anketa-bvb-visually-impaired/anketa-bvb-visually-impaired.component';
import {
  BvbVisuallyImpairedBubbleBoxComponent,
} from 'app/pages/player/bvb-visually-impaired/bvb-visually-impaired-bubble-box/bvb-visually-impaired-bubble-box.component';

import { RectangleBoxComponent } from '../../ui-kit/components/prf-tests/rectangle-box/rectangle-box.component';

@NgModule({
  imports: [SharedModule, RouterModule, CampTestModule, SupportTextModule, RectangleBoxComponent],

  declarations: [
    TellAboutComponent,
    SelectTestTypeComponent,
    SelectTestTypeIntComponent,
    SelectUserStatusComponent,
    SelectUserStatusIntComponent,
    ChildInfoComponent,
    AnketaComponent,
    NavigationComponent,
    BubbleBoxContainerComponent,
    AboutTestComponent,
    AgileBubbleBoxComponent,
    AgileBubbleBoxContainerComponent,
    BvbVisuallyImpairedBubbleBoxComponent,
    CampTestAgileBubbleBoxComponent,
    AnketaBvbComponent,
    AnketaBvbVisuallyImpairedComponent,
  ],
  providers: [SlideService, D3ToolsService, HttpService, PlayerService, AnketaService],
})
export class PlayerModule {}
