<div class="container">
  <div class="promotest-section">
    <div class="promotest-heading">
      <a class="profilum-logo-link w-inline-block" [class.disabled_link]="isVGuser" href="/{{ userRole }}">
        <img src="./profilum-assets/images/logos/profilum-logo.svg" />
      </a>
      <a class="mosrulogo-link w-inline-block">
        <img src="./profilum-assets/images/logos/mosru-logo.svg" alt="" />
      </a>
    </div>
    <div class="promotest-content">
      <h1 class="promotest-title">Узнайте, как развить таланты вашего ребенка</h1>
      <div class="promotest-text">
        Наш игровой тест поможет узнать больше о вашем ребенке и понять, как развивать его таланты. После прохождения тестирования вы
        получите индивидуальную подборку онлайн-курсов, бесплатных городских мероприятий и программ. Используйте эти рекомендации, чтобы
        помочь своему ребенку выбрать профессию и проявить свои таланты!
      </div>
      <form [formGroup]="form">
        <div class="form-container">
          <div class="block-row">
            <div class="content-slide-col">
              <label for="userName">Имя ребенка</label>
              <input
                type="text"
                maxlength="30"
                id="userName"
                class="create-event-field w-input"
                [class.active]="f.name.value"
                [class.error-field]="submitted && f.name.errors"
                formControlName="name"
              />
            </div>
            <div class="_break"></div>
            <div class="content-slide-col content-slide-col-2">
              <div class="content-slide-col tell-about__age">
                <label for="userAge">Возраст</label>
                <input
                  type="text"
                  id="userAge"
                  class="create-event-field w-input"
                  [class.active]="f.age.value"
                  [class.error-field]="submitted && f.name.errors"
                  formControlName="age"
                  (focusout)="checkChildAge()"
                  (focusin)="ageValid = true; ageChecked = false"
                />
              </div>
            </div>
          </div>

          <ng-container *ngIf="f.age.errors">
            <mat-error *ngIf="ageChecked"> К сожалению, диагностика не подходит для данного возраста </mat-error>
          </ng-container>
        </div>

        <div class="terms">
          <prf-custom-check-box formControlName="personalTerms">
            {{ 'SHARED.AGREEMENT.ACCEPT_TEXT' | translate }}
            <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" rel="nofollow">{{
              'SHARED.AGREEMENT.USER_CONFIRMATION' | translate
            }}</a
            ><br />
            {{ 'SHARED.AGREEMENT.CONSENT_PROCESSING' | translate }}
            <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" rel="nofollow">{{
              'SHARED.AGREEMENT.MY_PRIVATE_DATA' | translate
            }}</a>
          </prf-custom-check-box>
        </div>
        <a (click)="createSession()" [ngClass]="{ disabled: isAccessDenied }" [class.inactive]="sessionWaiting" class="button-start">
          <span class="btn-text">{{ 'SHARED.START_TEST' | translate }}</span>
        </a>
      </form>
    </div>
  </div>
</div>
