<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share">
      <a (click)="closePopup()" class="popup-share-close">
        <img ngSrc="./profilum-assets/images/common/close-popup.svg" alt="" height="40" width="40" />
      </a>
      <ng-container [ngSwitch]="popupType">
        <ng-container *ngSwitchCase="popupTypes.SuccessCopy">
          <ng-container *ngTemplateOutlet="successCopyTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="popupTypes.StartPage">
          <ng-container *ngTemplateOutlet="startPageTemplate"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #successCopyTemplate>
  <div class="popup-share-confirmed">
    <div class="popup-share-header">
      <img class="popup-share-confirmed-icon" ngSrc="./profilum-assets/images/common/confirm-reset.svg" alt="" height="44" width="44" />
      <p class="popup-share-header-title">Инструкция скопирована</p>

      <p class="popup-share-header-text">
        Отправьте его {{ dictionary.PupilDative }} в WhatsApp, «ВКонтакте», Viber, Telegram или другим удобным способом
      </p>
    </div>
    <div class="popup-share-buttons">
      <button type="button" class="button button-green" (click)="closePopup()">Продолжить</button>
    </div>
  </div>
</ng-template>
<ng-template #startPageTemplate>
  <div class="popup-share-header">
    <p class="popup-share-header-title">Что делать, если {{ dictionary.Pupil }} не вошёл в систему?</p>
    <div class="popup-share-main">
      <ng-container *ngIf="pupil.phoneNumberMask">
        <p class="popup-share-main-text">1. Убедитесь, что номер указан верно</p>
        <div class="popup-share-main-info">
          <p class="popup-share-main-important">
            {{ pupil.phoneNumberMask }}
          </p>
          <p class="popup-share-main-important">{{ pupil.fullNameText }}</p>
        </div>
      </ng-container>
      <p class="popup-share-main-text">2. Отправьте инструкцию {{ dictionary.PupilDative }}</p>
      <p class="popup-share-main-info">
        {{ invitationText }}
      </p>
    </div>
    <div class="popup-share-buttons">
      <button type="button" class="button button-green" (click)="copyInvitation()">Скопировать инструкцию</button>
    </div>
  </div>
</ng-template>
