import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { ClipboardService } from 'ngx-clipboard';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  ApiCoursesMaterialsService,
  ApiFavoritesService,
  ApiPlayerService,
  AppSettingsService,
  EmptyGuid,
  IGetCourseByClassResponse,
  ProductTypes,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-children-tabs-container',
  templateUrl: './children-tabs-container.component.html',
  styleUrls: ['./children-tabs-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChildrenTabsContainerComponent extends UnsubscribeComponent {
  public selectedProfession: any = {};
  public pupilSessionComplete: boolean = false;
  public parentSessionComplete: boolean = false;
  public pupilSessionStart: boolean = false;
  public resultsLoaded: boolean = false;
  public childChanged: boolean = false;
  public favoritesClasses: any[];
  public showInvitationModal: boolean = false;
  public isDiagnostic: boolean = false;
  public finishedTestSessionId: string = '';

  public emptyGuid = EmptyGuid;
  public showWelcomePage: boolean = AppSettingsService.settings.registrationParentWithChild;

  private _sessionIDParent: string;
  private _child: any = { firstName: '' };

  public motivationLastSessionId: string;
  public childInfo: {
    userId: string;
    userRole: string;
    firstName: string;
    regionId: string;
    municipalityId: string;
  };

  public parentMenu = [
    { key: 'testResult', name: 'Результат теста' },
    { key: 'professions', name: '' },
    { key: 'favorites', name: 'Избранное' },
    { key: 'profile', name: 'Профиль' },
  ];

  public userRole: string;
  public selectedParentMenu: any = this.parentMenu[0];
  public copyLinkRegister: string = '';
  public disableTalentDiagnosticInParentLK = AppSettingsService.settings.disableTalentDiagnosticInParentLK ?? true;

  @Input() favoriteProfessions: Array<any> = [];
  @Input() set sessionIDParent(val: string) {
    this._sessionIDParent = val;
  }
  @Input() set child(val: any) {
    this._child = val;
    this.webStorageService.set(StorageKeys.RefferalUserId, val.userId);
    this.webStorageService.set(StorageKeys.RefferalUserGender, val.gender);
    this.selectedParentMenu = this.parentMenu[0];
    this.resultsLoaded = false;
    this.getCourseByClass(val.schoolClassId)
      .pipe(this.unsubscribeOperator)
      .subscribe((response: IGetCourseByClassResponse) => {
        this.isDiagnostic = !response.courseIds.length;
      });

    // костыль с childChanged используется для обновления содержимого при переходе между детьми
    this.childChanged = true;

    this.timer = setTimeout(() => {
      this.childChanged = false;
    }, 10);
    this.getChildInfoOfB2cParent();
    this.getMotivationSession(this.child.userId).pipe(this.unsubscribeOperator).subscribe();
    this.getFavorites(this.child).pipe(this.unsubscribeOperator).subscribe();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private apiPlayerService: ApiPlayerService,
    private apiFavoritesService: ApiFavoritesService,
    private clipboardService: ClipboardService,
    private webStorageService: WebStorageService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
  ) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.copyLinkRegister = location.origin + '/code-registration';
    this.getTranslation('HEADER.PROF')
      .pipe(take(1))
      .subscribe(translation => (this.parentMenu[1].name = translation));

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['menu']) {
        const menu = this.parentMenu.filter(pm => pm.key === params['menu']);

        if (menu && menu.length) {
          setTimeout(() => (this.selectedParentMenu = menu[0]), 100);
        }
      }
    });
  }

  get child(): any {
    return this._child;
  }

  get sessionIDParent(): string {
    return this._sessionIDParent;
  }

  getTranslation(key: string, interpolationParams?: Object): Observable<any> {
    if (interpolationParams) {
      return this.translateService.get(key, interpolationParams);
    }
    return this.translateService.get(key);
  }

  public selectParentMenu(menuItem: any) {
    this.selectedParentMenu = menuItem;
  }

  public onCopyChildCode(shareDirectly: boolean): void {
    this.getTranslation('PARENT_PANEL.INVITATION_TEXT', {
      childName: this.child.firstName,
    })
      .pipe(take(1))
      .subscribe();
  }

  private getMotivationSession(childId: string): Observable<any> {
    return this.apiPlayerService.getSessionsList(childId).pipe(
      tap(sessions => {
        this.finishedTestSessionId = sessions.find(session => session.completed)?.sessionId || '';
        const motivationSessions = sessions
          .filter(session => session.screeningTestId === TESTS_VARIANTS_IDS.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

        this.motivationLastSessionId = motivationSessions && motivationSessions.length ? motivationSessions[0].sessionId : null;
      }),
    );
  }
  getFavorites(child): Observable<any> {
    return this.apiFavoritesService.getFavorites(child.userId, ProductTypes.Profession).pipe(
      this.unsubscribeOperator,
      tap(favorites => {
        if (favorites) {
          this.favoritesClasses = favorites;
        }
      }),
    );
  }

  private getChildInfoOfB2cParent() {
    if (this.isB2cParent) {
      if (this.parentMenu[1].key !== 'motivation') {
        this.parentMenu.splice(1, 0, { key: 'motivation', name: 'Учебная мотивация' });
      }
      this.parentMenu[0].name = 'Тест на таланты';

      this.childInfo = {
        userId: this.child.userId,
        userRole: 'pupil',
        firstName: this.child.firstName,
        regionId: this.child.regionId,
        municipalityId: this.child.municipalityId,
      };
    }
  }

  get isB2cParent() {
    const b2cRegionId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.regionId : null;
    const b2cMunicipalityId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.municipalityId : null;
    return (
      this.webStorageService.get(StorageKeys.UserRole) === 'parent' &&
      this.webStorageService.get(StorageKeys.RegionId) === b2cRegionId &&
      this.webStorageService.get(StorageKeys.MunicipalityId) === b2cMunicipalityId
    );
  }

  private getCourseByClass(id: string): Observable<IGetCourseByClassResponse> {
    return this.apiCoursesMaterialsService.getCourseByClass(id);
  }
}
