<div class="thanks-block">
  <div class="thanks-block-text">
    <div class="thanks-block-title">
      <ng-content select=".title"></ng-content>
    </div>
    <div class="thanks-block-desc">
      <ng-content select=".desc"></ng-content>
    </div>
  </div>
  <div class="thanks-block-button">
    <ng-container [ngSwitch]="bannerType">
      <ng-container *ngSwitchCase="bannerTypes.LINK">
        <a class="button green-btn" href="{{ linkUrl }}" target="_blank">
          <span *ngTemplateOutlet="button"></span>
        </a>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <a class="button green-btn" (click)="buttonClicked.emit()">
          <span *ngTemplateOutlet="button"></span>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #button>
  <ng-content select=".button"></ng-content>
</ng-template>
