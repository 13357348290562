import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterestsAndSkillsComponent } from './interests-and-skills.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipTextModule } from '../../tooltip-text/tooltip-text.module';

@NgModule({
  declarations: [InterestsAndSkillsComponent],
  imports: [CommonModule, TranslateModule, TooltipTextModule],
  exports: [InterestsAndSkillsComponent],
})
export class InterestsAndSkillsModule {}
