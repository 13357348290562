export const LandingBaseDictionary = {
  IncorrectData: 'Это не я или данные неверны',
  YourTeacher: 'Твой учитель',
  Greeting: 'Здравствуйте',
  You: 'Вы',
  EnterPhoneNumber: 'Введите ваш номер телефона',
  Phone: 'Телефон',
  ItsYourNumber: 'Пожалуйста, убедитесь, что номер указан верно',
  Confirm: 'Все верно',
  SpecifyNumber: 'Изменить номер телефона',
  Enter: 'Войти',
  Hello: 'Привет',
  Welcome: 'Добро пожаловать',
  ClassGenitive: 'Классе',
  YouIn: 'Ты в',
  LoginAnnotation: 'С его помощью вы будете входить в систему',
  InputYourNumber: 'Для входа в систему введите ваш номер телефона',
  ConfirmIdentity: 'Да, это я',
};

export enum StandardTermsDictionary {
  Teacher = 'учитель',
  TeacherGenitive = 'учителя',
  TeacherAblative = 'учителем',
  School = 'школа',
  Pupil = 'ученик',
  PupilDative = 'ученику',
  PupilGenitive = 'ученика',
  Pupils = 'ученики',
  PupilsGenitive = 'учеников',
  Class = 'класс',
  ClassAccusative = 'класс',
  ClassGenitive = 'класса',
  ClassPrepositional = 'классе',
  Classes = 'классы',
  ClassesGenitive = 'классов',
  Lesson = 'урок',
  OurClass = 'наш',
  OurClassGenitive = 'нашего',
  MustBeClass = 'должен',
}

export enum KruzhkiTermsDictionary {
  Teacher = 'преподаватель',
  TeacherGenitive = 'преподавателя',
  TeacherAblative = 'преподавателем',
  School = 'ОКЦ',
  Pupil = 'слушатель',
  PupilDative = 'слушателю',
  PupilGenitive = 'слушателя',
  Pupils = 'слушатели',
  PupilsGenitive = 'слушателей',
  Class = 'группа',
  ClassAccusative = 'группу',
  ClassGenitive = 'группы',
  ClassPrepositional = 'группе',
  Classes = 'группы',
  ClassesGenitive = 'групп',
  Lesson = 'занятие',
  OurClass = 'наша',
  OurClassGenitive = 'нашей',
  MustBeClass = 'должна',
}

export type DictionaryType = typeof StandardTermsDictionary | typeof KruzhkiTermsDictionary;

export const PhoneInputErrors = {
  minLength: 'Номер должен содержать 11 цифр',
};
