<ng-template [ngIf]="pupils && dataFetched">
  <ng-container *ngIf="isPupilsInvitationVisible && !userRestriction">
    <div *ngIf="pupils.length >= 2; else copyPupilsInvitationWidget" class="pupil-widgets">
      <prf-download-pupils-accesses
        [schoolClassId]="schoolClass.classId"
        class="pupil-widgets__item"
        *ngIf="false"
      ></prf-download-pupils-accesses>
      <ng-template [ngTemplateOutlet]="copyPupilsInvitationWidget"></ng-template>
    </div>

    <ng-template #copyPupilsInvitationWidget>
      <prf-copy-pupils-invitation
        [pupilsLength]="pupils.length"
        [schoolClassId]="schoolClass.classId"
        [shortRegLink]="schoolClass?.parentLink"
        class="pupil-widgets__item"
      ></prf-copy-pupils-invitation>
    </ng-template>
  </ng-container>
  <prf-pupils-table
    class="w-block pupils-table"
    [ngClass]="{ 'no-margin-top': isKruzhkiVariant }"
    [pupils]="pupils"
    [isMOStend]="isMOStend"
    [(successChanges)]="successChanges"
    [(undoEditing)]="undoEditing"
    [(continueEditing)]="continueEditing"
    (showResetPassword)="changeResetPasswordPopup($event)"
    (showChangePhone)="toggleChangePhonePopup($event)"
  ></prf-pupils-table>
</ng-template>
<prf-reset-password-popup
  *ngIf="resetPasswordPopup"
  [pupil]="pupil"
  [isDiagnosticClass]="isDiagnosticClass"
  (closeModal)="changeResetPasswordPopup()"
></prf-reset-password-popup>
<prf-change-pupil-phone-popup
  *ngIf="changePupilPhonePopup"
  [changePhoneInfo]="changePhoneInfo"
  (closeModal)="closeChangePhonePopup($event)"
  (closeModalWithSave)="closeChangePhonePopupWithSave($event)"
>
</prf-change-pupil-phone-popup>
<ng-container *ngIf="(teacherHasNoClasses$ | async) === true">
  <div class="pupil-widgets__title">{{ dictionary.Pupils | titlecase }}</div>
  <prf-teacher-with-no-classes-banner></prf-teacher-with-no-classes-banner>
</ng-container>
