import { Injectable } from '@angular/core';
import { HttpService, IFilterClasses } from '@profilum-library';
import { BehaviorSubject, Observable } from 'rxjs';

export enum PricesFilterTypes {
  BY_ASCENDING,
  BY_DESCENDING,
}
export interface IPriceFilterFormat {
  value: PricesFilterTypes;
  viewValue: string;
}
export const priceFiltersArray: IPriceFilterFormat[] = [
  {
    value: PricesFilterTypes.BY_ASCENDING,
    viewValue: 'По возрастанию',
  },
  {
    value: PricesFilterTypes.BY_DESCENDING,
    viewValue: 'По убыванию',
  },
];

export enum DateFiltersTypes {
  JANUARY = 1,
  FEBRUARE = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}
export interface IDateFilterFormat {
  value: DateFiltersTypes;
  viewValue: string;
}
export const datesFiltersArray: IDateFilterFormat[] = [
  {
    value: DateFiltersTypes.JANUARY,
    viewValue: 'Январь',
  },
  {
    value: DateFiltersTypes.FEBRUARE,
    viewValue: 'Февраль',
  },
  {
    value: DateFiltersTypes.MARCH,
    viewValue: 'Март',
  },
  {
    value: DateFiltersTypes.APRIL,
    viewValue: 'Апрель',
  },
  {
    value: DateFiltersTypes.MAY,
    viewValue: 'Май',
  },
  {
    value: DateFiltersTypes.JUNE,
    viewValue: 'Июнь',
  },
  {
    value: DateFiltersTypes.JULY,
    viewValue: 'Июль',
  },
  {
    value: DateFiltersTypes.AUGUST,
    viewValue: 'Август',
  },
  {
    value: DateFiltersTypes.SEPTEMBER,
    viewValue: 'Сентябрь',
  },
  {
    value: DateFiltersTypes.OCTOBER,
    viewValue: 'Октябрь',
  },
  {
    value: DateFiltersTypes.NOVEMBER,
    viewValue: 'Ноябрь',
  },
  {
    value: DateFiltersTypes.DECEMBER,
    viewValue: 'Декабрь',
  },
];

@Injectable({
  providedIn: 'root',
})
export class FiltersPromotestCoursesService {
  private coursesFilters = new BehaviorSubject<IFilterClasses>({});
  private priceFilters = new BehaviorSubject<IPriceFilterFormat>(null);
  private dateFilter = new BehaviorSubject<IDateFilterFormat>(null);

  constructor(private http: HttpService) {}

  getCoursesFilters(): Observable<IFilterClasses> {
    return this.coursesFilters.asObservable();
  }
  setCoursesFilter(filters: IFilterClasses, schoolID?: any) {
    this.coursesFilters.next(filters);
  }

  setPriceFilter(priceFilter: IPriceFilterFormat) {
    this.priceFilters.next(priceFilter);
  }
  getPriceFilter() {
    return this.priceFilters.asObservable();
  }

  setDateFilter(dateFilter: IDateFilterFormat) {
    this.dateFilter.next(dateFilter);
  }
  getDateFilter() {
    return this.dateFilter.asObservable();
  }
}

function sortList(left, right) {
  if (left.name > right.name) {
    return 1;
  }
  if (left.name < right.name) {
    return -1;
  }
  return 0;
}

function uniqSorted(list) {
  const newList = [list[0]];

  list.forEach(({ name, line }) => {
    if (newList[newList.length - 1].name !== name) {
      newList.push({ name, line });
    }
  });

  return newList;
}
