<div class="prf-extracurricular-activity-card" [ngClass]="card.theme">
  <prf-h3 class="prf-extracurricular-activity-card-title">{{ card.title }}</prf-h3>
  <prf-description class="prf-extracurricular-activity-card-description">
    @for (description of card.descriptions; track description; let index = $index) {
      <span [style.font-weight]="index === 1 && 700">{{ description }}</span>
    }
  </prf-description>
  <div [ngClass]="card.theme" class="prf-extracurricular-activity-card-image-container">
    <img
      [ngSrc]="card.image.src"
      [alt]="card.image.alt"
      [width]="card.image.width"
      [height]="card.image.height"
      class="prf-extracurricular-activity-card-image"
    />
  </div>
</div>
