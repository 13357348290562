import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonHeightType, ButtonRadiusType, ButtonType } from '@profilum-collections/common.collections';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@profilum-library';
import { ButtonComponent } from '../../../../components/button/button.component';
import { H1Component } from '../../components/h1/h1.component';
import { DescriptionComponent } from '../../components/description/description.component';
import { Router } from '@angular/router';
import { PrfBanner } from '../../components/banner/banner.types';
import { ImageComponent } from '../../components/image/image.component';
import { InfoBlockComponent } from '../../components/info-block/info-block.component';

@Component({
  selector: 'prf-banner',
  standalone: true,
  imports: [CommonModule, ButtonComponent, H1Component, DescriptionComponent, ImageComponent, InfoBlockComponent],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @Input() bannerData: PrfBanner;
  public readonly ButtonType = ButtonType;
  public readonly ButtonHeightType = ButtonHeightType;
  public readonly routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  public readonly ButtonRadiusType = ButtonRadiusType;
  private router = inject(Router);

  public navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
