<div *ngIf="classList && classList.length" class="class-table">
  <div class="class-table__header">
    <div class="class-column">
      <div class="class-column-count">
        {{ classList.length }}
        {{ classList.length | numerals : ['класс', 'класса', 'классов'] }}
      </div>
    </div>
    <div class="class-column class-column-state">
      <span class="text">Зарегистрировано</span>
      <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
      <div class="info-popup tooltip-desc">
        <span>Зарегистрированные родителями ученики</span>
        <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
      </div>
    </div>
    <div class="class-column class-column-state class-column-state_last">
      <span class="text">Диагностики</span>
      <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
      <div class="info-popup tooltip-desc">
        <span>Ученики, прошедшие диагностику</span>
        <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
      </div>
    </div>
  </div>
  <div class="class-table__list">
    <div class="class-table__row" *ngFor="let class of classList; let i = index" (click)="selectClass(class)">
      <div class="class-column class-column-number-order">
        <div class="class-header-number _2">{{ i + 1 }}</div>
      </div>
      <div class="class-column class-column-name">
        <div class="class-column _4">
          <div class="student-name">
            <a class="student-name-link"> {{ class.number }} {{ class.letter }} </a>
          </div>
        </div>
      </div>
      <div class="class-column class-column-state">
        <span class="sign">{{ class.pupilsCount }}</span>
      </div>

      <div class="class-column class-column-state class-column-state_last">
        <span class="sign sign-primary">{{ class.isTestedPupils ? class.isTestedPupils : 0 }}</span>
      </div>
    </div>
  </div>
</div>
