<div class="testing-progress-bar-block">
  <div class="testing-progress-bar" [style.width]="'0'"></div>
</div>
<div class="header-testing" [ngSwitch]="testType">
  <a [routerLink]="testType !== testTypes.MINCIFRY_TEST.toString() ? ['/' + userRole] : []" class="testing-logo-link w-inline-block">
    <img class="new-logo" src="./profilum-assets/images/dashboard/profilum-new-logo.svg" alt="Логотип Профилум" />
  </a>

  <ng-container *ngSwitchCase="'0'">
    <ng-container *ngTemplateOutlet="default_default360"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'1'">
    <ng-container *ngTemplateOutlet="default_default360"></ng-container>
  </ng-container>

  <ng-template #default_default360>
    <div class="header-testing-div">
      <div class="test-title">{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</div>
      <div *ngIf="userRole === 'pupil'">{{ currentAnketaStep }} из {{ testTypesSteps.DEFAULT_STANDART }}</div>
      <div *ngIf="userRole === 'parent'">{{ currentAnketaStep }} из {{ testTypesSteps.DEFAULT_360 }}</div>
    </div>
  </ng-template>

  <ng-container *ngSwitchCase="'2'">
    <div class="header-testing-div">
      <div class="test-title">Мир профессий</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.PROFESSIONPRESTIGE }}</div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'4'">
    <div class="header-testing-div">
      <div class="test-title">Тест на ценности</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.VALUES_TEST }}</div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'6'">
    <div class="header-testing-div">
      <div class="test-title">Тест</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.MINCIFRYDEMO_TEST }}</div>
    </div>
  </ng-container>

  <!--  <ng-container *ngSwitchCase="'7'">-->
  <!--    <div class="header-testing-div">-->
  <!--      <div class="test-title">Тест</div>-->
  <!--      <div>{{ currentAnketaStep }} из {{ testTypesSteps.BVB_TEST }}</div>-->
  <!--    </div>-->
  <!--  </ng-container>-->

  <ng-container *ngSwitchCase="'8'">
    <div class="header-testing-div">
      <div class="test-title">Тест</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.VK_TEST }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'9'">
    <div class="header-testing-div">
      <div class="test-title">Тест</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.CAMP_TEST }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'10'">
    <div class="header-testing-div">
      <div class="test-title">Тест</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.MINCIFRY_TEST }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'12'">
    <div class="header-testing-div">
      <div class="test-title">Тест</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.WEB_TEST }}</div>
    </div>
  </ng-container>
</div>
<div class="content-section testing-content">
  <div class="container container-745">
    <main [ngClass]="{ 'anketa-steps': true }">
      <prf-about-test
        [form]="forms[steps.AboutTest - 1]"
        (languageSelected)="changeLanguage($event)"
        *ngIf="currentAnketaStep == steps.AboutTest"
      ></prf-about-test>
    </main>
    <div *ngIf="isMOStend" class="mosreg-text">Результаты диагностики и рекомендации на ее основе придут на указанную вами почту.</div>
    <prf-navigation
      [showAgreements]="true"
      [buttonEvent]="navigation"
      [enableBack]="false"
      [enableForward]="enableForward()"
      [showStartDiagnosticButton]="isMincifryTest"
    >
    </prf-navigation>

    <prf-support-text *ngIf="isMincifryTest" class="support-text"></prf-support-text>
  </div>
</div>
