<ng-container [ngSwitch]="prosvetUserState">
  <ng-container *ngSwitchCase="loginCasesProsvet.LOGIN">
    <div class="login-section">
      <div class="login-container">
        <a routerLink="/" class="login-logo w-inline-block">
          <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
        </a>

        <div class="tabs login w-tabs">
          <div class="tabs-menu w-tab-menu">
            <a [routerLink]="['/prosvetlogin']" [routerLinkActive]="['w--current']" class="tab-link login w-inline-block w-tab-link">
              <div>{{ 'SHARED.ENTRANCE' | translate }}</div>
              <img src="./profilum-assets/images/icons/angle.png" width="20" class="angle" />
            </a>
            <a [routerLink]="['/prosvetregistration']" [routerLinkActive]="['w--current']" class="tab-link login w-inline-block w-tab-link">
              <div>{{ 'SHARED.REGISTRATION' | translate }}</div>
              <img src="./profilum-assets/images/icons/angle2.png" width="20" class="angle _2" />
            </a>
          </div>

          <div class="tabs-content w-tab-content">
            <div class="w-tab-pane w--tab-active">
              <div class="tab-pane login">
                <div class="user-data-form-block w-form">
                  <form [formGroup]="form" (keyup.enter)="onClickLogin()" autocomplete="false">
                    <div class="login-form-row">
                      <label for="email" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}</label>
                      <div class="login-field-column">
                        <input
                          type="email"
                          autocomplete="false"
                          class="login-field w-input"
                          maxlength="70"
                          name="email"
                          data-name="email"
                          id="email"
                          [class.error-field]="!loginForm.email['focused'] && isLoginInvalid"
                          (focusin)="loginForm.email['focused'] = true"
                          (focusout)="loginForm.email['focused'] = false"
                          formControlName="email"
                        />
                        <mat-error *ngIf="!loginForm.email['focused'] && isLoginInvalid">
                          {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="login-form-row">
                      <label for="password" class="login-label">{{ 'SHARED.FORM.PASSWORD' | translate }}</label>
                      <div class="login-field-column">
                        <input
                          type="password"
                          autocomplete="false"
                          class="login-field w-input"
                          maxlength="25"
                          name="password"
                          data-name="password"
                          id="password"
                          [class.error-field]="!loginForm.password['focused'] && isPasswordInvalid"
                          (focusin)="loginForm.password['focused'] = true"
                          (focusout)="loginForm.password['focused'] = false"
                          formControlName="password"
                        />
                        <mat-error *ngIf="isPasswordInvalid || passFailed">
                          {{ 'LOGIN_KZ.INCORRECT_PASSWORD' | translate }}
                        </mat-error>
                        <div class="under-field-text">
                          {{ 'LOGIN_KZ.ACCESS_CODE' | translate }}
                        </div>
                      </div>
                    </div>

                    <div class="w-center-content">
                      <a
                        (click)="animateLogin()"
                        [ngClass]="{ disabled: isAccessDenied }"
                        [class.btn-waiting]="buttonStates.waiting"
                        [class.btn-activated]="buttonStates.activated"
                        [class.btn-activate]="!buttonStates.active"
                        class="button-animate btn-activate"
                        id="btnActivation"
                      >
                        <span class="btn-icon"></span>
                        <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.ENTRANCE' | translate">{{
                          'LOGIN_KZ.ENTER' | translate
                        }}</span>
                      </a>
                    </div>
                  </form>
                </div>
                <a class="prosvetreg-button" (click)="prosvetReg()">
                  <span class="schoolPortal-text">Зарегистрироваться через Просвещение</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="loginCasesProsvet.REGISTER">
    <!-- регистрация ученика после авторизации на "Просвещении" -->
    <ng-container *ngIf="prosvetUserData.role === 'pupil'">
      <div class="login-section">
        <div class="login-container">
          <a routerLink="/" class="login-logo w-inline-block">
            <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
          </a>

          <div class="tabs login w-tabs">
            <div class="tabs-menu w-tab-menu">
              <a [routerLink]="['/prosvetlogin']" class="tab-link login w-inline-block w-tab-link">
                <div>{{ 'SHARED.ENTRANCE' | translate }}</div>
                <img src="./profilum-assets/images/icons/angle.png" width="20" class="angle" />
              </a>
              <a [routerLink]="['/prosvetregistration']" class="tab-link login w-inline-block w-tab-link w--current">
                <div>{{ 'SHARED.REGISTRATION' | translate }}</div>
                <img src="./profilum-assets/images/icons/angle2.png" width="20" class="angle _2" />
              </a>
            </div>
            <div class="tab-pane login">
              <div class="user-data-form-block w-form">
                <prf-registration-pupil-prosvet [prosvetUserData]="prosvetUserData"></prf-registration-pupil-prosvet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- регистрация родителя через Просвещение -->
    <ng-container *ngIf="prosvetUserData.role === 'parent'">
      <prf-open-registration-prosvet [prosvetUserData]="prosvetUserData"></prf-open-registration-prosvet>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="loginCasesProsvet.UPDATE">
    <!-- обновление данных ученика после авторизации на Просвещении -->
    <ng-container *ngIf="prosvetUserData.role === 'pupil'">
      <div class="login-section">
        <div class="login-container">
          <a routerLink="/" class="login-logo w-inline-block">
            <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
          </a>

          <div class="tabs login w-tabs">
            <div class="tabs-menu w-tab-menu">
              <a [routerLink]="['/prosvetlogin']" [routerLinkActive]="['w--current']" class="tab-link login w-inline-block w-tab-link">
                <div>{{ 'SHARED.UPDATE_PROFILE' | translate }}</div>
              </a>
            </div>
            <div class="tab-pane login">
              <div class="user-data-form-block w-form">
                <prf-update-pupil-prosvet [prosvetUserData]="prosvetUserData"></prf-update-pupil-prosvet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
