import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PrfLocation } from '../../../components/footer/footer.types';

@Component({
  selector: 'prf-footer-map',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './footer-map.component.html',
  styleUrl: './footer-map.component.scss',
})
export class FooterMapComponent {
  @Input() location: PrfLocation;
}
