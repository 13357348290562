import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-schools',
  template: '',
  standalone: true,
})
export class SchoolsComponent {
  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Школы' });
  }
}
