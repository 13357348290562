import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-motivation-results-page',
  templateUrl: './motivation-results-page.component.html',
  styleUrls: ['./motivation-results-page.component.scss'],
})
export class MotivationResultsPageComponent implements OnInit {
  public userRole: string = '';
  public urlSessionId: string = '';
  public pupil: {
    userId: string;
    regionId: string;
    municipalityId: string;
  };

  constructor(private activatedRoute: ActivatedRoute, private webStorageService: WebStorageService) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });
    this.pupil = {
      userId: this.webStorageService.get(StorageKeys.UserId),
      regionId: this.webStorageService.get(StorageKeys.RegionId),
      municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
    };
  }

  ngOnInit() {}
}
