<div class="new-spo-vpo-onboarding-page">
  <div class="new-spo-vpo-onboarding">
    <prf-show-messages
      [messages]="messages"
      [buttons]="buttons"
      [startMessageNumber]="startMessageNumber"
      [endMessageNumber]="endMessageNumber"
      [delay]="400"
      [confetti]="true"
      (buttonAction)="buttonsActions($event)"
    ></prf-show-messages>
  </div>
</div>
