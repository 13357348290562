<section>
  <prf-employer-menu></prf-employer-menu>
  <div class="content-section">
    <div class="container">
      <prf-page-header [title]="title"></prf-page-header>

      <prf-company-profile *ngIf="currentUrl === '/employer'"></prf-company-profile>

      <ng-container>
        <router-outlet></router-outlet>
      </ng-container>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>
