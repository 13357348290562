<div class="tooltip">
  <div *ngIf="useMouseEventsOutsideComponent; else useMouseEvents" class="info-icon">
    {{ buttonText }}
    <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
  </div>
  <ng-template #useMouseEvents>
    <div class="info-icon" (mouseenter)="show = true" (mouseleave)="show = false">
      {{ buttonText }}
      <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
    </div>
  </ng-template>
</div>

<ng-template #tooltip>
  <div
    class="info-popup tooltip-desc"
    [ngClass]="{
      active: show,
      'tooltip-desc-accent': accentColor,
      'tooltip-desc-top bottom-left-arrow': position === tooltipPosition.Top,
      'tooltip-desc-bottom top-left-arrow': position === tooltipPosition.Bottom,
      'tooltip-desc-left left-arrow': position === tooltipPosition.Left
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
