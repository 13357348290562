import { Directive, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { REG_EXP } from '../global-constants/reg-exp';
import { SchoolClassesClass } from '../classes/school-classes.class';
import { Router } from '@angular/router';
import { FilterSchoolsPipe } from '../pipes/filter-schools.pipe';
import { FilterByNamePipe } from '../pipes/filter-by-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SCHOOL_NUMBERS } from '../global-constants/school-numbers';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdminAccessLevel } from '../enums/admins.enums';
import { UtilsService } from '../dashboard/backend-services/utils.service';
import {
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  AuthHandlerService,
  B2gSaasService,
  EmptyGuid,
  ICity,
  IIsEmailFreeResponse,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import {
  ICityOptions,
  IRegionsOptions,
  ISchoolClasses,
  ISchoolClassLetterOptions,
  ISchoolClassNumberOptions,
  ISchoolClassOptions,
  ISchoolOptions,
} from 'app/shared/interfaces/iregistration-component.interface';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Directive()
export abstract class RegistrationBase<RegistrationService> extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public codeIsUsed: boolean = false;
  public codeNotFound: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;
  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;

  private _schoolView: ISchoolOptions[] = [];
  private _citiesView: ICityOptions[] = [];
  private _schoolLetterOptions: ISchoolClassLetterOptions[] = [];
  private _schoolNumberOptions: ISchoolClassNumberOptions[] = [];
  private _schoolClassOptions: ISchoolClassOptions[] = [];
  private _regionsOptions: IRegionsOptions[] = [];

  private minAge: number = 13; // минимальный разрешенный возраст для регистрации ученика

  private authHandlerService = inject(AuthHandlerService);

  protected constructor(
    protected userDataHandlerService: UserDataHandlerService,
    protected yandexMetricsService: YandexMetricsService,
    protected registrationService: RegistrationService | any,
    protected router: Router,
    protected fb: UntypedFormBuilder,
    protected apiLocationsService: ApiLocationsService,
    protected apiSchoolsService: ApiSchoolsService,
    protected filterSchoolsPipe: FilterSchoolsPipe,
    protected filterByNamePipe: FilterByNamePipe,
    protected translateService: TranslateService,
    protected appSettingsService: AppSettingsService,
    protected b2gSaasService: B2gSaasService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected utilsService?: UtilsService,
    protected role?: string,
  ) {
    super();
    this.role = this.role || 'pupil';
    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS') ?? [];
    this._schoolLetterOptions = [...this.SCHOOL_LETTERS].map(letter => ({
      name: letter.value,
      data: letter,
    }));
    this._schoolNumberOptions = [...SCHOOL_NUMBERS].map(number => ({
      name: number.value,
      data: number,
    }));
  }

  public async ngOnInit(): Promise<void> {
    this.initFormGroup();
    await this.getRegions();
    await this.getCities();
    await this.getSchools();

    if (this.defaultRegion) {
      this.regions = this.regions.filter(r => r.id === this.defaultRegion);
      this.f.region.setValue(this.regionsView.find(r => r.data.id === this.defaultRegion));
      this.onRegionChange();
    }

    if (this.regions) {
      this._regionsOptions = [...this.regions].map(region => ({ name: region.name, data: region }));
    }
  }

  public async getRegions(): Promise<void> {
    this.regions =
      (await this.apiLocationsService.getAllRegions().toPromise())?.filter(
        region => region.hrid != 'defaultRegion' && region.hrid != 'b2c',
      ) ?? [];
  }

  public async getCities(): Promise<void> {
    this.cities = (await this.apiLocationsService.getAllCities().toPromise()) ?? [];
  }

  public async getSchools(): Promise<void> {
    const schools = await this.apiSchoolsService.getSchools().toPromise();

    this.schools = schools
      ? [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }))
      : [];
  }

  public getSchoolClassBySchool(id): Observable<ISchoolClasses[]> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: ISchoolClasses[]) => {
        this.classes = classes;

        if (this.classes) {
          this._schoolClassOptions = [...this.classes].map(schoolClass => ({
            name: schoolClass.number + schoolClass.letter,
            data: schoolClass,
          }));
        }

        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  protected initFormGroup(): void {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      date: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.dateRegExp)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp),
      ]),
      role: new UntypedFormControl(this.role, [Validators.required]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl('M', [Validators.required]),
      personalTerms: new UntypedFormControl((this.personalTerms = true), [Validators.requiredTrue]),
      schoolClass: new UntypedFormControl(null, []),
    });
  }

  public get f() {
    return this.form.controls;
  }

  public onRegionChange(): void {
    this.f.city.reset();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }

  public onCityChange(): void {
    this.f.school.reset();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }

  public onSchoolChange(): void {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  public get regionsView(): IRegionsOptions[] {
    return this._regionsOptions;
  }

  public set citiesView(regionId: string) {
    this._citiesView =
      this.cities && regionId
        ? [...this.cities].filter(city => city.regionId === regionId).map(city => ({ name: city.name, data: city }))
        : [];
  }

  public get getCitiesView(): ICityOptions[] {
    return this._citiesView;
  }

  public set schoolView(cityName: string) {
    this._schoolView =
      this.schools && cityName
        ? [...this.schools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
        : [];
  }

  public get getSchoolView(): ISchoolOptions[] {
    return this._schoolView;
  }

  public get schoolClassView(): ISchoolClassOptions[] {
    return this._schoolClassOptions;
  }
  public get schoolClassNumber(): ISchoolClassNumberOptions[] {
    return this._schoolNumberOptions;
  }

  public get schoolClassLetter(): ISchoolClassLetterOptions[] {
    return this._schoolLetterOptions;
  }

  public get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }

  public get isAccessAllowed(): boolean {
    return this.form.valid && this.isClassSelected && this.isValidPassword(this.f.password.value);
  }

  public get currentClassLetter(): void {
    return this.f.schoolClassLetter.value ? this.f.schoolClassLetter.value.name : this.f.schoolClass.value.data.letter;
  }

  public get currentClassNumber(): void {
    return this.f.schoolClassNumber.value ? this.f.schoolClassNumber.value.name : this.f.schoolClass.value.data.number;
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }

  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }

  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isLetterError || this.isNumberError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  public toggleMask(): void {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public submit(): void {
    this.submitted = true;
    if (this.form.valid && this.personalTerms) {
      let credentials;
      credentials = {
        city: this.f.city.value.data.name,
        regionId: this.f.region.value.data.id,
        municipalityId: this.f.city.value.data.municipalityId,
        birthday: DateHelper.toDayJs(this.f.date.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        userName: this.f.email.value,
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        role: this.f.role.value,
        gender: this.f.gender.value,
        schoolId: this.f.school.value.data.id,
        schoolClassLetter: this.currentClassLetter,
        schoolClassNumber: this.currentClassNumber,
        schoolClass: this.f.schoolClass.value && this.f.schoolClass.value.data.id,
        tag: this.tag,
        IsAcceptPersonalInformation: this.personalTerms,
      };

      if (credentials) {
        this.registrationFailed = false;
        this.passFailed = false;
        this.registrationService
          .openregistration(credentials)
          .pipe(
            takeUntil(this.unsubscribe),
            switchMap((response: any) => {
              if (!response || response.userId == undefined || response.userId == null) {
                this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                this.registrationFailed = true; // ошибка регистрации
                this.failWaiting();
                return of(null);
              } else {
                if (this.tag) {
                  this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
                }

                this.yandexMetricsService.reachGoal(YmItems.PUPIL_REG);

                return this.registrationService.login(response.userName, credentials.password, false).pipe(
                  switchMap((loginResult: any) => {
                    if (!loginResult || (loginResult && loginResult.succeeded === false)) {
                      this.loginFailed = true;
                      this.passFailed = true;
                      this.removeWaiting();
                      return of(null);
                    } else {
                      this.userStorageService.setUserId = loginResult.userId;
                      this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                      this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                      this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                      this.webStorageService.set(StorageKeys.IsAuthorized, true);
                      return this.registrationService.getAccess(loginResult.userId).pipe(
                        tap((accessResponse: any) => {
                          this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                          this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);
                          this.setUserInfoInLS();

                          // Сразу попадаем в ЛК
                          switch (loginResult.role) {
                            case 'pupil': {
                              return this.router.navigate(['/pupil']);
                            }
                          }
                          this.removeWaiting();
                        }),
                      );
                    }
                  }),
                );
              }
            }),
          )
          .subscribe();
      }
    } else {
      this.failWaiting();
    }
  }

  public setUserInfoInLS(): void {
    const ui: IUserInfo = this.userDataHandlerService.getUserInfo();

    const defaultTerritoryId: string = EmptyGuid;

    const setAdminLevelLS = (ui: IUserInfo) => {
      const userRegionId = ui.regionId;
      const userMunicipalityId = ui.municipalityId;
      if (userRegionId != null && userMunicipalityId != null) {
        if (userRegionId == defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          this.webStorageService.set(StorageKeys.AdminLevel, AdminAccessLevel.GLOBAL);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          this.webStorageService.set(StorageKeys.AdminLevel, AdminAccessLevel.REGION);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId != defaultTerritoryId) {
          this.webStorageService.set(StorageKeys.AdminLevel, AdminAccessLevel.MUNICIPALITY);
        }
      }
    };

    if (!ui) {
      throw new Error('User info is not defined');
    }

    this.webStorageService.set(StorageKeys.ImagePath, ui.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, ui.firstName);
    this.webStorageService.set(StorageKeys.LastName, ui.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, ui.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, ui.gender);
    this.webStorageService.set(StorageKeys.CompanyId, ui.companyId);
    this.webStorageService.set(StorageKeys.Position, ui.position);
    this.webStorageService.set(StorageKeys.RegionId, ui.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, ui.municipalityId);

    if (this.webStorageService.get(StorageKeys.UserRole) == 'admin') {
      setAdminLevelLS(ui);
    }
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  public animateLogin(): void {
    if (this.buttonWaiting) {
      return;
    }

    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  public removeWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  public failWaiting = (): void => {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  };

  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }

  public focusOutErrorChecking(): void {
    this.focusOutPasswordErrors = this.isAnyPasswordError();
  }

  public additionEnable(): void {
    this.addition = !this.addition;
  }

  public checkFormatEmail(event): void {
    this.f.email['focused'] = false;
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }

  public checkEmailIsFree(event): void {
    this.f.email['focused'] = false;
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);

      if (this.checkEmail) {
        this.authHandlerService
          .checkEmailAvailability(this.f.email.value)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response: IIsEmailFreeResponse) => (this.duplicateUserName = !response.isFree));
      } else {
        this.duplicateUserName = false;
      }
    }
  }

  public checkFormatDate(event): void {
    if (event) {
      this.checkBirthday = true;
    }
  }

  public tooggleSelectClassType(): void {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
