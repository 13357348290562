<div class="lb-block-invite-welcome">
  <div class="invite-parent-row">
    <div class="add-child-column">
      <!-- <h3 class="h3-invite-parent">{{ 'SHARED.SMALL_BUSINESS' | translate }}</h3> -->
      <h3 class="h3-invite-parent">Спасибо за регистрацию!</h3>
      <!-- <p class="add-child-text">Для регистрации скопируйте этот код и передайте ребёнку</p> -->
      <p class="add-child-text">
        Когда ваш подросток пройдет диагностику, здесь появятся его результаты. А пока предлагаем ознакомиться с
        <a [routerLink]="['/professions']">каталогом профессий.</a>
      </p>
      <!-- <div class="child-codes">
        <div class="info-div">
          <button
            class="info-icon"
            [class.default-cursor]="child.registrationCode"
            (click)="generateRegLink()"
            (mouseover)="showToolTipe = true"
            (mouseout)="showToolTipe = false"
          ></button>
          <div class="info-popup" *ngIf="showToolTipe">
            <p *ngIf="!child.registrationCode">Этот код является паролем при регистрации.</p>
            <ng-container *ngIf="child.registrationCode">
              <p *ngIf="!linkIsGenerated">Сгенерировать ссылку для регистрации ребенка</p>
              <p *ngIf="linkIsGenerated">Ссылка скопирована</p>
            </ng-container>

            <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </div>
        <div class="profile-code">
          <div>Код ребенка</div>
          <div class="code-text">{{ child.registrationCode }}</div>
        </div>
      </div> -->
    </div>

    <img src="./profilum-assets/images/illustrations/Parent.svg" alt="" class="invite-parent-image add-child-img" />
  </div>
</div>
