import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prf-prof-actions',
  templateUrl: './prof-actions.component.html',
  styleUrls: ['./prof-actions.component.scss'],
})
export class ProfActionsComponent {
  @Input()
  public counter: number = 0;
  @Input()
  public favourite: boolean = false;
  @Output()
  public action: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public sendBack: EventEmitter<void> = new EventEmitter<void>();

  public get isIconHidden(): boolean {
    return this.counter === 0;
  }

  // Реакция ученика на профессию:
  // true - нравится
  // false - не нравится
  public onReaction(action: boolean): void {
    this.action.emit(action);
  }

  public onNavigation(): void {
    this.sendBack.emit();
  }
}
