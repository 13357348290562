<div class="show-messages">
  <ng-container *ngIf="confetti"><prf-confetti></prf-confetti></ng-container>
  <div class="show-messages-container" #list id="list">
    <div
      *ngFor="let message of messages | slice : 0 : endMessageNumber; let i = index; trackBy: trackByFn"
      class="show-messages-message"
      [class.show-messages-message-right]="message.position ?? message.position === 'right'"
      [style.animation-delay]="(i - startMessageNumber) * delay + delay + 'ms'"
    >
      <div
        class="show-messages-message-text"
        [class.show-messages-message__with-icon]="message.icon"
        [class.right-position]="message.position ?? message.position === 'right'"
      >
        <div [innerHTML]="message.text"></div>
        <img *ngIf="message.image" [src]="message.image" alt="/" class="show-messages-message-image"/>
        <img *ngIf="message.icon" class="show-messages-message-icon" [ngSrc]="message.icon" width="20" height="20"
             alt="/"/>
      </div>
    </div>
  </div>
  <div class="show-messages-footer">
    <ng-container *ngFor="let button of buttons; trackBy: trackByFn">
      <button
        type="button"
        class="show-messages-footer-button"
        [class.show-messages-footer-button_white]="button.style === 'white'"
        (click)="showActions(button.type)"
      >
        {{ button.text }}
      </button>
    </ng-container>
  </div>
</div>
