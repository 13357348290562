import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-recommended-classes',
  templateUrl: './recommended-classes.component.html',
  styleUrls: ['./recommended-classes.component.scss'],
})
export class RecommendedClassesComponent {
  @Input() set testResults(val: any) {
    this._testResults = val;
    this.setByResult();
  }

  public recommendedClasses: Array<any>;
  public colorList: any = ['green', 'purple', 'blue', 'turquoise', 'yellow', 'orange', 'brown', ''];
  public openResults: boolean = false;
  public isKruzhkiVariant: boolean = false;

  private _testResults: any;

  constructor(private router: Router) {
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
  }

  public ngOnInit(): void {
    this.openResults = this.router.url.includes('results-open');
  }

  public setByResult(): void {
    if (this._testResults && this._testResults.length > 0) {
      let classes = this._testResults.filter(d => d.objectType === 'ProfilClass' && d.results.length);
      this.recommendedClasses = classes.sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));
      this.recommendedClasses = this.recommendedClasses.slice(0, 3);

      //если Кружки - костылим склонения: меняем "ий" на "ая", "класс" на "группа"
      if (this.isKruzhkiVariant) {
        this.recommendedClasses.forEach((recommendedClassItem: any) => {
          recommendedClassItem.name = recommendedClassItem.name.replaceAll(/ий|ый/gi, 'ая').replace('класс', 'группа');
        });
      }
    }
  }
}
