import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of, throwError } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {
  ApiAuthService,
  AuthHandlerService,
  B2gSaasService,
  Helper,
  IUserInfo,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { BreakpointsComponent } from 'app/shared/common-components/breakpoints/breakpoints.component';
import { Steps } from '../open-registration-redesign/open-registration-redesign.component';
import { UserRoles } from 'app/shared/enums/userroles.enum';

export interface IUpdatePasswordModel {
  currentPassword: string;
  newPassword: string;
  newPassword2: string;
}

@Component({
  selector: 'prf-registration-change-password',
  templateUrl: './registration-change-password.component.html',
  styleUrls: ['./registration-change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationChangePasswordComponent extends BreakpointsComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public isMaskedRepeatPassword: boolean = true;
  public isMaskedNewPassword: boolean = true;
  public focusOutPasswordErrors: boolean = false;
  public charactersError: boolean = true;
  public letterError: boolean = true;
  public numberError: boolean = true;
  public whiteSpaceError: boolean = false;
  public rusLettersError: boolean = false;
  public errorChangePass: string = '';
  public passwordError: boolean = false;
  public incorrectRepeatPassword: boolean = false;
  public updatingPassword: boolean = false;
  public currentStep: Steps = Steps.Step1;
  public userRole: UserRoles = UserRoles.teacher;
  public isKruzhkiForm: boolean = false;
  public isResetPassword: boolean;
  public isSetPasswordByCodeRegistration: boolean = false;
  public isSameAsCode: boolean = false;
  public changePasswordText: string;
  public steps: typeof Steps = Steps;
  public errorStatus: string;
  private userEmail: string;
  private userPhoneNumber: string;
  private token: string;
  private registrationCode: string;
  private readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  private readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  private readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  private readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  private readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  private readonly testOneCapitalLetter: RegExp = REG_EXP.testOneCapitalLetter;
  private CHANGE_PASS_RU: { en: string; ru: string }[] = [
    {
      en: 'Incorrect password.',
      ru: '',
    },
    { en: 'Passwords must be at least 6 characters.', ru: 'Пароль должен быть не менее 6 символов.' },
    {
      en: `Passwords must have at least one lowercase ('a'-'z').`,
      ru: `Пароль должен иметь по крайней мере одину букву ('a'-'z').`,
    },
    {
      en: `Passwords must have at least one digit ('0'-'9').`,
      ru: `Пароль должен иметь как минимум одну цифру ('0'-'9').`,
    },
  ];
  private userInfo: IUserInfo;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private overlayBusyService: OverlayBusyService,
    private webStorageService: WebStorageService,
    private router: Router,
    private yandexMetricsService: YandexMetricsService,
    private activatedRoute: ActivatedRoute,
    private apiAuthService: ApiAuthService,
    private userActionsService: UserActionsService,
    private b2gSaasService: B2gSaasService,
    private authHandlerService: AuthHandlerService,
    private userDataHandlerService: UserDataHandlerService,
    protected breakpointsService: BreakpointsService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef, breakpointsService);
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.initForm();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    if (location.origin.includes('kruzhki')) {
      this.isKruzhkiForm = true;
    }
    this.changePasswordText = this.userRole === UserRoles.pupil ? 'PASS_CHANGE.REPEAT_PASSWORD' : 'PASS_CHANGE.REPEAT_PASSWORD_POLITE';
    this.userActionsService.setInitializationTime([
      YmItems.Pu_Reg_CreatePas_ClickEnterButton,
      YmItems.T_Reg_CreatePas_PasswordVisibility,
      YmItems.T_Reg_CreatePas_PasswordValidation,
    ]);
    this.activatedRoute.queryParams
      .pipe(
        tap((params: Params) => {
          this.userEmail = params.userEmail;
          this.userPhoneNumber = params.phone;
          this.token = params.token;
          this.registrationCode = params.registrationCode;
          this.isResetPassword =
            (Helper.isString(this.userEmail, true) || Helper.isString(this.userPhoneNumber, true)) && Helper.isString(this.token, true);
          this.isSetPasswordByCodeRegistration = Helper.isString(this.registrationCode, true);

          this.userInfo = this.userDataHandlerService.getUserInfo();

          if (this.isResetPassword || this.isSetPasswordByCodeRegistration) {
            return of(null);
          }
        }),
        this.unsubscribeOperator,
      )
      .subscribe({
        next: () => this.overlayBusyService.hide(),
        error: () => this.overlayBusyService.hide(),
      });
  }

  public toggleNewPassMask(): void {
    if (this.userRole === UserRoles.teacher) {
      this.userActionsService.log(YmItems.T_Reg_CreatePas_PasswordVisibility);
    }

    this.isMaskedNewPassword = !this.isMaskedNewPassword;
  }

  public toggleRepeatPassMask(): void {
    if (this.userRole === UserRoles.teacher) {
      this.userActionsService.log(YmItems.T_Reg_CreatePas_PasswordVisibility);
    }

    this.isMaskedRepeatPassword = !this.isMaskedRepeatPassword;
  }

  public focusOutErrorChecking(): void {
    if (this.form.value.newPassword !== '') {
      this.focusOutPasswordErrors =
        this.charactersError || this.letterError || this.numberError || this.rusLettersError || this.whiteSpaceError;
    }
  }

  public testNewPassword(event: string): boolean {
    this.passwordError = false;
    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;
    this.rusLettersError = false;
    this.whiteSpaceError = false;

    if (event && event.length > 0) {
      this.charactersError = !this.testSixCharter.test(event);
      this.letterError = !(this.testOneLetter.test(event) && this.testOneCapitalLetter.test(event));
      this.numberError = !this.testOneDigit.test(event);
      this.rusLettersError = this.testRusLetters.test(event);
      this.whiteSpaceError = !this.testWhiteSpace.test(event);
      this.isSameAsCode = event === this.userInfo?.registrationCode;

      switch (true) {
        case this.whiteSpaceError:
          return false;
        case this.charactersError:
          this.passwordError = true;
          return false;
        case this.letterError:
          this.passwordError = true;
          return false;
        case this.numberError:
          this.passwordError = true;
          return false;
        case this.rusLettersError:
          this.passwordError = true;
          return false;
        case this.isSameAsCode:
          this.passwordError = true;
          return false;
        default:
          this.testRepeatPassword();
          return true;
      }
    }
  }

  public isAccessAllowed(): boolean {
    return (
      (this.userInfo || this.isResetPassword || this.isSetPasswordByCodeRegistration) &&
      this.testNewPassword(this.form.value.newPassword) &&
      !this.incorrectRepeatPassword &&
      !this.submitted
    );
  }

  public testRepeatPassword(): void {
    this.incorrectRepeatPassword = this.form.value.newPassword !== this.form.value.repeatPassword || this.isSameAsCode;
    if (this.submitted) {
      this.submitted = this.incorrectRepeatPassword;
    }

    if (this.userRole === UserRoles.teacher && this.incorrectRepeatPassword) {
      this.yandexMetricsService.reachGoal(YmItems.T_Reg_CreatePas_PasswordMismatch);
    }
  }

  public continue(): void {
    this.submitted = true;
    if (this.currentStep === Steps.Step1) {
      this.submitChangedPassword();
    } else if (this.isResetPassword) {
      this.navigateToLk();
    }
  }

  private submitChangedPassword(): void {
    this.testRepeatPassword();

    if (this.incorrectRepeatPassword) {
      return;
    }

    if (this.isSetPasswordByCodeRegistration) {
      this.registerWithSetPassword();
      return;
    }

    this.isResetPassword ? this.resetPassword() : this.updatePassword();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      newPassword: new UntypedFormControl('', [Validators.maxLength(256)]),
      repeatPassword: new UntypedFormControl('', [Validators.maxLength(256)]),
    });
  }

  private registerWithSetPassword(): void {
    this.overlayBusyService.show();

    const registerWithSetPasswordRequestData = {
      registrationCode: this.registrationCode,
      userName: this.userPhoneNumber || this.userEmail,
      newPassword: this.form.value.newPassword,
      newPassword2: this.form.value.newPassword,
      isConsentToMailing: false,
    };

    this.b2gSaasService
      .registerWithSetPassword(registerWithSetPasswordRequestData)
      .pipe(
        tap((response: any) => {
          if (response?.status !== 'Success') {
            if (response?.comment === 'Сode has already been used') {
              this.utilsService.openSnackBar('👎 Данный код уже использован', 'error', 3000);
            }
            return throwError(null);
          } else {
            this.authHandlerService.login(response.userName, this.form.value.newPassword, true);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: () => {
          this.sendMetrics();
          this.overlayBusyService.hide();
        },
        error: () => this.overlayBusyService.hide(),
      });
  }

  private sendMetrics(): void {
    switch (this.userDataHandlerService.getUserInfo().role) {
      case UserRoles.pupil:
        this.yandexMetricsService.reachGoal(YmItems.RegistrationPupil_ConfirmButton);
        this.userActionsService.log(YmItems.Pu_Reg_Hellow_ClickEnterButton);
        break;

      case UserRoles.teacher:
        this.yandexMetricsService.reachGoal(YmItems.RegistrationTeacher_ConfirmButton);
        this.userActionsService.log(YmItems.T_Reg_Confirm_ClickConfirmButton);
        break;

      default:
        break;
    }
  }

  private updatePassword(): void {
    this.updatingPassword = true;
    const updatePasswordModel: IUpdatePasswordModel = {
      currentPassword: this.userDataHandlerService.getUserInfo().registrationCode,
      newPassword: this.form.value.newPassword,
      newPassword2: this.form.value.newPassword,
    };

    this.errorChangePass = '';

    if (!this.focusOutPasswordErrors) {
      this.b2gSaasService
        .changeUserPassword(updatePasswordModel)
        .pipe(this.unsubscribeOperator)
        .subscribe({
          next: changedResult => {
            if (changedResult) {
              if (changedResult.status === 'Success') {
                this.updatingPassword = false;
                this.changeDetectorRef.detectChanges();
                if (!this.isKruzhkiForm) {
                  this.utilsService.openSnackBar('👌 Пароль установлен', 'success', 3000);
                }
                this.navigateToLk();
              } else {
                this.updatingPassword = false;
                this.utilsService.openSnackBar('Изменения не сохранены', 'error');

                if (changedResult.comment) {
                  if (changedResult.comment.includes('Incorrect password')) {
                    this.errorChangePass = 'Неверный старый пароль.';
                  } else {
                    this.errorChangePass = this.translateChangePassRequest(changedResult.comment);
                  }
                }
              }
            }
          },
          error: () => {
            this.updatingPassword = false;
            this.utilsService.openSnackBar('Изменения не сохранены', 'error');
          },
        });
    }
  }

  private resetPassword(): void {
    this.updatingPassword = true;
    const resetPassword = Helper.isString(this.userEmail, true)
      ? this.apiAuthService.updatePasswordByEmail(this.userEmail, this.form.value.newPassword, this.token)
      : this.apiAuthService.updatePasswordByPhone(this.userPhoneNumber, this.form.value.newPassword, this.token);

    resetPassword.pipe(this.unsubscribeOperator).subscribe((resetResult: any) => {
      if (resetResult?.status === 'Success') {
        this.currentStep = Steps.Step2;
        this.updatingPassword = false;
        if (this.isKruzhkiForm) {
          sessionStorage.setItem(StorageKeys.IsRedirectAfterRegistrationOnKruzhki, 'true');
        }
        this.changeDetectorRef.detectChanges();
      } else {
        this.updatingPassword = false;
        this.router.navigate(['/login']);
      }
    });
  }

  private translateChangePassRequest(comment: string): string {
    const findText = this.CHANGE_PASS_RU.filter(cp => cp.en === comment);

    if (findText && findText.length) {
      return findText[0].ru;
    } else {
      return comment;
    }
  }

  private navigateToLk(): void {
    let userName: string;
    if (this.isResetPassword) {
      userName = Helper.isString(this.userEmail, true) ? this.userEmail : this.userPhoneNumber;
    } else {
      const userInfo = this.userDataHandlerService.getUserInfo();

      userName = Helper.isString(userInfo.email, true)
        ? userInfo.email
        : userInfo.phoneNumber;
    }
    if (this.userRole === UserRoles.pupil) {
      this.webStorageService.set(StorageKeys.ShowGreetingOnboarding, true);
    }
    this.authHandlerService.login(userName, this.form.value.newPassword, true);
  }
}
