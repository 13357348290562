<section class="prf-banner">
  <prf-h1 class="prf-banner-title">{{ bannerData.title }}</prf-h1>
  <prf-description class="prf-banner-description">{{ bannerData.description }}</prf-description>
  <prf-button
    class="prf-banner-button"
    [type]="ButtonType.PrimaryB2GRedesigned"
    [isFullWidth]="true"
    [radiusType]="ButtonRadiusType.X4"
    [heightType]="ButtonHeightType.AUTO"
    [buttonTitle]="bannerData.buttonText"
    [useCofoSansFont]="true"
    (emitClick)="navigateToLogin()"
  />
  <prf-image class="prf-banner-image" [image]="bannerData.image" [priority]="true" />
</section>
<div class="prf-banner-info-block">
  <prf-info-block [infoBlockData]="bannerData.infoBlocks" />
</div>
