<prf-menu></prf-menu>

<div class="content-section">
  <div class="container container-1000 prof-page-cont">
    <prf-page-header [title]="'Избранное'"></prf-page-header>

    <div *ngIf="loaded" class="professions-section">
      <ng-container *ngIf="userFavorites && userFavorites.length > 0; else emptyContent">
        <div class="catalog-events-row">
          <prf-course-card *ngFor="let course of userFavorites" [course]="course"></prf-course-card>
        </div>
      </ng-container>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>

<ng-template #emptyContent>
  <div class="empty-block">
    <div><img src="./assets/images/icons/favourites.svg" width="80" class="no-profession-icon" /></div>
    <div class="empty-block-text">{{ 'SHARED.YOU_HAVE_NOT_ADDED_ANYTHING' | translate }}</div>
  </div>
</ng-template>
