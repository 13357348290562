import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownItemType } from './types';

@Component({
  selector: 'prf-dropdown-component',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  @ViewChild('dropDown') private dropDown: ElementRef;
  @Input()
  public dropdownClasses: string = '';
  @Input()
  public isDisabled: boolean = false;
  @Input()
  public isOnlyDisabledVariant: boolean = false;
  @Input()
  public placeholderText: string = 'Все опции';
  @Input()
  public dropdownItems: DropdownItemType[] = [];
  @Input()
  public selectedOption: DropdownItemType = this.dropdownItems[0];
  @Input()
  public isSorting: boolean = false;
  @Input()
  public clearSortText: string = 'Очистить';
  @Input()
  public isActiveSort: boolean = false;
  @Input()
  public tooltipVisible: boolean = false;

  @Output()
  public updateValue: EventEmitter<any> = new EventEmitter<any>();

  public isOpened: boolean = false;

  public toggleDropdown(): void {
    this.isOpened = !this.isOpened;
  }

  public selectOption(clickedOption?: DropdownItemType): void {
    this.updateValue.emit(clickedOption);
    this.toggleDropdown();
  }

  public trackByFn(index: number): number {
    return index;
  }

  @HostListener('window:click', ['$event.target'])
  private closeDropdown(target: ElementRef): void {
    if (this.isOpened && !this.dropDown.nativeElement.contains(target)) {
      this.isOpened = false;
    }
  }
}
