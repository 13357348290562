<div class="filters-container" [ngClass]="{ 'fixed-menu': isMenuFixed }">
  <section class="filters-wrapper">
    <div class="filters-section">
      <span class="filter-title">
        {{ 'SHARED.COMPARISON' | translate }}
      </span>
      <div class="checkbox-wrapper">
        <div class="checkbox" (click)="toggleMunicipalityCharts()" [class.checked]="showMunicipityReport"></div>
        Среднее по муниципалитету
      </div>
    </div>
    <div class="filters-section classes-filters">
      <span class="filter-title">
        {{ 'SHARED.PARALLEL' | translate }}
      </span>
      <div class="filter-tabs">
        <button
          *ngFor="let classVariant of ClassVariants"
          type="button"
          class="tab-button class-button"
          [class.w-current]="activeFilterTab === classVariant.key"
          [value]="classVariant.key"
          (click)="setFilterTab(FilterVariantsEnum.CLASS, classVariant.key)"
        >
          {{ classVariant.value }} {{ 'SHARED.CLASS_LOWERCASE' | translate }}
        </button>
      </div>
    </div>
    <div class="filters-section genders-filters">
      <span class="filter-title">
        {{ 'SHARED.FORM.GENDER' | translate }}
      </span>
      <div class="filter-tabs">
        <button
          *ngFor="let genderVariant of GenderVariants"
          type="button"
          class="tab-button gender-button square-button"
          [class.w-current]="activeFilterTab === genderVariant.value"
          [value]="genderVariant.value"
          (click)="setFilterTab(FilterVariantsEnum.GENDER, genderVariant.value)"
        >
          {{ genderVariant.buttonText | translate }}
        </button>
      </div>
    </div>
  </section>
  <div class="background-overlay"></div>
</div>
