import { IDropdown } from '../interfaces/prf-anketa.interfaces';

export const APPROBATION_SLIDES_COUNT: number = 20;
export const APPROBATION_SLIDES_MINUTES: number = 15;
export const MUF_SLIDES_COUNT: number = 15;
export const MUF_SLIDES_MINUTES: number = 15;

export const APPROBATION_TEST_VARIANTS: IDropdown[] = [
  { name: 'Класс 1 - 5', value: '363d8955-21ab-df1b-bc1d-78e73a38ca9b' },
  { name: 'Класс 6 - 7', value: 'a9de6356-d365-4fed-ae99-151ba2aeecdf' },
  { name: 'Класс 8 - 9', value: '37461367-9ee1-481c-b965-efb063779603' },
  { name: 'Класс 10 - 11', value: 'a5c57c7c-88e4-87cc-bf2a-835cc00bbbdc' },
];

export const OPEN_CONTOUR_TEST_VARIANTS: IDropdown[] = [
  { name: 'Класс 6 - 7', value: '8c3aa86c-56f9-463a-80d5-98f00019f64f' },
  { name: 'Класс 8 - 9', value: 'eb21381d-7a78-42cf-a6fe-559678bb0d74' },
  { name: 'Класс 10 - 11', value: '4dd17492-c158-1a35-f60c-203932f03004' },
];

export enum SessionStatusEnum {
  Success = 'Success',
  Uncompleted = 'Uncompleted test',
  Untested = 'Untested user',
  Failed = 'Failed',
}

export enum BvbUrlEnum {
  BvbLkStudentUrlDev = 'https://dev.bvbinfo.ru/lk-student/results/profilum',
  BvbLkErrorTimeoutUrlDev = 'https://bvbinfo.ru/lk-student/results/profilum?error=timeout',
  BvbLkStudentUrlProd = 'https://bvbinfo.ru/lk-student/results/profilum',
  BvbLkErrorTimeoutUrlProd = 'https://bvbinfo.ru/lk-student/results/profilum?error=timeout',
  BvbLkParentUrlDev = 'https://dev.bvbinfo.ru/lk-parent/profile/diagnostics/karernaya_gramotnost_roditelya',
  BvbLkParentUrlProd = 'https://bvbinfo.ru/lk-parent/profile/diagnostics/karernaya_gramotnost_roditelya',
}

export const bvbLkDiagnosticQueryParams: Record<string, string> = {
  'aa458a31-2297-4678-81d6-e98e88078cfd': 'social_intelligence',
  'cf56049d-b9ff-4bd9-8fac-3910106a6aa6': 'emotional_intellect',
  '28ca16f1-0fad-4331-87b9-e13d4656e764': 'creative_thinking',
  'a532b22e-3fbf-4334-ba1a-6c954979ec4b': 'possibilities',
  'acd497fd-e26e-41b6-b6ff-e1eb4a4e0c88': 'talents',
};

export const bvbDiagnosticsWithYm: Record<string, string> = {
  'acd497fd-e26e-41b6-b6ff-e1eb4a4e0c88': 'Тест для платформы "Билет в будущее"',
  'a532b22e-3fbf-4334-ba1a-6c954979ec4b': 'Лайт-версия "Билет в будущее"',
};

export enum bvbDiagnostics {
  Talents = 'acd497fd-e26e-41b6-b6ff-e1eb4a4e0c88',
}
