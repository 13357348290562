import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ApiProfessionsService, ISpecialityData, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-specialty-card',
  templateUrl: './specialty-card.component.html',
  styleUrls: ['./specialty-card.component.scss'],
})
export class SpecialtyCardComponent implements OnInit {
  @Input() specialty: ISpecialityData;

  userId: string;
  userRole: string;
  popUpConfirming: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private apiProfessionsService: ApiProfessionsService,
    private utilsService: UtilsService,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {}

  ngOnInit() {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  deleteSpecialty(id) {
    this.apiProfessionsService
      .deleteSpecialty(id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Специальность удалена`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/specialties']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
