<div class="prf-teacher-with-no-classes-banner">
  <div class="prf-teacher-with-no-classes-banner-text">
    <span class="prf-teacher-with-no-classes-banner-title">Нет назначенных {{ dictionary.ClassesGenitive }}</span>
    <p class="prf-teacher-with-no-classes-banner-text">
      Если у вас {{ dictionary.MustBeClass }} быть {{ dictionary.Class }} или {{ dictionary.Classes }}, попросите школьного администратора
      назначить вас {{ dictionary.TeacherAblative }} для них
    </p>
  </div>
  <div class="prf-teacher-with-no-classes-banner-image"></div>
</div>
