import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { IChangePhoneInfo } from 'app/shared/common-components/pupils-table/pupils-table.component';
import { takeUntil } from 'rxjs/operators';
import { ApiUsersService, IUpdateRegistrationCode, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-change-pupil-phone-popup',
  templateUrl: './change-pupil-phone-popup.component.html',
  styleUrls: ['./change-pupil-phone-popup.component.scss'],
})
export class ChangePupilPhonePopupComponent extends UnsubscribeComponent {
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() closeModalWithSave = new EventEmitter<boolean>();
  public _changePhoneInfo: IChangePhoneInfo;
  public updateRegistrationCode: IUpdateRegistrationCode;

  @Input() set changePhoneInfo(changePhoneInfo: IChangePhoneInfo) {
    this._changePhoneInfo = changePhoneInfo;
    if (changePhoneInfo) {
      this.updateRegistrationCode = this.createUpdateRegistrationCode(changePhoneInfo.pupil, changePhoneInfo.newPhone);
    }
  }

  constructor(private apiUsersService: ApiUsersService, private webStorageService: WebStorageService) {
    super();
  }

  public closePopup(): void {
    this.closeModal.emit(false);
  }

  public closeSuccessPopup(): void {
    this.closeModal.emit(true);
  }

  public updatePupilPhone(): void {
    this.apiUsersService
      .updateRegistrationCodeTeachers(this.updateRegistrationCode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.closeModalWithSave.emit(true);
      });
  }

  private createUpdateRegistrationCode(pupil: IPupilsInClass, newPhone: string): IUpdateRegistrationCode {
    const schoolId: string = this.webStorageService.get(StorageKeys.SchoolId);
    return {
      userId: pupil.userId,
      schoolId: schoolId,
      firstName: pupil.firstName,
      lastName: pupil.lastName,
      gender: pupil.gender,
      phoneNumber: newPhone.replace(/\D/g, ''),
      role: 'pupil',
    };
  }
}
