import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { take } from 'rxjs/operators';

import {
  ApiAuthService,
  ApiUsersService,
  B2gSaasService,
  Helper,
  IUpdateRegistrationCode,
  RamStorageService,
  StorageKeys,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { IChildInfo } from '../parent.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TEXT_MASK } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.scss'],
})
export class ChildEditComponent extends UnsubscribeComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean = false;
  public childInfo: IChildInfo;
  public genderLocked: boolean = true;
  public currentGender: string;
  public readonly gendersView = {
    female: {
      key: 'F',
      name: 'Женский',
    },
    male: {
      key: 'M',
      name: 'Мужской',
    },
  };
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly dateMask: Array<string | RegExp> = TEXT_MASK.dateMaskWithDots;
  public readonly phoneMask: Array<string | RegExp> = TEXT_MASK.newPhoneMask;

  private readonly emailRegExp: RegExp = REG_EXP.emailRegExp;

  @Input() viewMode: boolean = true;
  @Output() editedUser: EventEmitter<IChildInfo> = new EventEmitter<IChildInfo>();

  constructor(
    private fb: UntypedFormBuilder,
    private apiUsersService: ApiUsersService,
    private utilsService: UtilsService,
    private ramStorageService: RamStorageService,
    private b2gSaasService: B2gSaasService,
    private apiAuthService: ApiAuthService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.ramStorageService
      .get(StorageKeys.CurrentChild)
      .pipe(this.unsubscribeOperator)
      .subscribe((child: IChildInfo) => {
        this.initForm(child);
        this.currentGender = child.gender;
        this.childInfo = child;
      });
  }

  public get f() {
    return this.form.controls;
  }

  public changePhone(): void {
    this.submitted = true;
    if (this.form.valid) {
      const child: IUpdateRegistrationCode = {
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.childInfo.middleName,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        gender: this.f.gender.value,
        schoolId: this.childInfo.schoolId,
        userId: this.childInfo.userId,
        role: 'pupil',
      };

      this.apiUsersService
        .updateRegistrationCodeParents(child)
        .pipe(this.unsubscribeOperator)
        .subscribe(
          () => {
            this.childInfo.phoneNumber = this.f.phone.value?.replace(/\D/g, '');
            this.editedUser.emit(this.childInfo);
          },
          () => {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error', 2000);
            this.editedUser.emit(this.childInfo);
          },
        );
    }
  }

  public checkPhone(): void {
    const phone: string = this.f.phone.value.replace(/\D/g, '');

    if (!Helper.isValidPhoneNumber(phone)) {
      this.f.phone.setErrors({ pattern: true });
      return;
    }
    
    this.apiAuthService
      .checkPhoneAvailability(phone)
      .pipe(take(1), this.unsubscribeOperator)
      .subscribe(resp => {
        if (!resp.free) {
          this.f.phone.setErrors({ isUsed: true });
        }
      });
  }

  private initForm(childInfo: IChildInfo): void {
    this.form = this.fb.group({
      lastName: new UntypedFormControl({ value: childInfo.lastName, disabled: true }, [Validators.required]),
      firstName: new UntypedFormControl({ value: childInfo.firstName, disabled: true }, [Validators.required]),
      phone: new UntypedFormControl({ value: '+' + childInfo.phoneNumber, disabled: this.viewMode }, [
        Validators.pattern(this.phoneRegExp),
      ]),
      email: new UntypedFormControl({ value: childInfo.email, disabled: true }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      date: new UntypedFormControl(
        {
          value: DateHelper.toDayJsInUTC(childInfo.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.dateRegExp)],
      ),
      gender: new UntypedFormControl({ value: childInfo.gender, disabled: true }, [Validators.required]),
      schoolName: new UntypedFormControl({ value: childInfo.schoolName, disabled: true }, [Validators.required]),
      className: new UntypedFormControl({ value: this.getClassName(childInfo), disabled: true }, [Validators.required]),
    });
  }

  private getClassName(childInfo: IChildInfo): string {
    return childInfo.schoolClassNumber + ' ' + childInfo.schoolClassLetter + ' класс';
  }

  public switchGender(key: string): void {
    this.currentGender = key;
  }
}
