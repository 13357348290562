import { StorageKeys } from '@profilum-library';

export const profNavigationInnerHTML = (data: any, color: string) => {
  const rotateValue = parseInt(data) > 0 ? 'rotate(0deg)' : parseInt(data) < 0 ? 'rotate(180deg)' : 'rotate(90deg)';
  return `<div class="data-container" style="position: relative;height: 45px;margin: 0 10px;padding: 0 10px;width: auto;
  background: #ffffff; box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05); border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;
  display: flex; align-items: center; justify-content: center;">
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${color};">${data}%</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValue};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${color}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${color}"/>
  </svg>
</div>
</div>
</div>`;
};

export const professionsMatchingInnerHTML = (data: any, color: string) => {
  const rotateValue = parseInt(data) > 0 ? 'rotate(0deg)' : parseInt(data) < 0 ? 'rotate(180deg)' : 'rotate(90deg)';
  return `<div class="data-container" style="position: relative;height: 45px;margin: 0 10px;padding: 0 10px;width: auto;background: #ffffff;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;
  display: flex;align-items: center;justify-content: center;">
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;">
    <div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
    color: ${color};">${data}%</div>
    <div class="item-pictogram" style="margin-left: -20px;margin-top: 3px;">
      <svg
        style="transform: ${rotateValue};"
        width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 6L7 1L1 6"stroke="${color}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
        <rect
          x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${color}"/>
      </svg>
    </div>
  </div>
</div>`;
};

export const talentDistributionInnerHTML = (dataSet: any[], colorSet: string[]) => {
  const rotateValues = dataSet.map(data => (parseInt(data) > 0 ? 'rotate(0deg)' : parseInt(data) < 0 ? 'rotate(180deg)' : 'rotate(90deg)'));
  dataSet = dataSet.map(el => {
    if (parseInt(el) > 0) {
      return '+' + el + '%';
    } else if (parseInt(el) < 0) {
      return el + '%';
    } else {
      return '=%';
    }
  });
  return `<div class="data-container" style="position: relative;height: 45px;margin: 0 10px;padding: 0 10px;width: auto;
      background: #ffffff;box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;
      display: flex;align-items: center;justify-content: center;">
  <div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
    <div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
    color: ${colorSet[0]};">${dataSet[0]}</div>
    <div class="item-pictogram" style="margin-left: -20px;margin-top: 3px;">
      <svg
        style="transform: ${rotateValues[0]};"
        width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 6L7 1L1 6"stroke="${colorSet[0]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
        <rect
          x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[0]}"/>
      </svg>
    </div>
  </div>
  <div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
  <div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
  color: ${colorSet[1]};">${dataSet[1]}</div>
  <div class="item-pictogram" style="margin-left: -12px; margin-top: 3px;">
    <svg
      style="transform: ${rotateValues[1]};"
      width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6L7 1L1 6"stroke="${colorSet[1]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
      <rect
        x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[1]}"/>
    </svg>
  </div>
</div>
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${colorSet[2]};">${dataSet[2]}</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValues[2]};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${colorSet[2]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[2]}"/>
  </svg>
</div>
</div>
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${colorSet[3]};">${dataSet[3]}</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValues[3]};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${colorSet[3]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[3]}"/>
  </svg>
</div>
</div>
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${colorSet[4]};">${dataSet[4]}</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValues[4]};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${colorSet[4]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[4]}"/>
  </svg>
</div>
</div>
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;margin-right: 15px;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${colorSet[5]};">${dataSet[5]}</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValues[5]};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${colorSet[5]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[5]}"/>
  </svg>
</div>
</div>
<div class="data-list-item" style="display: flex;align-items: center;flex-direction: row;justify-content: center;">
<div class="item-value" style=" margin-right: 5px;font-family: Avenirnextcyr, sans-serif;font-weight: bold;font-size: 14px;line-height: 16px;
color: ${colorSet[6]};">${dataSet[6]}</div>
<div class="item-pictogram" style="margin-top: -15px;margin-left: -6px;">
  <svg
    style="transform: ${rotateValues[6]};"
    width="14"height="17"viewBox="0 0 14 17"fill="none"xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L7 1L1 6"stroke="${colorSet[6]}"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"/>
    <rect
      x="8"y="17"width="2"height="15"rx="1"transform="rotate(-180 8 17)"fill="${colorSet[6]}"/>
  </svg>
</div>
</div>
  </div>`;
};

export const defaultChartData = {
  datasets: [
    {
      type: 'bar',
      fill: false,
      backgroundColor: '#FDCF00',
      hoverBackgroundColor: '#FDCF00',
      pointStyle: 'rectRounded',
      borderWidth: 0,
    },
    {
      type: 'bar',
      fill: false,
      backgroundColor: '#36CA75',
      pointStyle: 'rectRounded',
      borderWidth: 0,
    },
    {
      type: 'bar',
      fill: false,
      backgroundColor: '#7852FB',
      pointStyle: 'rectRounded',
      borderWidth: 0,
    },
  ],
};

export const defaultAdditionalChartData = {
  type: 'bar',
  options: {
    indexAxis: 'y',
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          color: '#333333',
          font: {
            family: 'Avenirnextcyr',
            size: 12,
          },
        },
        onClick: function (event, legendItem) {
          const index = legendItem.datasetIndex;
          const ci = this.chart;
          const meta = ci.getDatasetMeta(index);

          if (index === 0) {
            this.webStorageService.get(StorageKeys.DefaultEdFilters)
              ? this.webStorageService.get(StorageKeys.DefaultEdFilters) === 'true'
                ? this.webStorageService.set(StorageKeys.DefaultEdFilters, 'false')
                : this.webStorageService.set(StorageKeys.DefaultEdFilters, 'true')
              : null;
          }

          meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          ci.update();
        },
      },
      tooltip: {
        mode: 'point',
        callbacks: {
          title: function (tooltipItem) {
            const title = String(tooltipItem[0].label);
            return title;
          },
          label: function () {
            return '';
          },
          afterLabel: function (tooltipItem, data) {
            const result = tooltipItem.formattedValue;
            const tooltipText = tooltipItem.dataset.tooltipText || '';
            return result ? result + ' ' + tooltipText : '';
          },
        },
        backgroundColor: 'rgba(1, 51, 112, 0.8)',
        fontSize: 10,
        bodyFontColor: '#fff',
        bodyFontFamily: 'Avenirnextcyr',
        titleFontColor: '#fff',
        titleFontSize: 10,
        titleFontFamily: 'Avenirnextcyr',
        displayColors: false,
      },
    },
    scales: {
      x: {
        position: 'top',
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            family: 'Avenirnextcyr',
            size: 10,
          },
          color: '#333333',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            family: 'Avenirnextcyr',
            size: 16,
            weight: 'normal',
          },
          color: '#333333',
        },
        afterFit: function (scale) {
          scale.width = 150;
        },
      },
    },
    onHover() {
      const el = document.getElementsByTagName('canvas')[0];
      el.style.cursor = 'url(/profilum-assets/images/icons/filters/cursor.svg), pointer';
    },
  },
};

export const ClassVariants: { key: string; value: number }[] = [
  {
    key: 'seventhClass',
    value: 7,
  },
  {
    key: 'eighthClass',
    value: 8,
  },
  {
    key: 'ninthClass',
    value: 9,
  },
  {
    key: 'tenthClass',
    value: 10,
  },
  {
    key: 'eleventhClass',
    value: 11,
  },
];

export const GenderVariants: { buttonText: string; value: string }[] = [
  {
    buttonText: 'BUTTONS.GENDER.M',
    value: 'male',
  },
  {
    buttonText: 'BUTTONS.GENDER.F',
    value: 'female',
  },
];

export const education = [
  {
    value: 'CHARTS.REPRESENTATIONS_OF_TOPICS.FULL.UP',
    title: 'Представленность тематик(по возрастанию)',
  },
  {
    value: 'CHARTS.REPRESENTATIONS_OF_TOPICS.FULL.DOWN',
    title: 'Представленность тематик (по убыванию)',
  },
  {
    value: 'CHARTS.WANT_TO_ATTEND.FULL.UP',
    title: 'Хотят посещать (по возрастанию)',
  },
  {
    value: 'CHARTS.WANT_TO_ATTEND.FULL.DOWN',
    title: 'Хотят посещать (по убыванию)',
  },
  {
    value: 'CHARTS.VISIT_NOW.FULL.UP',
    title: 'Посещают сейчас (по возрастанию)',
  },
  {
    value: 'CHARTS.VISIT_NOW.FULL.DOWN',
    title: 'Посещают сейчас (по убыванию)',
  },
];

export const TRANSLATIONS = [
  'CHARTS.KEY_INDICATORS.FULL',
  'CHARTS.TALENT_DISTRIBUTION.FULL',
  'CHARTS.PROFIL_CLASSES.FULL',
  'CHARTS.PROFIL_CLASSES.TOOLTIP',
  'CHARTS.EDUCATION_LEVEL.FULL',
  'CHARTS.PLACES_BUDGET.FULL',
  'COMMON.PLACES',
  'CHARTS.PLACES_NOT_BUDGET.FULL',
  'CHARTS.POTENTIALITY_ARRIVE.FULL',
  'COMMON.PEOPLES',
  'CHARTS.PLACES_BUDGET.FULL',
  'COMMON.PLACES',
  'CHARTS.PLACES_NOT_BUDGET.FULL',
  'CHARTS.POTENTIALITY_ARRIVE.FULL',
  'COMMON.PEOPLES',
  'CHARTS.REPRESENTATIONS_OF_TOPICS.FULL',
  'COMMON.EVENTS',
  'CHARTS.WANT_TO_ATTEND.FULL',
  'COMMON.PEOPLES',
  'CHARTS.VISIT_NOW.FULL',
  'COMMON.PEOPLES',
];
