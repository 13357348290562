import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'prf-child-code-registration',
  templateUrl: './child-code-registration.component.html',
  styleUrls: ['./child-code-registration.component.scss'],
})
export class ChildCodeRegistrationComponent implements OnInit {
  @Input() child: any;
  public showToolTipe: boolean = false;

  linkIsGenerated: boolean = false;

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit() {}

  generateRegLink() {
    if (this.child.registrationCode) {
      let registrationUrl = location.origin + '/registration-pupil?code=' + this.child.registrationCode;
      this.clipboardService.copy(registrationUrl);
      setTimeout(() => (this.linkIsGenerated = true), 100);
    }
  }
}
