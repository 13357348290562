import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { UserInfoClass } from '../../../../shared/classes/userInfo.class';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import {
  ApiAuthService,
  ApiProfilesService,
  B2gSaasService,
  Helper,
  UserActionsService,
  UserDataHandlerService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';
import { DateFormatService } from '../../../../ui-kit/services/utils-services/date-format.service';

@Component({
  selector: 'prf-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
})
export class EditUserProfileComponent extends UnsubscribeComponent implements OnInit {
  public form: FormGroup;

  public date: string;
  public readonly dateMask: Array<string | RegExp> = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private emailRegExp: RegExp = REG_EXP.emailRegExp;
  private isPhoneChanged: boolean = false;

  @Input() private user: UserInfoClass;

  @Output() private editProfile: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apiProfilesService: ApiProfilesService,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private dateFormatService: DateFormatService,
    private userActionsService: UserActionsService,
    private b2gSaasService: B2gSaasService,
    private apiAuthService: ApiAuthService,
    private userDataHandlerService: UserDataHandlerService,

  ) {
    super();
  }

  public ngOnInit(): void {
    this.setFormGroup();
  }

  private setFormGroup(): void {
    this.form = this.formBuilder.group({
      lastName: new FormControl<string | null>(this.user.lastName, [Validators.required]),
      firstName: new FormControl<string | null>(this.user.firstName, [Validators.required]),
      middleName: new FormControl<string | null>(this.user.middleName),
      phoneNumber: new FormControl<string | null>(this.user.phoneNumber, [Validators.required]),
      email: new FormControl<string | null>(this.user.email),
      birthday: new FormControl<string | null>(this.user.birthday),
      gender: new FormControl<string | null>(this.user.gender, [Validators.required]),
    });
  }

  private resetFormGroupValues(): void {
    for (const formKey in this.form.value) {
      this.form.controls[formKey].setValue(this.user[formKey]);
    }
  }

  private checkFormChanges(): boolean {
    let isChanged: boolean = false;
    for (const formKey in this.form.value) {
      if (this.form.value[formKey] !== this.user[formKey]) {
        isChanged = true;
        if (formKey === 'phoneNumber') {
          this.isPhoneChanged = true;
        }
      }
    }
    return isChanged;
  }

  public cancelEdit(): void {
    this.editProfile.emit(false);
    this.resetFormGroupValues();
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  private setUserFields(): void {
    for (const formKey in this.form.value) {
      this.user[formKey] = this.form.value[formKey];
    }
  }

  public submitChanges(): void {
    if (!this.checkFormChanges()) {
      return;
    }
    if (this.isPhoneChanged) {
      this.apiAuthService
        .checkPhoneAvailability(this.form.value.phoneNumber)
        .pipe(take(1), this.unsubscribeOperator)
        .subscribe(response => {
          if (!response.free) {
            this.formControls.phoneNumber.setErrors({ alreadyRegistered: true });
          }
        });
    }
    if (this.form.valid) {
      this.setUserFields();
      this.apiProfilesService
        .updateUserProfile(this.user)
        .pipe(
          tap(() => {
            this.editProfile.emit(true);
            this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
            this.userDataHandlerService.fetchUserInfo();
          }),
          catchError(err => {
            return this.getTranslation('SHARED.SOMETHING_WENT_WRONG').pipe(
              switchMap(translation => {
                console.error(translation);
                return throwError(err);
              }),
            );
          }),
        )
        .subscribe(() => {
          this.userActionsService.log(YmItems.T_Profile_SaveChanges);
        });
    }
  }

  public checkFormatEmail(): void {
    if (this.form.value.email && !this.emailRegExp.test(this.form.value.email)) {
      this.formControls.email.setErrors({ wrongFormat: 'Wrong e-mail format' });
    }
  }

  public checkFormatBirthDate(): void {
    if (this.form.value.birthday && !this.dateFormatService.isValidDateFormat(this.form.value.birthday)) {
      this.formControls.birthday.setErrors({ wrongFormat: 'Wrong date format' });
    }
  }

  public checkPhone(): void {
    let phone: string = this.formControls.phoneNumber.value;
    if (phone) {
      phone = Helper.phoneValidation(phone);
      if (phone.length < 11) {
        this.formControls.phoneNumber.setErrors({ pattern: true });
        return;
      }
      this.formControls.phoneNumber.setValue(phone.replace(/\D/g, ''));
    }
  }

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
