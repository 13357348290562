<div class="header" [class.welc-header]="isWelcome">
  <div class="mobile-menu-button" (click)="showMobileMenu()">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </div>

  <ng-template [ngIf]="showClassDropdown && userInfo.role === 'teacher'">
    <div class="select-class-wrapper">
      <prf-dropdown-component
        [isOnlyDisabledVariant]="isOnlyDisabledDropdownVariant"
        [dropdownItems]="dropdownClassesOptions"
        [selectedOption]="dropdownSelectedOption"
        [placeholderText]="'Все ' + dictionary.Classes"
        [tooltipVisible]="true"
        (updateValue)="selectClass($event)"
      ></prf-dropdown-component>
      <span class="course-name">
        {{ dropdownSelectedOption?.data?.course ? dropdownSelectedOption?.data?.course.name : 'Диагностика на таланты' }}</span
      >
    </div>
  </ng-template>

  <ng-template [ngIf]="userInfo.role" [ngIfElse]="anonymousHeader">
    <ng-template [ngIf]="isWelcome" [ngIfElse]="generalHeader">
      <div class="class-head-row welcome-row">
        <div class="welcome-h1-div">
          <h1 class="welcome-h1-2">{{ title }}</h1>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #anonymousHeader>
    <div class="class-head-row welcome-row">
      <h1 class="heading">
        <span>{{ title }}</span>
      </h1>
    </div>
  </ng-template>

  <div *ngIf="userInfo.role" class="header-menu">
    <div *ngIf="userInfo.role === 'pupil'" class="notice">
      <div class="notice-column" *ngIf="checkNoticeAllow()">
        <a routerLink="/my-favorites" class="notice-dropdown _2 w-inline-block">
          <div [class.active]="userFavorites.length > 0" class="notice-dropdown-toggle bookmark">
            <div *ngIf="userFavorites.length > 0" class="notice-num yellow">
              {{ userFavorites.length }}
            </div>
          </div>
        </a>
      </div>
    </div>
    <a class="user-dropdown w-inline-block" [routerLink]="['/profile']">
      <div class="user-dropdown-toggle">
        <div
          class="user-photo"
          [ngStyle]="{
            'background-image':
              userData && userData.imagePath ? 'url(' + userData.imagePath + ')' : 'url(/profilum-assets/images/icons/no-photo.svg)'
          }"
        ></div>
        <div class="user-name">
          {{ userData && userData.firstName && !isSchoolStaff && !isMunicipalityAdmin ? userData.firstName : 'Мой профиль' }}
        </div>
      </div>
    </a>
  </div>
</div>

<ng-template #generalHeader>
  <div class="h1-div">
    <div class="class-head-row">
      <ng-container *ngIf="dataFetched">
        <ng-container *ngIf="userInfo.role !== 'director'; else directorHeader">
          <div class="class-head-column title-column">
            <button *ngIf="backButton" class="button white-button button-back" (click)="stepBack()">Ко всем классам</button>

            <h1 class="heading" [class.stud-page-heading]="backButtonUrl">
              <span>{{ title }}</span>
            </h1>

            <span class="school-name">
              {{ schoolTitle }}
            </span>

            <a
              *ngIf="isBackButtonWithUrlShow()"
              routerLink="{{ backButtonUrl }}"
              class="button white-button w-button"
              [class.button-back]="backButtonUrl"
            >
              {{ backButtonName }}
            </a>

            <a *ngIf="isEditButtonShow()" (click)="showEditClass()" class="school-edit-link">
              {{ 'SHARED.EDIT_BUTTON' | translate }}
            </a>
          </div>

          <div class="class-head-column button-column" *ngIf="buttonToTest">
            <div>
              <a class="button achievements-button w-button" routerLink="/test-na-professiyu"> Пройти тест еще раз </a>
            </div>
          </div>
        </ng-container>

        <ng-template #directorHeader>
          <div class="class-head-column title-column">
            <a *ngIf="isBackButtonWithUrlShow()" routerLink="{{ backButtonUrl }}" class="button white-button w-button button-back">
              {{ backButtonName }}
            </a>

            <h1 class="heading">
              <span>{{ title }}</span>
            </h1>

            <span class="school-name">
              {{ schoolTitle }}
            </span>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-template>
