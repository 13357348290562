<section class="director-section">
  <prf-director-menu></prf-director-menu>
  <div class="content-section">
    <div class="container">
      <prf-page-header
        [title]="title"
        [schoolTitle]="schoolTitle"
        [editButton]="false"
        [backButtonName]="buttonName"
        [backButtonUrl]="buttonUrl"
        [directorClassMessageButton]="checkClassMessageButton()"
      ></prf-page-header>

      <router-outlet></router-outlet>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>
