<ng-container *ngIf="testResults && testResults.length; else notPassedTest">
  <div class="event-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" *ngIf="isCourses" />
      <img src="./profilum-assets/images/menu/grey/Events.svg" width="80" class="no-profession-icon" *ngIf="!isCourses" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_RECOMMENDED_TITLE_NAME' | translate : { titleName: titleName } }}
    </div>
  </div>
</ng-container>

<ng-template #notPassedTest>
  <!-- заглушка, если не пройден тест -->
  <div class="test-parent-div">
    <div class="welcome-row">
      <div class="test-parent-column">
        <ng-container *ngIf="userRole === 'pupil'">
          <h3 class="test-parent-h3">{{ 'PROFESSIONS.LAUNCH_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.SYSTEM_DIAGNOSTIC_NARRATIVE' | translate }}
          </p>
        </ng-container>
        <ng-container *ngIf="userRole === 'parent'">
          <h3 class="test-parent-h3">{{ 'SHARED.TEST_360.COMPLETE_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.TEST_360.NARRATIVE' | translate }}
          </p>
        </ng-container>
        <div class="main-screen-buttons-row">
          <a routerLink="/test-na-professiyu" class="button test-parent-button w-button">{{ 'SHARED.START_TEST' | translate }}</a>
        </div>
      </div>
      <img *ngIf="!isPupil" src="./profilum-assets/images/illustrations/Parent360.svg" alt="родитель" width="353" class="test-parent-img" />
      <ng-container *ngIf="isPupil">
        <img
          src="{{
            isMale(userGender) ? './profilum-assets/images/illustrations/Pupil-m.svg' : './profilum-assets/images/illustrations/Pupil-w.svg'
          }}"
          alt="gender"
          width="365"
          class="welcome-image-stud"
        />
      </ng-container>
    </div>
  </div>
</ng-template>
