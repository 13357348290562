import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiAuthService } from '@profilum-library';

@Component({
  selector: 'prf-send-link',
  templateUrl: './send-link.component.html',
  styleUrls: ['./send-link.component.scss'],
})
export class SendLinkComponent implements OnInit, OnDestroy {
  @Output() updateValue = new EventEmitter<boolean>();
  email: string;

  constructor(private meta: Meta, private apiAuthService: ApiAuthService, private translateService: TranslateService) {
    this.getTranslation('SHARED.SEND_PASSWORD_CONFIRMATION_LINK')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  ngOnInit() {}

  sendConfirmationLink(email: string) {
    this.apiAuthService
      .sendConfirmationLink(email)
      .pipe(take(1))
      .subscribe(res => this.updateValue.emit(res.status == 'Success'));
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {}
}
