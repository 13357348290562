import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  B2gReportsService,
  Helper,
  IChoosesProfessionsReport,
  IData,
  ISchoolClass,
  UserActionsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  IChoosesProfessions,
  ILessonResultsCount,
  IMappedLessonData,
  IMappedLessonWithCourseData,
  IProfessionsCount,
} from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import {
  TeachersJournalService,
} from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';
import { RootScopeService } from '../../services/root-scope.service';

@Component({
  selector: 'prf-choices-professions',
  templateUrl: './choices-professions.component.html',
  styleUrls: ['./choices-professions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoicesProfessionsComponent extends UnsubscribeComponent {
  public showProfessionsChoices: boolean = false;
  public activeFieldIndex: number = 0;
  public choosesProfessions: IChoosesProfessions[];
  public declOfNum = Helper.declOfNum;
  public seeMore: boolean = false;
  public mappedLessonData: IMappedLessonData[];
  public professionsByFields: Record<
    string,
    {
      pupilIsPassedLessonsExists: boolean;
      lessonNumber: number;
      assignedPupilsCount: number;
    }
  > = {};
  private countsExistByFields: Record<string, boolean> = {};
  public oldPupilCounters = new Map<string, number>() || {};
  public passedLessonsByFieldId = new Map<string, boolean>() || {};
  public lessonResultsCount: ILessonResultsCount;
  public dictionary: DictionaryType;

  @Input() schoolClass: ISchoolClass;

  @Input('lessons') set lessons(lessons: IMappedLessonWithCourseData) {
    if (lessons) {
      const passedLessons = {};
      lessons.course.passedLessons.forEach(el => (passedLessons[el.id] = el.isPassed));
      this.loadData(lessons.mappedLessonData);
      let lessonMaterialId: string = '';
      lessons?.mappedLessonData[0].lessonData.forEach(lessonData => {
        lessonMaterialId = lessonData.lesson?.lessonMaterialId;
        if (lessonData.lesson?.tasks) {
          lessonData.lesson.tasks.forEach(task => {
            if (task?.chooseProfessionsFromFieldTask?.fieldId) {
              this.passedLessonsByFieldId[task.chooseProfessionsFromFieldTask.fieldId] = passedLessons[lessonMaterialId];
            }
          });
        }
      });
    }
  }

  @Input('fieldId') set fieldId(fieldId: string) {
    if (this.choosesProfessions) {
      const choosesProfession = this.choosesProfessions.find(choosesProfession => choosesProfession.fieldId === fieldId);
      if (choosesProfession) {
        this.activeFieldIndex = this.choosesProfessions.indexOf(choosesProfession);
      }
    }
  }

  constructor(
    private teachersJournalService: TeachersJournalService,
    private overlayBusyService: OverlayBusyService,
    private utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private userStorageService: UserStorageService,
    private userActionsService: UserActionsService,
    private rootScopeService: RootScopeService,
    private b2gReportsService: B2gReportsService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.userStorageService
      .get(StorageKeys.OldPassedPupilsForTeacher)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((oldPupilCounters: Map<string, number>) => {
        this.oldPupilCounters = oldPupilCounters ? oldPupilCounters : this.oldPupilCounters;
      });
    this.teachersJournalService.lessonResultsCount$.pipe(takeUntil(this.unsubscribe)).subscribe(lessonResultsCount => {
      this.lessonResultsCount = lessonResultsCount;
    });
    this.userActionsService.setInitializationTime([YmItems.T_Results_Industries, YmItems.T_Results_AllSelections]);
  }

  public setActiveField(index: number): void {
    const uaParams: IData = {
      FieldName: this.choosesProfessions[index].fieldName,
      SchoolClassId: this.schoolClass?.id,
    };

    const ymParams: IData = {
      event_label: { name_of_categorie: this.choosesProfessions[index].fieldName },
    };

    this.userActionsService.log(YmItems.T_Results_Industries, uaParams, ymParams);

    this.activeFieldIndex = index;
    if (this.choosesProfessions[index].newPupilsCount) {
      this.updatePupilsNewCounter(index);
    }
    this.seeLessProfessions();
  }

  public updatePupilsNewCounter(index: number): void {
    const choosesProfession = this.choosesProfessions[index];
    this.choosesProfessions[index].newPupilsCount = 0;
    this.oldPupilCounters[choosesProfession.fieldId] = choosesProfession.assignedPupilsCount;
    this.userStorageService.set(StorageKeys.OldPassedPupilsForTeacher, this.oldPupilCounters);
  }

  public seeMoreProfessions(): void {
    const uaParams: IData = {
      SchoolClassId: this.schoolClass?.id,
    };

    const ymParams: IData = {
      event_label: 'null',
    };

    this.userActionsService.log(YmItems.T_Results_AllSelections, uaParams, ymParams);

    this.seeMore = true;
  }

  public seeLessProfessions(): void {
    this.seeMore = false;
  }

  public isCurrentFieldHasCounts(fieldId: string): boolean {
    return this.countsExistByFields[fieldId];
  }

  private loadData(lessons: IMappedLessonData[]): void {
    this.overlayBusyService.show();
    this.mappedLessonData = lessons;
    this.calculateProfessionsByFields();

    this.b2gReportsService
      .getReportProfessionsFromField(this.schoolClass?.id)
      .pipe(
        switchMap(response => {
          return response?.usersChoosesProfessionsCount
            ? of(response.usersChoosesProfessionsCount)
            : this.b2gReportsService
                .generateReportProfessionsFromField(this.schoolClass?.id)
                .pipe(map((response: IChoosesProfessionsReport) => response?.usersChoosesProfessionsCount ?? null));
        }),
        filter((professionsFromField: IChoosesProfessionsReport) => {
          if (professionsFromField === null) {
            this.overlayBusyService.hide();
            return false;
          }
          return true;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: (professionsFromField: IChoosesProfessionsReport) => {
          this.parseProfessionsFromFieldReport(professionsFromField);
          this.overlayBusyService.hide();
          this.changeDetectorRef.detectChanges();
        },
        error: () => {
          this.overlayBusyService.hide();
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        },
      });
  }

  private parseProfessionsFromFieldReport(professionsFromField: IChoosesProfessionsReport): void {
    try {
      const parsedData: IChoosesProfessions[] = JSON.parse(professionsFromField.data) ?? [];

      parsedData.forEach((data: IChoosesProfessions) => {
        this.checkProfessionsDuplicates(data);
        data.professionsCounts?.sort((prof1: IProfessionsCount, prof2: IProfessionsCount) =>
          Helper.sorting(prof1.count, prof2.count, 'desc'),
        );
        this.countsExistByFields[data.fieldId] = data.professionsCounts?.some(count => count.count > 0);

        data.pupilsAreAssigned = false;

        if (this.professionsByFields[data.fieldId]) {
          const { lessonNumber, pupilIsPassedLessonsExists, assignedPupilsCount } = this.professionsByFields[data.fieldId];
          data.lessonNumber = lessonNumber;
          data.pupilsAreAssigned = pupilIsPassedLessonsExists;
          data.assignedPupilsCount = assignedPupilsCount;
          if (Object.keys(this.oldPupilCounters).length && this.oldPupilCounters[data.fieldId]) {
            data.newPupilsCount = data.assignedPupilsCount ? data.assignedPupilsCount - this.oldPupilCounters[data.fieldId] : 0;
          } else {
            data.newPupilsCount = data.assignedPupilsCount || 0;
          }
        }

        data.allPupils = this.mappedLessonData.length;
      });

      this.choosesProfessions = parsedData.sort((val1, val2) => {
        const val1LessonNumber = this.professionsByFields[val1.fieldId]?.lessonNumber ?? 10000;
        const val2LessonNumber = this.professionsByFields[val2.fieldId]?.lessonNumber ?? 10000;

        return Helper.sorting(val1LessonNumber, val2LessonNumber, 'asc');
      });
      this.showProfessionsChoices = true;
    } catch (e) {
      console.error(e);
      this.showProfessionsChoices = false;
    }
    if (this.choosesProfessions[0].newPupilsCount) {
      this.updatePupilsNewCounter(0);
    }
  }

  private checkProfessionsDuplicates(data: IChoosesProfessions): void {
    if (data.professionsCounts) {
      const professionsCounts: IProfessionsCount[] = data.professionsCounts;
      let professionsCountsWithoutDuplicates: IProfessionsCount[];
      const professionsWithoutDuplicates = new Map<string, number>();
      professionsWithoutDuplicates.clear();
      professionsCounts.forEach((prof: IProfessionsCount) => {
        professionsWithoutDuplicates[prof.name] = professionsWithoutDuplicates[prof.name]
          ? professionsWithoutDuplicates[prof.name] + prof.count
          : prof.count;
      });
      professionsCountsWithoutDuplicates = Object.entries(professionsWithoutDuplicates).map(([key, count]: [string, number]) => {
        return { name: key, count: count };
      });
      if (professionsCounts.length !== professionsCountsWithoutDuplicates.length) {
        data.professionsCounts = professionsCountsWithoutDuplicates;
      }
    }
  }

  private calculateProfessionsByFields(): void {
    this.mappedLessonData?.forEach(lesson => {
      lesson.lessonData.forEach(lessonInfo => {
        const tasks = lessonInfo.lesson?.tasks;
        const lessonMaterialId = lessonInfo.lesson?.lessonMaterialId;
        let passedPupilsCounter: number[] = [];
        if (lessonMaterialId) {
          passedPupilsCounter = this.lessonResultsCount[lessonMaterialId];
        }
        if (tasks) {
          let index = 0;
          const countPassedOnTask = [0, 0];
          tasks.forEach(task => {
            const fieldId = task.chooseProfessionsFromFieldTask?.fieldId;
            if (fieldId) {
              if (!this.professionsByFields[fieldId]) {
                this.professionsByFields[fieldId] = {
                  pupilIsPassedLessonsExists: false,
                  lessonNumber: lessonInfo.lesson.orderNumber,
                  assignedPupilsCount: 0,
                };
              }
              countPassedOnTask[index] = passedPupilsCounter ? passedPupilsCounter[index] : 0;
              lessonInfo.passedTasks.forEach(isPassed => {
                if (isPassed) {
                  this.professionsByFields[fieldId].pupilIsPassedLessonsExists = true;
                }
              });
              // todo нужно ли суммировать здесь? нужно понимать какой каунтер брать если два задания было
              this.professionsByFields[fieldId].assignedPupilsCount = passedPupilsCounter[task.id];
              // this.professionsByFields[fieldId].assignedPupilsCount = countPassedOnTask[0] + countPassedOnTask[1];
            }
            index++;
          });
        }
      });
    });
  }

  private sendMetrics(): void {}
}
