<header prfStickyHeader class="prf-header prf-header-opened block-paddings">
  <div class="prf-header-content prf-header-content-opened">
    <div class="prf-header-content-inner prf-header-content-inner-opened">
      <div class="prf-header-logo-wrapper">
        <prf-header-logo-link [logo]="headerData.logo" />
      </div>
      <div class="prf-header-navbar-wrapper">
        <prf-header-navbar [navItems]="headerData.nav" />
        <prf-header-login-link [specialNavItem]="headerData.navSpecial" />
      </div>
      <prf-header-burger-button />
    </div>
    <prf-header-dropdown-menu [navItems]="headerData.nav" [specialNavItem]="headerData.navSpecial" />
  </div>
</header>
