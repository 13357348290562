import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { take } from 'rxjs/operators';
import {
  ApiProfilesService,
  ApiUsersService,
  AppSettingsService,
  LocationEnum,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';

@Component({
  selector: 'prf-adding-children',
  templateUrl: './adding-children.component.html',
  styleUrls: ['./adding-children.component.scss'],
})
export class AddingChildrenComponent implements OnInit {
  @Input() userProfile: any = {};

  @Input()
  set open(val: boolean) {
    this.isShow = val;
  }

  //@Output() addedChild = new EventEmitter<boolean>();
  @Output() showReg = new EventEmitter<boolean>();

  @Output() updateParent = new EventEmitter<boolean>();
  @Output() closePage = new EventEmitter<boolean>();

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  public isShow: boolean = true;
  public code: string = '';
  public child: any = null;
  public codeLength: number = 6;
  public loading: boolean = false;
  public userId: string = '';
  inputsArray: any;
  isRemove: boolean = false;
  public activeLocation: LocationEnum = LocationEnum.BASE;
  public registrationByCode: boolean = false;

  constructor(
    private renderer: Renderer2,
    private apiUsersService: ApiUsersService,
    private apiProfilesService: ApiProfilesService,
    private appSettings: AppSettingsService,
    private utilsService: UtilsService,
    private webStorageService: WebStorageService,
  ) {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.activeLocation = this.appSettings.currentLocation;
  }

  ngOnInit() {
    const array = [];
    Array.from(Array(this.codeLength)).forEach(_ => {
      array.push(new UntypedFormControl(this.code[_], [Validators.maxLength(1), Validators.required]));
    });
    this.inputsArray = new UntypedFormArray(array);
  }

  public addChild() {
    if (this.child && this.child.userId) {
      this.apiProfilesService
        .bindChild(this.userId, this.child.userId)
        .pipe(take(1))
        .subscribe(_ => {
          this.webStorageService.set(StorageKeys.SkipCaching, true);
          this.updateParent.emit(true);
        });
    }
  }

  public updatedParent(event) {
    if (event) {
      this.updateParent.emit(true);
    }
  }

  public onKeyDown(e: any, inpCurrent: any) {
    if (e.code === 'Backspace' || (e.code === 'Delete' && this.code.length > 0)) {
      const index = this.inputsArray.controls.indexOf(inpCurrent);
      let focus = 0;
      if (index) {
        focus = index - 1;
      }
      this.renderer.selectRootElement(this.inputs.toArray()[focus].nativeElement).focus();
      this.inputsArray.controls.forEach(el => el.setValue(''));
      this.code = this.code.slice(0, index) + this.code.slice(index + 1);
      for (let i = 0; i < this.code.length; i++) {
        this.inputsArray.controls[i].setValue(this.code[i]);
      }
      this.isRemove = true;
    }
  }

  public onInsertData(e: any, inpCurrent: any) {
    if (!this.isRemove) {
      const str = inpCurrent.value.replace(/[^a-z0-9]/gi, '');
      const index = this.inputsArray.controls.indexOf(inpCurrent);
      if (str.length >= this.codeLength) {
        this.code = str;
        this.inputsArray.controls.forEach(el => el.setValue(''));
      } else {
        if (this.code.length > 0) {
          this.code = this.code.slice(0, index) + str.slice(-1) + this.code.slice(index + 1);
        } else {
          this.code = str;
        }
        this.inputsArray.controls[index].setValue('');
      }

      this.code = this.code.length > this.codeLength ? this.code.slice(0, this.codeLength) : this.code;
      for (let i = 0; i < this.code.length; i++) {
        this.inputsArray.controls[i].setValue(this.code[i]);
      }
      if (this.code.length == this.codeLength) {
        this.inputs.last.nativeElement.focus();
        this.findChildren();
      } else {
        this.code.length ? this.inputs.toArray()[this.code.length].nativeElement.focus() : this.inputs.first.nativeElement.focus();
      }
    } else {
      this.isRemove = false;
    }
  }

  private findChildren() {
    if (this.code.length === this.codeLength) {
      this.loading = true;

      this.apiUsersService
        .getUserInfoByCodeParents(this.code)
        .pipe(take(1))
        .subscribe(response => {
          if (response.status === 'Success') {
            this.child = response;
          } else {
            if (response.comment.includes('not found')) {
              this.utilsService.openSnackBar('Код не найден', 'error');
            }

            this.child = null;
          }

          this.loading = false;
        });
    } else {
      this.child = null;
    }
  }

  public showRegPage() {
    this.showReg.emit(true);
  }
}
