import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { TranslateService } from '@ngx-translate/core';

import {
  ApiPlayerService,
  ApiResultsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  IFilterClasses,
  ITestInfo,
  Stands,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';

import { IResult } from 'app/pages/results/results.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { RootScopeService } from '../../../services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { ClassesFormatTypes } from '../../../enums/courses-types.enum';

@Component({
  selector: 'prf-recommended-courses',
  templateUrl: './recommended-courses.component.html',
  styleUrls: ['./recommended-courses.component.scss'],
})
export class RecommendedCoursesComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() public isCourses: boolean;
  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;
  public menuList: any;
  public currentRecommend: any;
  public dataFetched: boolean = false;
  public recommendedCourses: any = [];
  public titleName: string;
  public userRole: string = '';
  public dictionary: DictionaryType;

  public swiperConfig: SwiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    speed: 500,
  };
  public testSessions: any[] = [];

  private testRecommends: any = [];
  private parentsRecomends: any = [];
  private teacherRecomends: any = [];
  private userId: string;
  private lastTestResults: any;
  private courseCategories: IResult[] = [];
  private programsAmount: number = 3;
  private currentIndexCarousel: number = 0;
  private recommendedCoursesTest: Array<any> = [];
  private recommendedCoursesParents: Array<any> = [];
  private recommendedCoursesTeacher: Array<any> = [];
  private regionId: string = null;

  constructor(
    private meta: Meta,
    private apiResultsService: ApiResultsService,
    private b2gSaasService: B2gSaasService,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private apiPlayerService: ApiPlayerService,
    private apiSchoolsService: ApiSchoolsService,
    private rootScopeService: RootScopeService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    this.route.data.pipe(takeUntil(this.unsubscribe)).subscribe(v => {
      this.isCourses = v.isCourses;
    });
  }

  public ngOnInit(): void {
    this.titleName = this.isCourses ? 'курсов' : 'мероприятий';
    this.userId = this.userDataHandlerService.getUserInfo().userId;

    forkJoin([this.getTestResults(), this.addRecommendedProgramsByParents(), this.addRecommendedProgramsByTeacher()])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.dataFetched = true;
        this.createMenu();
      });
  }

  public clickNext() {
    this.swiper.slideNext();
    this.currentIndexCarousel++;
  }

  public clickPrev() {
    this.swiper.slidePrev();
    this.currentIndexCarousel--;
  }

  public selectMenu(recommend: any) {
    if (recommend.isContent) {
      this.currentRecommend = recommend;

      this.getTranslation('SHARED.RESULT.BY_TEST')
        .pipe(take(1))
        .subscribe(translation => {
          if (recommend.name === translation) {
            this.recommendedCourses = this.recommendedCoursesTest;
            this.currentIndexCarousel = 0;
          }

          if (recommend.name === 'От родителей') {
            this.recommendedCourses = this.recommendedCoursesParents;
            this.currentIndexCarousel = 0;
          }

          if (recommend.name === `От ${this.dictionary.TeacherGenitive}`) {
            this.recommendedCourses = this.recommendedCoursesTeacher;
            this.currentIndexCarousel = 0;
          }
        });
    }
  }

  private getTestResults(): Observable<any> {
    return this.apiPlayerService.getSessionsList(this.userId).pipe(
      switchMap(sessions => {
        if (sessions && sessions.length > 0) {
          return this.apiPlayerService.regionTestInfo(this.regionId).pipe(
            switchMap((testInfo: ITestInfo) => {
              this.testSessions = sessions.filter(el => el.completed === true && el.screeningTestId === testInfo.screeningTestId);
              if (this.testSessions?.length) {
                // Ищем сессию с результатами теста
                const lastSessionCompleted = this.testSessions[this.testSessions.length - 1];
                if (lastSessionCompleted) {
                  return this.apiResultsService.getResultsPage(lastSessionCompleted.sessionId).pipe(
                    tap(results => {
                      if (results.results.length > 0) {
                        this.lastTestResults = results.results;
                        this.courseCategories = this.lastTestResults.filter(d => d.objectType === 'Course' && d.results.length);
                        // запрашиваются результаты по sessionId, не совместные, поэтому берутся results[0]
                        this.testRecommends = this.courseCategories.sort((a, b) =>
                          a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1,
                        );
                        return this.getCourses();
                      }
                    }),
                  );
                } else {
                  return of(null);
                }
              } else {
                return of(null);
              }
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  private async getCourses() {
    const maxCountCourses = 9;
    let coursesArrayCount = 0;
    let inc = 0;
    const uniqIdArray = [];

    if (this.isCourses) {
      do {
        const filters: IFilterClasses = {
          courses: [this.testRecommends[inc].name],
          from: 0,
          size: this.programsAmount,
          classesFormat: [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse],
        };
        await this.apiSchoolsService
          .getElasticFilteredClasses(filters)
          .pipe(
            take(1),
            tap(courses => {
              if (courses.length) {
                coursesArrayCount += courses.length;
                this.recommendedCoursesTest.push({
                  name: this.testRecommends[inc].name,
                  image: this.testRecommends[inc].imageUrl,
                  programms: courses,
                });
              }
            }),
          )
          .toPromise();
        inc++;
      } while (coursesArrayCount < maxCountCourses && inc < this.testRecommends.length);
    } else {
      do {
        const filters: IFilterClasses = {
          courses: [this.testRecommends[inc].name],
          from: 0,
          size: this.programsAmount,
          classesFormat: [
            ClassesFormatTypes.MasterClass,
            ClassesFormatTypes.Excursion,
            ClassesFormatTypes.Festival,
            ClassesFormatTypes.Action,
            ClassesFormatTypes.Meeting,
            ClassesFormatTypes.Competition,
            ClassesFormatTypes.Profproba,
            ClassesFormatTypes.OpenDay,
          ],
          municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
          stand: Stands.Talent,
        };
        await this.apiSchoolsService
          .getElasticFilteredClasses(filters)
          .pipe(
            take(1),
            tap(courses => {
              if (courses.length) {
                const uniqEventArray = [];

                courses.forEach(element => {
                  // проверяем не добавлено ли уже нужное нам событие
                  if (!uniqIdArray.includes(element.id)) {
                    coursesArrayCount++;
                    uniqIdArray.push(element.id);
                    uniqEventArray.push(element);
                  }
                });

                // Добавляем только если есть уникальные мероприятия
                if (uniqEventArray.length) {
                  this.recommendedCoursesTest.push({
                    name: this.testRecommends[inc].name,
                    image: this.testRecommends[inc].imageUrl,
                    programms: uniqEventArray,
                  });
                }
              }
            }),
          )
          .toPromise();
        inc++;
      } while (coursesArrayCount < maxCountCourses && inc < this.testRecommends.length);
    }
  }

  private addRecommendedProgramsByParents(): Observable<any> {
    const courseFormats: Array<string> = ['ShortCourse', 'LongCourse'];
    //
    return this.b2gSaasService.getRecommendations('Class', 'Parent').pipe(
      take(1),
      switchMap(r => {
        this.parentsRecomends = r;

        if (this.parentsRecomends && this.parentsRecomends.length) {
          return this.apiSchoolsService.getFilteredClasses({ ids: this.parentsRecomends }).pipe(
            take(1),
            tap(recommendedCoursesParents => {
              let courses = [];

              if (this.isCourses) {
                courses = recommendedCoursesParents.classes.filter(rcp => courseFormats.some(cf => cf === rcp.classesFormat));
              } else {
                courses = recommendedCoursesParents.classes.filter(rcp => !courseFormats.some(cf => cf === rcp.classesFormat));
              }

              if (courses.length === 0) {
                this.parentsRecomends = [];
              }

              this.recommendedCoursesParents = this.addCourses(courses);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  private addRecommendedProgramsByTeacher(): Observable<any> {
    const courseFormats: Array<string> = ['ShortCourse', 'LongCourse'];
    return this.b2gSaasService.getRecommendations('Class', 'Teacher').pipe(
      take(1),
      switchMap(r => {
        this.teacherRecomends = r;

        if (this.teacherRecomends && this.teacherRecomends.length) {
          return this.apiSchoolsService.getFilteredClasses({ ids: this.teacherRecomends }).pipe(
            take(1),
            tap(recommendedCoursesParents => {
              let courses = [];

              if (this.isCourses) {
                courses = recommendedCoursesParents.classes.filter(rcp => courseFormats.some(cf => cf === rcp.classesFormat));
              } else {
                courses = recommendedCoursesParents.classes.filter(rcp => !courseFormats.some(cf => cf === rcp.classesFormat));
              }

              if (courses.length === 0) {
                this.teacherRecomends = [];
              }
              this.recommendedCoursesTeacher = this.addCourses(courses);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  private createMenu(): void {
    this.getTranslation('SHARED.RESULT.BY_TEST')
      .pipe(take(1))
      .subscribe(translation => {
        this.menuList = [
          {
            name: translation,
            isContent: this.testRecommends.length > 0,
          },
          { name: 'От родителей', isContent: this.parentsRecomends.length > 0 },
          { name: `От ${this.dictionary.TeacherGenitive}`, isContent: this.teacherRecomends.length > 0 },
        ];

        const currentMenuTab = this.menuList.filter(item => item.isContent)[0] || this.menuList[0];
        this.selectMenu(currentMenuTab);
      });
  }

  private addCourses(courses): Array<any> {
    const recommendedCourses = [{ name: '', image: '', programms: [] }];

    courses.forEach(c => {
      const index = recommendedCourses.length - 1;

      if (recommendedCourses[index].programms.length < this.programsAmount) {
        recommendedCourses[index].programms.push(c);
      } else {
        recommendedCourses.push({ name: '', image: '', programms: [] });
        recommendedCourses[index + 1].programms.push(c);
      }
    });

    return recommendedCourses;
  }

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
