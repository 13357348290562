import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBlockItemComponent } from '../../components/info-block/info-block-item/info-block-item.component';
import { PrfInfoBlock } from '../../components/info-block/info-block.types';

@Component({
  selector: 'prf-info-block',
  standalone: true,
  imports: [CommonModule, InfoBlockItemComponent],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockComponent {
  @Input() infoBlockData: PrfInfoBlock[];
}
