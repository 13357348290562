import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@profilum-logic-services/header/header.service';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-header-dropdown-menu-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-dropdown-menu-item.component.html',
  styleUrl: './header-dropdown-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDropdownMenuItemComponent {
  @Input() linkHref: string;
  @Input() linkText: string;
  @Input() isSpecial = false;
  @Input() ymGoal?: string;

  private headerService = inject(HeaderService);
  private yandexMetricsService = inject(YandexMetricsService);

  public toggleMenu(ymGoal: string) {
    !this.isSpecial && this.headerService.closeMenu();
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
