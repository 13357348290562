import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ChildRegistrationComponent } from './children-add/child-registration/child-registration.component';
import { ChildrenAddComponent } from './children-add/children-add.component';
import { AddingChildrenComponent } from './children-tabs-container/adding-children/adding-children.component';
import { ChildrenTabsContainerComponent } from './children-tabs-container/children-tabs-container.component';
import { CodesChildrenComponent } from './children-tabs-container/codes-children/codes-children.component';
import {
  ParentPupilProfileComponent,
} from './children-tabs-container/parent-pupil-profile/parent-pupil-profile.component';
import { ProfessionMenuComponent } from './children-tabs-container/profession-menu/profession-menu.component';
import { ChildrenTabsComponent } from './children-tabs/children-tabs.component';
import { ParentPanelComponent } from './parent-panel.component';
import { ParentRoutingModule } from './parent-routing.module';
import { PromoTestComponent } from './promo-test/promo-test.component';
import {
  ChildCodeRegistrationComponent,
} from './children-tabs-container/child-code-registration/child-code-registration.component';
import {
  ChildAddFormComponent,
} from './children-tabs-container/adding-children/child-add-form/child-add-form.component';
import {
  ParentWelcomePageComponent,
} from './children-tabs-container/parent-welcome-page/parent-welcome-page.component';
import { AddLinkPopupComponent } from './parent-modals/add-link-popup/add-link-popup.component';
import { SuccessLinkPopupComponent } from './parent-modals/success-link-popup/success-link-popup.component';
import { SuccessAddPopupComponent } from './parent-modals/success-add-popup/success-add-popup.component';
import { AddChildPopupComponent } from './parent-modals/add-child-popup/add-child-popup.component';
import { ChildEditComponent } from './child-edit/child-edit.component';
import { PupilGenderToggleModule } from 'app/shared/common-components/gender-toggle/pupil-gender-toggle.module';
import { DynamicLabelModule } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.module';
import { ResetButtonModule } from 'app/shared/common-components/prf-form/reset-button/reset-button.module';
import { StaticNotificationModule } from '../../../ui-kit/components/static-notification/static-notification.module';
import { PhoneMaskModule } from '../../../shared/directives/phone-mask/phone-mask.module';
import { TippyDirective } from '@ngneat/helipopper';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import {
  RestrictionBannerComponent,
} from '../../../shared/common-components/restriction-banner/restriction-banner.component';
import { SuspensionMessageDirective } from '../../../shared/directives/suspension-message/suspension-message.directive';

@NgModule({
  declarations: [
    ParentPanelComponent,
    ChildrenTabsContainerComponent,
    ProfessionMenuComponent,
    ParentPupilProfileComponent,
    ChildrenAddComponent,
    ChildrenAddComponent,
    ChildRegistrationComponent,
    AddingChildrenComponent,
    CodesChildrenComponent,
    PromoTestComponent,
    ChildCodeRegistrationComponent,
    ChildAddFormComponent,
    ParentWelcomePageComponent,
    AddLinkPopupComponent,
    SuccessLinkPopupComponent,
    SuccessAddPopupComponent,
    AddChildPopupComponent,
    ChildEditComponent,
  ],
  imports: [
    CommonModule,
    ParentRoutingModule,
    SharedModule,
    PupilGenderToggleModule,
    DynamicLabelModule,
    ResetButtonModule,
    StaticNotificationModule,
    PhoneMaskModule,
    TippyDirective,
    TooltipComponent,
    ChildrenTabsComponent,
    RestrictionBannerComponent,
    SuspensionMessageDirective,
  ],
})
export class ParentModule {}
