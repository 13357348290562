import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PupilMenuBottomComponent } from './pupil-menu-botton.component';

@NgModule({
  declarations: [PupilMenuBottomComponent],
  imports: [CommonModule],
  exports: [PupilMenuBottomComponent],
})
export class PupilMenuBottomModule {}
