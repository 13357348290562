import { Component, Input } from '@angular/core';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';

@Component({
  selector: 'prf-education-circle-bar',
  templateUrl: './education-circle-bar.component.html',
  styleUrls: ['./education-circle-bar.component.scss'],
})
export class EducationCircleBarComponent {
  @Input() name: string = '';
  public _colorSet: any[] = [];
  public institutionData: IAnswerPercents[] = [];
  // Doughnut
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [];
  public doughnutChartType: string = 'doughnut';
  public pieChartLegend: boolean = false;
  public barChartData = {
    labels: [],
    datasets: [
      {
        label: [],
        data: [],
        backgroundColor: ['#2be8df', '#9e83fc', '#4ab5ff', '#fe7531', '#f856e2', '#ffd930', '#36ca75', '#3e60fd'],
      },
    ],
  };
  public chartColors: any[] = [
    {
      backgroundColor: ['#2be8df', '#9e83fc', '#4ab5ff', '#fe7531', '#f856e2', '#ffd930', '#36ca75', '#3e60fd'],
    },
  ];
  public pieChartOptions: any = {
    legend: {
      labels: {
        fontSize: 12,
      },
    },
    pointLabels: {
      fontSize: 12,
      fontColor: '#333',
      fontFamily: 'ALSSchlangesans, Arial',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data['labels'][tooltipItem[0]['index']];
        },
        label: function (tooltipItem, data) {
          return data['datasets'][0]['data'][tooltipItem['index']] + '%';
        },
      },
      backgroundColor: '#FFF',
      titleFontSize: 15,
      titleFontColor: '#353535',
      bodyFontColor: '#353535',
      bodyFontSize: 13,
      displayColors: false,
    },
  };

  @Input() set data(value: IAnswerPercents[]) {
    if (value && value.length) {
      this.doughnutChartLabels = [];
      this.doughnutChartData = [];
      this.barChartData.datasets[0].label = [];
      this.barChartData.datasets[0].data = [];
      value.forEach(item => {
        this.doughnutChartLabels.push(item.name);
        this.barChartData.labels.push(item.name);
        this.barChartData.datasets[0].label.push(item.name);
        this.doughnutChartData.push(item.percents);
        this.barChartData.datasets[0].data.push(item.percents);
      });
    }
    this.institutionData = value;
    return;
  }

  @Input() set colorSet(value: string[]) {
    if (value) {
      this.barChartData.datasets[0].backgroundColor = value;
      this._colorSet = value;
      this.chartColors = [
        {
          backgroundColor: value,
        },
      ];
    }
    return;
  }

  get colorSet() {
    return this._colorSet;
  }

  getRound(number) {
    if (number) {
      return Math.round(number);
    }
  }
}
