import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { combineLatest, iif, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAdminsService,
  ApiCoursesMaterialsService,
  AppSettingsService,
  B2gSaasService,
  EmptyGuid,
  IAddUserRequest,
  IAddUserResponse,
  IRegionCourses,
  ISchool,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';
import { ICourseMaterialExt } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { IMappedCourse } from '../schooladmin-courses.interface';

@Component({
  selector: 'prf-schooladmin-add-class',
  templateUrl: './schooladmin-add-class.component.html',
  styleUrls: ['./schooladmin-add-class.component.scss'],
})
export class SchooladminAddClassComponent extends UnsubscribeComponent implements OnInit {
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public numbersClass: {
    value: number;
  }[] = [];
  public form: UntypedFormGroup;
  public school: ISchool;
  public saveInProcess: boolean = false;
  public teacherLoaded: boolean = false;
  public teachers: ITeacher[] = [];
  public searchTeachers: ITeacher[] = [];
  public showTeachersList: boolean = false;
  public addNewTeacher: boolean = false;
  public numberLabel: boolean = false;
  public letterLabel: boolean = false;
  public firstNameLabel: boolean = false;
  public lastNameLabel: boolean = false;
  public middleNameLabel: boolean = false;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public dropdownNumberOpen: boolean = false;
  public dropdownCourseOpen: boolean = false;
  public courses: ICourseMaterialExt[];
  public mappedCourses: IMappedCourse[] = [];
  public allowedCoursesToClass: IMappedCourse[] = [];
  private submitted: boolean;
  private selectedTeacherId: string = '';
  private selectedCourseId: string = '';
  private regionId: string;
  private stringClassNumber: Record<number, string> = {
    5: 'fifthClass',
    6: 'sixthClass',
    7: 'seventhClass',
    8: 'eighthClass',
    9: 'ninthClass',
    10: 'tenthClass',
    11: 'eleventhClass',
  };

  @ViewChild('numberFilter') public readonly numberFilter: ElementRef;
  @ViewChild('numberDrop') public readonly numberDrop: ElementRef;
  @ViewChild('teacherDrop') public teacherDrop: ElementRef;
  @ViewChild('courseDrop') public courseDrop: ElementRef;

  constructor(
    private meta: Meta,
    private apiAdminsService: ApiAdminsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private appSettingsService: AppSettingsService,
    private overlayService: OverlayBusyService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private webStorageService: WebStorageService,
    private b2gSaasService: B2gSaasService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.getTranslation('SHARED.CREATING_NEW_CLASS')
      .pipe(take(1))
      .subscribe((translation: string) =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.setCreateClassControls();

    this.regionId = this.userDataHandlerService.getUserInfo().regionId;

    combineLatest([
      this.getSchoolAndTeachers(),
      this.apiAdminsService.getRegionCoursesByRegionId(this.regionId),
      this.apiCoursesMaterialsService.getAllCourseMaterials(),
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ([, courseRegion, courses]) => {
          courseRegion.map((regionCourse: IRegionCourses) => {
            const assignedCourse: ICourseMaterialExt = courses.courseMaterials.find(
              (course: ICourseMaterialExt) => course.id === regionCourse.courseId,
            );
            if (assignedCourse) {
              this.mappedCourses.push({ name: assignedCourse.name, ...regionCourse });
            }
          });

          for (const schoolNumber of SCHOOL_NUMBERS) {
            const mappedCourseForClass = this.mappedCourses.filter(course => course[this.stringClassNumber[schoolNumber.value]]);
            if (mappedCourseForClass?.length) {
              this.numbersClass.push(schoolNumber);
            }
          }

          this.teacherLoaded = true;
          this.overlayService.hide();
        },
        () => {
          this.overlayService.hide();
        },
      );
  }

  private getSchoolAndTeachers(): Observable<any> {
    return this.b2gSaasService.getSchool().pipe(
      switchMap(school => {
        this.school = school;
        return this.apiAdminsService.getTeachers(school.id);
      }),
      tap(teachers => {
        this.teachers = teachers;
        this.teachers.forEach(teacher => {
          teacher.fullName = teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
        });
        this.searchTeachers = this.teachers;
      }),
    );
  }

  private setCreateClassControls(): void {
    this.form = this.fb.group({
      lastName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[А-Яа-яЁё]+$')]),
      firstName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[А-Яа-яЁё]+$')]),
      middleName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[А-Яа-яЁё]+$')]),
      fullName: new UntypedFormControl('', []),
      email: new UntypedFormControl('', [Validators.pattern(this.emailRegExp)]),
      number: new UntypedFormControl('', [Validators.required]),
      letter: new UntypedFormControl('', [Validators.required, Validators.pattern('^[А-Яа-яЁё0-9]+$')]),
      phoneNumber: new UntypedFormControl('', []),
      course: new UntypedFormControl('', [Validators.required]),
    });
  }

  private removeWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  private failWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public animateSubmit(): void {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  private submit(): void {
    this.submitted = true;
    if (this.form.valid && !this.saveInProcess) {
      this.saveInProcess = true;
      this.b2gSaasService
        .getSchool()
        .pipe(
          switchMap(school => {
            this.school = school;
            return this.createNewSchoolClassInfo();
          }),
          switchMap(schoolData => {
            return combineLatest([
              of(schoolData),
              iif(
                () => !!(this.form.value.course && this.selectedCourseId),
                this.apiCoursesMaterialsService.assignCourseForClass({
                  courseActivity: {
                    schoolClassId: schoolData?.length ? schoolData[0] : null,
                    courseId: this.selectedCourseId,
                  },
                }),
                of(null),
              ),
            ]);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe({
          next: ([[classId]]) => {
            this.saveInProcess = false;
            this.webStorageService.set(StorageKeys.ShowCreatedClassPopupInfo, true);

            if (classId) {
              this.router.navigate(['/schooladmin/class/' + classId]);
            }
          },
          error: () => {
            this.form.reset();
          },
        });
    } else {
      this.failWaiting();
      this.saveInProcess = false;
      return;
    }
  }

  public setNumber(numberClass: number): void {
    this.allowedCoursesToClass = [];
    this.formControls.course.reset();
    this.formControls.number.setValue(numberClass);
    this.allowedCoursesToClass = this.mappedCourses.filter(course => course[this.stringClassNumber[numberClass]]);
  }

  public setCourse(course: IMappedCourse): void {
    this.formControls.course.setValue(course.name);
    this.selectedCourseId = course.courseId ? course.courseId : null;
    this.toggleOpenCourse();
  }

  get formControls() {
    return this.form.controls;
  }

  private createNewSchoolClassInfo(): Observable<any> {
    const createNewClass = {
      letter: this.form.value.letter.toUpperCase(),
      number: this.form.value.number,
      schoolId: this.school.id,
      teacherId: EmptyGuid,
    };
    return this.apiAdminsService.addNewSchoolClass(createNewClass).pipe(
      switchMap((response: string) => {
        if (response === 'ALREADY_EXIST' || response === EmptyGuid) {
          this.utilsService.openSnackBar('👎 Такой класс уже занят', 'error');
          this.formControls.number.setValue('');
          this.formControls.letter.setValue('');
          this.failWaiting();
          this.saveInProcess = false;
          return of(null);
        } else {
          if (this.addNewTeacher) {
            return combineLatest([
              of(response),
              this.createNewTeacher(response).pipe(
                switchMap(() => {
                  this.removeWaiting();
                  return this.apiAdminsService.refreshSchoolData(this.school.id);
                }),
              ),
            ]);
          } else {
            return combineLatest([
              of(response),
              this.apiAdminsService.bindTeacherToClass(this.selectedTeacherId, response).pipe(
                switchMap(() => {
                  this.removeWaiting();
                  return this.apiAdminsService.refreshSchoolData(this.school.id);
                }),
              ),
            ]);
          }
        }
      }),
      catchError(err => {
        this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        return throwError(err);
      }),
    );
  }

  private createNewTeacher(schoolClassId: string): Observable<IAddUserResponse> {
    const createNewTeacher: IAddUserRequest = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      middleName: this.form.value.middleName,
      schoolClassIds: schoolClassId ? [schoolClassId] : [],
      role: 'teacher',
    };
    return this.b2gSaasService.addUser(createNewTeacher).pipe(
      tap((response: IAddUserResponse) => {
        if (!response) {
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        }
      }),
    );
  }

  public isAccessAllowed(): boolean {
    return (
      this.form.value.lastName &&
      this.form.value.firstName &&
      this.form.value.middleName &&
      this.form.value.number &&
      this.form.value.letter &&
      this.form.value.course
    );
  }

  public toggleOpen(target: EventTarget): void {
    if (this.numberFilter?.nativeElement.contains(target)) {
      this.dropdownNumberOpen = !this.dropdownNumberOpen;
    }
  }

  public toggleOpenCourse(): void {
    this.showTeachersList = false;
    this.dropdownCourseOpen = !this.dropdownCourseOpen;
  }

  public toggleOpenTeacher(): void {
    this.dropdownCourseOpen = false;
    this.showTeachersList = !this.showTeachersList;
  }

  public selectTeacher(teacher: ITeacher): void {
    this.formControls.fullName.setValue(`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`);
    this.formControls.lastName.setValue(teacher.lastName);
    this.formControls.firstName.setValue(teacher.firstName);
    this.formControls.middleName.setValue(teacher.middleName);
    this.selectedTeacherId = teacher.teacherId ?? teacher.userId;

    this.showTeachersList = !this.showTeachersList;
  }

  public toggleInputTeacher(): void {
    this.addNewTeacher = !this.addNewTeacher;

    if (this.addNewTeacher) {
      this.formControls.fullName.setValue('');
      this.formControls.lastName.setValue('');
      this.formControls.firstName.setValue('');
      this.formControls.middleName.setValue('');
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target: EventTarget): void {
    if (!this.numberFilter?.nativeElement.contains(target)) {
      this.numberDrop?.nativeElement.classList.remove('w--open');
      this.dropdownNumberOpen = false;
    }
  }

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public disabledTextInput(event: KeyboardEvent): void {
    if (event.code === 'Backspace' || event.code === 'Delete') {
      return;
    }

    event.preventDefault();
  }

  public searchTeachersList(): void {
    const searchName: string = this.form.value.fullName.toLowerCase();

    if (searchName.length > 1) {
      this.searchTeachers = this.teachers.filter(teacher => {
        return teacher.fullName.toLowerCase().includes(searchName);
      });
    } else {
      this.searchTeachers = this.teachers;
    }
  }
}
