<div class="event-page-section create" *ngIf="company">
  <div class="edit-event-row">
    <div class="edit-event-column">
      <h1 class="event-h1 edit">{{ title }}</h1>
      <div class="event-status-block-2">
        <div class="event-status-text-2">
          {{ 'SHARED.CREATED_DUE_DATE' | translate : { createdDate: createdDate } }}
        </div>
        <div class="event-status-text-2">Ред {{ updatedDate }}</div>
      </div>
    </div>
  </div>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <div
        class="event-image uploaded-photo"
        [ngStyle]="{
          'background-image': company.logoUrl ? 'url(' + company.logoUrl + ')' : ''
        }"
      >
        <a class="white-button change-event-photo w-button" (click)="upload.click()">Изменить</a>
        <input type="file" (change)="uploadLogo($event)" #upload hidden />
      </div>
      <div class="event-image-text">Рекомендуем загружать качественные фото. Его увидят тысячи учеников и родителей.</div>

      <h2 class="event-h2">Основная информация</h2>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="hrid">HRID компании*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && f.hrid.errors"
            maxlength="256"
            name="hrid"
            placeholder="HRID компании"
            id="hrid"
            formControlName="hrid"
          />
          <ng-container *ngIf="submitted && f.hrid.errors">
            <mat-error *ngIf="f.hrid.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
          <!-- <ng-container *ngIf="submitted && !checkhrid">
            <mat-error *ngIf="!checkhrid">
              {{ 'SHARED.HRID_IS_BUSY' | translate }}
            </mat-error>
          </ng-container> -->
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label for="region" class="create-form-label">{{ 'SHARED.REGION' | translate }}*</label>
          <div class="div-space"></div>
        </div>
        <mat-form-field class="create-form-column">
          <mat-select
            placeholder="{{ 'SHARED.REGION' | translate }}"
            class="create-event-field w-select"
            [class.error-field]="submitted && f.region.errors"
            formControlName="region"
            name="region"
            id="region"
            [compareWith]="compareItems"
          >
            <mat-option (click)="selectRegion(region)" [value]="region" *ngFor="let region of allRegions"> {{ region.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="create-form-row" *ngIf="form.controls.region.value.id">
        <div class="create-form-column _1">
          <label for="municipality" class="create-form-label">Муниципалитет</label>
          <div class="div-space"></div>
        </div>
        <mat-form-field class="create-form-column">
          <mat-select
            placeholder="Муниципалитет"
            class="create-event-field w-select"
            [class.error-field]="submitted && f.municipality.errors"
            formControlName="municipality"
            name="municipality"
            id="municipality"
            [compareWith]="compareItems"
          >
            <mat-option [value]="municipality" (click)="clearMunicipality()">Не выбран</mat-option>
            <mat-option [value]="municipality" *ngFor="let municipality of municipalitiesByRegion"> {{ municipality.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="address">Адрес</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="address"
            [class.error-field]="submitted && f.address.errors"
            data-name="address"
            placeholder="Адрес"
            id="address"
            formControlName="address"
          />
          <ng-container *ngIf="submitted && f.address.errors">
            <mat-error *ngIf="f.address.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="companyDescription">Ценности и миссия компании, описание деятельности компании*</label>
        </div>
        <div class="create-form-column">
          <div>
            <quill-editor
              id="companyDescription"
              name="companyDescription"
              placeholder="Описание"
              formControlName="companyDescription"
              height="250px"
            >
            </quill-editor>
          </div>

          <ng-container *ngIf="submitted && f.companyDescription.errors">
            <mat-error *ngIf="f.companyDescription.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="responsiblePerson">Ответственное лицо</label>
        </div>
        <div class="create-form-column">
          <textarea
            id="responsiblePerson"
            name="responsiblePerson"
            placeholder="Ответственное лицо"
            [class.error-field]="submitted && f.responsiblePerson.errors"
            formControlName="responsiblePerson"
            class="create-event-field w-input"
          >
          </textarea>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="email">Контактный Email</label>
        </div>
        <div class="create-form-column">
          <textarea
            id="email"
            name="email"
            placeholder="Email"
            [class.error-field]="submitted && f.email.errors"
            formControlName="email"
            class="create-event-field w-input"
          >
          </textarea>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="phone">Контактный телефон</label>
        </div>
        <div class="create-form-column">
          <textarea
            id="phone"
            name="phone"
            placeholder="Номер телефона"
            [class.error-field]="submitted && f.phone.errors"
            formControlName="phone"
            class="create-event-field w-input"
          >
          </textarea>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="employeesCount">Количество сотрудников</label>
        </div>
        <div class="create-form-column">
          <input
            type="number"
            [class.error-field]="submitted && f.employeesCount.errors"
            class="create-event-field cost w-input"
            maxlength="256"
            name="employeesCount"
            data-name="employeesCount"
            id="employeesCount"
            formControlName="employeesCount"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="fieldNames">Привязка к сфере</label>
        </div>
        <div class="create-form-column">
          <mat-form-field>
            <mat-select placeholder="Сферы" formControlName="fieldNames" name="fieldNames" id="fieldNames" multiple>
              <mat-option *ngFor="let field of fields" [value]="field.name">
                {{ field.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <div class="fields-title">Текущие сферы</div>
            <div *ngFor="let fieldName of company.fieldNames" class="current-fieldName">
              <p>{{ fieldName }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="professions">Привязка к профессиям</label>
        </div>
        <div class="create-form-column">
          <mat-form-field>
            <mat-select name="professions" formControlName="professions" placeholder="Профессии" multiple>
              <mat-option *ngFor="let profession of professions" [value]="profession.id">
                {{ profession.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <h2 class="event-h2">Ссылки</h2>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="websiteLink">Сайт компании</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="websiteLink"
            data-name="websiteLink"
            id="websiteLink"
            formControlName="websiteLink"
            placeholder="Ссылка на сайт"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="yandexZenLink">Яндекс.Дзен</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="yandexZenLink"
            data-name="yandexZenLink"
            id="yandexZenLink"
            formControlName="yandexZenLink"
            placeholder="Ссылка на блог Яндекс.Дзен"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="vkontakteLink">ВКонтакте</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="vkontakteLink"
            data-name="vkontakteLink"
            id="vkontakteLink"
            formControlName="vkontakteLink"
            placeholder="Ссылка на страницу ВКонтакте"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="okLink">Одноклассники</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="okLink"
            data-name="okLink"
            id="okLink"
            formControlName="okLink"
            placeholder="Ссылка на страницу Одноклассники"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="instagramLink">Instagram</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="instagramLink"
            data-name="instagramLink"
            id="instagramLink"
            formControlName="instagramLink"
            placeholder="Ссылка на профиль Instagram"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="facebookLink">Facebook</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="facebookLink"
            data-name="facebookLink"
            id="facebookLink"
            formControlName="facebookLink"
            placeholder="Ссылка на страницу Facebook"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="youtubeLink">YouTube</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="youtubeLink"
            data-name="youtubeLink"
            id="youtubeLink"
            formControlName="youtubeLink"
            placeholder="Ссылка на канал YouTube"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="news">Новости</label>
        </div>
        <div class="create-form-column">
          <textarea
            id="news"
            name="news"
            placeholder="Ссылки на новости"
            [class.error-field]="submitted && f.news.errors"
            formControlName="news"
            class="create-event-field w-input"
          >
          </textarea>
        </div>
      </div>

      <div class="edit-event-buttons-row">
        <input type="submit" (click)="submit()" value="{{ 'SHARED.SAVE_CHANGES' | translate }}" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" routerLink="/employer">
          {{ 'SHARED.EXIT_WITHOUT_SAVE' | translate }}
        </a>
      </div>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
