<div class="discover-intro__variant-wrapper">
  <span class="discover-intro__title"> Как будешь знакомиться с профессией{{ name }}? </span>
  <span class="discover-intro__text"> Выбери до трех вариантов </span>
  <div class="discover-intro__tags-container">
    <a
      *ngFor="let variant of introVariants; let i = index"
      class="discover-intro__tag"
      [class.discover-intro__tag_selected]="variant.selected"
      (click)="selectAnswer(i)"
    >
      <span>{{ variant.text }}</span>
    </a>
  </div>
</div>

<div class="discover-intro__navigation-wrapper">
  <button class="discover-intro__button discover-intro__button_back" (click)="back.emit()">
    <span class="discover-intro__gradient-text">
      {{ 'BUTTONS.PREVIOUS' | translate }}
    </span>
  </button>

  <button
    class="discover-intro__button discover-intro__button_forward"
    [class.discover-intro__button_disabled]="!isValid"
    (click)="forwardButtonClick()"
  >
    {{ 'BUTTONS.NEXT' | translate }}
  </button>
</div>
