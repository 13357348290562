import { Component, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ApiFavoritesService, UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-courses-page',
  templateUrl: './courses-page.component.html',
  styleUrls: ['./courses-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoursesPageComponent {
  public userRole: string = '';
  public tabName: string = 'catalog';

  constructor(
    private meta: Meta,
    private apiFavoritesService: ApiFavoritesService,
    private router: Router,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Курсы' });
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/courses') {
          switch (this.userRole) {
            case 'pupil':
            case 'parent':
            case 'teacher':
            case 'tutor':
              break;
            default:
              this.router.navigate([`${this.userRole}/courses`]);
              break;
          }
        }
      }
    });
  }
}
