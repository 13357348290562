import { Injectable } from '@angular/core';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { ITalentsFilter } from 'app/shared/interfaces/ifilterclasses.interface';

import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettingsService, EmptyGuid, IFilterClasses, StorageKeys, WebStorageService } from '@profilum-library';

@Injectable()
export class CoursesFiltersService {
  private coursesFilters = new BehaviorSubject<IFilterClasses>({});
  private talentsFilters = new BehaviorSubject<ITalentsFilter>({});
  public schoolID: string = '';

  defaultTerritoryId: string = EmptyGuid;

  constructor(private webStorageService: WebStorageService) {}

  getCoursesFilters(): Observable<IFilterClasses> {
    return this.coursesFilters.asObservable();
  }
  setCoursesFilter(filters: any, schoolID?: any) {
    if (schoolID) {
      this.schoolID = schoolID;
    }
    this.coursesFilters.next(filters);
  }

  getTalentsFilters(): Observable<ITalentsFilter> {
    return this.talentsFilters.asObservable();
  }
  setTalentsFilter(filters: any) {
    this.talentsFilters.next(filters);
  }

  public setRegionMunicipalityFilters(filters: IFilterClasses) {
    const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
    const municipalityId: string = this.webStorageService.get(StorageKeys.MunicipalityId);
    const userRole: string = this.webStorageService.get(StorageKeys.UserRole);
    if (userRole === 'admin') {
      const adminLevel: string = this.webStorageService.get(StorageKeys.AdminLevel);
      switch (adminLevel) {
        case AdminAccessLevel.MUNICIPALITY:
          {
            filters.regionId = regionId;
            filters.municipalityId = municipalityId;
          }
          break;
        case AdminAccessLevel.REGION:
          {
            filters.regionId = regionId;
          }
          break;
      }
    } else if (
      userRole === 'schooladmin' &&
      AppSettingsService.settings.b2c &&
      this.webStorageService.get(StorageKeys.SchoolId) === AppSettingsService.settings.b2c.schoolId
    ) {
      // для шк. админа b2c
      filters.regionId = this.defaultTerritoryId;
    } else {
      filters.regionId = regionId;
      filters.municipalityId = municipalityId;
    }
    return filters;
  }
}
