<ng-container *ngIf="loaded">
  <!-- секция, если родитель на начал тест -->
  <div *ngIf="!sessionIDParent" class="test-parent-div">
    <div class="welcome-row">
      <div class="test-parent-column">
        <ng-container *ngIf="isPupil">
          <h3 class="test-parent-h3">{{ 'PROFESSIONS.LAUNCH_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.SYSTEM_DIAGNOSTIC_NARRATIVE' | translate }}
          </p>
        </ng-container>
        <ng-container *ngIf="!isPupil">
          <h3 class="test-parent-h3">{{ 'SHARED.TEST_360.COMPLETE_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.TEST_360.NARRATIVE' | translate }}
          </p>
        </ng-container>
        <div *ngIf="!uncompletedTest" class="main-screen-buttons-row">
          <a (click)="navigateStartTest()" class="button test-parent-button w-button">{{ 'SHARED.START_TEST' | translate }}</a>
        </div>
        <div *ngIf="uncompletedTest" class="main-screen-buttons-row">
          <a (click)="navigateContinueTest()" class="button welcome-button-stud-2 testing w-button">Продолжить тест</a>
          <a (click)="navigateStartTest()" class="button welcome-button-stud-2 white-button w-button">Начать заново</a>
        </div>
      </div>
      <img *ngIf="!isPupil" src="./profilum-assets/images/illustrations/Parent360.svg" alt="родитель" width="353" class="test-parent-img" />
      <ng-container *ngIf="isPupil">
        <img
          src="{{
            isMale(RefferalUserGender)
              ? './profilum-assets/images/illustrations/Pupil-m.svg'
              : './profilum-assets/images/illustrations/Pupil-w.svg'
          }}"
          alt="gender"
          width="365"
          class="welcome-image-stud"
        />
      </ng-container>
    </div>
  </div>

  <!-- секция, если тест начат, но не пройден -->
  <div *ngIf="sessionIDParent && parentSessionComplete == false" class="test-parent-div">
    <div class="welcome-row">
      <div class="test-parent-column testing">
        <h3 class="test-parent-h3">{{ 'SHARED.SILENCE_TEST_IN_PROGRESS' | translate }}!</h3>
        <div class="progress-block parent-test">
          <div class="progress-text">{{ currentSlideNumber }} из {{ slidesCount }}</div>
          <div class="progress-bar">
            <div class="progress-bar-color" [style.width]="progressBarWidth + '%'"></div>
          </div>
        </div>
        <p class="test-parent-text">Ты начал тест, не бросай это дело и результаты обрадуют тебя.</p>
        <div class="main-screen-buttons-row">
          <a routerLink="/test-na-professiyu/{{ sessionIDParent }}" class="button test-parent-button testing w-button">Продолжить тест</a>
          <a routerLink="/test-na-professiyu" class="button test-parent-button white-button w-button">Начать заново</a>
        </div>
      </div>
      <img *ngIf="!isPupil" src="./profilum-assets/images/illustrations/Parent360.svg" alt="родитель" width="353" class="test-parent-img" />
      <ng-container *ngIf="isPupil">
        <img
          src="{{
            isMale(RefferalUserGender)
              ? './profilum-assets/images/illustrations/Pupil-m.svg'
              : './profilum-assets/images/illustrations/Pupil-w.svg'
          }}"
          alt="gender"
          width="402"
          class="welcome-image-stud"
        />
      </ng-container>
    </div>
  </div>
</ng-container>
