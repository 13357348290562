import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, take, tap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { ClipboardService } from 'ngx-clipboard';
import {
  Helper,
  IActiveLesson,
  IExtendedPupil,
  ISchoolClass,
  StorageKeys,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import {
  IAssigningCourseData,
  ICourseMaterialExt,
} from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { MOSRED_ID } from 'app/shared/interfaces/regions-id.interface';
import { RootScopeService } from 'app/shared/services/root-scope.service';

@Component({
  selector: 'prf-class-courses',
  templateUrl: './class-courses.component.html',
  styleUrls: ['./class-courses.component.scss'],
})
export class ClassCoursesComponent extends UnsubscribeComponent implements OnInit {
  public classInfo: ISchoolClass;
  public pupils: IExtendedPupil[] = [];
  public popup: boolean = false;
  public isFirstSession: boolean = false;
  public isInvitationCopied: boolean = false;
  public isActive: boolean = false;
  public course: ICourseMaterialExt;
  public sectionsCount: number = 0;
  public lessonsCount: number = 0;
  public goToLessonSubject$: ReplaySubject<IActiveLesson>;
  public showCourse: boolean;
  public declOfNum = Helper.declOfNum;
  public isMOStend: boolean = false;
  public isUserFromSalesB2S: boolean = false;
  public texts: Record<string, string> = {
    DEFAULT: `Наш класс проходит профориентационный курс "Россия - мои горизонты", в рамках которого
         ребята узнают об особенностях образования и рынка труда нашего региона.
         Зарегистрируйте вашего ребенка, чтобы он получил доступ к необходимой для прохождения курса информации и диагностикам.
         Регистрация займет 2 минуты, перейдите по ссылке ниже и заполните форму.`,
    SALES_B2S: `Наш класс будет проходить курс «Карьерная грамотность».
     Чтобы ваш ребенок получил доступ к учебным материалам и рекомендациям по профессиям,
      вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.`,
  };

  private copyText: string = '';
  private regionId: string = '';
  private mosregId = MOSRED_ID;

  constructor(
    private route: ActivatedRoute,
    private teacherPanelService: TeacherPanelService,
    private clipboardService: ClipboardService,
    private router: Router,
    private overlayBusy: OverlayBusyService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private webStorageService: WebStorageService,
    private rootScopeService: RootScopeService,
    private yandexMetricsService: YandexMetricsService,
  ) {
    super();
    this.goToLessonSubject$ = new ReplaySubject<IActiveLesson>(1);
  }

  public ngOnInit(): void {
    this.initClassInfo();
    this.overlayBusy.show();
    this.rootScopeService.currentCLass
      .pipe(
        filter(v => v !== null),
        tap((courseData: IAssigningCourseData) => {
          this.lessonsCount = 0;
          this.classInfo = courseData.schoolClass as ISchoolClass;
          this.course = courseData.course as ICourseMaterialExt;

          if (this.course?.parsedLessons) {
            this.sectionsCount = this.course.parsedLessons.length;

            this.course.parsedLessons.forEach(([, lessonsData]) => {
              this.lessonsCount += lessonsData.lessons.length;
            });
          }

          this.showCourse = !courseData.activeLesson?.lesson;
        }),
        this.unsubscribeOperator,
      )
      .subscribe(
        () => {
          this.isActive = this.classInfo.pupilsCount > 0;
          this.overlayBusy.hide();
        },
        () => {
          this.overlayBusy.hide();
        },
      );
  }

  public closePopup(): void {
    this.popup = false;
    this.isFirstSession = false;
    this.webStorageService.set(StorageKeys.IsInvitationCopied, true);
  }

  public copyPupilRegLink(): void {
    this.yandexMetricsService.reachGoal(YmItems.T_Lessons_invitationInEmptyClass);
    this.popup = true;

    this.copyText =
      `Здравствуйте, ${this.isUserFromSalesB2S ? '' : 'уважаемые'} родители ${this.classInfo.className} класса! \r\n` +
      (this.isUserFromSalesB2S ? this.texts.SALES_B2S : this.texts.DEFAULT);

    const copyLink: string = this.classInfo.shortLink ? this.classInfo.shortLink : this.classInfo.parentRegistrationLink;
    this.clipboardService.copy(this.copyText + ' ' + copyLink);
  }

  public goToLesson(): void {
    this.teacherClassCoursesService
      .getActiveLesson(this.classInfo.id)
      .pipe(take(1))
      .subscribe((activeLesson: IActiveLesson) => {
        this.goToLessonSubject$.next(activeLesson);
      });
  }

  private initClassInfo(): void {
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    this.isUserFromSalesB2S = this.regionId === '2683eee3-306b-49a3-a7b9-7ce28df17251';
    this.isMOStend = this.mosregId === this.regionId;

    this.isFirstSession = this.webStorageService.get(StorageKeys.IsFirstSession);
    this.isInvitationCopied = this.webStorageService.get(StorageKeys.IsInvitationCopied);
    this.isFirstSession = this.isFirstSession ? !this.isInvitationCopied : this.isFirstSession;
  }
}
