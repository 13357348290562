<ng-container [ngSwitch]="classViewSelected">
  <ng-container *ngSwitchCase="classPageViews.CLASS_VIEW">
    <div class="class-wrapper">
      <div class="class-block_main" [ngClass]="showEditPupilsComponent ? 'hide' : '' || showEditClassComponent ? 'hide' : ''">
        <div class="return-button">
          <a (click)="routingBack()" class="button white-button w-button button_back">
            <span class="icon-back"></span>
            <span>
              {{ backButtonText }}
            </span>
          </a>
        </div>

        <!-- компонент класса для просмотра из-под админов -->
        <prf-schooladmin-class
          (editClassEvent)="showEditClass($event)"
          (editPupilsEvent)="showEditPupils($event)"
          (teacherEvent)="setTeacher($event)"
          (schoolClassEvent)="setSchoolClass($event)"
          (pupilsEvent)="setPupils($event)"
          [isUserFromSalesB2S]="isUserFromSalesB2S"
        ></prf-schooladmin-class>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="classPageViews.EDIT_CLASS_VIEW">
    <!-- редактирование класса из-под шк. админа -->
    <prf-schooladmin-edit-class (showEditClass)="hideEditClass($event)" [teacher]="teacher" [teacherClass]="schoolClass">
    </prf-schooladmin-edit-class>
  </ng-container>

  <ng-container *ngSwitchCase="classPageViews.EDIT_PUPILS_VIEW">
    <!-- добавление учеников из-под шк. админа -->
    <prf-schooladmin-add-students
      [schoolClass]="schoolClass"
      (showEditPupils)="hideEditPupils($event)"
      [pupils]="pupils"
      [teacherClass]="schoolClass"
      [schoolId]="userProfile.schoolId"
      [currentClassName]="schoolClass.number + schoolClass.letter"
    >
    </prf-schooladmin-add-students>
  </ng-container>
</ng-container>
