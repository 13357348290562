import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { IFilterClasses } from '@profilum-library';

export const COURSES_TYPES_DATA = [{ value: 'today', viewValue: 'Сегодня' }];

@Component({
  selector: 'prf-courses-filter-date',
  templateUrl: './courses-filter-date.component.html',
  styleUrls: ['./courses-filter-date.component.scss'],
})
export class CoursesFilterDateComponent implements OnInit, OnDestroy {
  subscription: any;
  filters: IFilterClasses;
  types = COURSES_TYPES_DATA;
  selectedTime: any = [];
  classes: any = [];
  dateOpen: boolean = false;
  selectType: string;
  @ViewChild('dateFilter') dateFilter: ElementRef;

  constructor(private filtersService: CoursesFiltersService) {}

  ngOnInit() {
    this.subscription = this.filtersService.getCoursesFilters().subscribe(data => {
      this.filters = data;
      this.selectedTime = data.publishedDate ? data.publishedDate : [];
    });
  }

  setTypeFilter(type: any) {
    this.selectType = type.value;
    if (type.value == 'today') {
      const date = new Date();
      this.filters.publishedDate = date.toDateString();
    } else {
      this.filters.publishedDate = null;
    }
    this.filtersService.setCoursesFilter(this.filters);
  }

  deselectDate() {
    this.filters.publishedDate = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.publishedDate) {
      const type = this.types.filter(el => el.value === this.selectType);
      return type[0].viewValue;
    } else {
      return 'Дата';
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.dateFilter && !this.dateFilter?.nativeElement.contains(target)) {
      this.dateOpen = false;
    }
  }
}
