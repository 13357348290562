<div class="prf-popup-overlay">
  <div class="prf-popup">
    <a (click)="closePopup()" class="close-lb w-inline-block">
      <img src="./assets/images/icons/close-grey.svg" alt="" />
    </a>
    <div class="add-child__scroll">
      <div class="prf-popup-header">
        <h3 class="prf-popup-title">Добавить подростка в {{ classInfo.classNumber }} «{{ classInfo.classLetter }}»</h3>
      </div>
      <form class="content" [formGroup]="addChildForm" name="wf-form-Login" autocomplete="off">
        <div class="prf-form-control add-child-form__control">
          <prf-dynamic-label [prfFormControl]="formControls.lastName" [prfFormControlElement]="lastName">
            {{ 'SHARED.FORM.FAMILY' | translate }}
          </prf-dynamic-label>
          <input
            class="prf-form-input parent-form__input"
            type="text"
            [class.error-field]="submitted && formControls.lastName.errors"
            name="lastName"
            data-name="lastName"
            id="lastName"
            formControlName="lastName"
            autocomplete="off"
            #lastName
          />
        </div>

        <div class="prf-form-control add-child-form__control">
          <prf-dynamic-label [prfFormControl]="formControls.firstName" [prfFormControlElement]="firstName">
            {{ 'SHARED.FORM.NAME' | translate }}
          </prf-dynamic-label>
          <input
            class="prf-form-input parent-form__input"
            type="text"
            [class.error-field]="submitted && formControls.firstName.errors"
            name="firstName"
            data-name="firstName"
            id="firstName"
            formControlName="firstName"
            autocomplete="off"
            #firstName
          />
        </div>

        <div class="prf-form-control add-child-form__control">
          <prf-dynamic-label [prfFormControl]="formControls.date" [prfFormControlElement]="dateInput"> Дата рождения </prf-dynamic-label>
          <input
            class="prf-form-input parent-form__input"
            type="text"
            [class.error-field]="submitted && formControls.date.errors"
            [textMask]="{ mask: dateMask }"
            name="date"
            data-name="date"
            id="date"
            formControlName="date"
            autocomplete="off"
            (focusout)="onFocusOutDataField()"
            #dateInput
          />
          <ng-template [ngIf]="formControls.date.dirty && formControls.date.errors">
            <ng-container [ngSwitch]="true">
              <mat-error *ngSwitchCase="!!formControls.date.errors.required">Заполните поле</mat-error>
              <mat-error *ngSwitchCase="!!formControls.date.errors.invalidDate"> Проверьте правильность введенных данных </mat-error>
            </ng-container>
          </ng-template>
        </div>

        <div class="prf-form-control add-child-form__control">
          <prf-dynamic-label [prfFormControl]="formControls.phoneNumber" [prfFormControlElement]="phoneNumber">
            {{ 'SHARED.FORM.PHONE' | translate }}
          </prf-dynamic-label>
          <input
            class="prf-form-input parent-form__input"
            type="text"
            autocomplete="off"
            prfPhoneMask
            [control]="formControls.phoneNumber"
            [htmlInputElement]="phoneNumber"
            [class.error-field]="focusOutFromPhoneField && formControls.phoneNumber.errors"
            name="phone"
            data-name="phone"
            id="phone"
            formControlName="phoneNumber"
            [placeholder]="'SHARED.FORM.PHONE' | translate"
            (focusout)="checkPhone(); focusOutFromPhoneField = true"
            #phoneNumber
          />
          <prf-reset-button
            [prfFormControl]="formControls.phoneNumber"
            [prfFormControlElement]="phoneNumber"
            [defaultValue]="' '"
          ></prf-reset-button>
          <ng-template [ngIf]="phoneChecked && formControls.phoneNumber.errors">
            <ng-container [ngSwitch]="true">
              <mat-error *ngSwitchCase="!!formControls.phoneNumber.errors.required"> Заполните поле </mat-error>
              <mat-error *ngSwitchCase="!!formControls.phoneNumber.errors.pattern"> Проверьте правильность номера телефона </mat-error>
              <mat-error *ngSwitchCase="!!formControls.phoneNumber.errors.isUsed"> Телефон зарегистрирован. </mat-error>
              <mat-error *ngSwitchCase="!!formControls.phoneNumber.errors.isUsedOnRegistrationForm">
                Телефон уже используется, введите другой
              </mat-error>
              <mat-error *ngSwitchCase="!!formControls.phoneNumber.errors.lessThan11Numbers"> Телефон должен содержать 11 цифр </mat-error>
            </ng-container>
          </ng-template>
        </div>

        <div class="profile-data-row gender-choice">
          <label class="login-label-gender">Пол</label>
          <prf-pupil-gender-toggle formControlName="gender"></prf-pupil-gender-toggle>
        </div>
      </form>

      <div class="prf-popup-buttons add-child__button">
        <button
          (click)="submitPupilAddForm()"
          class="prf-popup-button prf-popup-button_primary"
          [class.disabled]="!addChildForm.valid || !addChildForm.touched"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
