import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'prf-admin-do-panel',
  templateUrl: './admin-do-panel.component.html',
  styleUrls: ['./admin-do-panel.component.scss'],
})
export class AdminDOPanelComponent implements OnInit, OnDestroy {
  public title: string = 'Курсы и мероприятия';

  constructor(private meta: Meta, private router: Router) {
    this.meta.updateTag({
      name: 'og:title',
      content: 'Страница администратора дополнительного образования',
    });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkTitle(event.url);
      }
    });
  }

  ngOnInit() {}

  checkTitle(url) {
    if (url == '/adminDO/courses') {
      this.title = 'Курсы и мероприятия';
    } else if (url == '/adminDO/create-course') {
      this.title = 'Курсы и мероприятия';
    } else if (url == '/adminDO') {
      this.title = 'Курсы и мероприятия';
    }
  }

  ngOnDestroy() {}
}
