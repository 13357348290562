import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CoursesFiltersService } from './courses-filters.service';
import { IFilterClasses, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { COURSES_FORMAT_DATA_NEW, COURSES_TYPES_DATA_NEW } from '../../../../global-constants/constants';
import { ClassesFormatTypes } from '../../../../enums/courses-types.enum';

export const AGE_DATA = [
  {
    label: '0+',
    value: 'ZeroPlus',
  },
  {
    label: '4 - 6',
    value: 'FourSix',
  },
  {
    label: '7 - 10',
    value: 'SevenTen',
  },
  {
    label: '11 - 13',
    value: 'ElevenThirteen',
  },
  {
    label: '14 - 16',
    value: 'FourteenSixteen',
  },
  {
    label: '16+',
    value: 'SixteenPlus',
  },
];

@Component({
  selector: 'prf-courses-filter',
  templateUrl: './courses-filter.component.html',
  styleUrls: ['./courses-filter.component.scss'],
})
export class CoursesFilterComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  talents = [];
  groups = [];
  metro = [];
  maxPrice: any;
  ageRangeData: any = AGE_DATA;
  filters: IFilterClasses;
  priceHeader: string;
  disabledMaxPrice: boolean = false;
  isPriceFree: boolean = false;
  ageHeader: string = '';
  public schoolId: string = '';

  @ViewChild('ageFilter') ageFilter: ElementRef;
  @ViewChild('ageDrop') ageDrop: ElementRef;
  @ViewChild('priceFilter') priceFilter: ElementRef;
  @ViewChild('priceDrop') priceDrop: ElementRef;

  constructor(
    private filtersService: CoursesFiltersService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.getTranslation('PLAYER.ANKETA.TELL_ABOUT.AGE')
      .pipe(take(1))
      .subscribe(translation => (this.ageHeader = translation));
  }

  ngOnInit() {
    this.schoolId = this.webStorageService.get(StorageKeys.SchoolId);
    this.filtersService
      .getCoursesFilters()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(data => {
          this.filters = data;
          return this.checkPriceHeader(this.filters.maxPrice).pipe(tap(r => (this.priceHeader = r)));
        }),
      )
      .subscribe();
  }

  setFilter(obj: any = {}) {
    for (const key in obj) {
      if (obj) {
        this.filters[key] = obj[key];
      }
    }
    this.filtersService.setCoursesFilter(this.filters);
  }

  //TODO: Надо перерабатывать все новые методы по обработке (сделаны для проверки, что фильтр будет работать с новыми дропдаунами)
  handleSetFormat(data: any) {
    if (!data) {
      this.setFilter({ format: '' });
      COURSES_FORMAT_DATA_NEW.isActive = false;
      COURSES_FORMAT_DATA_NEW.activeOption = COURSES_FORMAT_DATA_NEW.defaultOption;
      return;
    }

    const formatFilterData = { format: data.value };
    this.setFilter(formatFilterData);
    const activeOption = COURSES_FORMAT_DATA_NEW.options.find(optionData => optionData.data.value === data.value);
    COURSES_FORMAT_DATA_NEW.isActive = true;
    COURSES_FORMAT_DATA_NEW.activeOption = activeOption;
  }

  handleSetType(data: any) {
    if (!data) {
      this.setFilter({ classesFormat: null });
      COURSES_TYPES_DATA_NEW.isActive = false;
      COURSES_TYPES_DATA_NEW.activeOption = COURSES_TYPES_DATA_NEW.defaultOption;
      return;
    }

    const enumIndex: string = ClassesFormatTypes[data.value];
    const typeFilterData = { classesFormat: enumIndex ? [enumIndex] : null };

    this.setFilter(typeFilterData);
    const activeOption = COURSES_TYPES_DATA_NEW.options.find(optionData => optionData.data.value === data.value);
    COURSES_TYPES_DATA_NEW.isActive = true;
    COURSES_TYPES_DATA_NEW.activeOption = activeOption;
  }

  checkFilters() {
    switch (true) {
      case this.filters.metroStationNames && this.filters.metroStationNames.length > 0:
        return true;
      case this.filters.audience && this.filters.audience.length > 0:
        return true;
      case this.filters.courseGroups && this.filters.courseGroups.length > 0:
        return true;
      case this.filters.courses && this.filters.courses.length > 0:
        return true;
      case this.filters.classesFormat && this.filters.classesFormat.length > 0:
        return true;
      case this.filters.maxPrice >= 0:
        return true;
      case this.filters.institutionId && this.filters.institutionId.length > 0:
        return true;
      default:
        return false;
    }
  }

  checkPriceHeader(price): Observable<any> {
    if (price || price === 0) {
      if (price > 0) {
        return this.getTranslation('SHARED.BEFORE').pipe(map(translation => translation + ' ' + this.filters.maxPrice));
      } else {
        return this.getTranslation('SHARED.ONLY_FREE');
      }
    } else {
      return this.getTranslation('SHARED.PRICE');
    }
  }

  checkAgeHeader() {
    if (this.filters.audience && this.filters.audience.length > 0) {
      const age = this.ageRangeData.filter(el => el.value === this.filters.audience);
      return age[0].label;
    } else {
      return this.ageHeader;
    }
  }

  updatePriceByValue() {
    if (!this.isPriceFree && this.maxPrice > 100) {
      this.setMaxPrice();
    }
  }

  updatePriceByCheckbox(checkbox: any) {
    this.isPriceFree = checkbox.classList.contains('checked');
    this.disabledMaxPrice = this.isPriceFree;
    this.setMaxPrice();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearAllFilters() {
    this.filtersService.setCoursesFilter({});
    this.disabledMaxPrice = false;
    this.maxPrice = null;
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.priceFilter && !this.priceFilter?.nativeElement.contains(target)) {
      this.priceDrop.nativeElement.classList.remove('w--open');
    }

    if (this.ageFilter && !this.ageFilter.nativeElement.contains(target)) {
      this.ageDrop.nativeElement.classList.remove('w--open');
      this.ageFilter.nativeElement.classList.remove('w--open');
    }
  }

  private setMaxPrice() {
    if (this.isPriceFree) {
      this.setFilter({ maxPrice: 0 });
    } else {
      this.setFilter({ maxPrice: this.maxPrice });
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  protected readonly COURSES_FORMAT_DATA_NEW = COURSES_FORMAT_DATA_NEW;
  protected readonly COURSES_TYPES_DATA_NEW = COURSES_TYPES_DATA_NEW;
}
