<div class="content scrollable-container">
  <h2 class="title">{{ modalContent.titleText }}</h2>
  <prf-notification-new
    class="notification"
    [type]="notificationType"
    [content]="modalContent.notification"
    [iconSrc]="'./profilum-assets/images/common/info-warning.svg'"
  ></prf-notification-new>
  <div class="step" *ngFor="let step of modalContent.steps; let index = index">
    <div class="step-count">{{ index + 1 }}</div>
    <span><b>{{ step.name }}</b>{{ step.text }}</span>
  </div>
  <h3 class="title-invite">{{ modalContent.inviteTextTitle }}</h3>
  <form class="form" [formGroup]="form">
    <textarea
      class="invite-text"
      id="inviteText"
      name="inviteText"
      maxlength="5000"
      formControlName="inviteText"
    ></textarea>
  </form>
  <div class="action-panel">
    <prf-button class="invite-button" [buttonTitle]="modalContent.buttonText" [type]="modalContent.buttonType" (emitClick)="copyText()"></prf-button>
  </div>
</div>

