<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share">
      <a (click)="closePopup()" class="popup-share-close">
        <img src="./profilum-assets/images/common/close-popup.svg" alt="" />
      </a>
      <ng-container *ngIf="_changePhoneInfo.isFree; else notFreeNumberTemplate">
        <div class="popup-share-section">
          <div class="popup-share-section-header">
            <p class="popup-share-section-header-title">
              Новый номер ученика
              <span class="popup-share-section-header-title-name">{{ _changePhoneInfo.pupil.fullNameText }}:</span>
              <span class="popup-share-section-header-title-name">{{ _changePhoneInfo.newPhone }}</span>
            </p>
            <p class="popup-share-section-header-text">
              Проверьте правильность введённого телефона. С его помощью ученик будет входить в систему.
            </p>
          </div>
          <div class="popup-share-section-buttons">
            <button class="popup-share-section-buttons-success" (click)="updatePupilPhone()">Всё верно</button>
            <button class="popup-share-section-buttons-cancel" (click)="closePopup()">Отменить редактирование</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #notFreeNumberTemplate>
  <div class="popup-share-section">
    <div class="popup-share-section-header">
      <div class="popup-share-section-header-icon">
        <img src="./profilum-assets/images/common/error-popup.svg" alt="" />
      </div>
      <p class="popup-share-section-header-title">
        <span class="popup-share-section-header-title-name">{{ _changePhoneInfo.newPhone }} уже используется, введите другой номер</span>
      </p>
      <p class="popup-share-section-header-text">Вы ввели телефон, который зарегистрирован в системе. Вам нужно ввести новый</p>
    </div>
    <div class="popup-share-section-buttons">
      <button class="popup-share-section-buttons-success" (click)="closeSuccessPopup()">Ввести другой</button>
      <button class="popup-share-section-buttons-cancel" (click)="closePopup()">Отменить редактирование</button>
    </div>
  </div>
</ng-template>
