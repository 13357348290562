<div class="prof-card">
    <ng-container *ngIf="!loading && cardData?.image; else placeholder">
      <div class="prof-card__image-wrapper">
        <div class="skeleton-img" *ngIf="loading else professionImage"></div>
      </div>
    </ng-container>

    <div class="prof-card__title">
      <span *ngIf="!loading" class="prof-card__title-text">{{ cardData?.title }}</span>
    </div>
    <div #cardContentRef class="prof-card__description scrollable-container" [innerHtml]="cardData?.content"></div>
  </div>


<ng-template #professionImage>
  <img class="prof-card__image" src="{{ cardData.image }}" alt="profession card image" fill/>
</ng-template>
<ng-template #placeholder>
  <div class="prof-card__image prof-card__image_placeholder"></div>
</ng-template>
