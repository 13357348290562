import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { ApiFieldsService, ApiSearchService, IFilterClasses } from '@profilum-library';
import {
  InternshipsFiltersService,
} from '../../../../../internship-page/internship-catalog/internship-filter/internships-filter.service';

@Component({
  selector: 'prf-select-themes',
  templateUrl: './select-themes.component.html',
  styleUrls: ['./select-themes.component.scss'],
})
export class SelectThemesComponent implements OnInit, OnDestroy {
  groups: any;
  filters: IFilterClasses;
  selectedCourses: any = [];
  searchCourse: string = '';
  selectedGroup: any = [];
  _existingThemes: any; //переменная нужна для временного хранения уже существующих тематик при редактировании мероприятия
  @ViewChild('groupFilter', { static: true }) groupFilter: ElementRef;
  @ViewChild('groupDrop') groupDrop: ElementRef;
  @Output() dataSelectThemes = new EventEmitter();

  private ngUnsubscribe$ = new Subject<any>();

  @Input()
  set existingThemes(val: any) {
    this._existingThemes = val;
    this.filters = {};
    this.filters.courses = val;
    this.filtersService.setCoursesFilter(this.filters);
  }

  constructor(
    private apiSearchService: ApiSearchService,
    private apiFieldsService: ApiFieldsService,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
  ) {}

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.filters = data;
        if (this._existingThemes) {
          this.filters.courses = this._existingThemes;
        }
        this.dataSelectThemes.emit(this.filters.courses);
        this.selectedCourses = data.courses ? data.courses : [];
      });

    observableForkJoin([this.apiSearchService.getTalentGroupCourse(), this.apiFieldsService.getGroups()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([talentGroupCourse, courseGroups]) => {
        talentGroupCourse.map(({ talent: name, groups }) => ({ name, groups }));

        this.groups = courseGroups.fieldDOs.map(({ name }) => ({ name, courses: [] }));
        talentGroupCourse.forEach(({ groups = [] }) => {
          groups.forEach(({ group, courses = [] }) => {
            let courseGroup = this.groups.find(({ name }) => name === group);

            if (!courseGroup) {
              courseGroup = {
                name: group,
                courses: [],
              };
              this.groups.push(courseGroup);
            }

            courses.forEach(course => {
              if (!courseGroup.courses.find(name => name === course)) {
                courseGroup.courses.push(course);
              }
            });
          });
        });
        this.groups = this.groups.filter(({ courses }) => courses.length);
        this.groups.forEach(group => {
          group.courses.sort();
          group.courses = group.courses.map(name => ({ name }));
        });
        this.checkGroupName();
      });
  }

  checkFilterHeader() {
    if (this.filters.courses && this.filters.courses.length > 0) {
      if (this.filters.courses.length > 1) {
        const courseTitles = ['курс', 'курса', 'курсов'];
        const n = this.filters.courses.length;
        const title = this.internshipsFiltersService.getTitleByNumber(n, courseTitles);
        return n + ' ' + title;
      } else {
        const course = this.selectedCourses.filter(el => el === this.filters.courses[0]);
        return course[0];
      }
    } else {
      return 'Направление';
    }
  }

  setCourseFilter(course: any) {
    if (!this.filters.courses) {
      this.filters.courses = [course.name];
    } else {
      const index = this.selectedCourses.indexOf(course.name);
      index !== -1 ? this.filters.courses.splice(index, 1) : this.filters.courses.push(course.name);
    }
    this._existingThemes = this.filters.courses;
    this.dataSelectThemes.emit(this.filters.courses);
    this.filtersService.setCoursesFilter(this.filters);
    this.checkGroupName();
  }

  deselectCourses() {
    this.filters.courses = null;
    this._existingThemes = null;
    this.selectedGroup = [];
    this.filtersService.setCoursesFilter(this.filters);
  }

  setGroupFilter(group: any) {
    const courses = group.courses.map(el => el.name);
    if (this.selectedGroup && this.selectedGroup.length > 0) {
      const index = this.selectedGroup.indexOf(group.name);
      if (index > -1) {
        this.selectedGroup = this.selectedGroup.filter(el => el != group.name);
        //this.selectedGroup.splice(index, 1);
        this.filters.courses = this.filters.courses.filter(el => courses.indexOf(el) === -1);
      } else {
        this.selectedGroup.push(group.name);
        this.filters.courses = this.filters.courses.concat(courses);
      }
    } else {
      this.selectedGroup = [group.name];
      this.filters.courses = courses;
    }
    this._existingThemes = this.filters.courses;
    this.dataSelectThemes.emit(this.filters.courses);
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkGroupName() {
    this.selectedGroup = [];
    if (this.filters.courses && this.filters.courses.length > 0) {
      this.groups.forEach(group => {
        group.courses.forEach(course => {
          if (this.filters.courses.includes(course.name)) {
            if (this.selectedGroup && this.selectedGroup.length > 0) {
              this.selectedGroup.push(group.name);
            } else {
              this.selectedGroup = [group.name];
            }
          }
        });
      });
    }
  }

  closeOther(dropdown: any, icon: any) {
    dropdown.classList.toggle('w--open');
    const elements = this.groupFilter.nativeElement.querySelectorAll('.event-dd-second.w-dropdown-list.w--open');
    elements.forEach(el => (el !== dropdown ? el.classList.remove('w--open') : false));
    icon.classList.toggle('rotateUp');
    const icons = this.groupFilter.nativeElement.querySelectorAll('.event-category-dd-icon.w-icon-dropdown-toggle.rotateUp');
    icons.forEach(el => (el !== icon ? el.classList.remove('rotateUp') : false));
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.groupFilter && !this.groupFilter?.nativeElement.contains(target)) {
      this.groupDrop.nativeElement.classList.remove('w--open');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
