import { Component } from '@angular/core';
import { IButtonOnbording, IMessageOnbording } from 'app/shared/interfaces/common/ondording.interface';
import { Router } from '@angular/router';
import { StorageKeys, UserActionsService, WebStorageService, YmItems } from '@profilum-library';

@Component({
  selector: 'prf-programs-spo-vpo-onboarding',
  templateUrl: './programs-spo-vpo-onboarding.component.html',
  styleUrls: ['./programs-spo-vpo-onboarding.component.scss'],
})
export class ProgramsSpoVpoOnboardingComponent {
  public endMessageNumber: number = 3;
  public messages: IMessageOnbording[] = [
    { text: '<p>Привет! 👋 <br /> Мы подготовили для тебя кое-что новенькое!</p>' },
    {
      text:
        '<p>Это <span class="onbording-main-text">персональная подборка</span> ' +
        'программ обучения, подобранная на основе диагностики ' +
        '<span class="onbording-main-text">интересов</span> и твоего' +
        ' <span class="onbording-main-text">местоположения</span></p>',
    },
    {
      text: '<p>Теперь ты всегда сможешь найти эту подборку у себя в профиле</p>',
      image: './assets/images/onboarding/spoVpoPrograms.png',
    },
  ];
  public buttons: IButtonOnbording[] = [{ text: 'Пойдем посмотрим!' }];

  constructor(private router: Router, private webStorageService: WebStorageService, private userActionsService: UserActionsService) {
    this.userActionsService.setInitializationTime([YmItems.S_Profile_SPOVO_Open, YmItems.S_Profile_SPOVO_Lets_See]);
    this.userActionsService.log(YmItems.S_Profile_SPOVO_Open);
  }

  public buttonsActions(): void {
    this.webStorageService.set(StorageKeys.OnbordingSpoVo, true);
    this.webStorageService.set(StorageKeys.ResultsType, 'Интересы');
    this.userActionsService.log(YmItems.S_Profile_SPOVO_Lets_See);
    this.router.navigate(['/pupil-profile']);
  }
}
