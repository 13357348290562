import { IDiagnosticResults } from './diagnostic-results.interface';

export const CAREER_LITERACY_MOCK: IDiagnosticResults = {
  chartType: 'doughnut',
  titleText: 'Карьерная грамотность',
  subtitleText: 'Занятие 3',
  status: 'not-passed',
  descriptionText: 'Задание не выдано',
  data: [],
  hideToggle: true,
};

export const INTERESTS_MOCK: IDiagnosticResults = {
  chartType: 'doughnut',
  titleText: 'Интересы',
  subtitleText: 'Занятие 7',
  status: 'not-passed',
  descriptionText: 'Задание не выдано',
  data: [
    {
      name: 'Анализ информации',
      percents: 66,
      count: 20,
    },
    {
      name: 'Природа и материалы',
      percents: 60,
      count: 18,
    },
    {
      name: 'Творчество и искусство',
      percents: 33,
      count: 10,
    },
    {
      name: 'Бизнес и управление',
      percents: 27,
      count: 8,
    },
    {
      name: 'Люди и отношения',
      percents: 24,
      count: 7,
    },
    {
      name: 'Мастерство и ручной труд',
      percents: 17,
      count: 5,
    },
    {
      name: 'Техника и технолгии',
      percents: 7,
      count: 2,
    },
    {
      name: 'Физическое развитие',
      percents: 0,
      count: 0,
    },
  ],
};

export const CAPABILITY_MOCK: IDiagnosticResults = {
  chartType: 'doughnut',
  titleText: 'Способности',
  subtitleText: 'Занятие 8',
  status: 'not-passed',
  descriptionText: 'Задание не выдано',
  data: [
    {
      name: 'Конструирование и математика',
      percents: 60,
      count: 18,
    },
    {
      name: 'Решение проблем',
      percents: 50,
      count: 15,
    },
    {
      name: 'Технические способности',
      percents: 36,
      count: 11,
    },
    {
      name: 'Навыки общения',
      percents: 33,
      count: 10,
    },
    {
      name: 'Навыки работы с информацией',
      percents: 17,
      count: 5,
    },
    {
      name: 'Физические навыки',
      percents: 0,
      count: 0,
    },
  ],
};

export const PROFIL_CLASS_MOCK: IDiagnosticResults = {
  chartType: 'bar',
  titleText: 'Выбор профильных классов',
  subtitleText: 'Занятие 8',
  status: 'not-passed',
  descriptionText: 'Задание не выдано',
  data: [
    {
      name: 'Академический (естествено-научный класс)',
      percents: 60,
      count: 18,
    },
    {
      name: 'Гуманитарный класс',
      percents: 46,
      count: 14,
    },
    {
      name: 'Инженерный класс',
      percents: 40,
      count: 12,
    },
    {
      name: 'Кадетский класс',
      percents: 33,
      count: 10,
    },
    {
      name: 'Медицинский класс',
      percents: 23,
      count: 7,
    },
    {
      name: 'Технологический класс',
      percents: 10,
      count: 3,
    },
    {
      name: 'Художественно-эстетический класс',
      percents: 0,
      count: 0,
    },
    {
      name: 'Социально-экономический класс',
      percents: 0,
      count: 0,
    },
  ],
};
