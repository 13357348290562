import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { ClipboardService } from 'ngx-clipboard';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { TEXT_MASK } from 'app/shared/global-constants/constants';
import { ApiAuthService, AppSettingsService, Helper, IExtendedPupil, UserDataHandlerService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { takeUntil } from 'rxjs/operators';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

export interface IChangePhoneInfo {
  pupil: IPupilsInClass;
  newPhone: string;
  isFree: boolean;
}

@Component({
  selector: 'prf-pupils-table',
  templateUrl: './pupils-table.component.html',
  styleUrls: ['./pupils-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PupilsTableComponent extends UnsubscribeComponent {
  public form: UntypedFormGroup;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public phoneMask = TEXT_MASK.newPhoneMask;
  public editPhone = new Map<number, boolean>();
  public currentIndex: number;
  public pupilsInClass: IPupilsInClass[];
  public currentPhone: string;
  public accessEditNumber: boolean = false;
  private notFreePhone: string = '';
  private newPhone: string = '';
  private changedPupil: IPupilsInClass;
  public declOfNum = Helper.declOfNum;
  public _successChanges = new Map<number, boolean>();
  public dictionary: DictionaryType;
  public isKruzhkiVariant: boolean = false;
  public userRestriction: boolean = false;

  @Input() public isMOStend: boolean = false;

  @Output() showResetPassword = new EventEmitter<IPupilsInClass>();
  @Output() successChangesChange = new EventEmitter<boolean>();
  @Output() undoEditingChange = new EventEmitter<boolean>();
  @Output() continueEditingChange = new EventEmitter<boolean>();
  @Output() showChangePhone = new EventEmitter<IChangePhoneInfo>();

  private static setInvitationText(pupil: IExtendedPupil): string {
    const loginPath: string = AppSettingsService.settings.copyLinkLogin;
    const registerPath: string = location.origin + '/code-registration';
    if (pupil.isActivated) {
      return (
        `${pupil.lastName} ${pupil.firstName},\n` +
        `добро пожаловать в Профилум!\n` +
        `1. перейди по ссылке: ${loginPath}\n` +
        `2. введи свой логин: ${pupil.email}\n` +
        `3. введи код: ${pupil.registrationCode}`
      );
    }
    return (
      `${pupil.lastName} ${pupil.firstName},\n` +
      `добро пожаловать в Профилум!\n` +
      `1. перейди по ссылке: ${registerPath}\n` +
      `2. введи свой e-mail\n` +
      `3. введи код: ${pupil.registrationCode}`
    );
  }

  @Input()
  public set successChanges(successChanges: boolean) {
    this._successChanges[this.currentIndex] = successChanges ?? false;
    if (this.pupilsInClass && successChanges) {
      this.pupilsInClass.forEach(pupil => {
        if (pupil.userId === this.changedPupil.userId) {
          pupil.phoneNumber = this.newPhone.replace(/\D/g, '');
          pupil.phoneNumberMask = Helper.setMaskPhoneNumber(pupil.phoneNumber);
          return;
        }
      });
    }
    this.closeChangePhoneInput(this.currentIndex);
  }

  @Input()
  public set undoEditing(undoEditing: boolean) {
    this.editPhone[this.currentIndex] = false;
    this.undoEditingChange.emit(false);
  }

  @Input()
  public set continueEditing(continueEditing: boolean) {
    this.timer = setTimeout(() => {
      window.document.getElementById('phone')?.focus();
    }, 0);
    this.notFreePhone = this.f.phone.value?.replace(/\D/g, '');
    this.continueEditingChange.emit(false);
  }

  @Input() set pupils(pupils: IExtendedPupil[]) {
    if (Array.isArray(pupils)) {
      this.pupilsInClass = pupils.map(pupil => {
        const fullName = `${pupil.lastName} ${pupil.firstName}`;
        const email = pupil.isActivated && pupil.email ? pupil.email : '';
        const phoneNumberMask = pupil.phoneNumber ? Helper.setMaskPhoneNumber(pupil.phoneNumber) : null;
        return {
          ...pupil,
          fullNameText: fullName.length > 30 ? fullName.substring(0, 27) + '...' : fullName,
          loginText: email.length > 34 ? email.substring(0, 31) + '...' : email,
          invitationText: PupilsTableComponent.setInvitationText(pupil),
          phoneNumberMask: phoneNumberMask,
        };
      });
      this.pupilsInClass.sort((a: IPupilsInClass, b: IPupilsInClass) => (a.fullNameText > b.fullNameText ? 1 : -1));
      this.changeDetectorRef.detectChanges();
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private clipboardService: ClipboardService,
    private fb: UntypedFormBuilder,
    private rootScopeService: RootScopeService,
    private apiAuthService: ApiAuthService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
    this.userRestriction = userDataHandlerService.isUserRestricted();
    this.dictionary = this.rootScopeService.getDictionary();
    this.form = this.fb.group({
      phone: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.phoneRegExp)]),
    });
    this.accessEditNumber = AppSettingsService.settings.accessEditNumber;
  }

  public showResetPasswordPopup(pupil: IPupilsInClass): void {
    this.showResetPassword.emit(pupil);
  }

  public showChangePhonePopup(pupil: IPupilsInClass): void {
    this.newPhone = this.f.phone.value;
    this.changedPupil = pupil;
    this.apiAuthService
      .checkPhoneAvailability(this.newPhone?.replace(/\D/g, ''))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (!response.free) {
          this.showChangePhone.emit({ pupil: pupil, newPhone: this.newPhone, isFree: false });
        } else {
          this.showChangePhone.emit({ pupil: pupil, newPhone: this.newPhone, isFree: true });
        }
      });
  }

  public showChangePhoneInput(index: number, pupil: IPupilsInClass): void {
    this.editPhone.clear();
    this.editPhone[this.currentIndex] = false;
    this.editPhone[index] = true;
    this.currentPhone = pupil.phoneNumber;
    this.form.setValue({ phone: '+' + pupil.phoneNumber });
    this.currentIndex = index;
    this.checkPhone();
    this.timer = setTimeout(() => {
      window.document.getElementById('phone')?.focus();
    }, 0);
  }

  public closeChangePhoneInput(index: number): void {
    this.editPhone[index] = false;
    this.timer = setTimeout(() => {
      this._successChanges[index] = false;
      this.successChangesChange.emit(false);
    }, 3000);
  }

  public focusOut(index: number): void {
    if (this.f.phone.value) {
      this.checkPhone();
    } else {
      this.closeChangePhoneInput(index);
    }
  }

  get f() {
    return this.form.controls;
  }

  public checkPhone(): void {
    const phone: string = this.f.phone.value?.replace(/\D/g, '');

    if (!Helper.isValidPhoneNumber(phone)) {
      this.f.phone.setErrors({ pattern: true });
    }
  }

  get saveForbidden() {
    const phone: string = this.f.phone.value?.replace(/\D/g, '');
    return (
      this.validField(this.f.phone) ||
      this.currentPhone === phone ||
      this.currentPhone.slice(1, 11) === phone ||
      this.notFreePhone === phone
    );
  }

  public validField(field: AbstractControl) {
    return field.touched && field.errors;
  }
}
