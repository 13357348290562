<div class="search">
  <form #f="ngForm" [formGroup]="searchField">
    <div class="search-block">
      <img class="search-test" src="./profilum-assets/images/common/search-prof.svg" alt="" />
      <input
        type="search"
        #search
        [formControl]="searchField"
        class="search-professions w-input"
        maxlength="256"
        placeholder="{{ 'SHARED.SEARCH' | translate }}"
        name="search"
        required
      />
      <a (click)="clearSearch()" *ngIf="value" class="search-mobile-clear"></a>
    </div>
  </form>
</div>
