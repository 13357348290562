import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from 'app/shared/pipes/filter-schools.pipe';
import { takeUntil } from 'rxjs/operators';
import { RegistrationBase } from '../../../../shared/auth-classes/registration.base';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import {
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  Helper,
  ISchoolPortalUserData,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
} from '@profilum-library';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-update-pupil-mosobl',
  templateUrl: './update-pupil-mosobl.component.html',
  styleUrls: ['./update-pupil-mosobl.component.scss'],
})
export class UpdatePupilMosOblComponent extends RegistrationBase<B2gSaasService> {
  @Input() SPUserData: ISchoolPortalUserData;
  public personalTerms: boolean = true;

  constructor(
    registrationService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    b2gSaasService: B2gSaasService,
    apiLocationsService: ApiLocationsService,
    utilsService: UtilsService,
    userDataHandlerService: UserDataHandlerService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      registrationService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
      utilsService,
    );
  }

  async ngOnInit() {
    this.defaultRegion = AppSettingsService.settings.regionId;
    await super.ngOnInit();
    this.setUserDataValues();
  }

  public updateSchoolClass() {
    this.submitted = true;
    if (this.form.valid) {
      const school = this.schools.find(school => school.id === this.SPUserData.schoolId);

      let classLetter: string;
      let classNumber: number;

      if (this.f.schoolClass.value == null) {
        classNumber = this.f.schoolClassNumber.value.name;
        classLetter = this.f.schoolClassLetter.value.name;
      } else {
        const schoolClassInfo = this.f.schoolClass.value ? Helper.parseSchoolClassName(this.f.schoolClass.value.name) : null;

        if (schoolClassInfo) {
          classLetter = schoolClassInfo.classLetter;
          classNumber = schoolClassInfo.classNumber;
        }
      }

      this.registrationService
        .softSchoolClassChange(this.SPUserData.userId, school.id, classNumber, classLetter)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
            return this.router.navigate(['/pupil-profile']);
          } else {
            this.utilsService.openSnackBar(ServerErrorMessage, 'error');
            this.failWaiting();
          }
        });
    } else {
      this.failWaiting();
    }
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.updateSchoolClass();
  }

  // SchoolPortal Methods
  private setUserDataValues(): void {
    const isValidDate = this.SPUserData.birthday && this.SPUserData.birthday !== '0001-01-01T00:00:00'; // в таком формте приходит отсутствие даты
    const birthday = isValidDate ? DateHelper.toDayJs(this.SPUserData.birthday).format('DD/MM/YYYY') : null;

    const school = this.schools.find(school => school.id === this.SPUserData.schoolId);
    const city = this.cities.find(city => city.name === this.SPUserData.city);

    // запрос классов по школе
    if (school && school.id) {
      this.getSchoolClassBySchool(school.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassLetter.reset();
        });
    }

    this.form = this.fb.group({
      region: new UntypedFormControl(this.regionsView.find(r => r.data.id === this.defaultRegion)),
      lastName: new UntypedFormControl({ value: this.SPUserData.lastName, disabled: true }, [Validators.required]),
      firstName: new UntypedFormControl({ value: this.SPUserData.firstName, disabled: true }, [Validators.required]),
      middleName: new UntypedFormControl({ value: this.SPUserData.middleName, disabled: true }),
      date: new UntypedFormControl({ value: isValidDate ? birthday : null, disabled: isValidDate }, [
        Validators.required,
        Validators.pattern(this.dateRegExp),
      ]),
      email: new UntypedFormControl({ value: this.SPUserData.email, disabled: this.SPUserData.email ? true : false }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      city: new UntypedFormControl({
        value: city ? { name: city.name, data: city } : null,
        disabled: city ? true : false,
      }),
      school: new UntypedFormControl(
        {
          value: school ? { name: school.viewValue, data: school } : null,
          disabled: school ? true : false,
        },
        [Validators.required],
      ),
      schoolClass: new UntypedFormControl(null),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl(
        {
          value: this.SPUserData.gender && ['M', 'F'].indexOf(this.SPUserData.gender) > -1 ? this.SPUserData.gender : null,
          disabled: this.SPUserData.gender && ['M', 'F'].indexOf(this.SPUserData.gender) > -1 ? true : false,
        },
        [Validators.required],
      ),
    });

    // нужно, чтобы подтянулись школы по городу
    this.f.city.value && !this.f.school.value ? this.onCityChange() : null;

    // вызываем проверку возраста
    this.checkFormatDate(this.f.date.value);
  }

  get isAccessAllowed() {
    return this.form.valid && this.isClassSelected;
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }
}
