export const ValuesResultsTexts = [
  {
    name: 'Ответственность',
    shortDescription: 'Готовность отвечать за себя и свои проекты, включая их успех или неудачи',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/responsibility/responsibility01.svg',
      './profilum-assets/images/results/values-results/cards-images/responsibility/responsibility02.svg',
      './profilum-assets/images/results/values-results/cards-images/responsibility/responsibility03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Быть ответственным — значит сдерживать обещания, осознавать последствия своих решений и соблюдать сроки договоренностей. Ответственный человек контролирует свою жизнь: он четко понимает, как повлиять на ход событий, а также может выбрать, как ему поступить.',
        aboutSelf:
          'Ты ценишь ответственный подход к делам и во многих ситуациях берешь на себя ответственность за происходящее. Ты самостоятельно решаешь, что и как делать, а не полагаешься на внешние события и факторы. Ответственный подход к делу помогает тебе становиться лучше: ты учишься действовать эффективнее и стремишься к высокому качеству результата.',
        payAttention:
          'Тебе придется сталкиваться не только с положительными, но и с негативными событиями. Умение брать на себя ответственность за ошибки — это непростой навык. Слишком высокий уровень ответственности может привести к перенапряжению, тревоге и снижению уровня мотивации.',
        tooltipText:
          'Важно напоминать себе, что ты не отвечаешь за все происходящее вокруг: иногда можно получить четверку, а не пятерку. А еще, важен не только результат, но и то, как ты себя чувствуешь, пока движешься в сторону его достижения.',
      },
      middleValue: {
        about:
          'Быть ответственным — значит сдерживать обещания, осознавать последствия своих решений и соблюдать сроки договоренностей. Ответственный человек контролирует свою жизнь: он четко понимает, как повлиять на ход событий, а также может выбрать, как ему поступить.',
        aboutSelf:
          'Ты считаешь, что иногда важно брать ответственность на себя, но далеко не всегда. Иногда ты полагаешься на других, надеясь, что они возьмут ответственность за ситуацию. Однако, когда ты практикуешь ответственный подход, ты чувствуешь самостоятельность, прилив сил и рост мотивации. Ответственный подход к делу побуждает тебя становиться лучше: ты учишься действовать эффективнее и стремишься к высокому качеству результата.',
        payAttention:
          'Тебе придется сталкиваться не только с положительными, но и с негативными событиями. Умение брать на себя ответственность за ошибки — это непростой навык, которому можно научиться. ',
        tooltipText:
          'Когда ты берешь на себя большую ответственность, не забывай напоминать себе, что ты не отвечаешь за все происходящее вокруг. Иногда можно получить четверку, а не пятерку. А еще, важен не только результат, но и то, как ты себя чувствуешь, пока движешься в сторону его достижения. Если тебе не хочется брать на себя ответственность, подумай о том, сделает ли ответственный подход тебя лучше и какие выгоды это принесет.',
      },
      lowValue: {
        about:
          'Быть ответственным — значит сдерживать обещания, осознавать последствия своих решений и соблюдать сроки договоренностей. Ответственный человек контролирует свою жизнь: он четко понимает, как повлиять на ход событий, а также может выбрать, как ему поступить.',
        aboutSelf:
          'Ответственность для тебя не очень важна, поэтому ты не любишь брать ее на себя и часто полагаешься на других. У такой позиции могут быть и плюсы: так ты не будешь ни за что отвечать, тебя не сделают “крайним”, и не нужно отвечать за неудачи перед другими.',
        payAttention:
          'Стоит помнить, что у ответственности есть и положительные стороны: ответственные люди — это лидеры, они чувствуют прилив сил, рост мотивации и ведут себя самостоятельно. Стремление к ответственному подходу побуждает нас становиться лучше: действовать эффективнее и стремиться к высокому качеству результата.',
        tooltipText:
          'Рекомендуем тебе подумать, почему ты избегаешь ответственности? Что именно тебя в этом настораживает? Чтобы справиться со страхом перед ответственностью, рекомендуем тебе браться за дела, которые тебе по силам — те, в которых заранее можно просчитать, сколько времени и ресурсов тебе потребуется. Если ты чувствуешь уверенность на 70-80% в успешном исходе дела, можно начинать. Но перед этим “подстели себе соломки”: продумай, что можно будет сделать, если что-то пойдет не так? И к кому можно обратиться за помощью?',
      },
    },
  },
  {
    name: 'Карьерный рост',
    shortDescription: 'Стремление к продвижению по карьерной лестнице и построению успешной карьеры',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/career/career01.svg',
      './profilum-assets/images/results/values-results/cards-images/career/career02.svg',
      './profilum-assets/images/results/values-results/cards-images/career/career03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Если ты ценишь карьерный рост, значит, стремишься развивать свое дело или развиваться на своей позиции. Для этого нужно постоянно овладевать новыми навыками и получать новый опыт.',
        aboutSelf:
          'Тебе важно чувствовать себя успешным в том, чем ты планируешь заниматься. Тебе не хотелось бы всю жизнь проработать на одном и том же месте, постоянно занимаясь одним и тем же. Тебя привлекает возможность построить собственную карьеру, добиться значительных высот. При переходе на новый уровень твои полномочия будут расширяться.',
        payAttention:
          'Продвижение по карьерной лестнице предполагает рост ответственности: скорее всего, тебе придется отвечать не только за себя, но и за других людей.',
        tooltipText:
          'Чтобы начать свой карьерный путь уже сейчас, ты можешь подобрать и пройти стажировку в компании твоей мечты, можешь узнавать больше о профессиях, которые тебя интересуют, слушая подкасты и читая статьи. Также ты можешь проходить курсы, прокачивая как soft skills (работа в команде, тайм-менеджмент, креативность), так и hard skills (английский язык или другие предметы, которые тебе нравятся).',
      },
      middleValue: {
        about:
          'Если ты ценишь карьерный рост, значит, стремишься развивать свое дело или развиваться на своей позиции. Для этого нужно постоянно овладевать новыми навыками и получать новый опыт.',
        aboutSelf:
          'Карьерный рост для тебя важен, но не настолько, чтобы пожертвовать ради него чем-то ценным или своим комфортом. В целом, тебе важно чувствовать себя успешным в том, чем ты планируешь заниматься. Тебе не хотелось бы всю жизнь проработать на одном и том же месте, постоянно занимаясь одним и тем же. Ты планируешь продвигаться вверх по карьерной лестнице, но в то же время соблюдать work-life balance (баланс между работой и личной жизнью).',
        payAttention:
          'Подъем по карьерной лестнице предполагает рост ответственности: скорее всего тебе придется отвечать не только за себя, но и за других людей.',
        tooltipText:
          'Чтобы начать свой карьерный путь уже сейчас, ты можешь подобрать и пройти стажировку в компании твоей мечты, можешь узнавать больше о профессиях, которые тебя интересуют, слушая подкасты и читая статьи. Также ты можешь проходить курсы, прокачивая как soft skills (работа в команде, тайм-менеджмент, креативность), так и hard skills (английский язык или другие предметы, которые тебе нравятся).',
      },
      lowValue: {
        about:
          'Если ты ценишь карьерный рост, значит, стремишься развивать свое дело или развиваться на своей позиции. Для этого нужно постоянно овладевать новыми навыками и получать новый опыт.',
        aboutSelf:
          'Карьерный рост для тебя не так уж важен. Тебя не привлекает возможность продвижения по карьерной лестнице или достижение значительных высот. В работе ты в первую очередь ценишь комфорт и постоянство. Если ты найдешь место по душе, то с радостью останешься на этой позиции на долгие годы.',
        payAttention:
          'Продвижение по карьерной лестнице развивает наши навыки, дает новый опыт и открывает новые горизонты. Да, иногда ради карьеры люди жертвуют свободным временем, но это не обязательное условие для карьерного роста. Ты можешь строить карьеру и соблюдать work-life balance (баланс между работой и личной жизнью).',
        tooltipText:
          'Рекомендуем тебе поставить перед собой одну масштабную долгосрочную цель, которая для тебя по-настоящему важна и мотивирует. Как понять, что эта цель масштабна? Подумай о своей мечте, а сразу после этого вспомни какую-нибудь небольшую цель. Ты сразу почувствуешь, насколько они различаются силой заряда. Маленькими и реалистичными пусть будут твои ежедневные шаги. А цель — огромной и интересной до мурашек. Ведь мурашки означают, что цель заряжена именно для тебя и заряда хватит надолго.',
      },
    },
  },
  {
    name: 'Лидерство',
    shortDescription: 'Умение получать поддержку со стороны единомышленников для достижения цели',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/leadership/leadership01.svg',
      './profilum-assets/images/results/values-results/cards-images/leadership/leadership02.svg',
      './profilum-assets/images/results/values-results/cards-images/leadership/leadership03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Лидер — это человек, имеющий влияние и ведущий группу людей за собой. Лидер, которого уважают и признают, чаще всего заботится не только о своем благе, но и о потребностях членов команды. Поэтому, чтобы стать лидером, нужно завоевать доверие людей.',
        aboutSelf:
          'Ты ценишь возможность управлять людьми, вести их за собой, быть лидером. А еще получаешь удовольствие от тех моментов, когда у тебя получается руководить группой людей. Как лидер, ты умеешь принимать сложные решения и нести за них ответственность. Скорее всего, в коллективе ты проявляешь себя как активный, инициативный человек, который пользуется уважением остальных.',
        payAttention:
          'Чтобы сохранить роль лидера, нужно нести ответственность за промахи и уметь признавать ошибки. Кроме того, нужно постоянно подтверждать свой статус, чтобы в один момент не утратить его.',
        tooltipText:
          'Когда будешь думать о будущей карьере, рассмотри варианты, связанные с управлением людьми. Это твой конек, используй его!',
      },
      middleValue: {
        about:
          'Лидер — это человек, имеющий влияние и ведущий группу людей за собой. Лидер, которого уважают и признают, чаще всего заботится не только о своем благе, но и о потребностях членов команды. Чтобы стать лидером, нужно завоевать доверие людей.',
        aboutSelf:
          'В части ситуаций для тебя важно вести себя как лидер: руководить группой людей, принимать сложные решения и нести за них ответственность. И ты получаешь от этого удовольствие. Однако иногда ты избегаешь лидерской роли: тебе спокойнее плыть по течению и не напрягаться лишний раз. Ты можешь быть отличным лидером в разных ситуациях, но далеко не всегда тебе это нужно.',
        payAttention:
          ' Чтобы сохранить роль лидера, нужно нести ответственность за промахи и уметь признавать ошибки. Кроме того, нужно постоянно подтверждать свой статус, чтобы в один момент не утратить его.',
        tooltipText:
          'Если ты хочешь привлекать еще больше людей на свою сторону, учись общаться, отстаивать свою позицию, развивай способность концентрироваться на главной цели, глубоко и быстро анализировать ситуацию. В общем, ты можешь выбрать курс развития soft skills (мягких навыков) и постепенно прокачивать их.',
      },
      lowValue: {
        about:
          'Лидер — это человек, имеющий влияние и ведущий группу людей за собой. Лидер, которого уважают и признают, чаще всего заботится не только о своем благе, но и о потребностях членов команды. Чтобы стать лидером, нужно завоевать доверие людей.',
        aboutSelf:
          'Лидерство для тебя не так ценно. Ты избегаешь ситуаций, в которых нужно управлять людьми, вести их за собой, быть лидером. Тебе не нравится брать на себя ответственность за всю команду и за дело в целом. Тебе комфортнее, когда управление ситуацией берет на себя кто-то другой.',
        payAttention:
          'У тебя могут появиться масштабные цели и мечты, для реализации которых тебе потребуется помощь других. А чтобы мобилизовать других, мотивировать их работать на благо своего проекта, нужно проявить себя лидером, сплотить их вокруг себя.',
        tooltipText:
          'Когда будешь думать о будущей карьере, рассматривай варианты, в которых ты сможешь работать независимо ни от кого и никем не управляя, либо, если ты нормально относишься к ведомой позиции, выбирай такие места, где тобой будут руководить. Это не плохо, ведь все люди разные и для каждого комфортен свой вариант.',
      },
    },
  },
  {
    name: 'Социальный статус',
    shortDescription: 'Престиж, узнаваемость и респектабельность',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/social_status/social_status01.svg',
      './profilum-assets/images/results/values-results/cards-images/social_status/social_status02.svg',
      './profilum-assets/images/results/values-results/cards-images/social_status/social_status03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Социальный статус — это положение, которое человек занимает в обществе. Чаще всего социальный статус определяется по таким признакам, как материальное положение и управленческие возможности. То есть, чем больше у человека денег и власти, тем выше он по социальному статусу , и тем выше его престиж в обществе. Социальный статус определяет стиль, образ жизни, круг знакомых, манеру поведения.',
        aboutSelf:
          'У тебя много амбиций и мотивации к тому, чтобы повысить свой социальный статус. Тебе бы хотелось встать на ступеньку выше, приблизившись к тем, на кого ты ориентируешься. Тебе важно производить впечатление, важно, чтобы тебя замечали. Также тебе бы хотелось войти в круг знаменитых и влиятельных людей.',
        payAttention:
          'В погоне за социальным статусом можно не заметить что-то простое, но важное. Например, можно упустить шанс познакомиться и подружиться с интересным человеком, социальный статус которого не соответствует твоему.',
        tooltipText:
          'Социальный статус можно укрепить не только за счет денег и власти. Социальные навыки, интеллект, уважение к людям, уверенность в себе, мастерство в каком-то деле — вот те качества, которые могут поднять тебя на новую высоту. Главное — их можно тренировать, и это совершенно бесплатно.',
      },
      middleValue: {
        about:
          'Социальный статус — это положение, которое человек занимает в обществе. Чаще всего социальный статус определяется по таким признакам, как материальное положение и управленческие возможности. То есть, чем больше у человека денег и власти, тем выше он по социальному статусу , и тем выше его престиж в обществе. Социальный статус определяет стиль, образ жизни, круг знакомых, манеру поведения.',
        aboutSelf:
          'Социальный статус является для тебя ценностью, но не во всех ситуациях. В целом, у тебя есть амбиции и мотивация к тому, чтобы повысить свой социальный статус. Иногда тебе бы хотелось встать на ступеньку выше, приблизившись к тем, на кого ты ориентируешься. Бывает, что тебе важно внимание людей, важно производить на них впечатление.',
        payAttention:
          'В погоне за социальным статусом можно не заметить что-то простое, но важное. Например, можно упустить шанс познакомиться и подружиться с интересным человеком, социальный статус которого не соответствует твоему.',
        tooltipText:
          'Социальный статус можно укрепить не только за счет денег и власти. Социальные навыки, интеллект, уважение к людям, уверенность в себе, мастерство в каком-то деле — вот те качества, которые могут поднять тебя на новую высоту. Главное — их можно тренировать, и это совершенно бесплатно.',
      },
      lowValue: {
        about:
          'Социальный статус — это положение, которое человек занимает в обществе. Чаще всего социальный статус определяется по таким признакам, как материальное положение и управленческие возможности. То есть, чем больше у человека денег и власти, тем выше он по социальному статусу , и тем выше его престиж в обществе. Социальный статус определяет стиль, образ жизни, круг знакомых, манеру поведения.',
        aboutSelf:
          'Социальный статус для тебя совершенно не важен. Ты не придаешь большого значения одежде из последних коллекций, новомодным гаджетам, ультрамодным кафе. Для тебя не важно производить впечатление, и нет потребности, чтобы тебя все замечали.',
        payAttention:
          'Узнаваемость может сыграть тебе на руку, если ты захочешь стать кем-то, кто должен быть у всех на виду, например, блогером или популярным экспертом.',
        tooltipText:
          'Если ты чувствуешь, что тебя удовлетворяет твой текущий социальный статус, это здорово! Но ты всегда можешь попробовать его повысить с помощью прокачки социальных навыков, интеллекта, уверенности в себе, мастерства в каком-то деле.',
      },
    },
  },
  {
    name: 'Деньги',
    shortDescription: 'Приоритет денег несмотря ни на что',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/money/money01.svg',
      './profilum-assets/images/results/values-results/cards-images/money/money02.svg',
      './profilum-assets/images/results/values-results/cards-images/money/money03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Деньги — важный компонент жизненного комфорта и благополучия. Наш тест спроектирован таким образом, чтобы понять, насколько деньги важны для тебя не сами по себе, а в сравнении с другими ценностями.',
        aboutSelf:
          'Для тебя ценность денег очевидна, можно сказать, что это один из основных приоритетов твоей жизни. Ты ценишь богатство, обеспеченность, высокие доходы. Наверное, ты размышляешь, как приумножить свой капитал, и это отличный двигатель развития.',
        payAttention:
          'В погоне за деньгами можно упустить что-то важное, например, дружбу или романтические отношения, либо пожертвовать чем-то значимым, например, моральными ценностями.',
        tooltipText:
          'Приблизиться к богатству тебе поможет финансовая грамотность. Это очень полезный навык, овладев которым, ты будешь знать, как правильно копить деньги, во что их инвестировать и как правильно тратить.',
      },
      middleValue: {
        about:
          'Деньги — важный компонент жизненного комфорта и благополучия. Наш тест спроектирован таким образом, чтобы понять, насколько деньги важны для тебя не сами по себе, а в сравнении с другими ценностями.',
        aboutSelf:
          'В некоторые моменты ты ощущаешь, что деньги для тебя очень важны. Тогда приоритет денег выходит на первый план и тебе хочется добиться богатства, обеспеченности и высоких доходов. Но бывает и так, что ты думаешь, что есть нечто гораздо более ценное.',
        payAttention:
          'В погоне за деньгами можно упустить что-то важное, например, дружбу или романтические отношения, либо пожертвовать чем-то значимым, например, моральными ценностями.',
        tooltipText:
          'Приблизиться к богатству тебе поможет финансовая грамотность. Это очень полезный навык, овладев которым ты будешь знать, как правильно копить деньги, во что их инвестировать и как правильно тратить.',
      },
      lowValue: {
        about:
          'Деньги — важный компонент жизненного комфорта и благополучия. Наш тест спроектирован таким образом, чтобы понять, насколько деньги важны для тебя не сами по себе, а в сравнении с другими ценностями.',
        aboutSelf:
          'Деньги не являются для тебя большой ценностью, если сопоставлять их с другими важными аспектами жизни. Может быть, ты и ценишь богатство, обеспеченность, высокие доходы сами по себе, но у тебя нет готовности жертвовать ради них дружбой, романтическими отношениями или моральными ценностями.',
        payAttention:
          'Вполне возможно, что в твоей жизни будут возникать моменты выбора: деньги или собственный комфорт, деньги или время, которое можно потратить на общение с важным для тебя человеком.',
        tooltipText:
          'Финансовая грамотность поможет тебе правильно распоряжаться имеющимися деньгами и избежать возможных рисков. Пройдя курс финансовой грамотности, ты узнаешь, как инвестировать деньги, какую сумму ежемесячно можно тратить, а какую — откладывать.',
      },
    },
  },
  {
    name: 'Саморазвитие',
    shortDescription: 'Развитие своих сил и способностей, раскрытие потенциала',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/self-development/self-development01.svg',
      './profilum-assets/images/results/values-results/cards-images/self-development/self-development02.svg',
      './profilum-assets/images/results/values-results/cards-images/self-development/self-development03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Саморазвитие — это процесс работы над собой для достижения успеха в разных сферах жизни. Психологи выделяют 2 типа мышления: 1 — мышление с установкой на рост и развитие, и 2 — фиксированное мышление, когда человек уверен в том, что все способности являются врожденными и их невозможно развивать. Именно от этой установки зависит желание учиться новому.',
        aboutSelf:
          'Ты ценишь саморазвитие в себе и других. Ты проявляешь проактивность и постоянно движешься вперед к своей цели или мечте. Ты берешь на себя ответственность за достижение цели и не перекладываешь вину за неудачи на окружающих или обстоятельства. Тебе важно максимально реализовать свой потенциал, поэтому ты не боишься сложных задач и не пасуешь перед вызовами. Так держать!',
        payAttention:
          'Саморазвитие — важная ценность, но и у нее есть минусы. Например, ставя перед собой только те задачи, которые приводят к личностному росту, можно забыть об отдыхе и приятном “ничегонеделании”, когда наш мозг по-настоящему отдыхает. Стоит позволить себе такой отдых и добавить его в свое ежедневное расписание.',
        tooltipText:
          'Когда у тебя появляется желание научиться чему-то новому, не откладывай его в долгий ящик. Начинай практиковать навык как можно быстрее, тогда твоя мотивация не успеет истощиться. Также ты можешь использовать чек-листы, которые помогут тебе быть более дисциплинированным и получать дофамин каждый раз, когда ты ставишь галочку напротив выполненного дела!',
      },
      middleValue: {
        about:
          'Саморазвитие — это процесс работы над собой для достижения успеха в разных сферах жизни. Психологи выделяют 2 типа мышления: 1 — мышление с установкой на рост и развитие, и 2 — фиксированное мышление, когда человек уверен в том, что все способности являются врожденными и их невозможно развивать. Именно от этой установки зависит желание учиться новому.',
        aboutSelf:
          'В целом, ты ценишь саморазвитие в себе и других, но не всегда у тебя есть готовность прикладывать усилия для этого. В тех случаях, когда у тебя появляется желание развиваться, ты проявляешь проактивность и движешься вперед к своей цели или мечте, не боясь брать на себя ответственность и не отступая перед сложными задачами.',
        payAttention:
          'У тебя бывают моменты, когда развиваться не хочется и это становится не таким важным. Такие периоды нормальны, в моменты ничегонеделания наш мозг по-настоящему отдыхает.',
        tooltipText:
          'Когда у тебя появляется желание научиться чему-то новому, не откладывай его в долгий ящик. Начинай практиковать навык как можно быстрее, тогда твоя мотивация не успеет истощиться. Также ты можешь использовать чек-листы, которые помогут тебе быть более дисциплинированным и получать дофамин каждый раз, когда ты ставишь галочку напротив выполненного дела!',
      },
      lowValue: {
        about:
          'Саморазвитие — это процесс работы над собой для достижения успеха в разных сферах жизни. Психологи выделяют 2 типа мышления: 1 — мышление с установкой на рост и развитие, и 2 — фиксированное мышление, когда человек уверен в том, что все способности являются врожденными и их невозможно развивать. Именно от этой установки зависит желание учиться новому.',
        aboutSelf:
          'Для тебя саморазвитие не обладает особой ценностью. Ты не испытываешь желания развиваться. Тебя вполне устраивает то, что ты имеешь сейчас, и большего тебе не надо.',
        payAttention:
          'Возможно, у тебя есть установка, что твои способности невозможно развить, или что ты вообще не обладаешь какими-то особенными способностями. Но это не так. Каждый человек обладает уникальным набором талантов, важно их открыть и развивать.',
        tooltipText:
          'Рекомендуем тебе подумать, почему развиваться для тебя не так важно? Саморазвитие — важная часть жизни, которая помогает нам становиться лучше. Такое состояние может быть вызвано разными причинами: начиная с того, что ты стоишь на одном месте и тебе неинтересно происходящее, так как ты все уже знаешь и умеешь, и заканчивая депрессией.',
      },
    },
  },
  {
    name: 'Справедливость',
    shortDescription: 'Равные возможности для всех',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/justice/justice01.svg',
      './profilum-assets/images/results/values-results/cards-images/justice/justice02.svg',
      './profilum-assets/images/results/values-results/cards-images/justice/justice03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Единого понимания справедливости не существует, разные группы людей считают справедливым нечто свое. Но есть общечеловеческое представление о морали. И если бы каждый ей следовал, то мир стал бы лучшим местом для всех.',
        aboutSelf:
          'Ты высоко ценишь справедливость, честность и порядочность в себе и других людях. Ты знаешь, как правильно поступить в конкретной ситуации, и стараешься следовать этому. Еще ты стараешься восстановить справедливость там, где она была нарушена. Ты настоящий супергерой в этом мире!',
        payAttention:
          'Следует иметь в виду, что существует такое искажение в нашем мышлении, как вера в справедливый мир. Люди, которые верят в справедливый мир, считают, что хорошее случается с хорошими людьми, а плохое — с плохими. Поэтому, если с хорошими людьми происходит что-то плохое, делается вывод, что они были не такими уж хорошими. Это опасное заблуждение, которое стоит отслеживать и не поддаваться ему.',
        tooltipText:
          'Когда ты помогаешь другим или восстанавливаешь справедливость, не забывай о своей безопасности. Также старайся максимально взвешенно и объективно подходить к оценке разных ситуаций — не всегда то, что поначалу кажется несправедливым, на деле оказывается таким.',
      },
      middleValue: {
        about:
          'Единого понимания справедливости не существует, разные группы людей считают справедливым нечто свое. Но есть общечеловеческое представление о морали. И если бы каждый ей следовал, то мир стал бы лучшим местом для всех.',
        aboutSelf:
          'Иногда ты ценишь справедливость, честность и порядочность в себе и других людях. Ты знаешь, как правильно поступить в конкретной ситуации, и стараешься следовать этому, но это не всегда получается. Иногда проще пройти мимо ситуации, которая требует вмешательства.',
        payAttention:
          'Следует иметь в виду, что существует такое искажение в нашем мышлении, как вера в справедливый мир. Люди, которые верят в справедливый мир, считают, что хорошее случается с хорошими людьми, а плохое — с плохими. Поэтому, если с хорошими людьми происходит что-то плохое, делается вывод, что они были не такими уж хорошими. Это опасное заблуждение, которое стоит отслеживать и не поддаваться ему.',
        tooltipText:
          'Когда ты помогаешь другим или восстанавливаешь справедливость, не забывай о своей безопасности. Также старайся максимально взвешенно и объективно подходить к оценке разных ситуаций — не всегда то, что поначалу кажется несправедливым, на деле оказывается таким.',
      },
      lowValue: {
        about:
          'Единого понимания справедливости не существует, разные группы людей считают справедливым нечто свое. Но есть общечеловеческое представление о морали. И если бы каждый ей следовал, то мир стал бы лучшим местом для всех.',
        aboutSelf:
          'Справедливость, честность и порядочность не являются для тебя приоритетами. Скорее всего, ты не будешь вмешиваться в ситуации, если видишь, что происходит что-то несправедливое, потому что, в первую очередь, ты концентрируешься на собственном комфорте.',
        payAttention:
          'Следует иметь в виду, что существует такое искажение в нашем мышлении, как вера в справедливый мир. Люди, которые верят в справедливый мир, считают, что хорошее случается с хорошими людьми, а плохое — с плохими. Поэтому, если с хорошими людьми происходит что-то плохое, делается вывод, что они были не такими уж хорошими. Это опасное заблуждение, которое стоит отслеживать и не поддаваться ему',
        tooltipText:
          'Рекомендуем подумать, почему справедливость стала для тебя не важна? Всегда ли так было или это произошло недавно? Моральные качества необходимы для построения близких и устойчивых отношений и являются основой доверия в дружбе и семье. Поэтому, возможно, тебе стоит уделять больше внимания вопросам справедливости.',
      },
    },
  },
  {
    name: 'Независимость',
    shortDescription: 'Независимость от общественного мнения и отстаивание собственной позиции',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/independence/independence01.svg',
      './profilum-assets/images/results/values-results/cards-images/independence/independence02.svg',
      './profilum-assets/images/results/values-results/cards-images/independence/independence03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Мы независимы от чужого мнения, когда поступаем так, как считаем нужным, а не тогда, когда живем “как положено”, “как правильно”. Человек, который независим от чужого мнения, говорит то, что действительно думает и чувствует, ему не надо носить маску. Его девиз — “быть, а не казаться”.',
        aboutSelf:
          'Ты высоко ценишь независимость от чужого мнения. Твоя осознанность и сосредоточенность на важных для тебя вещах поражают! Кажется, ты знаешь, что будет лучше для тебя в разных ситуациях, поэтому ты в первую очередь прислушиваешься к себе, а не к другим людям. Ты чувствуешь уверенность в себе для принятия самостоятельных решений.',
        payAttention:
          'Если прислушиваться исключительно к себе при принятии решений, можно пропустить дельный совет от другого человека. Ведь мы по своей природе не можем знать всего, поэтому чужое мнение может быть очень ценным.',
        tooltipText:
          'При принятии важных решений обращай внимание на конструктивные мнения и конкретные советы тех людей, которым ты доверяешь.',
      },
      middleValue: {
        about:
          'Мы независимы от чужого мнения, когда поступаем так, как считаем нужным, а не тогда, когда живем “как положено”, “как правильно”. Человек, который независим от чужого мнения, говорит то, что действительно думает и чувствует, ему не надо носить маску. Его девиз — “быть, а не казаться”.',
        aboutSelf:
          'Иногда ты ценишь независимость от чужого мнения. Поэтому в некоторых ситуациях принимаешь решения самостоятельно. Ты знаешь, что будет лучше для тебя, поэтому и прислушиваешься к себе, а не к другим людям. Но иногда тебе не так важно проявлять независимость, тогда ты рассматриваешь советы от других людей и принимаешь решения исходя из них.',
        payAttention:
          'Если прислушиваться исключительно к себе при принятии решений, можно пропустить дельный совет от другого человека. Ведь мы по своей природе не можем знать всего, поэтому чужое мнение может быть очень ценным.',
        tooltipText:
          'При принятии важных решений обращай внимание на конструктивные мнения и конкретные советы тех людей, которым ты доверяешь, но и прислушивайся к себе, ведь именно тебе, а не другим людям, придётся столкнуться с последствиями принятых решений.',
      },
      lowValue: {
        about:
          'Мы независимы от чужого мнения, когда поступаем так, как считаем нужным, а не тогда, когда живем “как положено”, “как правильно”. Человек, который независим от чужого мнения, говорит то, что действительно думает и чувствует, ему не надо носить маску. Его девиз — “быть, а не казаться”.',
        aboutSelf:
          'Тебе не важна независимость от чужого мнения. Принимая решения, ты часто опираешься на советы других людей. В целом, для тебя очень важно мнение других людей о тебе. Тебе бы хотелось нравиться окружающим, поэтому ты часто подстраиваешься под них.',
        payAttention:
          'Принимая решения, которые тебе посоветовали другие люди, помни, что с последствиями этих решений столкнешься именно ты. Поэтому важно иногда проявлять независимость и принимать те решения, которые кажутся верными именно тебе.',
        tooltipText:
          'Когда ты слишком большое внимание уделяешь тому, как выглядишь со стороны, можно пропустить важные моменты своей жизни из-за чрезмерной тревоги. Попробуй больше прислушиваться к себе, своим ощущениям. Они очень ценные и дают не менее полезную обратную связь о произошедшем, чем другие люди.',
      },
    },
  },
  {
    name: 'Успех',
    shortDescription: 'Стремление быть экспертом и получить публичный статус',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/success/success01.svg',
      './profilum-assets/images/results/values-results/cards-images/success/success02.svg',
      './profilum-assets/images/results/values-results/cards-images/success/success03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Каждому человеку время от времени важно почувствовать себя успешным в том, чем он занимается. Успех, признание нас экспертами повышает нашу самооценку и дает мотивацию двигаться дальше.',
        aboutSelf:
          'Ты очень ценишь переживание успеха! Кажется, твоя самооценка в полном порядке: ты чувствуешь уверенность в своих силах, и у тебя есть готовность предстать перед широкой публикой в качестве эксперта в своем любимом деле. Переживание успеха необходимо тебе как воздух: так ты чувствуешь радость и полноту жизни, а препятствия, связанные с публичностью, тебя не пугают.',
        payAttention:
          'Неудача — обратная сторона успеха. Чем больше мы движемся в сторону того, чтобы добиться исполнения своих желаний, тем больше вероятность встретиться с препятствием.',
        tooltipText:
          'Не бойся ошибок, ведь именно они помогают нам понять, что для нас действительно важно и как мы можем скорректировать свой путь. Это своего рода обратная связь, которой не стоит пренебрегать.',
      },
      middleValue: {
        about:
          'Каждому человеку время от времени важно почувствовать себя успешным в том, чем он занимается. Успех, признание нас экспертами повышает нашу самооценку и дает мотивацию двигаться дальше.',
        aboutSelf:
          'В целом, для тебя важно быть успешным или успешной. Но в некоторых случаях это кажется необязательным. Иногда ты чувствуешь готовность проявлять себя в качестве эксперта и представать перед широкой публикой. А иногда это кажется тебе таким ненужным, что ты уклоняешься от чрезмерного внимания со стороны других людей.',
        payAttention:
          'Неудача — обратная сторона успеха. Чем больше мы движемся в сторону того, чтобы добиться исполнения своих желаний, тем больше вероятность встретиться с препятствием.',
        tooltipText:
          'Не бойся ошибок, ведь именно они помогают нам понять, что для нас действительно важно и как мы можем скорректировать свой путь. Это своего рода обратная связь, которой не стоит пренебрегать.',
      },
      lowValue: {
        about:
          'Каждому человеку время от времени важно почувствовать себя успешным в том, чем он занимается. Успех, признание нас экспертами повышает нашу самооценку и дает мотивацию двигаться дальше.',
        aboutSelf:
          'Для тебя не важно иметь атрибуты успешности: выступления на публике в качестве эксперта, интервью, публикации, подписанные твоим именем. Ты спокойно относишься к успеху, для тебя признание — это что-то необязательное.',
        payAttention:
          'Порой успешность и связанная с ней публичность необходимы, чтобы продвигаться дальше по карьерной лестнице. Сейчас большое внимание люди уделяют созданию личного бренда.',
        tooltipText:
          'Рекомендуем тебе уже сейчас начинать строить свой личный бренд, это поможет тебе устроиться на первую серьезную работу или повысит доверие инвесторов к тебе, если ты собираешься открыть свой бизнес. Вначале тебе стоит определиться с ролью, которую ты будешь продвигать. Затем необходимо понять свою “фишку”, что будет отличать тебя от конкурентов. И затем начинай думать, какие каналы ты будешь задействовать, например, соцсети.',
      },
    },
  },
  {
    name: 'Безопасность',
    shortDescription: 'Личная безопасность, забота о своем здоровье и благополучии',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/safety/safety01.svg',
      './profilum-assets/images/results/values-results/cards-images/safety/safety02.svg',
      './profilum-assets/images/results/values-results/cards-images/safety/safety03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Потребность в безопасности и надежности можно определить как стремление к защищенности, стабильности, отсутствию страха, тревоги и хаоса. Также безопасность предполагает сохранение физического и психологического здоровья. Те, кто ценят безопасность, стремятся иметь стабильную работу и оклад, страхуют квартиру и здоровье, составляют прогнозы на будущее.',
        aboutSelf:
          'Для тебя важна безопасность и надежность, поэтому ты заботишься о собственной безопасности и безопасности других людей. Ты соблюдаешь правила, которые приняты в социуме. Поэтому другие знают тебя как надежного человека, на которого можно положиться.',
        payAttention:
          'К сожалению, потребность в безопасности невозможно удовлетворить полностью. Всегда будут появляться новые угрозы, стремящиеся нарушить сформированную стабильность.',
        tooltipText:
          'Психологи представляют потребностную сферу человека в виде пирамиды, внизу которой лежат базовые потребности, а выше — “необязательные” потребности, более высокого порядка. Потребность в безопасности является базовой. Если ты чувствуешь, что в целом твоя потребность в безопасности удовлетворена, переходи к удовлетворению потребностей более высокого порядка, например, к потребности принадлежности к группе или потребности в признании и саморазвитии.',
      },
      middleValue: {
        about:
          'Потребность в безопасности и надежности можно определить как стремление к защищенности, стабильности, отсутствию страха, тревоги и хаоса. Также безопасность предполагает сохранение физического и психологического здоровья. Те, кто ценят безопасность, стремятся иметь стабильную работу и оклад, страхуют квартиру и здоровье, составляют прогнозы на будущее.',
        aboutSelf:
          'Иногда для тебя очень важна безопасность, но не всегда. Когда ты чувствуешь потребность в безопасности, ты соблюдаешь правила, которые приняты в социуме. Иногда ты можешь вести себя как надежный человек, на которого можно положиться. Но иногда безопасность для тебя не важна, и тогда ты не соблюдаешь принятые правила.',
        payAttention:
          'Несоблюдение правил может привести к негативным последствиям. Они могут быть самыми разными: от испорченных отношений до физического неблагополучия.',
        tooltipText:
          'Психологи представляют потребностную сферу человека в виде пирамиды, внизу которой лежат базовые потребности, а выше — “необязательные” потребности, более высокого порядка. Потребность в безопасности является базовой. Если ты чувствуешь, что в целом твоя потребность в безопасности удовлетворена, переходи к удовлетворению потребностей более высокого порядка, например, к потребности принадлежности к группе или потребности в признании и саморазвитии.',
      },
      lowValue: {
        about:
          'Потребность в безопасности и надежности можно определить как стремление к защищенности, стабильности, отсутствию страха, тревоги и хаоса. Также безопасность предполагает сохранение физического и психологического здоровья. Те, кто ценят безопасность, стремятся иметь стабильную работу и оклад, страхуют квартиру и здоровье, составляют прогнозы на будущее.',
        aboutSelf: 'Для тебя не важна безопасность и надежность, поэтому обычно ты не соблюдаешь правила, которые приняты в социуме.',
        payAttention:
          'Несоблюдение правил обычно приводит к тем или иным негативным последствиям. Последствия могут быть самыми разными: от испорченных отношений до физического неблагополучия.',
        tooltipText:
          'Психологи представляют потребностную сферу человека в виде пирамиды, внизу которой лежат базовые потребности, а выше — “необязательные” потребности, более высокого порядка. Потребность в безопасности является базовой. Можно сказать, что удовлетворение базовых потребностей необходимо для того, чтобы начать удовлетворять потребности более высокого порядка, такие  как принадлежность к группе или потребность в признании и саморазвитии.',
      },
    },
  },
  {
    name: 'Смысл',
    shortDescription: 'Приоритет значимого и важного, того, что имеет смысл',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/sense/sense01.svg',
      './profilum-assets/images/results/values-results/cards-images/sense/sense02.svg',
      './profilum-assets/images/results/values-results/cards-images/sense/sense03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Для каждого человека самое важное, то, что имеет смысл, — индивидуально. Для одних это помощь людям, для других — оставить свой след в искусстве, для третьих — служить науке. И некоторые люди так организуют свою жизнь, что в ней остается место только самому важному, имеющему для них смысл. Все остальное из жизни убирается как нечто бессмысленное.',
        aboutSelf:
          'Для тебя важно, чтобы твоя деятельность была осмысленной, то есть полной смысла. Вряд ли ты возьмешься за что-то более простое, чтобы получить то, что тебе неважно. Ты борешься за свои приоритеты. Тебя не пугает путь “через тернии к звездам”.',
        payAttention:
          'Каждому приходится иногда выбирать: заниматься делом жизни, но получать небольшое вознаграждение, или заниматься чем-то менее важным, а получать за это гораздо больше. Правильного ответа в такой дилемме нет. Каждому приходится чем-то поступиться, когда он делает выбор.',
        tooltipText:
          'Попробуй искать смысл даже в том, что на первый взгляд кажется бессмысленным. Например, чтение заданной по литературе художественной книги может казаться бессмысленным, но эксперты считают, что чтение книг развивает способность сопереживать другим, то есть эмоциональный интеллект, который пригодится тебе в любом деле.',
      },
      middleValue: {
        about:
          'Для каждого человека самое важное, то, что имеет смысл, — индивидуально. Для одних это помощь людям, для других — оставить свой след в искусстве, для третьих — служить науке. И некоторые люди так организуют свою жизнь, что в ней остается место только самому важному, имеющему для них смысл. Все остальное из жизни убирается как нечто бессмысленное.',
        aboutSelf:
          'Иногда для тебя важно чтобы твоя деятельность была осмысленной, то есть полной смысла. В такие моменты вряд ли ты возьмешься за что-то более простое, чтобы получить то, что тебе неважно. Однако бывает и так, что тебе хочется заняться какими-то простыми и не напрягающими вещами, вместо того, чтобы заниматься чем-то осмысленным и серьезным.',
        payAttention:
          'Каждому приходится иногда выбирать: заниматься делом жизни, но получать небольшое вознаграждение, или заниматься чем-то менее важным, а получать за это гораздо больше. Правильного ответа в такой дилемме нет. Каждому приходится чем-то поступиться, когда он делает выбор.',
        tooltipText:
          'Попробуй искать смысл даже в том, что на первый взгляд кажется бессмысленным. Например, чтение заданной по литературе художественной книги может казаться бессмысленным, но эксперты считают, что чтение книг развивает способность сопереживать другим, то есть эмоциональный интеллект, который пригодится тебе в любом деле.',
      },
      lowValue: {
        about:
          'Для каждого человека самое важное, то, что имеет смысл, — индивидуально. Для одних это помощь людям, для других — оставить свой след в искусстве, для третьих — служить науке. И некоторые люди так организуют свою жизнь, что в ней остается место только самому важному, имеющему для них смысл. Все остальное из жизни убирается как нечто бессмысленное.',
        aboutSelf:
          'В целом, для тебя не важно, чтобы твоя деятельность была осмысленной. Тебе подходит формат работ и задач, в которых нет большого смысла, но которые просто нужно сделать. Часто ты выбираешь путь наименьшего сопротивления, делая то, что проще и приятнее, а не то, что важнее.',
        payAttention:
          'Может быть, тебе еще не удалось найти тот вид активности, который захватит тебя с головой и станет твоим смыслом жизни.',
        tooltipText:
          'Чтобы приблизиться к пониманию того, что имеет для тебя смысл, можно составить список активностей которые тебе нравятся, и задать себе вопросы: “Если бы ты заранее знал/знала, что добьешься в этом деле успеха, чем бы ты стал/стала заниматься?” и “Чем бы ты занимался/занималась, если бы вопрос денег был решен?”.',
      },
    },
  },
  {
    name: 'Альтруизм',
    shortDescription: 'Забота о других и толерантное (терпимое) отношение к окружающим',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/altruism/altruism01.svg',
      './profilum-assets/images/results/values-results/cards-images/altruism/altruism02.svg',
      './profilum-assets/images/results/values-results/cards-images/altruism/altruism03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Альтруизм — это бескорыстная забота о благополучии других, отказ от собственной выгоды в пользу блага для другого человека или общества. Некоторые ученые считают, что жизнь ради других позволяет сделать мир лучше, гуманнее. Толерантное отношение означает терпимость к другому образу жизни, внешнему виду, мировоззрению.',
        aboutSelf:
          'Ты ценишь доброе отношение к людям и по возможности стараешься всем помогать. А еще ты высоко ценишь терпимое отношение к тем, кто отличается от большинства. От тебя нельзя услышать шутку, оскорбляющую достоинство другого человека, и такие принципы ты стараешься распространять среди окружающих.',
        payAttention: 'При высоком уровне альтруизма есть вероятность, что о своих нуждах ты думаешь меньше, чем о нуждах окружающих.',
        tooltipText:
          'Обращай больше внимания на свое самочувствие и свои потребности. Не задвигай их на задний план! Они очень важны. Периодически задавай себе вопросы: “Как я себя чувствую?”, “Чего я хочу?”, “Чего мне не хватает?”. Заботься о себе так же, как ты заботишься о других людях.',
      },
      middleValue: {
        about:
          'Альтруизм — это бескорыстная забота о благополучии других, отказ от собственной выгоды в пользу блага для другого человека или общества. Некоторые ученые считают, что жизнь ради других позволяет сделать мир лучше, гуманнее. Толерантное отношение означает терпимость к другому образу жизни, внешнему виду, мировоззрению.',
        aboutSelf:
          'В некоторых ситуациях ты ценишь доброе отношение к людям, и тогда стараешься всем помогать. Также ты ценишь терпимое отношение к тем, кто отличается от большинства, но тебе не всегда удается проявлять толерантность. Бывает, что ты можешь пошутить так, что ненароком заденешь другого человека.',
        payAttention:
          'При таком уровне альтруизма есть вероятность, что порой о своих нуждах ты думаешь меньше, чем о нуждах окружающих. А иногда, наоборот, ты думаешь больше о себе, чем о том, кто нуждается в помощи.',
        tooltipText:
          'Если ты хочешь научиться быть толерантным, т.е. терпимым, во всех ситуациях, то стоит помнить, что все люди разные и каждый имеет право на свое мнение. У каждого человека свои привычки, таланты, культура, семья, путь, который он или она прошли. Необходимо учитывать все эти факторы, когда будешь составлять мнение о человеке.',
      },
      lowValue: {
        about:
          'Альтруизм — это бескорыстная забота о благополучии других, отказ от собственной выгоды в пользу блага для другого человека или общества. Некоторые ученые считают, что жизнь ради других позволяет сделать мир лучше, гуманнее. Толерантное отношение означает терпимость к другому образу жизни, внешнему виду, мировоззрению.',
        aboutSelf:
          'Для тебя альтруизм, то есть бескорыстная забота о людях, и толерантность (терпимость) не являются приоритетами. Вряд ли ты будешь помогать незнакомому человеку бескорыстно или участвовать в благотворительности. Ты в большей степени заботишься о себе, чем о других. Так как ты не ценишь терпимость к тем, кто отличается от большинства, ты можешь пошутить, указав на это отличие.',
        payAttention:
          'Забота о других и толерантность — необходимые качества для построения долгосрочных и надежных отношений. Также толерантность потребуется тебе на работе или в бизнесе, ведь ты будешь общаться и работать в команде с самыми разными людьми.',
        tooltipText:
          'Если ты все-таки хочешь научиться быть толерантным, то стоит помнить, что все люди разные и каждый имеет право на свое мнение. У каждого человека свои привычки, таланты, культура, семья, путь, который он или она прошли. Необходимо учитывать все эти факторы, когда будешь составлять мнение о человеке.',
      },
    },
  },
  {
    name: 'Забота о природе',
    shortDescription: 'Сохранение природы, умеренность в потреблении',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature01.svg',
      './profilum-assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature02.svg',
      './profilum-assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Экологичность или, другими словами, забота о природе, подразумевает сохранение окружающей среды. По всему миру множество людей обеспокоены сохранением природы: во многих странах, в том числе в России, практикуется раздельный сбор мусора, производство экологически чистых продуктов, экономия воды и электроэнергии.',
        aboutSelf:
          'Для тебя ценна забота об окружающей среде. Ты стараешься минимизировать свой экологический след. И ты ответственно подходишь к вопросам потребления и стараешься не создавать мусор из вещей, которыми еще можно пользоваться.',
        payAttention:
          'Некоторые люди, для которых важна эта ценность, становятся экоактивистами. К сожалению, активно проявляя свою позицию, они нередко сталкиваются с непониманием, раздражением и даже неприязнью со стороны окружающих.',
        tooltipText:
          'Рекомендуем тебе посмотреть сайты, на которых собраны волонтерские программы, направленные на сохранение природы и живых организмов. А на этом сайте ты сможешь рассчитать свой экологический след https://footprintcalculator.henkel.com/ru',
      },
      middleValue: {
        about:
          'Экологичность или, другими словами, забота о природе, подразумевает сохранение окружающей среды. По всему миру множество людей обеспокоены сохранением природы: во многих странах, в том числе в России, практикуется раздельный сбор мусора, производство экологически чистых продуктов, экономия воды и электроэнергии.',
        aboutSelf:
          'Бывают ситуации, в которых для тебя важно заботиться о природе. Тогда ты стараешься минимизировать свой экологический след. Ответственно подходишь к вопросам потребления и стараешься не создавать мусор из вещей, которыми еще можно пользоваться. Однако в некоторых ситуациях забота о природе теряет для тебя свою важность, и приоритеты смещаются на другие ценности.',
        payAttention:
          'В таких ситуациях важно помнить о том, что ресурсы природы невосполнимы. И задача всего человечества — постараться минимизировать наносимый Земле урон.',
        tooltipText:
          'Рекомендуем тебе посмотреть сайты, на которых собраны волонтерские программы, направленные на сохранение природы и живых организмов. А на этом сайте ты сможешь рассчитать свой экологический след https://footprintcalculator.henkel.com/ru',
      },
      lowValue: {
        about:
          'Экологичность или, другими словами, забота о природе, подразумевает сохранение окружающей среды. По всему миру множество людей обеспокоены сохранением природы: во многих странах, в том числе в России, практикуется раздельный сбор мусора, производство экологически чистых продуктов, экономия воды и электроэнергии.',
        aboutSelf:
          'Забота о природе для тебя не важна. Ты не интересуешься экологическим следом, который ты оставляешь на Земле. И нельзя сказать, что ты осознанно подходишь к вопросам потребления: ты можешь приобретать вещи на смену тем, что еще вполне могли бы послужить.',
        payAttention:
          'Важно помнить о том, что ресурсы природы невосполнимы. И задача всего человечества — постараться минимизировать наносимый Земле урон.',
        tooltipText:
          'Существуют простые и отнимающие мало сил и времени способы заботы о природе, попробуй что-нибудь из этого списка. Замени обычные лампочки на энергосберегающие. Выключай компьютер на ночь. Выключай воду, когда чистишь зубы. Используй свою кружку вместо одноразового стаканчика. Не используй полиэтиленовые пакеты, носи с собой тканевый шоппер. А на этом сайте ты сможешь рассчитать свой экологический след https://footprintcalculator.henkel.com/ru',
      },
    },
  },
  {
    name: 'Удовольствие',
    shortDescription: 'Делать то, что хочется, и стремиться к удовольствию',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/pleasure/pleasure01.svg',
      './profilum-assets/images/results/values-results/cards-images/pleasure/pleasure02.svg',
      './profilum-assets/images/results/values-results/cards-images/pleasure/pleasure03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Гедонизм — это стремление к удовольствию, которое становится смыслом жизни. Для того, чтобы получать от жизни удовольствие, необязательно иметь много денег: гедонисты умеют наслаждаться и простыми вещами, такими как хорошая погода, вкусная еда, сон, отдых.',
        aboutSelf:
          'Ты очень ценишь возможность получить от жизни все! Твои желания являются для тебя законом, то есть в первую очередь ты делаешь то, что хочешь. Ты стремишься постоянно ощущать удовольствие и находиться в состоянии счастья. Ты хорошо чувствуешь свои желания, умеешь их исполнять и наслаждаешься результатами этих достижений.',
        payAttention:
          'К сожалению, невозможно полностью избежать стресса и сделать жизнь безоблачной на 100%. В жизни всегда будут появляться новые обстоятельства, вызывающие стресс.',
        tooltipText:
          'Рекомендуем тебе попробовать получать кайф от достижения целей. Это непростой путь, на котором нужно прикладывать много усилий, перебарывать себя, проходить через периоды неудовольствия, но результат того стоит! Чек-листы по постановке целей ты можешь найти здесь: https://drive.google.com/file/d/1zJQXhinkOX3G2JKDfDCX1j19QCqNMv0R/view',
      },
      middleValue: {
        about:
          'Гедонизм — это стремление к удовольствию, которое становится смыслом жизни. Для того, чтобы получать от жизни удовольствие, необязательно иметь много денег: гедонисты умеют наслаждаться и простыми вещами, такими как хорошая погода, вкусная еда, сон, отдых.',
        aboutSelf:
          'Иногда ты ценишь гедонизм. В такие моменты твои желания являются для тебя законом, то есть в первую очередь ты делаешь то, что хочешь. Ты стремишься ощущать удовольствие и находиться в состоянии счастья. Однако порой на первое место выходят другие ценности, например, ответственность. И вместо того, чтобы отдыхать, ты берешься за дело, даже если тебе не очень-то хочется им заниматься.',
        payAttention: 'Можно сказать, что обычно ты достигаешь баланса между получением удовольствия и ответственностью.',
        tooltipText:
          'Рекомендуем тебе попробовать получать кайф от достижения целей. Это непростой путь, на котором нужно прикладывать много усилий, перебарывать себя, проходить через периоды неудовольствия, но результат того стоит! Чек-листы по постановке целей ты можешь найти здесь: https://drive.google.com/file/d/1zJQXhinkOX3G2JKDfDCX1j19QCqNMv0R/view',
      },
      lowValue: {
        about:
          'Гедонизм — это стремление к удовольствию, которое становится смыслом жизни. Для того, чтобы получать от жизни удовольствие, необязательно иметь много денег: гедонисты умеют наслаждаться и простыми вещами, такими как хорошая погода, вкусная еда, сон, отдых.',
        aboutSelf:
          'Получение удовольствия от жизни для тебя далеко не основной приоритет. В первую очередь ты делаешь то, что от тебя требуется, выполняешь свои обязательства, а только сделав все, что нужно, позволяешь себе отдохнуть и насладиться моментом.',
        payAttention: 'Под грузом ответственности нелегко находить время и силы на то, чтобы просто получать удовольствие.',
        tooltipText:
          'Рекомендуем тебе акцентировать внимание на собственных желаниях и потребностях. Попробуй составить список вещей и действий, которые доставляют тебе радость. И выполняй то, что в нем записано.',
      },
    },
  },
  {
    name: 'Традиции',
    shortDescription: 'Поддержание и сохранение культурных и семейных традиций',
    imageUrls: [
      './profilum-assets/images/results/values-results/cards-images/traditions/traditions01.svg',
      './profilum-assets/images/results/values-results/cards-images/traditions/traditions02.svg',
      './profilum-assets/images/results/values-results/cards-images/traditions/traditions03.svg',
    ],
    data: {
      hightValue: {
        about:
          'Человек, для которого на первом месте стоят традиции, ценит нормы и правила, принятые в его семье, а также в обществе. Соблюдение традиций позволяет обществу сохранять стабильность и процветать.',
        aboutSelf:
          'Для тебя важно сохранять традиции своей семьи. Ты хранишь память о своих родственниках, бережешь вещи, связанные с ними. Тебе важно быть частью семьи, поэтому основные праздники ты отмечаешь вместе с ними и так, как уже было заведено. Ты ценишь и бережешь хорошие отношения с семьей.',
        payAttention:
          'Иногда семьи не принимают новые правила, по которым хотят жить молодые люди. Тогда это грозит конфликтами, которые очень тяжело переживают те, кто ценят традиции.',
        tooltipText:
          'Рекомендуем тебе сохранять гибкость мышления, несмотря на приверженность традициям. Мир постоянно меняется, становясь более сложным, и гибкость необходима для того, чтобы подстраиваться к изменяющимся условиям.',
      },
      middleValue: {
        about:
          'Человек, для которого на первом месте стоят традиции, ценит нормы и правила, принятые в его семье, а также в обществе. Соблюдение традиций позволяет обществу сохранять стабильность и процветать.',
        aboutSelf:
          'Ты отчасти сохраняешь традиции своей семьи. Для тебя это достаточно важно, но не настолько, чтобы следовать им неукоснительно. В целом, ты хранишь память о своих родственниках, бережешь вещи, связанные с ними. Тебе важно быть частью семьи, поэтому основные праздники ты стараешься отмечать вместе с ними и так, как уже было заведено.',
        payAttention:
          'Иногда семьи не принимают новые правила, по которым хотят жить молодые люди. Тогда это грозит конфликтами, которые очень тяжело переживают те, кто ценят традиции.',
        tooltipText:
          'Рекомендуем тебе сохранять гибкость мышления, несмотря на приверженность традициям. Мир постоянно меняется, становясь более сложным, и гибкость необходима для того, чтобы подстраиваться к изменяющимся условиям.',
      },
      lowValue: {
        about:
          'Человек, для которого на первом месте стоят традиции, ценит нормы и правила, принятые в его семье, а также в обществе. Соблюдение традиций позволяет обществу сохранять стабильность и процветать.',
        aboutSelf:
          'Для тебя не важны семейные традиции, у тебя другие приоритеты. Ты вряд ли хранишь память о своих родственниках, бережешь вещи, связанные с ними. Часто ты отмечаешь праздники вдали от семьи и так, как этого хочешь ты.',
        payAttention: 'Крепкие семейные узы дают ощущение опоры и поддержки. Поэтому не стоит ими пренебрегать.',
        tooltipText:
          'Если тебе не нравятся существующие семейные традиции, ты можешь попробовать создать свои. И привлекать родственников к их исполнению.',
      },
    },
  },
];
