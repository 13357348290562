<section class="internships" *ngIf="isAllLoaded">
  <div class="test-progress-white-block">
    <div class="internship-filters">
      <div class="filter-button" [class.active]="selectedInternshipTypeFilter == 0" (click)="showActiveInternships()">Опубликованные</div>
      <div class="filter-button" [class.active]="selectedInternshipTypeFilter == 1" (click)="showUnpublishedInternships()">
        Неопубликованные
      </div>
      <div class="filter-button" [class.active]="selectedInternshipTypeFilter == 2" (click)="showPastInternships()">Прошедшие</div>
    </div>

    <div *ngIf="filteredInternships && filteredInternships.length > 0" class="edit-event-col">
      <a [routerLink]="['/employer/create-internship']" class="button edit-event-page-btn w-button">Добавить</a>
    </div>

    <div class="catalog-events-row">
      <ng-container *ngIf="filteredInternships">
        <div class="events-column" [class.short-view]="viewValue" *ngFor="let internship of filteredInternships">
          <prf-internship-card [internship]="internship" [viewValue]="false"> </prf-internship-card>
        </div>
      </ng-container>
    </div>

    <prf-internships-welcome-page *ngIf="!filteredInternships || filteredInternships.length === 0"> </prf-internships-welcome-page>
  </div>
</section>
