import { Component } from '@angular/core';
import { ISortedItem, ProfessionsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

export enum ProfessionSortingEnum {
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
  RELEVANCE_DESC = 'relevance_desc',
  RELEVANCE_ASC = 'relevance_asc',
  SALARIES_DESC = 'salaries_desc',
  SALARIES_ASC = 'salaries_asc',
}

@Component({
  selector: 'prf-profession-order',
  templateUrl: './profession-order.component.html',
  styleUrls: ['./profession-order.component.scss'],
})
export class ProfessionOrderComponent extends UnsubscribeComponent {
  public selectedFilter: ISortedItem;

  public SORTING_CONFIG = {
    names: {
      isActive: false,
      activeOption: null,
      options: [
        {
          name: 'По названию: А-Я, A-Z',
          data: {
            key: ProfessionSortingEnum.NAME_DESC,
            sortType: 'names',
          },
        },
        {
          name: 'По названию: Я-А, Z-A',
          data: {
            key: ProfessionSortingEnum.NAME_ASC,
            sortType: 'names',
          },
        },
      ],
    },
    relevance: {
      isActive: false,
      activeOption: null,
      options: [
        {
          name: 'По востребованности: (больше – меньше)',
          data: {
            key: ProfessionSortingEnum.RELEVANCE_DESC,
            sortType: 'relevance',
          },
        },
        {
          name: 'По востребованности: (меньше – больше)',
          data: {
            key: ProfessionSortingEnum.RELEVANCE_ASC,
            sortType: 'relevance',
          },
        },
      ],
    },
    salaries: {
      isActive: false,
      activeOption: null,
      options: [
        {
          name: 'По зарплате: (больше – меньше)',
          data: {
            key: ProfessionSortingEnum.SALARIES_DESC,
            sortType: 'salaries',
          },
        },
        {
          name: 'По зарплате: (меньше – больше)',
          data: {
            key: ProfessionSortingEnum.SALARIES_ASC,
            sortType: 'salaries',
          },
        },
      ],
    },
  };

  constructor(private professionService: ProfessionsService) {
    super();
    this.initializeDefaultActiveOptions();
  }

  public initializeDefaultActiveOptions(): void {
    for (const key in this.SORTING_CONFIG) {
      this.SORTING_CONFIG[key].activeOption = this.SORTING_CONFIG[key].options[0];
    }
  }

  public itemSelect(item: any): void {
    if (!item) {
      this.clearSorting();
      return;
    }
    const itemSortType: string = item.sortType;

    this.selectedFilter = this.SORTING_CONFIG[`${itemSortType}`].options.find(
      (configItems: ISortedItem) => configItems.data.key === item.key,
    );

    for (const key in this.SORTING_CONFIG) {
      if (itemSortType === key) {
        this.SORTING_CONFIG[key].isActive = true;
        this.SORTING_CONFIG[key].activeOption = this.selectedFilter;
      } else {
        this.SORTING_CONFIG[key].isActive = false;
        this.SORTING_CONFIG[key].activeOption = this.SORTING_CONFIG[key].options[0];
      }
    }
    this.professionService.setSortingProfession(item);
  }

  public clearSorting(): void {
    this.selectedFilter = null;

    for (const key in this.SORTING_CONFIG) {
      this.SORTING_CONFIG[key].isActive = false;
    }

    this.professionService.setSortingProfession(null);
  }
}
