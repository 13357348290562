import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H3Component } from '../../../components/h3/h3.component';
import { ButtonComponent } from '../../../../button/button.component';
import { ImageComponent } from '../../../components/image/image.component';
import {
  PrfInformationForDifferentUsersCard,
} from '../../../components/information-for-different-users/information-for-different-users.types';
import { RouterLink } from '@angular/router';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-information-for-different-users-card',
  standalone: true,
  imports: [CommonModule, H3Component, ButtonComponent, ImageComponent, RouterLink],
  templateUrl: './information-for-different-users-card.component.html',
  styleUrl: './information-for-different-users-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationForDifferentUsersCardComponent {
  @Input() card: PrfInformationForDifferentUsersCard;

  private yandexMetricsService = inject(YandexMetricsService);

  public onClickUserCard(ymGoal: string) {
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
