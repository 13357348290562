import { Component, Input } from '@angular/core';
import { PortfolioAddModalService } from '../portfolio-add-modal/portfolio-add-modal.service';

export const PORTFOLIO_FILE_TYPES = [
  { id: 1, type: 'Diploma', name: 'Диплом', image: 'achieve-icon-1.svg', bgColor: '' },
  { id: 2, type: 'Gramota', name: 'Грамота', image: 'achieve-icon-2.svg', bgColor: '#9375fb' },
  { id: 3, type: 'Attestat', name: 'Аттестат', image: 'achieve-icon-3.svg', bgColor: '#5d7aff' },
  {
    id: 4,
    type: 'Gratitude',
    name: 'Благодарность',
    image: 'achieve-icon-4.svg',
    bgColor: '#00d5cb',
  },
  {
    id: 5,
    type: 'Svidetelstvo',
    name: 'Свидетельство',
    image: 'achieve-icon-5.svg',
    bgColor: '#fdcf00',
  },
  {
    id: 6,
    type: 'Udostoverenie',
    name: 'Удостоверение',
    image: 'achieve-icon-6.svg',
    bgColor: '#ff7aed',
  },
  {
    id: 7,
    type: 'Certificate',
    name: 'Сертификат',
    image: 'achieve-icon-7_1.svg',
    bgColor: '#36ca75',
  },
  {
    id: 8,
    type: 'Recommendation',
    name: 'Рекомендация',
    image: 'achieve-icon-8.svg',
    bgColor: '#ffad00',
  },
  { id: 9, type: 'Other', name: 'Другое', image: 'achieve-icon-9.svg', bgColor: '#ad896a' },
];

@Component({
  selector: 'prf-portfolio-card',
  templateUrl: './portfolio-card.component.html',
  styleUrls: ['./portfolio-card.component.scss'],
})
export class PortfolioCardComponent {
  @Input() file: any;
  @Input() fixSize: boolean = false;
  @Input() editable: boolean = true;

  public isMouseOnEdit: boolean = false;

  get typeParams(): any {
    const types = PORTFOLIO_FILE_TYPES.filter(pft => pft.id === this.file.type);
    return types[0] ? types[0] : PORTFOLIO_FILE_TYPES[0];
  }

  constructor(private portfolioAddModalService: PortfolioAddModalService) {}

  public clickEdit() {
    this.portfolioAddModalService.show(this.file);
  }

  public downloadFile() {
    if (!this.file.filePath) {
      return;
    }

    const self = this;
    fetch(this.file.filePath).then(function (t) {
      return t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', self.file.filePath.split('/').pop(-1));
        a.click();
      });
    });
  }
}
