import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class InternshipDetailsService {
  private currentInternship = new BehaviorSubject(null);
  private favoritesInternship = new BehaviorSubject(null);
  private favoritesInternshipsChange = new BehaviorSubject(null);

  addInternship(internship: any) {
    this.currentInternship.next(internship);
  }

  getCurrentInternship(): Observable<any> {
    return this.currentInternship.asObservable();
  }

  addFavoriteInternships(internships: any) {
    this.favoritesInternship.next(internships);
  }

  getFavoriteInternships(): Observable<any> {
    return this.favoritesInternship.asObservable();
  }

  getFavoritesInternshipsChange(): Observable<any> {
    return this.favoritesInternshipsChange.asObservable();
  }
}
