import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'prf-restriction-banner-teacher',
  templateUrl: './schooladmin-success-banner-teacher.component.html',
  styleUrls: ['./schooladmin-success-banner-teacher.component.scss'],
})
export class SchooladminSuccessBannerTeacherComponent {
  @Output() closeModal = new EventEmitter<boolean>();

  public closePopup(): void {
    this.closeModal.emit(true);
  }
}
