<form [formGroup]="form" novalidate>
  <h1 class="title">{{ 'PLAYER.ANKETA.TELL_ABOUT.HEADER' | translate }}</h1>

  <p class="descr">{{ 'PLAYER.ANKETA.TELL_ABOUT.DESCRIPTION' | translate }}</p>

  <div class="content-slide content-slide--s0">
    <div class="content-slide-col">
      <label for="lastName">{{ 'PLAYER.ANKETA.TELL_ABOUT.LAST_NAME' | translate }}</label>
      <input type="text" id="lastName" class="input__slide0" formControlName="lastName" />
    </div>
    <div class="content-slide-col content-slide-col-2">
      <label for="firstName">{{ 'PLAYER.ANKETA.TELL_ABOUT.FIRST_NAME' | translate }}</label>
      <input type="text" id="firstName" class="input__slide0" formControlName="firstName" />
    </div>
    <div class="content-slide-col content-slide-col-2">
      <label>{{ 'PLAYER.ANKETA.TELL_ABOUT.GENDER' | translate }}</label>
      <label class="switch">
        <input class="switch-input" type="checkbox" (change)="changeGender()" formControlName="isFemale" />
        <span class="switch-label"></span>
        <span *ngIf="isMaleGender" class="switch-handle">{{ 'BUTTONS.GENDER.M' | translate }}</span>
        <span *ngIf="!isMaleGender" class="switch-handle">{{ 'BUTTONS.GENDER.F' | translate }}</span>
      </label>
    </div>
    <div class="content-slide-col">
      <label>{{ 'PLAYER.ANKETA.TELL_ABOUT.AGE' | translate }}</label>
      <input type="hidden" formControlName="age" class="tell-select" [(ngModel)]="age" />
      <!--<ng2-dropdown (onItemClicked)="onAgeChanged($event)">-->
      <!--<ng2-dropdown-button>-->
      <!--{{age}}-->
      <!--</ng2-dropdown-button>-->
      <!--<ng2-dropdown-menu [appendToBody]="false">-->
      <!--<ng2-menu-item *ngFor="let age of ageRange" [value]="age">-->
      <!--{{ age }}-->
      <!--</ng2-menu-item>-->
      <!--</ng2-dropdown-menu>-->
      <!--</ng2-dropdown>-->
    </div>
    <div *ngIf="isRu()" class="" style="margin-top: 35px">
      <div class="content-slide-col tell-about__school">
        <input type="hidden" formControlName="schoolNumberId" id="school" />
        <label for="school">{{ 'PLAYER.ANKETA.TELL_ABOUT.SCHOOL' | translate }}</label>
      </div>
      <div class="content-slide-col content-slide-col-2">
        <label>{{ 'PLAYER.ANKETA.TELL_ABOUT.CLASS' | translate }}</label>
        <input type="hidden" formControlName="classNumber" class="tell-select" [(ngModel)]="classNumber" />
      </div>
      <div class="content-slide-col content-slide-col-2">
        <label>{{ 'PLAYER.ANKETA.TELL_ABOUT.CLASS_LETTER' | translate }}</label>
        <input type="hidden" formControlName="classLetter" class="tell-select" [(ngModel)]="classLetter" />
      </div>
    </div>
  </div>
</form>
