import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetButtonComponent extends UnsubscribeComponent implements OnInit {
  @Input() prfFormControl: AbstractControl;
  @Input() prfFormControlElement: HTMLInputElement;
  @Input() defaultValue: string;
  public showResetButton: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.prfFormControl?.valueChanges.pipe(this.unsubscribeOperator).subscribe(value => {
      this.showResetButton = !!(value?.length ?? value);
      this.changeDetectorRef.detectChanges();
    });
  }

  public resetData(): void {
    if (this.prfFormControl) {
      this.prfFormControl.reset(this.defaultValue);
    }

    this.prfFormControlElement?.focus();
  }
}
