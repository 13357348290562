import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ISortedItem, ProfessionsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

//TODO: компонент удаляется

@Component({
  selector: 'prf-dropdown-sorting',
  templateUrl: './dropdown-sorting.component.html',
  styleUrls: ['./dropdown-sorting.component.scss'],
})
export class DropdownSortingComponent extends UnsubscribeComponent implements OnInit {
  @Input() set itemsList(val: ISortedItem[]) {
    this._itemsList = val;
  }
  get itemsList() {
    return this._itemsList;
  }
  _itemsList: ISortedItem[] = [];
  public open: boolean = false;
  public selectedItem: ISortedItem;
  public sortingTitle: string;

  @ViewChild('container') container: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.container?.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  constructor(private eRef: ElementRef, private professionService: ProfessionsService) {
    super();
  }

  ngOnInit() {
    this.sortingTitle = this.itemsList[0].name;
    this.getSorting();
  }

  private getSorting() {
    this.professionService
      .getSortingProfession()
      .pipe(
        takeUntil(this.unsubscribe),
        tap((sorting: ISortedItem) => {
          const selectedItem = this.itemsList.find(item => item == sorting);
          this.selectedItem = selectedItem ? selectedItem : null;
          this.sortingTitle = this.selectedItem ? this.selectedItem.name : this.itemsList[0].name;
        }),
      )
      .subscribe();
  }

  itemSelect(item: ISortedItem) {
    this.selectedItem = item;
    this.professionService.setSortingProfession(item);
    this.open = false;
  }

  clearSorting() {
    this.professionService.setSortingProfession(null);
    this.open = false;
  }
}
