import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiAuthService, B2gSaasService, IJwtRegisterData, StorageKeys, WebStorageService } from '@profilum-library';
import { SessionStatusEnum } from '@profilum-collections/prf-anketa.collections';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-jwt-login',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JwtLoginComponent extends UnsubscribeComponent implements OnInit {
  public invalidURLFormat: boolean = false;
  public invalidURLFormatMessage: string = 'Неверный формат урла';
  private _authCode: string = '';
  private tag: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiAuthService: ApiAuthService,
    private b2gSaasService: B2gSaasService,
    private utilsService: UtilsService,
    private router: Router,
    private webStorageService: WebStorageService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  private set setAuthCode(authCode: string) {
    this._authCode = authCode;
  }

  public get authCode(): string {
    return this._authCode;
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((queryParams: Params) => queryParams['code']),
        tap((authCode: string | undefined) => {
          this.invalidURLFormat = !authCode?.length;

          if (this.invalidURLFormat) {
            this.handleErrors();
          }
        }),
        filter(() => !this.invalidURLFormat),
        switchMap((authCode: string) => {
          this.setAuthCode = authCode;

          return this.apiAuthService.getJwtLoginData(this.authCode);
        }),
        catchError(() => of(null)),
        filter((response: IJwtRegisterData | null) => {
          if (response?.status !== 'Success') {
            this.handleErrors();
            return false;
          }

          return true;
        }),
        switchMap(({ userId, email }) => {
          return this.apiAuthService.loginJwtUser(userId, email);
        }),
        switchMap(loginData => {
          this.userStorageService.setUserId = loginData.userId;
          this.webStorageService.set(StorageKeys.Tag, loginData.tag);
          this.webStorageService.set(StorageKeys.UserId, loginData.userId);
          this.webStorageService.set(StorageKeys.Tag, loginData.tag);
          this.webStorageService.set(StorageKeys.UserRole, loginData.role);
          this.webStorageService.set(StorageKeys.IsAuthorized, true);
          // костыль для просвета, после доработки метода получать из него провайдера
          this.webStorageService.set(StorageKeys.ProviderName, 'prosvet');

          this.tag = loginData.tag;

          return this.b2gSaasService.getLastSession(loginData.userId);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(({ sessionId, status }) => {
        switch (status) {
          case SessionStatusEnum.Success:
            this.router.navigate(['/mincifry-test-results', sessionId]);
            break;
          case SessionStatusEnum.Uncompleted:
            this.router.navigate(['/mincifry-test', sessionId]);
            break;
          default:
            this.redirectByTag(this.tag);
            break;
        }
      });
  }

  public redirectByTag(tag: string): void {
    switch (tag) {
      case 'Unti2035':
        this.router.navigate(['/mincifry-test']);
        break;
      default:
        this.handleErrors();
        break;
    }
  }

  public handleErrors(): void {
    this.utilsService.openSnackBar(this.invalidURLFormatMessage, 'error');
    this.router.navigate(['']);
  }
}
