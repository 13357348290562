import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

export interface IFilter {
  selected: boolean;
  name: string;
  id: number;
}

class Filter implements IFilter {
  private readonly _name: string = '';
  private readonly _id: number;
  private _selected: boolean = false;

  constructor(id: number, name: string, selected: boolean = false) {
    this._id = id;
    this._name = name;
    this._selected = selected;
  }

  set selected(selected: boolean) {
    this._selected = selected;
  }

  get selected(): boolean {
    return this._selected;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }
}

@Component({
  selector: 'prf-dropdown-with-filter',
  templateUrl: './dropdown-with-filter.component.html',
  styleUrls: ['./dropdown-with-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownWithFilterComponent {
  public isOpen: boolean = false;
  public filterName: string = '';
  public selectedItem: Filter;
  private _items: Filter[] = [];
  @Input() placeholder: string = '';
  @Output() selectedItemEvent = new EventEmitter<Filter>();
  @ViewChild('container') container: ElementRef;
  @Input() set items(items: (string | { name: string })[]) {
    if (items) {
      this._items = items.map((item, index) => new Filter(index, (item as any)?.name ?? item));
      this.changeDetectorRef.detectChanges();
    }
  }

  get itemsList(): Filter[] {
    return this._items;
  }

  @HostListener('document:click', ['$event.target'])
  public clickedOutside(targetElement) {
    if (this.container && !this.container?.nativeElement.contains(targetElement)) {
      this.isOpen = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public clearFilterName($event: Event): void {
    this.filterName = '';
    $event.preventDefault();
    $event.stopPropagation();
    this.changeDetectorRef.detectChanges();
  }

  public onClickClear(): void {
    this.selectedItem = null;
    this.filterName = '';
    this._items.forEach(itm => (itm.selected = false));
    this.selectedItemEvent.emit(this.selectedItem);
    this.isOpen = false;
    this.changeDetectorRef.detectChanges();
  }

  public onSelectItem(selItem: Filter): void {
    selItem.selected = true;
    this.selectedItem = selItem;
    this._items.forEach(item => (item.selected = item.id === selItem.id));
    this.selectedItemEvent.emit(this.selectedItem);
    this.isOpen = false;
    this.changeDetectorRef.detectChanges();
  }
}
