<section>
  <prf-menu [root]="'teacher'"></prf-menu>
  <div class="content-section">
    <div class="container">
      <prf-page-header [title]="''"></prf-page-header>
      <div *ngIf="lessonsInfo" class="class-courses-lessons">
        <div class="class-courses-lessons__title-wrapper" (click)="navigateBack()">
          <div class="class-courses-lessons__button_back"></div>
          <h1 class="class-courses-lessons__title">{{ course.name }}</h1>
        </div>
        @if (disableLessonsInTeacherLK) {
          <div class="widget-course">
            <p class="widget-course-desc">
              В 2024/2025 учебном году проект Профилум" не реализуется в вашем регионе. Для просмотра вам доступны результаты прошлого
              учебного года и каталог профессий.
            </p>
          </div>
        } @else {
          <div class="ce-lessons" #lessonsList>
            <div
              *ngFor="let lesson of lessonsInfo; trackBy: trackByFn; let index = index"
              class="ce-lessons__body"
              [class.selected]="index === recommendedLessonDateIndex"
            >
              <div class="ce-lessons__date" [tp]="recommendedDateTooltip">
                <span class="ce-lessons__date-number">{{ lesson.date ? lesson.date : '-' }}</span>
                <span>{{ lesson.month ? lesson.month : 'Без даты' }}</span>
              </div>
              <div class="ce-lessons__body__title">
                <ng-container *ngIf="lesson.tag">
                  <div class="ce-lessons__body__title-tag" [class.ce-lessons__body__title-tag-region]="lesson.tag === 2">
                    {{ lesson.tag === 1 ? 'Федеральный компонент' : 'Региональный компонент' }}
                  </div>
                </ng-container>
                <div class="ce-lessons__body__title-name" [tp]="lessonNameTooltip" [tpData]="lesson">
                  {{ lesson.number }}. {{ lesson.name }}
                </div>
              </div>
              <ng-container *ngIf="!lesson.blockingInfo?.value; else isBlocked">
                <div class="ce-lessons__body__materials">
                  <!--<button type="button" class="ce-lessons__link" (click)="openMaterialsModal(lesson)" aria-label="Материалы темы">
                Материалы темы
              </button>-->
                  <a
                    *ngIf="!userRestriction; else disabledLink"
                    class="ce-lessons__link"
                    (click)="sendMaterialsMetric(lesson, 'open')"
                    [href]="lesson.conspectUrl"
                    target="_blank"
                    aria-label="Открыть материалы для занятия"
                    [class.ce-lessons__link_disabled]="!lesson.conspectUrl"
                  >
                    Конспект
                  </a>
                  <a
                    *ngIf="!userRestriction; else disabledLink"
                    class="ce-lessons__link"
                    (click)="sendMaterialsMetric(lesson, 'download')"
                    [href]="lesson.yandexDiskMaterialsUrl"
                    target="_blank"
                    aria-label="Скачать материалы для занятия"
                    [class.ce-lessons__link_disabled]="!lesson.yandexDiskMaterialsUrl"
                  >
                    Презентация
                  </a>
                  <!--TODO убрать костыль по Башкортостану-->
                  <span
                    *ngIf="
                      isUserFromBashkortostan &&
                        lesson.number === AppSettingsService.settings.bashkortostanClosedLesson.teacherLessonNumber;
                      else defaultTemplate
                    "
                    class="ce-lessons__activity ce-lessons__passed ce-lessons__full-content"
                    >Задание недоступно с 20 марта</span
                  >
                  <ng-template #defaultTemplate>
                    <button
                      *ngIf="!lesson.passed; else passed"
                      [disabled]="userRestriction"
                      (click)="showPopup(lesson)"
                      class="ce-lessons__activity"
                      [class.ce-lessons__not-available]="!activeLessonIds.includes(lesson.id)"
                    >
                      {{ !activeLessonIds.includes(lesson.id) ? 'Нет заданий' : 'Выдать задание' }}
                    </button>
                  </ng-template>
                  <ng-template #passed>
                    <span class="ce-lessons__activity ce-lessons__passed">{{ getPassed(lesson.id) }}/{{ pupilCount }} выполнили</span>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #isBlocked>
                <div class="ce-lessons__blocked">
                  {{ lesson.blockingInfo.description ? lesson.blockingInfo.description : 'Заблокировано. Откроется позднее' }}
                </div>
              </ng-template>
            </div>
          </div>
        }
      </div>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>

<ng-template [ngIf]="showOpenLessonPopup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <div class="popup-share">
        <a (click)="cancel()" class="close-lb w-inline-block">
          <img src="./assets/images/icons/close-grey.svg" alt="" />
        </a>
        <div class="popup-share-header">
          <h3 class="popup-share-title">Задание к занятию №{{ selectedLesson.number }} «{{ selectedLesson.name }}»</h3>
        </div>
        <div class="popup-share-content">
          <p class="popup-share-text">Ученики смогут выполнить задание в своем личном кабинете</p>
          <p *ngIf="notPassedLessons" class="popup-share-text">
            Также будут выданы задания к {{ isMultipleLessonNotPassed ? 'предыдущим занятиям' : 'предыдущему занятию' }} №{{
              notPassedLessons
            }}
          </p>
        </div>
        <div class="popup-share-buttons">
          <button class="button" (click)="complete()">Выдать</button>
          <button class="button button-white" (click)="cancel()">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #materialsModalTemplate>
  <prf-teacher-materials-modal [lesson]="chosenLesson"></prf-teacher-materials-modal>
</ng-template>

<ng-template #recommendedDateTooltip>
  <prf-tooltip>Рекомендуемая дата проведения урока</prf-tooltip>
</ng-template>

<ng-template #lessonNameTooltip let-lesson="data">
  <prf-tooltip>{{ lesson.number }}. {{ lesson.name }}</prf-tooltip>
</ng-template>

<ng-template #disabledLink>
  <span class="ce-lessons__link-disabled">Недоступно</span>
</ng-template>
