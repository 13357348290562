import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService, ILoginForCampTestUserModel, StorageKeys, WebStorageService } from '@profilum-library';
import { merge as observableMerge } from 'rxjs';

@Injectable()
export class AnketaService {
  private anketa = {
    firstName: undefined,
    lastName: undefined,
    classNumber: undefined,
    classLetter: undefined,
    age: undefined,
    isFemale: false,
    testType: undefined,
    userStatus: undefined,
    childAge: undefined,
    schoolNumberId: undefined,
    language: undefined,
  };
  private userAnketa: any;
  private formControls: UntypedFormGroup[];
  private _childInfoForm: UntypedFormGroup;
  public getForms: () => UntypedFormGroup[] = () => this.formControls;

  constructor(private http: HttpService, private webStorageService: WebStorageService) {
    const aboutTest = new UntypedFormGroup({});

    this.formControls = [aboutTest];
    observableMerge(...this.formControls.map(f => f.valueChanges)).subscribe(d => {
      Object.assign(this.anketa, d);
      this.userAnketa = this.anketa;
      this.webStorageService.set(StorageKeys.Anketa, this.userAnketa);
    });
  }

  get childInfoForm(): UntypedFormGroup {
    return this._childInfoForm ?? (this._childInfoForm = AnketaService.initChildInfoForm());
  }

  get getLeadDto(): ILoginForCampTestUserModel {
    return {
      leadDto: {
        city: this._childInfoForm.controls['city'].value,
        gender: this._childInfoForm.controls['gender'].value,
        schoolClassNumber: this._childInfoForm.controls['class'].value,
      },
    };
  }

  public removeChildInfoForm(): void {
    this._childInfoForm = null;
  }

  private static initChildInfoForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      city: new UntypedFormControl(null, [Validators.required]),
      class: new UntypedFormControl(null, [Validators.required]),
      gender: new UntypedFormControl(null, [Validators.required]),
    });
  }
}
