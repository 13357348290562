<div *ngIf="schoolClassId" class="success-banner">
  <div class="success-banner-text">
    <div class="success-banner-title">
      Поздравляем! Ученики <br />
      прошли диагностику
    </div>
    <div class="success-banner-desc">
      Посмотрите статистику по классу<br />
      и результаты тех, кто прошёл
    </div>
  </div>
  <div class="success-banner-button">
    <button class="button green-btn" [routerLink]="['/results']" [queryParams]="{ schoolClassId: schoolClassId }">
      Смотреть результаты
    </button>
  </div>
</div>
