import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-profession-search',
  templateUrl: './profession-search.component.html',
  styleUrls: ['./profession-search.component.scss'],
})
export class ProfessionSearchComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public searchField: UntypedFormControl;
  public searches: any;
  public empty: boolean = false;

  @Input() set searchClear(searchClear: boolean) {
    if (searchClear) {
      this.clearSearch();
    }
  }

  @Output() searchesData = new EventEmitter();

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe)).subscribe(term => {
      this.searchesData.emit(term);
    });
  }

  public clearSearch(): void {
    this.searchField.setValue('');
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
