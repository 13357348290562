import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { ApiProfessionsService, ApiUniversitiesService, ISpecialityData } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-add-university',
  templateUrl: './add-university.component.html',
  styleUrls: ['./add-university.component.scss'],
})
export class AddUniversityComponent implements OnInit {
  specialities: ISpecialityData[];

  public form: UntypedFormGroup;
  public submitted: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private apiProfessionsService: ApiProfessionsService,
    private apiUniversitiesService: ApiUniversitiesService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Добавление ВУЗа' });
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, Validators.required),
      universitySpecialities: new UntypedFormControl(),
    });

    this.apiProfessionsService
      .getSpecialities()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(specialities => {
          this.specialities = specialities;
        }),
      )
      .subscribe();
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const universityObject = {
        name: this.form.get('name').value,
        universitySpecialities: this.form.get('universitySpecialities').value,
      };

      this.apiUniversitiesService
        .addUniversity(universityObject)
        .pipe(
          tap((response: any) => {
            this.utilsService.openSnackBar('👌 ВУЗ добавлен, вы будете перенаправлены на страницу данного ВУЗа', 'success');
            return setTimeout(_ => {
              this.router.navigate(['/admin/universities/' + response.id]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
