import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterVariantsEnum } from '../models';
import { ClassVariants, GenderVariants } from '../director-analytics.constants';

@Component({
  selector: 'prf-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  public activeFilterTab: string = '';
  protected readonly FilterVariantsEnum = FilterVariantsEnum;
  protected readonly ClassVariants = ClassVariants;
  protected readonly GenderVariants = GenderVariants;

  @Input() public showMunicipityReport: boolean = false;
  @Input() public isMenuFixed: boolean = false;

  @Output() public toggleMunicipalityChartsEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public setFilterTabEmitter: EventEmitter<{ filterType: string; filterValue: string }> = new EventEmitter<{
    filterType: string;
    filterValue: string;
  }>();

  public toggleMunicipalityCharts(): void {
    this.toggleMunicipalityChartsEmitter.emit();
  }

  public setFilterTab(filterType: string, filterValue: string) {
    this.setFilterTabEmitter.emit({ filterType, filterValue });
    this.activeFilterTab = this.activeFilterTab === filterValue ? '' : filterValue;
  }
}
