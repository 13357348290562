<ng-container [ngSwitch]="userInfo.role">
  <ng-container *ngSwitchCase="'pupil'">
    <prf-menu></prf-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'tutor'">
    <prf-tutor-menu [innerRoute]="true"></prf-tutor-menu>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <prf-menu></prf-menu>
  </ng-container>
</ng-container>

<!-- <prf-menu></prf-menu> -->

<div class="content-section">
  <div class="container">
    <prf-page-header [title]="'HEADER.PROF' | translate" [showClassDropdown]="false"></prf-page-header>

    <div class="tabs" *ngIf="loaded">
      <div class="tabs-professions">
        <div *ngIf="userInfo.role === 'pupil'" class="tabs-menu-div-2">
          <div class="tabs-menu-prof">
            <a (click)="tabName = 'catalog'" [class.current]="tabName === 'catalog'" class="tab-link-prof w-inline-block">
              <div>{{ 'SHARED.CATALOG' | translate }}</div>
              <img src="./profilum-assets/images/icons/angle.png" width="20" alt="" class="angle" />
            </a>
            <a (click)="tabName = 'recommendations'" [class.current]="tabName === 'recommendations'" class="tab-link-prof w-inline-block">
              <div>Рекомендованные</div>
              <img
                src="./profilum-assets/images/icons/angle.png"
                width="20"
                alt=""
                class="angle"
                [class.hide]="tabName !== 'recommendations'"
              />
              <img src="./profilum-assets/images/icons/angle2.png" width="20" alt="" class="angle _2" />
            </a>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <prf-profession-catalog [tabName]="tabName"></prf-profession-catalog>
          </div>
        </div>

        <div *ngIf="userInfo.role === 'pupil'" [class.no-display]="tabName !== 'recommendations'" class="tabs-content">
          <div class="tab-pane left">
            <prf-recommended-professions
              [testResults]="testResults"
              [pupilSessionId]="userInfo.role === 'pupil' ? testSessionId : null"
            ></prf-recommended-professions>
          </div>
        </div>
      </div>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>
