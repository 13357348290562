import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
  public userRole: string = '';
  public tabName: string = 'catalog';

  constructor(private meta: Meta, private webStorageService: WebStorageService) {
    this.meta.updateTag({ name: 'og:title', content: 'Мероприятия' });
  }

  public ngOnInit() {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }
}
