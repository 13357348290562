import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiInstitutionsService, IFilterClasses } from '@profilum-library';

@Component({
  selector: 'prf-courses-filter-institution',
  templateUrl: './courses-filter-institution.component.html',
  styleUrls: ['./courses-filter-institution.component.scss'],
})
export class CoursesFilterInstitutionComponent implements OnInit {
  filters: IFilterClasses = {};

  institutionsAll: any[] = [];
  selectedInstitution: any[] = [];
  filterOpen: boolean = false;

  @ViewChild('institutionsFilter') institutionsFilter: ElementRef;

  private ngUnsubscribe$ = new Subject<any>();
  constructor(private filtersService: CoursesFiltersService, private apiInstitutionsService: ApiInstitutionsService) {}

  ngOnInit() {
    this.apiInstitutionsService
      .getInstitutionsAll()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap((institutions: any[]) => {
          this.institutionsAll = institutions.sort((a, b) => (a.name > b.name ? 1 : -1));
          return this.filtersService.getCoursesFilters().pipe(
            tap(filters => {
              this.filters = filters;
              this.selectedInstitution = filters.institutionId
                ? this.institutionsAll.find(inst => filters.institutionId === inst.id)
                : null;
            }),
          );
        }),
      )
      .subscribe();
  }

  setInstitutionFilter(institution: any) {
    if (this.filters.institutionId === institution.id) {
      this.deselectInstitution();
    } else {
      this.filters.institutionId = institution.id;
      this.filtersService.setCoursesFilter(this.filters);
    }
  }

  deselectInstitution() {
    this.filters.institutionId = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader(): string {
    if (this.filters.institutionId) {
      const institution = this.institutionsAll.find(el => el.id === this.filters.institutionId);
      return institution.name;
    } else {
      return 'Организатор';
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.institutionsFilter && !this.institutionsFilter?.nativeElement.contains(target)) {
      this.filterOpen = false;
    }
  }
}
