import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { B2gSaasService, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-internship-cards',
  templateUrl: './internship-cards.component.html',
  styleUrls: ['./internship-cards.component.scss'],
})
export class InternshipCardsComponent implements OnInit {
  public loaded: boolean = false;
  public userRole: string = '';
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public tabName: string = 'catalog'; // [catalog, recommendations]
  public testResults: any;
  public userId: string = '';

  constructor(private b2gSaasService: B2gSaasService, private webStorageService: WebStorageService) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.testSessionId = this.webStorageService.get(StorageKeys.TestSessionId);
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
  }
  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    if (this.sessionStatus === 'Success') {
      const sessionIds = [];
      sessionIds.push(this.testSessionId);
      const getResultsObject = {
        regionId: this.webStorageService.get(StorageKeys.RegionId),
        municipalityId: this.webStorageService.get(StorageKeys.MunicipalityId),
        sessionIds: sessionIds,
        role: this.webStorageService.get(StorageKeys.UserRole),
      };

      this.b2gSaasService
        .getResultPage(getResultsObject)
        .pipe(take(1))
        .subscribe(results => {
          if (results.length > 0) {
            this.testResults = results;
          }
        });
    }

    this.loaded = true;
  }
}
