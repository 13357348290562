<div *ngIf="showJournal" class="teachers-journal">
  <table class="teachers-journal__table">
    <thead class="teachers-journal__table__header teachers-journal-table-header" #tableHeader>
      <tr>
        <th>
          <span class="teachers-journal-table-header__title">{{ dictionary.Pupils | titlecase }} {{ mappedLessons.length }}</span>
          <ng-template [ngIf]="showArrows">
            <div
              class="teachers-journal-arrow teachers-journal-arrow_left"
              (click)="scrollLeft()"
              [ngClass]="{ 'teachers-journal-arrow_active': showActiveLeftArrow }"
            ></div>
            <div
              class="teachers-journal-arrow teachers-journal-arrow_right"
              (click)="scrollRight()"
              [ngClass]="{ 'teachers-journal-arrow_active': showActiveRightArrow }"
            ></div>
          </ng-template>
        </th>
        <ng-container *ngFor="let lessonData of mappedLessons[0].lessonData; let index = index">
          <th [ngStyle]="{ display: index >= minIndex && index <= maxIndex ? null : 'none' }">
            <div class="teachers-journal-tooltip-two-lessons" *ngIf="lessonData.twoTasks"></div>
            <div
              class="exercise-number"
              [class.no-tasks]="!lessonData.tasksExist"
              [class.diagnostic-theme]="lessonData.isDiagnostic"
              [tp]="exerciseInfoTooltip"
              [tpData]="lessonData"
              [tpPlacement]="'left'"
            >
              {{ lessonData.orderNumber.toString() }}
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tfoot class="teachers-journal__table__footer teachers-journal-table-footer">
      <tr *ngIf="lessonResultsCount">
        <td class="teachers-journal-table-footer__title">Всего выполнили</td>
        <ng-container *ngFor="let lessonData of mappedLessons[0].lessonData; let index = index">
          <td [ngStyle]="{ display: index >= minIndex && index <= maxIndex ? null : 'none' }">
            <div class="teachers-journal-table-footer__counter" *ngIf="lessonResultsCount[lessonData.materialId]">
              {{ lessonResultsCount[lessonData.materialId][0] }}
              <div class="teachers-journal-table-footer__counter-two-lessons" *ngIf="lessonData.twoTasks">
                {{ lessonResultsCount[lessonData.materialId][1] }}
              </div>
            </div>
          </td>
        </ng-container>
      </tr>
    </tfoot>
    <tbody class="teachers-journal__table__body teachers-journal-table-body">
      <tr *ngFor="let mappedLesson of mappedLessons; let index = index">
        <td class="teachers-journal-table-body__pupil-name">
          <div
            *ngIf="mappedLesson.allNotPassed"
            class="teachers-journal-table-body_pupil-dont-complete-tasks"
            [tp]="noCompleteTasksTooltip"
          ></div>
          {{ mappedLesson.fullName }}
        </td>
        <ng-container *ngFor="let lessonData of mappedLesson.lessonData; let index = index">
          <td
            [ngStyle]="{ display: index >= minIndex && index <= maxIndex ? null : 'none' }"
            [ngClass]="{
              'incomplete-lesson': !lessonData.isPassed && lessonData.tasksExist,
              'complete-lesson': lessonData.isPassed,
              'disabled-lesson': !lessonData.tasksExist
            }"
          >
            <ng-container *ngIf="!!lessonData.lesson">
              <div class="teachers-journal-tooltip-section" [class.teachers-journal-tooltip-two]="lessonData.twoTasks">
                <div
                  *ngFor="let task of lessonData.lesson.tasks; let indx = index"
                  class="lesson-icon"
                  [ngClass]="{
                    'incomplete-lesson': !lessonData.passedTasks[indx] && lessonData.tasksExist,
                    'complete-lesson': lessonData.passedTasks[indx],
                    'disabled-lesson': !lessonData.tasksExist
                  }"
                  [tp]="lessonInfoTooltip"
                  [tpData]="{ lessonData, task, indx }"
                ></div>
              </div>
            </ng-container>
            <ng-container *ngIf="!lessonData.lesson">
              <div class="teachers-journal-tooltip-section" [class.teachers-journal-tooltip-two]="lessonData.twoTasks">
                <div class="lesson-icon disabled-lesson" [tp]="noTasksTooltip" [tpData]="{ lessonData }"></div>
              </div>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <div class="teachers-journal-info">
    <span class="teachers-journal-info__completed">выполнено</span>
    <span class="teachers-journal-info__incompleted">не выполнено</span>
    <span class="teachers-journal-info__disabled">нет задания</span>
  </div>
</div>

<ng-template #noCompleteTasksTooltip>
  <prf-tooltip>{{ dictionary.Pupil | titlecase }} не выполняет задания. Возможно, ему нужна помощь</prf-tooltip>
</ng-template>

<ng-template #exerciseInfoTooltip let-lessonData="data">
  <prf-tooltip [class.accent-theme]="lessonData.isDiagnostic">
    <p class="teachers-journal-tooltip__header">
      <span
        class="teachers-journal-tooltip__header__title"
        [class.teachers-journal-tooltip__header__title_accent]="lessonData.isDiagnostic"
      >
        Занятие {{ lessonData.orderNumber }}
      </span>
      <span
        *ngIf="lessonData.tasksExist"
        class="teachers-journal-tooltip__header__status"
        [ngClass]="{
          'teachers-journal-tooltip__header__status_accent': lessonData.isDiagnostic
        }"
        [className]="'teachers-journal-tooltip__header__status_' + getLessonStatus(lessonData)"
      >
        {{ getLessonStatusText(lessonData) }}
      </span>
    </p>
    <p
      *ngIf="lessonData.tooltipText.length"
      class="teachers-journal-tooltip__description"
      [class.teachers-journal-tooltip__description_accent]="lessonData.isDiagnostic"
    >
      <span *ngIf="lessonData.tooltipText.length === 1; else multipleTasksDescription">
        <!--TODO убрать костыль по Башкортостану-->
        {{
          lessonData.orderNumber === AppSettingsService.settings.bashkortostanClosedLesson.teacherLessonNumber && isUserFromBashkortostan
            ? 'Задание недоступно с 20 марта'
            : lessonData.tooltipText[0]
        }}
      </span>
    </p>
    <ng-template #multipleTasksDescription>
      <ul
        class="teachers-journal-tooltip__description__list"
        [class.teachers-journal-tooltip__description__list_accent]="lessonData.isDiagnostic"
      >
        <li *ngFor="let text of lessonData.tooltipText">{{ text }}</li>
      </ul>
    </ng-template>
  </prf-tooltip>
</ng-template>

<ng-template #noTasksTooltip let-data="data">
  <prf-tooltip>
    <p class="teachers-journal-tooltip__header">
      <span class="teachers-journal-tooltip__header__title">Занятие {{ data.lessonData.orderNumber }}</span>
      <span
        *ngIf="data.lessonData.tasksExist"
        class="teachers-journal-tooltip__header__status"
        [ngClass]="{ completed: data.lessonData.isPassed }"
      >
        {{ data.lessonData.isPassed ? 'выполнено' : 'не выполнено' }}
      </span>
    </p>
    <p class="teachers-journal-tooltip__description">
      {{ data.lessonData.lesson?.tasks[0]?.text }}
      <span *ngIf="data.lessonData.tooltipText">{{ data.lessonData.tooltipText }}</span>
    </p></prf-tooltip
  >
</ng-template>

<ng-template #lessonInfoTooltip let-data="data">
  <prf-tooltip [class.accent-theme]="data.lessonData.isDiagnostic">
    <p class="teachers-journal-tooltip__header">
      <span
        class="teachers-journal-tooltip__header__title"
        [class.teachers-journal-tooltip__header__title_accent]="data.lessonData.isDiagnostic"
      >
        Занятие {{ data.lessonData.orderNumber }}
      </span>
      <span
        *ngIf="data.lessonData.tasksExist"
        class="teachers-journal-tooltip__header__status"
        [ngClass]="{
          'teachers-journal-tooltip__header__status_complete': data.lessonData.passedTasks[data.indx],
          'teachers-journal-tooltip__header__status_accent': data.lessonData.isDiagnostic
        }"
      >
        {{ data.lessonData.passedTasks[data.indx] ? 'выполнено' : 'не выполнено' }}
      </span>
    </p>
    <p class="teachers-journal-tooltip__description" [class.teachers-journal-tooltip__description_accent]="data.lessonData.isDiagnostic">
      <span *ngIf="data.task.text">{{
        data.lessonData.orderNumber === AppSettingsService.settings.bashkortostanClosedLesson.teacherLessonNumber && isUserFromBashkortostan
          ? 'Задание недоступно с 20 марта'
          : data.task.text
      }}</span>
    </p>
  </prf-tooltip>
</ng-template>
