<ng-container *ngIf="userRole === 'parent' || userRole === 'pupil'">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section">
    <div class="container container-promo-results">
      <prf-page-header [title]="' '"></prf-page-header>
      <div class="header_buttons" *ngIf="!isMobile">
        <a class="header_button _button" (click)="routingAllCourses()">Партнеры</a>
        <a class="header_button _button" (click)="navigateNewTest()">Пройти тест</a>
        <a class="header_button _button" (click)="returnToResults()">Результаты теста</a>
      </div>
      <h1 class="page-title-h1">Узнайте свои таланты</h1>
      <div class="promotest-results">
        <ng-container *ngTemplateOutlet="promoAction"> </ng-container>
      </div>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>

<ng-template #promoAction>
  <div class="promoaction-container">
    <div class="container-1050">
      <section class="section-main-info">
        <div class="section-container">
          <div class="section-text">
            Акция «Узнай свои таланты» проходит для москвичей с детьми в возрасте от 7-ми до 17-ти лет. В рамках Акции дети и родители
            смогут пройти интерактивное тестирование талантов ребенка и получить рекомендации по подходящим образовательным курсам, на
            которые партнеры предоставят особые условия.<br /><br />Тестирование талантов SmartHumans было разработано компанией «Профилум»,
            разработчик диагностики имеет положительные рецензии РАО (Российской Академии Образования) и помог более 800 000 ребят выбрать
            будущую профессию. Тест SmartHumans имеет интересную сюжетную линию: это диалог между родителем и персонажем-нейросетью. По
            результатам тестирования будет определено три самых ярких таланта ребенка и умная рекомендательная система выберет возможности
            для их развития в городе.
          </div>
          <div class="main-info-card">
            <img src="./profilum-assets/images/dashboard/promoaction-page/main_pic.svg" alt="Research development" />
          </div>
        </div>
      </section>
      <section class="section-partners">
        <div class="section-container">
          <div class="section-title">Партнеры акции</div>
          <div class="partners-grid-container">
            <div class="partners_upper">
              <div class="partner-item">
                <img
                  class="logo_make_school"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_make_school.png"
                  alt="Make School"
                />
              </div>
              <div class="partner-item">
                <img
                  class="logo_rss_inet"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_rss_inet.png"
                  alt="Russian Chess School"
                />
              </div>
              <div class="partner-item">
                <img class="logo_da_bro" src="./profilum-assets/images/dashboard/promoaction-page/logo_da_bro.png" alt="Da'bro" />
              </div>
              <div class="partner-item">
                <img
                  class="logo_TSSK"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_TSSK.png"
                  alt="Theater of storytelling Konstantin Kozhevnikov"
                />
              </div>
              <div class="partner-item">
                <img
                  class="logo_ozone_acad"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_ozone_acad.png"
                  alt="Ozon academy"
                />
              </div>
              <div class="partner-item">
                <img
                  class="logo_school_creative"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_school_creative.png"
                  alt="Online school of creativity cids"
                />
              </div>
              <div class="partner-item">
                <img
                  class="logo_prosveshenie"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_prosveshenie.png"
                  alt="Prosvesheniye"
                />
              </div>
            </div>
            <div class="partners_left">
              <div class="partner-item">
                <img class="logo_uteens" src="./profilum-assets/images/dashboard/promoaction-page/logo_uteens.png" alt="Uteens" />
              </div>
              <div class="partner-item">
                <img class="logo_skysmart" src="./profilum-assets/images/dashboard/promoaction-page/logo_skysmart.png" alt="Skysmart" />
              </div>
              <div class="partner-item">
                <img class="logo_DTM" src="./profilum-assets/images/dashboard/promoaction-page/logo_DTM.png" alt="Moscow kids techoparks" />
              </div>
              <div class="partner-item">
                <img
                  class="logo_tumo_moscow"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_tumo_moscow.png"
                  alt="Tumo Moscow"
                />
              </div>
              <div class="partner-item">
                <img class="logo_kidzania" src="./profilum-assets/images/dashboard/promoaction-page/logo_kidzania.png" alt="Kidzania" />
              </div>
              <div class="partner-item">
                <img
                  class="logo_kids_blogging"
                  src="./profilum-assets/images/dashboard/promoaction-page/logo_kids_blogging.png"
                  alt="Jids Blogging School"
                />
              </div>
            </div>

            <div class="support-container">
              <div class="support-content section-container">
                <div class="section-title">При поддержке</div>
                <div class="support-grid-container">
                  <div class="support-item">
                    <img src="./profilum-assets/images/dashboard/promoaction-page/support_mosinno.png" alt="Moscow innovations" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear_float"></div>
      </section>
      <section class="section-action-rules">
        <div class="section-container">
          <div class="section-title">Условия Акции</div>
          <div class="section-text">
            Акция «Узнай свои таланты» проходит для москвичей с детьми в возрасте от 7-ми до 17-ти лет. <br />Более 200 образовательных
            курсов города доступны со скидкой или бесплатно. <br />Партнёры Акции берут на себя ответственность за качество оказываемых в
            рамках Акции услуг.
          </div>
          <a class="_agreement" href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank">Пользовательское соглашение</a>
        </div>
      </section>
    </div>
  </div>
</ng-template>
