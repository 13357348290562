<div class="add-achievement-lb lb show" *ngIf="show">
  <div (click)="hideModal()" class="lb-bg"></div>

  <div class="lb-block-achievement">
    <a (click)="hideModal()" class="close-lb w-inline-block">
      <img src="./profilum-assets/images/icons/close-lb.svg" />
    </a>

    <h3 class="h3-add-achievement">{{ 'SHARED.ADD_ACHIEVEMENTS' | translate }}</h3>

    <div class="w-form">
      <form id="email-form" name="email-form" data-name="Email Form">
        <div class="add-achievement-lb-row">
          <div class="add-achievement-column">
            <p class="add-child-text">
              {{
                userRole === 'pupil'
                  ? ('SHARED.ADD_ACHIEVEMENTS_PLACEHOLDER' | translate)
                  : ('SHARED.ADD_ACHIEVEMENTS_PUPIL_PLACEHOLDER' | translate)
              }}
            </p>

            <div class="add-achievement-row">
              <label class="add-achievement-label">Тип:</label>
              <div class="add-achieve-column">
                <div #typesSelector class="add-achieve-dropdown w-dropdown">
                  <div (click)="openSelectType()" class="add-achieve-dropdown-toggle w-dropdown-toggle">
                    <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                    <div>{{ selectedType.name }}</div>
                  </div>
                  <nav [class.w--open]="isOpenSelectType" class="dd-add-achieve w-dropdown-list">
                    <div class="dd-prof-filter-div">
                      <a
                        *ngFor="let type of typeList"
                        (click)="selectType(type)"
                        [class.w--current]="type === selectedType"
                        class="add-achievement-dd-link w-dropdown-link"
                      >
                        {{ type.name }}
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div class="add-achievement-row">
              <label class="add-achievement-label _2">Название:</label>
              <div class="add-achieve-column">
                <textarea
                  #textfield
                  (keyup)="textChange(textfield)"
                  id="field"
                  name="field"
                  maxlength="60"
                  rows="5"
                  class="add-achieve-field w-input"
                  >{{ file.name }}</textarea
                >
              </div>
            </div>
            <div class="add-achievement-row">
              <label class="add-achievement-label">Файл:</label>

              <div *ngIf="!file.file" class="add-achieve-column">
                <a (click)="selectFile()" class="button add-file w-button">Прикрепить</a>
                <input type="file" [accept]="acceptedFileFormats" hidden #fileInput (change)="onSelectFile($event)" />
              </div>

              <div *ngIf="file.file" class="add-achieve-column">
                <div class="attached-file-row-2">
                  <div class="attach-icon-div">
                    <img src="./profilum-assets/images/icons/attached-file-icon-2.svg" alt="" />
                  </div>
                  <div class="attached-file-title">{{ file.file.name }}</div>
                  <div *ngIf="!editMode" class="attached-file-column">
                    <a (click)="file.file = ''" class="delete-attachment w-inline-block">
                      <img src="./profilum-assets/images/icons/delete.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <prf-portfolio-card [file]="file" [fixSize]="true" [editable]="false"></prf-portfolio-card>
        </div>

        <input
          *ngIf="!editMode"
          (click)="addPortfolio()"
          [class.no-active]="!file.file"
          type="submit"
          value="{{ 'SHARED.ADD_ACHIEVEMENT' | translate }}"
          class="button add-achieve-btn w-button"
        />

        <div *ngIf="editMode" class="achieve-buttons-row">
          <a (click)="clickSave()" class="button save-achieve w-button no-active">Сохранить</a>
          <a (click)="clickDelete()" class="button white-button save-achieve w-button">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </form>

      <div class="w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div class="w-form-fail">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  </div>
</div>
