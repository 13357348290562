<div #recommendationMenu class="dropdown-recommendations w-dropdown">
  <div (click)="openRecommendationMenu()" [class.green]="recommendedAll" class="button event-page-btn w-dropdown-toggle">
    <div *ngIf="!recommendedAll">{{ 'SHARED.RECOMMEND' | translate }}</div>
    <div *ngIf="recommendedAll">Рекомендация отправлена</div>
  </div>

  <nav [class.w--open]="isOpen" class="recommendations-students-list w-dropdown-list">
    <div *ngIf="!userList || userList.length === 0" class="notice-list-container">
      <img src="./profilum-assets/images/icons/triangle.png" width="28" alt="" class="triangle teacher-recom" />
      <div class="notice-title recom" style="padding-bottom: 20px">Сначала добавьте ребенка</div>
    </div>

    <div *ngIf="userList && userList.length > 0" class="notice-list-container">
      <img src="./profilum-assets/images/icons/triangle.png" width="28" alt="" class="triangle teacher-recom" />
      <div class="notices-block recom">
        <div class="notices-div-block">
          <div class="notice-title recom">{{ 'SHARED.RECOMMEND' | translate }}</div>

          <div *ngIf="userList && userList.length > 3" class="students-list-form-search w-form">
            <form id="email-form" name="email-form" data-name="Email Form">
              <div class="search-block">
                <input
                  #inputSearch
                  (keyup)="onInputSearchText($event)"
                  type="text"
                  class="search-categories w-input"
                  maxlength="256"
                  name="Search-2"
                  placeholder="{{ 'SHARED.SEARCH' | translate }}"
                />
                <a
                  (click)="clearSearch(inputSearch)"
                  [class.show]="searchText && searchText.length > 0"
                  class="search-clear w-inline-block"
                ></a>
              </div>
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>

          <div class="notices-scroll">
            <div class="recom-students-list">
              <a
                *ngIf="userListFiltered && userListFiltered.length > 3 && !recommendedAll"
                [class.active]="selectedAll"
                (click)="onCheckAll()"
                class="recom-student-checkbox w-inline-block"
              >
                <div class="student-checkbox-photo">
                  <img src="./profilum-assets/images/icons/all-studs.svg" alt="" class="student-checkbox-photo-img" />
                </div>

                <div class="student-checkbox-name all">Всем</div>
                <div [class.checked]="selectedAll" class="checkbox-div"></div>
              </a>
              <a
                *ngFor="let user of userListFiltered; let index = index"
                [class.active]="user._recommended"
                (click)="onCheckChild(user)"
                class="recom-student-checkbox w-inline-block"
              >
                <div class="student-checkbox-photo">
                  <img [attr.src]="user.imagePath" alt="" class="student-checkbox-photo-img" />
                </div>
                <div class="student-checkbox-name">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</div>
                <div [class.checked]="user._recommended" [class.no-active]="user.recommended" class="checkbox-div"></div>
              </a>
            </div>
          </div>
          <!--<div class="notice-gradient white"></div>-->
        </div>

        <a (click)="recommendProfession()" [class.no-active]="!selectedAny" class="button notice-button w-button">
          {{ 'SHARED.SEND_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </nav>
</div>

<div *ngIf="isRecommended && !recommendedAll" class="recommendations-sent">Рекомендации отправлены</div>
