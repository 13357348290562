import { Component, inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, finalize, map, retry, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { Observable, of } from 'rxjs';
import {
  ApiAuthService,
  ApiCoursesMaterialsService,
  AppSettingsService,
  B2gSaasService,
  EUserTags,
  IData,
  IUserInfo,
  ResultsTestService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { PlayerService } from '../../player/player.service';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { ApiCalculatorService } from '@profilum-api-services/api-calculator/api-calculator.service';

@Component({
  selector: 'prf-results-switcher',
  templateUrl: './results-switcher.component.html',
  styleUrls: ['./results-switcher.component.scss'],
})
export class ResultsSwitcherComponent extends UnsubscribeComponent implements OnInit {
  private testType: string = '';
  private userRole: string = '';
  private userData: IUserInfo;
  private sessionId: string = '';
  private promoTag: string = ''; // метка для определения участия пользователя в промо акции
  private isProfessionsTryOn: boolean = false;
  private overlayBusyService: OverlayBusyService = inject(OverlayBusyService);
  private apiCalculatorService: ApiCalculatorService = inject(ApiCalculatorService);

  constructor(
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
    private userActionsService: UserActionsService,
    private apiAuthService: ApiAuthService,
    private b2gSaasService: B2gSaasService,
    private playerService: PlayerService,
    private userDataHandlerService: UserDataHandlerService,
    private resultsTest: ResultsTestService,
  ) {
    super();
    this.testType = webStorageService.get(StorageKeys.TestType)?.toString();
    this.userData = this.userDataHandlerService.getUserInfo();
    this.userRole = this.userData.role;
    this.promoTag = webStorageService.get(StorageKeys.PromoTag)?.toString();
    this.isProfessionsTryOn = webStorageService.get(StorageKeys.ProfessionsTryOn);
  }

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          if (params['ssid']) {
            this.sessionId = params['ssid'];
          } else {
            this.router.navigate(['/404']);
          }

          return this.calculateSessionResults();
        }),
        switchMap(() => {
          if (this.userRole === 'parent') {
            if (this.testType === ETestTypes.PROMO_TEST.toString()) {
              if (this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString()) {
                this.router.navigate(['/vorobievi-gori/promotest-results/' + this.sessionId]);
              } else {
                this.router.navigate(['/promotest-results/' + this.sessionId]);
              }
            } else {
              const refferalUserId = this.webStorageService.get(StorageKeys.RefferalUserId);
              this.router.navigate(['/parent/' + refferalUserId + '/testResult']);
            }
            return of(null);
          } else {
            if (this.userRole === 'pupil') {
              this.resultsTest.setResultsTestId();
            }
            switch (this.testType) {
              case ETestTypes.DEFAULT_STANDART.toString():
                this.yandexMetricsService.reachGoal(YmItems.PUPIL_TEST_FINISHED);
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                this.router.navigate(['/' + this.userRole]);
                break;
              case ETestTypes.DEFAULT_360.toString():
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                this.router.navigate(['/' + this.userRole]);
                break;
              case ETestTypes.PROFESSIONPRESTIGE.toString():
                this.router.navigate(['/results-profession-prestige']);
                break;
              case ETestTypes.MOTIVATION_TEST.toString():
                this.router.navigate(['/motivation-test-results/' + this.sessionId]);
                break;
              case ETestTypes.VALUES_TEST.toString():
                this.router.navigate(['/values-test-results/' + this.sessionId]);
                break;
              case ETestTypes.MINCIFRYDEMO_TEST.toString():
                this.router.navigate(['/mincifrydemotest-results/' + this.sessionId]);
                break;
              case ETestTypes.BVB_TEST.toString():
                this.apiAuthService
                  .logout()
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe(() => {
                    this.userDataHandlerService.logout();
                    window.location.href = AppSettingsService.settings.bvbLkStudentUrl;
                  });
                break;
              case ETestTypes.BVB_TEST_VISUALLY_IMPAIRED.toString():
                this.router.navigate(['/bvb-test-visually-impaired-results/' + this.sessionId]);
                break;
              case ETestTypes.VK_TEST.toString():
                this.router.navigate(['/vk-test-results/' + this.sessionId]);
                break;
              case ETestTypes.CAMP_TEST.toString():
                this.router.navigate(['/camp-test-results/' + this.sessionId]);
                break;
              case ETestTypes.MINCIFRY_TEST.toString():
                this.router.navigate(['/mincifry-test-results/' + this.sessionId]);
                break;
              case ETestTypes.WEB_TEST.toString():
                this.router.navigate(['/web-test-results/' + this.sessionId]);
                break;
              case ETestTypes.INTERESTS_TEST.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .pipe(
                    finalize(() => {
                      const lastSessionId = this.webStorageService.get(StorageKeys.LastSessionId);
                      if (lastSessionId) {
                        this.webStorageService.set(StorageKeys.LastSessionId, null);
                      }
                      if (this.isProfessionsTryOn) {
                        this.router.navigate(['/passed-diagnostics-interests']);
                      } else {
                        this.router.navigate(['/results-diagnostic/interests']);
                      }
                    }),
                    takeUntil(this.unsubscribe),
                  )
                  .subscribe(() => this.yandexMetricsService.reachGoal(YmItems.S_lessons_CompleteExercise));
                break;
              case ETestTypes.CAPABILITIES_TEST.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .pipe(
                    finalize(() => {
                      const lastSessionId = this.webStorageService.get(StorageKeys.LastSessionId);
                      if (lastSessionId) {
                        this.webStorageService.set(StorageKeys.LastSessionId, null);
                      }
                      if (this.isProfessionsTryOn) {
                        this.router.navigate(['/passed-diagnostics-capabilities']);
                      } else {
                        this.router.navigate(['/results-diagnostic/capabilities']);
                      }
                    }),
                    takeUntil(this.unsubscribe),
                  )
                  .subscribe(() => this.yandexMetricsService.reachGoal(YmItems.S_lessons_CompleteExercise));
                break;
              case ETestTypes.OUTPUT_DIAGNOSTIC.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .subscribe(() => this.router.navigate(['/results-diagnostic/output-diagnostic']));
                break;
              case ETestTypes.CAREER_LITERACY_TEST.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .pipe(
                    finalize(() => {
                      const lastSessionId = this.webStorageService.get(StorageKeys.LastSessionId);
                      const showResultsWithCards = this.webStorageService.get(StorageKeys.OnlyForward);
                      if (lastSessionId) {
                        this.webStorageService.set(StorageKeys.LastSessionId, null);
                      }
                      if (showResultsWithCards) {
                        this.router.navigate(['/career-results-with-cards']);
                      } else {
                        this.router.navigate(['/career-results/' + this.sessionId]);
                      }
                    }),
                    takeUntil(this.unsubscribe),
                  )
                  .subscribe();
                break;
              case ETestTypes.MOBILE_TEST.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .pipe(
                    finalize(() => {
                      const lastSessionId = this.webStorageService.get(StorageKeys.LastSessionId);
                      if (lastSessionId) {
                        this.webStorageService.set(StorageKeys.LastSessionId, null);
                      }
                      this.router.navigate(['/pupil-profile']);
                    }),
                    takeUntil(this.unsubscribe),
                  )
                  .subscribe();
                break;
              case ETestTypes.DIAGNOSTIC_CAREER_TYPE.toString():
                this.apiCoursesMaterialsService
                  .setPassed(
                    this.userData.userId,
                    this.webStorageService.get(StorageKeys.CourseId),
                    this.webStorageService.get(StorageKeys.LessonId),
                    this.webStorageService.get(StorageKeys.SchoolClassId),
                    this.webStorageService.get(StorageKeys.TaskId),
                    true,
                  )
                  .pipe(
                    finalize(() => {
                      this.webStorageService.set(StorageKeys.IdDiagnosticByCareerType, this.sessionId);
                      this.router.navigate(['/chat']);
                    }),
                    takeUntil(this.unsubscribe),
                  )
                  .subscribe();
                break;
              default:
                if (this.userRole == 'pupil' && environment.analytics) {
                  this.yandexMetricsService.reachGoal(YmItems.PUPIL_TEST_FINISHED);
                }
                this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
                this.webStorageService.set(StorageKeys.SessionStatus, 'Success');
                setTimeout(() => {
                  // редирект на начало теста через браузерную кнопку "назад"
                  switch (this.webStorageService.get(StorageKeys.RedirectTestType)) {
                    case ETestTypes.VALUES_TEST.toString():
                      this.webStorageService.clear(StorageKeys.RedirectTestType);
                      return this.router.navigate(['/values-test']);
                    default:
                      this.router.navigate(['/' + this.userRole]);
                  }
                }, 1000);
                break;
            }
            return of(null);
          }
        }),
      )
      .subscribe(() => {
        const diagnostic = this.playerService.currentTest.getValue();
        const uaParams: IData = { Diagnostic: diagnostic };
        const ymParams: IData = {
          event_label: {
            Diagnostic_Name: diagnostic?.name,
            Diagnostic_Id: diagnostic?.id,
          },
        };
        this.userActionsService.log(YmItems.S_Lessons_Diagnostics_Done, uaParams, ymParams);
      });
  }

  private calculateSessionResults = (): Observable<boolean> => {
    this.overlayBusyService.show();
    return this.apiCalculatorService.calculateSessionResults(this.sessionId).pipe(
      map(response => response.status === 'Success'),
      retry({ count: 3, delay: 2000 }),
      take(1),
      filter(isCalculated => isCalculated),
      tap(() => this.overlayBusyService.hide()),
    );
  };
}
