<div class="student-page-section">
  <div class="student-page-row header-info-row">
    <div class="flex-row">
      <div
        class="student-page-photo"
        [ngStyle]="{
          'background-image': pupil.imagePath ? 'url(' + pupil.imagePath + ')' : 'url(/profilum-assets/images/icons/no-photo-child.svg)'
        }"
      ></div>
      <div class="student-page-name">{{ pupil.firstName }} {{ pupil.lastName }}</div>
    </div>
    <div *ngIf="pupil" class="student-info-tab">
      <div class="student-info-container">
        <div class="student-info-row">
          <span class="student-info-name _1">Почта ученика: </span><span class="student-info-value">{{ pupil.email }}</span>
        </div>
        <div class="student-info-row">
          <span class="student-info-name _2">Код регистрации: </span><span class="student-info-value">{{ pupil.registrationCode }}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="parent-menu">
      <div class="parent-menu-row-2">
        <a
          *ngFor="let menuItem of detailsMenu"
          (click)="selectMenuItem(menuItem)"
          [class.w--current]="menuItem === selectedMenuItem"
          class="parent-menu-link"
          >{{ menuItem.name }}</a
        >
      </div>
    </div>

    <div class="tab-pane-parent">
      <ng-container *ngIf="selectedMenuItem.key === 'professions'">
        <div class="tabs-professions">
          <div class="tab-menu-professions" *ngIf="favoriteProfessions.length > 0">
            <a
              class="tabs-professions-link"
              *ngFor="let profHead of favoriteProfessions | slice : 0 : 2"
              [class.w--current]="activeProfession === profHead"
              (click)="setActiveProfession(profHead)"
              >{{ profHead.name }}</a
            >

            <div
              class="menu-professions-dropdown w-dropdown"
              *ngIf="favoriteProfessions && favoriteProfessions.length > 2"
              (click)="nav.classList.toggle('w--open')"
            >
              <div class="professions-dd-toggle w-dropdown-toggle">
                <div class="professions-dd-icon w-icon-dropdown-toggle"></div>
                <div>{{ 'SHARED.YET' | translate }}</div>
              </div>
              <nav class="dropdown-list w-dropdown-list" #nav>
                <div class="professions-dd-bg">
                  <a
                    class="professions-dd-link w-dropdown-link"
                    *ngFor="let profMenu of favoriteProfessions | slice : 2"
                    [class.w--current]="activeProfession === profMenu"
                    (click)="setActiveProfession(profMenu)"
                    >{{ profMenu.name }}</a
                  >
                </div>
              </nav>
            </div>
          </div>

          <prf-detailed-profession-card
            *ngIf="activeProfession; else noFavorites"
            [profession]="activeProfession"
          ></prf-detailed-profession-card>
          <ng-template #noFavorites>
            <prf-not-selected-profession [name]="pupil.firstName"></prf-not-selected-profession>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'results'">
        <prf-student-test-results [pupil]="pupil"></prf-student-test-results>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'motivation'">
        <div class="test-history-block">
          <div *ngIf="motivationLastSessionId; else notPassedMotivation" class="test-results">
            <prf-motivation-results
              [userRole]="userRole"
              [pupil]="pupilInfo"
              [sessionId]="motivationLastSessionId"
            ></prf-motivation-results>
          </div>
        </div>
      </ng-container>

      <ng-template #notPassedMotivation>
        <div class="no-test-block">
          <div>
            <img src="./profilum-assets/images/icons/no-test-icon.png" width="80" class="no-profession-icon" />
          </div>
          <div class="empty-block-text">
            {{ 'SHARED.NOT_PASSED_MOTIVATION_TEST' | translate }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="selectedMenuItem.key === 'favorites'">
        <prf-user-favorites [user]="pupil" [favoritesList]="favoritesClasses"></prf-user-favorites>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'portfolio'">
        <prf-portfolio-page
          [child]="pupil"
          [childPortfolios]="portfolios"
          (_childPortfolios)="portfoliosChange($event)"
        ></prf-portfolio-page>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'profile'">
        <prf-user-profile-shared [user]="pupil"></prf-user-profile-shared>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'history'">
        <prf-pupil-tests-history [pupil]="pupil" [sessionList]="sessionList"></prf-pupil-tests-history
      ></ng-container>
    </div>
  </div>
</div>
