import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { of, zip } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  ApiCoursesMaterialsService,
  AppSettingsService,
  EmptyGuid,
  IData,
  ILessonActivity,
  IPassedLessons,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { TippyDirective } from '@ngneat/helipopper';

import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import {
  IAssigningCourseData,
  ICourseMaterialExt,
  ILessonMaterial,
} from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import {
  TeachersJournalService,
} from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import { ILessonResultsCount } from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { SharedModule } from '../../../shared.module';
import { ModalHandlerService } from '../../../../ui-kit/services/modal-handler/modal-handler.service';
import { ClassCoursesModule } from '../class-courses.module';

@Component({
  selector: 'prf-teacher-lessons',
  standalone: true,
  templateUrl: './teacher-lessons.component.html',
  styleUrls: ['./teacher-lessons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgForOf, TitleCasePipe, SharedModule, ClassCoursesModule, TooltipComponent, TippyDirective],
})
export class TeacherLessonsComponent extends UnsubscribeComponent implements OnInit {
  public lessonsInfo: ILessonMaterial[];
  public hasPassedLesson: boolean = false;
  public showOpenLessonPopup: boolean = false;
  public userRestriction: boolean = false;
  // todo скрывает последующие уроки в рамках задачи SITE-7489 (см. комментарии), нужно будет выпилить
  public activeLessonIds: string[] = [];
  public notPassedLessons: string = '';
  public isMultipleLessonNotPassed: boolean = false;
  public selectedLesson: ILessonMaterial;
  public schoolClassId: string;
  public pupilCount: number;
  public dictionary: DictionaryType;
  public recommendedLessonDateIndex: number = 0;
  public MonthList: string[] = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];
  private readonly teacherId: string;
  private courseId: string;
  public course: ICourseMaterialExt;
  private passedLessons: IPassedLessons[];
  private lessonResultsCount: ILessonResultsCount;
  private currentClassInfo: IAssigningCourseData;
  public chosenLesson: ILessonMaterial;
  public disableLessonsInTeacherLK = false;
  public isUserFromBashkortostan = false;
  public userRegion: string;
  protected readonly AppSettingsService = AppSettingsService;

  @ViewChild('materialsModalTemplate') public materialsModalTemplate: TemplateRef<any>;
  @ViewChild('lessonsList') public lessonsList: ElementRef;

  constructor(
    private router: Router,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilsService: UtilsService,
    private overlayBusyService: OverlayBusyService,
    private ramStorageService: RamStorageService,
    private webStorageService: WebStorageService,
    private teachersJournalService: TeachersJournalService,
    private rootScopeService: RootScopeService,
    private userActionsService: UserActionsService,
    private modalHandlerService: ModalHandlerService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.dictionary = rootScopeService.getDictionary();
    this.teacherId = webStorageService.get(StorageKeys.TeacherId);
    this.userRestriction = userDataHandlerService.isUserRestricted();
    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Lessons);
    this.userActionsService.setInitializationTime([YmItems.T_Lessons_AccessToLesson, YmItems.T_Lessons_Open, YmItems.T_Lessons_Download]);
    this.userRegion = this.userDataHandlerService.getUserInfo().regionId;
    this.isUserFromBashkortostan =
      this.userDataHandlerService.getUserInfo().regionId === AppSettingsService.settings.bashkortostanClosedLesson.regionId;
    this.disableLessonsInTeacherLK =
      AppSettingsService.settings.disableLessonsInTeacherLK &&
      (AppSettingsService.settings.disableLessonsInTeacherLKInRegions ?? []).includes(this.userRegion);
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.rootScopeService.currentCLass
      .pipe(
        filter(v => v !== null),
        switchMap((currentClassInfo: IAssigningCourseData) => {
          this.currentClassInfo = currentClassInfo;
          if (currentClassInfo && currentClassInfo.course) {
            this.router.navigate(['/teacher-lessons', currentClassInfo.schoolClass.id]);
          } else {
            this.router.navigate(['/class-diagnostic', currentClassInfo.schoolClass.id]);
            return of(null);
          }
          this.courseId = (this.currentClassInfo.course as ICourseMaterialExt).id;

          this.overlayBusyService.show();

          this.schoolClassId = this.currentClassInfo.schoolClass?.id ?? this.currentClassInfo.schoolClass;

          if (!this.schoolClassId && !this.teacherId) {
            this.overlayBusyService.hide();
            this.router.navigate(['/teacher']);
            return of(null);
          }

          return this.teachersJournalService.getLessons(this.schoolClassId, [this.courseId]);
        }),
        filter(mappedLessons => mappedLessons !== null),
        tap(mappedLessons => {
          this.pupilCount = mappedLessons.length;
        }),
        switchMap(() =>
          zip([
            this.teacherClassCoursesService.getCourseMaterialWithPassedData(this.courseId, this.currentClassInfo.schoolClass),
            this.teachersJournalService.lessonResultsCount$,
          ]),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        ([course, lessonResultsCount]) => {
          this.activeLessonIds = [];
          this.recommendedLessonDateIndex = 0;

          this.passedLessons = course.passedLessons;
          this.course = course;
          this.hasPassedLesson = this.teacherClassCoursesService.hasPassedLesson(this.course);
          let parsed = [];

          this.course.parsedLessons?.forEach(chapter => {
            this.activeLessonIds = this.activeLessonIds.concat(
              chapter[1]?.lessons?.filter(el => el.hasLinkedTaskForPupil && !el.passed).map(lesson => lesson.id) ?? [],
            );
          });

          this.course.parsedLessons.forEach(parsedLesson => {
            parsed = [...parsed, ...parsedLesson[1].lessons];
          });
          this.lessonsInfo = parsed;
          const currentDate = new Date();

          this.lessonsInfo.forEach((lessonInfo: ILessonMaterial) => {
            if (lessonInfo.recommendedDate) {
              const recommendedDate: Date = new Date(lessonInfo.recommendedDate);
              lessonInfo.date = recommendedDate?.getDate();
              lessonInfo.month = this.MonthList[recommendedDate.getMonth()];

              if (recommendedDate.getTime() - currentDate.getTime() < 0) {
                ++this.recommendedLessonDateIndex;
              }
            }
          });
          this.lessonResultsCount = lessonResultsCount;
          this.changeDetectorRef.detectChanges();
          this.overlayBusyService.hide();

          this.scrollToIndex(this.recommendedLessonDateIndex);
        },
        error => {
          this.overlayBusyService.hide();
          this.utilsService.openSnackBar('Неполадки на сервере', 'error');
          this.timer = window.setTimeout(() => {
            this.utilsService.closeSnackBar();
          }, 5000);
          this.router.navigate(['/teacher']);
        },
      );
  }

  public showPopup(lesson: ILessonMaterial): void {
    if (lesson.id !== this.activeLessonIds[0]) {
      let i: number = 0;

      while (lesson.id !== this.activeLessonIds[i]) {
        this.notPassedLessons += this.lessonsInfo.find(notPassedLesson => notPassedLesson.id === this.activeLessonIds[i]).number + ', ';
        i++;
      }

      this.notPassedLessons = this.notPassedLessons.slice(0, -2);
      this.isMultipleLessonNotPassed = this.notPassedLessons.includes(',');
    }

    this.showOpenLessonPopup = true;
    this.selectedLesson = lesson;
  }

  public complete(): void {
    this.showOpenLessonPopup = false;
    this.notPassedLessons = '';
    this.activeLessonIds.splice(0, this.activeLessonIds.indexOf(this.selectedLesson.id) + 1);

    this.completeLesson(this.selectedLesson, true);
  }

  public cancel(): void {
    this.showOpenLessonPopup = false;
    this.notPassedLessons = '';
  }

  public trackByFn(index: number, item: ILessonMaterial): string {
    return item.id;
  }

  public isActive(lesson: ILessonMaterial): boolean {
    const prevPassedLesson = this.passedLessons.find(passedLesson => passedLesson.orderNumber === lesson.number - 1);

    return !prevPassedLesson || prevPassedLesson.isPassed;
  }

  public getPassed(lessonId: string): number {
    return this.lessonResultsCount[lessonId] ? this.lessonResultsCount[lessonId][0] : 0;
  }

  private completeLesson(lesson: ILessonMaterial, isPassed: boolean): void {
    const lessonActivities: ILessonActivity[] = [];

    const index = this.lessonsInfo.findIndex(les => {
      if (les.hasLinkedTaskForPupil && !les.passed) {
        lessonActivities.push({
          userId: this.teacherId,
          courseId: this.courseId,
          lessonId: les.id,
          schoolClassId: this.schoolClassId,
          taskId: EmptyGuid,
          isPassed,
        });
      }

      return les.id === lesson.id;
    });

    this.apiCoursesMaterialsService
      .setMultipleTaskPassedTemp(lessonActivities)
      .pipe(take(1))
      .subscribe(
        () => {
          if (isPassed) {
            const uaParams: IData = {
              LessonNumber: lesson.number,
              SchoolClassId: this.schoolClassId,
            };

            const ymParams: IData = {
              event_label: { Lesson_Number: lesson.number },
            };

            this.userActionsService.log(YmItems.T_Lessons_AccessToLesson, uaParams, ymParams);
            this.hasPassedLesson = true;
          }

          for (let i = 0; i <= index; i++) {
            if (this.lessonsInfo[i].hasLinkedTaskForPupil) {
              this.lessonsInfo[i].passed = isPassed;
            }
          }

          this.passedLessons.forEach(passedLesson => {
            lessonActivities.forEach(activity => {
              if (passedLesson.id === activity.lessonId) {
                passedLesson.isPassed = isPassed;
              }
            });
          });

          this.updatePassedLessons(lessonActivities);
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.utilsService.openSnackBar('Неполадки на сервере, уже чиним', 'error');

          this.timer = window.setTimeout(() => {
            this.utilsService.closeSnackBar();
          }, 5000);
        },
      );
  }

  private updatePassedLessons(newPassedLessons: ILessonActivity[]): void {
    this.rootScopeService.currentCLass.pipe(takeUntil(this.unsubscribe)).subscribe((rootScopedCurrentClass: IAssigningCourseData) => {
      const newCurrentClassPassedLessons = (rootScopedCurrentClass.course as ICourseMaterialExt).passedLessons;
      newPassedLessons.forEach(newPassedLesson => {
        newCurrentClassPassedLessons.find(passedLesson => passedLesson.id === newPassedLesson.lessonId).isPassed = true;
      });

      this.webStorageService.set(StorageKeys.NewPassedLessons, newCurrentClassPassedLessons);
    });
  }

  public navigateBack(): void {
    this.router.navigate(['/teacher']);
  }

  private scrollToIndex(index: number) {
    const listItem: HTMLElement | null = this.lessonsList?.nativeElement.children[index];
    if (listItem) {
      listItem.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  public openMaterialsModal(lesson: ILessonMaterial): void {
    this.chosenLesson = lesson;
    this.modalHandlerService.sideModalTemplate.next(this.materialsModalTemplate);
    this.modalHandlerService.setConfirmButton('');
    this.modalHandlerService.toggleSideModal(true);
  }

  public sendMaterialsMetric(lesson: ILessonMaterial, type: string): void {
    const uaParams: IData = {
      LessonNumber: lesson.number,
      SchoolClassId: this.schoolClassId,
    };

    const ymParams: IData = {
      event_label: { Lesson: lesson.number },
    };

    switch (type) {
      case 'open':
        this.userActionsService.log(YmItems.T_Lessons_Open, uaParams, ymParams);
        break;

      case 'download':
        this.userActionsService.log(YmItems.T_Lessons_Download, uaParams, ymParams);
        break;

      default:
        break;
    }
  }
}
