import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {
  public sortFilterArray(array: any[], sortDirection: boolean): void {
    if (sortDirection) {
      array.sort(function (a, b) {
        return b.count - a.count;
      });
    } else {
      array.sort(function (a, b) {
        return a.count - b.count;
      });
    }
  }

  public dictionaryToChartConfig(object) {
    let result = Object.keys(object).map(k => {
      return {
        label: k,
        percent: undefined,
        count: object[k],
      };
    });

    result = result.filter(r => r.count != 0).sort((a, b) => (a.label && !b.label ? -1 : !a.label && b.label ? 1 : 0));

    return result;
  }

  public objectToChartConfig(object) {
    let result = Object.keys(object).map(k => {
      return {
        label: k,
        percent: object[k].percent,
        count: object[k].count,
      };
    });

    result = result.filter(r => r.count != 0).sort((a, b) => (a.label && !b.label ? -1 : !a.label && b.label ? 1 : 0));

    return result;
  }

  public getSortedFilterArray(educationFilter: string, { wantedItems, visitedItems, subjectsRepresentation }): any[] {
    let result: string[] = [];

    // здесь добавить фильтр отдельный для муниципального графика
    switch (educationFilter) {
      case 'CHARTS.REPRESENTATIONS_OF_TOPICS.FULL.UP':
        this.sortFilterArray(subjectsRepresentation, false);
        result = Array.from(
          new Set(
            subjectsRepresentation
              .map(c => c.label)
              .concat(
                visitedItems.map(c => c.label),
                wantedItems.map(c => c.label),
              ),
          ),
        );
        break;
      case 'CHARTS.REPRESENTATIONS_OF_TOPICS.FULL.DOWN':
        this.sortFilterArray(subjectsRepresentation, true);
        result = Array.from(
          new Set(
            subjectsRepresentation
              .map(c => c.label)
              .concat(
                visitedItems.map(c => c.label),
                wantedItems.map(c => c.label),
              ),
          ),
        );
        break;
      case 'CHARTS.WANT_TO_ATTEND.FULL.UP':
        this.sortFilterArray(wantedItems, false);
        result = Array.from(
          new Set(
            wantedItems
              .map(c => c.label)
              .concat(
                visitedItems.map(c => c.label),
                subjectsRepresentation.map(c => c.label),
              ),
          ),
        );
        break;
      case 'CHARTS.WANT_TO_ATTEND.FULL.DOWN':
        this.sortFilterArray(wantedItems, true);
        result = Array.from(
          new Set(
            wantedItems
              .map(c => c.label)
              .concat(
                visitedItems.map(c => c.label),
                subjectsRepresentation.map(c => c.label),
              ),
          ),
        );
        break;
      case 'CHARTS.VISIT_NOW.FULL.UP':
        this.sortFilterArray(visitedItems, false);
        result = Array.from(
          new Set(
            visitedItems
              .map(c => c.label)
              .concat(
                wantedItems.map(c => c.label),
                subjectsRepresentation.map(c => c.label),
              ),
          ),
        );
        break;
      case 'CHARTS.VISIT_NOW.FULL.DOWN':
        this.sortFilterArray(visitedItems, true);
        result = Array.from(
          new Set(
            visitedItems
              .map(c => c.label)
              .concat(
                wantedItems.map(c => c.label),
                subjectsRepresentation.map(c => c.label),
              ),
          ),
        );
        break;
      //  аналогично "Представленность тематик: по возрастанию"
      default:
        this.sortFilterArray(subjectsRepresentation, false);
        result = Array.from(
          new Set(
            subjectsRepresentation
              .map(c => c.label)
              .concat(
                visitedItems.map(c => c.label),
                wantedItems.map(c => c.label),
              ),
          ),
        );
    }
    return result;
  }
}
