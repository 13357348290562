<div class="group-card" (click)="showDetailGroup()">
  <div class="group-card__title">
    <div class="group-card__title-name">{{ _group.groupName }}</div>
    <div class="group-card__title-progress">
      <div
        class="group-card__title-progress-bar"
        [ngStyle]="{
          width:
            ((_group.progress.passed > _group.progress.total ? _group.progress.total : _group.progress.passed) * 100) /
              _group.progress.total +
            '%',
          background: _group.color
        }"
      ></div>
    </div>
    <div class="group-card_title-counter">
      <span class="group-card__title-counter-main"
        >{{ _group.progress.passed > _group.progress.total ? _group.progress.total : _group.progress.passed }} из
        {{ _group.progress.total }}</span
      >
      занятий пройдено
    </div>
  </div>
  <button class="group-card__button" type="button">Открыть</button>
</div>
