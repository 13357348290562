import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHandlerService } from 'app/ui-kit/services/modal-handler/modal-handler.service';
import { ButtonComponent } from '@profilum-components/button/button.component';
import { ButtonType } from '@profilum-collections/common.collections';

export interface ModalConfig {
  title: string;
  content: string;
  buttonTitle?: string;
  action?: string;
  secondaryButtonTitle?: string;
  additionalAction?: string;
  buttonType?: ButtonType;
  iconColor?: 'green' | 'blue';
}

@Component({
  selector: 'prf-center-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './center-modal.component.html',
  styleUrls: ['./center-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenterModalComponent {
  public modalConfig: ModalConfig;
  public buttonType: typeof ButtonType = ButtonType;

  @ViewChild('content', { read: ViewContainerRef }) content: ViewContainerRef;
  @ViewChild('footer', { read: ViewContainerRef }) footer: ViewContainerRef;

  constructor(private modalHandlerService: ModalHandlerService, private changeDetector: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.modalHandlerService.getCenterModalConfig().subscribe(config => {
      if (config) {
        this.modalConfig = config;
        this.changeDetector.detectChanges();
      }
    });
  }

  public closeModal(): void {
    this.modalHandlerService.toggleCenterModal(false);
  }

  public confirm(confirmation: string): void {
    this.modalHandlerService.setConfirmButton(confirmation);
    this.closeModal();
  }

  public additionalAction(confirmation: string): void {
    this.modalHandlerService.setConfirmButton(confirmation);
    this.closeModal();
  }
}
