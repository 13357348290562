import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from '../backend-services/utils.service';
import { ApiAuthService, UserDataHandlerService, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent extends UnsubscribeComponent implements OnInit {
  private userDataHandlerService = inject(UserDataHandlerService);

  constructor(
    private apiAuthService: ApiAuthService,
    private router: Router,
    private utilsService: UtilsService,
    private webStorageService: WebStorageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.apiAuthService
      .logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.userDataHandlerService.logout();
        this.webStorageService.clearAll();
        this.utilsService.openSnackBar('👌 Вы вышли из системы', 'success');
        this.router.navigate(['/']);
        setTimeout(() => {
          location.reload();
        }, 500);
      });
  }
}
