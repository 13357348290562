import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PrfLogo } from '../../../components/header/header.types';

@Component({
  selector: 'prf-footer-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './footer-logo.component.html',
  styleUrl: './footer-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLogoComponent {
  @Input() logo: PrfLogo;
}
