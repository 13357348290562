<div class="news-page-section">
  <div class="news-page-container">
    <div class="news-card-row article-page">
      <div class="news-card-date article-page">27 февраля в 10:31</div>
      <div class="news-card-author">{{ 'SHARED.DANCE_SCHOOL_TODES' | translate }}</div>
    </div>
    <h1 class="news-page-h1">Человек–«швейцарский нож»: какие навыки нужны для успеха</h1>
    <p class="article-subtitle">
      Сооснователь «Профилум» Анна Шайхутдинова, курирующая развитие сервиса при Лаборатории инноваций Гарвардского университета, пообщалась
      с Чарльзом Фаделем*, признанным экспертом в сфере образования. Рассказываем самое интересное из их беседы про навыки будущего.
    </p>
    <div class="article-content">
      <img
        src="./profilum-assets/images/illustrations/Bitmap-2.jpg"
        srcset="
          ./profilum-assets/images/illustrations/Bitmap-2-p-500.jpeg   500w,
          ./profilum-assets/images/illustrations/Bitmap-2-p-1080.jpeg 1080w,
          ./profilum-assets/images/illustrations/Bitmap-2.jpg         1400w
        "
        sizes="(max-width: 767px) 92vw, (max-width: 991px) 71vw, 70vw"
        class="article-image"
      />
      <p>
        <strong>— Знаю, что вы можете говорить на эту тему часами, но если взять конкретно, то какие навыки вы считаете </strong
        ><a href="#"><strong>критично важными</strong></a
        ><strong> для освоения сегодня?</strong>
      </p>
      <p>
        В моей книге я выделяю четыре измерения, в которых существуют навыки. Первое — под общим названием «Знания». То, что мы знаем и
        понимаем. Сюда относятся знания традиционные, вроде общей грамотности, математики и языков, и современные — например,
        предпринимательство, робототехника и тому подобное.
      </p>
      <p>
        Третье измерение включает любопытство, любознательность, внимательность, развитое мышление, этические установки,
        <a href="#">задатки лидера</a>, смелость и так далее. Я определяют этот компонент под общим словом «Характер».
      </p>
      <div style="padding-top: 56.17021276595745%" id="w-node-3bc3c659-ec70-e141-8a4e-f1cbba706306" class="article-video w-video w-embed">
        <iframe
          class="embedly-embed"
          src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FE4l5sXC3Lu0%3Ffeature%3Doembed&amp;url=http%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DE4l5sXC3Lu0&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FE4l5sXC3Lu0%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
          scrolling="no"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen="true"
        ></iframe>
      </div>
      <h2>Словом, в грядущем мире</h2>
      <p>
        В моей книге я выделяю четыре измерения, в которых существуют навыки. Первое — под общим названием «Знания». То, что мы знаем и
        понимаем. Сюда относятся знания традиционные, вроде общей грамотности, математики и языков, и современные — например,
        предпринимательство, робототехника и тому подобное.
      </p>
    </div>
  </div>
</div>
