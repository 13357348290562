import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-mincifrydemotest-results',
  templateUrl: './mincifrydemotest-results.component.html',
  styleUrls: ['./mincifrydemotest-results.component.scss'],
})
export class MincifrydemotestResultsComponent extends UnsubscribeComponent implements OnInit {
  public sessionId: string = null;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params && params['ssid']) {
        this.sessionId = params['ssid'];
      }
    });
  }
}
