import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { InternshipsFiltersService } from './internship-filter/internships-filter.service';
import { Subject } from 'rxjs';
import { ApiInternshipsService, IInternship } from '@profilum-library';

const PAGE_SIZE: number = 15;
const TRIGGER_SCROLL_BOTTOM: number = 350;

@Component({
  selector: 'prf-internship-catalog',
  templateUrl: './internship-catalog.component.html',
  styleUrls: ['./internship-catalog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InternshipCatalogComponent implements OnInit, OnDestroy {
  @Input() showRecommended: boolean = true;
  @Input() tabName: string;

  public loading: boolean = false;
  // public categoryList: Array<InternshipFilter> = [];
  public internshipList: Array<IInternship> = [];
  public internshipListFiltered: Array<IInternship> = [];
  public currentGroupElementsCount: number = PAGE_SIZE; // количество загруженных профессий в текущей группе
  public pageNumber: number = 0; // номер загруженной страницы
  public loadedAll: boolean = false;
  public loadedCategory: boolean = false;
  public DemandRatingAsc: boolean = false;
  titleName: string = 'стажировок';
  private filters: any = {};
  private demandRatingsByDesc = ['High', 'Medium', 'Low'];
  private demandRatingsByAsc = ['Low', 'Medium', 'High'];

  private maxDemandRating = 'High';
  private minDemandRating = 'Low';

  private ngUnsubscribe$ = new Subject<any>();

  constructor(private apiInternshipsService: ApiInternshipsService, private filtersService: InternshipsFiltersService) {
    this.getInternshipCatalog(PAGE_SIZE);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.tabName == 'catalog' && window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM) {
      if (!this.loading && !this.loadedAll) {
        this.loadMore();
      }
    }
  }

  ngOnInit() {
    this.filtersService
      .getInternshipsFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.filters = data;
      });
    // .subscribe(data => {
    //   this.filters = data;
    //   this.schoolId = this.webStorageService.get(StorageKeys.SchoolId);
    //   if (this.webStorageService.get(StorageKeys.Course)) {
    //     this.filters.courses = [this.webStorageService.get(StorageKeys.Course)];
    //     this.webStorageService.clear(StorageKeys.Course);
    //   }

    // })
  }

  public loadMore() {
    this.pageNumber++;
    this.currentGroupElementsCount = this.currentGroupElementsCount + PAGE_SIZE;
    this.getInternshipCatalog(PAGE_SIZE);
  }

  // данные из поиска
  dataSearch(data: string) {
    this.internshipListFiltered = this.internshipList.filter(
      internship => internship.name && internship.name.toLowerCase().indexOf(data.toLowerCase()) != -1,
    );
  }

  public getInternshipCatalog(selectedInternshipsCount: number, reset?: boolean) {
    this.loading = true;
    this.loadedAll = false;
    if (reset) {
      this.internshipList = [];
      this.internshipListFiltered = [];
    }

    this.apiInternshipsService
      .getAllInternships()
      .pipe(
        take(1),
        tap(internships => {
          if (internships && internships.length) {
            this.loadedAll = internships.length < selectedInternshipsCount;
            internships.forEach(p => this.internshipList.push(p));
            this.internshipListFiltered = this.internshipList;
          }
          this.loading = false;
        }),
      )
      .subscribe();
  }

  private switchDemandRating(responseInternshipsLength: number, orderedDemandRatings: string[]) {
    const lastDemandRatingIndex = orderedDemandRatings.length - 1; // индекс последнего элемента
    if (this.filters.DemandRating === orderedDemandRatings[lastDemandRatingIndex]) {
      this.loadedAll = true;
    } else {
      const nextDemandRating = this.getNextItem(orderedDemandRatings, this.filters.DemandRating, lastDemandRatingIndex);
      if (nextDemandRating !== null) {
        this.getNextDemandInternships(responseInternshipsLength, nextDemandRating);
      }
    }
  }

  // получить значение следующего элементаю Если элемент не найден, то вернуть null
  private getNextItem(orderedItems: string[], currentValue: string, lastItemIndex: number): string {
    let nextItem = null;
    for (let i = 0; i < lastItemIndex; i++) {
      if (currentValue === orderedItems[i]) {
        nextItem = orderedItems[i + 1];
        break;
      }
    }
    return nextItem;
  }

  private getNextDemandInternships(responseInternshipsLength: number, nextDemandRating: string) {
    this.filters.DemandRating = nextDemandRating;
    this.currentGroupElementsCount = 0; // обнуляем счетчик элементов при переходе к другому уровню востребованности

    // const loadedInternshipsCounter = this.internshipList.length + responseInternshipsLength; // то, что было в массиве + количество элементов из последнего запроса
    const loadedInternshipsCounter = this.internshipListFiltered.length + responseInternshipsLength; // то, что было в массиве + количество элементов из последнего запроса

    const requiredInternshipsCount = PAGE_SIZE * this.pageNumber; // необходимое количество профессий для заполнения всех текущих страниц
    if (loadedInternshipsCounter < requiredInternshipsCount) {
      this.currentGroupElementsCount = PAGE_SIZE - responseInternshipsLength; // подсчитываем количество элементов, необходимых для полного заполнения страницы
      this.getInternshipCatalog(this.currentGroupElementsCount);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
    this.filtersService.setInternshipsFilter({}, 0);
  }
}
