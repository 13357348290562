import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBubbleSectionData } from 'app/pages/player/section/section.interfaces';
import { IBubble } from 'app/pages/player/slide/slide.interface';
import { ETestTypesSteps } from 'app/shared/enums/testtypes.enum';
import { takeUntil } from 'rxjs/operators';
import { SlideService } from 'app/shared/services/slide.service';
import { StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-bvb-visually-impaired-bubble-box',
  templateUrl: './bvb-visually-impaired-bubble-box.component.html',
  styleUrls: ['./bvb-visually-impaired-bubble-box.component.scss'],
})
export class BvbVisuallyImpairedBubbleBoxComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() data: IBubbleSectionData;
  @Input() slide: any;
  @Output() outerHandlerBubble = new EventEmitter();
  public sectionType: string;
  public slideNum: number;
  public mobileContent: boolean;
  public userRole: string = '';
  public testTypesSteps = ETestTypesSteps;
  public sectionId: string;
  public isRectangle: boolean = false;
  public isCircle: boolean = false;
  public slidesNumber: string = '';
  private questionIdsWithCircles = [
    '5bbf6f4c-d426-4634-be82-b797c217b7f3',
    'cb386d26-cdda-4822-a08d-01d1b35e4e3a',
    'cef092cb-cb39-4f88-9f15-a1277001e166',
    '1f30d589-bcb6-4039-bc4b-f4c423914fbc',
    'f0dafbc2-dce3-4d32-af9f-0abe2b861604',
    '44dbe6b4-5459-419b-a786-052f71e08195',
  ];
  private questionIdsWithRectangles = [
    'db4d14a5-49ef-1c67-72f3-d928ed9be7b0',
    '6d61cd42-3b73-e2fe-b48c-a79766b9887f',
    '15c26cb6-387f-1821-71fc-bccba81537ad',
    'a0410e33-b919-49b9-df20-0f24810f198e',
    'db15f57a-c454-ceda-363f-437d5dea89c5',
    'c56a4d91-ce69-d055-8a75-21470ddc2abb',
    'a7c34e12-0e31-44ad-aa25-0c4a30b67973',
    '48dc6b53-5e70-209b-38b9-c5eeb3bc77bd',
    'defb718f-aa06-07e1-4a80-8971709dee0d',
    'f74c81b5-7612-338e-f0b4-5d9a38e7cc2b',
    '39d18039-ac4f-445c-af07-dd53ae0bf57a',
    'abbda951-6157-4c9c-80aa-7234ff9f27e5',
  ];
  private sessionId: string;
  private currentSlideType: string;
  private currentSections;

  constructor(private activatedRoute: ActivatedRoute, private slideService: SlideService, private webStorageService: WebStorageService) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  public ngOnInit(): void {
    this.slidesNumber = this.webStorageService.get(StorageKeys.SlidesNumber);
    this.slidesNumber = this.slidesNumber ? this.slidesNumber : this.testTypesSteps.BVB_TEST_VISUALLY_IMPAIRED.toString();
    this.sectionType = this.data.sectionType;
    this.sectionId = this.data.sectionId;

    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.sessionId = params['ssid'];
    });

    this.slideNum = this.slideService.getCurrenSlideNumber(this.sectionId, this.sessionId);
    this.currentSlideType = this.slide.questionType;
    this.currentSections = this.slide.slideView.bubbleView.sections;
    this.isRectangle =
      this.slide.slideView.bubbleView.bubbles[0].answerType !== 'Image' &&
      this.questionIdsWithRectangles.indexOf(this.slide.questionId) !== -1;
    this.isCircle = this.questionIdsWithCircles.indexOf(this.slide.questionId) !== -1;
  }

  public selectAnswer(element: IBubble): void {
    if (element.sectionId && this.sectionId !== element.sectionId) {
      return;
    }

    if (this.slide.questionType === 'Single') {
      this.data.elements.forEach(element => {
        if (element.sectionId && element.sectionId === this.sectionId) {
          element.sectionId = null;
          element.sectionType = null;
        }
      });
    }

    if (!element.sectionId) {
      element.sectionId = this.sectionId;
      element.sectionType = this.sectionType;
    } else {
      element.sectionId = null;
      element.sectionType = null;
    }
  }
}
