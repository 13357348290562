import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-professions-mobile-search',
  standalone: true,
  templateUrl: './professions-mobile-search.component.html',
  styleUrls: ['./professions-mobile-search.component.scss'],
  imports: [ReactiveFormsModule, TranslateModule, NgIf],
})
export class ProfessionsMobileSearchComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public searchField: UntypedFormControl;
  public value: boolean = false;

  @Output() searchesData = new EventEmitter();

  @Input() set searchClear(searchClear: boolean) {
    if (searchClear) {
      this.clearSearch();
    }
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe)).subscribe(term => {
      this.value = !!term;
      this.searchesData.emit(term);
    });
  }

  public clearSearch(): void {
    this.searchField.setValue('');
  }
}
