import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ApiUniversitiesService, IUniversity, StorageKeys, WebStorageService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import {
  InternshipsFiltersService,
} from '../../internship-page/internship-catalog/internship-filter/internships-filter.service';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-all-universities',
  templateUrl: './all-universities.component.html',
  styleUrls: ['./all-universities.component.scss'],
})
export class AllUniversitiesComponent implements OnInit {
  currentUniversities: IUniversity[] = [];
  currentUniversity: IUniversity;
  universitiesCount: string;

  dataFetched: boolean = false;
  viewValue: boolean = false;
  titleName: string;
  userRole: string;
  url: string;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private apiUniversitiesService: ApiUniversitiesService,
    private overlayService: OverlayBusyService,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
    private meta: Meta,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Все ВУЗы' });

    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    this.loadUniversities()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.overlayService.hide());
  }

  loadUniversities(): Observable<any> {
    return this.apiUniversitiesService.getAllUniversities().pipe(
      tap(response => {
        const universities = response;

        if (universities) {
          for (const item in universities) {
            this.currentUniversities.push(universities[item]);
          }
          this.dataFetched = true;
        }
      }),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countUniversities(universitiesLength: number) {
    const universitiesTitles = ['ВУЗ', 'ВУЗа', 'ВУЗов'];
    const title = this.internshipsFiltersService.getTitleByNumber(universitiesLength, universitiesTitles);
    return (this.universitiesCount = universitiesLength + ' ' + title);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
