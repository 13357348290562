<div class="return-button">
  <a (click)="routingClassPage()" class="button white-button w-button">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
</div>
<section class="teacher" *ngIf="teacher !== undefined">
  <div class="content-row">
    <div class="teacher-notActive" (click)="teacherToggle = !teacherToggle" *ngIf="teacher.teacherId === null">
      <h2>
        {{ 'SHARED.TEACHER_IO' | translate : { firstName: teacher.firstName, lastName: teacher.lastName } }}
      </h2>
    </div>
    <div class="teacher-Active" (click)="teacherToggle = !teacherToggle" *ngIf="teacher.teacherId !== null">
      <h2>
        {{ 'SHARED.TEACHER_IO' | translate : { firstName: teacher.firstName, lastName: teacher.lastName } }}
      </h2>
    </div>
  </div>
</section>
