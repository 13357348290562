<div class="navbar _dir">
  <div class="logo-div">
    <a (click)="selectMain()" class="home-link w-inline-block" [class.mo-logo]="isMOStend"></a>
  </div>
  <div class="menu">
    <a class="menu-link _dir _main w-inline-block" [class.w--current]="mainActive" [routerLink]="['/' + root]">
      <div>{{ 'SHARED.GENERAL' | translate }}</div>
    </a>

    <a class="menu-link _dir _analytics w-inline-block" [routerLinkActive]="['w--current']" [routerLink]="['/' + root + '/analytics']">
      <div>Аналитика</div>
    </a>

    <a class="menu-link _dir _slices w-inline-block" [routerLinkActive]="['w--current']" [routerLink]="['/' + root + '/slices']">
      <div>Срезы</div>
    </a>

    <a class="menu-link _dir _courses w-inline-block" [routerLinkActive]="['w--current']" [routerLink]="['/' + root + '/courses']">
      <div>Курсы</div>
    </a>
  </div>
</div>

<!---------------------- mobile menu --------------------------------->
<div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
  <div
    [ngStyle]="{
      transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
    }"
    class="mobile-menu-container"
    style="transform-style: preserve-3d"
  >
    <div class="mobile-menu-header">
      <div class="mobile-menu-button" (click)="hideMobileMenu()">
        <div class="mobile-menu-icon-div">
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
        </div>
      </div>
      <div class="mobile-menu-logo-div">
        <a (click)="selectMain()" class="mobile-menu-logo-link w-inline-block w--current" [class.mo-logo]="isMOStend">
          <img
            src="{{
              isMOStend ? './profilum-assets/images/logos/profilum-logo.svg' : './profilum-assets/images/dashboard/profilum-new-logo.svg'
            }}"
            class="profilum-mobile-logo"
            alt="Profilum logo"
          />
        </a>
      </div>
    </div>
    <div class="mobile-user-div">
      <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
        <div class="user-dropdown-toggle">
          <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
          <div class="user-name mob">Мой профиль</div>
        </div>
      </a>
    </div>

    <a
      class="menu-link school-2 w-inline-block"
      [routerLinkActive]="['w--current']"
      [class.w--current]="mainActive"
      (click)="this.hideProfile(); this.hideMobileMenu()"
      [routerLink]="['/' + root]"
    >
      <div>{{ 'SHARED.GENERAL' | translate }}</div>
    </a>

    <a
      class="menu-link _dir _analytics w-inline-block"
      [routerLinkActive]="['w--current']"
      [class.w--current]="innerRoute"
      (click)="this.hideProfile(); this.hideMobileMenu()"
      [routerLink]="['/' + root + '/analytics']"
    >
      <div>Аналитика</div>
    </a>

    <a
      class="menu-link _dir _slices w-inline-block"
      [routerLinkActive]="['w--current']"
      [class.w--current]="innerRoute"
      (click)="this.hideProfile(); this.hideMobileMenu()"
      [routerLink]="['/' + root + '/slices']"
    >
      <div>Срезы</div>
    </a>

    <a
      class="menu-link _dir _courses w-inline-block"
      [routerLinkActive]="['w--current']"
      [class.w--current]="innerRoute"
      (click)="this.hideProfile(); this.hideMobileMenu()"
      [routerLink]="['/' + root + '/courses']"
    >
      <div>Курсы и мероприятия</div>
    </a>
  </div>
</div>
