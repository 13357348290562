import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';

import { ICourseMaterialsSectionItem } from '../../model';
import { TippyDirective } from '@ngneat/helipopper';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';

@Component({
  selector: 'prf-course-material-block',
  templateUrl: './course-material-block.component.html',
  styleUrls: ['./course-material-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, NgClass, NgIf, TippyDirective, TooltipComponent],
})
export class CourseMaterialBlockComponent {
  protected readonly FILETYPES_IMAGES = {
    Video: '1',
    Presentation: '2',
    Document: '3',
    Text: '3',
    Unknown: '4',
  };
  public isTooltipShown: boolean = false;

  @Input({ required: true }) public courseMaterialItem: ICourseMaterialsSectionItem;

  @Output() public copyLinkEmitter: EventEmitter<string> = new EventEmitter<string>();

  public copyLinkToMaterial(linkToMaterial: string): void {
    this.copyLinkEmitter.emit(linkToMaterial);
  }

  public goToMaterial(linkToMaterial: string): void {
    setTimeout(() => {
      window.open(linkToMaterial, '_blank');
    });
  }
}
