import { Component, Input } from '@angular/core';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-welcome-choise-profession',
  templateUrl: './welcome-choise-profession.component.html',
  styleUrls: ['./welcome-choise-profession.component.scss'],
})
export class WelcomeChoiseProfessionComponent {
  @Input() testResults: any;

  public testCompleted: boolean = false;

  constructor(private webStorageService: WebStorageService) {
    const sessionStatus: string = this.webStorageService.get(StorageKeys.SessionStatus);
    this.testCompleted = sessionStatus === 'Success';
  }
}
