<div class="results-graph-container" [class.results-graph-container_wide]="showByPupil">
  <div [class.chart-container]="showByPupil">
    <canvas
      baseChart
      [datasets]="doughnutChartDatasets"
      [labels]="doughnutChartLabels"
      [type]="doughnutChartType"
      [options]="pieChartOptions"
      [legend]="pieChartLegend"
    >
    </canvas>
  </div>
  <div class="progress-bars" [class.progress-bars_wide]="showByPupil">
    <ul *ngFor="let object of data; let coloridx = index">
      <li class="bar-container">
        <div class="bar-name" [class.bar-name_no-result]="isNoResult">
          {{ object.name }}
          <prf-tooltip-text class="bar-name-tooltip" width="390" *ngIf="object?.description">
            <p class="bar-name-tooltip__description">
              {{ object.description }}
            </p>
          </prf-tooltip-text>
        </div>
        <div class="bar-wrapper">
          <div class="bar-section" [class.bar-section_wide]="showByPupil">
            <div
              class="bar-section-percent"
              [style.background-color]="chartColors[0].backgroundColor[coloridx]"
              [ngStyle]="{ 'width.%': object.percents }"
            ></div>
            <div class="bar-section-background"></div>
          </div>
          <ng-container *ngIf="!showByPupil; else showByPupilTemplate">
            <div class="percents" [style.background-color]="chartColors[0].backgroundColor[coloridx]">
              {{ object.percents | number : '1.0-0' }}%
            </div>
          </ng-container>
          <ng-template #showByPupilTemplate>
            <div
              class="percents percents_wide"
              [class.percents_no-result]="isNoResult"
              [style.background-color]="object.percents > 0 ? chartColors[0].backgroundColor[coloridx] : color_gray"
              [style.color]="object.percents > 0 ? null : color_darkGray"
            >
              {{ object.count | number : '1.0-0' }}
              {{ declOfNum(object.count, [dictionary.Pupil, dictionary.PupilGenitive, dictionary.PupilsGenitive]) }}
            </div>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
</div>
