<div *ngIf="sessionId" class="result-container">
  <div class="content-section testing-content-2">
    <div class="container container-900 results-open-container">
      <div class="results-open-header">
        <h1 class="results-open-main-title">Спасибо за прохождение теста!</h1>
        <p class="results-open-main-desc">
          Ниже ты можешь познакомиться с результатами диагностики. Они помогут лучше понять свои сильные стороны и области интересов, чтобы
          сориентироваться в будущих направлениях обучения. Также данные результаты помогут подобрать оптимальные варианты по обучению
          программированию, которые подойдут тебе под твой уровень и под твои задачи развития.
        </p>
      </div>

      <prf-results [sessionId]="sessionId"></prf-results>

      <section class="results-open-footer">
        <p class="results-open-main-desc">
          У тебя достаточно высокие результаты по итогам решения задач на математику и логическое мышление. Вероятно, тебе будет достаточно
          легко осваивать знания и навыки, которые могут потребоваться в программировании. Сама сфера программирования тоже может быть тебе
          близка по структуре мышления и способам работы с информацией.<br />
          Примечание:<br />
          Данные результаты являются описанием итогов пройденного тестирования. Вернуться к этим результатам можно по ссылке на приглашение
          к тесту. Окончательные рекомендации и итоговое приглашение на ту или иную программу придут тебе на почту, поэтому рекомендуем
          смотреть за информационными сообщениями от нашего проекта.
        </p>
        <div class="results-open-button">
          <a class="button" [routerLink]="['/']">Завершить</a>
        </div>
      </section>
    </div>
  </div>
</div>
