export const TESTS_VARIANTS_IDS = {
  passportOfTalents: '9ba5d188-d2b2-b840-ba68-8bda05102737',
  passportOfTalentsMO: '1f1edeee-4655-41b9-ab15-145e977abf66',
  test360: '',
  motivationtest: '8cc4237d-166f-4a89-80d6-70e3e7616716',
  promoTestMosRu: '0708668d-1f28-4cef-8492-cb1a6adf7556',
  valuesTest: '48751c29-0918-4fab-be65-76c75f0806c6',
  mincifrydemoTest: '249c2398-0bd6-48d2-85ba-7285a527b366',
  bvbTest: 'acd497fd-e26e-41b6-b6ff-e1eb4a4e0c88',
  bvbTestVisuallyImpaired: 'acd497fd-e26e-41b6-b6ff-e1eb4a4e0c88',
  vkTest: '928396ab-66a4-45bf-a83c-03592ae46c37',
  mincifryTest: 'b2ff59c8-8b77-4dc9-b4a4-a3722e5c5838',
  interestsTest: 'dd1ca6f3-9398-4821-beee-7ec48c435b7a',
  capabilitiesTest: 'b71fae25-3c82-4c4f-a4d4-8451bb00d410',
  careerLiteracyTest: '13a8be7a-87fe-4528-b960-a44f2025e7d9',
  depkultInterestsTest: '44e8bc93-739e-4c23-be10-49695cddc7bc',
  depkultCapabilities: 'a4749d7b-0915-433c-a82a-367abfd9e6a9',
};
