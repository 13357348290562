<div *ngIf="lessonsInfo" class="class-courses-lessons">
  <div class="class-courses-lessons__title-wrapper">
    <a [routerLink]="'/class-courses/' + schoolClassId" class="class-courses-lessons__button_back">
      <span class="class-courses-lessons__button-name_hidden">Назад</span>
    </a>
    <h1 class="class-courses-lessons__title">{{ currentChapter.name }}</h1>
  </div>
  <div class="ce-lessons">
    <div class="ce-lessons__header">
      <span class="ce-lessons__header__title">{{ getLessonsHeader(lessonsInfo) }}</span>
      <div class="ce-lessons__header__materials">
        <span>Конспекты</span>
        <span>Материалы</span>
        <span>Задания</span>
      </div>
    </div>
    <div *ngFor="let lesson of lessonsInfo.lessons; trackBy: trackByFn" class="ce-lessons__body">
      <span class="ce-lessons__body__number">{{ lesson.number + '.' }}</span>
      <span class="ce-lessons__body__title">{{ lesson.name }}</span>
      <ng-container *ngIf="!lesson.blockingInfo?.value; else isBlocked">
        <div class="ce-lessons__body__materials">
          <a
            class="ce-lessons__link"
            (click)="sendMaterialsMetric(lesson, 'open')"
            [href]="lesson.conspectUrl"
            target="_blank"
            aria-label="Открыть материалы для занятия"
            [class.ce-lessons__link_disabled]="!lesson.conspectUrl"
          >
            Открыть
          </a>
          <a
            class="ce-lessons__link"
            (click)="sendMaterialsMetric(lesson, 'download')"
            [href]="lesson.yandexDiskMaterialsUrl"
            target="_blank"
            aria-label="Скачать материалы для занятия"
            [class.ce-lessons__link_disabled]="!lesson.yandexDiskMaterialsUrl"
          >
            Скачать
          </a>
          <button
            *ngIf="!lesson.passed; else passed"
            (click)="showPopup(lesson)"
            class="ce-lessons__activity button"
            [class.ce-lessons__not-available]="!activeLessonIds.includes(lesson.id)"
          >
            {{ !activeLessonIds.includes(lesson.id) ? 'Нет заданий' : 'Выдать задание' }}
          </button>
          <ng-template #passed>
            <span class="ce-lessons__activity ce-lessons__passed"> Выполнили {{ getPassed(lesson.id) }} из {{ pupilCount }} </span>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="ce-lessons__actions">
    <a *ngIf="previousChapter" (click)="goToPreviousChapter()" [attr.aria-label]="'Предыдущий раздел'" class="ce-lessons__previous-section">
      <span class="previous-section__link-text">{{ 'Предыдущий раздел' }}</span>
    </a>
    <a
      *ngIf="nextChapter"
      (click)="goToNextChapter()"
      [attr.aria-label]="'Следующий раздел'"
      class="ce-lessons__next-section"
      [class.ce-lessons__next-section_disabled]="checkIsDisabledChapter()"
    >
      <span class="next-section__link-text">{{ 'Следующий раздел' }}</span>
    </a>
  </div>
</div>

<ng-template [ngIf]="showOpenLessonPopup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <div class="popup-share">
        <a (click)="cancel()" class="close-lb w-inline-block">
          <img src="./profilum-assets/images/landing/close-lb.svg" alt="" />
        </a>
        <div class="popup-share-header">
          <h3 class="popup-share-title">Задание к занятию №{{ selectedLesson.number }} «{{ selectedLesson.name }}»</h3>
        </div>
        <div class="popup-share-content">
          <p class="popup-share-text">Ученики смогут выполнить задание в своем личном кабинете</p>
          <p *ngIf="notPassedLessons" class="popup-share-text">
            Также будут выданы задания к {{ isMultipleLessonNotPassed ? 'предыдущим занятиям' : 'предыдущему занятию' }} №{{
              notPassedLessons
            }}
          </p>
        </div>
        <div class="popup-share-buttons">
          <button class="button" (click)="complete()">Выдать</button>
          <button class="button button-white" (click)="cancel()">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
