<section class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
    </a>

    <article class="tabs login w-tabs">
      <div class="tabs-content w-tab-content">
        <div class="w-tab-pane w--tab-active">
          <div class="tab-pane login">
            <header>
              <h1>{{ 'PASSWORD.RESTORE' | translate }}</h1>
            </header>

            <div class="user-data-form-block w-form">
              <form [formGroup]="form" autocomplete="false" autocomplete="off">
                <section class="login-form-row">
                  <label for="password" class="login-label">{{ 'PASSWORD.NEW' | translate }}</label>
                  <div class="form-full-width">
                    <div class="login-field-column">
                      <input
                        *ngIf="!isMaskedPassword"
                        autocomplete="off"
                        type="password"
                        class="login-field w-input"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="256"
                        name="password"
                        data-name="password"
                        id="password"
                        formControlName="password"
                        (ngModelChange)="testPassword($event)"
                        (focus)="popupPassword.style.display = 'block'; testPassword(event)"
                        (focusout)="popupPassword.style.display = 'none'"
                      />
                      <input
                        *ngIf="isMaskedPassword"
                        autocomplete="off"
                        type="text"
                        class="login-field w-input"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="256"
                        name="password"
                        data-name="password"
                        id="passwordUnMask"
                        formControlName="password"
                        (ngModelChange)="testPassword($event)"
                        (focus)="popupPassword.style.display = 'block'; testPassword(event)"
                        (focusout)="popupPassword.style.display = 'none'"
                      />
                      <div [ngClass]="{ eye: isMaskedPassword, eye__closed: !isMaskedPassword }" (click)="toggleMask()"></div>
                      <div
                        class="info-popup recommendation w-hidden-small w-hidden-tiny hide-popup"
                        #popupPassword
                        [style.display]="errorChecking() ? 'block' : 'none'"
                      >
                        <p>{{ 'PASSWORD.REQUIREMENT' | translate }}</p>
                        <ol class="info-exacting">
                          <li class="info-pswrd-icon info-popup-pswrd" [class.not-correct]="charactersError">
                            {{ 'PASSWORD.6_SYMBOL' | translate }}
                          </li>
                          <li class="info-pswrd-icon info-popup-letter" [class.not-correct]="letterError">
                            {{ 'PASSWORD.1_SYMBOL' | translate }}
                          </li>
                          <li class="info-pswrd-icon info-popup-digit" [class.not-correct]="numberError">
                            {{ 'PASSWORD.1_DIGIT' | translate }}
                          </li>
                        </ol>
                        <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                      </div>
                    </div>
                    <div class="error-field-container" *ngIf="errorChecking()">
                      <div>{{ 'PASSWORD.NOT_VALID_FORMAT' | translate }}</div>
                      <ul>
                        <li *ngIf="charactersError">
                          {{ 'PASSWORD.LAST_THEN_6_SYMBOL' | translate }}
                        </li>
                        <li *ngIf="letterError">{{ 'PASSWORD.NOT_SYMBOL' | translate }}</li>
                        <li *ngIf="numberError">{{ 'PASSWORD.NOT_DIGIT' | translate }}</li>
                        <li *ngIf="whiteSpaceError">
                          {{ 'PASSWORD.NOT_VALID_SYMBOL' | translate }}
                        </li>
                        <li *ngIf="rusLettersError">{{ 'PASSWORD.USED_RU_SYMBOL' | translate }}</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <div class="reset-button">
                  <a class="button land-green-btn w-button" [ngClass]="{ disabled: !isAccessAllowed() }" (click)="sendCredentials()">
                    {{ 'PASSWORD.NEXT' | translate }}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</section>
