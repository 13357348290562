export enum TaskTypes {
  RadioButton,
  CheckBox,
  Match,
  Partition,
  MultiMatch,
  Blocking,
  ScreeningTest,
  ChooseProfessionsFromField,
  ProfessionsTryOn,
}

export enum LessonStatus {
  NO_TASK = 'no-task',
  NOT_GIVEN_OUT = 'not-given-out',
  GIVEN_OUT = 'given-out',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
}
