import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { takeUntil, tap } from 'rxjs/operators';
import { MenuService } from '../menus/menu/menu.service';
import { ApiProfilesService, AppSettingsService, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-promo-action-page',
  templateUrl: './promo-action-page.component.html',
  styleUrls: ['./promo-action-page.component.scss'],
})
export class PromoActionPageComponent extends UnsubscribeComponent implements OnInit {
  public userId: string = '';
  public userRole: string = '';
  public regionId: string = '';
  public municipalityId: string = '';

  resultsRoutingUrl: string = '';

  isMobile: boolean = false;

  constructor(
    private router: Router,
    private apiProfilesService: ApiProfilesService,
    private menuService: MenuService,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    this.municipalityId = this.webStorageService.get(StorageKeys.MunicipalityId);
    this.menuService.isMobileScreen$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.checkPromotestResults();
  }

  routingAllCourses() {
    return this.router.navigateByUrl('/partners-courses-all');
  }

  navigateNewTest() {
    return this.router.navigate(['/parent/mosrupromo']);
  }

  returnToResults() {
    this.resultsRoutingUrl && this.resultsRoutingUrl.length
      ? this.router.navigate([this.resultsRoutingUrl])
      : this.router.navigate(['/parent/mosrupromo']);
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  checkPromotestResults() {
    this.apiProfilesService
      .getSharedResults(this.webStorageService.get(StorageKeys.UserId))
      .pipe(
        takeUntil(this.unsubscribe),
        tap(sharedResults => {
          let promotestResults: any[] = [];
          if (sharedResults.length > 0) {
            promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);

            this.resultsRoutingUrl = promotestResults.length > 0 ? '/promotest-results' : '/parent/mosrupromo';
          }
        }),
      )
      .subscribe();
  }
}
