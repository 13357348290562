import { Routes } from '@angular/router';

import { AuthGuard, AuthRoleGuard, CanLoadPupil, MosruGuard } from '@profilum-library';

import {
  UserProfileOutletComponent,
} from '../catalog/user-profile-page/user-profile-outlet/user-profile-outlet.component';
import { AddCourseComponent } from '../catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import { MineCoursesComponent } from '../catalog/courses-page/mine-courses/mine-courses.component';
import {
  ActiveInternshipsComponent,
} from '../../shared/dashboard/internships/all-internships/active-internships/active-internships.component';
import {
  AddInternshipComponent,
} from '../../shared/dashboard/internships/all-internships/active-internships/add-internship/add-internship.component';
import {
  EditInternshipComponent,
} from '../../shared/dashboard/internships/all-internships/active-internships/edit-internship/edit-internship.component';
import { AllInternshipsComponent } from '../../shared/dashboard/internships/all-internships/all-internships.component';
import {
  ArchiveInternshipsComponent,
} from '../../shared/dashboard/internships/all-internships/archive-internships/archive-internships.component';
import { InternshipsComponent } from '../../shared/dashboard/internships/internships.component';
import {
  MineInternshipsComponent,
} from '../../shared/dashboard/internships/mine-internships/mine-internships.component';
import { SettingsComponent } from '../../shared/dashboard/settings/settings.component';
import { AdminDOPanelComponent } from './adminDO/admin-do-panel.component';
import { CityAdminComponent } from './cityadmin/cityadmin.component';
import { ChildRegistrationComponent } from './parent/children-add/child-registration/child-registration.component';
import { ParentPanelComponent } from './parent/parent-panel.component';
import { SchooladminAddClassComponent } from './schooladmin/schooladmin-add-class/schooladmin-add-class.component';
import { SchoolAdminPanelComponent } from './schooladmin/schooladmin-panel.component';
import { TeacherPanelComponent } from './teacher/teacher-panel.component';
import { EmployerPanelComponent } from './employer/employer-panel.component';
import { DirectorPanelComponent } from './director/director-panel.component';
import { DirectorSchoolComponent } from './director/director-school/director-school.component';
import { DirectorClassComponent } from './director/director-class/director-class.component';
import { DirectorAnalyticsComponent } from './director/director-analytics/director-analytics.component';
import { ArchiveCoursesComponent } from '../catalog/courses-page/all-courses/archive-courses/archive-courses.component';
import { AllCoursesComponent } from '../catalog/courses-page/all-courses/all-courses.component';
import { ActiveCoursesComponent } from '../catalog/courses-page/all-courses/active-courses/active-courses.component';
import {
  EditCourseComponent,
} from '../catalog/courses-page/all-courses/active-courses/edit-course/edit-course.component';
import { CoursesComponent } from '../catalog/courses-page/courses.component';
import {
  EmployerInternshipsComponent,
} from 'app/shared/dashboard/internships/employer-internships/employer-internships.component';
import {
  InternshipDetailsComponent,
} from 'app/shared/dashboard/internships/all-internships/active-internships/internship/internship-details/internship-details.component';
import { DirectorSlicesComponent } from './director/director-slices/director-slices.component';
import { EditCompanyComponent } from './employer/edit-company/edit-company.component';
import {
  SchooladminClassTemplateComponent,
} from './schooladmin/schooladmin-class-template/schooladmin-class-template.component';
import { TutorPanelComponent } from './tutor/tutor-panel.component';
import { LogoutComponent } from 'app/shared/dashboard/logout/logout.component';
import { UpdateUserInfoComponent } from './pupil/update-user-info/update-user-info.component';
import { PromoTestComponent } from './parent/promo-test/promo-test.component';
import { TutorPupilsComponent } from './tutor/tutor-pupils/tutor-pupils.component';
import {
  DirectorStudentPageComponent,
} from './director/director-class/director-student-page/director-student-page.component';
import { DirectorTeacherComponent } from './director/director-class/director-teacher/director-teacher.component';
import { SchooladminClassesComponent } from './schooladmin/schooladmin-classes/schooladmin-classes.component';
import {
  TeacherStartPageComponent,
} from 'app/pages/control-panel/teacher/teacher-start-page/teacher-start-page.component';
import {
  TeacherLessonsComponent,
} from '../../shared/common-components/class-courses/teacher-lessons/teacher-lessons.component';
import { AdminPanelComponent } from './admin/admin-panel.component';
import { SchoolsComponent } from './admin/schools/schools.component';

const InternshipRoutes: Routes = [
  {
    path: '',
    redirectTo: '/admin/internships/all-internships',
    pathMatch: 'full',
  },
  {
    path: 'all-internships',
    component: AllInternshipsComponent,
    children: [
      { path: '', component: ActiveInternshipsComponent },
      { path: 'active-internships', component: ActiveInternshipsComponent },
      { path: 'archive-internships', component: ArchiveInternshipsComponent },
    ],
  },
  {
    path: 'mine-internships',
    component: MineInternshipsComponent,
    children: [
      { path: '', component: ActiveInternshipsComponent },
      { path: 'active-internships', component: ActiveInternshipsComponent },
      { path: 'archive-internships', component: ArchiveInternshipsComponent },
    ],
  },
];

const loadPupilLk = () => {
  console.log('load pupil?');
  const assignedCourse: any = window.localStorage.getItem('assignedCourseExists');
  if (assignedCourse === 'true') {
    return import('./pupil/pupil.module').then(m => m.PupilModule);
  }

  return import('app/pages/control-panel/old-school/pupil/pupil-panel-old-school.module').then(m => m.PupilPanelOldSchoolModule);
};

export const ControlPanelRoutes: Routes = [
  {
    path: 'director',
    component: DirectorPanelComponent,
    // canActivate: [AuthRoleGuard],
    // data: {roles: ['director']},
    children: [
      { path: '', component: DirectorSchoolComponent },
      { path: 'class/:classId', component: DirectorClassComponent },
      { path: 'pupil', component: DirectorStudentPageComponent },
      { path: 'teacher', component: DirectorTeacherComponent },
      {
        path: 'analytics',
        component: DirectorAnalyticsComponent,
      },
      {
        path: 'slices',
        component: DirectorSlicesComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
        children: [
          { path: '', redirectTo: '/director/courses/all-courses', pathMatch: 'full' },
          {
            path: 'all-courses',
            component: AllCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
          {
            path: 'mine-courses',
            component: MineCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
        ],
      },
      { path: 'create-course', component: AddCourseComponent },
      { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'logout', component: LogoutComponent },
    ],
  },
  {
    path: 'teacher',
    component: TeacherStartPageComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['teacher'] },
  },
  {
    path: 'teacher-panel',
    component: TeacherPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['teacher'] },
  },
  {
    path: 'teacher-lessons/:classId',
    component: TeacherLessonsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['teacher'] },
  },
  {
    path: 'teacher-class-results',
    loadChildren: () =>
      import('app/pages/control-panel/teacher/teacher-class-results/teacher-class-results.module').then(m => m.TeacherClassResultsModule),
  },
  {
    path: 'class-courses-lessons',
    loadChildren: () =>
      import('app/pages/control-panel/teacher/teacher-class-lessons/teacher-class-lessons.module').then(m => m.TeacherClassLessonsModule),
  },
  {
    path: 'class-courses/:classId',
    loadChildren: () =>
      import('app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.module').then(m => m.TeacherClassCoursesModule),
  },
  {
    path: 'class-diagnostic/:classId',
    loadChildren: () =>
      import('app/pages/control-panel/teacher/teacher-class-diagnostics/teacher-class-diagnostics.module').then(
        m => m.TeacherClassDiagnosticsModule,
      ),
  },
  {
    path: 'teacher/logout',
    component: LogoutComponent,
  },
  {
    path: 'pupil',
    canLoad: [CanLoadPupil],
    loadChildren: loadPupilLk,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  { path: 'pupil/logout', component: LogoutComponent },
  {
    path: 'pupil/:profession',
    canLoad: [CanLoadPupil],
    loadChildren: loadPupilLk,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'schooladmin',
    component: SchoolAdminPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['schooladmin'] },
    children: [
      { path: '', redirectTo: 'classes', pathMatch: 'full' },
      { path: 'classes', component: SchooladminClassesComponent },
      { path: 'class/:classId', component: SchooladminClassTemplateComponent },
      { path: 'new-class', component: SchooladminAddClassComponent },
      {
        path: 'courses',
        component: CoursesComponent,
        children: [
          { path: '', redirectTo: '/schooladmin/courses/all-courses', pathMatch: 'full' },
          {
            path: 'all-courses',
            component: AllCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
          {
            path: 'mine-courses',
            component: MineCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
        ],
      },
      { path: 'create-course', component: AddCourseComponent },
      { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'internships', component: InternshipsComponent, children: InternshipRoutes },
      { path: 'internships/:hrid', component: InternshipDetailsComponent },
    ],
  },
  {
    path: 'admin',
    component: AdminPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['admin'] },
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        children: [{ path: 'schools', component: SchoolsComponent }],
      },
    ],
  },
  {
    path: 'adminDO',
    component: AdminDOPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['adminDO'] },
    children: [
      {
        path: 'courses',
        component: CoursesComponent,
        children: [
          { path: '', redirectTo: '/adminDO/courses/all-courses', pathMatch: 'full' },
          {
            path: 'all-courses',
            component: AllCoursesComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
          {
            path: 'mine-courses',
            component: MineCoursesComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
        ],
      },
      { path: 'create-course', component: AddCourseComponent },
      { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'internships', component: InternshipsComponent, children: InternshipRoutes },
      { path: 'internships/:hrid', component: InternshipDetailsComponent },
    ],
  },
  {
    path: 'employer',
    component: EmployerPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['employer'] },
    children: [
      {
        path: 'edit-company/:companyId',
        component: EditCompanyComponent,
        pathMatch: 'full',
      },
      {
        path: 'courses',
        component: CoursesComponent,
        children: [
          { path: '', redirectTo: '/employer/courses/all-courses', pathMatch: 'full' },
          {
            path: 'all-courses',
            component: AllCoursesComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
          {
            path: 'mine-courses',
            component: MineCoursesComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
        ],
      },
      { path: 'create-course', component: AddCourseComponent },
      { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'logout', component: LogoutComponent },
      {
        path: 'internships',
        component: EmployerInternshipsComponent,
      },
      { path: 'internships/:hrid', component: InternshipDetailsComponent },
      // {
      //   path: 'internships',
      //   component: InternshipsComponent,
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: '/employer/internships/all-internships',
      //       pathMatch: 'full',
      //     },
      //     {
      //       path: 'all-internships',
      //       component: AllInternshipsComponent,
      //       children: [
      //         {path: '', component: ActiveInternshipsComponent},
      //         {path: 'active-internships', component: ActiveInternshipsComponent},
      //         {path: 'archive-internships', component: ArchiveInternshipsComponent},
      //       ],
      //     },
      //     {
      //       path: 'mine-internships',
      //       component: MineInternshipsComponent,
      //       children: [
      //         {path: '', component: ActiveInternshipsComponent},
      //         {path: 'active-internships', component: ActiveInternshipsComponent},
      //         {path: 'archive-internships', component: ArchiveInternshipsComponent},
      //       ],
      //     },
      //   ],
      // },
      { path: 'create-internship', component: AddInternshipComponent },
      { path: 'edit-internship/:hrid', component: EditInternshipComponent, pathMatch: 'full' },
      { path: 'copy-internship/:hrid', component: EditInternshipComponent, pathMatch: 'full' },
    ],
  },
  {
    path: 'tutor',
    component: TutorPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['tutor'] },
    children: [
      { path: '', component: TutorPupilsComponent },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'logout', component: LogoutComponent },
    ],
  },
  {
    path: 'parent',
    component: ParentPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['parent'] },
    children: [],
  },
  { path: 'parent/logout', component: LogoutComponent },
  { path: 'parent/mosrupromo', component: PromoTestComponent, canActivate: [MosruGuard] },
  { path: 'vorobievi-gori/mosrupromo', component: PromoTestComponent },
  {
    path: 'parent/:uid',
    component: ParentPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['parent'] },
  },
  {
    path: 'parent/:uid/:menu',
    component: ParentPanelComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['parent'] },
  },
  {
    path: 'child-registration',
    component: ChildRegistrationComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['parent'] },
  },
  {
    path: 'cityadmin',
    component: CityAdminComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['cityadmin'] },
    children: [],
  },
  { path: 'cityadmin/logout', component: LogoutComponent },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'update-user-info', component: UpdateUserInfoComponent },
];
