import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ClipboardService } from 'ngx-clipboard';
import {
  ApiCoursesMaterialsService,
  IData,
  IGetCourseByClassResponse,
  ISchoolClass,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { IAssigningCourseData } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-copy-pupils-invitation',
  templateUrl: './copy-pupils-invitation.component.html',
  styleUrls: ['./copy-pupils-invitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyPupilsInvitationComponent extends UnsubscribeComponent implements OnInit {
  public showSharePopup: boolean = false;
  public linkCopied: boolean = false;
  public successSend: boolean = false;
  public courseIds: string[];
  public loading: boolean = true;
  public class: string = '';
  public isUserFromSalesB2S: boolean = false;
  private regionId: string = '';
  public dictionary: DictionaryType;
  public _schoolClassId: string = '';

  @Input() public shortRegLink: string = '';
  @Input() public pupilsLength: number = 0;

  @Input()
  public set schoolClassId(schoolClassIdProp: string) {
    if (schoolClassIdProp) {
      this._schoolClassId = schoolClassIdProp;
      this.getCourseByClass();
    } else {
      this.loading = false;
    }
  }

  public texts: Record<string, string> = {
    DEFAULT: `проходит профориентационный курс "Россия - мои горизонты", в рамках которого
         ребята узнают об особенностях образования и рынка труда нашего региона.
         Зарегистрируйте вашего ребенка, чтобы он получил доступ к необходимой для прохождения курса информации и диагностикам.
         Регистрация займет 2 минуты, перейдите по ссылке ниже и заполните форму.`,
    SALES_B2S: `будет проходить курс «Карьерная грамотность».
     Чтобы ваш ребенок получил доступ к учебным материалам и рекомендациям по профессиям,
      вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.  `,
    DIAGNOSTIC: `Для нашего класса бесплатно проводят тест на профориентацию.\r\n
     В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете посмотреть результаты.\r\n ️
      Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.\r\n`,
  };

  constructor(
    private clipboardService: ClipboardService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private changeDetectorRef: ChangeDetectorRef,
    private webStorageService: WebStorageService,
    private ramStorageService: RamStorageService,
    private userActionsService: UserActionsService,
    private rootScopeService: RootScopeService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.dictionary = this.rootScopeService.getDictionary();
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    this.isUserFromSalesB2S = this.regionId === '2683eee3-306b-49a3-a7b9-7ce28df17251';
    this.ramStorageService
      .get(StorageKeys.CurrentClassInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((classInfo: IAssigningCourseData) => {
        this.class = (classInfo.schoolClass as ISchoolClass).className;
      });
  }

  public getCourseByClass(): void {
    this.apiCoursesMaterialsService
      .getCourseByClass(this._schoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (courseIds: IGetCourseByClassResponse) => {
          this.courseIds = courseIds.courseIds;
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.loading = false;
        },
      );
  }

  public openCopyInvitationPopup(): void {
    document.body.classList.add('fixed-body');
    this.showSharePopup = true;
  }

  public copyInvitation(): void {
    const copyTextKG: string = `Здравствуйте, уважаемые родители ${this.class} класса!\r\n` + 'Наш класс ' + this.texts.DEFAULT;

    const copyTextKGFromB2S: string = `Здравствуйте, уважаемые родители ${this.class} класса!\r\n` + 'Наш класс ' + this.texts.SALES_B2S;

    const copyTextDiagnostic: string = `Уважаемые родители ${this.class} класса!\r\n` + this.texts.DIAGNOSTIC;

    this.clipboardService.copy(
      `${!this.courseIds?.length ? copyTextDiagnostic : this.isUserFromSalesB2S ? copyTextKGFromB2S : copyTextKG} ${this.shortRegLink}`,
    );
    this.linkCopied = !this.linkCopied;
    this.successSend = true;
    this.showSharePopup = true;

    const ymItem: YmItems = this.pupilsLength >= 2 ? YmItems.T_Class_invitationInNotEmptyClass : YmItems.T_Class_InvitationInEmptyClass;
    const uaParams: IData = {
      SchoolClassId: this.schoolClassId,
    };
    this.userActionsService.log(ymItem, uaParams);
  }

  public closePopup(status?: string): void {
    document.body.classList.remove('fixed-body');
    if (status === 'done') {
      this.showSharePopup = !this.showSharePopup;
      this.linkCopied = !this.linkCopied;
      this.successSend = false;
      return;
    }

    this.showSharePopup = !this.showSharePopup;
  }
}
