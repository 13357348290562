<div class="change-password">
  <div *ngIf="isKruzhkiForm" class="kruzhki-logos hide-mobile">
    <a class="logo" href="https://www.mos.ru/" target="_blank">
      <img src="./assets/images/registration/depkult_gerb.svg" alt="logo" />
    </a>
    <a class="logo" href="https://mos-razvitie.ru/" target="_blank">
      <img src="./assets/images/registration/mosrazvitie.svg" class="logo" alt="logo" />
    </a>
  </div>
  <div class="change-password__wrapper" [ngClass]="{ 'with-margin-bottom': isKruzhkiForm }">
    <a *ngIf="isResetPassword && !(currentStep === steps.Step2) && !isKruzhkiForm" href="https://profilum.ru" class="change-password__logo">
      <img src="./profilum-assets/images/registration/profilum_logo_big.svg" alt="PROFILUM" />
    </a>
    <h2 *ngIf="currentStep === steps.Step1" class="change-password__header">
      {{ userRole !== 'pupil' ? 'Придумайте' : 'Придумай' }}<span *ngIf="isResetPassword"> новый</span> пароль
    </h2>
    <div class="change-password__form-wrapper">
      <ng-container *ngIf="currentStep === steps.Step1; else passwordChanged">
        <form [formGroup]="form" autocomplete="off" class="change-password__form">
          <div class="prf-form-control">
            <label class="prf-form-label" for="newPassword" *ngIf="formControls.newPassword.value">
              {{ (isResetPassword ? 'SHARED.FORM.NEW_PASSWORD' : 'SHARED.FORM.PASSWORD') | translate }}
            </label>
            <input
              class="prf-form-input"
              [type]="isMaskedNewPassword ? 'password' : 'text'"
              [class.error-field]="focusOutPasswordErrors || formControls.newPassword.errors"
              name="newPassword"
              data-name="newPassword"
              id="newPassword"
              formControlName="newPassword"
              [placeholder]="(isResetPassword ? 'SHARED.FORM.NEW_PASSWORD' : 'SHARED.FORM.PASSWORD') | translate"
              autocomplete="off"
              (ngModelChange)="testNewPassword($event)"
              (focusout)="focusOutErrorChecking()"
            />
            <div
              class="prf-form-control-img"
              [class.prf-form-control-img_closed]="!isMaskedNewPassword"
              (click)="toggleNewPassMask()"
            ></div>
            <mat-error *ngIf="focusOutPasswordErrors">Проверьте правильность введенных данных</mat-error>
            <mat-error *ngIf="isSameAsCode">Новый пароль не должен совпадать с кодом регистрации</mat-error>
            <mat-error *ngIf="errorChangePass">{{ errorChangePass }}</mat-error>
          </div>

          <ul class="prf-form-password-validators">
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!charactersError">
              от 6 символов
            </li>
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!letterError">
              строчные и заглавные латинские буквы
            </li>
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!numberError">
              1 цифра
            </li>
          </ul>

          <div class="prf-form-control">
            <label class="prf-form-label" for="repeatPassword" *ngIf="formControls.repeatPassword.value">
              {{ changePasswordText | translate }}
            </label>
            <input
              class="prf-form-input"
              [type]="isMaskedRepeatPassword ? 'password' : 'text'"
              [class.error-field]="formControls.repeatPassword.touched && (incorrectRepeatPassword || formControls.repeatPassword.errors)"
              name="repeatPassword"
              data-name="repeatPassword"
              id="repeatPassword"
              formControlName="repeatPassword"
              [placeholder]="changePasswordText | translate"
              autocomplete="off"
              (ngModelChange)="testRepeatPassword()"
              (focusout)="focusOutErrorChecking()"
            />
            <div
              class="prf-form-control-img"
              [class.prf-form-control-img_closed]="!isMaskedRepeatPassword"
              (click)="toggleRepeatPassMask()"
            ></div>
            <mat-error *ngIf="incorrectRepeatPassword">Пароли не совпадают</mat-error>
          </div>
        </form>
      </ng-container>
      <ng-template #passwordChanged>
        <div class="change-password__success">
          <div class="change-password__success-icon"></div>
          <h2 class="change-password__header">Пароль {{ isResetPassword ? 'изменен!' : 'установлен' }}</h2>
          <ng-container *ngIf="isResetPassword; else changedPassword">
            <p class="change-password__text">Используйте его для входа в систему</p>
          </ng-container>
          <ng-template #changedPassword>
            <p class="change-password__text">
              Для входа в личный кабинет используй<span *ngIf="userRole !== 'pupil'">те</span> этот пароль и номер телефона
            </p>
          </ng-template>
        </div>
      </ng-template>

      <button
        type="button"
        class="prf-btn-primary change-password__button"
        [class.vertical-centered]="isKruzhkiForm && !isMobile"
        [class.prf-btn-primary_disabled]="!isAccessAllowed() || updatingPassword"
        (click)="continue()"
      >
        {{ currentStep === steps.Step1 || isResetPassword ? 'Продолжить' : 'В личный кабинет' }}
      </button>
    </div>
  </div>
  <span *ngIf="isKruzhkiForm && !isMobile" class="prf-start-page-buttons-footer">
    Проводится на базе платформы
    <a [routerLink]="['/']" target="_blank">
      <img src="./profilum-assets/images/registration/profilum_logo_small.svg" alt="PROFILUM" />
    </a>
  </span>
  <prf-page-footer *ngIf="!isMobile" [isNoPaddingTop]="true"></prf-page-footer>
</div>
