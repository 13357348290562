<div class="testing-buttons-div" [class.bvb-button-wrapper]="isBvbTest || isBvbTestVisuallyImpaired">
  <ng-template [ngIf]="!showStartDiagnosticButton" [ngIfElse]="startDiagnosticButton">
    <a
      class="button testing-button back white-button w-button"
      routerLink="/{{ userRole }}"
      [class.bvb-button_disabled]="isBvbTest"
      [class.bvb-visually-impaired-button_disabled]="isBvbTestVisuallyImpaired"
      *ngIf="disabled || !enableBack; else enabledBack"
    >
      {{ 'BUTTONS.PREVIOUS' | translate }}
    </a>
    <ng-template #enabledBack>
      <a
        class="button testing-button back white-button w-button"
        [class.bvb-button_back]="isBvbTest"
        [class.bvb-visually-impaired-button_back]="isBvbTestVisuallyImpaired"
        (click)="back()"
      >
        {{ 'BUTTONS.PREVIOUS' | translate }}
      </a>
    </ng-template>

    <a
      class="button testing-button next w-button disabled"
      *ngIf="!isValidField || disabled || !enableForward; else enabledForward"
      [class.bvb-button_next]="isBvbTest"
      [class.bvb-visually-impaired-button_next]="isBvbTestVisuallyImpaired"
    >
      {{ 'BUTTONS.NEXT' | translate }}
    </a>
    <ng-template #enabledForward>
      <a *ngIf="isFinish && !isBvbTest && !isBvbTestVisuallyImpaired" class="button testing-button w-button" (click)="forward()">
        {{ 'BUTTONS.OK' | translate }}
      </a>
      <a
        *ngIf="!isFinish"
        class="button testing-button next w-button"
        [class.bvb-button_next]="isBvbTest"
        [class.bvb-visually-impaired-button_next]="isBvbTestVisuallyImpaired"
        (click)="forward()"
      >
        {{ 'BUTTONS.NEXT' | translate }}
      </a>

      <a
        *ngIf="isFinish && (isBvbTest || isBvbTestVisuallyImpaired)"
        class="button testing-button finish bvb-button_next"
        [class.bvb-button_next]="isBvbTest"
        [class.bvb-visually-impaired-button_next]="isBvbTestVisuallyImpaired"
        (click)="forward()"
      >
        Завершить
      </a>
    </ng-template>
  </ng-template>
  <ng-template #startDiagnosticButton>
    <a *ngIf="!isValidField || disabled || !enableForward; else enabledForward" class="button testing-button w-button disabled">
      Пройти диагностику
    </a>
    <ng-template #enabledForward>
      <a class="button testing-button w-button" (click)="forward()"> Пройти диагностику </a>
    </ng-template>
  </ng-template>
</div>
