<div class="notification-popup__overlay"></div>
<div class="notification-popup__wrapper">
  <div class="notification-popup__content">
    <h1 class="notification-popup__header" [innerHTML]="headerText"></h1>
    <div>
      <p *ngFor="let text of mainText; let i = index; trackBy: trackByFn" class="notification-popup__text" [innerHTML]="text"></p>
    </div>
    <div class="notification-popup__actions">
      <button *ngIf="secondaryButtonText" class="notification-popup__button" type="reset" (click)="buttonAction.emit('close')">
        {{ secondaryButtonText }}
      </button>
      <button
        class="notification-popup__button"
        [ngClass]="'notification-popup__button_' + mainButtonColor"
        type="submit"
        (click)="buttonAction.emit('next')"
      >
        {{ mainButtonText }}
      </button>
    </div>
  </div>
</div>
