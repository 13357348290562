<div class="diagnostic-results-accordion">
  <div class="diagnostic-results-accordion__header">
    <div class="diagnostic-results-accordion__text-container">
      <p *ngIf="result?.subtitleText" class="diagnostic-results-accordion__subtitle">
        {{ result.subtitleText }}
      </p>
      <p *ngIf="result?.titleText" class="diagnostic-results-accordion__title">
        {{ result.titleText }}
      </p>
      <div *ngIf="result?.descriptionText" class="diagnostic-results-accordion__description">
        <div class="diagnostic-results-accordion__info-icon" [ngClass]="'diagnostic-results-accordion__info-icon_' + result.status"></div>
        {{ result.descriptionText }}
      </div>
    </div>
    <button
      (click)="toggle()"
      class="diagnostic-results-accordion__icon w-icon-slider-right"
      [class.diagnostic-results-accordion__icon_opened]="isOpened"
      [class.hidden]="result.hideToggle"
    ></button>
  </div>

  <div class="diagnostic-results-accordion__chart" [class.hidden]="!isOpened">
    <ng-container *ngIf="result?.chartType === 'doughnut'; else answersBars">
      <prf-results-objecttype-chart-and-bars
        [status]="result?.status"
        [data]="result?.data"
        [showByPupil]="true"
      ></prf-results-objecttype-chart-and-bars>
    </ng-container>
    <ng-template #answersBars>
      <prf-results-answers-bars
        isSingleColor="true"
        [color]="color_turquoise"
        [status]="result?.status"
        [data]="result?.data"
        [showByPupil]="true"
      ></prf-results-answers-bars>
    </ng-template>
  </div>
</div>
