import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  ApiFavoritesService,
  ApiProfessionsService,
  ApiProfilesService,
  AppSettingsService,
  FavoritesDataHandlerService,
  IData,
  IProfession,
  ProfessionsService,
  StorageKeys,
  UserActionsService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  AddFirstProfessionModalService,
} from 'app/shared/dashboard/professions/welcome-choise-profession/add-first-profession-modal/add-first-profession-modal.service';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import {
  IVariant,
} from '../../../../shared/common-components/discover-intro-recommendations/discover-intro-recommendations.component';

@Component({
  selector: 'prf-profession-detail-mobile',
  templateUrl: './profession-detail-mobile.component.html',
  styleUrls: ['./profession-detail-mobile.component.scss'],
})
export class ProfessionDetailMobileComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public userId: string;
  private userRole: string = '';

  @Input() public profession: IProfession;
  public contentMenu: string[] = [];
  public currentContent: string;
  public dataFetched: boolean = false;
  public videoList: any;
  public personsList: any;
  private isGoal: boolean = false;
  public currentFavoriteIds: any = [];
  public hrid: string;
  public testCompleted: boolean = false;
  public favorites: Array<any> = [];
  public showSalary: boolean = false;
  public ymItems = YmItems;

  public favorite: boolean = false;
  private favoriteId: string = '';

  public imgPath: string = '';
  public isBvb: boolean = false;
  public showBvbRecommendations: boolean = false;
  public professionOptions;

  @ViewChild('profInfoMenu') infoMenu: ElementRef;

  constructor(
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private goalsService: GoalsService,
    private professionService: ProfessionsService,
    public route: ActivatedRoute,
    private router: Router,
    private apiFavoritesService: ApiFavoritesService,
    private addFirstProfessionModalService: AddFirstProfessionModalService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private locationI: Location,
    private yandexMetricsService: YandexMetricsService,
    private webStorageService: WebStorageService,
    private userActionsService: UserActionsService,
    private apiProfessionsService: ApiProfessionsService,
    private apiProfilesService: ApiProfilesService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Детали профессии' });
    this.isBvb = location.pathname.includes('bvb');
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.professionOptions = this.webStorageService.get(StorageKeys.ProfessionOptions);
    const sessionStatus: string = this.webStorageService.get(StorageKeys.SessionStatus);

    this.testCompleted = sessionStatus === 'Success';
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.route.params
      .pipe(
        mergeMap(params => {
          window.scrollTo(0, 0);
          this.hrid = params['hrid'];
          this.dataFetched = false;
          return this.loadProfession();
        }),
      )
      .subscribe(() => this.overlayService.hide());

    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
    this.userActionsService.setInitializationTime([YmItems.S_ProfessionsCard_Likes]);
  }

  private loadProfession(): Observable<any> {
    return this.apiProfessionsService.getProfessionByHridMongo(this.hrid).pipe(
      mergeMap(job => {
        let currentObservable$: Observable<any>;
        if (job) {
          window.scrollTo(0, 0);
          this.prepareMenu();
          this.professionService.setCurrentProfession(this.profession);
          currentObservable$ = this.checkGoal();
          this.imgPath = this.imagePath();
          this.isBvb ? this.getBvbFavorite() : this.loadFavorites();
        } else {
          currentObservable$ = of(null);
        }
        return currentObservable$;
      }),
    );
  }

  public checkGoal(): Observable<any> {
    if (!this.userRole) {
      return of(null);
    }

    return of(() => {
      this.favorites = this.favoritesDataHandlerService.getFavoriteProfessions().getValue();
      if (this.favorites.length > 0) {
        // Проверка текущей профессии добавлена ли она в избранное
        const favoritesProfessionsIds = this.favorites.map(el => el.productId);
        this.isGoal = favoritesProfessionsIds.indexOf(this.profession.id) > -1;
        // Получаем Ids избранных с текущей профессией (могут быть дубляжи)
        const filteredFavorites = this.favorites.filter(el => el.productId === this.profession.id);
        this.currentFavoriteIds = filteredFavorites.map(el => el.id);
      }
    });
  }

  private prepareMenu(): void {
    this.contentMenu = [];
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.contentMenu.push('Видео');
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.movies.length > 0) {
      this.contentMenu.push('Фильмы');
    }
    if (this.profession.persons.length > 0) {
      this.contentMenu.push('Профессия в лицах');
      this.personsList = this.profession.persons.slice(0, 2);
    }
    if (this.profession.articles.length > 0) {
      this.contentMenu.push('Книги и статьи');
    }
    this.currentContent = this.contentMenu[0];
    this.dataFetched = true;
  }

  public prepareData(): void {
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.persons.length > 0) {
      this.personsList = this.profession.persons.slice(0, 2);
    }
  }

  public scroll(): void {
    this.infoMenu.nativeElement.scrollIntoView();
  }

  public checkPopup(event, popup): void {
    if (!popup.contains(event.target)) {
      popup.style.display = 'block';
    }
  }

  public sanitizeUrl(url: string) {
    const trustedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return trustedUrl;
  }

  public loadFavorites(): void {
    const favorites = this.favoritesDataHandlerService.getFavoriteProfessions().getValue();
    favorites.forEach(prof => {
      if (this.profession.id === prof.productId) {
        this.favorite = true;
        this.favoriteId = prof.id;
      }
    });
  }

  public goBack(): void {
    this.locationI.back();
  }

  private getBvbFavorite(): void {
    this.apiProfilesService
      .getClassesFormatsForProfessionByUserId(this.userId)
      .pipe(
        map(classesFormats =>
          classesFormats.classesFormatsForProfessions.map(classesFormatsForProfession => classesFormatsForProfession.professionId),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe(professions => {
        this.favorite = professions.includes(this.profession.id);
      });
  }

  public updateFavorites(): void {
    if (this.isBvb) {
      if (this.favorite) {
        this.apiProfilesService
          .deleteClassesFormatsForProfessions(this.userId, this.profession.id)
          .pipe(take(1), takeUntil(this.unsubscribe))
          .subscribe();
        this.favorite = false;
      } else {
        this.showBvbRecommendations = true;
      }
    } else {
      if (this.favorite) {
        this.deleteFromFavorite();
      } else {
        this.addToFavorites();
      }
    }
  }

  public createClassesFormat(variants: IVariant[]) {
    this.closeBvbRecommendation();
    const request = {
      classesFormatsForProfession: {
        userId: this.userId,
        professionId: this.profession.id,
        classFormats: variants.map(variant => variant.text),
      },
    };
    this.apiProfilesService.createClassesFormatsForProfessions(request).pipe(take(1), takeUntil(this.unsubscribe)).subscribe();
    this.favorite = true;
  }

  public addToFavorites(): void {
    this.showBvbRecommendations = false;
    this.apiFavoritesService
      .addToFavorites(this.profession.id, '3', this.userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.favorite = true;

        const uaParams: IData = {
          ProfessionId: this.profession.id,
        };
        const ymParams: IData = {
          event_label: { Profession: this.profession.id },
        };
        this.userActionsService.log(YmItems.S_ProfessionsCard_Likes, uaParams, ymParams);
      });
    this.loadFavorites();
  }

  public deleteFromFavorite(): void {
    this.apiFavoritesService
      .deleteFromFavorites(this.favoriteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.favorite = false;
        this.yandexMetricsService.reachGoal(YmItems.S_ProfessionsCard_NotLikes); //
      });
    this.loadFavorites();
  }

  public closeBvbRecommendation(): void {
    this.showBvbRecommendations = false;
  }

  public imagePath(): string {
    const length = this.profession?.images?.length;
    return length ? this.profession.images[length - 1].path : '';
  }
}
