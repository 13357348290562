<div *ngIf="activeTimer" class="timer-value" [style.margin]="'20px 0 20px 50px'">
  {{ timer$ | async }}
</div>

<ul class="params-list">
  <li *ngFor="let param of routeParams" class="param-item">
    <span>{{ param.paramName + ': ' }}</span>
    <span>{{ param.paramValue }}</span>
  </li>
</ul>
