import { saveAs } from 'file-saver';
import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { takeUntil } from 'rxjs/operators';
import { ApiUsersService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

export interface IContent {
  title: string;
  desc: string;
  button: string;
}

@Component({
  selector: 'prf-class-widget',
  templateUrl: './class-widget.component.html',
  styleUrls: ['./class-widget.component.scss'],
})
export class ClassWidgetComponent extends UnsubscribeComponent {
  public showSharePopup: boolean = false;
  public linkCopied: boolean = false;
  public successSend: boolean = false;
  public loading: boolean = false;
  public content: IContent = null;
  @Input() schoolClassId: string;
  private copyText: string =
    'Уважаемые родители! \r\n' +
    'Для нашего класса бесплатно проводят тест на профориентацию.\r\n' +
    'В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете посмотреть результаты.\r\n' +
    '❗️ Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.\r\n';

  @Input() set data(value: any) {
    if (value) {
      this.content = value;
    }
  }

  @Input() shortRegLink: string = '';
  @Input() showSmall: boolean = false;
  @Input() access: boolean = false;
  @Input() status: string = '';

  constructor(private clipboardService: ClipboardService, private apiUsersService: ApiUsersService) {
    super();
  }

  public closePopup(status?: string): void {
    if (status === 'done') {
      this.showSharePopup = !this.showSharePopup;
      this.linkCopied = !this.linkCopied;
      this.content.title = 'Приглашение скопировано. Пригласить ещё раз?';
      this.successSend = false;
      return;
    }

    this.showSharePopup = !this.showSharePopup;
  }

  public copyPupilRegLink(): void {
    this.clipboardService.copy(this.copyText + ' ' + this.shortRegLink);
    this.linkCopied = !this.linkCopied;
    this.successSend = true;
  }

  public getDataUnregistered(): void {
    this.loading = true;
    this.apiUsersService
      .getDataUnregisteredTeachers(this.schoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        const blob = new Blob([response.body], { type: response.headers.get('content-type') });
        const file = new File([blob], 'Доступы_для_учеников', {
          type: response.headers.get('content-type'),
        });
        saveAs(file);
        setTimeout(() => (this.loading = false), 500);
      });
  }
}
