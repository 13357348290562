import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { CoursesFiltersService } from '../courses-filter/courses-filters.service';
import { IFilterClasses } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

// минимальная длина строки поиска для осуществления запроса
export const MinSearchLength = 3;

//поиск в каталоге ученика, родителя
@Component({
  selector: 'prf-courses-search',
  templateUrl: './courses-search.component.html',
  styleUrls: ['./courses-search.component.scss'],
})
export class CoursesSearchComponent extends UnsubscribeComponent implements OnInit {
  public searchField: UntypedFormControl;
  private filters: IFilterClasses;
  public empty: boolean = false;

  constructor(private filtersService: CoursesFiltersService) {
    super();
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.filtersService
      .getCoursesFilters()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(filters => {
          this.filters = filters;

          return this.searchField.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            map(term => {
              if (term && term.length != 0 && term.length >= MinSearchLength) {
                this.filters.query = term;
                this.filtersService.setCoursesFilter(this.filters);
              } else if (!term || (term && term.length === 0)) {
                this.filters.query = term;
                this.filtersService.setCoursesFilter(this.filters);
              }
            }),
          );
        }),
      )
      .subscribe();
  }

  clearSearch() {
    this.searchField.setValue(null);
    this.filters.query = null;
    this.filtersService.setCoursesFilter(this.filters);
  }
}
