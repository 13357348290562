import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable, Subject } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { ApiProfilesService, RamStorageService, StorageKeys, UserDataHandlerService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import {
  PupilMobileEditProfileMenuComponent,
} from '../pupil-mobile-edit-profile-menu/pupil-mobile-edit-profile-menu.component';
import { ImageCropperV2Component } from '../../../../../ui-kit/components/image-cropper-v2/image-cropper-v2.component';
import { UtilsService } from '../../../../../shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-pupil-mobile-edit-user-avatar',
  standalone: true,
  imports: [CommonModule, PupilMobileEditProfileMenuComponent, ImageCropperV2Component],
  templateUrl: './pupil-mobile-edit-user-avatar.component.html',
  styleUrls: ['./pupil-mobile-edit-user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PupilMobileEditUserAvatarComponent extends UnsubscribeComponent {
  public uploadedPhoto$: Observable<FileList>;
  public cropImageSubject: Subject<boolean> = new Subject<boolean>();
  @Output() return = new EventEmitter<any>();
  @Output() saveAvatar = new EventEmitter<any>();

  constructor(
    private userDataHandlerService: UserDataHandlerService,
    private apiProfilesService: ApiProfilesService,
    private utilsService: UtilsService,
    private ramStorageService: RamStorageService,
  ) {
    super();
    this.uploadedPhoto$ = this.ramStorageService.get(StorageKeys.UploadedOriginalUserPhoto).pipe(
      filter((uploadedOriginalUserPhoto: FileList) => {
        if (!uploadedOriginalUserPhoto) {
          this.canselChanges();
          return false;
        }

        return true;
      }),
    );
  }

  public editUserPhoto(event: FileList): void {
    if (event[0].size > 1024 * 1024) {
      this.utilsService.openSnackBar('👎 Размер не болле: 1 МB', 'error');
      return;
    }

    this.apiProfilesService
      .changeUserAvatar(event)
      .pipe(
        finalize(() => {
          this.saveChanges();
        }),
        take(1),
      )
      .subscribe(() => this.userDataHandlerService.fetchUserInfo());
  }

  public submitChanges(): void {
    this.cropImageSubject.next(true);
  }

  private saveChanges(): void {
    this.saveAvatar.emit();
  }

  public canselChanges(): void {
    this.ramStorageService.clear(StorageKeys.UploadedOriginalUserPhoto);
    this.return.emit();
  }
}
