<div *ngIf="dataLoaded && showResultMenu" class="professions-menu">
  <div class="professions-menu-row-2">
    <a (click)="resultMenu = 'results'" [class.w--current]="resultMenu === 'results'" class="professions-menu-link w--current">
      {{ 'SHARED.RESULT.S' | translate }}
    </a>
    <a (click)="resultMenu = 'recommendations'" [class.w--current]="resultMenu === 'recommendations'" class="professions-menu-link">
      {{ 'SHARED.RECOMMENDATIONS' | translate }}
    </a>
  </div>
</div>

<!-- Блок отбражения результатов TODO - вынести в отдельный компонент -->
<div *ngIf="dataLoaded" [class.no-display]="resultMenu !== 'results'">
  <ng-template [ngIf]="!openResults" [ngIfElse]="openResultsBlock">
    <div class="result-container">
      <prf-test-switch
        *ngIf="showSwitch"
        [user]="child"
        [checkParent]="showParent"
        [checkPupil]="showPupil"
        [isCompleteTestParent]="isCompleteTestParent"
        [isCompleteTestPupil]="isCompleteTestPupil"
        (clickParent)="showParent = $event"
        (clickPupil)="showPupil = $event; checkResults()"
        class="test-switch"
      >
      </prf-test-switch>
      <a routerLink="/{{ userInfo.role }}" class="testing-logo-link w-inline-block"> </a>
      <!--<a (click)="printer()" class="button white-button print-btn-2 w-button">Распечатать</a>-->
      <div class="results-wrapper scrollable-container">
        <ng-container *ngIf="!nullResults; else emptyResults">
          <div *ngIf="showResultsHeader" class="test-result-block">
            <h1 class="test-result-h1">{{ 'TEST_AND_RESULTS.RESULTS' | translate }}</h1>
            <div *ngIf="userInfo.role === 'pupil'">
              <p class="test-result-text _w-700">
                {{ 'TEST_AND_RESULTS.PUPIL_TEXT' | translate }}
              </p>
            </div>
            <div *ngIf="userInfo.role === 'parent'">
              <p class="test-result-text _w-700">
                {{ 'TEST_AND_RESULTS.PARENT_TEXT' | translate }}
              </p>
            </div>
          </div>

          <div class="test-result-block" *ngIf="skillsAttainmentData[0]?.length || skillsAttainmentData[1]?.length">
            <h2
              class="test-result-h1"
              *ngIf="
                testType !== testTypesEnum.MINCIFRYDEMO_TEST.toString() || testType !== testTypesEnum.CAMP_TEST.toString();
                else mincifrydemoTitle
              "
            >
              {{ 'TEST_AND_RESULTS.SKILLS_ABILITIES' | translate }}
            </h2>
            <div *ngIf="userInfo.role === 'pupil'">
              <p class="test-result-text _w-700">
                {{ 'TEST_AND_RESULTS.PUPIL_SCHEME_TEXT' | translate }}
              </p>
            </div>
            <div *ngIf="userInfo.role === 'parent'">
              <p class="test-result-text _w-700">
                {{ 'TEST_AND_RESULTS.PARENT_SCHEME_TEXT' | translate }}
              </p>
            </div>

            <ng-template
              [ngIf]="testType === testTypesEnum.MINCIFRYDEMO_TEST.toString() || testType === testTypesEnum.CAMP_TEST.toString()"
            >
              <p class="test-result-text _w-700">
                У каждого из нас есть свои навыки и области применения, где можно реализовать свои сильные стороны. На диаграмме ты можешь
                увидеть, какими навыками ты сейчас обладаешь и отметить свои зоны потенциала.
              </p>
            </ng-template>

            <div *ngIf="!showParent || !showPupil" class="test-result-diagram _2">
              <prf-spider-chart-custom
                [inputSpiderChartData]="skillsAttainmentData"
                [theme]="showParent ? SpiderChartThemeEnum.BLUE : SpiderChartThemeEnum.GREEN"
                [figureType]="SpiderChartFiguresEnum.HEXAGON"
              ></prf-spider-chart-custom>
            </div>

            <div *ngIf="showParent && showPupil" class="test-result-diagram">
              <div class="diagram-column-2">
                <prf-spider-chart-custom
                  [inputSpiderChartData]="skillsAttainmentData"
                  [figureType]="SpiderChartFiguresEnum.HEXAGON"
                  [isDoubleResultsVariant]="true"
                ></prf-spider-chart-custom>

                <div class="diagram-row">
                  <div class="diagram-column">
                    <div class="diagram-color-oval"></div>
                    <div>
                      {{ 'TEST_AND_RESULTS.CHILD_RESULTS' | translate }}
                    </div>
                  </div>
                  <div class="diagram-column">
                    <div class="diagram-color-oval blue"></div>
                    <div>
                      {{ 'TEST_AND_RESULTS.PARENT_RESULTS' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="diagram-info">
                <img src="./profilum-assets/images/icons/diagr-info-icon.svg" class="diagram-info-icon" />
                <p class="diagram-info-paragraph">
                  {{ 'TEST_AND_RESULTS.DIAGRAM_TEXT' | translate }}
                </p>
              </div>
            </div>
          </div>

          <prf-interests-progress-bar
            *ngIf="interestsData?.length"
            [title]="
              testType !== testTypesEnum.MINCIFRYDEMO_TEST.toString()
                ? ('SHARED.INTERESTS_AND_PREFERENCES' | translate)
                : 'Твои интересы и предпочтения'
            "
            [texts]="{
              first:
                (showOnlyParentResults
                  ? 'SHARED.INTERESTS_NARRATIVE_PARENTS_PSKOV_DIAGNOSTIC'
                  : !openResults && userInfo.role !== 'pupil' && userInfo.role !== 'parent' && !isMinCifry
                  ? 'SHARED.INTERESTS_NARRATIVE'
                  : 'SHARED.INTERESTS_NARRATIVE_MINCIFRI'
                ) | translate,
              second: !openResults && userInfo.role === 'pupil' ? ('SHARED.INTERESTS_NARRATIVE_PUPIL' | translate) : '',
              third: openResults || userInfo.role === 'parent' ? ('SHARED.INTERESTS_NARRATIVE_PARENTS' | translate) : ''
            }"
            [userInfo.role]="userInfo.role"
            [data]="interestsData"
            [showParent]="showParent"
            [showPupil]="showPupil"
            [mainProgressDescription]="'Результаты ребенка'"
            [additionalProgressDescription]="'Результаты родителя'"
          >
          </prf-interests-progress-bar>

          <div class="test-result-block">
            <prf-recommended-classes [testResults]="currentResults ? currentResults.results : []"></prf-recommended-classes>
          </div>

          <div *ngIf="additionalTextName" class="test-result-block">
            <h1 *ngIf="userInfo.role === 'pupil'" class="test-result-h1">
              {{ userInfo.role === 'pupil' ? ('SHARED.YOUR_TALENTS' | translate) : ('SHARED.CHILD_TALENTS' | translate) }}
            </h1>
          </div>

          <div *ngIf="isSchoolStaff" class="test-result-block">
            <h1 class="test-result-h1">
              {{ 'SHARED.TALENTS' | translate }}
            </h1>
          </div>

          <div class="results__professions">
            <div *ngFor="let talent of talentsData">
              <prf-recommended-professions-card [talent]="talent"></prf-recommended-professions-card>
            </div>
          </div>

          <div *ngIf="additionalText.length > 0" class="test-result-block">
            <div class="test-result-description-row">
              <p class="test-result-description-text" [innerHTML]="additionalText[0]"></p>
              <div class="test-result-description-column">
                <br />
                <!-- <p class="test-result-description-text" [innerHTML]="additionalText[1]"></p> -->
                <p class="test-result-description-text" [innerHTML]="talentsData[0].description"></p>
              </div>
              <div class="test-result-description-column">
                <br />
                <p class="test-result-description-text" [innerHTML]="talentsData[1].description"></p>
                <br />
                <p class="test-result-description-text" [innerHTML]="talentsData[2].description"></p>
              </div>
            </div>
          </div>

          <p *ngIf="userInfo.role === 'pupil'" class="after-testing-text">
            {{ 'TEST_AND_RESULTS.PUPIL_RECOMMENDATIONS' | translate }}
          </p>
          <p *ngIf="userInfo.role === 'parent'" class="after-testing-text">
            {{ 'TEST_AND_RESULTS.PARENT_RECOMMENDATIONS' | translate }}
          </p>

          <div *ngIf="showAfterTestingText">
            <a routerLink="{{ routeHome(userInfo.role) }}" class="button after-test w-button">{{ 'SHARED.ON_MAIN' | translate }}</a>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<!-- Блок отображения рекомендаций TODO - вынести в отдельный компонент -->
<div *ngIf="dataLoaded && showResultMenu" [class.no-display]="resultMenu !== 'recommendations'">
  <h1 class="test-result-h1">{{ 'SHARED.RECOMMENDED_PROFESSIONS' | translate }}</h1>
  <p *ngIf="userInfo.role !== 'pupil'" class="test-result-text _w-700">
    {{ 'SHARED.SELECTION_OF_PROFESSION_NARRATIVE_PARENT' | translate }}
  </p>
  <p *ngIf="userInfo.role === 'pupil'" class="test-result-text _w-700">
    {{ 'SHARED.SELECTION_OF_PROFESSION_NARRATIVE' | translate }}
  </p>
  <prf-recommended-professions
    [testResults]="currentResults ? currentResults.results : []"
    [pupilSessionId]="isCompleteTestPupil ? pupilSessionId : null"
    [childId]="child.userId"
  ></prf-recommended-professions>

  <prf-recommended-classes [testResults]="currentResults ? currentResults.results : []"></prf-recommended-classes>
</div>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>

<ng-template #mincifrydemoTitle>
  <h1 class="test-result-h1">Твои навыки и сильные стороны</h1>
</ng-template>

<ng-template #openResultsBlock>
  <div class="result-container">
    <div class="container container-900">
      <div class="test-result-block">
        <h2 class="test-open-result-title">
          {{ 'TEST_AND_RESULTS.SKILLS_ABILITIES' | translate }}
        </h2>
        <div>
          <p class="test-open-result-text">
            {{ 'TEST_AND_RESULTS.PARENT_SCHEME_TEXT_OPEN_RESULTS' | translate }}
          </p>
        </div>

        <div class="test-result-diagram _2">
          <prf-spider-chart-custom
            [theme]="showParent ? SpiderChartThemeEnum.BLUE : SpiderChartThemeEnum.GREEN"
            [inputSpiderChartData]="skillsAttainmentData"
            [figureType]="SpiderChartFiguresEnum.HEXAGON"
          ></prf-spider-chart-custom>
        </div>
      </div>

      <hr class="divider" />

      <prf-interests-and-skills [interestsAndSkillsData]="interestsData" [textData]="openResultsText"></prf-interests-and-skills>

      <hr class="divider" />

      <div class="test-result-block">
        <h2 class="test-open-result-title">
          {{ 'OPEN_RESULTS.TALENTS_CHILD_TITLE' | translate }}
        </h2>

        <p class="test-open-result-text">
          {{ 'OPEN_RESULTS.TALENTS_CHILD_DESC' | translate }}
        </p>

        <prf-recommended-classes [testResults]="currentResults ? currentResults.results : []"></prf-recommended-classes>
      </div>

      <div class="results__professions">
        <div *ngFor="let talent of talentsData">
          <prf-recommended-professions-card [talent]="talent"></prf-recommended-professions-card>
        </div>
      </div>

      <hr class="divider" />
    </div>
  </div>
</ng-template>
