<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm">
    <div class="admin-menu">
      <div class="admin-menu-row">
        <a class="professions-menu-link w--current">{{ 'SHARED.ACTIVE' | translate }}</a>
        <a class="professions-menu-link">{{ 'SHARED.ARCHIVE' | translate }}</a>
      </div>
      <div class="admin-menu-col">
        <a class="admin-add-event w-button" [routerLink]="['../../create-course']">{{ 'SHARED.ADD' | translate }}</a>
      </div>
    </div>
    <!-- SEARCH -->
    <prf-search-courses></prf-search-courses>

    <!-- FILTERS -->
    <prf-filters-courses></prf-filters-courses>

    <!--VIEW SETTINGS-->
    <div class="courses-row-1">
      <div class="courses-number">{{ classesCount }}</div>
      <div class="courses-column-1">
        <div class="gender-choice-row">
          <a (click)="viewValue = false" [class.active]="viewValue === false" #grid class="gender-button w-inline-block">
            <img *ngIf="!viewValue" src="./profilum-assets/images/courses/list-view-1-white.svg" />
            <img *ngIf="viewValue" src="./profilum-assets/images/courses/list-view-1-gray.svg" />
          </a>
          <a (click)="viewValue = true" [class.active]="viewValue === true" #list class="gender-button right w-inline-block">
            <img *ngIf="viewValue" src="./profilum-assets/images/courses/list-view-2-white.svg" />
            <img *ngIf="!viewValue" src="./profilum-assets/images/courses/list-view-2-gray.svg" />
          </a>
        </div>
      </div>
    </div>

    <!-- EVENTS -->
    <prf-events-courses [viewValue]="viewValue"></prf-events-courses>
  </div>
</div>
