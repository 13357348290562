import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { B2gSaasService } from '@profilum-library';

@Component({
  selector: 'prf-goal-recommendation',
  templateUrl: './goal-recommendation.component.html',
})
export class GoalRecommendationComponent implements OnInit {
  @Input() data;
  public recommendations: any;
  constructor(private b2gSaasService: B2gSaasService, private router: Router) {}

  ngOnInit() {
    this.b2gSaasService
      .getRecommendations('Profession')
      .pipe(take(1))
      .subscribe(recommendations => (this.recommendations = recommendations));
  }

  openGoal(name) {
    this.router.navigate([`/${name}`]);
  }
}
