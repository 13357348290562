<ng-container>
  <div class="director">
    <section class="director_section section_general" *ngIf="_schoolStatisticFlag">
      <div class="info-tab _classes">
        <div class="info-tab-title">{{ 'DIRECTOR.OF_CLASSES' | translate }}</div>
        <div class="info-tab-content">
          {{ schoolStatistic.classesCount }}
        </div>
      </div>
      <div class="info-tab _teachers">
        <div class="info-tab-title">{{ 'DIRECTOR.OF_TEACHERS' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.teachersCount }}</div>
      </div>
      <!-- <div class="info-tab _pupils">
        <div class="info-tab-title">{{ 'DIRECTOR.OF_REGISTERED_PUPILS' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.registeredPupilsCount }}</div>
      </div> -->
      <div class="info-tab _tested">
        <div class="info-tab-title">{{ 'DIRECTOR.OF_TESTED_PUPILS' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.completedTestsCount }}</div>
      </div>
      <div class="info-tab _trajectory">
        <div class="info-tab-title">{{ 'DIRECTOR.OF_PUPILS_WITH_TRAJECTORY' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.selectedProfessionsCount }}</div>
      </div>
    </section>
    <section class="director_section section_general" *ngIf="!_schoolStatisticFlag">
      Школьная статистика не рассчитана, обратитесь к администратору сервиса
    </section>

    <section class="director_section section_classes scrollable-container" *ngIf="mergingArrays">
      <div class="classes_header">
        <div class="clases_tabs_block">
          <!-- <div class="classes_infotab">
            <div class="info-tab-title">{{ 'DIRECTOR.PERCENT_TESTED' | translate }}</div>
            <div class="info-tab-content">{{ percentTested }}%</div>
          </div>
          <div class="classes_infotab">
            <div class="info-tab-title">{{ 'DIRECTOR.CREDIBILITY_LEVEL' | translate }}</div>
            <div class="info-tab-content">{{ percentCredibility }}%</div>
          </div> -->
        </div>
      </div>
      <div class="clear_float"></div>
      <div class="classes-section">
        <div class="content-row">
          <div class="classes-container">
            <div class="class-div">
              <div class="class-header _2" [class.fixed]="isFixed">
                <div class="class-row class-header-row-3">
                  <div class="class-column _9"></div>
                  <div class="class-column-class _4">
                    <div class="column-title">{{ 'SHARED.CLASS' | translate }}</div>
                  </div>
                  <div class="teacher-class-header">
                    <div>{{ 'CODE_REGISTRATION_KZ.TEACHER' | translate }}</div>
                  </div>
                  <div class="class-column _percents">
                    <div class="column-title">{{ 'SHARED.IN_CLASS' | translate }}</div>
                    <div class="class-test-row">
                      <div class="class-test-column">
                        <div>{{ 'SHARED.AT_ALL' | translate }}</div>
                      </div>
                      <div class="class-test-column">
                        <div>{{ 'SHARED.COMPLETED_TEST_PERCENT' | translate }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="class-column _6">
                    <div class="column-title">{{ 'SHARED.PUPILS_QUANTITY' | translate }}</div>
                    <div class="class-test-row">
                      <div class="class-test-column">
                        <div>{{ 'SHARED.AT_ALL' | translate }}</div>
                      </div>
                      <div class="class-test-column">
                        <div>{{ 'SHARED.COMPLEED_TEST' | translate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="classes-list-block _2">
                <div class="students-list">
                  <!--CLASS-->
                  <ng-container *ngIf="loadedClasses">
                    <div
                      class="class-list-item-2"
                      *ngFor="let mergingArray of mergingArrays; let i = index"
                      [ngClass]="mergingArrays.length === i ? 'no-underline' : ''"
                    >
                      <div class="class-row-2 classes-row">
                        <!--Number-->
                        <div class="class-column _9">
                          <!-- <div class="class-header-number _2"></div> -->
                        </div>
                        <!--Class name-->
                        <div class="class-row-div">
                          <a class="classes-column-class classes-link" [routerLink]="['../director/class', mergingArray.id]">
                            <div class="class-column _2">
                              <div class="class-number-column">{{ mergingArray.number }} «{{ mergingArray.letter }}»</div>
                            </div>
                          </a>

                          <!--Teacher name-->
                          <a class="classes-column-teacher classes-link" [routerLink]="['../director/class', mergingArray.id]">
                            <div class="classes-column-teacher-item">
                              <div
                                class="class-photo w-inline-block"
                                [ngStyle]="{
                                  'background-image': mergingArray && mergingArray.imagePath ? 'url(' + mergingArray.imagePath + ')' : ''
                                }"
                              ></div>
                              <div class="class-column-name">
                                <div>
                                  <span>{{ mergingArray.lastName }} {{ mergingArray.firstName }} {{ mergingArray.middleName }}</span>
                                </div>
                                <div *ngIf="mergingArray.lastName == null || mergingArray.firstName == null">
                                  {{ 'SHARED.CLASS_WITHOUT_TEACHER' | translate }}
                                </div>
                                <div class="advice is-register" *ngIf="mergingArray?.isActivated">
                                  {{ 'SHARED.REGISTER_COMPLETED' | translate }}
                                </div>
                                <div class="student-online-status not-register" *ngIf="!mergingArray?.isActivated">
                                  {{ 'SHARED.NOT_REGISTERED' | translate }}
                                </div>
                              </div>
                            </div>
                          </a>
                          <div class="class-column-percent">
                            <div class="class-test-row">
                              <div class="class-test-column test">
                                <div class="length-students">
                                  {{ mergingArray.totalPupilsCount }}
                                </div>
                              </div>
                              <div class="class-test-column test">
                                <div class="length-students-percent">{{ mergingArray.completedTestPercent }}%</div>
                              </div>
                            </div>
                          </div>

                          <div class="class-column _6">
                            <a class="class-test-row classes-link" [routerLink]="['../director/class', mergingArray.id]">
                              <div class="class-test-column test">
                                <div class="length-students">
                                  {{ mergingArray.totalPupilsCount }}
                                </div>
                              </div>
                              <div class="class-test-column test">
                                <div class="length-students-complete">
                                  {{ mergingArray.completedTestsCount }}
                                </div>
                              </div>
                            </a>
                          </div>

                          <!-- <div class="class-column _11">
                            <a
                              class="delete-class w-inline-block"
                              *ngIf="
                                mergingArray.totalPupilsCount == 0 && !mergingArray?.isActivated
                              "
                              (click)="deleteSchoolClass(mergingArray.id)"
                            >
                            </a>
                          </div> -->
                          <div class="class-line" [ngClass]="teachers.length === i ? 'no-underline' : ''"></div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
