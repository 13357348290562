<div class="static-notification">
  <div class="wrapper">
    <h3 class="title">{{ title }}</h3>
    <p class="text">{{ text }}</p>
    <h4 *ngIf="subtitle" class="subtitle">{{ subtitle }}</h4>
    <p *ngIf="additionalText" class="text">{{ additionalText }}</p>
    <button type="button" class="button" (click)="onButtonCLick()">
      <span *ngIf="tooltipText" [class.hide]="!showTooltip" class="tooltip"> {{ tooltipText }}</span>
      {{ buttonText }}
    </button>
  </div>
</div>
