import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  ApiAuthService,
  B2gSaasService,
  IMosRuUserData,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { loginCases } from 'app/pages/control-panel/parent/promo-test/promo-test.component';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-mosrulogin',
  template: '',
})
export class MosruLoginComponent extends UnsubscribeComponent implements OnInit {
  public authCode: string = '';
  public mosRuUrl = 'https://www.mos.ru/otvet-tehnologii/kak-povysit-status-uchetnoy-zapisi-na-mos-ru/';
  public MosRuUserData: IMosRuUserData = {
    userId: null,
    email: null,
    role: null,
    status: null,
    comment: null,
  };

  public mosRuUser: loginCases = loginCases.LOGIN;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private b2gSaasService: B2gSaasService,
    private apiAuthService: ApiAuthService,
    private utilsService: UtilsService,
    private webStorageService: WebStorageService,
    private userStorageService: UserStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
  }

  public ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.authCode = params['code'];
      if (this.authCode) {
        // запрос данных пользователя, зарегистрировавшегося на mos.ru
        this.getMosRuUserData(this.authCode).pipe(takeUntil(this.unsubscribe)).subscribe();
      } else {
        this.mosRuReg();
      }
    });
  }

  private getMosRuUserData(code: string): Observable<IMosRuUserData> {
    return this.apiAuthService.getMosruUserData(code).pipe(
      take(1),
      switchMap(MRUserData => {
        this.MosRuUserData = MRUserData;

        return this.handleGetUserDataResponse(this.MosRuUserData);
      }),
      catchError((error: HttpErrorResponse) => {
        this.utilsService.openSnackBar('Ошибка авторизации: ' + error.error.comment, 'error');

        return throwError(() => error);
      }),
    );
  }
  private handleGetUserDataResponse(MRUserData: IMosRuUserData): Observable<any> {
    if (MRUserData) {
      let currentObservable$: Observable<any> = of(null);

      switch (MRUserData.status) {
        case 'Success':
          {
            // можно логинить пользователя
            this.mosRuUser = loginCases.LOGIN;
            currentObservable$ = this.doMOLogin(MRUserData);
          }
          break;
        case 'MosRu User not trusted':
          {
            // можно регистрировать пользователя
            this.utilsService.openSnackBar(`Для доступа к сервису необходима полная учетная запись на mos.ru`, 'error', 3000);
            setTimeout(() => {
              location.href = this.mosRuUrl;
            }, 3000);
          }
          break;
        case 'Different registration type':
          {
            this.utilsService.openSnackBar(
              'Для вашего пользователя недоступен вход через mos.ru. Попробуйте другой вариант входа в систему',
              'error',
            );
          }
          break;
        case 'User with current email already exists':
          {
            this.utilsService.openSnackBar('В системе уже есть другой пользователь с такой электронной почтой', 'error');
          }
          break;
        case 'Failed':
          {
            switch (MRUserData.comment) {
              default:
                this.utilsService.openSnackBar(`Ошибка регистрации, комментарий: ${MRUserData.comment}`, 'error');
            }
          }
          break;
      }
      return currentObservable$;
    } else {
      return of(null);
    }
  }

  private doMOLogin(userData: IMosRuUserData): Observable<any> {
    return this.apiAuthService.loginMosruUser(userData.userId, userData.email).pipe(
      switchMap((loginResult: any) => {
        if (!loginResult || loginResult.succeeded === false) {
          this.mosRuUser = loginCases.LOGIN;
          this.utilsService.openSnackBar(
            'Кажется, вы поменяли почту и/или пароль к своему личному кабинету. Для авторизации обратитесь в нашу службу поддержи, написав на help@profilum.ru или позвонив по телефону +7 (495) 120-33-97. Мы поможем вам восстановить доступ.',
            'error',
            15000,
          );

          return of(null);
        } else {
          this.userStorageService.setUserId = loginResult.userId;
          this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
          this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
          this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
          this.webStorageService.set(StorageKeys.IsAuthorized, true);

          return this.b2gSaasService.getAccess(loginResult.userId);
        }
      }),
      tap((accessResponse: any) => {
        this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
        this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);

        const userInfo: IUserInfo = this.userDataHandlerService.getUserInfo();
        this.setUserInfoInLS(userInfo);

        const lastRoute: string = this.webStorageService.get(StorageKeys.LastRoute);
        lastRoute ? this.router.navigate([lastRoute]) : this.router.navigate(['/mosruabout']);
      }),
    );
  }
  private setUserInfoInLS(userInfo: IUserInfo) {
    if (!userInfo) {
      throw new Error('User info is not defined');
    }

    this.webStorageService.set(StorageKeys.ImagePath, userInfo.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, userInfo.firstName);
    this.webStorageService.set(StorageKeys.LastName, userInfo.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, userInfo.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, userInfo.gender);
    this.webStorageService.set(StorageKeys.CompanyId, userInfo.companyId);
    this.webStorageService.set(StorageKeys.Position, userInfo.position);
    this.webStorageService.set(StorageKeys.RegionId, userInfo.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, userInfo.municipalityId);
    this.webStorageService.set(StorageKeys.City, userInfo.city);
  }

  private mosRuReg() {
    this.apiAuthService
      .getMosruAuthUrl()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(mosRuRoute => {
        if (mosRuRoute) {
          location.href = mosRuRoute.url;
          return;
        }
      });
  }
}
