import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { ApiSchoolsService, IFilterClasses, Stands, StorageKeys, WebStorageService } from '@profilum-library';
import {
  InternshipsFiltersService,
} from '../../../internship-page/internship-catalog/internship-filter/internships-filter.service';

@Component({
  selector: 'prf-active-courses',
  templateUrl: './active-courses.component.html',
  styleUrls: ['./active-courses.component.scss'],
})
export class ActiveCoursesComponent implements OnInit {
  public courses: any = [];
  public classesCount: string;
  public classesSearchCount: string;
  public viewValue: boolean = false;
  filters: IFilterClasses;

  private ngUnsubscribe$ = new Subject<any>();
  private userRole: string;

  constructor(
    private meta: Meta,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
    private webstorageService: WebStorageService,
    private apiSchoolsService: ApiSchoolsService,
  ) {
    this.userRole = this.webstorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.filtersService.getCoursesFilters().pipe(
      takeUntil(this.ngUnsubscribe$),
      tap(filters => {
        this.filters = filters;
        this.findCoursesCountByRegion();
      }),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  showTerritoryFilter() {
    return !(this.userRole === 'schooladmin' || this.userRole === 'director');
  }

  findCoursesCountByRegion() {
    const filters = Object.assign({}, this.filters);
    filters.stand = Stands.Talent;
    let getCount$ = this.apiSchoolsService.getClassesCountElastic(filters);
    this.filtersService.setRegionMunicipalityFilters(filters);

    if (this.userRole === 'admin') {
      getCount$ = this.apiSchoolsService.getClassesCount(filters);
    }

    return getCount$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
      count => {
        return this.countCourses(null, count.count);
      },
      catchError(err => throwError(err)),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countCourses(coursesLength: number, searchLength: number = 0) {
    const complexLength = coursesLength + searchLength;
    const courseTitles = ['курс', 'курса', 'курсов'];
    const title = this.internshipsFiltersService.getTitleByNumber(complexLength, courseTitles);
    return (this.classesCount = complexLength + ' ' + title);
  }
}
