<ng-container [ngSwitch]="activeLocation">
  <ng-container *ngSwitchCase="LocationEnum.MOSOBL">
    <prf-page-footer-mosobl></prf-page-footer-mosobl>
  </ng-container>
  <ng-container *ngSwitchCase="LocationEnum.KAZAZKHSTAN">
    <prf-page-footer-kz></prf-page-footer-kz>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="baseFooter"></ng-container>
  </ng-container>
</ng-container>

<ng-template #baseFooter>
  <div class="footer-section" [ngClass]="{ 'no-padding-top': isNoPaddingTop }">
    <div class="footer-row">
      <div class="footer-text">© {{ yearNumber }} Профилум</div>
      <!-- <div class="footer-text">8 495 445-01-09</div> -->
      <div class="footer-emails">
        <div *ngIf="activeLocation !== 'kruzhki'; else kruzhkiContacts" class="footer-text">
          <a href="mailto:help@profilum.ru" class="footer-link">
            <strong>help&#64;profilum.ru</strong>
          </a>
          <a href="tel:88003502009" class="footer-link"> <strong>8 800 350 2009</strong> (звонок бесплатный)</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #kruzhkiContacts>
  <div class="footer-text">
    <a href="mailto:help@протворчество.москва" class="footer-link">
      <strong>help&#64;протворчество.москва</strong>
    </a>
    <a href="tel:+74951203397" class="footer-link"> <strong>+7 (495) 120-33-97</strong> (звонок бесплатный)</a>
  </div>
</ng-template>
