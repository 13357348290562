import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, take, tap, timeout } from 'rxjs/operators';
import {
  IActiveLessons,
  IChapter,
  IChapterInfo,
  IChaptersInfo,
  IParsedLessons,
} from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import {
  ApiCoursesMaterialsService,
  IActiveLesson,
  ICourseMaterialExt,
  ILessonActivity,
  ILessonMaterial,
  ILessons,
  IPassedLessons,
  ISchoolClass,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { StorageKeys as UserStorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';

@Injectable()
export class TeacherClassCoursesService {
  public readonly countOfActiveChapters: number = 99;
  public activeLessons: IActiveLessons;

  constructor(
    private teacherPanelService: TeacherPanelService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private webStorageService: WebStorageService,
    private userStorageService: UserStorageService,
  ) {
    this.userStorageService.get(UserStorageKeys.ActiveLessons).pipe(
      map((activeLessons: IActiveLessons | null) => activeLessons ?? {}),
      catchError(() => of({})),
    ).subscribe(response=>{
      this.activeLessons = response;
    })
  }

  public setChaptersData(
    courseId: string,
    schoolClassId: string,
    currentIndex: number,
    chapterData: [string, ILessons],
    chapters: [string, ILessons][],
  ): void {
    let previousChapter: IChapter = null;
    let nextChapter: IChapter = null;
    const previousIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    const sections: IChapter[] = chapters.map((chapter, index) => {
      if (index === nextIndex) {
        nextChapter = { index: nextIndex, name: chapter[0] };
      }

      if (index === previousIndex) {
        previousChapter = { index: previousIndex, name: chapter[0] };
      }

      return { index, name: chapter[0] };
    });

    const chapterInfo: IChapterInfo = {
      previousChapter,
      currentChapter: { index: currentIndex, name: chapterData[0] },
      nextChapter,
      schoolClassId: schoolClassId,
      courseId: courseId,
      chapters: sections,
    };

    this.userStorageService
      .get(UserStorageKeys.ClassCourseChapterInfo)
      .pipe(take(1))
      .subscribe((chaptersInfo: IChaptersInfo) => {
        this.userStorageService.set(UserStorageKeys.ClassCourseChapterInfo, {
          ...chaptersInfo,
          [schoolClassId]: chapterInfo,
        });
      });
  }

  public getCourseMaterialWithPassedData(courseId: string, schoolClass: ISchoolClass): Observable<ICourseMaterialExt> {
    return this.apiCoursesMaterialsService.getOneCourseMaterial(courseId).pipe(
      switchMap(res => combineLatest([of(res), this.apiCoursesMaterialsService.getPassed(schoolClass.id, courseId), of(schoolClass)])),
      switchMap(([courseMaterial, lessonsActivities, schoolClass]) => {
        const course = this.parseLessons(schoolClass, courseMaterial, lessonsActivities);

        return this.getLastActiveLesson(schoolClass.id, course).pipe(
          map(activeLesson => {
            course.activeLesson = activeLesson;
            return course;
          }),
        );
      }),
    );
  }

  public parseLessons(schoolClass: ISchoolClass, course: ICourseMaterialExt, lessonsActivities: ILessonActivity[]): ICourseMaterialExt {
    let hasPassedLesson = false;
    const passedLessons: IPassedLessons[] = [];
    const lessonsMaterials = course?.lessonsMaterials ?? [];

    this.webStorageService.set(StorageKeys.TeacherId, schoolClass.teacherId);
    lessonsActivities = lessonsActivities.filter(lessonsAct => lessonsAct.userId === schoolClass.teacherId);

    const lessonsAct =
      lessonsActivities?.reduce((prevVal, currentVal) => {
        if (currentVal.isPassed) {
          prevVal[currentVal.lessonId] = currentVal.isPassed;
        }

        return prevVal;
      }, {}) ?? {};

    const parsedLessons: IParsedLessons = lessonsMaterials.reduce((prevVal, currentVal) => {
      const { number } = currentVal;
      const parsedLesson = { ...currentVal };
      const previousData = prevVal[parsedLesson.blockName] ?? {};
      const { min, max } = this.updateMinMax(previousData.minNumber, previousData.maxNumber, number);
      const isPassed = !!lessonsAct[parsedLesson.id];

      previousData.minNumber = min;
      previousData.maxNumber = max;
      hasPassedLesson = !hasPassedLesson ? isPassed : hasPassedLesson;
      parsedLesson.passed = isPassed;
      passedLessons.push({ id: parsedLesson.id, orderNumber: parsedLesson.number, isPassed });

      if (Array.isArray(previousData.lessons)) {
        previousData.lessons.push(parsedLesson);
      } else {
        previousData.lessons = [parsedLesson];
      }

      prevVal[parsedLesson.blockName] = previousData;

      return prevVal;
    }, {} as IParsedLessons);

    course.parsedLessons = Object.entries(parsedLessons);
    course.passedLessons = passedLessons;

    return course;
  }

  public updateMinMax(min: number, max: number, newValue: number): { min: number; max: number } {
    const minValue = !min || min > newValue ? newValue : min;
    const maxValue = !max || max < newValue ? newValue : max;

    return { min: minValue, max: maxValue };
  }

  public setActiveLesson = (classId: string, activeLesson: IActiveLesson, course: ICourseMaterialExt): void => {
    this.getActiveLessons()
      .pipe(
        tap((activeLessons: IActiveLessons) => {
          course.activeLesson = activeLesson;
          this.userStorageService.set(UserStorageKeys.ActiveLessons, {
            ...activeLessons,
            [classId]: activeLesson,
          });
        }),
        catchError(() => {
          this.userStorageService.set(UserStorageKeys.ActiveLessons, { [classId]: activeLesson });
          course.activeLesson = activeLesson;
          return of({ [classId]: activeLesson });
        }),
        timeout(3000),
        retry(3),
        take(1),
      )
      .subscribe();
  };

  public hasPassedLesson = (course: ICourseMaterialExt): boolean => {
    return course?.passedLessons.some((passedLesson: IPassedLessons) => passedLesson.isPassed) ?? false;
  };

  public getActiveLessons = (): Observable<IActiveLessons> => {
    return of(this.activeLessons);
  };

  public getActiveLesson(classId: string, course?: ICourseMaterialExt): Observable<IActiveLesson> {
    return this.getActiveLessons().pipe(
      map((activeLessons: IActiveLessons) => {
        if (course && activeLessons[classId]) {
          if (this.isActiveLessonFromCourse(course, activeLessons[classId])) {
            return activeLessons[classId];
          }

          return null;
        }

        return activeLessons[classId] ?? null;
      }),
    );
  }

  public isActiveLessonFromCourse(course: ICourseMaterialExt, activeLesson: IActiveLesson): boolean {
    if (activeLesson.lesson) {
      const activeLessonId: string = activeLesson.lesson.id;
      const isActiveLessonInCourse: boolean = course.parsedLessons.some((parsedLessonConstruction: [string, ILessons]) => {
        const parsedLessons: ILessonMaterial[] = parsedLessonConstruction[1].lessons;
        const activeLessonFromCourse: ILessonMaterial = parsedLessons.find(
          (lessonItem: ILessonMaterial) => lessonItem.id === activeLessonId,
        );

        if (activeLessonFromCourse) {
          return true;
        }
      });

      return isActiveLessonInCourse;
    }

    return false;
  }

  private getLastActiveLesson(classId: string, course: ICourseMaterialExt): Observable<IActiveLesson> {
    return this.getActiveLesson(classId, course).pipe(
      map((activeLesson: IActiveLesson) => {
        if (activeLesson) {
          return activeLesson;
        }

        for (let i = course.passedLessons.length - 1; i >= 0; i--) {
          if (course.passedLessons[i].isPassed) {
            activeLesson = { lessonIndex: -1, chapterIndex: -1, lesson: null };

            for (let j = 0; j < course.parsedLessons.length; j++) {
              const lessonIndex = course.parsedLessons[j][1].lessons.findIndex(lesson => lesson.id === course.passedLessons[i].id);

              if (lessonIndex !== -1) {
                activeLesson.chapterIndex = j;
                activeLesson.lessonIndex = lessonIndex;
                activeLesson.lesson = course.parsedLessons[j][1].lessons[lessonIndex];
                break;
              }
            }

            break;
          }
        }

        activeLesson = activeLesson ? activeLesson : { lessonIndex: -1, chapterIndex: -1, lesson: null };
        this.setActiveLesson(classId, activeLesson, course);
        return activeLesson;
      }),
    );
  }

  public calculateCloserDate(course: ICourseMaterialExt): string {
    const futureDates: ILessonMaterial[] = course.lessonsMaterials
      .filter((item: ILessonMaterial) => item?.recommendedDate > new Date().toISOString())
      .sort((a: ILessonMaterial, b: ILessonMaterial) => new Date(a.recommendedDate).getTime() - new Date(b.recommendedDate).getTime());

    if (futureDates.length) {
      return (
        new Date(futureDates[0].recommendedDate).toLocaleString('ru', { day: 'numeric' }) +
        ' ' +
        new Date(futureDates[0].recommendedDate).toLocaleString('ru', { month: 'long', day: 'numeric' }).split(' ')[1]
      );
    } else {
      return '';
    }
  }
}
