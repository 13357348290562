<ng-container [ngSwitch]="userRole">
  <ng-container *ngSwitchCase="'admin'">
    <prf-admin-menu [innerRoute]="true" [adminLevel]="adminLevel"></prf-admin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'schooladmin'">
    <prf-schooladmin-menu [innerRoute]="true"></prf-schooladmin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'adminDO'">
    <prf-admin-menu [innerRoute]="true" [adminLevel]="adminLevel" [itemsHide]="['landing', 'users', 'professions', 'centers']">
    </prf-admin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'director'">
    <prf-director-menu [innerRoute]="true"></prf-director-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'tutor'">
    <prf-tutor-menu [innerRoute]="true"></prf-tutor-menu>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <prf-menu></prf-menu>
  </ng-container>
</ng-container>

<div class="content-section">
  <div class="container">
    <!-- <prf-page-header
      [title]="title"
      [backButtonUrl]="backButtonUrl()"
      [backButtonName]="
        isCourse ? ('SHARED.ALL_COURCES' | translate) : ('SHARED.ALL_EVENTS' | translate)
      "
    >
    </prf-page-header> -->

    <div class="return-button">
      <a routerLink="/vorobievi-gori/partners-courses-all" class="">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
    </div>

    <div class="admin-header-event-page" *ngIf="course && checkCourseEdit(course)">
      <div class="admin-header-col">
        <div class="event-status-text ev-page" *ngIf="course.startDate">
          {{ 'SHARED.CREATED_DUE_DATE' | translate : { createdDate: startDate } }}
        </div>
      </div>
      <div class="edit-event-column event-page-edit">
        <div class="edit-event-col-2">
          <a [routerLink]="['/' + userRole + '/copy-course', course.hrid]" class="edit-event-button w-inline-block">
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="./profilum-assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="event-page-section" *ngIf="course">
      <div class="event-page-header event-gradient-bg-2" [ngClass]="color">
        <div
          class="event-image-bg"
          [ngStyle]="{
            'background-image': course.mainImagePath ? 'url(' + course.mainImagePath + ')' : ''
          }"
        ></div>
        <div class="event-header-column">
          <div class="event-page-age">{{ getAges() }}</div>
        </div>
        <div class="event-header-column _2">
          <div class="event-page-h1-div">
            <h1 class="event-page-h1">{{ course.name }}</h1>
          </div>
        </div>
      </div>
      <div class="event-page-content">
        <div class="event-page-description-row">
          <div class="event-page-description-column">
            <h2 class="event-page-h2">{{ 'COURCES.PAGE.DESCRIPTION' | translate }}</h2>

            <div class="event-page-descr-row">
              <div *ngIf="course.courses && course.courses.length > 0" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SHARED.THEMATIC' | translate }}</h4>
                <ul class="event-description-list">
                  <li *ngFor="let field of course.courses">
                    <div class="event-description-title">{{ field }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="course.courseGroups && course.courseGroups.length" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SIDE.PROFILE' | translate }}</h4>
                <ul class="event-description-list">
                  <li *ngFor="let group of course.courseGroups">
                    <div class="event-description-title">{{ group }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="complexity" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SHARED.LEVEL' | translate }}</h4>
                <ul class="event-description-list">
                  <li>
                    <div class="event-description-title">{{ complexity }}</div>
                  </li>
                </ul>
              </div>
            </div>

            <p class="event-page-description-text" innerHtml="{{ course.description }}"></p>
          </div>
          <div class="event-page-description-column _2">
            <div class="event-page-text">{{ 'SHARED.ORGANIZER' | translate }}</div>
            <h3 class="event-page-h3">{{ course.institution }}</h3>
            <div class="event-page-date" *ngIf="course.startDate">{{ startDate }}</div>
            <div class="event-page-price">
              <div class="event-final-price">
                {{
                  calculateRealPrice() > 0
                    ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
                    : ('COURCES.PAGE.FREE' | translate)
                }}
              </div>
              <div class="event-page-price-row" *ngIf="course.unitDiscount && course.unitDiscount > 0">
                <div class="starting-price">{{ +course.unitPrice }}</div>
                <div class="discount" *ngIf="course.unitDiscount > 0 && course.isPercent">
                  {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
                </div>
                <div class="discount" *ngIf="course.unitDiscount > 0 && !course.isPercent">
                  {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
            </div>
            <div class="event-page-address">
              <div class="event-address event-pg">{{ course.address }}</div>
              <div class="event-metro-row metro-li" *ngFor="let station of course.metroStations">
                <div class="metro-color" [ngClass]="metroColors.getStationByLine(station)"></div>
                <div class="event-metro">{{ station.name }}</div>
              </div>
            </div>

            <a (click)="goToCourse()" class="button event-page-btn w-button">Записаться</a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="otherCourses.length > 0 || similarCourses.length > 0" class="similar-prof-block">
      <ng-container *ngIf="otherCourses.length > 0">
        <h4 class="similar-prof-heading">
          {{ 'SHARED.YET' | translate }} {{ title }} {{ 'SHARED.FROM' | translate }}
          {{ course.institution | lowercase }}
        </h4>
        <div class="events-row">
          <prf-course-card *ngFor="let course of otherCourses | slice : 0 : 3" [course]="course"></prf-course-card>
        </div>
      </ng-container>

      <div *ngIf="otherCourses.length > 0 && similarCourses.length > 0" class="similar-events-line"></div>

      <ng-container *ngIf="similarCourses.length > 0">
        <h4 class="similar-prof-heading">{{ 'SHARED.SIMILAR' | translate }} {{ title }}</h4>
        <div class="catalog-events-row">
          <prf-course-card *ngFor="let course of similarCourses | slice : 0 : 3" [course]="course"></prf-course-card>
        </div>
      </ng-container>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>

<ng-template #isFavoriteCard>
  <a
    class="event-page-heart w-inline-block"
    (click)="removeFavorite()"
    [style.background-image]="'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    (mouseover)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-line-white.svg)'"
    (mouseleave)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    #heartActive
  ></a>
</ng-template>

<!-- <ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">{{ 'SHARED.DELETING_A_COURSE' | translate }}</h4>
          <div class="task-result-text">{{ 'SHARED.WANT_TO_DELETE_COURSE' | translate }}</div>
          <a class="button attach-file-btn w-button" (click)="deleteCourse(course.id)">{{
            'BUTTONS.DELETE' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->
<!-- <ng-container *ngIf="addtoArchive">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="addtoArchive = !addtoArchive">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">{{ 'SHARED.ADDING_TO_ARCHIVE' | translate }}</h4>
          <div class="task-result-text">{{ 'SHARED.WANT_TO_ADD_COURSE' | translate }}</div>
          <a class="button attach-file-btn w-button" (click)="archive(course)">{{
            'SHARED.ADD' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->
