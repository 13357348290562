import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'prf-schooladmin-share-banner',
  templateUrl: './schooladmin-share-banner.component.html',
  styleUrls: ['./schooladmin-share-banner.component.scss'],
})
export class SchooladminShareBannerComponent {
  @Input() schortRegLink: string = '';
  @Input() widget: boolean = false;
  @Input() smallWidget: boolean = false;
  @Input() pupilsLength: number = 0;

  public showSharePopup: boolean = false;
  public linkCopied: boolean = false;
  public successSend: boolean = false;
  public loading: boolean = false;
  public title: string = 'Пригласите родителей';

  private copyText: string =
    'Уважаемые родители! \r\n' +
    'Для нашего класса бесплатно проводят тест на профориентацию.\r\n' +
    'В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете посмотреть результаты.\r\n' +
    '❗️ Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.\r\n';

  constructor(private clipboardService: ClipboardService) {}

  public closePopup(status?: string): void {
    if (status === 'done') {
      this.showSharePopup = !this.showSharePopup;
      this.linkCopied = !this.linkCopied;
      this.title = 'Приглашение скопировано. Пригласить ещё раз?';
      this.successSend = false;
      return;
    }

    this.showSharePopup = !this.showSharePopup;
  }

  public copyPupilRegLink(): void {
    this.clipboardService.copy(this.copyText + ' ' + this.schortRegLink);
    this.linkCopied = !this.linkCopied;
    this.successSend = true;
  }
}
