<section class="teacher">
  <prf-menu [root]="'teacher'" [test]="isResults"></prf-menu>

  <div class="content-section">
    <div class="container">
      <prf-page-header
        [title]="headerTitle ? headerTitle : 'Мои классы'"
        [schoolTitle]="schoolTitle"
        [editButton]="false"
        [isTeacherDashboard]="pupilsFlag && !isResults"
        [showClassDropdown]="false"
      ></prf-page-header>

      <ng-template [ngIf]="!selectedClass">
        <prf-teacher-class-list [classList]="classList" (selectedClass)="openClass($event)"></prf-teacher-class-list>
      </ng-template>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>
