<div class="prof-mobile">
  <ng-template #bvbRecommendations>
    <prf-discover-intro-recommendations
      [professionName]="profession.name"
      (forward)="createClassesFormat($event)"
      (back)="closeBvbRecommendation()"
    ></prf-discover-intro-recommendations>
  </ng-template>
  <ng-container *ngIf="!showBvbRecommendations; else bvbRecommendations">
    <ng-container *ngIf="profession && dataFetched">
      <div class="prof-mobile-img">
      <img class="prof-mobile-img"
           [class.prof-mobile-img-grey]="isGreyImage"
           [src]="imgPath ? imgPath : './profilum-assets/images/profession/prof-mobile.png'" alt=""/>
      <div class="prof-mobile-container-recommended">
        <div class="prof-mobile-container-recommended-capabilities" *ngIf="capabilities">Способности
        </div>
        <div class="prof-mobile-container-recommended-interests" *ngIf="recommended">Интересы</div>
        <div
          class="prof-mobile-container-recommended-favourite"
          *ngIf="favorite && professionTryingOnStage"
        >
          Избранное
        </div>
      </div>
      </div>
      <div class="prof-mobile-container" [class.scrollable-container]="isScroll">
        <div class="prof-mobile-container-header">
          <div class="prof-mobile-container-header-text">
            {{ profession.name }}
          </div>
          <div
            *ngIf="!professionTryingOnStage"
            class="prof-mobile-container-header-icon"
            [class.prof-mobile-container-header-icon_favourite]="favorite"
            (click)="updateFavorites()"
          ></div>
        </div>
        <div class="prof-mobile-container-about" innerHtml="{{ profession.longDescription }}"></div>
        <div class="prof-mobile-container-way">
          <div class="prof-mobile-container-title">Путь к профессии</div>
          <prf-mobile-steps-trajectory [hideButton]="true" [profession]="profession"
                                       (showSpoVoPrograms)="showPrograms($event)"></prf-mobile-steps-trajectory>
        </div>
        <div class="prof-mobile-container-more">
          <div class="prof-mobile-container-title">Больше интересного</div>
          <div class="prof-mobile-container-menu" #profInfoMenu>
            <div class="prof-mobile-menu-row">
              <a
                class="prof-mobile-menu-link"
                *ngFor="let item of contentMenu"
                [ngClass]="{ 'w--current': currentContent == item }"
                (click)="currentContent = item; prepareData()"
              >{{ item }}</a
              >
            </div>
          </div>
          <!--  TODO: рефакторинг на switchCase -->
          <div class="prof-mobile-info">
            <div class="prof-mobile-info-tabs">
              <ng-container *ngIf="currentContent === 'Видео'">
                <ng-container *ngTemplateOutlet="videos"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Фильмы'">
                <ng-container *ngTemplateOutlet="movies"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Профессия в лицах'">
                <ng-container *ngTemplateOutlet="faces"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Книги и статьи'">
                <ng-container *ngTemplateOutlet="articles"></ng-container>
              </ng-container>
            </div>
          </div>
          <div *ngIf="showSalary">
            <div class="prof-mobile-container-title">Средняя зарплата на разных этапах карьерного роста</div>
            <ul class="average-salary-list w-list-unstyled">
              <li class="prof-mobile-container-salary">
                <div class="average-salary-row">
                  <div class="average-salary-post">Специалист высшей категории</div>
                  <div class="wage-amount">
                    {{ profession.maxSalary | number }}
                    {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                  </div>
                </div>
                <div class="average-salary-line-wrapper">
                  <div class="average-salary-line"></div>
                </div>
              </li>
              <li class="prof-mobile-container-salary">
                <div class="average-salary-row">
                  <div class="average-salary-post">Специалист со средним опытом</div>
                  <div class="wage-amount">
                    {{ profession.averageSalary | number }}
                    {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                  </div>
                </div>
                <div class="average-salary-line-wrapper">
                  <div class="average-salary-line _2"></div>
                </div>
              </li>
              <li class="prof-mobile-container-salary">
                <div class="average-salary-row">
                  <div class="average-salary-post">Начинающий специалист</div>
                  <div class="wage-amount">
                    {{ profession.minSalary | number }}
                    {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                  </div>
                </div>
                <div class="average-salary-line-wrapper">
                  <div class="average-salary-line _3"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #faces>
    <div class="professional-list">
      <a class="professional-card w-inline-block" *ngFor="let person of personsList; let i = index"
         (click)="checkPopup($event, popup)">
        <div class="professional-info-lb lb" #popup>
          <div class="lb-bg"></div>
          <div class="lb-block prof-popup">
            <div class="close-lb" (click)="popup.style.display = 'none'">
              <img src="./profilum-assets/images/icons/close-lb.svg"/>
            </div>
            <div class="professional-info-row">
              <div class="professional-info-column _1">
                <p>{{ person.description }}</p>
              </div>
              <div class="professional-info-column _2">
                <div class="professional-photo prof-popup">
                  <img
                    src="{{ person.imagePath ? '/staticfiles' + person.imagePath : './profilum-assets/images/no-photo_1.svg' }}"/>
                </div>
                <div class="professional-name prof-popup">{{ person.name }}</div>
                <div class="professional-post">{{ profession.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="professional-photo">
          <img src="{{ person.imagePath ? '/staticfiles' + person.imagePath : './profilum-assets/images/icons/no-photo_1.svg' }}"/>
        </div>
        <div class="professional-column">
          <div class="professional-name">{{ person.name }}</div>
          <div class="professional-post">{{ profession.name }}</div>
          <div class="professional-bio">
            <p class="professional-bio-text">{{ person.description }}</p>
            <div class="professional-read-more">подробнее</div>
          </div>
        </div>
      </a>
    </div>

    <div class="prof-mobile-center-button" *ngIf="profession.persons.length > 2">
      <a
        class="white-button show-more w-button"
        (click)="personsList = profession.persons"
        *ngIf="personsList.length < profession.persons.length"
      >Показать всех</a
      >
      <a
        class="white-button show-more w-button"
        (click)="personsList = profession.persons.slice(0, 2); scroll()"
        *ngIf="personsList.length == profession.persons.length"
      >Скрыть</a
      >
    </div>
  </ng-template>

  <ng-template #movies>
    <ul class="films-list">
      <li *ngFor="let movieName of profession.movies">
        <div class="film-title">{{ movieName }}</div>
      </li>
    </ul>
  </ng-template>

  <ng-template #videos>
    <ng-container *ngFor="let video of videoList">
      <div class="frame-video">
        <iframe width="100%" [src]="video.url | safeUrl" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="prof-info-video-text">{{ video.name }}</div>
    </ng-container>
    <div class="prof-mobile-center-button" *ngIf="profession.videos.length > 1">
      <a class="white-button show-more w-button" (click)="videoList = profession.videos"
         *ngIf="videoList.length < profession.videos.length"
      >Показать все видео</a
      >
      <a
        class="white-button show-more w-button"
        (click)="videoList = profession.videos.slice(0, 1); scroll()"
        *ngIf="videoList.length == profession.videos.length"
      >Скрыть видео</a
      >
    </div>
  </ng-template>

  <ng-template #articles>
    <ul class="articles-list">
      <li class="articles-list-item" *ngFor="let article of profession.articles">
        <a
          prfYmReachGoal
          [ymItemTarget]="ymItems.S_ProfessionsCard_Materials"
          class="article-link"
          [href]="sanitizeUrl(article.url)"
          target="_blank"
        >{{ article.name }}</a
        >
      </li>
    </ul>
  </ng-template>
</div>

<div class="overlay" *ngIf="selectedSpecial">
  <div class="programs-spo-vpo scrollable-container">
    <div class="programs-spo-vpo__container">
      <div class="programs-spo-vpo__title">
        <div class="programs-spo-vpo__title-name">{{selectedSpecial.fields[1].name}}</div>
        <img class="programs-spo-vpo__title-close" (click)="closePrograms()" src="./profilum-assets/images/common/close-filter.svg"/>
      </div>
      <div class="programs-spo-vpo__text" [innerHTML]="selectedSpecial.fields[1].description">
      </div>
      <div class="programs-spo-vpo__title-name">Учебные заведения, в которых открыт набор на 2024 год</div>
      <div class="programs-spo-vpo__links" *ngFor="let program of selectedPrograms">
      <img src="./profilum-assets/images/common/link-program.svg"/>
        <a class="programs-spo-vpo__links-text" [href]="program.educationalInstitutionLink" target="_blank">{{program.educationalInstitutionName}}</a>
      </div>
    </div>
  </div>
</div>

