import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { COURSES_FORMAT_DATA } from 'app/shared/global-constants/constants';
import { takeUntil } from 'rxjs/operators';
import { FiltersPromotestCoursesService } from '../filters-promotest-courses.service';
import { IFilterClasses, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

// в запросе по фильтрам фомировать объект, чтобы исключать поля null undefined
@Component({
  selector: 'prf-filters-format',
  templateUrl: './filters-format.component.html',
  styleUrls: ['./filters-format.component.scss'],
})
export class FiltersFormatComponent extends UnsubscribeComponent implements OnInit {
  filters: IFilterClasses;
  formats = COURSES_FORMAT_DATA;
  classes: any = [];
  formatOpen: boolean = false;
  selectFormat: string;
  @ViewChild('formatFilter') formatFilter: ElementRef;

  public tag: string;

  constructor(private filtersService: FiltersPromotestCoursesService, private webStorageService: WebStorageService) {
    super();
    this.tag = this.webStorageService.get(StorageKeys.Tag);
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.filters = data;
      });
  }

  setFormatsFilter(format: any) {
    this.filters.format = format.value;
    this.filtersService.setCoursesFilter(this.filters);
    this.formatOpen = false;
  }

  deselectFormat() {
    this.filters.format = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.format) {
      const format = this.formats.find(el => el.value === this.filters.format);
      return format.viewValue;
    } else {
      return 'Формат';
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.formatFilter && !this.formatFilter?.nativeElement.contains(target)) {
      this.formatOpen = false;
    }
  }
}
