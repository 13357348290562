import { ChartData } from 'chart.js';

export type FILTER = 'male' | 'female' | 'seventhClass' | 'eighthClass' | 'ninthClass' | 'tenthClass' | 'eleventhClass';

export enum FilterVariantsEnum {
  CLASS = 'classFilter',
  GENDER = 'genderFilter',
}

export enum EducationChartTypesEnum {
  BASE,
  MUNICIPALITY,
}

export enum ReportEnum {
  DEFAULT,
  MUNICIPALITY,
}

export interface IBarChart {
  data: any;
  options: {
    indexAxis: string;
    plugins: {
      legend: {
        onClick: (event, legendItem) => void;
        display: boolean;
        labels: {
          usePointStyle: boolean;
          color: string;
          font: { size: number; family: string };
        };
      };
      tooltip: {
        mode: string;
        titleFontColor: string;
        backgroundColor: string;
        bodyFontFamily: string;
        titleFontSize: number;
        callbacks: {
          afterLabel: (tooltipItem, data) => string | string;
          label: (tooltipItem, data) => string;
          title: (tooltipItem, data: ChartData) => string;
        };
        fontSize: number;
        bodyFontColor: string;
        titleFontFamily: string;
        displayColors: boolean;
      };
    };
    responsive: boolean;
    scales: {
      x: {
        ticks: {
          color: string;
          font: { size: number; family: string };
        };
        grid: { display: boolean };
        position: string;
        beginAtZero: boolean;
      };
      y: {
        ticks: {
          color: string;
          callback: (tick) => string;
          font: { size: number; weight: string; family: string };
        };
        grid: { display: boolean };
        afterFit: (scale) => void;
        beginAtZero: boolean;
      };
    };
    aspectRatio: number;
    maintainAspectRatio: boolean;
    onHover(e): void;
  };
  type: string;
}

export enum ChartTypesEnum {
  VPO,
  SPO,
  EDUCATION,
}
