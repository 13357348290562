import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PORTFOLIO_FILE_TYPES } from '../portfolio-card/portfolio-card.component';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { PortfolioAddModalService } from './portfolio-add-modal.service';
import { ApiProfilesService, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-portfolio-add-modal',
  templateUrl: './portfolio-add-modal.component.html',
  styleUrls: ['./portfolio-add-modal.component.scss'],
})
export class PortfolioAddModalComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  public isOpenSelectType: boolean = false;
  public typeList: Array<any> = PORTFOLIO_FILE_TYPES;
  public selectedType: any = this.typeList[0];
  public portfolioId: string = '';
  public editMode: boolean = false;
  public acceptedFileFormats: string[] = ['application/pdf', 'image/jpeg', 'image/png'];
  public file: any = {
    filePath: '',
    id: '',
    name: '',
    portfolioId: '',
    type: '',
    file: '',
  };
  public userRole: string = '';

  private ngUnsubscribe = new Subject<any>();

  @ViewChild('typesSelector') typesSelector: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private portfolioAddModalService: PortfolioAddModalService,
    private apiProfilesService: ApiProfilesService,
    private webStorageService: WebStorageService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.portfolioAddModalService.show$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(file => {
      this.show = !!file;
      this.portfolioId = this.portfolioAddModalService.portfolioId;

      if (this.show) {
        if (file && file.id) {
          this.file = file;
          this.file.file = { name: this.nameFromPath(file.filePath) };
          this.selectedType = this.typeList[file.type - 1];
          this.editMode = !!file.filePath;
        } else {
          this.editMode = false;
          this.selectedType = this.typeList[0];
          this.file = {
            filePath: '',
            id: '',
            name: '',
            portfolioId: '',
            type: '',
            file: '',
          };
        }
      }
    });
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.typesSelector) {
      if (this.typesSelector && !this.typesSelector?.nativeElement.contains(target)) {
        this.isOpenSelectType = false;
      }
    }
  }

  public textChange(event: any) {
    this.file.name = event.value;
  }

  public selectFile() {
    const event = new MouseEvent('click', { bubbles: false });
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  public onSelectFile(event) {
    if (!this.acceptedFileFormats.includes((event.target as HTMLInputElement).files.item(0)?.type)) {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    } else {
      this.file.file = event.target.files[0];
    }
  }

  public addPortfolio() {
    if (this.file.file) {
      this.hideModal();
      this.apiProfilesService
        .addPortfolioFile(this.portfolioId, this.file.name, this.selectedType.id, this.file.file)
        .pipe(take(1))
        .subscribe(result => {
          this.file.id = result;
          this.portfolioAddModalService.added$.next(this.file);
        });
    }
  }

  public hideModal() {
    this.portfolioAddModalService.hide();
  }

  public openSelectType() {
    this.isOpenSelectType = true;
  }

  public selectType(type: any) {
    this.selectedType = type;
    this.isOpenSelectType = false;
    this.file.type = type.id;
  }

  public clickSave() {
    this.hideModal();
    this.apiProfilesService
      .deletePortfolioFile(this.file.id)
      .pipe(take(1))
      .subscribe(() => {
        this.apiProfilesService.addExistingPortfolioFile(this.file).subscribe(result => {
          this.file.id = result;
          this.portfolioAddModalService.added$.next(this.file);
        });
      });
  }

  public clickDelete() {
    this.hideModal();
    this.apiProfilesService
      .deletePortfolioFile(this.file.id)
      .pipe(take(1))
      .subscribe(() => {
        this.portfolioAddModalService.added$.next(this.file);
      });
  }

  private nameFromPath(path: string): string {
    const a: number = path.lastIndexOf('/');
    path = path.substring(a + 1);
    const b: number = path.indexOf('_');
    path = path.substring(b + 1);

    return path;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
