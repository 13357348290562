import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  COURSES_TYPES,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { ApiInternshipsService, StorageKeys, WebStorageService } from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-internship-card',
  templateUrl: './internship-card.component.html',
  styleUrls: ['./internship-card.component.scss'],
})
export class InternshipCardComponent implements OnInit, OnDestroy {
  @Input() internship: any;
  @Input() viewValue: any;
  @Input() image: any;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;
  types = COURSES_TYPES;
  currentType: any;
  isFavorite: boolean = false;
  isCourse: boolean;
  subscription: any;
  favoritesInternships: any;
  userId: string;
  userRole: string;
  accessAllow: boolean = false;
  popUpConfirming: boolean = false;
  startDate: string;

  constructor(private apiInternshipsService: ApiInternshipsService, private webStorageService: WebStorageService) {}

  ngOnInit() {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.accessAllow = this.userRole === 'admin';
    // this.subscription = this.internshipDetailsService.getFavoriteInternships().subscribe(data => {
    //   this.favoritesInternships = data;
    //   if (this.favoritesInternships) {
    //     let productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
    //     this.isFavorite = productIds.length > 0;
    //   }
    // });
    this.startDate = DateHelper.toDayJs(this.internship.startDate).format('D MMMM YYYY');
  }

  deleteInternship(internshipID) {
    this.apiInternshipsService.removeCurrentInternship(internshipID);
    this.popUpConfirming = !this.popUpConfirming;
    return this.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
