<div *ngIf="sessionId" class="result-container">
  <div class="content-section testing-content-2">
    <div class="container container-900 results-open-container">
      <div class="results-open-header">
        <img class="results-open-main-img" src="./assets/images/mo-landing/mo-map-talents.png" alt="Карта талантов Подмосковья" />
        <h1 class="results-open-main-title">{{ 'OPEN_RESULTS.MAIN_TITLE' | translate }}</h1>
        <p class="results-open-main-desc" [innerHTML]="'OPEN_RESULTS.MAIN_DESC' | translate"></p>
      </div>
      <prf-results [sessionId]="sessionId" (resultsEmitter)="setResults($event)"></prf-results>

      <section *ngIf="coursesByResults.length" class="recommendations-courses">
        <h2 class="section-title">{{ 'TEST_AND_RESULTS.RECOMMENDED_CURSES' | translate }}</h2>
        <p class="section-desc">
          {{ 'TEST_AND_RESULTS.RECOMMENDED_CURSES_DESC' | translate }}
        </p>
        <p class="section-desc">
          {{ 'TEST_AND_RESULTS.RECOMMENDED_CURSES_DESC_ATTENTION' | translate }}
        </p>
        <div class="courses-container">
          <prf-course-card
            *ngFor="let course of coursesByResults"
            [course]="course"
            [favouritesAccess]="false"
            [directCourseRouting]="true"
          ></prf-course-card>
        </div>
      </section>

      <hr class="divider" />

      <section class="recommendations-professions">
        <h2 class="section-title">{{ 'OPEN_RESULTS.RECOMMENDED_PROFESSIONS' | translate }}</h2>
        <p class="section-desc">
          {{ 'OPEN_RESULTS.RECOMMENDED_PROFESSIONS_DESC' | translate }}
        </p>
        <ng-container *ngFor="let rpl of professionsByResults | slice : 0 : 3">
          <h3 class="section-desc_subtitle">{{ rpl.field.name }}</h3>

          <p class="section-desc_field">{{ rpl.field.description }}</p>

          <div class="education-requirements-row">
            <div class="education-requirements-text">Необходимое образование:</div>

            <a
              *ngIf="rpl.professions.higherEducation.length"
              [class.active]="rpl.tab === 'higherEducation'"
              (click)="rpl.tab = 'higherEducation'"
              class="education-requirements-link"
            >
              {{ 'SHARED.EDU.HIGHER_SHORT' | translate }}
            </a>

            <a
              *ngIf="rpl.professions.secondaryEducation.length"
              [class.active]="rpl.tab === 'secondaryEducation'"
              (click)="rpl.tab = 'secondaryEducation'"
              class="education-requirements-link"
            >
              {{ 'SHARED.EDU.VOCATIONAL_SHORT' | translate }}
            </a>
          </div>

          <div class="professions-row recom">
            <ng-container *ngIf="rpl.professions.higherEducation.length && rpl.tab === 'higherEducation'">
              <div class="professions-column-2" *ngFor="let profession of rpl.professions.higherEducation | slice : 0 : 3">
                <div class="professions-card w-inline-block">
                  <div class="professions-image">
                    <img src="{{ profession.mainImagePath }}" alt="" />
                  </div>
                  <div class="prof-column">
                    <div class="professions-card-vertical-column">
                      <h3 class="professions-h3">{{ profession.name }}</h3>
                      <p class="professions-description">
                        {{ removeTags(profession.shortDescription) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="rpl.professions.secondaryEducation.length && rpl.tab === 'secondaryEducation'">
              <div class="professions-column-2" *ngFor="let profession of rpl.professions.secondaryEducation | slice : 0 : 3">
                <div class="professions-card w-inline-block">
                  <div class="professions-image">
                    <img src="{{ profession.mainImagePath }}" alt="" />
                  </div>
                  <div class="prof-column">
                    <div class="professions-card-vertical-column">
                      <h3 class="professions-h3">{{ profession.name }}</h3>
                      <p class="professions-description">
                        {{ removeTags(profession.shortDescription) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </section>

      <section class="survey">
        <div class="survey-wrapper">
          <h3 class="survey-title">Пожалуйста, ответьте на три вопроса :)</h3>
          <p class="survey-desc">Помогите нам стать лучше благодаря вашей обратной связи!</p>
          <form class="survey-form" [formGroup]="form">
            <div class="survey-question">
              <h4 class="survey-question-title" style="max-width: 75%">1. {{ questions.agreementWithResults }}</h4>
              <div class="answer-wrapper">
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Да" formControlName="agreementWithResults" />
                  <span class="radio"></span>
                  <span>Да</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Нет" formControlName="agreementWithResults" />
                  <span class="radio"></span>
                  <span>Нет</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Затрудняюсь ответить" formControlName="agreementWithResults" />
                  <span class="radio"></span>
                  <span>Затрудняюсь ответить</span>
                </label>
              </div>
            </div>

            <div class="survey-question">
              <h4 class="survey-question-title">2. {{ questions.takeRecommendation }}</h4>
              <div class="answer-wrapper">
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Да" formControlName="takeRecommendation" />
                  <span class="radio"></span>
                  <span>Да</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Нет" formControlName="takeRecommendation" />
                  <span class="radio"></span>
                  <span>Нет</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Затрудняюсь ответить" formControlName="takeRecommendation" />
                  <span class="radio"></span>
                  <span>Затрудняюсь ответить</span>
                </label>
              </div>
            </div>

            <div class="survey-question">
              <h4 class="survey-question-title">3. {{ questions.testOrNot }}</h4>
              <div class="answer-wrapper">
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="да" formControlName="testOrNot" />
                  <span class="radio"></span>
                  <span>Да</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="нет" formControlName="testOrNot" />
                  <span class="radio"></span>
                  <span>Нет</span>
                </label>
                <label class="answer-button">
                  <input class="radio_hidden" type="radio" value="Затрудняюсь ответить" formControlName="testOrNot" />
                  <span class="radio"></span>
                  <span>Затрудняюсь ответить</span>
                </label>
              </div>
            </div>

            <button
              class="survey-button_send"
              (click)="sendForm()"
              [class.button_disabled]="buttonBlocked || !form.valid"
              [disabled]="buttonBlocked || !form.valid"
            >
              <ng-template [ngIf]="!sent" [ngIfElse]="sentTemplate"> Отправить ответы </ng-template>
              <ng-template #sentTemplate>
                <svg class="icon-button-sent" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.45015 16C7.0845 16 6.74929 15.877 6.47505 15.6004L0.411372 9.48319C-0.137104 8.92987 -0.137104 8.06917 0.411372 7.51586C0.959849 6.96254 1.813 6.96254 2.36148 7.51586L7.48061 12.6494L19.6385 0.414982C20.187 -0.138332 21.0402 -0.138332 21.5887 0.414982C22.1371 0.968296 22.1371 1.82901 21.5887 2.38233L8.45566 15.6004C8.15096 15.877 7.8158 16 7.45015 16Z"
                    fill="#FFFFFF"
                  />
                </svg>
                <span>Отправлено</span>
              </ng-template>
            </button>
          </form>
        </div>
      </section>

      <section class="results-open-footer">
        <div class="results-open-banner">
          <p class="results-open-banner-text">Потенциал ребенка — ключ к его мотивации.</p>
          <p class="results-open-banner-text">
            А глубинное понимание интересов, талантов и <br />
            способностей поможет добиться высоких результатов <br />
            в учебе и стать успешным в будущем.
          </p>
          <p class="results-open-banner-text">Желаем вам и вашей семье реализации планов!</p>
          <p class="results-open-banner-text">
            <b>Команда проекта «Карта талантов Подмосковья»</b>
          </p>
        </div>

        <div class="feedback-link-wrapper">
          <span class="feedback-link-icon"></span>
          <span class="feedback-link-text">Если у вас возникли вопросы — </span>
          <a class="feedback-link" href="https://t.me/talentmosreg_bot" target="_blank">напишите нам</a>
        </div>
      </section>
    </div>
  </div>
</div>
