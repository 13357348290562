import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MosruLoginComponent } from './mosrulogin/mosrulogin.component';
import { MosruLoginRoutingModule } from './mosru-routing.module';

@NgModule({
  declarations: [MosruLoginComponent],
  imports: [CommonModule, MosruLoginRoutingModule, SharedModule],
  exports: [MosruLoginComponent],
})
export class MosruModule {}
