import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarThemes } from './progress-bar/progress-bar.component';

export const MAX_DIFFERENT: number = 15;

@Component({
  selector: 'prf-interests-progress-bar',
  templateUrl: './interests-progress-bar.component.html',
  styleUrls: ['./interests-progress-bar.component.scss'],
})
export class InterestsProgressBarComponent implements OnInit {
  public doubleResults: boolean = false;
  private _data: Array<any> = [];

  @Input()
  public showParent!: boolean;
  @Input()
  public showPupil!: boolean;
  @Input()
  public userRole: string = '';
  @Input()
  public title: string;
  @Input()
  public texts: { [key: string]: string };
  @Input()
  public mainProgressDescription: string = 'Результаты ребенка';
  @Input()
  public additionalProgressDescription: string = 'Результаты родителя';
  @Input()
  set data(val: Array<any>) {
    this._data = val;
    this.prepareData();
  }

  get data(): Array<any> {
    return this._data;
  }

  ngOnInit(): void {
    this.doubleResults = this.showParent && this.showPupil;
  }

  private prepareData() {
    this._data = this._data.sort((a, b) => b.parentEstimate - a.parentEstimate);
    this._data.forEach(item => {
      item.problem = this._data.length > 1 && Math.abs(item.childEstimate - item.parentEstimate) > MAX_DIFFERENT;
    });
    this.doubleResults = this.showParent && this.showPupil;
  }

  protected readonly ProgressBarThemes = ProgressBarThemes;
}
