<div class="lb-block-invite-welcome">
  <div class="invite-parent-row">
    <div class="add-child-column">
      <h3 class="h3-invite-parent">{{ 'SHARED.ADD_CHILD' | translate }}</h3>
      <p class="add-child-text" *ngIf="!registrationByCode">Зарегистрируйте ребенка самостоятельно</p>

      <div *ngIf="registrationByCode" class="add-child-text-code">
        <p class="add-child-text">
          Для добавления ребенка потребуется ввести {{ codeLength }}ти значный код. Взять код можно у классного руководителя вашего ребенка.
        </p>

        <div class="w-form">
          <form id="wf-form-Invite-Parent" name="wf-form-Invite-Parent" data-name="Invite Parent">
            <label class="invite-parent-label">Код приглашения</label>

            <div [class.not-found]="child === null && code.length === codeLength && loading === false" class="add-child-div">
              <div class="add-child-code-row">
                <ng-container *ngFor="let inp of inputsArray.controls; let i = index">
                  <input
                    #input
                    [class.not-found]="child === null && code.length === codeLength && loading === false"
                    class="add-child-code-field w-input"
                    [formControl]="inp"
                    (input)="onInsertData($event, inp)"
                    (keydown)="onKeyDown($event, inp)"
                  />
                </ng-container>
              </div>

              <div *ngIf="child !== null && code.length === codeLength && loading === false" class="add-child-div-2">
                <img src="{{ child.imagePath ? child.imagePath : './profilum-assets/images/icons/ava-0.png' }}" class="add-child-photo" />

                <div class="add-child-name">
                  {{ child.firstName }}
                  {{ child.middleName && child.middleName.length > 0 ? ' ' + child.middleName : '' }}
                  {{ ' ' + child.lastName }}
                </div>
              </div>

              <div *ngIf="child === null && code.length === codeLength && loading === false" class="add-child-div-2 not-found">
                <div class="add-child-name">Ребенок не найден</div>
              </div>
            </div>
          </form>
          <input
            (click)="addChild()"
            [class.not-active]="child === null"
            type="submit"
            value="{{ 'SHARED.ADD_CHILD' | translate }}"
            data-wait="Please wait..."
            class="button add-child-button w-button"
          />
          <router-outlet></router-outlet>

          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>

          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>

        <div class="add-child__code-button">
          <p>Вернуться <a (click)="registrationByCode = !registrationByCode">к форме</a> добавления ребенка</p>
        </div>
      </div>

      <prf-child-add-form *ngIf="!registrationByCode" (updateParent)="updatedParent($event)"> </prf-child-add-form>

      <div *ngIf="!registrationByCode" class="add-child__code-button">
        <p>У меня есть <a (click)="registrationByCode = !registrationByCode">код ребенка</a> из школы</p>
      </div>
    </div>
    <div class="invite-parent-image-block">
      <img src="./profilum-assets/images/illustrations/Parent.svg" class="invite-parent-image" />
    </div>
  </div>
</div>
