import { UntypedFormControl } from '@angular/forms';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

export function DateValidator(format = 'DD/MM/YYYY'): any {
  return (control: UntypedFormControl): Record<string, any> => {
    const val = DateHelper.toDayJs(control.value, format);

    if (!val.isValid()) {
      return { invalidDate: true };
    }

    return null;
  };
}
