import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ETestTime, ETestTypes } from 'app/shared/enums/testtypes.enum';
import { TESTS_VARIANTS_IDS } from '../../../shared/global-constants/tests-variants.data';
import { takeUntil } from 'rxjs/operators';
import { ITestSlidesCount } from '../../../shared/interfaces/islide.interface';
import { ApiPlayerService, AppSettingsService, Helper, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-about-test',
  templateUrl: './about-test.component.html',
  styleUrls: ['./about-test.component.scss'],
})
export class AboutTestComponent extends UnsubscribeComponent implements OnInit {
  @Input() form;

  @Output() languageSelected = new EventEmitter<any>();

  public declOfNum = Helper.declOfNum;
  public role: string = '';
  public testType: string = '';
  public testTypes: typeof ETestTypes = ETestTypes;
  public selectLanguageAvailable: boolean = false;
  public testDuration: number = null;
  public testSteps: number = null;
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  constructor(private apiPlayerService: ApiPlayerService, private webStorageService: WebStorageService) {
    super();
    this.role = this.webStorageService.get(StorageKeys.UserRole);
    this.testType = this.webStorageService.get(StorageKeys.TestType)?.toString();

    if (this.testType === this.testTypes.BVB_TEST.toString()) {
      this.apiPlayerService
        .getTestSlidesCount(TESTS_VARIANTS_IDS.bvbTest)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: ITestSlidesCount) => {
          this.testSteps = res.count;
          this.webStorageService.set(StorageKeys.SlidesNumber, res.count.toString());
        });
    }

    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguageAvailable =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == this.webStorageService.get(StorageKeys.RegionId);

    if (this.testType == null) {
      if (this.role == 'pupil') {
        this.testType = ETestTypes.DEFAULT_STANDART.toString();
      }
      if (this.role == 'parent') {
        this.testType = ETestTypes.DEFAULT_360.toString();
      } else {
        this.testType = ETestTypes.DEFAULT_STANDART.toString();
      }
    }
  }

  public ngOnInit(): void {
    this.languageSelected.emit(this.selectedLanguage);
    if (this.selectedLanguage) {
      this.toggleLanguage(this.regionLanguages.default);
    }

    if (this.testType === ETestTypes.BVB_TEST.toString()) {
      this.testDuration = ETestTime.BVB_TEST;
    }

    if (this.testType === ETestTypes.BVB_TEST_VISUALLY_IMPAIRED.toString()) {
      this.testDuration = ETestTime.BVB_TEST_VISUALLY_IMPAIRED;
    }
  }

  public toggleLanguage(language: string): void {
    if (language) {
      this.selectedLanguage = language;
      this.webStorageService.set(StorageKeys.TestLang, language);
      this.languageSelected.emit(language);
    }
    return;
  }
}
