import { AfterViewInit, Directive, ElementRef, inject, OnDestroy, signal } from '@angular/core';

@Directive({
  selector: '[prfStickyHeader]',
  standalone: true,
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy {
  public isScrolledUp = signal(false);
  private prevScroll = window.scrollY;
  private elementRef = inject(ElementRef);

  public ngAfterViewInit() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  public ngOnDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll = () => {
    const currScroll = window.scrollY;
    const isScrolledUp = this.prevScroll > currScroll;
    const element = this.elementRef.nativeElement as HTMLElement;

    if (currScroll === 0 || !isScrolledUp) {
      element.classList.remove('sticky-header');
    } else if (isScrolledUp) {
      element.classList.add('sticky-header');
    }

    this.prevScroll = currScroll;
  };
}
