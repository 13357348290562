import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { B2gSaasService, IUserInfo, StorageKeys, WebStorageService } from '@profilum-library';
import { Router } from '@angular/router';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-app-root',
  template: '',
})
export class AuthRoutingComponent implements OnInit {
  private response;
  constructor(
    private b2gSaasService: B2gSaasService,
    private userStorageService: UserStorageService,
    private webStorageService: WebStorageService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.b2gSaasService
      .getUserInfo()
      .pipe(
        tap((response: IUserInfo) => {
          this.userStorageService.setUserId = response.userId;
          this.webStorageService.set(StorageKeys.UserId, response.userId);
          this.webStorageService.set(StorageKeys.Tag, response.tag);
          this.webStorageService.set(StorageKeys.IsAuthorized, true);
          this.webStorageService.set(StorageKeys.ImagePath, response.imagePath);
          this.webStorageService.set(StorageKeys.FirstName, response.firstName);
          this.webStorageService.set(StorageKeys.LastName, response.lastName);
          this.webStorageService.set(StorageKeys.SchoolId, response.schoolId);
          this.webStorageService.set(StorageKeys.UserGender, response.gender);
          this.webStorageService.set(StorageKeys.CompanyId, response.companyId);
          this.webStorageService.set(StorageKeys.Position, response.position);
          this.webStorageService.set(StorageKeys.RegionId, response.regionId);
          this.webStorageService.set(StorageKeys.MunicipalityId, response.municipalityId);
          this.b2gSaasService
            .getShortUserInfo()
            .pipe(
              tap(response => {
                this.webStorageService.set(StorageKeys.UserRole, response.role);
                this.response = response;
              }),
            )
            .subscribe(() => this.setActiveRoute(this.response.role));
        }),
      )
      .subscribe();
  }

  private setActiveRoute(role: string) {
    const userRoles = {
      admin: () => this.router.navigate(['/admin/schools']),
      cityadmin: () => this.router.navigate(['/cityadmin']),
      adminDO: () => this.router.navigate(['/adminDO/courses/all-courses']),
      schooladmin: () => this.router.navigate(['/schooladmin/classes']),
      director: () => this.router.navigate(['/director']),
      teacher: () => this.router.navigate(['/teacher']),
      parent: () => this.router.navigate(['/parent']),
      employer: () => this.router.navigate(['/employer']),
      tutor: () => this.router.navigate(['/tutor']),
      pupil: () => this.router.navigate(['/pupil']),
    };
    const isRoleValid: boolean = userRoles.hasOwnProperty(role);
    if (role && isRoleValid) {
      userRoles[role]();
    }
  }
}
