<div class="prf-footer-map">
  <a rel="noopener noreferrer" target="_blank" class="prf-footer-map-link" [href]="location.href">
    <img [ngSrc]="location.image.src" [alt]="location.image.alt" [width]="location.image.width" [height]="location.image.height" />
  </a>
  <div class="prf-footer-map-item">
    <div class="prf-footer-map-item-title">Адрес:</div>
    <a rel="noopener noreferrer" target="_blank" [href]="location.href" class="prf-footer-map-item-text">
      {{ location.address }}
    </a>
  </div>
</div>
