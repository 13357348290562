<div class="director-analytics">
  <form [formGroup]="form">
    <form [formGroup]="educationFiltersForm">
      <ng-container *ngIf="isLoaded">
        <ng-container *ngIf="viewData && !reportNotFound; else reportNotFoundScreen">
          <prf-filters
            [showMunicipityReport]="showMunicipityReport"
            [isMenuFixed]="isMenuFixed"
            (toggleMunicipalityChartsEmitter)="toggleMunicipalityCharts()"
            (setFilterTabEmitter)="setFormControl($event)"
          ></prf-filters>
          <div #contentContainer class="fixed-container" [ngClass]="{ 'fixed-menu': isMenuFixed }">
            <section class="progress-bars">
              <div class="progress-bars-container">
                <div class="progress-item circles-bars">
                  <prf-progress-circle-bar
                    class="progress-item"
                    *ngIf="viewData?.keyIndicators"
                    [circleName]="translations['CHARTS.KEY_INDICATORS.FULL']"
                    [professionsMatching]="viewData.keyIndicators.professionsMatching"
                    [profNavigationIndex]="viewData.keyIndicators.profNavigationIndex"
                    [circlebarToolTip]="translateService.instant('CHARTS.KEY_INDICATORS.TOOLTIP')"
                  >
                  </prf-progress-circle-bar>
                </div>
                <div class="progress-item chart-tree">
<!--                  <prf-chart-tree-map-->
<!--                    *ngIf="viewData?.talentDistribution"-->
<!--                    class="progress-item"-->
<!--                    [chartName]="translations['CHARTS.TALENT_DISTRIBUTION.FULL']"-->
<!--                    [chartToolTip]="translateService.instant('CHARTS.TALENT_DISTRIBUTION.TOOLTIP')"-->
<!--                    [chartDesc]="'CHARTS.TALENT_DISTRIBUTION.DESC' | translate"-->
<!--                    [Items]="viewData.talentDistribution"-->
<!--                  ></prf-chart-tree-map>-->
                </div>
              </div>

              <div class="buttons-row">
                <a download class="_button" (click)="downloadPupilsDistribution(pupilDistributionType.Talent)">
                  {{ 'BUTTONS.DOWNLOAD_RESULTS' | translate }}
                </a>
              </div>
            </section>

            <ng-container *ngIf="showMunicipityReport">
              <section class="progress-bars municipality-report" #municipalityProgressBars>
                <div class="municipality-chart-title">Муниципалитет</div>
                <div class="progress-bars-container">
                  <div class="progress-item circles-bars">
                    <prf-progress-circle-bar
                      class="progress-item"
                      *ngIf="viewMunicipalityData?.keyIndicators"
                      [professionsMatching]="viewMunicipalityData.keyIndicators.professionsMatching"
                      [profNavigationIndex]="viewMunicipalityData.keyIndicators.profNavigationIndex"
                    ></prf-progress-circle-bar>
                  </div>
                  <div class="progress-item chart-tree">
<!--                    <prf-chart-tree-map-->
<!--                      *ngIf="viewMunicipalityData?.talentDistribution"-->
<!--                      class="progress-item"-->
<!--                      [Items]="viewMunicipalityData.talentDistribution"-->
<!--                    ></prf-chart-tree-map>-->
                  </div>
                </div>
              </section>
            </ng-container>

            <section class="profile-classes-section">
              <div class="profile-classes-container">
                <div class="progress-item chart-tree">
<!--                  <prf-chart-tree-map-->
<!--                    *ngIf="viewData?.classesDistribution"-->
<!--                    class="progress-item"-->
<!--                    [chartName]="translations['CHARTS.PROFIL_CLASSES.FULL']"-->
<!--                    [chartToolTip]="translations['CHARTS.PROFIL_CLASSES.TOOLTIP']"-->
<!--                    [Items]="viewData.classesDistribution"-->
<!--                  ></prf-chart-tree-map>-->
                  <div class="buttons-row">
                    <a class="_button" (click)="downloadPupilsDistribution(pupilDistributionType.ProfilClass)" download>{{
                      'BUTTONS.DOWNLOAD_RESULTS' | translate
                    }}</a>
                  </div>
                </div>
                <div class="progress-item circle-bars">
                  <prf-progress-circle-bar
                    class="progress-item full-height"
                    *ngIf="viewData?.institutionIndexes"
                    [circleName]="translations['CHARTS.EDUCATION_LEVEL.FULL']"
                    [circlebarToolTip]="translateService.instant('CHARTS.EDUCATION_LEVEL.TOOLTIP')"
                    [institutionIndexes]="viewData.institutionIndexes"
                  ></prf-progress-circle-bar>
                  <div class="buttons-row">
                    <a class="_button" (click)="downloadPupilsDistribution(pupilDistributionType.Level)" download>{{
                      'BUTTONS.DOWNLOAD_RESULTS' | translate
                    }}</a>
                  </div>
                </div>
              </div>
            </section>

            <ng-container *ngIf="showMunicipityReport">
              <section class="profile-classes-section municipality-report">
                <div class="municipality-chart-title">Муниципалитет</div>
                <div class="profile-classes-container">
                  <div class="progress-item chart-tree">
<!--                    <prf-chart-tree-map-->
<!--                      *ngIf="viewMunicipalityData?.classesDistribution"-->
<!--                      class="progress-item"-->
<!--                      [Items]="viewMunicipalityData.classesDistribution"-->
<!--                    ></prf-chart-tree-map>-->
                  </div>
                  <div class="progress-item circle-bars">
                    <prf-progress-circle-bar
                      class="progress-item"
                      *ngIf="viewMunicipalityData?.institutionIndexes"
                      [institutionIndexes]="viewMunicipalityData.institutionIndexes"
                    ></prf-progress-circle-bar>
                  </div>
                </div>
              </section>
            </ng-container>

            <section class="infrastructure-section director_section" *ngIf="viewData">
              <div class="infrastructure-container">
                <prf-chart-tabs
                  class="progress-item"
                  [chartTabsTooltip]="translateService.instant('CHARTS.INFRASTRUCTURE_AND_REQUESTS.TOOLTIP')"
                  [tabs]="[
                    {
                      name: 'CHARTS.ADDITIONAL_EDUCATION.SHORT' | translate,
                      content: viewData.chartEducation ? Education : empty
                    },
                    {
                      name: 'CHARTS.SPO.SHORT' | translate,
                      content: viewData.chartSPO ? SPO : empty
                    },
                    {
                      name: 'CHARTS.VPO.SHORT' | translate,
                      content: viewData.chartVPO ? VPO : empty
                    }
                  ]"
                >
                </prf-chart-tabs>
                <ng-template #Education>
                  <div class="canvas-wrapper small-height">
                    <div class="dropdown-line">
                      <div class="right">
                        <prf-dropdown
                          [itemsList]="education"
                          (ngModelChange)="setReport(ReportEnum.DEFAULT)"
                          formControlName="educationBaseFilter"
                        >
                        </prf-dropdown>
                      </div>
                    </div>
                    <ng-container *ngIf="isUpdatingBase === false">
                      <div class="director-scrollbar" [style.height]="chartEducationHeight">
                        <canvas
                          #chartEducationCanvas
                          baseChart
                          style="margin-top: 45px"
                          [type]="viewData.chartEducation.type"
                          [datasets]="viewData.chartEducation.data.datasets"
                          [labels]="viewData.chartEducation.data.labels"
                          [options]="viewData.chartEducation.options"
                        ></canvas>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
                <ng-template #SPO>
                  <div class="canvas-wrapper">
                    <div class="director-scrollbar" [style.height]="chartSPOheight">
                      <canvas
                        baseChart
                        [type]="viewData.chartSPO.type"
                        [datasets]="viewData.chartSPO.data.datasets"
                        [labels]="viewData.chartSPO.data.labels"
                        [options]="viewData.chartSPO.options"
                      ></canvas>
                    </div>
                  </div>
                </ng-template>
                <ng-template #VPO>
                  <div class="canvas-wrapper">
                    <div class="director-scrollbar" [style.height]="chartVPOheight">
                      <canvas
                        baseChart
                        [type]="viewData.chartVPO.type"
                        [datasets]="viewData.chartVPO.data.datasets"
                        [labels]="viewData.chartVPO.data.labels"
                        [options]="viewData.chartVPO.options"
                      ></canvas>
                    </div>
                  </div>
                </ng-template>
                <div class="buttons-row">
                  <a class="_button" (click)="downloadPupilsDistribution(pupilDistributionType.WantedDO)" download>{{
                    'BUTTONS.DOWNLOAD_RESULTS' | translate
                  }}</a>
                </div>
              </div>
            </section>

            <ng-container *ngIf="showMunicipityReport">
              <section class="infrastructure-section municipality-report" *ngIf="viewMunicipalityData">
                <div class="municipality-chart-title">Муниципалитет</div>
                <div class="infrastructure-container">
                  <prf-chart-tabs
                    class="progress-item"
                    [noTitle]="true"
                    [tabs]="[
                      {
                        name: 'CHARTS.ADDITIONAL_EDUCATION.SHORT' | translate,
                        content: viewMunicipalityData.chartEducation ? Education : empty
                      },
                      {
                        name: 'CHARTS.SPO.SHORT' | translate,
                        content: viewMunicipalityData.chartSPO ? SPO : empty
                      },
                      {
                        name: 'CHARTS.VPO.SHORT' | translate,
                        content: viewMunicipalityData.chartVPO ? VPO : empty
                      }
                    ]"
                  >
                  </prf-chart-tabs>
                  <ng-template #Education>
                    <div class="canvas-wrapper">
                      <div class="dropdown-line">
                        <div class="right">
                          <prf-dropdown
                            [itemsList]="education"
                            (ngModelChange)="setReport(ReportEnum.MUNICIPALITY)"
                            formControlName="educationMunicipalityFilter"
                          >
                          </prf-dropdown>
                        </div>
                      </div>
                      <ng-container *ngIf="!!isUpdatingMunicipality">
                        <div class="director-scrollbar" [style.height]="chartSPOheight">
                          <canvas
                            baseChart
                            style="margin-top: 45px"
                            [type]="viewMunicipalityData.chartEducation.type"
                            [datasets]="viewMunicipalityData.chartEducation.data.datasets"
                            [labels]="viewMunicipalityData.chartEducation.data.labels"
                            [options]="viewMunicipalityData.chartEducation.options"
                          ></canvas>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                  <ng-template #SPO>
                    <div class="canvas-wrapper small-height">
                      <div class="director-scrollbar" [style.height]="chartSPOheight">
                        <canvas
                          baseChart
                          [type]="viewMunicipalityData.chartSPO.type"
                          [datasets]="viewMunicipalityData.chartSPO.data.datasets"
                          [labels]="viewMunicipalityData.chartSPO.data.labels"
                          [options]="viewMunicipalityData.chartSPO.options"
                        ></canvas>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #VPO>
                    <div class="canvas-wrapper small-height">
                      <div class="director-scrollbar" [style.height]="chartVPOheight">
                        <canvas
                          baseChart
                          [type]="viewMunicipalityData.chartVPO.type"
                          [datasets]="viewMunicipalityData.chartVPO.data.datasets"
                          [labels]="viewMunicipalityData.chartVPO.data.labels"
                          [options]="viewMunicipalityData.chartVPO.options"
                        ></canvas>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </section>
            </ng-container>

            <section class="professions-section director_section" *ngIf="viewData">
              <div class="professions-container">
                <prf-top-list
                  class="progress-item"
                  [Denamed]="viewData.profDemanded"
                  [Popular]="viewData.profPopular"
                  [NotPopular]="viewData.profNotPopular"
                  [topListTooltip]="translateService.instant('CHARTS.TOP_PROFESSIONS.TOOLTIP')"
                ></prf-top-list>
                <div class="buttons-row">
                  <a class="_button" (click)="downloadPupilsDistribution(pupilDistributionType.Profession)" download>{{
                    'BUTTONS.DOWNLOAD_RESULTS' | translate
                  }}</a>
                </div>
                <div class="clear_float"></div>
              </div>
            </section>

            <ng-container *ngIf="showMunicipityReport">
              <section class="professions-section municipality-report" *ngIf="viewMunicipalityData">
                <div class="municipality-chart-title">Муниципалитет</div>
                <div class="professions-container">
                  <prf-top-list
                    [noTitle]="true"
                    class="progress-item"
                    [Denamed]="viewMunicipalityData.profDemanded"
                    [Popular]="viewMunicipalityData.profPopular"
                    [NotPopular]="viewMunicipalityData.profNotPopular"
                  >
                  </prf-top-list>
                </div>
              </section>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </form>
</div>

<ng-template #reportNotFoundScreen>
  <div class="event-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Courses.svg" width="100" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Для вашей школы нет отчета</div>
  </div>
</ng-template>
