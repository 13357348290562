import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeComponent } from '../../../../../components/unsubscribe/unsubscribe.component';
import { takeUntil } from 'rxjs/operators';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { HeaderService } from '../../../../../logic-services/header/header.service';

@Component({
  selector: 'prf-header-burger-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-burger-button.component.html',
  styleUrl: './header-burger-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBurgerButtonComponent extends UnsubscribeComponent implements OnInit {
  private isMenuOpened = false;
  private headerService = inject(HeaderService);
  private breakpointsService = inject(BreakpointsService);

  public ngOnInit() {
    this.breakpointsService.isDesktop.pipe(takeUntil(this.unsubscribe)).subscribe(isDesktop => {
      isDesktop && this.headerService.closeMenu();
      this.isMenuOpened = false;
    });

    this.headerService.toggleMenuSubject.pipe(takeUntil(this.unsubscribe)).subscribe(isMenuOpened => {
      document.body.classList[isMenuOpened ? 'add' : 'remove']('show-menu');
    });
  }

  public toggleMenu() {
    this.isMenuOpened ? this.headerService.closeMenu() : this.headerService.openMenu();
    this.isMenuOpened = !this.isMenuOpened;
  }
}
