import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { OverlayBusyService } from '../../logic-services/overlay-busy/overlay-busy.service';
import {
  ExtracurricularActivitiesComponent,
} from '../start-page-base-new/components/extracurricular-activities/extracurricular-activities.component';
import { FooterComponent } from '../start-page-base-new/components/footer/footer.component';
import {
  ProfessionCatalogComponent,
} from '../start-page-base-new/components/profession-catalog/profession-catalog.component';
import {
  DiagnosticsOfInterestsComponent,
} from '../start-page-base-new/components/diagnostics-of-interests/diagnostics-of-interests.component';
import { InfoBlockComponent } from '../start-page-base-new/components/info-block/info-block.component';
import { BannerComponent } from '../start-page-base-new/components/banner/banner.component';
import { HeaderComponent } from '../start-page-base-new/components/header/header.component';
import {
  InformationForDifferentUsersComponent,
} from '../start-page-base-new/components/information-for-different-users/information-for-different-users.component';
import { PrfStartPageBaseData } from '../start-page-base-new/start-page-base-new.types';
import startPageJsonData from './data/start-page.data.json';

@Component({
  selector: 'prf-start-page-base-new',
  standalone: true,
  imports: [
    ExtracurricularActivitiesComponent,
    FooterComponent,
    ProfessionCatalogComponent,
    DiagnosticsOfInterestsComponent,
    InfoBlockComponent,
    BannerComponent,
    HeaderComponent,
    InformationForDifferentUsersComponent,
  ],
  templateUrl: './start-page-base-new.component.html',
  styleUrl: './start-page-base-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPageBaseNewComponent implements OnInit {
  public startPageData: PrfStartPageBaseData = startPageJsonData;
  private overlayBusyService = inject(OverlayBusyService);

  public ngOnInit(): void {
    this.overlayBusyService.hide();
  }
}
