import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  FieldTypes,
  IData,
  IProfession,
  UserActionsService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { FavouriteIconComponent } from '../../../shared/common-components/favourite-icon/favourite-icon.component';
import { CCUColorMap, ColorMap } from './tinder-industry.collections';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-profession-mobile-card',
  standalone: true,
  templateUrl: './profession-mobile-card.component.html',
  styleUrls: ['./profession-mobile-card.component.scss'],
  imports: [NgClass, NgStyle, FavouriteIconComponent, NgIf],
})
export class ProfessionMobileCardComponent extends UnsubscribeComponent implements OnInit {
  @Input() public profession: IProfession;
  @Input() public favourite: boolean = false;
  @Input() public fromProfile: boolean = false;
  @Input() public recommended: boolean = false;
  @Input() public capabilities: boolean = false;
  @Input() public profCatalog: boolean = false;
  @Input() public noColor: boolean = false;
  @Input() public professionTryingOnStage: number = 0;

  @Output() public updateFavourites: EventEmitter<any> = new EventEmitter();
  @Output() public showProfession: EventEmitter<IProfession> = new EventEmitter<IProfession>();
  public colorMap: typeof ColorMap | typeof CCUColorMap;
  public deleting: boolean = false;
  public isBvb: boolean = false;
  private cancelledDelete: ReturnType<typeof window.setTimeout>;

  constructor(
    private router: Router,
    private yandexMetricsService: YandexMetricsService,
    private webStorageService: WebStorageService,
    private userActionsService: UserActionsService,
  ) {
    super();
    this.isBvb = location.pathname.includes('bvb');
  }

  public ngOnInit(): void {
    const isDefaultFields = this.profession?.fields[0]?.type === FieldTypes.Default;

    this.colorMap = isDefaultFields ? ColorMap : CCUColorMap;
  }

  public openProfession(): void {
    const journalParams: IData = { ProfessionID: this.profession.id };
    const event: YmItems = this.profCatalog ? YmItems.S_Professions_ProfessionsCard : YmItems.S_Favorites_ProfessionsCard;
    this.userActionsService.log(event, journalParams);
    this.showProfession.emit(this.profession);
  }

  public addToFavourite(): void {
    if (this.fromProfile) {
      this.deleteFavourite();

      if (!this.deleting) {
        const uaParams: IData = {
          ProfessionId: this.profession.id,
        };
        const ymParams: IData = {
          event_label: { Profession: this.profession.id },
        };

        this.userActionsService.log(YmItems.S_Favorites_Likes, uaParams, ymParams);
      }

      this.cancelledDelete = this.timer = setTimeout(() => {
        if (this.deleting) {
          this.updateFavourites.emit({ favourite: this.favourite, profession: this.profession });
          this.yandexMetricsService.reachGoal(YmItems.S_Favorites_NotLikes);
        }
      }, 4800);
    } else {
      this.updateFavourites.emit({ favourite: this.favourite, profession: this.profession });
      this.favourite = !this.favourite;

      if (this.favourite) {
        const uaParams: IData = {
          ProfessionId: this.profession.id,
        };
        const ymParams: IData = {
          event_label: { Profession: this.profession.id },
        };

        this.userActionsService.log(YmItems.S_Professions_Likes, uaParams, ymParams);
      } else {
        this.yandexMetricsService.reachGoal(YmItems.S_Professions_NotLikes); //
      }
    }
  }

  public deleteFavourite(): void {
    this.deleting = !this.deleting;
    clearTimeout(this.cancelledDelete);
  }
}
