<div class="profession-card__item profession-card__item--type{{ num }}" (click)="openProfession()">
  <div class="profession-card__ico">
    <img src="{{ data.mainImagePath }}" />
  </div>
  <div class="profession-card__name">{{ data.name }}</div>
  <div class="profession-card__text">{{ data.shortDescription }}</div>
  <div class="profession-card__salary">{{ data.salaryString }}</div>
  <div class="profession-card__bottom">
    <div class="profession-card__favorite">
      <div class="heart heart--st1-white" *ngIf="false"></div>
    </div>
  </div>
</div>
