<ng-container *ngIf="pupils && dataFetched">
  <ng-container *ngIf="!showPupilPage; else pupilPage">
    <div class="content-row">
      <div class="content-schooladmin">
        <div class="class-wrapper">
          <div class="class-number">
            <div class="flex-row">
              <h2 class="class-number-title" #className>
                {{ 'SHARED.CLASS_WITH_LETTER' | translate : { number: schoolClass.number, letter: schoolClass.letter } }}
              </h2>
              <a
                *ngIf="userRole == userRoles.schooladmin && !userRestriction"
                class="school-edit-link"
                (click)="showEditClass()"
                (mouseenter)="showElement($event, className.offsetWidth)"
                (mouseleave)="hideElement($event)"
              ></a>
              <div class="class-edit-link-tooltip">
                <span class="class-edit-link-tooltip-text">Редактировать информацию о классе</span>
                <img
                  class="class-edit-link-tooltip-triangle"
                  ngSrc="./assets/images/icons/info-triangle-revert.png"
                  height="12"
                  width="24"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="teacher-container">
            <div class="widget-teacher"
                 *ngIf="(teacher?.registrationCode && !teacher?.isActivated) && !userRestriction">
              <div class="widget-teacher-header">
                <p class="widget-teacher-header-title">Скопируйте и отправьте приглашение учителю</p>
                <p class="widget-teacher-header-text">
                  В сообщении — инструкция и данные для входа в систему. Отправьте учителю в WhatsApp, «ВКонтакте», Viber, Telegram или
                  другим удобным способом.
                </p>
              </div>
              <div class="widget-teacher-section">
                <button class="widget-teacher-section-button button green-btn" (click)="copyTeacherRegLink()">
                  Скопировать приглашение
                </button>
              </div>
            </div>
            <div class="teacher-container-item">
              <div class="teacher-container-lastName" *ngIf="teacher">
                <span>
                  Учитель: {{ teacher.lastName }} {{ teacher.firstName }}
                  {{ teacher.middleName }}
                </span>
                <span *ngIf="teacher?.isActivated" class="teacher-status">Зарегистрирован</span>
              </div>
            </div>
          </div>
          <div class="pupils-wrapper">
            <prf-schooladmin-success-banner *ngIf="testedPupilExists" [schoolClassId]="schoolClass.id"></prf-schooladmin-success-banner>
            <div class="class-div class-table-pupils" *ngIf="pupils.length > 0">
              <div class="students-edit">
                <h2 class="students-title">Ученики</h2>
                <a
                  *ngIf="userRole == userRoles.schooladmin && !userRestriction"
                  class="school-edit-link"
                  (click)="showEditPupils()"
                  (mouseenter)="showElement($event)"
                  (mouseleave)="hideElement($event)"
                >
                </a>
                <div class="school-edit-link-tooltip">
                  <span class="school-edit-link-tooltip-text">Редактировать список учеников</span>
                  <img
                    class="school-edit-link-tooltip-triangle"
                    ngSrc="./profilum-assets/images/icons/info-triangle.png"
                    height="12"
                    width="24"
                    alt=""
                  />
                </div>
              </div>

              <div class="pupils-table">
                <div *ngIf="pupils && pupils.length" class="content-column">
                  <div class="class-div">
                    <div class="class-header">
                      <div class="class-row class-header-row-3">
                        <div class="class-column _9"></div>
                        <div class="class-column _4">
                          <div class="class-column-count">
                            {{ pupils.length }}
                            {{ pupils.length | numerals : ['ученик', 'ученика', 'учеников'] }}
                          </div>
                        </div>
                        <div class="class-column class-column-state">
                          <!-- <div>Профессия</div> -->
                          <div class="table-column-text">
                            <span class="text">Диагностика</span>
                            <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
                            <div class="info-popup tooltip-desc">
                              <span>Статус диагностики.</span>
                              <div>
                                <span class="sign-single-done"></span>
                                <span>- прошел только ученик,</span>
                              </div>
                              <div>
                                <span class="sign-double-done"></span>
                                <span>- ученик и родитель</span>
                              </div>
                              <img
                                ngSrc="./profilum-assets/images/icons/info-triangle.png"
                                width="12"
                                class="info-triangle tooltip-desc"
                                height="6"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="class-column class-column-state">
                          <div class="table-column-text">
                            <span class="text">Траектория</span>
                            <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
                            <div class="info-popup tooltip-desc">
                              <span> Показывает, что ученик добавил одну или несколько профессий в свои цели </span>
                              <img
                                ngSrc="./profilum-assets/images/icons/info-triangle.png"
                                width="12"
                                class="info-triangle tooltip-desc"
                                height="6"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="class-list-block _2 scrollable-container">
                      <div class="students-list">
                        <div
                          class="class-list-item-2"
                          *ngFor="let pupil of pupils; let i = index"
                          [ngClass]="pupils.length === i ? 'no-underline' : ''"
                          [class.last-selected]="pupil.userId === selectedPupilId"
                        >
                          <div>
                            <div class="class-row-2">
                              <div class="class-column _9">
                                <div class="class-header-number _2">{{ i + 1 }}</div>
                              </div>
                              <div class="class-row-div" *ngIf="pupil.isActivated; else notActivated">
                                <div class="class-column _7">
                                  <div class="class-column _4">
                                    <div class="student-name">
                                      <p class="student-name-link disabled_link">{{ pupil.firstName }} {{ pupil.lastName }}</p>
                                    </div>
                                  </div>
                                </div>
                                <!--Test-->
                                <div class="class-column class-column-state class-column_green">
                                  <ng-container *ngIf="!pupil.lastSessionCompleted && !pupil.lastParentSessionCompleted">
                                    <span class="sign-none"></span>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="
                                      (pupil.lastSessionCompleted || pupil.lastParentSessionCompleted) &&
                                      !(pupil.lastSessionCompleted && pupil.lastParentSessionCompleted)
                                    "
                                  >
                                    <span class="sign-single-done"></span>
                                  </ng-container>
                                  <ng-container *ngIf="pupil.lastSessionCompleted && pupil.lastParentSessionCompleted">
                                    <span class="sign-double-done"></span>
                                  </ng-container>
                                </div>

                                <div class="class-column class-column-state">
                                  <ng-container *ngIf="!pupil.lastSessionCompleted || !pupil.favoriteProfessions.length">
                                    <span class="sign-none"></span>
                                  </ng-container>
                                  <ng-container *ngIf="pupil.lastSessionCompleted && pupil.favoriteProfessions.length">
                                    <span class="sign-single-done"></span>
                                  </ng-container>
                                </div>
                                <div [ngClass]="pupils.length - 1 === i ? 'no-underline' : 'class-line'"></div>
                              </div>

                              <ng-template #notActivated>
                                <div class="class-row-div">
                                  <div class="class-column _7">
                                    <div class="class-column _4">
                                      <div class="student-name">
                                        <a class="student-name-link disabled_link">{{ pupil.firstName }} {{ pupil.lastName }}</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="class-column _10">
                                    <div class="info-div-class">
                                      <button class="not-activated-pupil" [disabled]="userRestriction"
                                              (click)="changeResetPasswordPopup(pupil)">
                                        Не вошел в систему
                                      </button>
                                    </div>
                                  </div>
                                  <div [ngClass]="pupils.length - 1 === i ? 'no-underline' : 'class-line'"></div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="class-sidebar"
          [class.top]="pupils.length < 2 && pupils.length > 0 && !testedPupilExists && !isMOStend && !teacher?.isActivated"
          [class.middle]="pupils.length < 2 && pupils.length > 0 && !testedPupilExists && !isMOStend && teacher?.isActivated"
          [class.small]="pupils.length == 0 && !isMOStend && !teacher?.isActivated"
        >
          <prf-class-test-results *ngIf="schoolClass" [schoolClassSetter]="currentClassData"></prf-class-test-results>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #pupilPage>
  <prf-teacher-student-page [pupil]="selectedPupil"></prf-teacher-student-page>
</ng-template>

<prf-reset-password-popup
  *ngIf="resetPasswordPopup"
  [pupil]="pupil"
  [isDiagnosticClass]="isDiagnosticClass"
  (closeModal)="changeResetPasswordPopup()"
></prf-reset-password-popup>

<ng-template #inviteTeacher>
  <prf-teacher-invite
    class="side-modal-wrapper"
    [modalContent]="modalContent"
    [inviteText]="inviteText"
    (inviteCopied)="showInviteCopyConfirmation()"
  ></prf-teacher-invite>
</ng-template>
