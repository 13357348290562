import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ApiProfessionsService, ISpecialityData, StorageKeys, WebStorageService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import {
  InternshipsFiltersService,
} from '../../internship-page/internship-catalog/internship-filter/internships-filter.service';

@Component({
  selector: 'prf-all-specialties',
  templateUrl: './all-specialties.component.html',
  styleUrls: ['./all-specialties.component.scss'],
})
export class AllSpecialtiesComponent implements OnInit {
  currentSpecialties: ISpecialityData[] = [];
  currentSpecialty: ISpecialityData;
  specialtiesCount: string;

  dataFetched: boolean = false;
  viewValue: boolean = false;
  titleName: string;
  userRole: string;
  url: string;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private apiProfessionsService: ApiProfessionsService,
    private overlayService: OverlayBusyService,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
    private router: Router,
    private meta: Meta,
    private webStorageService: WebStorageService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Все специальности' });

    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    this.loadSpecialties()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.overlayService.hide());
  }

  loadSpecialties(): Observable<any> {
    return this.apiProfessionsService.getSpecialities().pipe(
      tap(response => {
        const specialties = response;

        if (specialties) {
          for (const item in specialties) {
            this.currentSpecialties.push(specialties[item]);
          }
          this.dataFetched = true;
        }
      }),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countSpecialties(specialtiesLength: number) {
    const specialtiesTitles = ['специальность', 'специальности', 'специальностей'];
    const title = this.internshipsFiltersService.getTitleByNumber(specialtiesLength, specialtiesTitles);
    return (this.specialtiesCount = specialtiesLength + ' ' + title);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
