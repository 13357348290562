<prf-menu [root]="userRole"></prf-menu>

<div class="content-section">
  <div class="container">
    <prf-page-header [title]="'Результаты теста'"></prf-page-header>

    <div class="results-section">
      <ng-container *ngIf="!nullResults; else emptyResults">
        <div *ngIf="dataLoaded" class="result-container">
          <h1 class="test-result-h1">Ценности</h1>
          <p class="test-results-text">
            Если ты знаешь свою систему ценностей — значит, знаешь свои убеждения и можешь принимать осознанные решения. На основе наших
            исследований мы выявили 15 базовых ценностей. Познакомься с результатами своего теста и нашими рекомендациями.
          </p>
          <div class="test-results-section main-categories-chart-section">
            <div *ngIf="!isMobile" class="chart-container">
              <canvas
                #chartCanvas
                baseChart
                [colors]="['#5ed591', '#FFDD46', '#FD651A']"
                [chartType]="chartData.type"
                [datasets]="chartData.data.datasets"
                [labels]="chartData.data.labels"
                [options]="chartData.options"
              ></canvas>
            </div>
            <div *ngIf="isMobile" class="chart-container chart-container_flex">
              <div *ngFor="let result of resultsArray; let i = index" class="chart-container__item">
                <div class="chart-item__label">
                  <span class="chart-item__label-num">{{ i + 1 }}.</span>{{ result.name }}
                </div>
                <div class="chart-item__bar">
                  <div class="chart-bar__scale" [style.backgroundColor]="result.backgroundColor" [style.width]="result.value + '%'">
                    <span *ngIf="result.value >= 10">{{ result.value }}%</span>
                  </div>
                  <div *ngIf="result.value < 10" class="chart-bar__scale_hidden">
                    <span>{{ result.value }}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isMobile" class="report-button-container">
              <a class="_button report-button" href="{{ './assets/docs/UsersGuide.pdf' }}" download> Скачать полный отчёт </a>
            </div>
          </div>
          <div *ngIf="!mobileOpened" class="test-results-section values-section">
            <h1 class="test-result-h1">Полный отчет</h1>
            <div class="high-values-section values-section-item" *ngIf="highValuesResults.length">
              <div class="value-level-tab">
                <div class="value-level-text">💪🏻 Высокий уровень</div>
                <div class="value-level-data">
                  {{ hightLevelRangeString }}
                </div>
              </div>
              <div class="value-cards-container">
                <div
                  *ngFor="let item of highValuesResults; let i = index"
                  [class.inactive]="item.descriptionOpened === false"
                  class="value-card"
                >
                  <div class="card-container" #valueCard>
                    <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                      <div class="card-value">
                        {{ item.value + '%' }}
                      </div>
                      <div class="card-name">{{ item.name }}</div>
                    </div>
                    <div class="card-main">
                      <div class="card-description">
                        {{ item.shortDescription }}
                      </div>
                      <button
                        *ngIf="!item.descriptionOpened"
                        class="card-link"
                        (click)="isMobile ? openMobile(item, 'high', $event) : openDescription(highValuesResults, i, valueCard)"
                      >
                        Подробнее
                      </button>
                      <button *ngIf="item.descriptionOpened" class="card-link" (click)="closeDescriptions()">Свернуть</button>
                    </div>
                  </div>
                  <div
                    class="card-desription-container"
                    [class.display]="highValuesResults[i] && highValuesResults[i].descriptionOpened"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                    [style.margin-left]="
                      highValuesResults[i] && highValuesResults[i].descriptionOpened ? '-' + (checkLeftOffset(valueCard) + 30) + 'px' : null
                    "
                  >
                    <div
                      *ngIf="!isMobile"
                      class="card-description-wrapper"
                      [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                    >
                      <div class="card-description">
                        <div class="description-container">
                          <div class="decsription-tabs">
                            <div class="description-tab-container">
                              <div class="description-tab about-tab">
                                <div class="description-card-title">О чем</div>
                                <div class="description-card-text">
                                  {{ item.aboutText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab aboutMyself-tab">
                                <div class="description-card-title">Узнай о себе</div>
                                <div class="description-card-text">
                                  {{ item.aboutMyselfText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab payAttention-tab">
                                <div class="description-card-title">Обрати внимание</div>
                                <div class="description-card-text">
                                  {{ item.payAttentionText }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="description-advice-tab">
                            <div class="description-advice-title">⭐️ Практический совет</div>
                            <div class="description-advice-text">{{ item.advice }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="middle-values-section values-section-item" *ngIf="middleValuesResults.length">
              <div class="value-level-tab">
                <div class="value-level-text">👌🏻 Средний уровень</div>
                <div class="value-level-data">
                  {{ middleLevelRangeString }}
                </div>
              </div>
              <div class="value-cards-container">
                <div
                  *ngFor="let item of middleValuesResults; let j = index"
                  class="value-card"
                  [class.inactive]="item.descriptionOpened === false"
                >
                  <div class="card-container" #valueCard>
                    <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                      <div class="card-value">
                        {{ item.value + '%' }}
                      </div>
                      <div class="card-name">{{ item.name }}</div>
                    </div>
                    <div class="card-main">
                      <div class="card-description">
                        {{ item.shortDescription }}
                      </div>
                      <button
                        *ngIf="!item.descriptionOpened"
                        class="card-link"
                        (click)="openDescription(middleValuesResults, j, valueCard, $event)"
                      >
                        Подробнее
                      </button>
                      <button *ngIf="item.descriptionOpened" class="card-link" (click)="closeDescriptions()">Свернуть</button>
                    </div>
                  </div>
                  <div
                    class="card-desription-container"
                    [class.display]="middleValuesResults[j] && middleValuesResults[j].descriptionOpened"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                    [style.margin-left]="
                      middleValuesResults[j] && middleValuesResults[j].descriptionOpened
                        ? '-' + (checkLeftOffset(valueCard) + 30) + 'px'
                        : null
                    "
                  >
                    <div
                      *ngIf="!isMobile"
                      class="card-description-wrapper"
                      [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                    >
                      <div class="card-description">
                        <div class="description-container">
                          <div class="decsription-tabs">
                            <div class="description-tab-container">
                              <div class="description-tab about-tab">
                                <div class="description-card-title">О чем</div>
                                <div class="description-card-text">
                                  {{ item.aboutText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab aboutMyself-tab">
                                <div class="description-card-title">Узнай о себе</div>
                                <div class="description-card-text">
                                  {{ item.aboutMyselfText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab payAttention-tab">
                                <div class="description-card-title">Обрати внимание</div>
                                <div class="description-card-text">
                                  {{ item.payAttentionText }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="description-advice-tab">
                            <div class="description-advice-title">⭐️ Практический совет</div>
                            <div class="description-advice-text">{{ item.advice }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="low-values-section values-section-item" *ngIf="lowValuesResults.length">
              <div class="value-level-tab">
                <div class="value-level-text">😨 Низкий уровень</div>
                <div class="value-level-data">
                  {{ lowLevelRangeString }}
                </div>
              </div>
              <div class="value-cards-container">
                <div
                  *ngFor="let item of lowValuesResults; let k = index"
                  [class.inactive]="item.descriptionOpened === false"
                  class="value-card"
                >
                  <div class="card-container" #valueCard>
                    <div class="card-background" [style.background-position-y.px]="170 - 15 - (170 * item.value) / 100">
                      <div class="card-value">
                        {{ item.value + '%' }}
                      </div>
                      <div class="card-name">{{ item.name }}</div>
                    </div>
                    <div class="card-main">
                      <div class="card-description">
                        {{ item.shortDescription }}
                      </div>
                      <button
                        *ngIf="!item.descriptionOpened"
                        class="card-link"
                        (click)="isMobile ? openMobile(item, 'low', $event) : openDescription(lowValuesResults, k, valueCard)"
                      >
                        Подробнее
                      </button>
                      <button *ngIf="item.descriptionOpened" class="card-link" (click)="closeDescriptions()">Свернуть</button>
                    </div>
                  </div>
                  <div
                    class="card-desription-container"
                    [class.display]="lowValuesResults[k] && lowValuesResults[k].descriptionOpened"
                    [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 60 + 'px' : null"
                    [style.margin-left]="
                      lowValuesResults[k] && lowValuesResults[k].descriptionOpened ? '-' + (checkLeftOffset(valueCard) + 30) + 'px' : null
                    "
                  >
                    <div
                      *ngIf="!isMobile"
                      class="card-description-wrapper"
                      [style.width]="resultsContainer && resultsContainer.offsetWidth ? resultsContainer.offsetWidth + 'px' : null"
                    >
                      <div class="card-description">
                        <div class="description-container">
                          <div class="decsription-tabs">
                            <div class="description-tab-container">
                              <div class="description-tab about-tab">
                                <div class="description-card-title">О чем</div>
                                <div class="description-card-text">
                                  {{ item.aboutText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab aboutMyself-tab">
                                <div class="description-card-title">Узнай о себе</div>
                                <div class="description-card-text">
                                  {{ item.aboutMyselfText }}
                                </div>
                              </div>
                            </div>
                            <div class="description-tab-container">
                              <div class="description-tab payAttention-tab">
                                <div class="description-card-title">Обрати внимание</div>
                                <div class="description-card-text">
                                  {{ item.payAttentionText }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="description-advice-tab">
                            <div class="description-advice-title">⭐️ Практический совет</div>
                            <div class="description-advice-text">{{ item.advice }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>

<div class="mobile-overlay" [ngClass]="{ opened: mobileOpened }" *ngIf="mobileOpened">
  <div class="motivation-swiper-wrapper">
    <a class="close" (click)="closeMobile()">
      <img src="./profilum-assets/images/results/motivation-results/close-cross.svg" alt="" />
    </a>
    <div>
      <div
        class="motivation-bar-mobile motivation-bar-mobile_first value-bar-mobile"
        [style.backgroundColor]="mobileDataColor"
        [style.background-position-y.px]="145 - (145 * mobileData['value']) / 100"
      >
        <div class="tab-item-value tab-item-value__value-test">{{ mobileData.value }}%</div>
        <div class="tab-item-name tab-item-name__value-test">
          {{ mobileData['name'] }}
        </div>
      </div>
      <div class="motiv-section-header motiv-section-header_vt">
        <p class="motiv-section-text motiv-section-text_value-test">
          {{ mobileData['shortDescription'] }}
        </p>
      </div>
      <div class="motiv-section-recommendations">
        <div class="card-description-wrapper">
          <div class="card-description">
            <div class="description-container">
              <div class="decsription-tabs">
                <div
                  class="description-tab-container"
                  style="background: #9375fb url({{ mobileImages[0] }})
                  center bottom no-repeat; background-size: contain;"
                >
                  <div class="description-tab about-tab">
                    <div class="description-card-title">О чем</div>
                    <div class="description-card-text">
                      {{ mobileData['aboutText'] }}
                    </div>
                  </div>
                </div>
                <div
                  class="description-tab-container"
                  style="background: #496df6 url({{ mobileImages[1] }})
                    center bottom no-repeat; background-size: contain;"
                >
                  <div class="description-tab aboutMyself-tab">
                    <div class="description-card-title">Узнай о себе</div>
                    <div class="description-card-text">
                      {{ mobileData['aboutMyselfText'] }}
                    </div>
                  </div>
                </div>
                <div
                  class="description-tab-container"
                  style="background: #e48d6b url({{ mobileImages[2] }})
                  center bottom no-repeat; background-size: contain;"
                >
                  <div class="description-tab payAttention-tab">
                    <div class="description-card-title">Обрати внимание</div>
                    <div class="description-card-text">
                      {{ mobileData['payAttentionText'] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="description-advice-tab">
                <div class="description-advice-title">⭐️ Практический совет</div>
                <div class="description-advice-text">{{ mobileData['advice'] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>
