<svg class="confetti-container" xmlns="http://www.w3.org/2000/svg" >
  <defs>
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color: #7953FF; stop-opacity: 1"></stop>
      <stop offset="100%" style="stop-color: #0047FF; stop-opacity: 1"></stop>
    </linearGradient>
    <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color: #0CAB72; stop-opacity: 1"></stop>
      <stop offset="100%" style="stop-color: #46C0B9; stop-opacity: 1"></stop>
    </linearGradient>
    <linearGradient id="gradient3" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color: #F87C23; stop-opacity: 1"></stop>
      <stop offset="100%" style="stop-color: #FAE057; stop-opacity: 1"></stop>
    </linearGradient>
    <linearGradient id="gradient4" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color: #3A81ED; stop-opacity: 1"></stop>
      <stop offset="100%" style="stop-color: #00F0FF; stop-opacity: 1"></stop>
    </linearGradient>
    <g class="confetti">
      <rect class="paper" width="24" height="16" />
    </g>
  </defs>
  <g class="container"/>
</svg>
