<div>
  <h2>Тест стилей</h2>
  <p class="test-breakpoints styles"></p>
</div>

<div>
  <h2>Тест скриптов</h2>
  <p *ngIf="isMobile" class="test-breakpoints">Mobile</p>
  <p *ngIf="isTablet" class="test-breakpoints">Tablet</p>
  <p *ngIf="isDesktop" class="test-breakpoints">Desktop</p>
</div>
