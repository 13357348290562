import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ICity } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { Gender } from 'app/shared/enums/gender.enums';

@Component({
  selector: 'prf-child-info-form',
  templateUrl: './child-info-form.component.html',
  styleUrls: ['./child-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoFormComponent extends UnsubscribeComponent implements OnDestroy {
  public form: UntypedFormGroup;
  public isMale: boolean = false;
  public isFemale: boolean = false;
  public cityLabel: boolean = false;
  public firstNameLabel: boolean = false;
  public lastNameLabel: boolean = false;
  public submitted: boolean = false;
  public classLabel: boolean = false;
  public cities: { name: string; data: ICity }[];

  constructor(private anketaService: AnketaService) {
    super();
    this.form = anketaService.childInfoForm;
  }

  get citiesView(): { name: string; data: ICity }[] {
    return this.cities;
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.anketaService.removeChildInfoForm();
  }

  public validField(field: AbstractControl): boolean {
    return field.touched && !!field.errors;
  }

  public setMale(): void {
    this.formControls.gender.setValue(Gender.Male);
    this.isFemale = false;
    this.isMale = true;
  }

  public setFemale(): void {
    this.formControls.gender.setValue(Gender.Female);
    this.isFemale = true;
    this.isMale = false;
  }
}
