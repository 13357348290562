export const CapabilitiesMap: Record<string, string> = {
  Творческие:
    'Способности к придумыванию и созданию творческих продуктов, будь то\n' +
    'тексты статей, рассказы, стихи, предметы искусства, музыка, кино или\n' +
    'актерская игра',
  Физические:
    'Способности к использованию ресурсов своего тела. Они включают\n' +
    'координацию движений, гибкость тела, выносливость и способность\n' +
    'преодолевать значительную силу, например поднимать тяжести',
  Организационные:
    'Способности к управлению своим поведением, временем и ресурсами,\n' +
    'работой других людей или команд. Они помогают планировать и\n' +
    'организовывать деятельность (собственную или коллектива) по достижению\n' +
    'целей и оценивать ее результаты, быть настойчивым на пути к цели',
  Абстрактные:
    'Способности к работе с мыслями, идеями и другим абстрактным материалом.\n' +
    'Они включают логику, математическое и вербальное мышление, скорость\n' +
    'восприятия и эффективность запоминания',
  Социальные:
    'Способности к взаимодействию с людьми. Они помогают хорошо выстраивать\n' +
    'общение с другими людьми, понимать причины их поведения и настроения,\n' +
    'объединяться с ними в команды для достижения целей, организовывать\n' +
    'совместную деятельность команды',
  Прикладные:
    'Способности к взаимодействию с физическим миром, его созданию и\n' +
    'редактированию, прикладной деятельности. Они помогают ориентироваться в\n' +
    'пространстве, понимать, как функционируют механизмы, что-то мастерить или\n' +
    'создавать изделия, получать осязаемый результат своей работы',
};

export const InterestsMap: Record<string, string> = {
  'Технологии и информатика':
    'Интерес к информационным технологиям, анализу данных,\n' + 'программированию, созданию сайтов, приложений и видеоигр',
  'Финансы и бизнес':
    'Интерес к работе с бизнесом, включая финансово-экономические вопросы,\n' +
    'продажам, маркетингу и оценке ценности предметов искусства️',
  'Живая природа': 'Интерес к работе с объектами живой природы, ландшафтному дизайну,\n' + 'урбанистике, озеленению и флористике',
  'Помощь людям': 'Интерес и желание помогать людям, организовывать социальную работу и\n' + 'социальные проекты, заниматься просвещением',
  Языки: 'Интерес к лингвистике и работе с языками, переводу текстов и проверке их\n' + 'корректности',
  'Неживая природа и материалы': 'Интерес к работе с объектами неживой природы - природными ресурсами и\n' + 'материалами',
  Творчество: 'Интерес к разным видам творчества, например визуальному,\n' + 'исполнительскому искусству, медиа, кино, моде\n',
  'Устройство общества':
    'Интерес к функционированию общества и государства, включая\n' + 'взаимодействие социальных групп, изучение и освещение этих процессов',
  Коммуникация: 'Интерес к общению с людьми, коммуникации и управлению',
  Спорт: 'Интерес к спортивным состязаниям, киберспорту и комментированию',
  Тексты:
    'Интерес к гуманитарным дисциплинам, где в основе работы лежит текст\n' + '(история, публицистика, писательство, библиотечное дело)\n',
  'Техника и прикладное мастерство': 'Интерес к работе с техникой и механизмами, а также к ручному труду и\n' + 'созданию изделий',
};
