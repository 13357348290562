import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderService } from '../../../../../logic-services/header/header.service';
import { PrfLogo } from '../../../components/header/header.types';

@Component({
  selector: 'prf-header-logo-link',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './header-logo-link.component.html',
  styleUrl: './header-logo-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoLinkComponent {
  @Input() logo: PrfLogo;

  private headerService = inject(HeaderService);

  public closeMenu() {
    this.headerService.closeMenu();
  }
}
