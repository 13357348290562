import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { catchError, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { from, Observable, of, throwError } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ApiUsersService, B2gSaasService, ICompletedTestsCount, ISchool, ISchoolClass } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { ISchoolStatistic } from 'app/shared/interfaces/Ischoolstatistic.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-director-school',
  templateUrl: './director-school.component.html',
  styleUrls: ['./director-school.component.scss'],
})
export class DirectorSchoolComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public title: string = '';
  public userId: string = null;
  public school: ISchool;
  public schoolTitle: string = '';
  public schoolClasses: ISchoolClass[] = [];
  public error: boolean = false;
  public schoolClassIdToOpen: string = '';
  public _schoolStatisticFlag: boolean = false;
  public schoolStatistic: ISchoolStatistic;

  public mergingArrays: Array<any> = [];
  public classesCompletedTestsCount: Array<ICompletedTestsCount> = [];
  public loadedClasses: boolean = false;
  public teachers: Array<any> = [];

  public percentTested = 65; //tested data
  public percentCredibility = 71; //teted data

  pupilsWithTrajectories: any[] = [];

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private apiUsersService: ApiUsersService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private overlayService: OverlayBusyService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет директора' });

    this.translateService
      .get('SHARED.GENERAL')
      .pipe(take(1))
      .subscribe(translation => {
        if (!this.title) {
          this.title = translation;
        }
      });
  }

  ngOnInit() {
    this.overlayService.show();
    this.b2gSaasService
      .getSchool()
      .pipe(
        take(1),
        switchMap(school => {
          this.school = school;
          this.schoolTitle = this.school?.number;
          // получаем список классов в школе

          return this.apiUsersService.getSchoolClassesDirectors().pipe(
            take(1),
            switchMap(response => {
              const schoolClasses = response.schoolClasses;
              if (schoolClasses) {
                schoolClasses.forEach(schoolClass => {
                  this.createSchoolClass(schoolClass); // добавляем класс в список
                });
              }
              return this.getStatistic().pipe(
                take(1),
                switchMap(r => {
                  // количество пройденных тестов в каждом классе
                  return this.apiUsersService.getCompletedTestsCountDirectors(this.school?.id).pipe(
                    take(1),
                    switchMap(completedTestsCount => {
                      // список классов
                      return this.apiUsersService.getSchoolClassesExtendedDirectors(this.school?.id).pipe(
                        take(1),
                        switchMap(schoolClassesExtended => {
                          // учителей класса
                          return this.apiUsersService.getTeachersDirectors(this.school?.id).pipe(
                            take(1),
                            tap(
                              teachers => {
                                this.classesCompletedTestsCount = completedTestsCount;
                                this.schoolClasses = schoolClassesExtended;
                                this.teachers = teachers;

                                // объединяем массивы в один для удобства
                                this.mergingArrays = this.schoolClasses.map(a => {
                                  const objectTwo = this.teachers.find(b => b.teacherClassesIds.includes(a.id));
                                  if (objectTwo) {
                                    Object.assign(a, objectTwo);
                                  }
                                  const _completedPercent = a.completedTestsCount / a.totalPupilsCount;
                                  return a;
                                });

                                this.mergingArrays.sort((classA, classB) => {
                                  // сортируем по классу
                                  if (classA.number < classB.number) {
                                    return -1;
                                  }
                                  if (classA.number > classB.number) {
                                    return 1;
                                  }
                                  // сортируем по букве
                                  if (classA.letter < classB.letter) {
                                    return -1;
                                  }
                                  if (classA.letter > classB.letter) {
                                    return 1;
                                  }
                                });
                                // дописываем количество завершенных тестов по каждому классу
                                this.mergingArrays.forEach(item => {
                                  const schoolClassTestsCount = this.classesCompletedTestsCount.find(c => c.schoolClassId === item.id);
                                  item.completedTestsCount =
                                    schoolClassTestsCount && typeof schoolClassTestsCount.count === 'number'
                                      ? schoolClassTestsCount.count
                                      : 0;

                                  item.completedTestPercent = item.completedTestsCount
                                    ? Math.round(100 * (item.completedTestsCount / item.totalPupilsCount))
                                    : 0;
                                });

                                this.loadedClasses = true;
                              },
                              catchError(err => {
                                this.utilsService.openSnackBar(ServerErrorMessage, 'error');
                                return throwError(err);
                              }),
                            ),
                          );
                        }),
                      );
                    }),
                  );
                }),
              );
            }),
          );
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => this.overlayService.hide());
  }

  // создание объекта класса
  public createSchoolClass(data) {
    const schoolClass: ISchoolClass = {
      id: data.id,
      teacherId: data.teacherId,
      letter: data.letter,
      number: data.number,
      schoolId: data.schoolId,
    };
    this.schoolClasses.push(schoolClass);
  }

  public processRemoveClass(removedClass: ISchoolClass) {
    this.schoolClasses = this.schoolClasses.filter(p => p.id != removedClass);
  }

  // Parse data charts
  private getStatistic(): Observable<any> {
    return this.apiUsersService.getSchoolStatisticDirectors(this.school?.id).pipe(
      tap(r => {
        this.schoolStatistic = r;
        this._schoolStatisticFlag = true;
      }),
    );
  }

  public getPupilsWithTrajectories(classes: ISchoolClass[]): Observable<any> {
    if (classes.length) {
      return from(classes).pipe(
        mergeMap(_class => {
          return this.apiUsersService.getExtendedPupilsDirectors(_class.id).pipe(
            tap(pupils => {
              pupils.forEach(pupil => {
                if (pupil && pupil.favoriteProfessions && pupil.favoriteProfessions.length) {
                  this.pupilsWithTrajectories.push(pupil);
                }
              });
            }),
          );
        }),
        toArray(),
      );
    } else {
      return of(null);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
