<div (click)="classClick(classData)" class="class-card" [ngClass]="{ 'one-card-class': isOneCard }">
  <div class="class-card__main-info">
    <span *ngIf="badgeName" class="class-card__badge" [ngClass]="badgeTheme">{{ badgeName }}</span>
    <p class="class-card__class-name" *ngIf="classData.schoolClass?.number && classData.schoolClass?.letter">
      <span
        class="class-card__class-name-bolder"
        [tp]="tooltip"
        [tpData]="classData.schoolClass.number + ' «' + classData.schoolClass.letter + '»'"
        >{{ classData.schoolClass.number }} «{{ classData.schoolClass.letter }}»</span
      >
      {{ LABELS.CLASS }}
    </p>
    <p class="class-card__closer-lesson" *ngIf="closerLessonDate">
      {{ LABELS.CLOSER_LESSON }}
      <span class="class-card__closer-lesson-date" [tp]="tooltip" [tpData]="LABELS.TOOLTIP"
        >{{ closerLessonDate }}
        <svg class="icon" xmlns="http://www.w3.org/2000/svg">
          <use [attr.href]="'./profilum-components/icons/all-icons.svg#question'"></use></svg
      ></span>
    </p>
  </div>
  <div class="class-card__button">
    <button type="button" class="button">{{ LABELS.BUTTON }}</button>
  </div>
</div>

<ng-template #tooltip let-tooltipText="data">
  <prf-tooltip [limitedWidth]="false">{{ tooltipText }}</prf-tooltip>
</ng-template>
