<div class="event-card" (click)="checkAction($event)">
  <a class="event-gradient-bg w-inline-block" [ngClass]="currentType.color">
    <div class="event-header">
      <h3 class="event-heading">{{ internship.name }}</h3>
    </div>
    <div class="event-image-bg" [ngStyle]="{ 'background-image': 'internship.mainImagePath' }"></div>
  </a>
  <div class="event-info-div">
    <a
      class="event-heart w-inline-block"
      *ngIf="!isFavorite; else isFavoriteCard"
      [style.background-image]="'url(/profilum-assets/images/icons/hearts/Heart-line-violet.svg)'"
      (mouseover)="heart.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/Heart-violet.svg)'"
      (mouseleave)="heart.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/Heart-line-violet.svg)'"
      #heart
    >
    </a>
    <div class="event-date" *ngIf="internship.isRegular">{{ 'SHARED.REGULARLY' | translate }}</div>
    <div class="event-date" *ngIf="internship.startDate">{{ startDate }}</div>
    <p class="event-description">{{ internship.shortDescription }}</p>
    <div class="event-address">{{ internship.address }}</div>
  </div>
</div>

<ng-template #isFavoriteCard>
  <a
    class="event-heart w-inline-block"
    [style.background-image]="'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    (mouseover)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-line-white.svg)'"
    (mouseleave)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    #heartActive
  ></a>
</ng-template>
