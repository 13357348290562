import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-footer-contacts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer-contacts.component.html',
  styleUrl: './footer-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterContactsComponent {
  public formattedPhone: string;
  public originalPhone: string;
  @Input() title: string;
  @Input() ymGoal?: string;
  @Input() set phone(phone: string) {
    if (!phone) return;

    this.originalPhone = phone;
    this.formattedPhone = phone.replace(/[() \s-]/g, '');
  }

  private yandexMetricsService = inject(YandexMetricsService);

  public onClickPhoneLink() {
    this.yandexMetricsService.reachGoal(this.ymGoal);
  }
}
