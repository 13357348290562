<div #dropdown (click)="!isFind ? (isFind = true) : false">
  <mat-card class="dropdown" [class.swapped]="!isFind">
    <form>
      <div class="dropdown__input">
        <input
          #findInput
          autocomplete="off"
          type="text"
          placeholder="{{ 'Все (' + _options.length + ')' }}"
          matInput
          [formControl]="control"
        />
        <div class="dropdown__icons">
          <mat-icon
            *ngIf="control.value"
            (click)="resetWithTimeout(100, toggleInput.bind(this, $event))"
            class="dropdown__input-img close-icon cursor-arrow"
            svgIcon="close"
          ></mat-icon>
          <mat-icon
            (click)="toggleInput($event)"
            class="dropdown__input-img arrow-icon cursor-arrow"
            [class.dropdown__input-img-rotate]="isFind"
            svgIcon="arrow"
          ></mat-icon>
        </div>
      </div>

      <mat-list class="dropdown__list" *ngIf="isFind">
        <mat-option
          class="dropdown__list-item cursor-arrow"
          *ngFor="let option of filteredOptions | async"
          (click)="control.setValue(option.name); writeValue(option); toggleInput($event)"
          [value]="option.name"
        >
          {{ option.name }}
        </mat-option>
      </mat-list>
    </form>
  </mat-card>
</div>
