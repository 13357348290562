import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { MenuService } from '../menu/menu.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IUserInfo, StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  @Input() home: string = 'Школы';
  @Input() homeURL: string = 'schools';
  @Input() itemsHide: Array<any> = [];
  @Input() hide: boolean = false;
  @Input() innerRoute: boolean = false;
  @Input() internshipActive: boolean = false;
  @Input() professionActive: boolean = false;
  @Input() adminLevel: string;
  public href: string;

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger2') menuTrigger2: MatMenuTrigger;

  public isShowMobileMenu: boolean = false;
  public userInfo: IUserInfo;
  public imagePath: string = '';
  public firstName: string = '';
  public isMunicipalityAdmin: boolean = false;

  isMOStend: boolean = false;
  // Переменная временно скрывает стажировки
  isInternshipsReady: boolean = false;

  constructor(
    public router: Router,
    private menuService: MenuService,
    private headerService: PageHeaderService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.imagePath = this.webStorageService.get(StorageKeys.ImagePath);
    this.firstName = this.webStorageService.get(StorageKeys.FirstName);
    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {
    this.href = this.router.url;
    this.isMunicipalityAdmin = AdminAccessLevel.MUNICIPALITY === this.webStorageService.get(StorageKeys.AdminLevel);
  }

  get routeActive(): boolean {
    return this.router.isActive('/schooladmin', true);
  }

  menuOpened() {
    const toggledMenuOverlay = document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0];
    if (window.innerWidth > 991) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -83px)');
    } else if (window.innerWidth > 767) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -55px)');
    } else {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(55px, -10px)');
    }
  }

  public selectMain() {
    this.hideProfile();
    this.hideMobileMenu();

    switch (this.userInfo.role) {
      case 'schooladmin':
        this.router.navigate(['/' + this.userInfo.role + '/classes']);
        break;
      case 'adminDO':
        this.router.navigate(['/' + this.userInfo.role + '/courses/all-courses']);
        break;
      case 'admin':
        this.router.navigate(['/' + this.userInfo.role + '/schools']);
        break;
      default:
        return this.router.navigate(['/' + this.userInfo.role]);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.menuTrigger.closeMenu();
      this.menuTrigger2.closeMenu();
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/profile']);
  }

  /* Проверка есть ли в массиве элемент который нужно скрыть */
  public checkItemHide(element): boolean {
    const hideElement = this.itemsHide.filter(el => el === element);
    return hideElement[0] != element;
  }
}
