import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';

@Component({
  selector: 'prf-progress-circle-bar',
  templateUrl: './progress-circle-bar.component.html',
  styleUrls: ['./progress-circle-bar.component.scss'],
})
export class ProgressCircleBarComponent extends UnsubscribeComponent {
  @Input() profNavigationIndex: number | null;
  @Input() professionsMatching: number | null;
  @Input() institutionIndexes: IAnswerPercents[] = [];
  @Input() circlebarToolTip: string;

  public circleTitle: string = '';
  // @Input() additionalEducationInfrastructure: number | null;

  chartsProfMatchingFull: string = '';
  chartsProfMatchingTooltip: string = '';
  chartsProfNavFull: string = '';
  chartsProfnavTooltip: string = '';
  chartsEduFull: string = '';

  @Input() set circleName(value: string) {
    this.circleTitle = value;
  }

  constructor(public translateService: TranslateService) {
    super();
    this.getTranslations([
      'CHARTS.KEY_INDICATORS.PROFESSION_MATCHING.FULL',
      'CHARTS.KEY_INDICATORS.PROFESSION_MATCHING.TOOLTIP',
      'CHARTS.KEY_INDICATORS.PROFNAVIGATION_INDEX.FULL',
      'CHARTS.KEY_INDICATORS.PROFNAVIGATION_INDEX.TOOLTIP',
      'CHARTS.EDUCATION_LEVEL.EDUCATION_INDEX.FULL',
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.chartsProfMatchingFull = translations['CHARTS.KEY_INDICATORS.PROFESSION_MATCHING.FULL'];
        this.chartsProfMatchingTooltip = translations['CHARTS.KEY_INDICATORS.PROFESSION_MATCHING.TOOLTIP'];
        this.chartsProfNavFull = translations['CHARTS.KEY_INDICATORS.PROFNAVIGATION_INDEX.FULL'];
        this.chartsProfnavTooltip = translations['CHARTS.KEY_INDICATORS.PROFNAVIGATION_INDEX.TOOLTIP'];
        this.chartsEduFull = translations['CHARTS.EDUCATION_LEVEL.EDUCATION_INDEX.FULL'];
      });
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }
}
