import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum ButtonColor {
  PURPLE = 'purple', // default
  BLUE = 'blue', // interests
  ORANGE = 'orange', // capabilities
}

@Component({
  selector: 'prf-notification-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPopupComponent {
  @Input()
  public headerText: string;
  @Input()
  public mainText: string[];
  @Input()
  public mainButtonColor: ButtonColor = ButtonColor.PURPLE;
  @Input()
  public mainButtonText: string;
  @Input()
  public secondaryButtonText: string;
  // 'next' for the next step, 'close' for returning
  @Output()
  public buttonAction: EventEmitter<'next' | 'close'> = new EventEmitter<'next' | 'close'>();

  public trackByFn(index: number): number {
    return index;
  }
}
