<section class="add-class" *ngIf="teacherLoaded">
  <div class="profile-section">
    <div class="profile-row">
      <div class="profile-column">
        <form id="email-form" [formGroup]="form">
          <h1>{{ 'SHARED.CREATING_NEW_CLASS' | translate }}</h1>
          <span class="title-school-number">{{ school.number }}</span>
          <div class="choice">
            <div class="select-class">
              <div class="dropdown-inline cat number-field" #numberFilter>
                <div class="dropdown-inline-container w-dropdown">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="numberLabel || formControls.number.value"
                      for="number"
                      class="login-label"
                      [class.error-field]="formControls.number.touched && formControls.number.errors"
                    >
                      Класс
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="formControls.number.touched && formControls.number.errors"
                        [class.fill-field]="formControls.number.touched && formControls.number.valid"
                        id="number"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="number"
                        (focusin)="numberLabel = true"
                        (focusout)="formControls.number.value ? null : (numberLabel = false)"
                        (click)="toggleOpen($event.target)"
                        (keydown)="disabledTextInput($event)"
                      />
                      <div class="dropdown-arrow" (click)="toggleOpen($event.target)" [class.opened]="dropdownNumberOpen" [class.fill]="formControls.number.value"></div>
                    </div>
                  </div>
                  <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="dropdownNumberOpen" #numberDrop>
                    <div class="prof-categories-links child-dropdown-column">
                      <a
                        class="prof-categories-link w-inline-block"
                        *ngFor="let number of numbersClass"
                        (click)="setNumber(number.value); toggleOpen($event.target)"
                      >
                        <div class="category-text-link">{{ number.value }}</div>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
              <div>
                <div class="dropdown-inline cat">
                  <div class="dropdown-inline-container w-dropdown">
                    <div class="login-input-container">
                      <label
                        [class.topped-out]="letterLabel || formControls.letter.value"
                        for="number"
                        class="login-label"
                        [class.error-field]="formControls.letter.touched && formControls.letter.errors"
                      >
                        Буква или название
                      </label>

                      <div class="input-wrapper">
                        <input
                          class="login-input uppercase w-input"
                          [class.error-field]="formControls.letter.touched && formControls.letter.errors"
                          [class.fill-field]="formControls.letter.touched && formControls.letter.valid"
                          id="letter"
                          maxlength="25"
                          type="text"
                          autocomplete="off"
                          formControlName="letter"
                          (focusin)="letterLabel = true"
                          (focusout)="formControls.letter.value ? null : (letterLabel = false)"
                        />
                        <mat-error *ngIf="formControls.letter.touched && formControls.letter.errors">
                          Некорректное название класса
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="class-teacher-select class-teacher-select_course" *ngIf="allowedCoursesToClass.length">
                <input
                  class="class-field w-input"
                  type="text"
                  formControlName="course"
                  [class.error]="formControls.course.touched && formControls.course.errors"
                  placeholder="Выбрать курс из списка"
                  autocomplete="off"
                  (click)="toggleOpenCourse()"
                  (keydown)="disabledTextInput($event)"
                  #courseDrop
                  readonly
                />
                <div class="dropdown-arrow" (click)="toggleOpenCourse()" [class.opened]="dropdownCourseOpen" [class.fill]="formControls.course.value"></div>
                <div class="teachers-list-wrapper" *ngIf="dropdownCourseOpen" (mouseleave)="toggleOpenCourse()">
                  <ul class="teachers-list">
                    <ng-container *ngFor="let course of allowedCoursesToClass">
                      <li class="teacher-list-item" (click)="setCourse(course)">
                        {{ course.name }}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="class-teacher">
              <div class="subtitle-class">Учитель</div>

              <div *ngIf="addNewTeacher" class="class-teacher-form">
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="lastNameLabel || formControls.lastName.value"
                      for="lastName"
                      class="login-label"
                      [class.error-field]="formControls.lastName.touched && formControls.lastName.errors"
                    >
                      Фамилия
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="formControls.lastName.touched && formControls.lastName.errors"
                        [class.fill-field]="formControls.lastName.touched && formControls.lastName.valid"
                        id="lastName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="lastName"
                        (focusin)="lastNameLabel = true"
                        (focusout)="formControls.lastName.value ? null : (lastNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="firstNameLabel || formControls.firstName.value"
                      for="firstName"
                      class="login-label"
                      [class.error-field]="formControls.firstName.touched && formControls.firstName.errors"
                    >
                      Имя
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="formControls.firstName.touched && formControls.firstName.errors"
                        [class.fill-field]="formControls.firstName.touched && formControls.firstName.valid"
                        id="firstName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="firstName"
                        (focusin)="firstNameLabel = true"
                        (focusout)="formControls.firstName.value ? null : (firstNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="middleNameLabel || formControls.middleName.value"
                      for="middleName"
                      class="login-label"
                      [class.error-field]="formControls.middleName.touched && formControls.middleName.errors"
                    >
                      Отчество
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="formControls.middleName.touched && formControls.middleName.errors"
                        [class.fill-field]="formControls.middleName.touched && formControls.middleName.valid"
                        id="middleName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="middleName"
                        (focusin)="middleNameLabel = true"
                        (focusout)="formControls.middleName.value ? null : (middleNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!addNewTeacher" class="class-teacher-select">
                <input
                  class="class-field w-input"
                  type="text"
                  formControlName="fullName"
                  placeholder="Выбрать из списка"
                  autocomplete="off"
                  (click)="toggleOpenTeacher()"
                  (input)="searchTeachersList()"
                  #teacherDrop
                />
                <div class="dropdown-arrow" (click)="toggleOpenTeacher()" [class.opened]="showTeachersList"
                     [class.fill]="formControls.fullName.value"></div>
                <div class="teachers-list-wrapper" *ngIf="showTeachersList" (mouseleave)="toggleOpenTeacher()">
                  <ul class="teachers-list">
                    <ng-container *ngIf="searchTeachers?.length; else noSearchTeachers">
                      <ng-container *ngFor="let teacher of searchTeachers">
                        <li class="teacher-list-item" (click)="selectTeacher(teacher)">
                          {{ teacher.fullName }}
                        </li>
                      </ng-container>
                    </ng-container>
                    <ng-template #noSearchTeachers>
                      <li class="teacher-list-item">
                        не найдено
                      </li>
                    </ng-template>
                  </ul>
                </div>
              </div>

              <a (click)="toggleInputTeacher()">
                {{ addNewTeacher ? 'Выбрать из списка' : 'Добавить нового учителя' }}
              </a>
            </div>
          </div>
          <div class="class-buttons-row left">
            <a
              (click)="animateSubmit()"
              [ngClass]="{ disabled: !isAccessAllowed() && !saveInProcess }"
              class="button class-save-button"
              id="btnActivation"
            >
              <!--<span class="btn-icon"></span>-->
              <span class="btn-text" data-wait="Подождите" data-after="Сохранено">Создать</span>
            </a>
            <a routerLink="/schooladmin/classes" class="button white-button class-edit-button w-button"> Отменить </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
