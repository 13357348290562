import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { B2gSaasService } from '@profilum-library';

@Component({
  selector: 'prf-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  news: any = [];
  @Input() schools;

  constructor(private meta: Meta, private b2gSaasService: B2gSaasService) {
    this.meta.updateTag({ name: 'og:title', content: 'Новости' });
  }

  public ngOnInit(): void {
    this.b2gSaasService
      .getNews('0', '10', '')
      .pipe(take(1))
      .subscribe(res => {
        this.news = [];
        res.forEach(item => {
          this.news.push(item);
        });
      });
  }
}
