export const ColorMap: Record<string, string> = {
  'Агропромышленность': '#00CF15',
  'Архитектура, строительство и недвижимость': '#E25100',
  'Безопасность': '#FFDE32',
  'Бизнес, менеджмент и деловые услуги': '#8F8F8F',
  'Государственные и социальные сервисы': '#003ACE',
  'Добыча полезных ископаемых': '#8F5600',
  'Здравоохранение и медицина': '#00E7AF',
  'Информационные технологии (ИТ) и телекоммуникации': '#651DFF',
  'Космическая отрасль': '#070707',
  'Красота и мода': '#f0445b',
  'Культура и искусство': '#FA06FF',
  'Легкая промышленность': '#9644FF',
  'Лесная промышленность, деревообработка': '#00A624',
  'Машиностроение и производство транспортных средств': '#022674',
  'Металлургия': '#00767D',
  'Образование и наука': '#0047FF',
  'Пищевая промышленность': '#FF8A00',
  'Силикатная промышленность': '#6CF6FF',
  'СМИ, медиа и развлечения': '#FF0062',
  'Спорт': '#4C2FFF',
  'Топливно-энергетический комплекс (ТЭК)': '#B7F800',
  'Торговля': '#E20000',
  'Транспорт и логистика': '#EA02D3',
  'Туризм, ресторанный и гостиничный бизнес': '#FFF737',
  'Финансы и страхование': '#00BC35',
  'Химическая промышленность': '#69DBFF',
  'Электроника и приборостроение': '#8858ED',
  'Юридические институты': '#FFBC54',
};

export const DescriptionMap: Record<string, string> = {
  'Агропромышленность': 'Разведение животных, выращивание растений, сбор урожая.',
  'Архитектура, строительство и недвижимость': 'Строительство и ремонт домов, дорог, мостов. Покупка и продажа земли и недвижимости.',
  'Безопасность': 'Обеспечение безопасности людей и имущества, предотвращение преступлений.',
  'Бизнес, менеджмент и деловые услуги': 'Создание и продажа товаров, оказание услуг с целью заработать деньги.',
  'Государственные и социальные сервисы': 'Управление жизнью страны, общение с другими государствами, решение проблем граждан.',
  'Добыча полезных ископаемых': 'Добыча ценных ресурсов, например, угля, нефти или газа.',
  'Здравоохранение и медицина': 'Забота о здоровье людей: лечение и профилактика болезней, создание лекарств.',
  'Информационные технологии (ИТ) и телекоммуникации': 'Использование компьютеров, программ и интернета для работы с информацией.',
  'Космическая отрасль': 'Исследование космоса, запуск ракет и спутников, изучение планет и звезд.',
  'Красота и мода': 'Создание стильной одежды, причесок, макияжа и аксессуаров для самовыражения.',
  'Культура и искусство': 'Выражение идей и чувств через музыку, кино, живопись и литературу.',
  'Легкая промышленность': 'Производство товаров для повседневной жизни: одежды, обуви и предметов для дома.',
  'Лесная промышленность, деревообработка': 'Заготовка древесины, производство мебели, бумаги и картона.',
  'Машиностроение и производство транспортных средств': 'Производство автомобилей, самолетов и кораблей. Конструирование станков, комбайнов и другой техники.',
  'Металлургия': 'Обработка металлической руды для создания домов, машин, мостов и электроники.',
  'Образование и наука': 'Обучение и исследование мира вокруг нас. Получение знаний и развитие общества.',
  'Пищевая промышленность': 'Производство готовой еды, напитков и полуфабрикатов.',
  'Силикатная промышленность': 'Производство стекла, кирпича, цемента и керамики.',
  'СМИ, медиа и развлечения': 'Источники новостей и информации: телевидение, интернет-блоги, видео-хостинги, новостные сайты и телеграм-каналы.',
  'Спорт': 'Олимпийские игры и чемпионаты, спортивные команды и клубы, тренажеры и товары для спорта.',
  'Топливно-энергетический комплекс (ТЭК)': 'Обеспечение страны энергией: бензином, газом, электричеством.',
  'Торговля': 'Покупка и продажа товаров или услуг между людьми и компаниями.',
  'Транспорт и логистика': 'Доставка грузов и перевозка пассажиров из одного места в другое.',
  'Туризм, ресторанный и гостиничный бизнес': 'Путешествия, изучение культуры, места для отдыха и встреч.',
  'Финансы и страхование': 'Управление деньгами с помощью банков, фондов и бирж. Защита денег, бизнеса и имущества от различных угроз.',
  'Химическая промышленность': 'Производство бытовых товаров: моющих средств, косметики, пластика. А также товаров для других отраслей: медицины, сельского хозяйства и энергетики.',
  'Электроника и приборостроение': 'Создание электронных устройств: телевизоров, компьютеров и телефонов. Производство приборов для измерения: термометров, манометров и многих других.',
  'Юридические институты': 'Защита прав человека и обеспечение справедливости в обществе.',
};
export const IconsMap: Record<string, string> = {
  'Агропромышленность': '🌾',
  'Архитектура, строительство и недвижимость': '🏗️',
  'Безопасность': '👮‍♀️',
  'Бизнес, менеджмент и деловые услуги': '💼',
  'Государственные и социальные сервисы': '🏛️',
  'Добыча полезных ископаемых': '⛏️',
  'Здравоохранение и медицина': '🏥',
  'Информационные технологии (ИТ) и телекоммуникации': '💻',
  'Космическая отрасль': '🚀',
  'Красота и мода': '💅',
  'Культура и искусство': '🎭',
  'Легкая промышленность': '👕',
  'Лесная промышленность, деревообработка': '🌲',
  'Машиностроение и производство транспортных средств': '🚗',
  'Металлургия': '🔩',
  'Образование и наука': '🎓',
  'Пищевая промышленность': '🥐',
  'Силикатная промышленность': '🏭',
  'СМИ, медиа и развлечения': '📺',
  'Спорт': '🚴‍♂️',
  'Топливно-энергетический комплекс (ТЭК)': '⚡️',
  'Торговля': '🛒',
  'Транспорт и логистика': '🚚',
  'Туризм, ресторанный и гостиничный бизнес': '🏖️',
  'Финансы и страхование': '💰',
  'Химическая промышленность': '🔬',
  'Электроника и приборостроение': '🔌',
  'Юридические институты': '⚖️',
};

export const ImagesMap: Record<string, string> = {
  'Агропромышленность': './assets/images/industries/Агропромышленность.png',
  'Архитектура, строительство и недвижимость': './assets/images/industries/Архитектура, строительство и недвижимость.png',
  'Безопасность': './assets/images/industries/Безопасность.png',
  'Бизнес, менеджмент и деловые услуги': './assets/images/industries/Бизнес.png',
  'Государственные и социальные сервисы': './assets/images/industries/Государственные сервисы.png',
  'Добыча полезных ископаемых': './assets/images/industries/Добыча полезных ископаемых.png',
  'Здравоохранение и медицина': './assets/images/industries/Медицина и здравоохранение.png',
  'Информационные технологии (ИТ) и телекоммуникации': './assets/images/industries/Информационные технологии.png',
  'Космическая отрасль': './assets/images/industries/Космическая отрасль.png',
  'Красота и мода': './assets/images/industries/Красота и мода.png',
  'Культура и искусство': './assets/images/industries/Культура.png',
  'Легкая промышленность': './assets/images/industries/Легкая промышленность.png',
  'Лесная промышленность, деревообработка': './assets/images/industries/Лесная промышленность и деревообработка.png',
  'Машиностроение и производство транспортных средств': './assets/images/industries/Машиностроение и производство ТС.png',
  'Металлургия': './assets/images/industries/Металлургия.png',
  'Образование и наука': './assets/images/industries/Образование и наука.png',
  'Пищевая промышленность': './assets/images/industries/Пищевая.png',
  'Силикатная промышленность': './assets/images/industries/Силикатная промышленность.png',
  'СМИ, медиа и развлечения': './assets/images/industries/СМИ.png',
  'Спорт': './assets/images/industries/Спорт.png',
  'Топливно-энергетический комплекс (ТЭК)': './assets/images/industries/ТЭК.png',
  'Торговля': './assets/images/industries/Торговля.png',
  'Транспорт и логистика': './assets/images/industries/Транспорт и логистика.png',
  'Туризм, ресторанный и гостиничный бизнес': './assets/images/industries/Туризм.png',
  'Финансы и страхование': './assets/images/industries/Финансы и страхование.png',
  'Химическая промышленность': './assets/images/industries/Химическая.png',
  'Электроника и приборостроение': './assets/images/industries/Электроника и приборостроение.png',
  'Юридические институты': './assets/images/industries/Юридические институты.png',
};

export const CCUColorMap: Record<string, string> = {
  'Архитектура': '#00CF15',
  'Видео и компьютрные игры': '#FFDE32',
  'Дизайн': '#003ACE',
  'Издательская деятельность': '#00E7AF',
  'Изобразительное искусство': '#651DFF',
  'Исполнительские искусства': '#f0445b',
  'Кино': '#FA06FF',
  'Культурное наследие': '#9644FF',
  'Мода': '#00A624',
  'Музеи и библиотеки': '#FFBC54',
  'Музыка': '#0047FF',
  'Народные промыслы': '#FF8A00',
  'Образоание': '#6CF6FF',
  'Реклама': '#FF0062',
  'TV и радио': '#B7F800',
};
