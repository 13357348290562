import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { ETestType } from 'app/pages/player/anketa/anketa.enums';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKeys, WebStorageService } from '@profilum-library';

export const AnketaTestTypes = new Map<string, (userRole: string) => ETestType>([
  [
    'default',
    (userRole: string) => {
      return userRole === 'parent' ? ETestType.Test360 : ETestType.Default;
    },
  ],
  [ETestTypes.MOTIVATION_TEST.toString(), (userRole: string) => ETestType.MotivationTest],
  [ETestTypes.VALUES_TEST.toString(), (userRole: string) => ETestType.ValuesTest],
  [ETestTypes.MINCIFRYDEMO_TEST.toString(), (userRole: string) => ETestType.MincifrydemoTest],
  [ETestTypes.BVB_TEST.toString(), (userRole: string) => ETestType.BvbTest],
  [ETestTypes.BVB_TEST_VISUALLY_IMPAIRED.toString(), (userRole: string) => ETestType.BvbTestVisuallyImpaired],
  [ETestTypes.VK_TEST.toString(), (userRole: string) => ETestType.VkTest],
  [ETestTypes.CAMP_TEST.toString(), (userRole: string) => ETestType.CampTest],
  [ETestTypes.MINCIFRY_TEST.toString(), (userRole: string) => ETestType.MincifryTest],
  [ETestTypes.WEB_TEST.toString(), (userRole: string) => ETestType.WebTest],
]);

export const TestTypeSetter = new Map<string, (userRole: string, webStorageService: WebStorageService) => void>([
  [
    'motivation-test',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.MOTIVATION_TEST, webStorageService),
  ],
  [
    'mosrupromo',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.PROMO_TEST, webStorageService),
  ],
  [
    'values-test',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.VALUES_TEST, webStorageService),
  ],
  [
    'test-na-professiyu',
    (userRole: string, webStorageService: WebStorageService) => {
      userRole === 'pupil'
        ? setTestTypeToStorage(ETestTypes.DEFAULT_STANDART, webStorageService)
        : userRole === 'parent'
        ? setTestTypeToStorage(ETestTypes.DEFAULT_360, webStorageService)
        : null;
    },
  ],
  [
    'mincifrydemotest',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.MINCIFRYDEMO_TEST, webStorageService),
  ],
  ['bvb-test', (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.BVB_TEST, webStorageService)],
  [
    'bvb-test-visually-impaired',
    (userRole: string, webStorageService: WebStorageService) =>
      setTestTypeToStorage(ETestTypes.BVB_TEST_VISUALLY_IMPAIRED, webStorageService),
  ],
  ['vk-test', (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.VK_TEST, webStorageService)],
  ['web-test', (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.WEB_TEST, webStorageService)],
  ['camp-test', (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.CAMP_TEST, webStorageService)],
  [
    'mincifry-test',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.MINCIFRY_TEST, webStorageService),
  ],
  // [
  //   'interests-test',
  //   (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.INTERESTS_TEST, webStorageService),
  // ],
  // [
  //   'capabilities-test',
  //   (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.CAPABILITIES_TEST, webStorageService),
  // ],
  [
    'career-test',
    (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.CAREER_LITERACY_TEST, webStorageService),
  ],
  // [
  //   'mobile-test',
  //   (userRole: string, webStorageService: WebStorageService) => setTestTypeToStorage(ETestTypes.MOBILE_TEST, webStorageService),
  // ],
]);

export const setTestTypeToStorage = (testType: ETestTypes, webStorageService: WebStorageService): void => {
  webStorageService.set(StorageKeys.TestType, testType.toString());
};

export const setTestType = (activatedRoute: ActivatedRoute, webStorageService: WebStorageService): Observable<string> => {
  return activatedRoute.url.pipe(
    map((url: UrlSegment[]) => {
      const userRole: string = webStorageService.get(StorageKeys.UserRole);

      url.forEach((segment: UrlSegment) =>
        TestTypeSetter.has(segment.path) ? TestTypeSetter.get(segment.path)(userRole, webStorageService) : null,
      );
      return webStorageService.get(StorageKeys.TestType);
    }),
  );
};
