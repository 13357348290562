<div class="test-result-block" [class.open-results]="openResults">
  <h2 class="test-result-h1">{{ interestsAndSkills.title | translate }}</h2>
  <p class="test-result-text _w-700">{{ interestsAndSkills.description | translate }}</p>
  <div class="test-result-parameters">
    <div *ngFor="let skillData of interestsData" class="test-result-parameters-row">
      <prf-tooltip-text>{{ skillData.description }}</prf-tooltip-text>
      <div class="test-result-parameters-text">{{ skillData.title }}</div>
      <div class="test-result-parameters-column">
        <div class="test-result-parameters-bar">
          <div [style.width]="skillData.parentEstimate + '%'" class="test-result-parameters-line w-clearfix">
            <div class="test-result-parameters-oval"></div>
          </div>
        </div>
        <div class="test-result-parameters-num">
          {{ skillData.parentEstimate }}
        </div>
      </div>
    </div>
  </div>
</div>
