import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminDOModule } from './adminDO/admin-do.module';
import { CityAdminModule } from './cityadmin/city-admin.module';
import { ControlPanelComponent } from './control-panel.component';
import { DirectorModule } from './director/director.module';
import { ParentModule } from './parent/parent.module';
import { SchoolAdminModule } from './schooladmin/school-admin.module';
import { TeacherModule } from './teacher/teacher.module';
import { EmployerModule } from './employer/employer.module';
import { TutorModule } from './tutor/tutor.module';
import { UtilsService } from '@profilum-library';

@NgModule({
  declarations: [ControlPanelComponent],
  imports: [
    CommonModule,
    AdminDOModule,
    EmployerModule,
    CityAdminModule,
    SchoolAdminModule,
    DirectorModule,
    TeacherModule,
    ParentModule,
    TutorModule,
  ],
  exports: [AdminDOModule, EmployerModule, CityAdminModule, SchoolAdminModule, DirectorModule, TeacherModule, ParentModule],
  providers: [UtilsService],
})
export class ControlPanelModule {}
