import { Injectable } from '@angular/core';
import { IPupilsInClass } from '../../../shared/interfaces/iextendedpupil.interface';
import { AppSettingsService, Helper, IExtendedPupil } from '@profilum-library';

@Injectable({
  providedIn: 'root',
})
export class InvitationInstructionService {
  private static setInvitationText(pupil: IExtendedPupil): string {
    const loginPath: string = AppSettingsService.settings.copyLinkLogin;
    const registerPath: string = location.origin + '/code-registration';
    return (
      `${pupil.lastName} ${pupil.firstName},\n` +
      `добро пожаловать в Профилум!\n` +
      `1. перейди по ссылке: ${pupil.isActivated ? loginPath : registerPath}\n` +
      `2. ${pupil.isActivated ? 'введи свой логин:' : 'введи свой e-mail'} ${pupil.isActivated ? pupil.email : ''}\n` +
      `3. введи код: ${pupil.registrationCode}`
    );
  }

  public createInvitationCardData(pupil: IExtendedPupil): IPupilsInClass {
    const fullName: string = `${pupil.lastName} ${pupil.firstName}`;
    const email: string = pupil.isActivated && pupil.email ? pupil.email : '';
    const phoneNumberMask: string = pupil.phoneNumber ? Helper.setMaskPhoneNumber(pupil.phoneNumber) : null;
    return {
      ...pupil,
      fullNameText: fullName.length > 30 ? fullName.substring(0, 27) + '...' : fullName,
      loginText: email.length > 34 ? email.substring(0, 31) + '...' : email,
      invitationText: InvitationInstructionService.setInvitationText(pupil),
      phoneNumberMask: phoneNumberMask,
    };
  }
}
