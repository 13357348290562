import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H2Component } from '../../components/h2/h2.component';
import { DescriptionComponent } from '../../components/description/description.component';
import {
  ExtracurricularActivityCardsComponent,
} from '../../components/extracurricular-activities/extracurricular-activity-cards/extracurricular-activity-cards.component';
import {
  PrfExtraCurricularActivitiesData,
} from '../../components/extracurricular-activities/extracurricular-activity-cards.types';
import { SectionComponent } from '../../components/section/section.component';

@Component({
  selector: 'prf-extracurricular-activities',
  standalone: true,
  imports: [CommonModule, H2Component, DescriptionComponent, ExtracurricularActivityCardsComponent, SectionComponent],
  templateUrl: './extracurricular-activities.component.html',
  styleUrl: './extracurricular-activities.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtracurricularActivitiesComponent {
  @Input() extraCurricularActivities: PrfExtraCurricularActivitiesData;
}
