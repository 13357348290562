import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { SchoolAdminRoutingModule } from './school-admin-routing.module';
import { SchooladminAddClassComponent } from './schooladmin-add-class/schooladmin-add-class.component';
import { SchooladminAddStudentsComponent } from './schooladmin-add-students/schooladmin-add-students.component';
import { SchooladminClassTemplateComponent } from './schooladmin-class-template/schooladmin-class-template.component';
import { SchooladminClassComponent } from './schooladmin-classes/schooladmin-class/schooladmin-class.component';
import { SchooladminClassesComponent } from './schooladmin-classes/schooladmin-classes.component';
import { SchooladminWelcomeComponent } from './schooladmin-classes/schooladmin-welcome/schooladmin-welcome.component';
import { SchooladminEditClassComponent } from './schooladmin-edit-class/schooladmin-edit-class.component';
import { SchoolAdminPanelComponent } from './schooladmin-panel.component';
import { SchooladminShareBannerComponent } from './schooladmin-share-banner/schooladmin-share-banner.component';
import { SchooladminAccessBannerComponent } from './schooladmin-access-banner/schooladmin-access-banner.component';
import { SchooladminSuccessBannerComponent } from './schooladmin-success-banner/schooladmin-success-banner.component';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import {
  SchooladminSuccessBannerTeacherComponent,
} from './schooladmin-success-banner-teacher/schooladmin-success-banner-teacher.component';
import { TeacherInviteComponent } from 'app/ui-kit/components/modal/side-modal/templates/teacher-invite';
import { TippyDirective } from '@ngneat/helipopper';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import {
  RestrictionBannerComponent,
} from '../../../shared/common-components/restriction-banner/restriction-banner.component';
import { SuspensionMessageDirective } from '../../../shared/directives/suspension-message/suspension-message.directive';

@NgModule({
  declarations: [
    SchoolAdminPanelComponent,
    SchooladminAddStudentsComponent,
    SchooladminClassTemplateComponent,
    SchooladminEditClassComponent,
    SchooladminAddClassComponent,
    SchooladminClassesComponent,
    SchooladminClassComponent,
    SchooladminWelcomeComponent,
    SchooladminShareBannerComponent,
    SchooladminAccessBannerComponent,
    SchooladminSuccessBannerComponent,
    SchooladminSuccessBannerTeacherComponent,
  ],
  providers: [TeacherClassCoursesService],
  imports: [
    CommonModule,
    SchoolAdminRoutingModule,
    SharedModule,
    NgOptimizedImage,
    TeacherInviteComponent,
    TippyDirective,
    TooltipComponent,
    RestrictionBannerComponent,
    SuspensionMessageDirective,
  ],
})
export class SchoolAdminModule {}
