import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MosruLoginComponent } from './mosrulogin/mosrulogin.component';

const routes: Routes = [
  {
    path: 'mosrulogin',
    component: MosruLoginComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class MosruLoginRoutingModule {}
