import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { YandexMetricsService, YmItems } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { ClipboardService } from 'ngx-clipboard';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

enum EPopupTypes {
  StartPage = 'startPage',
  SuccessCopy = 'successCopy',
}

@Component({
  selector: 'prf-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.scss'],
})
export class ResetPasswordPopupComponent extends UnsubscribeComponent {
  @Input() public pupil: IPupilsInClass;
  @Input() private newPhone: string = '';
  @Output() private closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public popupTypes: typeof EPopupTypes = EPopupTypes;
  public popupType: string = EPopupTypes.StartPage;
  public invitationText: string = '';
  public dictionary: DictionaryType;

  @Input() private set isDiagnosticClass(isDiagnosticClass: boolean) {
    if (isDiagnosticClass) {
      const copyText: string = '! Перейди по ссылке, чтобы активировать аккаунт и начать проходить диагностику: ';
      this.invitationText =
        'Привет, ' + this.pupil.firstName + copyText + location.origin + '/code-registration?code=' + this.pupil.registrationCode;
    } else {
      const copyText: string = '! Перейди по ссылке, чтобы активировать аккаунт и начать курс "Карьерная грамотность": ';
      this.invitationText =
        'Привет, ' + this.pupil.firstName + copyText + location.origin + '/code-registration?code=' + this.pupil.registrationCode;
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private clipboardService: ClipboardService,
    private yandexMetricsService: YandexMetricsService,
    private rootScopeService: RootScopeService,
  ) {
    super();
    this.dictionary = rootScopeService.getDictionary();
    document.body.style.overflow = 'hidden'
  }

  public closePopup(): void {
    document.body.style.overflow = 'auto'
    this.closeModal.emit(true);
  }

  public copyInvitation(): void {
    this.clipboardService.copy(this.invitationText);
    this.yandexMetricsService.reachGoal(YmItems.T_Class_StudentAccess);
    this.popupType = EPopupTypes.SuccessCopy;
  }
}
