import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { Router } from '@angular/router';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  ApiCoursesMaterialsService,
  EmptyGuid,
  Helper,
  IActiveLesson,
  IData,
  ILessonActivity,
  ILessons,
  IPassedLessons,
  ISchoolClass,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { StorageKeys as UserStorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  IAssigningCourseData,
  IChapter,
  IChapterInfo,
  IChaptersInfo,
  ICourseMaterialExt,
  ILessonMaterial,
} from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import {
  TeachersJournalService,
} from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import { ILessonResultsCount } from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { MOSRED_ID } from '../../../interfaces/regions-id.interface';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-class-courses-lessons',
  templateUrl: './class-courses-lessons.component.html',
  styleUrls: ['./class-courses-lessons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassCoursesLessonsComponent extends UnsubscribeComponent implements OnInit {
  public lessonsInfo: ILessons;
  public nextChapter: IChapter;
  public currentChapter: IChapter;
  public previousChapter: IChapter;
  public hasPassedLesson: boolean = false;
  public showOpenLessonPopup: boolean = false;
  // todo скрывает последующие уроки в рамках задачи SITE-7489 (см. комментарии), нужно будет выпилить
  public activeLessonIds: string[] = [];
  public selectedLesson: ILessonMaterial;
  public schoolClassId: string;
  public pupilCount: number;
  public ymItems = YmItems;
  public dictionary: DictionaryType;
  public notPassedLessons: string = '';
  public isMultipleLessonNotPassed: boolean = false;
  private readonly teacherId: string;
  private courseId: string;
  private chapters: IChapter[];
  private course: ICourseMaterialExt;
  private passedLessons: IPassedLessons[];
  private lessonResultsCount: ILessonResultsCount;
  private currentClassInfo: IAssigningCourseData;
  private readonly mosRegId: string = MOSRED_ID;

  constructor(
    private router: Router,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilsService: UtilsService,
    private overlayBusyService: OverlayBusyService,
    private ramStorageService: RamStorageService,
    private webStorageService: WebStorageService,
    private teachersJournalService: TeachersJournalService,
    private userStorageService: UserStorageService,
    private rootScopeService: RootScopeService,
    private userActionsService: UserActionsService,
  ) {
    super();
    this.dictionary = rootScopeService.getDictionary();
    this.teacherId = webStorageService.get(StorageKeys.TeacherId);
    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Lessons);
    this.userActionsService.setInitializationTime([YmItems.T_Lessons_AccessToLesson, YmItems.T_Lessons_Open, YmItems.T_Lessons_Download]);
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.rootScopeService.currentCLass
      .pipe(
        filter(v => v !== null),
        switchMap((currentClassInfo: IAssigningCourseData) => {
          this.currentClassInfo = currentClassInfo;
          this.overlayBusyService.show();

          return this.userStorageService.get(UserStorageKeys.ClassCourseChapterInfo);
        }),
        switchMap((chaptersInfo: IChaptersInfo) => {
          if (!Helper.isObject(chaptersInfo, true)) {
            this.overlayBusyService.hide();
            this.router.navigate(['/teacher']);
            return of(null);
          }

          this.schoolClassId = (this.currentClassInfo.schoolClass as ISchoolClass)?.id ?? this.currentClassInfo.schoolClass;
          const currentChapterInfo = chaptersInfo[this.schoolClassId];

          if (!this.schoolClassId && !this.teacherId) {
            this.overlayBusyService.hide();
            this.router.navigate(['/teacher']);
            return of(null);
          }

          if (!currentChapterInfo) {
            this.overlayBusyService.hide();
            this.router.navigate(['/class-courses/' + this.schoolClassId]);
            return of(null);
          }

          const { currentChapter, courseId, nextChapter, previousChapter, chapters } = currentChapterInfo;

          this.courseId = courseId;
          this.currentChapter = currentChapter;
          this.nextChapter = nextChapter;
          this.previousChapter = previousChapter;
          this.chapters = chapters;

          if (!(currentChapter && courseId)) {
            this.overlayBusyService.hide();
            this.router.navigate(['/class-courses/' + this.schoolClassId]);
            return of(null);
          }

          return this.teachersJournalService.getLessons(this.schoolClassId, [this.courseId]);
        }),
        filter(mappedLessons => mappedLessons !== null),
        tap(mappedLessons => {
          this.pupilCount = mappedLessons.length;
        }),
        switchMap(() =>
          combineLatest([
            this.teacherClassCoursesService.getCourseMaterialWithPassedData(
              this.courseId,
              this.currentClassInfo.schoolClass as ISchoolClass,
            ),
            this.teachersJournalService.lessonResultsCount$,
          ]),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        ([course, lessonResultsCount]) => {
          this.passedLessons = course.passedLessons;
          this.course = course;
          this.hasPassedLesson = this.teacherClassCoursesService.hasPassedLesson(this.course);

          this.lessonsInfo = this.course.parsedLessons[this.currentChapter.index][1];
          this.course.parsedLessons?.forEach(chapter => {
            this.activeLessonIds = this.activeLessonIds.concat(
              chapter[1]?.lessons?.filter(el => el.hasLinkedTaskForPupil && !el.passed).map(lesson => lesson.id) ?? [],
            );
          });
          this.lessonResultsCount = lessonResultsCount;
          this.changeDetectorRef.detectChanges();
          this.overlayBusyService.hide();
        },
        error => {
          console.error(error);
          this.overlayBusyService.hide();
          this.utilsService.openSnackBar('Неполадки на сервере', 'error');
          this.timer = window.setTimeout(() => {
            this.utilsService.closeSnackBar();
          }, 5000);
          this.router.navigate(['/teacher']);
        },
      );
  }

  public getLessonsHeader(lessons: ILessons): string {
    const { minNumber, maxNumber } = lessons;

    return minNumber >= maxNumber ? `Занятие ${minNumber}` : `Занятия ${minNumber}-${maxNumber}`;
  }

  public showPopup(lesson: ILessonMaterial): void {
    if (lesson.id !== this.activeLessonIds[0]) {
      let i: number = 0;

      while (lesson.id !== this.activeLessonIds[i]) {
        this.notPassedLessons +=
          this.lessonsInfo.lessons.find(notPassedLesson => notPassedLesson.id === this.activeLessonIds[i]).number + ', ';
        i++;
      }

      this.notPassedLessons = this.notPassedLessons.slice(0, -2);
      this.isMultipleLessonNotPassed = this.notPassedLessons.includes(',');
    }

    this.showOpenLessonPopup = true;
    this.selectedLesson = lesson;
  }

  public complete(): void {
    this.showOpenLessonPopup = false;
    this.notPassedLessons = '';
    this.activeLessonIds.splice(0, this.activeLessonIds.indexOf(this.selectedLesson.id) + 1);

    this.completeLesson(this.selectedLesson, true);
  }

  public cancel(): void {
    this.showOpenLessonPopup = false;
    this.notPassedLessons = '';
  }

  public trackByFn(index: number, item: ILessonMaterial): string {
    return item.id;
  }

  public goToNextChapter(): void {
    const nextChapter = this.chapters.find(section => section.index === this.nextChapter.index + 1);
    const chapterInfo: IChapterInfo = {
      previousChapter: (this.previousChapter = this.currentChapter),
      currentChapter: (this.currentChapter = this.nextChapter),
      nextChapter: (this.nextChapter = nextChapter ?? null),
      schoolClassId: this.schoolClassId,
      courseId: this.courseId,
      chapters: this.chapters,
    };
    this.userStorageService
      .get(UserStorageKeys.ClassCourseChapterInfo)
      .pipe(take(1))
      .subscribe((chaptersInfo: IChaptersInfo) => {
        this.userStorageService.set(UserStorageKeys.ClassCourseChapterInfo, {
          ...chaptersInfo,
          [this.schoolClassId]: chapterInfo,
        });
      });
    this.lessonsInfo = this.course.parsedLessons[this.currentChapter.index][1];
  }

  public goToPreviousChapter(): void {
    const previousChapter = this.chapters.find(section => section.index === this.previousChapter.index - 1);

    const chapterInfo: IChapterInfo = {
      nextChapter: (this.nextChapter = this.currentChapter),
      currentChapter: (this.currentChapter = this.previousChapter),
      previousChapter: (this.previousChapter = previousChapter ?? null),
      schoolClassId: this.schoolClassId,
      courseId: this.courseId,
      chapters: this.chapters,
    };

    this.userStorageService
      .get(UserStorageKeys.ClassCourseChapterInfo)
      .pipe(take(1))
      .subscribe((chaptersInfo: IChaptersInfo) => {
        this.userStorageService.set(UserStorageKeys.ClassCourseChapterInfo, {
          ...chaptersInfo,
          [this.schoolClassId]: chapterInfo,
        });
      });

    this.lessonsInfo = this.course.parsedLessons[this.currentChapter.index][1];
  }

  public isActive(lesson: ILessonMaterial): boolean {
    const prevPassedLesson = this.passedLessons.find(passedLesson => passedLesson.orderNumber === lesson.number - 1);

    return !prevPassedLesson || prevPassedLesson.isPassed;
  }

  public checkIsDisabledChapter(): boolean {
    const regionID: string = this.webStorageService.get(StorageKeys.RegionId);
    const countEnableChapters: number = this.teacherClassCoursesService.countOfActiveChapters;

    if ((regionID === this.mosRegId && this.nextChapter) || this.nextChapter?.index >= countEnableChapters + 1) {
      return false;
    }
    return !this.nextChapter || this.nextChapter?.index >= countEnableChapters;
  }

  public getPassed(lessonId: string): number {
    return this.lessonResultsCount[lessonId] ? this.lessonResultsCount[lessonId][0] : 0;
  }

  private completeLesson(lesson: ILessonMaterial, isPassed: boolean): void {
    const lessonActivities: ILessonActivity[] = [];

    const index = this.lessonsInfo.lessons.findIndex(les => {
      if (les.hasLinkedTaskForPupil && !les.passed) {
        lessonActivities.push({
          userId: this.teacherId,
          courseId: this.courseId,
          lessonId: les.id,
          schoolClassId: this.schoolClassId,
          taskId: EmptyGuid,
          isPassed,
        });
      }

      return les.id === lesson.id;
    });
    this.apiCoursesMaterialsService
      .setMultipleTaskPassedTemp(lessonActivities)
      .pipe(take(1))
      .subscribe(
        () => {
          if (isPassed) {
            const uaParams: IData = {
              LessonNumber: lesson.number,
              SchoolClassId: this.schoolClassId,
            };

            const ymParams: IData = {
              event_label: { Lesson_Number: lesson.number },
            };

            this.userActionsService.log(YmItems.T_Lessons_AccessToLesson, uaParams, ymParams);
            this.hasPassedLesson = true;
            this.setActiveLesson(index);
          }

          for (let i = 0; i <= index; i++) {
            if (this.lessonsInfo.lessons[i].hasLinkedTaskForPupil) {
              this.lessonsInfo.lessons[i].passed = isPassed;
            }
          }

          this.passedLessons.forEach(passedLesson => {
            lessonActivities.forEach(activity => {
              if (passedLesson.id === activity.lessonId) {
                passedLesson.isPassed = isPassed;
              }
            });
          });

          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.utilsService.openSnackBar('Неполадки на сервере, уже чиним', 'error');

          this.timer = window.setTimeout(() => {
            this.utilsService.closeSnackBar();
          }, 5000);
        },
      );
  }

  private setActiveLesson(index: number): void {
    let lessonIndex = index + 1;
    let chapterIndex = this.currentChapter?.index ?? 0;

    if (lessonIndex >= this.lessonsInfo.lessons.length) {
      if (this.nextChapter) {
        lessonIndex = 0;
        chapterIndex = this.nextChapter.index;
      } else {
        lessonIndex -= 1;
      }
    }

    const activeLesson: IActiveLesson = {
      lessonIndex,
      chapterIndex,
      lesson: this.course.parsedLessons[chapterIndex][1].lessons[lessonIndex],
    };

    this.teacherClassCoursesService.setActiveLesson(this.schoolClassId, activeLesson, this.course);
  }

  public sendMaterialsMetric(lesson: ILessonMaterial, type: string): void {
    const uaParams: IData = {
      LessonNumber: lesson.number,
      SchoolClassId: this.schoolClassId,
    };

    const ymParams: IData = {
      event_label: { Lesson: lesson.number },
    };

    switch (type) {
      case 'open':
        this.userActionsService.log(YmItems.T_Lessons_Open, uaParams, ymParams);
        break;

      case 'download':
        this.userActionsService.log(YmItems.T_Lessons_Download, uaParams, ymParams);
        break;

      default:
        break;
    }
  }
}
