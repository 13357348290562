import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { AdminClassService } from 'app/shared/services/admin-class.service';
import { B2gSaasService, IExtendedPupil, ISchoolClass } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { SchooladminDictionary } from '../schooladmin.dictionary';

enum ClassPageViews {
  CLASS_VIEW,
  EDIT_CLASS_VIEW,
  EDIT_PUPILS_VIEW,
}

@Component({
  selector: 'prf-schooladmin-class-template',
  templateUrl: './schooladmin-class-template.component.html',
  styleUrls: ['./schooladmin-class-template.component.scss'],
})
export class SchooladminClassTemplateComponent extends UnsubscribeComponent implements OnInit {
  public schoolClass: ISchoolClass;
  public teacher: ITeacher;
  public pupils: IExtendedPupil[] = [];
  public userProfile: any;
  public isUserFromSalesB2S: boolean = false;
  public showEditPupilsComponent: boolean = false;
  public showEditClassComponent: boolean = false;
  private isChanged: boolean = false;
  public backButtonText: string = SchooladminDictionary.BackToClasses;

  public classViewSelected: any = ClassPageViews.CLASS_VIEW;
  public classPageViews = ClassPageViews;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private router: Router,
    private b2gSaasService: B2gSaasService,
    private adminClassService: AdminClassService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: SchooladminDictionary.SchoolAdminOffice });
  }

  ngOnInit(): void {
    this.b2gSaasService
      .getUserInfo()
      .pipe(
        tap(userProfile => {
          this.userProfile = userProfile;
          this.isUserFromSalesB2S = userProfile.regionId === '2683eee3-306b-49a3-a7b9-7ce28df17251';
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
    this.adminClassService.getPupilPage().subscribe(pupilPage => {
      this.backButtonText = pupilPage.showPupilPage ? SchooladminDictionary.BackToClass : SchooladminDictionary.BackToClasses;
    });
  }

  public showEditPupils(): void {
    this.showEditPupilsComponent = true;
    this.classViewSelected = ClassPageViews.EDIT_PUPILS_VIEW;
  }

  public hideEditPupils(isChanged: boolean): void {
    this.showEditPupilsComponent = false;
    this.isChanged = isChanged;
    this.classViewSelected = ClassPageViews.CLASS_VIEW;
    window.scrollTo(0, 0);
  }

  public showEditClass(): void {
    this.showEditClassComponent = true;
    this.classViewSelected = ClassPageViews.EDIT_CLASS_VIEW;
  }

  public hideEditClass(classUpdated): void {
    this.showEditClassComponent = false;
    this.classViewSelected = ClassPageViews.CLASS_VIEW;
  }

  public setTeacher(event): void {
    this.teacher = event;
  }

  public setSchoolClass(event): void {
    this.schoolClass = event;
  }

  public setPupils(event): void {
    this.pupils = event;
  }

  public routingBack(): void {
    this.adminClassService
      .getPupilPage()
      .pipe(take(1))
      .subscribe(pupilPage => {
        if (pupilPage.showPupilPage) {
          this.adminClassService.closePupilPage();
        } else {
          this.router.navigate(['/schooladmin/classes'], { queryParams: { isChanged: this.isChanged }, queryParamsHandling: 'merge' });
        }
      });
  }

  public showElement(event: any): void {
    event.target.nextSibling.style.display = 'block';
  }

  public hideElement(event: any): void {
    event.target.nextSibling.style.display = 'none';
  }
}
