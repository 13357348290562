import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prf-chart-tabs',
  templateUrl: './chart-tabs.component.html',
  styleUrls: ['./chart-tabs.component.scss'],
})
export class ChartTabsComponent implements OnInit {
  contents: any[];
  @Input() tabs: { name: string; content: TemplateRef<any> }[];
  @Input() noTitle: boolean = false;
  @Input() chartTabsTooltip: string;
  constructor(public translateService: TranslateService) {}

  ngOnInit() {}
}
