import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { RegistrationBase } from '../../../../../shared/auth-classes/registration.base';
import { FilterSchoolsPipe } from '../../../../../shared/pipes/filter-schools.pipe';
import { FilterByNamePipe } from '../../../../../shared/pipes/filter-by-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UtilsService } from '../../../../../shared/dashboard/backend-services/utils.service';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-code-registration-murmansk',
  templateUrl: './code-registration-murmansk.component.html',
  styleUrls: ['./code-registration-murmansk.component.scss'],
})
export class CodeRegistrationMurmanskComponent extends RegistrationBase<B2gSaasService> implements OnInit {
  public registrationPupil: boolean;
  public registrationTeacher: boolean;
  public registrationOther: boolean;
  public tglRegistration: boolean = false;
  public advertisingTerms: boolean = false;
  public registrationOpen: boolean = false;
  public isKz: boolean = true;

  constructor(
    registrationService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    apiLocationsService: ApiLocationsService,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    b2gSaasService: B2gSaasService,
    userDataHandlerService: UserDataHandlerService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
    protected utilsService: UtilsService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      registrationService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
      utilsService,
      'parent',
    );
    this.meta.updateTag({ name: 'og:title', content: 'Регистрация по коду' });
    router.events.pipe(takeUntil(this.unsubscribe)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/mo-registration-pupil': {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
          case '/mo-registration-teacher': {
            return (this.registrationTeacher = true);
          }
          case '/mo-registration-other': {
            return (this.registrationOther = true);
          }
          default: {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.form = this.fb.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
    });
    // Check Tag in URL
    if (!this.tag) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
        this.tag = params.tag;
      });
      // Check if we have this tag type in system
      if (this.tag != PrEventsEnums.Emailing.toString()) {
        this.tag = null;
      }
    }
    this.setLanguage();
  }

  public setLanguage() {
    const language: string = this.webStorageService.get(StorageKeys.SelectedLanguage);
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz' ? true : false;
      this.translateService.use(language);
    }
    return;
  }

  async submit() {
    this.clearPasswordSpaces();
    this.submitted = true;
    this.duplicateUserName = false;
    this.codeIsUsed = false;
    if (this.form.valid) {
      this.passFailed = false;
      this.duplicateUserName = false;

      this.registrationService
        .registration(this.form.value.email, this.form.value.password, this.advertisingTerms)
        .pipe(
          switchMap((response: any) => {
            if (response === 'ALREADY_EXIST') {
              this.duplicateUserName = true; // почта занята
              this.failWaiting();
              return of(null);
            } else if (response === 'CODE_IS_USED') {
              this.codeIsUsed = true; // код использован
              this.failWaiting();
              return;
            } else if (response === 'CANNOT_FIND') {
              this.codeNotFound = true; // код не найден
              this.failWaiting();
              return of(null);
            } else {
              // Сразу попадаем в ЛК
              return this.registrationService.login(response.userName, this.form.value.password, false).pipe(
                switchMap((loginResult: any) => {
                  if (loginResult) {
                    if (loginResult.succeeded === false) {
                      this.loginFailed = true;
                      this.passFailed = true;
                      this.removeWaiting();
                      return of(null);
                    } else if (response === 'CODE_IS_USED') {
                      this.codeIsUsed = true; // код использован
                      this.failWaiting();
                      return of(null);
                    } else {
                      this.userStorageService.setUserId = loginResult.userId;
                      this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                      this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                      this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                      this.webStorageService.set(StorageKeys.IsAuthorized, true);

                      return this.registrationService.getAccess(loginResult.userId).pipe(
                        tap((accessResponse: any) => {
                          this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                          this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);

                                this.setUserInfoInLS();

                              switch (loginResult.role) {
                                case 'admin': {
                                  // Пока нет пользователя с ролью админ, переходим просто /admin
                                  this.router.navigate(['/admin/schools']);
                                  return;
                                }
                                case 'cityadmin': {
                                  this.router.navigate(['/cityadmin']);
                                  return;
                                }
                                case 'adminDO': {
                                  this.router.navigate(['/adminDO/courses/all-courses']);
                                  return;
                                }
                                case 'schooladmin': {
                                  this.router.navigate(['/schooladmin/classes']);
                                  return;
                                }
                                case 'director': {
                                  this.router.navigate(['/director']);
                                  return;
                                }
                                case 'teacher': {
                                  this.router.navigate(['/teacher']);
                                  return;
                                }
                                case 'pupil': {
                                  this.yandexMetricsService.reachGoal(YmItems.PUPIL_CODE_REG);
                                  this.router.navigate(['/pupil']);
                                  return;
                                }
                              }
                        }),
                      );
                    }
                  } else {
                    return of(null);
                  }
                }),
              );
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(_ => this.removeWaiting());
    } else {
      this.failWaiting();
    }
  }

  clearPasswordSpaces() {
    const insertedCode = this.form.get('password').value;
    const codeWithoutSpaces = insertedCode.replace(/[ ]/g, '');
    this.form.get('password').setValue(codeWithoutSpaces);
    this.form.get('password').updateValueAndValidity();
  }

  setUserInfoInLS() {
    const ui: IUserInfo = this.userDataHandlerService.getUserInfo();

    if (!ui) {
      throw new Error('User info is not defined');
    }
    this.webStorageService.set(StorageKeys.ImagePath, ui.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, ui.firstName);
    this.webStorageService.set(StorageKeys.LastName, ui.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, ui.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, ui.gender);
    this.webStorageService.set(StorageKeys.CompanyId, ui.companyId);
    this.webStorageService.set(StorageKeys.Position, ui.position);
    this.webStorageService.set(StorageKeys.RegionId, ui.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, ui.municipalityId);
  }

  public toggleRegistration() {
    this.tglRegistration = !this.tglRegistration;
    this.registrationOpen = !this.registrationOpen;
  }

  get isAccessAllowed() {
    return this.form.value && this.personalTerms;
  }
  get accessFormRole(): boolean {
    if (this.registrationTeacher) {
      return true;
    } else if (this.registrationOther) {
      return true;
    } else {
      return this.registrationPupil && this.tglRegistration;
    }
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
}
