<div class="professions-loader">
  <ng-container *ngFor="let item of [].constructor(3)">
    <div class="card">
      <div class="card__circle"></div>

      <div class="card__text">
        <div class="card__title"></div>
        <ng-container *ngFor="let item of [].constructor(2)">
          <div class="card__description"></div>
        </ng-container>
      </div>
    </div>

  </ng-container>
</div>

