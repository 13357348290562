import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CourseMaterialsTheme, ICourseMaterialsSection, ICourseMaterialsSectionItem } from '../model';

@Component({
  selector: 'prf-course-material-section',
  templateUrl: './course-material-section.component.html',
  styleUrls: ['./course-material-section.component.scss'],
})
export class CourseMaterialSectionComponent {
  @Input({ required: true }) public courseMaterialSectionData: ICourseMaterialsSection;
  @Input() public theme: CourseMaterialsTheme = CourseMaterialsTheme.DEFAULT;

  @Output() public copyLinkEmitter: EventEmitter<string> = new EventEmitter<string>();

  public copyLinkToMaterial(linkToMaterial: string): void {
    this.copyLinkEmitter.emit(linkToMaterial);
  }

  public downloadAllMaterials(courseMaterialSectionData: ICourseMaterialsSection): void {
    courseMaterialSectionData.items.forEach((item: ICourseMaterialsSectionItem): void => {
      setTimeout(() => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = item.location;
        anchor.download = item.name + item.type;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, 200);
    });
  }

  protected readonly CourseMaterialsTheme = CourseMaterialsTheme;
}
