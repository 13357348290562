import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TeacherPanelService {
  public pupilsEdited = new BehaviorSubject({ pupilsEdited: false });
  private showPupil = new BehaviorSubject({ showPupilPage: false });
  private editPupils = new BehaviorSubject({ editPupils: null });

  showAddPupils() {
    this.editPupils.next({ editPupils: true });
  }

  closeAddPupils() {
    this.editPupils.next({ editPupils: false });
  }

  getEditPupils(): Observable<any> {
    return this.editPupils.asObservable();
  }

  showPupilPage() {
    this.showPupil.next({ showPupilPage: true });
  }

  closePupilPage() {
    this.showPupil.next({ showPupilPage: false });
  }

  getPupilPage(): Observable<any> {
    return this.showPupil.asObservable();
  }
}
