import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiProfessionsService,
  ApiUniversitiesService,
  ISpecialityData,
  IUniversity,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-edit-university',
  templateUrl: './edit-university.component.html',
  styleUrls: ['./edit-university.component.scss'],
})
export class EditUniversityComponent implements OnInit {
  university: IUniversity;
  universityId: string;

  specialities: ISpecialityData[];

  public submitted: boolean = false;

  public userRole: string;
  popUpConfirming: boolean = false;

  public form: UntypedFormGroup;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private translateService: TranslateService,
    private apiProfessionsService: ApiProfessionsService,
    private apiUniversitiesService: ApiUniversitiesService,
    private webStorageService: WebStorageService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование ВУЗа' });
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.overlayService.show();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.universityId = params['id'];
    });
    forkJoin([this.apiUniversitiesService.getUniversityById(this.universityId), this.apiProfessionsService.getSpecialities()])
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(([university, specialities]) => {
          this.university = university;
          this.specialities = specialities;
          this.form = this.fb.group({
            id: new UntypedFormControl(this.university.id, []),
            name: new UntypedFormControl(this.university.name, [Validators.required]),
            universitySpecialities: new UntypedFormControl(university.universitySpecialities, []),
          });
        }),
      )
      .subscribe(
        r => this.overlayService.hide(),
        err => {
          this.overlayService.hide();
        },
      );
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      const universityObject = {
        name: this.form.get('name').value,
        universitySpecialities: this.form.get('universitySpecialities').value,
      };

      this.apiUniversitiesService
        .updateUniversity(universityObject)
        .pipe(
          tap(r => {
            this.utilsService.openSnackBar('👌 ВУЗ обновлён, вы будете перенаправлены на страницу данного ВУЗа', 'success');
            return setTimeout(_ => {
              this.router.navigate(['/admin/universities/' + this.form.get('id').value]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  deleteUniversity(id) {
    this.apiUniversitiesService
      .deleteUniversity(id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 ВУЗ удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
