import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { gsap } from 'gsap';


@Component({
  selector: 'prf-confetti',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfettiComponent implements AfterViewInit {

  public gradients: string[] = [
    'url(#gradient1)',
    'url(#gradient2)',
    'url(#gradient3)',
    'url(#gradient4)',
  ];

  public ngAfterViewInit(): void {
    const confetti: Element = document.querySelector('.confetti');
    const container: Element = document.querySelector('.container');

    const confettiTl: gsap.core.Timeline = gsap.timeline();

    confettiTl.play(0);

    let counter: number = 100;
    let rot: number;
    let duration: number;
    let clone;

    while (--counter > -1) {
      const randomIndex: number = Math.floor(Math.random() * this.gradients.length);
      const randomGradient: string = this.gradients[randomIndex];
      const tl: gsap.core.Timeline = gsap.timeline();
      clone = confetti.cloneNode(true);
      container.appendChild(clone);
      rot = gsap.utils.random(0, 360);
      duration = gsap.utils.random(3, 9);
      gsap.set(clone, {
        fill: randomGradient,
        rotation: rot,
        transformOrigin: '50% 50%'
      })

      tl.fromTo(clone, {
        x: gsap.utils.random(0, 800),
        y: gsap.utils.random(-250, -50),
        rotation: rot
      }, {
        duration: duration,
        x: '+=' + gsap.utils.random(-200, 200),
        y: 1000,
        rotation: '+=180',
        ease: 'linear'
      })
        .to(clone.querySelector('.paper'), {
          duration: duration / 23,
          scaleY: 0.1,
          repeat: 23,
          yoyo: true
        }, 0)
      confettiTl.add(tl, counter / 200).timeScale(2);
    }

    gsap.set('.paper', {
      transformOrigin: '50% 50%'
    })
  }
}
