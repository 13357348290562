<div class="recommended-classes" *ngIf="recommendedClasses && recommendedClasses.length">
  <h2 *ngIf="!openResults" class="test-result-h1 rec">
    {{ isKruzhkiVariant ? ('TEST_AND_RESULTS.RECOMMENDED_GROUPS' | translate) : ('TEST_AND_RESULTS.RECOMMENDED_CLASSES' | translate) }}
  </h2>
  <ng-container *ngFor="let recommendClass of recommendedClasses; let i = index">
    <div class="recommended-classes-text" [ngClass]="i < 7 ? colorList[i - 1] : colorList[i - 8]">
      {{ recommendClass.name }}
    </div>
  </ng-container>
</div>
