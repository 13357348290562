<ng-template [ngIf]="sectionInfo">
  <ng-template [ngIf]="!hideHeader">
    <div class="slide-number">{{ slideNum }} {{ 'COMMON.OF' | translate }} {{ slidesNumber }}</div>
    <div class="text-container">
      <h1 class="title">{{ sectionInfo.title }}</h1>
      <p class="descr">{{ sectionInfo.description }}</p>
    </div>
  </ng-template>

  <div class="prf-rectangle-box">
    <prf-rectangle-view
      *ngFor="let answer of sectionInfo.elements; trackBy: trackByFn"
      [answerInfo]="answer"
      [sectionInfo]="sectionInfo"
      (selectedAnswer)="selectAnswer($event)"
      [updateSelections]="updateSelections"
      class="prf-rectangle-box-item"
    ></prf-rectangle-view>
  </div>
</ng-template>
