import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../shared/modules/material.module';
import { FilterByNamePipe } from '../../../shared/pipes/filter-by-name.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { PagesModule } from '../../../pages/pages.module';
import { MurmanskRoutingModule } from './murmansk-routing.module';
import { UpdatePupilMurmanskComponent } from './login-murmansk/update-pupil-murmansk/update-pupil-murmansk.component';
import { TokenauthMurmanskComponent } from './tokenauth-murmansk/tokenauth-murmansk.component';
import { LoginMurmanskComponent } from './login-murmansk/login-murmansk.component';
import { RegistrationMurmanskComponent } from './registration-murmansk/registration-murmansk.component';
import {
  CodeRegistrationMurmanskComponent,
} from './registration-murmansk/code-registration/code-registration-murmansk.component';
import {
  OpenRegistrationMurmanskComponent,
} from './registration-murmansk/open-registration/open-registration-murmansk.component';
import {
  RegistrationPupilMurmanskComponent,
} from './registration-murmansk/registration-pupil/registration-pupil-murmansk.component';

@NgModule({
  imports: [MaterialModule, ReactiveFormsModule, PagesModule, SharedModule, MurmanskRoutingModule],
  declarations: [
    LoginMurmanskComponent,
    RegistrationMurmanskComponent,
    CodeRegistrationMurmanskComponent,
    OpenRegistrationMurmanskComponent,
    RegistrationPupilMurmanskComponent,
    TokenauthMurmanskComponent,
    UpdatePupilMurmanskComponent,
  ],
  providers: [FilterByNamePipe],
})
export class MurmanskModule {}
