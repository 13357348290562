<ng-container *ngIf="userRole === 'parent' && !isVGuser">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section">
    <div class="container container-promo-results">
      <prf-page-header [title]="' '"></prf-page-header>
      <div *ngIf="dataLoaded && !isMobile" class="header_buttons">
        <a class="header_button _button" (click)="routingAllCourses()">Партнеры</a>
        <a class="header_button _button" routerLink="/parent/mosrupromo">Пройти тест</a>
        <a class="header_button _button" routerLink="/mosruabout">Об акции</a>
      </div>

      <h1 class="results-title-h1">Узнайте свои таланты</h1>
      <div class="promotest-results">
        <ng-container *ngTemplateOutlet="promotestResults"> </ng-container>
      </div>
      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isVGuser">
  <prf-menu [root]="userRole"></prf-menu>
  <div class="content-section VGpage">
    <div class="container container-promo-results">
      <ng-container *ngIf="dataLoaded && !isMobile">
        <div class="header_buttons">
          <a class="header_button _button" routerLink="/vorobievi-gori/partners-courses-all">Курсы</a>
          <a class="header_button _button" routerLink="/vorobievi-gori/mosrupromo">Пройти тест</a>
        </div>
        <h1 class="results-title-h1">Узнайте свои таланты</h1>
      </ng-container>

      <ng-container *ngIf="dataLoaded && isMobile">
        <div class="mobile-menu-button" (click)="showMobileMenu()">
          <div class="mobile-menu-icon-div">
            <div class="menu-line"></div>
            <div class="menu-line"></div>
          </div>
        </div>
        <h1 class="results-title-h1">Узнайте свои таланты</h1>
        <div class="clear_float"></div>
      </ng-container>

      <div class="promotest-results">
        <ng-container *ngTemplateOutlet="promotestResults"> </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #promotestResults>
  <div *ngIf="dataLoaded" class="result-container">
    <div class="container-1050">
      <div class="tabs-menu-div parent-tabs">
        <div class="tabs-menu">
          <a
            *ngFor="let child of childrenTestedShow"
            [class.w--current]="child == selectedChild"
            (click)="onSelectChild(child)"
            class="tab-link-par parent-tabs w-inline-block"
          >
            <div class="tab-link-parent">
              <img
                [attr.src]="child.imagePath ? child.imagePath : './profilum-assets/images/icons/no-photo.svg'"
                alt=""
                class="tab-photo-child"
              />
              <div class="text-block">{{ child.firstName }}</div>
            </div>

            <img
              [class.active]="child === selectedChild"
              src="./profilum-assets/images/icons/angle.png"
              width="20"
              alt=""
              class="angle-parent-tabs"
            />
            <img
              [class.active]="child === selectedChild && !first"
              src="./profilum-assets/images/icons/angle2.png"
              width="20"
              alt=""
              class="angle-parent-tabs _2"
            />
          </a>

          <a
            *ngIf="childrenMore.length === 0"
            (click)="popUpConfirming = true"
            (mouseenter)="buttonOpacity = 1"
            (mouseleave)="buttonOpacity = 0"
            class="tab-link add-tab parent-tabs w-inline-block w-tab-link"
          >
            <div class="profession-icon parent-tab-icon-div">
              <img src="./assets/images/icons/add-icon.svg" class="parent-tab-icon" />
              <img src="./assets/images/icons/add-prof.svg" class="icon-hover parent-tab-icon" [style.opacity]="buttonOpacity" />
            </div>
          </a>
        </div>

        <!-- дропдаун, если есть childrenMore -->
        <div *ngIf="childrenMore.length > 0" #tabDropdown class="tabs-dropdown w-dropdown">
          <div (click)="isTabDropdown = !isTabDropdown" class="tabs-dropdown-toggle w-dropdown-toggle">
            <img src="./assets/images/icons/Tabs-Dropdown-Icon.svg" alt="" class="tabs-dropdown-icon" />
          </div>

          <nav [class.w--open]="isTabDropdown" class="tabs-dropdown-list w-dropdown-list">
            <div class="tabs-dd-bg">
              <div class="tabs-dd-row">
                <div *ngFor="let child of childrenMore" class="tabs-dd-column parent-tabs">
                  <a
                    (click)="onSelectChild(child)"
                    [class.w--current]="child === selectedChild"
                    class="tabs-dd-link parent-tabs w-inline-block"
                  >
                    <div class="tab-link-parent">
                      <img
                        [attr.src]="child.imagePath ? child.imagePath : './profilum-assets/images/icons/no-photo.svg'"
                        alt=""
                        class="tab-photo-child"
                      />
                      <div class="text-block">{{ child.firstName }}</div>
                    </div>
                  </a>
                </div>

                <div class="tabs-dd-column parent-tabs">
                  <a
                    (click)="popUpConfirming = true"
                    (mouseenter)="buttonOpacity = 1"
                    (mouseleave)="buttonOpacity = 0"
                    class="tabs-dd-link add-tab parent-tabs w-inline-block"
                  >
                    <div class="profession-icon parent-tab-icon-div">
                      <img src="./assets/images/icons/add-icon.svg" class="parent-tab-icon" />
                      <img src="./assets/images/icons/add-prof.svg" class="icon-hover parent-tab-icon" [style.opacity]="buttonOpacity" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <ng-container *ngIf="results && results.sessionIncompleted; else testCompleted">
        <ng-container *ngTemplateOutlet="incompletedTest"></ng-container>
      </ng-container>

      <ng-template #testCompleted>
        <ng-container *ngIf="results && results.nullResults; else resultsBlock">
          <ng-container *ngTemplateOutlet="emptyResults"></ng-container>
        </ng-container>

        <ng-template #resultsBlock>
          <div class="tabs-content w-tab-content">
            <h3 class="promo-results-title">Таланты ребенка</h3>
            <div class="promo-results-text">
              Ваш ребенок - обладатель уникальных талантов. Узнайте, как их развивать с лучшими образовательными компаниями Москвы
            </div>
            <section class="promo-results-talents">
              <div class="talents-container">
                <div class="talent-item" *ngFor="let talent of talentsData">
                  <div class="talent-pic">
                    <img src="{{ talent.imageUrl }}" />
                  </div>
                  <div class="talent-name">
                    {{ talent.name }}
                  </div>
                  <div class="talent-text">
                    {{ talent.description }}
                  </div>
                </div>
              </div>
              <div class="line-separator _1"></div>
              <ng-container *ngIf="!isMobileConsult; else mobileConsultCard">
                <!-- блок с информацией о консультациях или подписке -->
                <div class="consults-container">
                  <div class="consults-photo _1">
                    <img src="/profilum-assets/images/consults/promotest-subscribe-0.svg" />
                  </div>
                  <div class="consults-photo _2">
                    <img src="{{ consultsPromoData.consultantPhotoUrl }}" alt="Subscribe picture promo" />
                  </div>
                  <div class="consults-textblock">
                    <div class="consults-title">
                      <span>Хотите выбрать ребенку профиль обучения и будущую профессию?</span>
                    </div>
                    <a class="consults-button" href="{{ consultsPromoData.consultsUrl }}" target="_blank">Узнать больше</a>
                  </div>
                </div>
                <!-- <div class="consults-container">
              <div class="consults-photo">
                <img
                  src="{{ consultsPromoData.consultantPhotoUrl }}"
                  alt="Subscribe picture promo"
                />
              </div>
              <div class="consults-textblock">
                <div class="consults-name">
                  {{ consultsPromoData.consultantName }}
                </div>
                <div class="consults-text">
                  • Индивидуальное занятие по профориентации<br />
                  • Подбор профессии и вузов<br />
                  • Персональный отчет "Карта талантов"
                </div>
              </div>
              <div class="consults-data-block">
                <div class="consults-data">
                  <div class="consults-price-container">
                    <div class="consults-price">
                      <div class="price-value">
                        {{ consultsPromoData.consultPrice - consultsPromoData.consultDiscount }}
                      </div>
                      <div class="price-currency">{{ consultsPromoData.consultCurrency }}</div>
                    </div>

                    <div class="consults-price-full" *ngIf="consultsPromoData.consultDiscount">
                      <div class="price-value" id="id_price_undiscounted">
                        {{ consultsPromoData.consultPrice }}
                        <div
                          class="_crossing-line"
                          [style.transform]="'rotate(-' + crossingLineAngle + 'deg)'"
                          [style.width]="crossingLineLength + 'px'"
                        ></div>
                      </div>
                      <div class="price-currency">{{ consultsPromoData.consultCurrency }}</div>
                    </div>
                  </div>
                </div>
                <div class="consults-line _2"></div>
                <a
                  class="consults-button"
                  href="{{ consultsPromoData.consultsUrl }}"
                  target="_blank"
                  >Получить презентацию</a
                >
              </div>
              <div class="consults-discount">-{{ consultsPromoData.consultDiscount }} &#8381;</div>
            </div> -->
              </ng-container>

              <ng-template #mobileConsultCard>
                <div class="consults-container _mobile">
                  <div class="consults-text">Хотите выбрать ребенку профиль обучения и будущую профессию?</div>
                  <a class="consults-button" href="{{ consultsPromoData.consultsUrl }}" target="_blank">Узнать больше</a>
                </div>
                <!-- <div class="consults-container _mobile">
              <div class="consults-discount">-{{ consultsPromoData.consultDiscount }} &#8381;</div>
              <div class="consults-title"></div>
              <div class="consults-photo">
                <img src="{{ consultsPromoData.consultantPhotoUrl }}" alt="Consulter's photo" />
              </div>
              <div class="consults-name">
                {{ consultsPromoData.consultantName }}
              </div>
              <div class="consults-text">
                • Индивидуальное занятие по профориентации<br />
                • Подбор профессии и вузов<br />
                • Персональный отчет "Карта талантов"
              </div>
              <div class="consults-line"></div>
              <div class="consults-data">
                <div class="consults-price-container">
                  <div class="consults-price-full" *ngIf="consultsPromoData.consultDiscount">
                    <div class="price-value" id="id_price_undiscounted">
                      {{ consultsPromoData.consultPrice }}
                      <div
                        class="_crossing-line"
                        [style.transform]="'rotate(-' + crossingLineAngle + 'deg)'"
                        [style.width]="crossingLineLength + 'px'"
                      ></div>
                    </div>
                    <div class="price-currency">{{ consultsPromoData.consultCurrency }}</div>
                  </div>

                  <div class="consults-price">
                    <div class="price-value">
                      {{ consultsPromoData.consultPrice - consultsPromoData.consultDiscount }}
                    </div>
                    <div class="price-currency">{{ consultsPromoData.consultCurrency }}</div>
                  </div>
                </div>
              </div>
              <a class="consults-button" href="{{ consultsPromoData.consultsUrl }}" target="_blank"
                >Получить презентацию</a
              >
            </div> -->
              </ng-template>
            </section>
            <div class="line-separator _2"></div>
            <section class="promo-results-courses-recommendations">
              <div class="recommendations-heading">
                <h3 class="promo-results-title">Рекомендованные курсы</h3>

                <a *ngIf="!isVGuser" class="courses-recommendations-link" (click)="routingAllCourses()"> Все участники </a>
                <a *ngIf="isVGuser" class="courses-recommendations-link" routerLink="/vorobievi-gori/partners-courses-all">
                  Все участники
                </a>
              </div>
              <div *ngIf="!isVGuser" class="promo-results-text">
                Мы подобрали лучшие курсы для вашего ребенка по специальным ценам. Для активации скидки переходите на сайт партнера и
                укажите промокод при оплате или сообщите менеджеру по телефону
              </div>
              <div *ngIf="isVGuser" class="promo-results-text">
                Мы подобрали подходящие курсы специально для вашего ребенка. Для записи на курс переходите по ссылке и регистрируйтесь на
                портале mos.ru
              </div>
              <div class="courses-container">
                <prf-promotest-course-card *ngFor="let course of recommendedCoursesTest" [course]="course"></prf-promotest-course-card>
              </div>
            </section>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>
<ng-template #incompletedTest>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Ваш ребенок пока не прошел тест</div>
  </div>
</ng-template>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Добавление нового ребенка</h4>
          <div class="task-result-text">Вы хотите пройти тест за другого ребенка?</div>
          <div class="buttons-row">
            <a class="button attach-file-btn w-button" (click)="navigateNewTest()">{{ 'BUTTONS.YES' | translate }}</a>
            <a class="button attach-file-btn w-button" (click)="popUpConfirming = !popUpConfirming">{{ 'BUTTONS.CANCEL' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="popupReminderShow">
  <div class="perform-task-lb lb show" #PopupOverlay>
    <div class="lb-bg"></div>
    <div class="lb-block task-lb _popupReminder" #PopupReminder>
      <a class="close-lb w-inline-block" (click)="popupReminderShow = !popupReminderShow; isReminded = true">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="consults-title">{{ reminderTitle }}</div>
        <div class="consults-photo">
          <img src="./profilum-assets/images/consults/reminder-image.svg" alt="Consulter's photo" />
        </div>
        <div class="consults-text _heading_text">Узнайте больше о своем ребенке с сервисом «Профилум»!</div>
        <ul class="consults-text _list_text">
          <li>
            <span
              >Тесты на определение<br />
              способностей и мотивации
            </span>
          </li>
          <li>
            <span>Индивидуальные занятия <br />по профориентации</span>
          </li>
        </ul>
        <a class="consults-button" href="{{ popupReminderLink }}">Узнать больше</a>
      </div>
    </div>
  </div>
</ng-container>
