<ng-template [ngIf]="showStartPage">
  <div class="testing-progress-bar-block">
    <div class="testing-progress-bar" style="width: 0"></div>
  </div>
  <div class="header-testing">
    <img class="bvb-logo" src="./profilum-assets/images/logos/logo-profilum.svg" alt="Логотип Профилум" />
  </div>
  <div class="content-section testing-content content-section_bvb-test">
    <div class="container container_bvb-test">
      <main [ngClass]="{ 'anketa-steps': true }">
        <prf-about-test
          [form]="forms[steps.AboutTest - 1]"
          (languageSelected)="changeLanguage($event)"
          *ngIf="currentAnketaStep === steps.AboutTest"
        ></prf-about-test>
      </main>

      <div class="button-wrapper_bvb">
        <button class="button button_bvb" (click)="startTest()">Пройти диагностику</button>
      </div>
    </div>

    <div class="footer-section">
      <div class="footer-row">
        <div class="footer-text">© Профилум 2022</div>
      </div>
    </div>
  </div>
</ng-template>
