import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FiltersPromotestCoursesService } from '../filters-promotest-courses.service';
import { IFilterClasses } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

export enum AgeCategories {
  SEVENPLUS = '7-8',
  NINEPLUS = '9-10',
  ELEVENPLUS = '11-12',
  THIRTEENPLUS = '13-14',
  FIFTEENPLUS = '15-17',
}

@Component({
  selector: 'prf-filters-age',
  templateUrl: './filters-age.component.html',
  styleUrls: ['./filters-age.component.scss'],
})
export class FiltersAgeComponent extends UnsubscribeComponent implements OnInit {
  filters: IFilterClasses;
  public ageCategories = [];
  selectedAge: any = null;

  classes: any = [];
  ageOpen: boolean = false;
  @ViewChild('ageFilter') ageFilter: ElementRef;

  constructor(private filtersService: FiltersPromotestCoursesService) {
    super();
    this.ageCategories = Object.keys(AgeCategories).map(category => {
      return AgeCategories[category];
    });
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.filters = data;
        this.selectedAge = data.minAgeAudience ? this.setSelectedAge(data.minAgeAudience) : null;
      });
  }

  setAgeFilter(ageCategory: any) {
    this.selectedAge = this.selectedAge === ageCategory ? null : ageCategory;

    switch (this.selectedAge) {
      case AgeCategories.SEVENPLUS:
        this.filters.minAgeAudience = 7;
        this.filters.maxAgeAudience = 8;
        break;
      case AgeCategories.NINEPLUS:
        this.filters.minAgeAudience = 9;
        this.filters.maxAgeAudience = 10;
        break;
      case AgeCategories.ELEVENPLUS:
        this.filters.minAgeAudience = 11;
        this.filters.maxAgeAudience = 12;
        break;
      case AgeCategories.THIRTEENPLUS:
        this.filters.minAgeAudience = 13;
        this.filters.maxAgeAudience = 14;
        break;
      case AgeCategories.FIFTEENPLUS:
        this.filters.minAgeAudience = 15;
        this.filters.maxAgeAudience = 17;
        break;
      default:
        break;
    }

    this.filtersService.setCoursesFilter(this.filters);
    this.ageOpen = false;
  }

  deselectAge() {
    this.selectedAge = null;
    this.filters.minAgeAudience = null;
    this.filters.maxAgeAudience = null;
    this.filtersService.setCoursesFilter(this.filters);
    this.ageOpen = false;
  }

  checkFilterHeader() {
    if (this.selectedAge) {
      const age = this.ageCategories.find(el => el === this.selectedAge);
      return age;
    } else {
      return 'Возраст';
    }
  }

  setSelectedAge(minAudience: number): string {
    switch (minAudience) {
      case 7:
        return AgeCategories.SEVENPLUS;
      case 9:
        return AgeCategories.NINEPLUS;
      case 11:
        return AgeCategories.ELEVENPLUS;
      case 13:
        return AgeCategories.THIRTEENPLUS;
      case 15:
        return AgeCategories.FIFTEENPLUS;
      default:
        return null;
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.ageFilter && !this.ageFilter?.nativeElement.contains(target)) {
      this.ageOpen = false;
    }
  }
}
