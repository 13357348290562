import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { StorageKeys, WebStorageService } from '@profilum-library';

import { ITab } from '../choose-profession.interface';

@Component({
  selector: 'prf-prof-navigation',
  templateUrl: './prof-navigation.component.html',
  styleUrls: ['./prof-navigation.component.scss'],
})
export class ProfNavigationComponent {
  @Input() public amount: number = 1;
  @Input() public counter: number = 0;
  @Input() public title: string = 'Выбор профессии';
  public _tabs: Map<string, ITab> | [];
  public hasOneTab: boolean = false;
  public hasNoTabs: boolean = false;
  public tinderType: boolean = false;

  @Input() public set tabs(tabs: Map<string, ITab>) {
    this._tabs = tabs ?? [];
    this.hasOneTab = tabs.size === 1;
    this.tinderType = !!this.webStorageService.get(StorageKeys.TinderType);
    this.hasNoTabs = !tabs.size;
  }

  @Output() public selectNewTab: EventEmitter<string> = new EventEmitter<string>();

  constructor(private webStorageService: WebStorageService, private router: Router) {}

  public get profCount(): number {
    return this.amount - this.counter;
  }
  // функция для сортировки по-умолчанию (в порядке добавления)
  public originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return 0;
  }

  public onSelectTab(event: string): void {
    this.selectNewTab.emit(event);
  }

  public closeTinder(): void {
    this.webStorageService.set(StorageKeys.UpdateFavouriteProfessions, true);
    this.router.navigate(['/pupil']).then();
  }
}
