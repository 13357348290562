<div class="tabs-menu-div parent-tabs">
  <div class="tabs-menu">
    <a
      *ngFor="let child of children; let first = first; let i = index"
      [class.w--current]="child === selectedChild"
      (click)="onSelectChild(child)"
      class="tab-link-par parent-tabs w-inline-block w--current"
    >
      <div class="tab-link-parent">
        <div class="text-block" [tp]="isMobile ? null : tooltip" [tpData]="child.firstName">
          {{ child.firstName }}
        </div>
        <div class="tabs-child-school">
          <ng-container *ngIf="child.schoolClassNumber">{{ child.schoolClassNumber }}</ng-container>
          <ng-container *ngIf="child.schoolClassLetter"
            >«{{ child.schoolClassLetter }}»<span class="tabs-child-school__name">, </span></ng-container
          >
          <span *ngIf="child.schoolName" class="tabs-child-school__name">{{ child.schoolName }}</span>
        </div>
      </div>

      <img
        [class.active]="child === selectedChild"
        src="./profilum-assets/images/icons/angle.png"
        width="20"
        alt=""
        class="angle-parent-tabs"
      />
      <img
        [class.active]="child === selectedChild && !first"
        src="./profilum-assets/images/icons/angle2.png"
        width="20"
        alt=""
        class="angle-parent-tabs _2"
      />
    </a>

    <a
      *ngIf="children.length < childrenMax && !userRestriction"
      (click)="clickAddChild()"
      (mouseenter)="buttonOpacity = 1"
      (mouseleave)="buttonOpacity = 0"
      class="tab-link add-tab parent-tabs w-inline-block w-tab-link"
    >
      <img src="./profilum-assets/images/icons/add-icon.svg" class="parent-tab-icon" />
      <span class="parent-tab-name">Добавить подростка</span>
    </a>
  </div>
</div>

<ng-template #tooltip let-childName="data">
  <prf-tooltip>{{ childName }}</prf-tooltip>
</ng-template>
