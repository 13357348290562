export enum EAnketaStep {
  AboutTest = 1,
}

export enum ETestType {
  Default = 1,
  Test360,
  Specialist,
  MotivationTest,
  ValuesTest,
  MincifrydemoTest,
  BvbTest,
  VkTest,
  CampTest,
  MincifryTest,
  BvbTestVisuallyImpaired,
  WebTest,
  InterestsTest,
  CapabilitiesTest,
  CareerLiteracyTest,
  DiagnosticCareerType,
}

export enum EUserStatus {
  Specialist = 1,
  Student,
  Schoolar,
}
