import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Internship } from 'app/shared/classes/internship.class';
import { take } from 'rxjs/operators';
import { ApiInternshipsService, StorageKeys, WebStorageService } from '@profilum-library';

export enum INTERNSHIP_TYPE_FILTER {
  active = 0,
  unpublished = 1,
  past = 2,
}

@Component({
  selector: 'prf-employer-internships',
  templateUrl: './employer-internships.component.html',
  styleUrls: ['./employer-internships.component.scss'],
})
export class EmployerInternshipsComponent implements OnInit {
  public internships: Internship[];
  public filteredInternships: Internship[];
  private companyId: string;
  public internshipTypeFilter = INTERNSHIP_TYPE_FILTER;
  public selectedInternshipTypeFilter: any;
  public isAllLoaded: boolean = false;

  constructor(private meta: Meta, private apiInternshipsService: ApiInternshipsService, private webStorageService: WebStorageService) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница стажировок' });
  }

  ngOnInit() {
    this.companyId = this.webStorageService.get(StorageKeys.CompanyId);
    this.apiInternshipsService
      .getInternshipsByCompany(this.companyId)
      .pipe(take(1))
      .subscribe(result => {
        this.internships = result.internships;
        this.showActiveInternships();
        this.isAllLoaded = true;
      });
  }

  //опубликованы и еще не прошли
  public showActiveInternships() {
    this.filteredInternships = this.internships.filter(x => x.isActive == true && new Date(x.closeDate).getTime() >= new Date().getTime());
    this.selectedInternshipTypeFilter = this.internshipTypeFilter.active;
  }

  //неопубликованы
  public showUnpublishedInternships() {
    this.filteredInternships = this.internships.filter(x => x.isActive == false);
    this.selectedInternshipTypeFilter = this.internshipTypeFilter.unpublished;
  }

  //прошли
  public showPastInternships() {
    this.filteredInternships = this.internships.filter(x => new Date(x.closeDate).getTime() < new Date().getTime());
    this.selectedInternshipTypeFilter = this.internshipTypeFilter.past;
  }
}
