import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateValidator } from 'app/shared/instruments/validators/dateValidator';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { AuthHandlerService, B2gSaasService, Helper, IAddUserRequest } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { take } from 'rxjs/operators';
import { TEXT_MASK } from 'app/shared/global-constants/constants';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

export interface IClassInfo {
  classNumber: number;
  classLetter: string;
  classId: string;
}

@Component({
  selector: 'prf-add-child-popup',
  templateUrl: './add-child-popup.component.html',
  styleUrls: ['./add-child-popup.component.scss'],
})
export class AddChildPopupComponent extends UnsubscribeComponent implements OnInit {
  @Input() schoolId: string;
  @Input() classInfo: IClassInfo;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() successAdding = new EventEmitter<boolean>();

  public addChildForm: UntypedFormGroup;
  public submitted: boolean = false;
  public focusOutFromPhoneField: boolean = false;
  public phoneChecked: boolean = false;
  public readonly dateMask: Array<RegExp | string> = TEXT_MASK.dateMaskWithDots;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public phoneMask: Array<string | RegExp> = TEXT_MASK.newPhoneMask;

  constructor(private fb: UntypedFormBuilder, private b2gSaasService: B2gSaasService, private authHandlerService: AuthHandlerService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  get formControls() {
    return this.addChildForm.controls;
  }

  get isAccessAllowed(): boolean {
    return this.addChildForm.touched && this.addChildForm.valid;
  }

  public submitPupilAddForm(): void {
    if (!this.addChildForm.valid) {
      return;
    }
    const childCredentials: IAddUserRequest = {
      firstName: this.formControls.firstName.value,
      lastName: this.formControls.lastName.value,
      schoolId: this.schoolId,
      role: 'pupil',
      schoolClassIds: this.classInfo.classId ? [this.classInfo.classId] : [],
      schoolClassNumber: this.classInfo.classNumber,
      schoolClassLetter: this.classInfo.classLetter,
      birthday: DateHelper.toDayJs(this.formControls.date.value, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      gender: this.formControls.gender.value,
      phoneNumber: this.formControls.phoneNumber.value.replace(/\D/g, ''),
      isAcceptPersonalInformation: true,
    };

    this.b2gSaasService
      .addUser(childCredentials)
      .pipe(this.unsubscribeOperator)
      .subscribe(() => {
        this.successAdding.emit(true);
      });
  }

  private initForm(): void {
    this.addChildForm = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      date: new UntypedFormControl(null, [Validators.required, DateValidator('DD.MM.YYYY')]),
      gender: new UntypedFormControl(null, [Validators.required]),
    });
  }

  public closePopup(): void {
    this.closeModal.emit(true);
  }

  public checkPhone(): void {
    this.phoneChecked = true;
    const phone: string = this.formControls.phoneNumber.value;

    if (!Helper.isValidPhoneNumber(phone)) {
      this.formControls.phoneNumber.setErrors({ pattern: true });
      return;
    }

    if (phone === ' ') {
      this.formControls.phoneNumber.reset();
    }

    this.authHandlerService
      .checkPhoneAvailability(phone.replace(/\D/g, ''))
      .pipe(take(1), this.unsubscribeOperator)
      .subscribe(response => {
        if (!response.free) {
          this.formControls.phoneNumber.setErrors({ isUsed: true });
        }
      });
  }

  public onFocusOutDataField(): void {
    if (DateHelper.isInappropriateAge(this.formControls.date.value)) {
      this.formControls.date.setErrors({ invalidDate: true });
    }
  }
}
