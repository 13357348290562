import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '@profilum-components/button/button.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NotificationNewComponent, NotificationType } from 'app/ui-kit/components/notification';
import { ModalHandlerService } from 'app/ui-kit/services/modal-handler/modal-handler.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'prf-teacher-invite',
  standalone: true,
  imports: [CommonModule, NotificationNewComponent, ButtonComponent, ReactiveFormsModule],
  templateUrl: './teacher-invite.component.html',
  styleUrls: ['./teacher-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherInviteComponent {
  @Input() modalContent;
  @Input() registrationCode: string;
  @Input() inviteText: string;
  @Output() inviteCopied: EventEmitter<boolean> = new EventEmitter<boolean>();
  public notificationType: NotificationType = NotificationType.Warning;
  public form: FormGroup;

  constructor(
    private modalHandlerService: ModalHandlerService,
    private formBuilder: FormBuilder,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      inviteText: new FormControl(this.inviteText),
    });
  }

  public copyText(): void {
    const copyText = this.form.controls.inviteText.value;
    this.clipboardService.copy(copyText);
    this.inviteCopied.emit(true);
    this.modalHandlerService.toggleSideModal(false);
  }
}
