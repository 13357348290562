<a class="event-card adm" [routerLink]="['/admin/specialties/', specialty.id]">
  <a class="event-gradient-bg full-height green w-inline-block">
    <div class="event-header full-height">
      <h3 class="event-heading">{{ specialty.name }}</h3>
      <div class="event-info-row-2"></div>
    </div>
    <div class="event-info-div adm"></div>
  </a>
  <div class="event-status-block">
    <div class="event-status-text">{{ 'SHARED.ADDED' | translate }} {{ specialty.createdDate | date : 'dd MMM yyyy hh:mm' }}</div>
    <a [routerLink]="['/admin/edit-specialty', specialty.id]" class="event-status-edit-btn w-inline-block edit-event"></a>
  </div>
</a>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление специальности</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить специальность?</div>
          <a class="button attach-file-btn w-button" (click)="deleteSpecialty(specialty.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
