import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService, IProfession, ProfessionsService } from '@profilum-library';

@Component({
  selector: 'prf-profession-card',
  templateUrl: './profession-card.component.html',
  styleUrls: ['./profession-card.component.scss'],
})
export class ProfessionCardComponent implements OnInit {
  @Input() data: IProfession;
  @Input() langObject: any;

  public salary: string = '';
  public num = 1;

  constructor(private location: Location, private professionsService: ProfessionsService, private appSettingsService: AppSettingsService) {}

  ngOnInit() {
    this.num = this.professionsService.getColorSchemeNumber(this.data.fieldNames[0]);
    this.data.salaryString =
      this.langObject.COMMON.SALARY +
      ' ' +
      this.data.minSalary +
      '-' +
      this.data.maxSalary +
      ' ' +
      this.appSettingsService.getByDefaultLocale('COMMON.MONEY_SYMBOL');
    this.data.shortDescription = this.data.shortDescription.replace('<p>', '').replace('</p>', '');
    if (this.data.mainImagePath) {
      this.data.mainImagePath = this.data.mainImagePath.replace(
        '/content/images/professions/default_icons/',
        './profilum-assets/images/profession/',
      );
    }
  }

  public openProfession() {
    if (window) {
      const url = location.origin + this.location.prepareExternalUrl('/professions/') + this.data.hrid;
      window.open(url);
    }
  }
}
