<div class="modals-wrapper">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="center-modal">
    <button class="close-button" (click)="closeModal()">
      <svg class="close-icon" xmlns="http://www.w3.org/2000/svg">
        <use [attr.href]="'./profilum-components/icons/all-icons.svg#close'"></use>
      </svg>
    </button>
    <span class="icon-done" [ngClass]="{ 'icon-done_blue': modalConfig?.iconColor === 'blue' }"></span>
    <div class="title">{{ modalConfig?.title }}</div>
    <div class="content" [innerHTML]="modalConfig?.content"></div>
    <prf-button
      *ngIf="modalConfig?.buttonTitle"
      [buttonTitle]="modalConfig?.buttonTitle"
      [type]="modalConfig?.buttonType || buttonType.Secondary"
      (emitClick)="confirm(modalConfig?.action)"
    >
    </prf-button>
    <prf-button
      *ngIf="modalConfig?.secondaryButtonTitle"
      class="secondary-button"
      [buttonTitle]="modalConfig?.secondaryButtonTitle"
      [type]="buttonType.Ghost"
      (emitClick)="additionalAction(modalConfig?.additionalAction)"
    >
    </prf-button>
  </div>
</div>
