<div class="pupil-menu-bottom">
  <div class="overlay" [class.overlay_on]="onbording"></div>
  <div class="pupil-menu-bottom-lessons" [class.pupil-menu-bottom-lessons_active]="root === 'pupil'">
    <div class="pupil-menu-bottom-lessons-icon" (click)="selectLessons()">
      <div class="pupil-icon" [ngClass]="{ 'pupil-icon-active': root === 'pupil' }"></div>
    </div>
    <div class="pupil-text" [ngClass]="{ 'pupil-text-active': root === 'pupil' }">Задания</div>
  </div>
  <div class="pupil-menu-bottom-profile" [class.pupil-menu-bottom-profile_active]="root === 'profile'" (click)="selectMain()">
    <div class="pupil-menu-bottom-profile-icon">
      <img
        *ngIf="imagePath; else initialsTemplate"
        class="pupil-menu-bottom-profile-icon-logo"
        [ngClass]="{ 'logo-active': root === 'profile' }"
        [src]="imagePath"
      />
      <ng-template #initialsTemplate>
        <div class="pupil-menu-bottom-profile-icon-initials" [ngClass]="{ 'logo-active': root === 'profile' }">
          {{ initials }}
        </div>
      </ng-template>
      <div class="pupil-text" [ngClass]="{ 'pupil-text-active': root === 'profile' }">Профиль</div>
    </div>
  </div>
  <div
    class="pupil-menu-bottom-professions"
    [class.pupil-menu-bottom-professions_active]="root === 'profession'"
    (click)="selectProfessions()"
  >
    <div class="pupil-menu-bottom-professions-icon">
      <div class="profession-icon" [ngClass]="{ 'profession-icon-active': root === 'profession' }"></div>
    </div>
    <div class="pupil-text" [ngClass]="{ 'pupil-text-active': root === 'profession' }">Профессии</div>
  </div>
</div>
