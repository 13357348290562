import { Component, Input, OnInit } from '@angular/core';
import { ResultsStatus } from 'app/shared/common-components/results-data-presentation/result-status.type';
import { Helper } from '@profilum-library';
import { RootScopeService } from '../../../services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';

@Component({
  selector: 'prf-results-answers-bars',
  templateUrl: './results-answers-bars.component.html',
  styleUrls: ['./results-answers-bars.component.scss'],
})
export class ResultsAnswersBarsComponent implements OnInit {
  @Input() public data: IAnswerPercents[];
  @Input() public color: string;
  @Input() public isSingleColor: boolean;
  @Input() public showByPupil: boolean = false;
  @Input() public set status(status: ResultsStatus) {
    if (status) {
      this.isNoResult = status === 'not-passed' || status === 'passed-not-started';
    }
  }
  public isNoResult: boolean;
  public color_darkGray: string = '#BFBFBF';

  public barColor: string[] = ['#2be8df', '#9e83fc', '#4ab5ff', '#fe7531', '#f856e2', '#ffd930', '#36ca75', '#3e60fd'];
  public dictionary: DictionaryType;

  constructor(private rootScopeService: RootScopeService) {
    this.dictionary = rootScopeService.getDictionary();
  }

  public ngOnInit(): void {
    if (this.isNoResult) {
      this.barColor = ['#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7'];
      this.color = '#d7d7d7';
    }
  }

  public declOfNum = Helper.declOfNum;

  public getColor(index: number): string {
    if (this.isSingleColor) {
      return this.color;
    }
    return this.barColor[index];
  }
}
