import { Component } from '@angular/core';
import { IButtonOnbording, IMessageOnbording } from 'app/shared/interfaces/common/ondording.interface';
import { Router } from '@angular/router';
import {
  ShowMessagesComponent,
} from '../../../../../shared/common-components/onbording/show-messages/show-messages.component';
import { OnbordingButtonsTypes } from '../fitting-professions-onbording/fitting-professions-onbording.component';
import { ApiNotificationsService, StorageKeys, WebStorageService } from '@profilum-library';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-new-spo-vpo-onboarding',
  standalone: true,
  templateUrl: './new-spo-vpo-onboarding.component.html',
  styleUrls: ['./new-spo-vpo-onboarding.component.scss'],
  imports: [ShowMessagesComponent],
})
export class NewSpoVpoOnboardingComponent extends UnsubscribeComponent {
  public endMessageNumber: number = 2;
  public startMessageNumber: number = 1;
  public messages: IMessageOnbording[] = [
    { text: '<p class="show-messages-title-text">👋 Привет, у нас </br>обновление!</p>' },
    {
      text: '<p class="show-messages__message-small">Теперь в карточках профессий ты можешь увидеть доступные способы обучения в твоем регионе для каждой из них!</p>',
    },
    {
      text: '<p class="show-messages-right-position-text">Давай посмотрим</p>',
      position: 'right',
    },
    {
      text: '<p class="show-messages__message-small">Теперь, чтобы спланировать обучение в той или иной профессии в своем регионе, перейди в раздел профессий</p>',
      image: './assets/images/onboarding/catalog.png',
    },
    {
      text: '<p class="show-messages__message-small">Дальше выбери профессию, которая тебе интересна </p>',
      image: './assets/images/onboarding/profession.png',
    },
    {
      text: '<p class="show-messages-right-position-text">Что дальше?</p>',
      position: 'right',
    },
    {
      text: '<p class="show-messages__message-small">В карточке ты увидишь раздел «Путь в профессии» в нем мы оставили для тебя ссылки на образовательные программы </p>',
      image: './assets/images/onboarding/path-to-profession.png',
    },
    {
      text: '<p class="show-messages__message-small">В твоих избранных профессиях ты также увидишь эти рекомендации</p>',
    },
  ];
  public buttons: IButtonOnbording[] = [{ text: 'Давай посмотрим', type: OnbordingButtonsTypes.Next }];

  constructor(
    private router: Router,
    private webStorageService: WebStorageService,
    private apiNotificationsService: ApiNotificationsService,
  ) {
    super();
  }

  public buttonsActions(event: string): void {
    switch (event) {
      case OnbordingButtonsTypes.Next: {
        this.startMessageNumber = 3;
        this.endMessageNumber = 4;
        this.timer = setTimeout(() => {
          this.buttons = [{ text: 'Что дальше?', type: OnbordingButtonsTypes.More }];
        }, 400);
        this.timer = setTimeout(() => {
          this.startMessageNumber = 4;
          this.endMessageNumber = 5;
        }, 700);
        break;
      }
      case OnbordingButtonsTypes.More: {
        this.startMessageNumber = 5;
        this.endMessageNumber = 6;
        this.timer = setTimeout(() => {
          this.buttons = [{ text: 'Спасибо!', type: OnbordingButtonsTypes.Close }];
        }, 400);
        this.timer = setTimeout(() => {
          this.startMessageNumber = 6;
          this.endMessageNumber = 7;
        }, 700);
        this.timer = setTimeout(() => {
          this.startMessageNumber = 7;
          this.endMessageNumber = 8;
        }, 1400);
        break;
      }
      case OnbordingButtonsTypes.Close: {
        const idNotify: string = this.webStorageService.get(StorageKeys.IdOnboardingNotification);
        this.apiNotificationsService.setNotificationViewed(idNotify).pipe(takeUntil(this.unsubscribe)).subscribe();

        this.router.navigate(['/pupil']);
        break;
      }
      default: {
        break;
      }
    }
  }
}
