<ng-container [ngSwitch]="testType">
  <ng-container *ngSwitchCase="testTypes.DEFAULT_STANDART.toString()">
    <ng-container *ngTemplateOutlet="default_default360"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="testTypes.DEFAULT_360.toString()">
    <ng-container *ngTemplateOutlet="default_default360"></ng-container>
  </ng-container>

  <ng-template #default_default360>
    <div class="about-test-section">
      <form *ngIf="role === 'pupil'" [formGroup]="form" novalidate>
        <h1>{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_CHILDREN' | translate }}</h1>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_1_CHILDREN' | translate }}</p>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_2_CHILDREN' | translate }}</p>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_3_CHILDREN' | translate }}</p>
      </form>
      <form *ngIf="role !== 'pupil'" [formGroup]="form" novalidate>
        <h1>{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</h1>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_1_PARENT' | translate }}</p>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_2_PARENT' | translate }}</p>
        <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_3_PARENT' | translate }}</p>
      </form>

      <ng-container *ngIf="selectLanguageAvailable">
        <div class="language-tab">
          <div class="language-text">{{ 'SHARED.SELECT_LANGUAGE_TESTING' | translate }}</div>
          <div class="language-switcher">
            <a
              (click)="toggleLanguage(regionLanguages.default)"
              [class.active]="selectedLanguage == regionLanguages.default"
              class="switcher-language-btn"
            >
              Русский
            </a>
            <a
              (click)="toggleLanguage(regionLanguages.native)"
              [class.active]="selectedLanguage == regionLanguages.native"
              class="switcher-language-btn"
            >
              Казахский
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-container *ngSwitchCase="testTypes.PROFESSIONPRESTIGE.toString()">
    <div>
      <h1>Опрос о профессиях</h1>
      <p>
        Приглашаем тебя пройти небольшой опрос про современный рынок труда, который содержит несколько вопросов на тему знания и отношения к
        разным профессиям. Опрос займет у тебя около 5 минут, и поможет собрать ценную информацию для исследовательских целей.
      </p>
      <img src="./profilum-assets/images/illustrations/start-page-prej.svg" class="welcome-image-stud" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.MOTIVATION_TEST.toString()">
    <div class="about-test-section">
      <h1>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.HEADER' | translate }}</h1>
      <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_1' | translate }}</p>
      <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_2' | translate }}</p>
      <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_3' | translate }}</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.VALUES_TEST.toString()">
    <div class="about-test-section">
      <h1>Какая у тебя система ценностей?</h1>
      <p>
        У каждого человека есть своя система ценностей — определенный набор убеждений: часть из них очень важны, другие нейтральны, а
        некоторые — совсем не важны. Ценности определяют, что мы выбираем и какие поступки совершаем. По результатам тестирования наша
        система покажет, что важно именно для тебя, и даст практические рекомендации, как сделать свою жизнь комфортнее.<br />
        Успешного старта!
      </p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.MINCIFRYDEMO_TEST.toString()">
    <div class="about-test-section">
      <h1>Добро пожаловать на страницу с тестированием в рамках проекта программирование для школьников!</h1>
      <br />
      <p>
        Предлагаем пройти входную диагностику, которая позволит определить твои интересы и предпочтения и порекомендовать наиболее
        подходящие программы обучения. Методика состоит из серии вопросов и занимает около 30 минут, поэтому перед началом убедись в наличии
        достаточного времени.
      </p>
      <br />
      <p>Приятного прохождения!</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.BVB_TEST.toString()">
    <div class="about-test-section about-test-section_bvb">
      <h1 class="title_bvb"><span>Диагностика проекта&nbsp;</span><span>«Билет в будущее»</span></h1>
      <p class="desc_bvb">
        Пройди её и узнай, какие направления <br />
        профессионального развития тебе подходят
      </p>
    </div>

    <div class="about-test-container">
      <div class="test-info" *ngIf="testDuration">
        <div class="test-duration">
          <div class="duration-value">{{ testDuration }}</div>
          <div class="duration-units">минут</div>
        </div>
        <div class="questions-quantity" *ngIf="testSteps">
          <div class="quantity-value">{{ testSteps }}</div>
          <div [innerHTML]="declOfNum(testSteps, ['вопрос', 'вопроса', 'вопросов'])" class="quantity-text"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.VK_TEST.toString()">
    <div class="about-test-section">
      <h1>Добро пожаловать на страницу с тестированием в рамках проекта программирование для школьников!**VK**</h1>
      <br />
      <p>
        Предлагаем пройти входную диагностику, которая позволит определить твои интересы и предпочтения и порекомендовать наиболее
        подходящие программы обучения. Методика состоит из серии вопросов и занимает около 30 минут, поэтому перед началом убедись в наличии
        достаточного времени.
      </p>
      <br />
      <p>Приятного прохождения!</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.WEB_TEST.toString()">
    <div class="about-test-section">
      <h1>Добро пожаловать на страницу с тестированием в рамках проекта программирование для школьников!</h1>
      <br />
      <p>
        Предлагаем пройти входную диагностику, которая позволит определить твои интересы и предпочтения и порекомендовать наиболее
        подходящие программы обучения. Методика состоит из серии вопросов и занимает около 30 минут, поэтому перед началом убедись в наличии
        достаточного времени.
      </p>
      <br />
      <p>Приятного прохождения!</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.CAMP_TEST.toString()">
    <div class="about-test-section">
      <h1>Добро пожаловать на диагностику!</h1>
      <br />
      <p>
        Пройди тест и узнай свои интересы, навыки и таланты! После прохождения посмотри свои результаты и каталог профессий! Прохождение
        методики занимает около 30 минут
      </p>
      <br />
      <prf-child-info-form></prf-child-info-form>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.MINCIFRY_TEST.toString()">
    <div class="about-test-section">
      <h1>Добро пожаловать!</h1>
      <br />
      <p>Пройди диагностику, чтобы мы могли оценить твои знания, интересы и предпочтения. Обрати внимание: у тебя одна попытка!</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="testTypes.BVB_TEST_VISUALLY_IMPAIRED.toString()">
    <div class="about-test-section about-test-section_bvb">
      <h1 class="title_bvb-visually-impaired"><span>Диагностика проекта&nbsp;</span><span>«Билет в будущее»</span></h1>
      <p class="desc_bvb-visually-impaired">
        Пройди её и узнай, какие направления <br />
        профессионального развития тебе подходят
      </p>
    </div>

    <div class="about-test-container">
      <div class="test-info" *ngIf="testDuration">
        <div class="test-duration">
          <div class="duration-value visually-impaired-duration-value">{{ testDuration }}</div>
          <div class="duration-units visually-impaired-duration-units">минут</div>
        </div>
        <div class="questions-quantity" *ngIf="testSteps">
          <div class="quantity-value visually-impaired-quantity-value">{{ testSteps }}</div>
          <div
            [innerHTML]="declOfNum(testSteps, ['вопрос', 'вопроса', 'вопросов'])"
            class="quantity-text visually-impaired-quantity-text"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
