import { Component, ElementRef, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IProfessionCard } from '../choose-profession.interface';

@Component({
  selector: 'prf-prof-card',
  templateUrl: './prof-card.component.html',
  styleUrls: ['./prof-card.component.scss'],
})
export class ProfCardComponent implements OnChanges {
  @Output() @ViewChild('cardContentRef') public cardContentRef: ElementRef;
  @Input() public cardData: IProfessionCard;
  @Input() public loading: boolean;

  public ngOnChanges(): void {
    this.scrollCardContentToTop();
  }

  private scrollCardContentToTop(): void {
    this.cardContentRef?.nativeElement?.scrollTo(0, 0);
  }
}
