<div *ngIf="childInfo" class="parent-form" [class.view-mode]="viewMode">
  <form
    class="parent-form_body"
    id="wf-form-User-Data"
    name="wf-form-User-Data"
    data-name="User Data"
    [formGroup]="form"
    autocomplete="off"
  >
    <div class="prf-form-control parent-form__control">
      <label class="prf-form-label parent-form__label" for="lastName" *ngIf="f.lastName.value && !viewMode">
        {{ 'SHARED.FORM.FAMILY' | translate }}
      </label>
      <input
        class="prf-form-input parent-form__input"
        type="text"
        [class.error-field]="submitted && f.lastName.errors"
        name="lastName"
        data-name="lastName"
        id="lastName"
        formControlName="lastName"
        placeholder="{{ 'SHARED.FORM.FAMILY' | translate }}"
        autocomplete="off"
      />
    </div>

    <div class="prf-form-control parent-form__control">
      <label class="prf-form-label parent-form__label" for="firstName" *ngIf="f.firstName.value && !viewMode">
        {{ 'SHARED.FORM.NAME' | translate }}
      </label>
      <input
        class="prf-form-input parent-form__input"
        type="text"
        [class.error-field]="submitted && f.firstName.errors"
        name="firstName"
        data-name="firstName"
        id="firstName"
        formControlName="firstName"
        placeholder="{{ 'SHARED.FORM.NAME' | translate }}"
        autocomplete="off"
      />
    </div>

    <div class="prf-form-control parent-form__control">
      <label class="prf-form-label parent-form__label" for="date" *ngIf="f.date.value && !viewMode"> Дата рождения </label>
      <input
        class="prf-form-input parent-form__input"
        type="text"
        [class.error-field]="submitted && f.date.errors"
        [textMask]="{ mask: dateMask }"
        name="date"
        placeholder="Дата рождения"
        data-name="date"
        id="date"
        formControlName="date"
        autocomplete="off"
      />
    </div>

    <div class="prf-form-control parent-form__control">
      <label class="prf-form-label parent-form__label" for="Phone" *ngIf="f.phone.value && !viewMode">
        {{ 'SHARED.FORM.PHONE' | translate }}
      </label>
      <input
        class="prf-form-input parent-form__input"
        type="text"
        autocomplete="off"
        [class.error-field]="f.phone.errors"
        name="Phone"
        data-name="Phone"
        id="Phone"
        formControlName="phone"
        placeholder="{{ 'SHARED.FORM.PHONE' | translate }}"
        [textMask]="{ mask: phoneMask }"
        (focusout)="checkPhone()"
      />
      <ng-container *ngIf="f.phone.errors">
        <mat-error *ngIf="f.phone.errors.pattern"> Номер телефона введен неверно</mat-error>
        <mat-error *ngIf="f.phone.errors.isUsed"> Телефон зарегистрирован. </mat-error>
      </ng-container>
    </div>

    <div class="profile-data-row gender-choice">
      <label class="label-gender">Пол</label>
      <div class="gender-choice" [class.disabled-choice]="true">
        <div class="gender-choice-row">
          <a
            class="change-gender right"
            (click)="switchGender(gendersView.female.key); $event.stopPropagation()"
            [ngClass]="currentGender === gendersView.female.key ? 'active' : ''"
            [class.change-gender_hide]="viewMode && currentGender !== gendersView.female.key"
          >
            {{ gendersView.female.name }}
          </a>
          <a
            class="change-gender"
            (click)="switchGender(gendersView.male.key); $event.stopPropagation()"
            [ngClass]="currentGender === gendersView.male.key ? 'active' : ''"
            [class.change-gender_hide]="viewMode && currentGender !== gendersView.male.key"
          >
            {{ gendersView.male.name }}
          </a>
        </div>
      </div>
    </div>

    <div class="prf-form-control parent-form__control" *ngIf="viewMode">
      <label class="prf-form-label parent-form__label" for="firstName" *ngIf="f.schoolName.value && !viewMode"> Школа </label>
      <textarea
        class="prf-form-input parent-form__input"
        type="text"
        [class.error-field]="submitted && f.schoolName.errors"
        name="schoolName"
        data-name="schoolName"
        id="schoolName"
        formControlName="schoolName"
        placeholder="Школа"
        autocomplete="off"
      >
      </textarea>
    </div>

    <div class="prf-form-control parent-form__control" *ngIf="viewMode">
      <label class="prf-form-label parent-form__label" for="firstName" *ngIf="f.className.value && !viewMode"> Класс </label>
      <input
        class="prf-form-input parent-form__input"
        type="text"
        [class.error-field]="submitted && f.className.errors"
        name="className"
        data-name="className"
        id="className"
        formControlName="className"
        placeholder="{{ 'SHARED.FORM.NAME' | translate }}"
        autocomplete="off"
      />
    </div>

    <ng-container *ngIf="!viewMode">
      <button (click)="changePhone()" class="button-send-form" [class._disabled]="!form.valid || !f.phone.touched || !f.phone.value">
        Сохранить
      </button>
    </ng-container>
  </form>
</div>
