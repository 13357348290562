import { Component, Input, OnInit } from '@angular/core';
import { CompareModalComponent } from '../../../results/compare-modal/compare-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'prf-interest-progress-bar',
  templateUrl: './interest-progress-bar.component.html',
  styleUrls: ['./interest-progress-bar.component.scss'],
})
export class InterestProgressBarComponent implements OnInit {
  @Input() data;
  @Input() isParent;

  constructor(private dialog: MatDialog) {}

  openDialog(skillData) {
    if (skillData.canCompare) {
      // Выводить окно только если есть возможность сравнивать
      let dialogRef = this.dialog.open(CompareModalComponent);
      dialogRef.componentInstance.data = skillData;
      dialogRef.componentInstance.isParent = this.isParent;
    }
  }

  ngOnInit() {
    this.data = this.data.sort((a, b) => b.parentEstimate - a.parentEstimate);
    // Вычислить расхождения если они есть
    this.data.forEach(item => {
      item.isEqual = Math.abs(item.childEstimate - item.parentEstimate) < 1;
      item.totalEstimate = item.childEstimate ? Math.floor((item.parentEstimate + item.childEstimate) / 2) : item.parentEstimate;
      item.canCompare = item.childEstimate && item.parentEstimate !== item.childEstimate;
    });
  }
}
