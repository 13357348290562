<div class="land-body">
  <div class="land-main-section-2">
    <img class="mobile main-section-pic" src="./assets/images/mo-landing/top-pic-mobile.svg" alt="" />
    <div class="land-content">
      <div class="land-header-row-2">
        <div class="land-header-logo-2 w-inline-block">
          <div class="profilum_logo">
            <img src="./assets/images/mo-landing/mo-logo.svg" alt="Moscow Region" />
          </div>
        </div>
        <div class="land-header-column">
          <a [ngClass]="routeAnimationsElements" routerLink="/mo-login" class="land-header-button w-button">{{
            'SHARED.ENTRANCE' | translate
          }}</a>
        </div>
      </div>
      <div class="land-main-block">
        <div class="main-block">
          <h1 class="land-h1-2" [ngClass]="routeAnimationsElements">
            <span class="underline">Карта талантов</span>
            <span class="underline">Подмосковья</span>
          </h1>
          <p class="land-subtitle-h1-2" [ngClass]="routeAnimationsElements">
            Система профнавигации, в которой можно построить траекторию к востребованной профессии в своём муниципалитете
          </p>
          <a [ngClass]="routeAnimationsElements" routerLink="/mo-registration" class="button land-blue-btn w-button">{{
            'SHARED.START_TEST' | translate
          }}</a>
          <a [ngClass]="routeAnimationsElements" target="_blank" [href]="urlForCompany" class="button land-blue-btn btn-school w-button"
            >Зарегистрировать школу</a
          >
        </div>
        <img [ngClass]="routeAnimationsElements" src="./assets/images/mo-landing/land-main-image.png" alt="" class="land-main-image" />
      </div>
    </div>
  </div>

  <div class="land-swiper-section land-content">
    <div class="land-swiper-container">
      <div class="swiper-promo-next">
        <img src="./assets/images/mo-landing/swiper-next.svg" alt="next" />
      </div>
      <div class="swiper-promo-prev">
        <img src="./assets/images/mo-landing/swiper-prev.svg" alt="prev" />
      </div>

      <div class="swiper-promo-wrapper">
        <swiper-container
          prfSwiperElement
          init="false"
          [ngClass]="routeAnimationsElements"
          #swiperPromoComponent
          [config]="swiperPromoConfig"
          (indexChange)="swiperIndexChange($event)"
          class="swiper-promo"
        >
          <swiper-slide class="swiper-promo-slide swiper-slide _1">
            <div class="slide-title">1. Начни строить свой маршрут к профессии мечты с вводного тестирования</div>
            <div class="slide-description">Важно определить, каким набором навыков ты обладаешь, еще до начала поисков профессии</div>
            <div class="slide-card-wrapper">
              <div class="slide-card">
                <img class="slide-pic" src="./assets/images/mo-landing/swiper-diagram.svg" alt="diagrams" />
                <div class="slide-pics-mobile mobile">
                  <img class="slide-mobile-pic" src="./assets/images/mo-landing/swiper-diagram1-mobile.svg" alt="" />
                  <img class="slide-mobile-pic" src="./assets/images/mo-landing/swiper-diagram2-mobile.svg" alt="" />
                </div>
                <div class="swiper-promo-pagination"></div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-promo-slide swiper-slide _2">
            <div class="slide-title">2. Выбирай профессии для изучения</div>
            <div class="slide-description">
              Мы порекомендуем тебе три сферы и девять профессий, в которых твои таланты полностью реализуются
            </div>
            <div class="slide-card">
              <img class="slide-pic" src="./assets/images/mo-landing/swiper-professions.svg" alt="professions" />
              <div class="slide-pics-mobile mobile">
                <img class="slide-mobile-pic _1" src="./assets/images/mo-landing/swiper-sl2-p1.svg" alt="profession card" />
                <img class="slide-mobile-pic _2" src="./assets/images/mo-landing/swiper-sl2-p2.svg" alt="profession card" />
                <img class="slide-mobile-pic _3" src="./assets/images/mo-landing/swiper-sl2-p3.svg" alt="profession card" />
              </div>
              <div class="swiper-promo-pagination"></div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-promo-slide swiper-slide _3">
            <div class="slide-title">3. Узнай, какими навыками обладаешь</div>
            <div class="slide-description">Для выбранной профессии ты получишь подборку мероприятий и заданий</div>
            <div class="slide-card">
              <img class="slide-pic" src="./assets/images/mo-landing/swiper-skills.png" alt="talents" />
              <div class="slide-pics-mobile mobile">
                <img class="slide-mobile-pic _1" src="./assets/images/mo-landing/swiper-sl3-pic.png" alt="levels page" />
              </div>
              <div class="swiper-promo-pagination"></div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>

  <div class="land-content">
    <img class="background-pic-mobile _1 mobile" src="./assets/images/mo-landing/back-bw-mobile.svg" alt="" />
    <div class="land-advantages-section">
      <img class="background-pic _1" src="./assets/images/mo-landing/back_bluewave_1.svg" alt="" />
      <div class="land-advantages-row">
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./assets/images/mo-landing/Idea_big.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Выявление талантов</h3>
          <p class="land-advantages-text">Тестирование предназначено для учащихся 7-11 классов и их родителей (тест 360)</p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./assets/images/mo-landing/Chat.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Индивидуальная траектория</h3>
          <p class="land-advantages-text">Сервис помогает подобрать мероприятия и кружки, связанные с интересующей профессией</p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./assets/images/mo-landing/Tie.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Прямая связь <br />с работодателями</h3>
          <p class="land-advantages-text">Возможность попасть на стажировку и познакомиться с лучшими компаниями региона</p>
        </div>
      </div>
    </div>
    <div class="land-users-section">
      <h2 class="land-h2">Для кого этот сервис?</h2>
      <p class="land-subtitle-h2">Карта талантов Подмосковья открывает множество возможностей:</p>
      <div class="land-user-tabs">
        <div class="land-user-tabs-menu" [ngClass]="routeAnimationsElements">
          <div class="land-user-tabs-menu-row">
            <a [ngClass]="{ 'w--current': swiperIndex === 0 }" (click)="toggleOverlayControls(0)" class="land-user-tab-link w-inline-block">
              <div class="land-user-tab-link-text">Ученикам и родителям</div>
            </a>
            <a
              [ngClass]="{ 'w--current': swiperIndex === 1 }"
              (click)="toggleOverlayControls(1)"
              class="land-user-tab-link w-inline-block w--current"
            >
              <div class="land-user-tab-link-text">Учителям и школам</div>
            </a>
            <a [ngClass]="{ 'w--current': swiperIndex === 2 }" (click)="toggleOverlayControls(2)" class="land-user-tab-link w-inline-block">
              <div class="land-user-tab-link-text">{{ 'LANDING_KZ.USERS_SECTION.NAVIGATION.ORGANIZATIONS' | translate }}</div>
            </a>
          </div>
        </div>

        <swiper-container
          prfSwiperElement
          init="false"
          [ngClass]="routeAnimationsElements"
          #swiperComponent
          [config]="swiperConfig"
          (indexChange)="swiperIndexChange($event)"
          class="professions-row"
        >
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img src="./assets/images/mo-landing/Family.svg" alt="" class="land-user-image" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Test.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Тестирование</h4>
                    <p class="land-user-text">Тест о подростке для родителя - 360.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Trajectory.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Рекомендации и построение индивидуальной траектории</h4>
                    <p class="land-user-text">
                      Актуальные и перспективные профессии, возможности дополнительного образования, профильные мероприятия и стажировки для
                      построения индивидуальной траектории.
                    </p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Free_work.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Стажировки</h4>
                    <p class="land-user-text">Привлечение учеников на практики и стажировки</p>
                  </div>
                </div>
                <a routerLink="/mo-registration" class="registr-button land-blue-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img src="./assets/images/mo-landing/Teacher.svg" alt="" class="land-user-image" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Hat.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Актуальные знания</h4>
                    <p class="land-user-text">
                      Использование современных технологий. Информация о перспективных в муниципалитете и регионе профессиях, потребностях
                      экономики.
                    </p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Idea.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Профили талантов</h4>
                    <p class="land-user-text">Формирование аналитики, позволяющей эффективно управлять профнавигационной работой.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Book.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Методическая поддержка</h4>
                    <p class="land-user-text">
                      Материалы для сопровождения профразвития школьников и организации тематических мероприятий.
                    </p>
                  </div>
                </div>
                <a routerLink="/mo-registration" class="registr-button land-blue-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img src="./assets/images/mo-landing/Professional.svg" alt="" class="land-user-image" />
              </div>

              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Test.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Практика и стажировки</h4>
                    <p class="land-user-text">Тест о подростке для родителя - 360.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Trajectory.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Активные кадры</h4>
                    <p class="land-user-text">Отбор активных участников системы.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./assets/images/mo-landing/Free_work.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Узнаваемость бренда</h4>
                    <p class="land-user-text">Повышение узнаваемости своего HR бренда среди молодёжи.</p>
                  </div>
                </div>
                <a routerLink="/mo-registration" class="registr-button land-blue-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="land-registration-section">
      <div class="section-container">
        <div class="land-reg-row">
          <div class="land-reg-column">
            <h2 class="land-h2 reg">Направляйте своего ребёнка</h2>
            <p class="land-subtitle-h2 reg">
              Пройдите тест 360 и узнайте, одинаково ли вы и ваш подросток видите профессиональное будущее.
            </p>
            <div class="w-center">
              <a routerLink="/mo-registration" class="land-blue-btn w-button">{{ 'SHARED.START_TEST' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
      <img class="background-pic _2" src="./assets/images/mo-landing/back_bluewave_2.svg" alt="" />
      <img class="background-pic-mobile _2 mobile" src="./assets/images/mo-landing/back-bw2-mobile.svg" alt="" />
    </div>
    <footer class="land-footer">
      <div class="footer-container">
        <div class="footer-grid-container">
          <div class="footer-grid-section mo-education">
            <img class="footer-logo-pic" src="./assets/images/mo-landing/footer-mo-logo.svg" alt="Profilum logo" />
          </div>

          <div class="footer-grid-section privacy">
            <div class="land-footer-text">© {{ nowDate | date : 'yyyy' }} Карта талантов<br />Подмосковья</div>
          </div>

          <div class="footer-grid-section academy">
            <img class="footer-academy-pic" src="./assets/images/mo-landing/Academy.png" alt="Social Academy" />
          </div>
          <div class="footer-grid-section docs">
            <div class="footer-personal">
              <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.USED_PRIVACY_DATA' | translate
              }}</a>
            </div>
            <div class="footer-agreement">
              <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.AGREEMENT.USER_AGREEMENT' | translate
              }}</a>
            </div>
          </div>

          <div class="footer-grid-section profilum">
            <img src="./assets/images/mo-landing/profilum-logo.svg" alt="Profilum logo" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
