<section class="edit-class">
  <div class="profile-section">
    <div class="profile-row">
      <div class="profile-column">
        <form id="email-form" [formGroup]="form">
          <h1>{{ 'SHARED.EDITING_CLASS' | translate }}</h1>
          <div class="choice">
            <div class="subtitle-class">Класс</div>
            <div class="select-class">
              <div class="dropdown-inline cat" #numberFilter>
                <div class="dropdown-inline-container w-dropdown">
                  <div class="login-input-container">
                    <label for="number" class="login-label topped-out"> Класс </label>
                    <div class="input-wrapper">
                      <input
                        readonly="readonly"
                        class="login-input w-input"
                        id="number"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="number"
                        disabled
                      />
                      <mat-error>
                        Для смены номера класса обратитесь <br />
                        к администратору системы
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="dropdown-inline cat" #letterFilter>
                  <div class="dropdown-inline-container w-dropdown">
                    <div class="login-input-container">
                      <label
                        [class.topped-out]="letterLabel || f.letter.value"
                        for="number"
                        class="login-label"
                        [class.error-field]="f.letter.touched && f.letter.errors"
                      >
                        Буква или название
                      </label>

                      <div class="input-wrapper">
                        <input
                          class="login-input uppercase w-input"
                          [class.error-field]="f.letter.touched && f.letter.errors"
                          [class.fill-field]="f.letter.touched && f.letter.valid"
                          id="letter"
                          maxlength="25"
                          type="text"
                          autocomplete="off"
                          formControlName="letter"
                          (focusin)="letterLabel = true"
                          (focusout)="f.letter.value ? null : (letterLabel = false)"
                        />
                        <mat-error *ngIf="f.letter.touched && f.letter.errors"> Некорректное название класса </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="class-course-select">
                <div class="class-teacher-select_course">
                  <input
                    readonly="readonly"
                    class="class-field w-input"
                    type="text"
                    formControlName="course"
                    placeholder="Выбрать курс из списка"
                    autocomplete="off"
                    disabled
                  />
                  <div class="dropdown-arrow"></div>
                </div>
                <mat-error> Для смены курса класса обратитесь к администратору системы </mat-error>
              </div>
            </div>
            <div class="class-teacher">
              <div class="subtitle-class">Учитель</div>

              <div *ngIf="addNewTeacher" class="class-teacher-form">
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="lastNameLabel || f.lastName.value"
                      for="lastName"
                      class="login-label"
                      [class.error-field]="f.lastName.touched && f.lastName.errors"
                    >
                      Фамилия
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="f.lastName.touched && f.lastName.errors"
                        [class.fill-field]="f.lastName.touched && f.lastName.valid"
                        id="lastName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="lastName"
                        (focusin)="lastNameLabel = true"
                        (focusout)="f.lastName.value ? null : (lastNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="firstNameLabel || f.firstName.value"
                      for="firstName"
                      class="login-label"
                      [class.error-field]="f.firstName.touched && f.firstName.errors"
                    >
                      Имя
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="f.firstName.touched && f.firstName.errors"
                        [class.fill-field]="f.firstName.touched && f.firstName.valid"
                        id="firstName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="firstName"
                        (focusin)="firstNameLabel = true"
                        (focusout)="f.firstName.value ? null : (firstNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
                <div class="class-name-column">
                  <div class="login-input-container">
                    <label
                      [class.topped-out]="middleNameLabel || f.middleName.value"
                      for="middleName"
                      class="login-label"
                      [class.error-field]="f.middleName.touched && f.middleName.errors"
                    >
                      Отчество
                    </label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="f.middleName.touched && f.middleName.errors"
                        [class.fill-field]="f.middleName.touched && f.middleName.valid"
                        id="middleName"
                        maxlength="25"
                        type="text"
                        autocomplete="off"
                        formControlName="middleName"
                        (focusin)="middleNameLabel = true"
                        (focusout)="f.middleName.value ? null : (middleNameLabel = false)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!addNewTeacher" class="class-teacher-select">
                <input
                  class="class-field w-input"
                  type="text"
                  formControlName="fullName"
                  placeholder="Выбрать из списка"
                  autocomplete="off"
                  (click)="showTeachersList = !showTeachersList"
                  #teacherDrop
                />
                <div class="dropdown-arrow" [class.opened]="showTeachersList" [class.fill]="f.fullName.value"></div>
                <div class="teachers-list-wrapper" *ngIf="showTeachersList">
                  <ul class="teachers-list">
                    <ng-container *ngFor="let teacher of teachers">
                      <li class="teacher-list-item" (click)="selectTeacher(teacher)">
                        {{ teacher.lastName }} {{ teacher.firstName }} {{ teacher.middleName }}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>

              <a (click)="toggleInputTeacher()">
                {{ addNewTeacher ? 'Выбрать из списка' : 'Добавить нового учителя' }}
              </a>
            </div>
          </div>
          <div class="class-buttons-row left">
            <a
              (click)="animateSubmit()"
              [ngClass]="{ disabled: !isAccessAllowed() || isProcessing }"
              [class.btn-waiting]="buttonWaiting"
              [class.btn-activated]="buttonActivated"
              [class.btn-activate]="!buttonActivate"
              class="button-animate btn-activate"
              id="btnActivation"
            >
              <span class="btn-icon"></span>
              <span class="btn-text" data-wait="Подождите" data-after="Сохранено">Сохранить</span>
            </a>

            <a (click)="hideEdit(false)" class="button white-button class-edit-button w-button"> Отменить </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
