import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'prf-pupil-common-loader',
  standalone: true,
  templateUrl: './pupil-common-loader.component.html',
  styleUrls: ['./pupil-common-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PupilCommonLoaderComponent {
}
