import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPupilsAccessesComponent } from './download-pupils-accesses.component';

@NgModule({
  declarations: [DownloadPupilsAccessesComponent],
  imports: [CommonModule],
  exports: [DownloadPupilsAccessesComponent],
})
export class DownloadPupilsAccessesModule {}
