import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppSettingsService,
  IData,
  IProfession,
  StorageKeys,
  UserActionsService,
  WebStorageService,
  YmItems,
} from '@profilum-library';

const DESCRIPTION_LENGTH: number = 170;

@Component({
  selector: 'prf-profession-card-new',
  templateUrl: './profession-card-new.component.html',
  styleUrls: ['./profession-card-new.component.scss'],
})
export class ProfessionCardNewComponent {
  @HostBinding('class.professions-column-2') hostClass: boolean = true;

  @Input()
  set profession(profession: IProfession) {
    if (!profession || !profession.name) {
      return;
    }

    this._profession = Object.assign({}, profession);

    if (this._profession.mainImagePath) {
      this._profession.mainImagePath = './profilum-assets/images/profession/' + this._profession.mainImagePath.substring(42);
    } else {
      this._profession.mainImagePath = './profilum-assets/images/icons/no-prof-icon.png';
    }

    this._profession.shortDescription = this._profession.shortDescription.replace(/<p>/i, '').replace(/<\/p>/i, '');

    if (this._profession.shortDescription.length > DESCRIPTION_LENGTH) {
      this._profession.shortDescription = this._profession.shortDescription.substring(0, DESCRIPTION_LENGTH - 3) + '...';
    }
  }

  get profession(): IProfession {
    return this._profession;
  }

  private _profession: IProfession;
  public showSalary: boolean = false;

  isMobile: boolean = false;

  constructor(private router: Router, private userActionsService: UserActionsService, private webStorageService: WebStorageService) {
    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
    this.isMobile = window.innerWidth <= 991;
    this.userActionsService.setInitializationTime([YmItems.T_Professions_ProfessionCard]);
  }

  public checkProfession(profession: any): boolean {
    const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
    const municipalityId: string = this.webStorageService.get(StorageKeys.MunicipalityId);
    return profession.regionId === regionId && profession.municipalityId === municipalityId;
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen) + '..';
    }
    return text;
  }

  public openProfession() {
    const uaParams: IData = {
      ProfessionId: this.profession.id,
      ProfessionUrl: this.profession.hrid,
    };

    const ymParams: IData = {
      event_label: { Profession: this.profession.id },
    };

    this.userActionsService.log(YmItems.T_Professions_ProfessionCard, uaParams, ymParams);
    this.router.navigate(['/professions', this.profession.hrid]);
    // this.professionService.setCurrentProfession(this.profession);
    // this.teacherPanelService.showStudentPage();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 991;
  }
}
