import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

@Component({
  selector: 'prf-tokenauth-mosobl',
  templateUrl: './tokenauth-murmansk.component.html',
  styleUrls: ['./tokenauth-murmansk.component.scss'],
})
export class TokenauthMurmanskComponent implements OnInit {
  queryParams: any;
  accessToken: any;
  tokenFailed: boolean = false;
  showMessage: boolean = false;
  alertMessage: string = '';

  buttonWaiting: boolean = false;
  buttonActivated: boolean = false;
  buttonActivate: boolean = false;

  constructor(private meta: Meta, private router: Router, private route: ActivatedRoute, private overlayService: OverlayBusyService) {}

  ngOnInit() {
    this.overlayService.show();
    this.queryParams = this.route.snapshot.queryParams;
    this.accessToken = this.queryParams['accessToken'];

    if (this.accessToken && this.accessToken != '') {
      //????
    } else {
      this.showMessage = true;
      this.alertMessage = 'Нет токена в URL';
    }
    this.overlayService.hide();
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
    this.overlayService.hide();
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
    this.overlayService.hide();
  }
}
