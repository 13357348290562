export enum ChatBotButtonTypeEnum {
  NEXT_STEP = 'NEXT_STEP',
  DISPOSABLE = 'DISPOSABLE',
  CLOSE = 'CLOSE',
}

export enum ChatBotButtonVisibleEnum {
  AFTER_ONE = 'AFTER_ONE',
}

export enum ChatBotMessageTypeEnum {
  DEFAULT = 'DEFAULT',
  WITH_TEMPLATE_VARIABLES = 'WITH_TEMPLATE_VARIABLES',
  SEVERAL_POINTS_EQUAL = 'SEVERAL_POINTS_EQUAL',
}
