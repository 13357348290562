import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { IPupil } from 'app/shared/interfaces/ipupil.interface';
import { of } from 'rxjs';
import { ApiUsersService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-director-student-page',
  templateUrl: './director-student-page.component.html',
  styleUrls: ['./director-student-page.component.scss'],
})
export class DirectorStudentPageComponent extends UnsubscribeComponent implements OnInit {
  public pupil: IPupil;
  public classId: string;

  constructor(private apiUsersService: ApiUsersService, private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        switchMap((queryParams: Params) => {
          if (queryParams) {
            this.classId = queryParams.classId;
            return this.apiUsersService.getPupilsDirectors(queryParams.classId).pipe(
              tap((pupils: IPupil[]) => {
                if (pupils) {
                  this.pupil = pupils.find(el => el.userId === queryParams.userId);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  routingClassPage() {
    this.router.navigate([`director/class/${this.classId}`], { state: { userId: this.pupil.userId } });
  }
}
