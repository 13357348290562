<section class="openregistration" *ngIf="dataFetched">
  <header>
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
    </a>
  </header>
  <div class="openregistration-container">
    <h1>{{ 'SHARED.REGISTRATION_CHILD' | translate }}</h1>
    <form [formGroup]="form" class="openregistration-container__wrapper">
      <div class="profile-data-wrapper">
        <div class="profile-data-row">
          <div>
            <label for="lastName" class="user-data-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
            <div class="div-space"></div>
          </div>
          <div class="profile-field-div">
            <input
              type="text"
              class="user-data-field w-input"
              [class.error-field]="submitted && f.lastName.errors"
              name="lastName"
              data-name="lastName"
              id="lastName"
              formControlName="lastName"
            />
          </div>
        </div>

        <div class="profile-data-row">
          <div>
            <label for="firstName" class="user-data-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
            <div class="div-space"></div>
          </div>
          <div class="profile-field-div">
            <input
              type="text"
              class="user-data-field w-input"
              [class.error-field]="submitted && f.firstName.errors"
              name="firstName"
              data-name="firstName"
              id="firstName"
              formControlName="firstName"
            />
          </div>
        </div>

        <div class="profile-data-row">
          <div>
            <label for="middleName" class="user-data-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label>
            <div class="div-space"></div>
          </div>
          <div class="profile-field-div">
            <input
              type="text"
              class="user-data-field w-input"
              [class.error-field]="submitted && f.middleName.errors"
              name="middleName"
              data-name="middleName"
              id="middleName"
              formControlName="middleName"
            />
          </div>
        </div>

        <div class="profile-data-row">
          <div>
            <label for="date" class="user-data-label">Дата рождения:</label>
            <div class="div-space"></div>
          </div>
          <div class="profile-field-div">
            <input
              type="text"
              autocomplete="new-date"
              (ngModelChange)="validateAge()"
              class="user-data-field w-input"
              [class.error-field]="submitted && f.date.errors"
              [textMask]="{ mask: dateMask }"
              name="date"
              data-name="date"
              id="date"
              formControlName="date"
              (focusout)="checkFormatDate($event)"
            />
            <ng-container *ngIf="checkBirthday">
              <mat-error *ngIf="f.date.errors || !validateAge()">
                {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.CHECK_DATA' | translate }}
              </mat-error>
            </ng-container>
          </div>
        </div>

        <div class="profile-data-row">
          <div>
            <label class="user-data-label">Пол:</label>
            <div class="div-space"></div>
          </div>
          <div class="gender-choice">
            <div class="gender-choice-row">
              <a class="gender-button active" (click)="setMale()" [class.active]="isMale(genderChoice)" #male> М </a>
              <a class="gender-button right" (click)="setFemale()" [class.active]="!isMale(genderChoice)" #female> Ж </a>
            </div>
          </div>
        </div>

        <div class="profile-data-row" *ngIf="cityEnabled">
          <prf-dropdown-with-search
            formControlName="city"
            [label]="'SHARED.FORM.CITY' | translate"
            [options]="citiesView"
            (ngModelChange)="onCityChange()"
            [isError]="submitted && f.city.errors"
          ></prf-dropdown-with-search>
        </div>
        <div class="profile-data-row" *ngIf="f.city.value && !mosruUser && schoolEnabled">
          <prf-dropdown-with-search
            formControlName="school"
            [label]="'SHARED.FORM.SCHOOL' | translate"
            [options]="schoolView"
            (ngModelChange)="onSchoolChange()"
            [isError]="submitted && f.school.errors"
          ></prf-dropdown-with-search>
        </div>

        <div class="profile-data-row" *ngIf="f.school.value && classesFetched && !mosruUser && classEnabled">
          <div>
            <label class="login-label">Класс:</label>
            <div class="div-space"></div>
          </div>
          <div class="dd-container">
            <prf-dropdown-with-search
              *ngIf="isClasses"
              class="dropdown-inline cat"
              formControlName="schoolClass"
              [label]="'Класс'"
              [type]="1"
              [options]="schoolClassView"
              [isError]="submitted && f.schoolClass.errors"
            ></prf-dropdown-with-search>

            <prf-dropdown-with-search
              *ngIf="!isClasses"
              class="dropdown-inline cat dd-padding-r"
              formControlName="schoolClassNumber"
              [label]="'Номер'"
              [type]="1"
              [options]="schoolClassNumber"
              [isError]="submitted && f.schoolClassNumber.errors"
            ></prf-dropdown-with-search>

            <prf-dropdown-with-search
              *ngIf="!isClasses"
              class="dropdown-inline cat dd-padding-l"
              formControlName="schoolClassLetter"
              [label]="'Класс'"
              [type]="1"
              [options]="schoolClassLetter"
              [isError]="submitted && f.schoolClassLetter.errors"
            ></prf-dropdown-with-search>

            <a class="dd-toggle-isclass" (click)="tooggleSelectClassType()">{{
              isClasses ? ('SHARED.FORM.CLASS_NOT_LISTED' | translate) : ('SHARED.FORM.SELECT_LISTED' | translate)
            }}</a>
          </div>
        </div>

        <div class="terms">
          <div class="checkbox-terms-grn" [class.checked-terms-grn]="personalTerms" (click)="personalTerms = !personalTerms"></div>
          <span class="terms-text"
            >{{ 'SHARED.AGREEMENT.ACCEPT_TEXT' | translate }}
            <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank">{{ 'SHARED.AGREEMENT.USER_CONFIRMATION' | translate }}</a>
            {{ 'SHARED.AGREEMENT.CONSENT_PROCESSING' | translate }}
            <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank">{{ 'SHARED.AGREEMENT.MY_PRIVATE_DATA' | translate }}</a>
          </span>
        </div>

        <div class="w-center-content">
          <a
            (click)="animateLogin()"
            [ngClass]="{ disabled: !isAccessAllowed() }"
            [class.btn-waiting]="buttonWaiting"
            [class.btn-activated]="buttonActivated"
            [class.btn-activate]="!buttonActivate"
            class="button-animate btn-activate"
            id="btnActivation"
          >
            <span class="btn-icon"></span>
            <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.REGISTRATION' | translate">Зарегистрировать</span>
          </a>
        </div>
      </div>
    </form>
  </div>

  <div class="add-child-lb lb show" [style.opacity]="isShow ? 1 : 0" [style.display]="isShow ? 'block' : 'none'">
    <div class="lb-bg"></div>

    <div class="lb-block">
      <div class="invite-parent-row">
        <div class="add-child-column">
          <h3 class="h3-invite-parent">{{ 'SHARED.SMALL_BUSINESS' | translate }}</h3>
          <p class="add-child-text">Для регистрации ребенка потребуется ввести 6ти значный код на странице регистрации</p>
          <div class="profile-code">
            <div>Код ребенка</div>
            <div class="code-text">{{ response.registrationCode }}</div>
            <div class="info-div">
              <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
              <div class="info-popup" *ngIf="showInfoPopup">
                <p>Этот код является паролем при регистрации.</p>
                <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
              </div>
            </div>
          </div>
          <a routerLink="/registration">{{ 'SHARED.REGISTRATION' | translate }}</a>
        </div>

        <img
          src="./profilum-assets/images/illustrations/Bitmap.png"
          width="267"
          srcset="./profilum-assets/images/illustrations/Bitmap-p-500.png 500w, ./profilum-assets/images/illustrations/Bitmap.png 534w"
          sizes="(max-width: 767px) 100vw, 267px"
          class="invite-parent-image"
        />
      </div>
      <a (click)="closeModal()" class="close-lb w-inline-block">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
    </div>
  </div>
</section>
