import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ApiSchoolsService, IFilterClasses, StorageKeys, WebStorageService } from '@profilum-library';
import {
  InternshipsFiltersService,
} from '../../../../../internship-page/internship-catalog/internship-filter/internships-filter.service';

export const COURSES_TYPES = [
  { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple', count: null },
  { value: 'Excursion', viewValue: 'Экскурсия', color: '', count: null },
  { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple', count: null },
  { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange', count: null },
  { value: 'Action', viewValue: 'Акция', color: '', count: null },
  { value: 'Festival', viewValue: 'Фестиваль', color: '', count: null },
  { value: 'Meeting', viewValue: 'Встреча', color: 'yellow', count: null },
  { value: 'Competition', viewValue: 'Конкурс', color: 'orange', count: null },
  { value: 'Profproba', viewValue: 'Профпроба', color: 'blue', count: null },
  { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green', count: null },
];

@Component({
  selector: 'prf-courses-filter-types',
  templateUrl: './courses-filter-types.component.html',
  styleUrls: ['./courses-filter-types.component.scss'],
})
export class CoursesFilterTypesComponent implements OnInit, OnDestroy {
  filters: IFilterClasses;
  types = COURSES_TYPES;
  selectedTypes: any = [];
  typesOpen: boolean = false;
  @ViewChild('typesFilter') typesFilter: ElementRef;

  classesFormatTypes = ClassesFormatTypes;

  userRole: string;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private filtersService: CoursesFiltersService,
    private apiSchoolsService: ApiSchoolsService,
    private internshipsFiltersService: InternshipsFiltersService,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        mergeMap(filters => {
          this.filters = filters;
          this.selectedTypes = filters.classesFormat ? filters.classesFormat : [];
          return this.loadStatistic();
        }),
      )
      .subscribe();

    const path = this.router.routerState.snapshot.url;
    if (path.includes('/events')) {
      this.types = [
        { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple', count: null },
        { value: 'Excursion', viewValue: 'Экскурсия', color: '', count: null },
        { value: 'Action', viewValue: 'Акция', color: '', count: null },
        { value: 'Festival', viewValue: 'Фестиваль', color: '', count: null },
        { value: 'Meeting', viewValue: 'Встреча', color: 'yellow', count: null },
        { value: 'Competition', viewValue: 'Конкурс', color: 'orange', count: null },
        { value: 'Profproba', viewValue: 'Профпроба', color: 'blue', count: null },
        { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green', count: null },
      ];
    } else if (path.includes('/courses')) {
      if (this.userRole === 'admin') {
        this.types = [
          { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple', count: null },
          { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange', count: null },
          { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple', count: null },
          { value: 'Excursion', viewValue: 'Экскурсия', color: '', count: null },
          { value: 'Action', viewValue: 'Акция', color: '', count: null },
          { value: 'Festival', viewValue: 'Фестиваль', color: '', count: null },
          { value: 'Meeting', viewValue: 'Встреча', color: 'yellow', count: null },
          { value: 'Competition', viewValue: 'Конкурс', color: 'orange', count: null },
          { value: 'Profproba', viewValue: 'Профпроба', color: 'blue', count: null },
          { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green', count: null },
        ];
      } else {
        this.types = [
          { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple', count: null },
          { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange', count: null },
        ];
      }
    }
  }

  private loadStatistic(): Observable<any> {
    let classesCountStatistic$: Observable<any> = of(null);
    classesCountStatistic$ = this.apiSchoolsService.getClassesCountStatistic(this.filters, this.userRole === 'admin');
    return classesCountStatistic$.pipe(
      takeUntil(this.ngUnsubscribe$),
      tap(classesCountStatistic => {
        this.types.forEach(type => {
          if (!this.types.find(type => type.value === classesCountStatistic.classesFormatsCount[type.value])) {
            type.count = classesCountStatistic.classesFormatsCount[type.value];
          }
        });
      }),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  setTypeFilter(type: { value: string; viewValue: string; color: string; count: any }) {
    let enumIndex: number;
    if (!this.filters.classesFormat) {
      enumIndex = ClassesFormatTypes[type.value];
      this.filters.classesFormat = enumIndex ? [enumIndex] : null;
    } else {
      enumIndex = ClassesFormatTypes[type.value];

      const index = this.selectedTypes.indexOf(enumIndex);
      index !== -1 ? this.filters.classesFormat.splice(index, 1) : this.filters.classesFormat.push(enumIndex);
    }
    this.filtersService.setCoursesFilter(this.filters);
  }

  deselectTypes() {
    this.filters.classesFormat = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.classesFormat && this.filters.classesFormat.length > 0) {
      if (this.filters.classesFormat.length > 1) {
        const typeTitles = ['тип', 'типа', 'типов'];
        return (
          this.filters.classesFormat.length +
          ' ' +
          this.internshipsFiltersService.getTitleByNumber(this.filters.classesFormat.length, typeTitles)
        );
      } else {
        const enumIndex = this.filters.classesFormat[0];
        const type = this.types.filter(el => el.value == ClassesFormatTypes[enumIndex].toString());
        return type[0].viewValue;
      }
    } else {
      return 'Тип';
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.typesFilter && !this.typesFilter?.nativeElement.contains(target)) {
      this.typesOpen = false;
    }
  }
}
