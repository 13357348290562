import { Component } from '@angular/core';
import { RegistrationBase } from '../../../../shared/auth-classes/registration.base';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterSchoolsPipe } from '../../../../shared/pipes/filter-schools.pipe';
import { FilterByNamePipe } from '../../../../shared/pipes/filter-by-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
} from '@profilum-library';
import { UtilsService } from '../../../../shared/dashboard/backend-services/utils.service';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-registration-pupil',
  templateUrl: './registration-pupil.component.html',
  styleUrls: ['./registration-pupil.component.scss'],
})
export class RegistrationPupilComponent extends RegistrationBase<B2gSaasService> {
  public regionEnabled: boolean = true;
  public cityEnabled: boolean = true;
  public schoolEnabled: boolean = true;
  public classEnabled: boolean = true;

  public _regionsView;

  public registrationByParams: boolean = false;

  constructor(
    router: Router,
    fb: UntypedFormBuilder,
    apiLocationsService: ApiLocationsService,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    userDataHandlerService: UserDataHandlerService,
    private activatedRoute: ActivatedRoute,
    protected b2gSaasService: B2gSaasService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
    protected utilsService: UtilsService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      b2gSaasService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
      utilsService,
    );
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.activatedRoute.queryParams
      .pipe(
        switchMap(params => {
          if (params) {
            params.regionId ? this.webStorageService.set(StorageKeys.RegionIdParam, params.regionId) : null;
            params.cityId ? this.webStorageService.set(StorageKeys.CityIdParam, params.cityId) : null;
            params.schoolId ? this.webStorageService.set(StorageKeys.SchoolIdParam, params.schoolId) : null;
            params.classId ? this.webStorageService.set(StorageKeys.ClassIdParam, params.classId) : null;

            return this.activatedRoute.queryParams.pipe(
              switchMap(params => {
                this.regionsView = this.regions;
                if (params?.regionId) {
                  this.registrationByParams = true;
                  const region = this.regionsView.find(region => region.data.id === params.regionId);
                  if (region) {
                    this.defaultRegion = region.data.regionId;
                    this.form.get('region').setValue(region);
                    this.regionEnabled = false;

                    this.onRegionChange();
                    if (params.cityId) {
                      const city = this.getCitiesView.find(city => city.data.id === params.cityId);
                      if (city) {
                        this.form.get('city').setValue(city);
                        this.cityEnabled = false;

                        this.onCityChange();
                        if (params.schoolId) {
                          const school = this.getSchoolView.find(school => school.data.id === params.schoolId);
                          if (school) {
                            this.form.get('school').setValue(school);
                            this.schoolEnabled = false;

                            return this.onSchoolChange().pipe(
                              tap(r => {
                                if (params.classId) {
                                  const pupilschoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === params.classId);
                                  this.f.schoolClass.setValue(pupilschoolClass);
                                  this.classEnabled = false;
                                }
                              }),
                            );
                          } else {
                            return of(null);
                          }
                        } else {
                          return of(null);
                        }
                      }
                    } else {
                      return of(null);
                    }
                  } else {
                    this.defaultRegion = AppSettingsService.settings.b2c.regionId;
                    this.regions = this.regions.filter(r => r.id === this.defaultRegion);
                    this.f.region.setValue(this.regionsView.find(r => r.data.id === this.defaultRegion));
                    this.onRegionChange();
                    return of(null);
                  }
                } else {
                  return of(null);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  set regionsView(regions) {
    this._regionsView = [...regions].map(region => ({ name: region.name, data: region }));
  }

  get regionsView() {
    return [...this.regions].map(region => ({ name: region.name, data: region }));
  }

  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }

  get isAccessAllowed() {
    return this.registrationByParams
      ? this.form.valid && this.isValidPassword(this.f.password.value) && !this.isNotEnoughYears
      : this.form.valid && this.isClassSelected && this.isValidPassword(this.f.password.value) && !this.isNotEnoughYears;
  }

  toChangeSchool() {
    this.onSchoolChange().pipe(take(1)).subscribe();
  }

  onSchoolChange(): Observable<any> {
    if (this.f.school.value && this.f.school.value.data.id) {
      return this.getSchoolClassBySchool(this.f.school.value.data.id).pipe(
        tap(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        }),
        takeUntil(this.unsubscribe),
      );
    } else {
      return of(null);
    }
  }
}
