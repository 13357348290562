import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-diagram-compare-modal',
  templateUrl: './diagram-compare-modal.component.html',
  styleUrls: ['./diagram-compare-modal.component.scss'],
})
export class DiagramCompareModalComponent implements OnInit {
  modalSpiderData;
  axisIndex;
  valueIndex;
  isParent;

  constructor(public dialogRef: MatDialogRef<DiagramCompareModalComponent>) {}

  ngOnInit() {}

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
