<label for="tabs" class="label tabs-label">
  <span class="chart-title" *ngIf="!noTitle">
    {{ 'CHARTS.INFRASTRUCTURE_AND_REQUESTS.FULL' | translate }}
    <mat-icon
      *ngIf="chartTabsTooltip && chartTabsTooltip.length"
      svgIcon="info"
      class="cursor-arrow"
      [tp]="tooltip"
      [tpPlacement]="'right'"
    ></mat-icon>
  </span>
  <mat-card class="tabs-container">
    <mat-tab-group id="tabs" animationDuration="500ms" #matTabGroup>
      <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.name">
        <ng-container *ngTemplateOutlet="tab.content"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</label>

<ng-template #tooltip>
  <prf-tooltip>{{ chartTabsTooltip }}</prf-tooltip>
</ng-template>
