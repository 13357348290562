<!-- SEARCH -->
<prf-search-courses></prf-search-courses>

<!-- FILTERS -->
<prf-filters-courses [territoryFilters]="showTerritoryFilter()"></prf-filters-courses>

<!--VIEW SETTINGS-->
<div class="courses-row-1">
  <div class="courses-number">{{ classesCount }}</div>

  <div class="courses-column-1">
    <div class="gender-choice-row">
      <a (click)="viewValue = false" [class.active]="viewValue === false" #grid class="gender-button w-inline-block">
        <img *ngIf="!viewValue" src="./profilum-assets/images/courses/list-view-1-white.svg" />
        <img *ngIf="viewValue" src="./profilum-assets/images/courses/list-view-1-gray.svg" />
      </a>
      <a (click)="viewValue = true" [class.active]="viewValue === true" #list class="gender-button right w-inline-block">
        <img *ngIf="viewValue" src="./profilum-assets/images/courses/list-view-2-white.svg" />
        <img *ngIf="!viewValue" src="./profilum-assets/images/courses/list-view-2-gray.svg" />
      </a>
    </div>
  </div>
</div>

<!-- EVENTS -->
<prf-events-courses [viewValue]="viewValue" (countCoursesEmitter)="countCourses($event)"></prf-events-courses>
