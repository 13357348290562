import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { BaseSliderComponent } from './base-slider/base-slider.component';
import { BooksSliderComponent } from './books-slider/books-slider.component';
import { JobSliderComponent } from './job-slider/job-slider.component';
import { JobSubscriptionComponent } from './job-subscription/job-subscription.component';
import { MovieSliderComponent } from './movie-slider/movie-slider.component';
import { PersonSliderComponent } from './person-slider/person-slider.component';
import { ProfessionCardNewComponent } from './profession-cards/profession-card-new/profession-card-new.component';
import { ProfessionCardComponent } from './profession-cards/profession-card/profession-card.component';
import { ProfessionCardsComponent } from './profession-cards/profession-cards.component';
import { ProfessionCatalogComponent } from './profession-cards/profession-catalog/profession-catalog.component';
import {
  ProfessionOrderComponent,
} from './profession-cards/profession-catalog/profession-order/profession-order.component';
import {
  ProfessionSearchComponent,
} from './profession-cards/profession-catalog/profession-search/profession-search.component';
import {
  ProfessionContentLoaderComponent,
} from './profession-cards/profession-content-loader/profession-content-loader.component';
import {
  ProfessionRecommendationsComponent,
} from './profession-cards/profession-recommendations/profession-recommendations.component';
import { ProfessionDetailsComponent } from './profession-details/profession-details.component';
import { DropdownModule } from '../../../ui-kit/components/dropdown/dropdown.module';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import {
  ProfessionsLoaderComponent,
} from '../../../ui-kit/components/loaders/mobile-loaders/templates/professions-loader/professions-loader.component';

@NgModule({
    imports: [RouterModule, SharedModule, DropdownModule, SwiperDirective, ProfessionsLoaderComponent],
  declarations: [
    JobSubscriptionComponent,
    BaseSliderComponent,
    MovieSliderComponent,
    JobSliderComponent,
    PersonSliderComponent,
    BooksSliderComponent,
    ProfessionDetailsComponent,
    ProfessionCardsComponent,
    ProfessionCatalogComponent,
    ProfessionSearchComponent,
    ProfessionOrderComponent,
    ProfessionRecommendationsComponent,
    ProfessionCardNewComponent,
    ProfessionContentLoaderComponent,
    ProfessionCardComponent,
  ],
  providers: [],
  bootstrap: [],
  exports: [ProfessionOrderComponent, ProfessionContentLoaderComponent, ProfessionCardNewComponent, ProfessionSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfessionsModule {}
