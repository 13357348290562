export const QUESTIONS_WITH_SUBSECTIONS: string[] = ['54aa24f2-436f-4ce9-8c1d-1522703992ce'];

export const QUESTIONS_WITH_RECTANGLE_VIEW: string[] = [
  'a0410e33-b919-49b9-df20-0f24810f198e',
  'db15f57a-c454-ceda-363f-437d5dea89c5',
  'c56a4d91-ce69-d055-8a75-21470ddc2abb',
  'dd80d550-bf45-e40b-0bb4-58b7fc33d772',
  'a7c34e12-0e31-44ad-aa25-0c4a30b67973',
  '8a5214c9-881a-1eb0-6019-2024370c0850',
  '48dc6b53-5e70-209b-38b9-c5eeb3bc77bd',
  'defb718f-aa06-07e1-4a80-8971709dee0d',
  '39d18039-ac4f-445c-af07-dd53ae0bf57a',
  'a8355db6-1331-418c-a40b-f65084f9c193',
  'abbda951-6157-4c9c-80aa-7234ff9f27e5',
  '1f34c7eb-9606-f501-d31e-c06d7988ecd7',
  'c65c7c38-3662-4e81-9bbb-fb4b04cd2214',
  '7c1b64ba-13bb-80db-44e1-db52dbe828c5',
  'b8d4411e-55b1-7da9-3d4f-b5df32c8d7c9',
  'cf0085e8-032c-a2d6-6b7a-a0a6eddc0f4e',
  'c8c17e62-edba-0622-577e-04e87a822ca4',
  'efd40002-0f46-2d2f-ce94-c0497cf66608',
  '6daa3992-1a83-12b2-5d5f-f3612fd819ee',
  'f13deb35-f4c3-5ee6-933f-50f9d4a8ca04',
  '0d7c07ec-d20c-851a-c5ed-bea653045ac3',
  'ce48ff21-7838-a5c6-9e70-a9fb636a1fc7',
  '806ea6fa-444a-c005-8afc-58f433ef1433',
];

export const QUESTIONS_WITH_RECTANGLE_VIEW_LESS_THAN_FIVE_QUESTION: string[] = ['806ea6fa-444a-c005-8afc-58f433ef1433'];

export const QUESTIONS_WITH_FEW_ANSWER_CHOICES: string[] = ['c65c7c38-3662-4e81-9bbb-fb4b04cd2214'];

export const CUSTOM_MINCIFRY_QUESTIONS: string[] = [
  '93df9fad-a99b-a8d4-c47e-7286c6ea4dcd',
  '3fc10ebf-f7b0-35b6-fd75-e12297bbf4f0',
  'b9e5c283-375d-d187-6c0e-f17813f9aef4',
  'b690e6a0-a433-36ae-f5a8-d27f31e89112',
  'efd1c6dc-72eb-c0ab-d05f-74b4a224fbcd',
  'ca641f85-6076-06d3-3e87-a8019720b5b9',
  '507247e0-e34a-2300-703e-5c046d827000',
  '694f5d42-c884-54c4-d7ee-40240d728de4',
  '5e4c14e4-b1aa-201c-3cd7-7ff0d1a99287',
  '43896010-1014-c541-c773-7c6bd1ed6149',
  'e7e744eb-c11d-6076-eb15-1740d11dc3e8',
  '7f2916e4-6ced-5096-6852-8ad8e5d49668',
  '86f8ae47-6f72-0403-cc3c-7307d64dc4ad',
  '0b5b3713-1292-5b68-373b-05816d7cc3ed',
  '4e44e5f6-dfe5-37af-d7a1-d530f5185415',
  '3a36ee5f-b4f4-f7a1-7963-02984377aef5',
];

// toDo: новая версия компонентов 'rectangle view' пока использована только в 45 - 47 вопросах теста PROD-1006,
// нужно будет изменить привязку на объект rectangleView с бекенда.
// Если значение больше нуля, то количество выбранных ответов ограничено.
// Нужно добавить возможность установки ограничений в тест едиторе.
export const QUESTIONS_WITH_RECTANGLE_VIEW_FROM_UI_KIT: Record<string, number> = {
  'ce48ff21-7838-a5c6-9e70-a9fb636a1fc7': 0,
  '806ea6fa-444a-c005-8afc-58f433ef1433': 0,
  '1f34c7eb-9606-f501-d31e-c06d7988ecd7': 3,
};
