import dayjs, { Dayjs, ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utcTime from 'dayjs/plugin/utc';
import isSameOrBeforeCond from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfterCond from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import isBetweenCond from 'dayjs/plugin/isBetween';
import ru from 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Helper } from '@profilum-helpers/common-helper/helper';

dayjs.extend(timezone);
dayjs.extend(utcTime);
dayjs.extend(duration);
dayjs.extend(isSameOrBeforeCond);
dayjs.extend(isSameOrAfterCond);
dayjs.extend(isBetweenCond);
dayjs.extend(customParseFormat);
dayjs.locale(ru);

export abstract class DateHelper {
  public static get dayjs(): typeof dayjs {
    return dayjs;
  }

  public static toDayJs(date: string | Date | Dayjs, format?: string, timeZone?: string): Dayjs {
    return Helper.isString(timeZone, true) ? dayjs.tz(date, format ?? '', timeZone) : dayjs(date, format);
  }

  public static toDayJsInUTC(date: string | Date | Dayjs, format?: string): Dayjs {
    return DateHelper.dayjs.utc(date, format);
  }

  public static cloneDayJsObject(date: Dayjs): Dayjs {
    return date.clone();
  }

  public static getTimestamp(): number {
    return dayjs(new Date()).millisecond().valueOf();
  }

  public static isSameOrBefore(date1: Dayjs, date2: Dayjs, unit?: OpUnitType): boolean {
    return date1.isSameOrBefore(date2, unit);
  }

  public static isSameOrAfter(date1: Dayjs, date2: Dayjs, unit?: OpUnitType): boolean {
    return date1.isSameOrAfter(date2, unit);
  }

  public static isBetween(startDate: Dayjs, endDate: Dayjs, dateBetween: Dayjs, unit?: OpUnitType): boolean {
    return dateBetween.isBetween(startDate, endDate, unit);
  }

  public static isAfter(date1: Dayjs, date2: Dayjs, unit?: OpUnitType): boolean {
    return date1.isAfter(date2, unit);
  }

  public static isBefore(date1: Dayjs, date2: Dayjs, unit?: OpUnitType): boolean {
    return date1.isBefore(date2, unit);
  }

  public static sortDescFunc(date1: Dayjs, date2: Dayjs, unit?: QUnitType | OpUnitType): number {
    return date2.diff(date1, unit);
  }

  public static sortAscFunc(date1: Dayjs, date2: Dayjs, unit?: QUnitType | OpUnitType): number {
    return date1.diff(date2, unit);
  }

  public static sortDates(dates: Dayjs[], sorting: 'desc' | 'asc', unit?: QUnitType | OpUnitType): Dayjs[] {
    if (!Helper.isArray(dates)) {
      return [];
    }

    return sorting === 'asc'
      ? dates.sort((date1: Dayjs, date2: Dayjs) => DateHelper.sortAscFunc(date1, date2, unit))
      : dates.sort((date1: Dayjs, date2: Dayjs) => DateHelper.sortDescFunc(date1, date2, unit));
  }

  public static convertTo24Hours(time: string, leadingZero: boolean = true): string {
    const h = time.match(/^(\d+)/)?.[1];
    const m = time.match(/:(\d+)/)?.[1];
    let hours: number = h ? Number(h) : 0;
    const minutes: number = m ? Number(m) : 0;
    const AMPM: string | undefined = time.match(/\s(.*)$/)?.[1];

    if (AMPM == 'PM' && hours < 12) hours = hours + 12;
    if (AMPM == 'AM' && hours == 12) hours = hours - 12;

    let sHours: string = hours.toString();
    let sMinutes: string = minutes.toString();

    if (leadingZero) {
      if (hours < 10) sHours = `0${sHours}`;
      if (minutes < 10) sMinutes = `0${sMinutes}`;
    }

    return `${sHours}:${sMinutes}`;
  }

  public static addTimeToDate(date: Dayjs, time?: string): Dayjs {
    try {
      if (date.isValid()) {
        const timeStr: string = time && /\b(1[0-2]|0?[1-9]):([0-5][0-9])( [AaPp][Mm])?/.test(time) ? time : '12:00:00 AM';
        const timeIn24Format: string = /\s(.*)$/.test(timeStr) ? DateHelper.convertTo24Hours(timeStr) : timeStr;
        const arr: string[] = timeIn24Format.split(':');
        const hours: number = Helper.parseToNumber(arr[0]);
        const minutes: number = Helper.parseToNumber(arr[1]);

        return date.set('hour', hours).set('minute', minutes).set('second', 0).set('millisecond', 0);
      }

      return date.clone();
    } catch (e) {
      console.error(e);
      return date.clone();
    }
  }

  public static parseTimeStringToObject(time: string): { hours: string; minutes: string } {
    const timeArr: string[] = DateHelper.convertTo24Hours(time.toUpperCase(), false).split(':');

    return {
      hours: timeArr[0],
      minutes: timeArr[1],
    };
  }

  public static validateDate(date: Date | Dayjs | string): boolean {
    return DateHelper.dayjs(date).isValid();
  }

  public static isInappropriateAge(dateValue: string): boolean {
    const date = DateHelper.toDayJs(dateValue, 'DD.MM.YYYY');
    const now = DateHelper.dayjs();
    const difference = DateHelper.sortDescFunc(date, now, 'year');

    const minimumAge = 11;
    const maximumAge = 18;

    return difference < minimumAge || difference > maximumAge;
  }

  public static getExpiresDateForCookie(value: number, unit: ManipulateType): string {
    return DateHelper.dayjs().add(value, unit).toString();
  }
}
