import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  ApiFieldsService,
  ApiSchoolsService,
  ApiSearchService,
  IFilterClasses,
  Stands,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import {
  InternshipsFiltersService,
} from '../../../../../internship-page/internship-catalog/internship-filter/internships-filter.service';

@Component({
  selector: 'prf-courses-filter-group',
  templateUrl: './courses-filter-group.component.html',
  styleUrls: ['./courses-filter-group.component.scss'],
})
export class CoursesFilterGroupComponent implements OnInit, OnDestroy {
  groups: any;
  filters: IFilterClasses;
  selectedCourses: any = [];
  searchCourse: string = '';
  selectedGroup: any = [];
  groupOpen: boolean = false;
  @ViewChild('groupFilter') groupFilter: ElementRef;
  @ViewChild('groupDrop') groupDrop: ElementRef;

  userRole: string;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private apiSchoolsService: ApiSchoolsService,
    private apiSearchService: ApiSearchService,
    private apiFieldsService: ApiFieldsService,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
    private webStorageService: WebStorageService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        mergeMap(data => {
          this.filters = data;
          this.selectedCourses = data.courses ? data.courses : [];
          return this.loadStatistic();
        }),
      )
      .subscribe();
  }

  private loadStatistic(): Observable<any> {
    let countStatistic$: Observable<any> = of(null);
    this.filters.stand = Stands.Talent;
    countStatistic$ = this.apiSchoolsService.getClassesCountStatistic(this.filters, this.userRole === 'admin');

    return forkJoin([this.apiSearchService.getTalentGroupCourse(), this.apiFieldsService.getGroups(), countStatistic$]).pipe(
      takeUntil(this.ngUnsubscribe$),
      tap(([talentGroupCourse, courseGroups, classesCountStatistic]) => {
        talentGroupCourse.map(({ talent: name, groups }) => ({ name, groups }));
        this.groups = courseGroups.fieldDOs.map(({ name }) => ({ name, courses: [] }));
        talentGroupCourse.forEach(({ groups = [] }) => {
          groups.forEach(({ group, courses = [] }) => {
            let courseGroup = this.groups.find(({ name }) => name === group);

            if (!courseGroup) {
              courseGroup = {
                name: group,
                courses: [],
              };
              this.groups.push(courseGroup);
            }

            courses.forEach(course => {
              if (!courseGroup.courses.find(item => item.name === course)) {
                const count = classesCountStatistic.coursesCount[course];
                courseGroup.courses.push({ name: course, count: count == null ? 0 : count });
              }
            });
          });
        });
        this.groups = this.groups.filter(({ courses }) => courses.length);
        this.groups.forEach(group => {
          group.courses.sort();
          group.courses = group.courses.map(item => ({ item }));
        });
      }),
    );
  }

  checkFilterHeader() {
    if (this.filters.courses && this.filters.courses.length > 0) {
      if (this.filters.courses.length > 1) {
        const courseTitles = ['направление', 'направления', 'направлений'];
        const n = this.filters.courses.length;
        const title = this.internshipsFiltersService.getTitleByNumber(n, courseTitles);
        return n + ' ' + title;
      } else {
        const course = this.selectedCourses.filter(el => el === this.filters.courses[0]);
        return course[0];
      }
    } else {
      return 'Направление';
    }
  }

  setCourseFilter(course: any) {
    if (!this.filters.courses) {
      this.filters.courses = [course.item.name];
    } else {
      const index = this.selectedCourses.indexOf(course.item.name);
      index !== -1 ? this.filters.courses.splice(index, 1) : this.filters.courses.push(course.item.name);
    }
    this.filtersService.setCoursesFilter(this.filters);
    this.checkGroupName();
  }

  deselectCourses() {
    this.filters.courses = null;
    this.selectedGroup = [];
    this.filtersService.setCoursesFilter(this.filters);
  }

  setGroupFilter(group: any) {
    if (!this.filters.courses) {
      this.selectedGroup = [];
    }
    const courses = group.courses.map(el => el.item.name);
    if (this.selectedGroup && this.selectedGroup.length > 0) {
      const index = this.selectedGroup.indexOf(group.name);
      if (index > -1) {
        this.selectedGroup.splice(index, 1);
        this.filters.courses = this.filters.courses.filter(el => courses.indexOf(el) === -1);
      } else {
        this.selectedGroup.push(group.name);
        this.filters.courses = this.filters.courses.concat(courses);
      }
    } else {
      this.selectedGroup = [group.name];
      this.filters.courses = courses;
    }
    this.checkGroupName();
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkGroupName() {
    this.selectedGroup = [];
    if (this.filters.courses && this.filters.courses.length > 0) {
      this.groups.forEach(group => {
        group.courses.forEach(course => {
          if (this.filters.courses.includes(course.item.name)) {
            if (this.selectedGroup && this.selectedGroup.length > 0) {
              this.selectedGroup.push(group.name);
            } else {
              this.selectedGroup = [group.name];
            }
          }
        });
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  closeOther(dropdown: any, icon: any) {
    dropdown.classList.toggle('w--open');
    const elements = this.groupFilter.nativeElement.querySelectorAll('.event-dd-second.w-dropdown-list.w--open');
    elements.forEach(el => (el !== dropdown ? el.classList.remove('w--open') : false));
    icon.classList.toggle('rotateUp');
    const icons = this.groupFilter.nativeElement.querySelectorAll('.event-category-dd-icon.w-icon-dropdown-toggle.rotateUp');
    icons.forEach(el => (el !== icon ? el.classList.remove('rotateUp') : false));
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.groupFilter && !this.groupFilter?.nativeElement.contains(target)) {
      this.groupOpen = false;
    }
  }
}
