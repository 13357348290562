import { ProfActionsComponent } from './prof-actions/prof-actions.component';
import { ProfNavigationComponent } from './prof-navigation/prof-navigation.component';
import { ProfCardComponent } from './prof-card/prof-card.component';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { PupilMenuBottomModule } from 'app/shared/common-components/pupil-menu-bottom/pupil-menu-bottom.module';
import { ChooseProfessionComponent } from './choose-profession.component';
import { ProfTabComponent } from './prof-tab/prof-tab.component';
import { NgOptimizedImage } from '@angular/common';
import {
  NotificationPopupComponent,
} from '../../shared/common-components/notification-popup/notification-popup.component';

@NgModule({
    imports: [SharedModule, RouterModule, PupilMenuBottomModule, NgOptimizedImage, NotificationPopupComponent],

  declarations: [ChooseProfessionComponent, ProfCardComponent, ProfNavigationComponent, ProfActionsComponent, ProfTabComponent],
  exports: [ProfActionsComponent],
})
export class ChooseProfessionModule {}
