<div class="admin-header-event-page" *ngIf="dataFetched && internship">
  <div class="admin-header-col">
    <div class="event-status-text ev-page" *ngIf="internship.startDate">
      Создано {{ internship.createdDate | date : 'dd MMMM yyyy hh:mm' }}
    </div>
    <div class="event-status-text ev-page">Ред {{ internship.updatedDate | date : 'dd MMMM yyyy hh:mm' }}</div>
  </div>
  <div class="edit-event-column event-page-edit">
    <div class="edit-event-col-2">
      <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
    </div>
    <div class="edit-event-col">
      <a [routerLink]="['/' + userRole + '/edit-internship', internship.hrid]" class="button edit-event-page-btn w-button">{{
        'SHARED.EDIT_BUTTON' | translate
      }}</a>
    </div>
  </div>
</div>

<div class="event-page-section" *ngIf="dataFetched && internship">
  <div class="event-page-header event-gradient-bg-2" [ngClass]="color">
    <div
      class="event-image-bg"
      [ngStyle]="{
        'background-image': internship.mainImagePath ? 'url(' + internship.mainImagePath + ')' : ''
      }"
    ></div>
    <div class="event-header-column _2">
      <div class="event-page-h1-div">
        <h1 class="event-page-h1">{{ internship.name }}</h1>
      </div>
      <div *ngIf="internship.link">
        <a href="{{ internship.link }}" target="_blank">{{ internship.link }}</a>
      </div>
    </div>
  </div>

  <div class="event-page-content">
    <div class="event-page-description-row">
      <div class="event-page-description-column">
        <h2 class="event-page-h2">Описание</h2>

        <div class="event-page-descr-row">
          <div *ngIf="internship.departamentName" class="event-page-descr-column">
            <h4 class="event-page-h4">Департамент</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">{{ internship.departamentName }}</div>
              </li>
            </ul>
          </div>

          <div *ngIf="format" class="event-page-descr-column">
            <h4 class="event-page-h4">Формат</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">{{ format }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.startDate && internship.closeDate" class="event-page-descr-column">
            <h4 class="event-page-h4">Даты проведения стажировки</h4>
            <div class="event-page-date">
              с {{ internship.startDate | date : 'dd MMM yyyy' }} по
              {{ internship.closeDate | date : 'dd MMM yyyy' }}
            </div>
          </div>

          <div *ngIf="internship.totalPlaces" class="event-page-descr-column">
            <h4 class="event-page-h4">Количество мест</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">{{ internship.totalPlaces }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.deadline" class="event-page-descr-column">
            <h4 class="event-page-h4">Дедлайн подачи заявки</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">До {{ internship.deadline | date : 'dd MMM yyyy' }}</div>
              </li>
            </ul>
          </div>

          <div *ngIf="internship.candidates" class="event-page-descr-column">
            <h4 class="event-page-h4">Кого готовы взять</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">{{ internship.candidates }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.duties">
            <h4 class="event-page-h4">Обязанности</h4>
            <ul class="event-description-list">
              <li>
                <div class="event-description-title">{{ internship.duties }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.description">
            <h4 class="event-page-h4">Описание</h4>
            <p class="event-page-description-text" innerHtml="{{ internship.description }}"></p>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.specialities && internship.specialities.length > 0">
            <h4 class="event-page-h4">Для каких специальностей</h4>
            <ul class="event-description-list">
              <li *ngFor="let speciality of internship.specialities">
                <div class="event-description-title">{{ speciality }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.talents && internship.talents.length > 0">
            <h4 class="event-page-h4">Для каких талантов</h4>
            <ul class="event-description-list">
              <li *ngFor="let talent of internship.talents">
                <div class="event-description-title">{{ talent }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.courses && internship.courses.length > 0">
            <h4 class="event-page-h4">Для каких тематик ДО</h4>
            <ul class="event-description-list">
              <li *ngFor="let course of internship.courses">
                <div class="event-description-title">{{ course }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="event-page-descr-row">
          <div *ngIf="internship.address">
            <h4 class="event-page-h4">Место проведения</h4>
            <p class="event-page-description-text" innerHtml="{{ internship.address }}"></p>
          </div>
        </div>
      </div>

      <div class="event-page-description-column _2">
        <h2 class="event-page-h2">Расписание стажировки</h2>
      </div>
    </div>

  </div>
</div>

<ng-template #isFavoriteCard>
  <a
    class="event-page-heart w-inline-block"
    (click)="removeFavorite()"
    [style.background-image]="'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    (mouseover)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-line-white.svg)'"
    (mouseleave)="heartActive.style.backgroundImage = 'url(/profilum-assets/images/icons/hearts/heart-white.svg)'"
    #heartActive
  ></a>
</ng-template>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./profilum-assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление стажировки</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить стажировку?</div>
          <a class="button attach-file-btn w-button" (click)="deleteInternship(internship.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
