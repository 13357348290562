import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { B2gSaasService } from '@profilum-library';

@Component({
  selector: 'prf-cityadmin',
  templateUrl: './cityadmin.component.html',
  styleUrls: ['./cityadmin.component.scss'],
})
export class CityAdminComponent implements OnInit {
  public _statisticFlag: boolean = false;

  public _cityStatistic: any = {
    classesCount: 0,
    pupilsCount: 0,
    schoolsCount: 0,
  };
  public _questions: any;

  constructor(private meta: Meta, private b2gSaasService: B2gSaasService) {
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет администратора города' });
  }

  public ngOnInit(): void {
    this.getStatistic();
  }

  // Parse data charts
  private getStatistic(): void {
    this.b2gSaasService
      .getCityStatistic()
      .pipe(take(1))
      .subscribe((response: any) => {
        this._cityStatistic.classesCount = response.classesCount;
        this._cityStatistic.pupilsCount = response.pupilsCount;
        this._cityStatistic.schoolsCount = response.schoolsCount;
        this._questions = response.questions;
      });
  }
}
