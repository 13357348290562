import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { AppSettingsService, ROUTE_ANIMATIONS_ELEMENTS } from '@profilum-library';

export const PAGE_SIZE: number = 1;

@Component({
  selector: 'prf-landing-mosobl',
  templateUrl: './landing-mosobl.component.html',
  styleUrls: ['./landing-mosobl.component.scss'],
})
export class LandingMosoblComponent implements AfterViewInit {
  public routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  public swiperConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    speed: 500,
    allowTouchMove: true,
    spaceBetween: 200,
  };
  public swiperPromoConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    navigation: {
      nextEl: '.swiper-promo-next',
      prevEl: '.swiper-promo-prev',
    },
    pagination: {
      el: '.swiper-promo-pagination',
      type: 'bullets',
      clickable: true,
    },
    spaceBetween: 200,
    allowTouchMove: true,
    speed: 600,
  };
  public swiperIndex: number = 0;
  public nowDate: Date = new Date();

  public urlForCompany: string = AppSettingsService.settings.anketaForOrganisations;

  @ViewChild('swiperComponent') swiperComponent?: ElementRef;

  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });
  }

  public ngAfterViewInit(): void {
    Object.assign(this.swiperComponent.nativeElement, this.swiperConfig);
    this.swiperComponent.nativeElement.initialize();
  }

  public swiperIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  public toggleOverlayControls(goto: number): void {
    this.swiperComponent.nativeElement.swiper.slideTo(goto);
    this.swiperIndexChange(goto);
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }
}
