<div class="event-page-section create" *ngIf="specialty">
  <div class="edit-event-row">
    <div class="edit-event-column">
      <h1 class="event-h1 edit">Редактирование</h1>
      <div class="event-status-block-2">
        <div class="event-status-text-2">
          {{ 'SHARED.CREATED_DUE_DATE' | translate : { createdDate: createdDate } }}
        </div>
      </div>
      <div class="edit-event-column _2">
        <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
      </div>
    </div>
  </div>

  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <h2 class="event-h2">Основная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row" [style.margin-bottom]="'100px'">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание*</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            [class.error-field]="submitted && f.description.errors"
            id="description"
            name="description"
            placeholder="Описание"
            formControlName="description"
          >
          </quill-editor>
          <ng-container *ngIf="submitted && f.description.errors">
            <mat-error *ngIf="f.description.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="govCode">GovCode*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <div class="info-popup" *ngIf="showInfoPopup">
              <p>Для указания govCode доступны только цифры</p>
              <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="govCode"
            data-name="govCode"
            [class.error-field]="submitted && f.govCode.errors"
            placeholder="govCode"
            id="govCode"
            formControlName="govCode"
          />
          <ng-container *ngIf="submitted && f.govCode.errors">
            <mat-error *ngIf="f.govCode.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="edit-event-buttons-row">
        <input type="submit" (click)="submit()" value="{{ 'SHARED.SAVE_CHANGES' | translate }}" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" routerLink="/admin/specialties">
          {{ 'SHARED.EXIT_WITHOUT_SAVE' | translate }}
        </a>
      </div>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>

  <ng-container *ngIf="popUpConfirming">
    <div class="perform-task-lb lb show">
      <div class="lb-bg"></div>
      <div class="lb-block task-lb">
        <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
          <img src="./profilum-assets/images/icons/close-lb.svg" />
        </a>
        <div class="tasl-lb-row">
          <div class="task-lb-column _2">
            <h4 class="task-lb-heading-result">Удаление специальности</h4>
            <div class="task-result-text">Вы уверены, что хотите удалить специальность?</div>
            <a class="button attach-file-btn w-button" (click)="deleteSpecialty(specialty.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
