import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApiUtilsService, B2gSaasService, INews } from '@profilum-library';

@Component({
  selector: 'prf-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss'],
})
export class AddNewsComponent implements OnInit {
  error: boolean = false;
  @Input() schools;
  _selectedFiles: FileList;
  news: INews = {
    imageURL: null,
    title: 'Заголовок',
    description: 'Описание',
    groupId: 'groupId',
    eventType: 'тип новости',
  };

  constructor(private apiUtilsService: ApiUtilsService, private b2gSaasService: B2gSaasService) {}

  public ngOnInit(): void {
    const globalNewsTarget = {
      id: null,
      value: 'Для всех',
      viewValue: 'Для всех',
    };
    this.schools.unshift(globalNewsTarget);
  }

  public changeSelected(data): void {
    this.news.groupId = this.schools.find(s => s.value == data).id;
    if (this.news.groupId == null) {
      this.news.eventType = 'GlobalNews';
    } else {
      this.news.eventType = 'SchoolNews';
    }
  }

  public selectFile(event): void {
    if (event.target.files.item(0).type.match('image.*')) {
      this._selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  public upload(): void {
    this.apiUtilsService
      .uploadImage(this._selectedFiles, this.news.title + 'news')
      .pipe(take(1))
      .subscribe(r => {
        this.news.imageURL = r;
      });
  }

  public updateUrl(): void {
    this.news.imageURL = '/staticfiles/content/images/default.png';
  }

  public onSubmit(valid: boolean): void {
    if (valid) {
      // Add news
      this.b2gSaasService.createNews(this.news);
    }
  }
}
