<div class="test-result-block">
  <h2 class="test-result-h1">
    {{ title }}
  </h2>
  <div>
    <p *ngIf="texts?.first" class="test-result-text _w-700">
      {{ texts.first }}
    </p>
    <p *ngIf="texts?.second" class="test-result-text _w-700">
      {{ texts.second }}
    </p>
    <p *ngIf="texts?.third" class="test-result-text test-open-result-text">
      {{ texts.third }}
    </p>
  </div>

  <div class="test-result-parameters">
    <prf-progress-bar
      *ngFor="let skillData of data"
      [title]="skillData.title"
      [progress]="doubleResults ? skillData.childEstimate : skillData.parentEstimate"
      [theme]="showParent && !doubleResults ? ProgressBarThemes.BLUE : ProgressBarThemes.GREEN"
      [isWithProblem]="doubleResults && skillData.problem"
      [additionalProgress]="doubleResults ? skillData.parentEstimate : null"
      [additionalProgressTheme]="doubleResults ? ProgressBarThemes.BLUE : ''"
    ></prf-progress-bar>
  </div>

  <div *ngIf="showPupil && showParent" class="diagram-row _2">
    <div class="diagram-column">
      <i class="diagram-color-oval"></i>
      <span>{{ mainProgressDescription }}</span>
    </div>
    <div class="diagram-column">
      <i class="diagram-color-oval blue"></i>
      <span>{{ additionalProgressDescription }}</span>
    </div>
  </div>
</div>
