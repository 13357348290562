import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UtilsService } from '../backend-services/utils.service';
import { ApiAdminsService, B2gSaasService, UserDataHandlerService } from '@profilum-library';

@Injectable()
export class GoalsService {
  public updateTrajectories$ = new Subject<boolean>();

  private staticTask: any = new BehaviorSubject(null);

  constructor(
    private utilsService: UtilsService,
    private b2gSaasService: B2gSaasService,
    private userDataHandlerService: UserDataHandlerService,
    private apiAdminsService: ApiAdminsService,
  ) {}

  getUserTrajectories(professionId: string): Observable<any> {
    if (this.userDataHandlerService.getUserInfo().role === 'schooladmin') {
      return this.apiAdminsService.getUserTrajectories(professionId);
    } else {
      return this.b2gSaasService.getUserTrajectories(professionId);
    }
  }

  showStaticTaskModal(staticTaskData: any) {
    this.staticTask.next(staticTaskData);
  }

  closeStaticTaskModal() {
    this.staticTask.next(null);
  }

  getStaticTaskModal(): Observable<any> {
    return this.staticTask.asObservable();
  }

  toUpdateTrajectories(toUpdate: boolean) {
    this.updateTrajectories$.next(toUpdate);
  }
  checkTrajectories() {
    return this.updateTrajectories$.asObservable();
  }
}
