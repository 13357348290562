<div class="select-test-type">
  <div class="select-test-type__item" [ngClass]="{ 'select-test-type__item--active': selected == 1 }" (click)="select(1)">
    <div class="select-test-type__pic">
      <img alt="" src="./profilum-assets/images/icons/slippers.png" class="" />
    </div>
    <div class="select-test-type__name">{{ 'PLAYER.ANKETA.TEST_TYPE.PERSONAL' | translate }}</div>
    <div class="select-test-type__text">
      {{ 'PLAYER.ANKETA.TEST_TYPE.PERSONAL_DESCRIPTION' | translate }}
    </div>
  </div>
  <div class="select-test-type__item" [ngClass]="{ 'select-test-type__item--active': selected == 2 }" (click)="select(2)">
    <div class="select-test-type__pic">
      <img alt="" src="./profilum-assets/images/icons/slippers-2.png" class="" />
    </div>
    <div class="select-test-type__name">{{ 'PLAYER.ANKETA.TEST_TYPE.SCREEN360' | translate }}</div>
    <div class="select-test-type__text">
      {{ 'PLAYER.ANKETA.TEST_TYPE.SCREEN360_DESCRIPTION' | translate }}
    </div>
  </div>
  <div class="select-test-type__item" [ngClass]="{ 'select-test-type__item--active': selected == 3 }" (click)="select(3)">
    <div class="select-test-type__pic">
      <img alt="" src="./profilum-assets/images/icons/slippers.png" class="" />
    </div>
    <div class="select-test-type__name">{{ 'PLAYER.ANKETA.TEST_TYPE.SPECIALIST' | translate }}</div>
    <div class="select-test-type__text">
      {{ 'PLAYER.ANKETA.TEST_TYPE.SPECIALIST_DESCRIPTION' | translate }}
    </div>
  </div>
</div>
