<div class="slide-number">{{ slideNum }} {{ 'COMMON.OF' | translate }} {{ slidesNumber }}</div>
<div class="text-container">
  <h1 class="title bvb-title">{{ data.title }}</h1>
  <p class="descr descr-visually-impaired">{{ data.description }}</p>
</div>
<div
  class="bvb-bubble-box-container bvb-box-with-circles"
  [ngClass]="{
    'bvb-box-with-circles': isCircle,
    'bvb-slide-positive': sectionType === 'Positive',
    'bvb-slide-negative': sectionType === 'Negative'
  }"
>
  <div *ngFor="let element of data.elements" [class.bvb-answer-container]="isCircle">
    <div
      *ngIf="{
        positive: element.sectionId && this.sectionId !== element.sectionId && element.sectionType === 'Positive',
        negative: element.sectionId && this.sectionId !== element.sectionId && element.sectionType === 'Negative'
      } as alreadyChosen"
      [id]="element.answerId"
      (click)="selectAnswer(element)"
      class="bvb-leaf"
      [class.bvb-circle]="isCircle"
      [class.bvb-checkboxes]="isRectangle"
      [class.bvb-selected]="element.sectionId && this.sectionId === element.sectionId"
      [class.bvb-selected-positive]="alreadyChosen.positive"
      [class.bvb-selected-negative]="alreadyChosen.negative"
    >
      <img *ngIf="element.answerType === 'Image'" class="bvb-answer-picture" src="./assets/images/answers/{{ element.text }}" />
      <p
        *ngIf="element.answerType !== 'Open' && element.answerType !== 'Image' && !alreadyChosen.positive && !alreadyChosen.negative"
        [innerHTML]="element.text"
      ></p>
    </div>
  </div>
</div>
