import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorRoutingModule } from './tutor-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { TutorPanelComponent } from './tutor-panel.component';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';
import { TutorPupilsComponent } from './tutor-pupils/tutor-pupils.component';

@NgModule({
  declarations: [TutorPanelComponent, TutorPupilsComponent],
  imports: [CommonModule, TutorRoutingModule, SharedModule, DashboardModule],
})
export class TutorModule {}
