export enum BadgeThemes {
  BLUE = 'blue-theme',
  YELLOW = 'yellow-theme',
  GREEN = 'green-theme',
}

export enum ClassCardTags {
  DEFAULT = 'Россия — мои горизонты',
  KG = 'Карьерная грамотность',
  DIAGNOSTIC = 'Диагностика',
}

export enum LABELS_ENUM {
  CLASS = 'класс',
  CLOSER_LESSON = 'Ближайшее занятие:',
  BUTTON = 'Открыть',
  TOOLTIP = 'Рекомендуемая дата проведения урока',
}
