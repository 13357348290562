import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrfNavSpecial } from '../../../components/header/header.types';
import { YandexMetricsService } from '@profilum-library';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'prf-header-login-link',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header-login-link.component.html',
  styleUrl: './header-login-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLoginLinkComponent {
  @Input() specialNavItem: PrfNavSpecial;

  private yandexMetricsService = inject(YandexMetricsService);

  public onClickLoginLink(ymGoal: string) {
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
