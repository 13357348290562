import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'prf-tutor-panel',
  templateUrl: './tutor-panel.component.html',
  styleUrls: ['./tutor-panel.component.scss'],
})
export class TutorPanelComponent implements OnInit {
  public title: string = '';
  public currentUrl: string;

  constructor(private meta: Meta, private router: Router) {
    this.meta.updateTag({
      name: 'og:title',
      content: 'Страница тьютора',
    });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.checkTitle(event.url);
      }
    });
  }

  ngOnInit() {}

  checkTitle(url) {
    if (url == '/tutor') {
      this.title = 'Главная';
    }
  }
}
