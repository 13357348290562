import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { B2gSaasService } from '@profilum-library';

@Component({
  selector: 'prf-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input('matBadgeHidden') _hidden: boolean = true;
  _notifications$: any;
  _notificationsLength: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private b2gSaasService: B2gSaasService) {}

  ngOnInit() {
    this._notifications$ = this.b2gSaasService
      .getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(notifications => {
        this._notificationsLength = notifications.length;
        if (this._notificationsLength > 0) {
          this._hidden = false;
        }
      });
  }

  updateNotification() {
    this._notificationsLength = 0;
    this._hidden = true;
  }

  mouseEnter(notification: string) {
    let markNotification = {
      messageId: notification,
      isRead: true,
    };
    this.b2gSaasService.markNotification(markNotification);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
