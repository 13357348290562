import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicLabelComponent } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.component';

@NgModule({
  declarations: [DynamicLabelComponent],
  imports: [CommonModule],
  exports: [DynamicLabelComponent],
})
export class DynamicLabelModule {}
