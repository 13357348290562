import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { combineLatest, iif, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAdminsService,
  ApiCoursesMaterialsService,
  ApiTemporaryService,
  AppSettingsService,
  B2gSaasService,
  EmptyGuid,
  IAddUserRequest,
  IAddUserResponse,
  IGetCourseByClassResponse,
  ISchool,
  ISchoolClass,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { ICourseMaterialExt } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { IMappedCourse } from '../schooladmin-courses.interface';

@Component({
  selector: 'prf-schooladmin-edit-class',
  templateUrl: './schooladmin-edit-class.component.html',
  styleUrls: ['./schooladmin-edit-class.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchooladminEditClassComponent extends UnsubscribeComponent implements OnInit {
  @Output() showEditClass = new EventEmitter();
  @Input() teacherClass: ISchoolClass;
  @Input() teacher: ITeacher;
  public selectedTeacher: ITeacher;
  public form: UntypedFormGroup;
  public teachers: ITeacher[] = [];
  public showTeachersList: boolean = false;
  public addNewTeacher: boolean = false;
  public letterLabel: boolean = false;
  public firstNameLabel: boolean = false;
  public lastNameLabel: boolean = false;
  public middleNameLabel: boolean = false;
  public selectedTeacherId: string = '';
  public isProcessing: boolean = false;

  public dropdownNumberOpen: boolean = false;
  public dropdownCourseOpen: boolean = false;
  public courses: ICourseMaterialExt[];
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  public mappedCourses: IMappedCourse[] = [];
  public allowedCoursesToClass: IMappedCourse[] = [];

  private submitted: boolean;
  private errors: number[] = [];
  private school: ISchool;
  private selectedCourseId: string = '';

  private regionId: string;
  private stringClassNumber = {
    5: 'fifthClass',
    6: 'sixthClass',
    7: 'seventhClass',
    8: 'eighthClass',
    9: 'ninthClass',
    10: 'tenthClass',
    11: 'eleventhClass',
  };

  @ViewChild('numberFilter') public readonly numberFilter: ElementRef;
  @ViewChild('numberDrop') public readonly numberDrop: ElementRef;
  @ViewChild('teacherDrop') public teacherDrop: ElementRef;
  @ViewChild('courseDrop') public courseDrop: ElementRef;

  constructor(
    private meta: Meta,
    private apiAdminsService: ApiAdminsService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private appSettingsService: AppSettingsService,
    private apiTemporaryService: ApiTemporaryService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private overlayService: OverlayBusyService,
    private webStorageService: WebStorageService,
    private b2gSaasService: B2gSaasService,
  ) {
    super();
    this.getTranslation('SHARED.EDITING_CLASS')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  ngOnInit(): void {
    this.setUpdateClassControls();
    this.overlayService.show();
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
    forkJoin([
      this.apiCoursesMaterialsService.getAllCourseMaterials(),
      this.apiAdminsService.getRegionCoursesByRegionId(this.regionId),
      this.getSchoolAndTeachers(),
    ])
      .pipe(
        switchMap(([courses, courseRegion]) => {
          this.mappedCourses = courseRegion.map(regionCourse => {
            const assignedCourse = courses.courseMaterials.find(course => course.id === regionCourse.courseId);
            return {
              name: assignedCourse.name,
              ...regionCourse,
            };
          });
          return combineLatest([of(courses), this.getCourseId()]);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        ([courses, courseId]) => {
          if (courseId?.length) {
            this.courses = courses.courseMaterials;

            const assignedCourse = this.courses.find(course => course.id === courseId);
            this.selectAllowedCourseToClass(Number(this.teacherClass.number));

            if (assignedCourse) {
              this.setCourse(assignedCourse);
            }
          }
          this.overlayService.hide();
        },
        () => {
          this.overlayService.hide();
        },
      );
  }

  private removeWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  private failWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public animateSubmit(): void {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  private setUpdateClassControls(): void {
    this.form = this.fb.group({
      number: new UntypedFormControl(this.teacherClass.number.toString(), [Validators.required]),
      letter: new UntypedFormControl(this.teacherClass.letter, [Validators.required, Validators.pattern('^[А-Яа-яЁё0-9]+$')]),
      firstName: new UntypedFormControl(this.teacher && this.teacher.firstName ? this.teacher.firstName : null, [Validators.required]),
      lastName: new UntypedFormControl(this.teacher && this.teacher.lastName ? this.teacher.lastName : null, [Validators.required]),
      middleName: new UntypedFormControl(this.teacher && this.teacher.middleName ? this.teacher.middleName : null, [Validators.required]),
      fullName: new UntypedFormControl(this.concatFullName()),
      course: new UntypedFormControl('', []),
    });
  }

  private concatFullName(): string | null {
    if (!this.teacher) {
      return null;
    }

    return `${this.teacher.lastName} ${this.teacher.firstName} ${this.teacher.middleName}`;
  }

  public hideEdit(isUpdated): void {
    this.showEditClass.emit(isUpdated);
  }

  public submit(): void {
    this.submitted = true;

    if (this.form.valid && !this.isProcessing) {
      this.isProcessing = true;
      this.apiAdminsService
        .getSchool()
        .pipe(
          switchMap(school => {
            this.school = school;
            // Обновляем информацию о школе
            return this.updateClassInfo();
          }),
          switchMap(() => {
            return iif(
              () => !!(this.form.value.course && this.selectedCourseId),
              this.apiCoursesMaterialsService.updateCourseForClass({
                courseActivity: {
                  schoolClassId: this.teacherClass.id,
                  courseId: this.selectedCourseId,
                },
              }),
              of(null),
            );
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(() => {
          this.isProcessing = false;

          if (!this.errors.length) {
            this.removeWaiting();
            this.hideEdit(false);
          } else {
            this.failWaiting();
          }
        });
    } else {
      this.failWaiting();
      return;
    }
  }

  private selectAllowedCourseToClass(numberClass: number): void {
    this.allowedCoursesToClass = this.mappedCourses.filter(course => course[this.stringClassNumber[numberClass]]);
    this.allowedCoursesToClass.push({ name: 'Диагностика' });
  }

  public setCourse(course: IMappedCourse): void {
    this.f.course.setValue(course.name);
    this.selectedCourseId = course.courseId ? course.courseId : null;
  }

  get f() {
    return this.form.controls;
  }

  private getCourseId(): Observable<string> {
    this.overlayService.show();
    return this.apiCoursesMaterialsService.getCourseByClass(this.teacherClass.id).pipe(
      map((course: IGetCourseByClassResponse) => course.courseIds[0]),
      catchError(err => {
        this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        return throwError(err);
      }),
    );
  }

  private fieldsNotEmpty(): boolean {
    return (
      this.addNewTeacher && this.form.value.firstName !== null && this.form.value.lastName !== null && this.form.value.middleName !== null
    );
  }

  private updateClassInfo(): Observable<any> {
    this.selectedTeacherId = this.selectedTeacherId
      ? this.selectedTeacherId
      : this.selectedTeacher
      ? this.selectedTeacher.teacherId ?? this.selectedTeacher.userId
      : null;

    const updateClass = {
      id: this.teacherClass.id,
      teacherId: this.selectedTeacherId ? this.selectedTeacherId : EmptyGuid,
      letter: this.form.value.letter.toUpperCase(),
      number: this.form.value.number,
      schoolId: this.school.id,
      pupilsCount: this.teacherClass.pupilsCount,
    };

    let currentObservable$: Observable<any> = of(null);

    if (!this.teacher || this.addNewTeacher) {
      if (this.fieldsNotEmpty()) {
        // если в классе нет учителя и поля для него заполнены, создаем нового
        currentObservable$ = this.createNewTeacher(this.teacherClass.id).pipe(
          tap(r => {
            updateClass.teacherId = r.userId;
          }),
        );
      }
    }

    return currentObservable$.pipe(
      switchMap(() =>
        this.apiAdminsService.updateSchoolClass(updateClass).pipe(
          switchMap(() => this.apiTemporaryService.refreshSchoolClass(this.teacherClass.id, updateClass.schoolId)),
          catchError(err => {
            this.utilsService.openSnackBar(ServerErrorMessage, 'error');
            return throwError(err);
          }),
        ),
      ),
    );
  }

  private createNewTeacher(schoolClassId: string): Observable<IAddUserResponse> {
    const createNewTeacher: IAddUserRequest = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      middleName: this.form.value.middleName,
      schoolClassIds: schoolClassId ? [schoolClassId] : [],
      role: 'teacher',
    };
    return this.b2gSaasService.addUser(createNewTeacher).pipe(
      catchError((err: any) => {
        this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        return throwError(err);
      }),
    );
  }

  public selectTeacher(teacher: ITeacher): void {
    this.f.fullName.setValue(`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`);
    this.f.lastName.setValue(teacher.lastName);
    this.f.firstName.setValue(teacher.firstName);
    this.f.middleName.setValue(teacher.middleName);
    this.selectedTeacherId = teacher.teacherId ?? teacher.userId;

    this.showTeachersList = !this.showTeachersList;
  }

  public isAccessAllowed(): boolean {
    return this.f.number.value && this.f.letter.value;
  }

  public getSchoolAndTeachers(): Observable<any> {
    return this.b2gSaasService.getSchool().pipe(
      switchMap(school => {
        this.school = school;
        return this.apiAdminsService.getTeachers(school.id).pipe(
          tap(teachers => {
            this.teachers = teachers;

            if (this.teacher) {
              this.selectedTeacher = this.teachers.find(teacher => teacher.userId === this.teacher.userId);
            }
          }),
        );
      }),
    );
  }

  public toggleInputTeacher(): void {
    this.addNewTeacher = !this.addNewTeacher;
  }

  public toggleOpen(target): void {
    if (this.numberFilter?.nativeElement.contains(target)) {
      this.dropdownNumberOpen = !this.dropdownNumberOpen;
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target): void {
    if (this.numberDrop && !this.numberFilter?.nativeElement.contains(target)) {
      this.numberDrop.nativeElement.classList.remove('w--open');
      this.dropdownNumberOpen = false;
    }

    if (this.showTeachersList && !this.teacherDrop.nativeElement.contains(target)) {
      this.showTeachersList = false;
    }

    if (this.dropdownCourseOpen && !this.courseDrop.nativeElement.contains(target)) {
      this.dropdownCourseOpen = false;
    }
  }

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
