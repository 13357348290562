import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationBase } from 'app/shared/auth-classes/registration.base';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from 'app/shared/pipes/filter-schools.pipe';
import { takeUntil, tap } from 'rxjs/operators';
import { FormErrorMessage, ServerErrorMessage } from 'app/shared/global-constants/constants';
import {
  ApiLocationsService,
  ApiSchoolsService,
  ApiUsersService,
  AppSettingsService,
  B2gSaasService,
  Helper,
  IUserInfo,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
} from '@profilum-library';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-update-user-info',
  templateUrl: './update-user-info.component.html',
  styleUrls: ['./update-user-info.component.scss'],
})
export class UpdateUserInfoComponent extends RegistrationBase<B2gSaasService> {
  public userData: IUserInfo;
  public personalTerms: boolean = true;
  dataFetched: boolean = false;

  constructor(
    registrationService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    b2gSaasService: B2gSaasService,
    apiLocationsService: ApiLocationsService,
    utilsService: UtilsService,
    userDataHandlerService: UserDataHandlerService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
    private apiUsersService: ApiUsersService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      registrationService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
      utilsService,
    );
    this.userData = this.userDataHandlerService.getUserInfo();
  }

  async ngOnInit() {
    this.defaultRegion = AppSettingsService.settings.regionId;
    await super.ngOnInit();
    await this.getUserData();
    this.setUserDataValues();
    this.dataFetched = true;
  }

  public updateUserData() {
    this.submitted = true;
    if (this.form.valid) {
      const schoolId: string = this.f.school.value.data.id;

      let classLetter: string;
      let classNumber: number;

      if (this.f.schoolClass.value == null) {
        classNumber = this.f.schoolClassNumber.value.name;
        classLetter = this.f.schoolClassLetter.value.name;
      } else {
        const schoolClassInfo = this.f.schoolClass.value ? Helper.parseSchoolClassName(this.f.schoolClass.value.name) : null;

        if (schoolClassInfo) {
          classLetter = schoolClassInfo.classLetter;
          classNumber = schoolClassInfo.classNumber;
        }
      }

      this.apiUsersService
        .softSchoolClassChangePupils(this.userData.userId, schoolId, classNumber, classLetter)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
            return this.router.navigateByUrl('/pupil');
          } else {
            this.utilsService.openSnackBar(ServerErrorMessage, 'error');
            this.failWaiting();
          }
        });
    } else {
      this.utilsService.openSnackBar(FormErrorMessage, 'error');

      this.failWaiting();
    }
  }

  public animateLogin(): void {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.updateUserData();
  }

  private getUserData(): Promise<any> {
    return this.b2gSaasService
      .getUserInfo()
      .pipe(
        tap((ui: IUserInfo) => {
          this.userData = ui;
        }),
      )
      .toPromise();
  }

  private setUserDataValues(): void {
    // check the  equation!
    const isValidDate =
      this.userData.birthday && DateHelper.toDayJs(this.userData.birthday).isSame(DateHelper.toDayJs('0001-01-01T00:00:00')); // в таком формте приходит отсутствие даты

    const birthday = isValidDate ? DateHelper.toDayJs(this.userData.birthday).format('DD/MM/YYYY') : null;

    const region = this.regions.find(region => region.id === this.userData.regionId);
    const school = this.schools.find(school => school.id === this.userData.schoolId);
    const city = this.cities.find(city => city.name === this.userData.city);

    // запрос класов по школе
    if (school && school.id) {
      this.getSchoolClassBySchool(school.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassLetter.reset();
        });
    }

    this.form = this.fb.group({
      lastName: new UntypedFormControl({ value: this.userData.lastName, disabled: true }, [Validators.required]),
      firstName: new UntypedFormControl({ value: this.userData.firstName, disabled: true }, [Validators.required]),
      middleName: new UntypedFormControl({ value: this.userData.middleName, disabled: true }),
      date: new UntypedFormControl({ value: isValidDate ? birthday : null, disabled: isValidDate }, [
        Validators.required,
        Validators.pattern(this.dateRegExp),
      ]),
      email: new UntypedFormControl({ value: this.userData.email, disabled: !!this.userData.email }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      region: new UntypedFormControl({ value: region ? { name: region.name, data: region } : null, disabled: !!region }, [
        Validators.required,
      ]),
      city: new UntypedFormControl({
        value: city ? { name: city.name, data: city } : null,
        disabled: !!city,
      }),
      school: new UntypedFormControl(
        {
          value: school ? { name: school.viewValue, data: school } : null,
          disabled: !!school,
        },
        [Validators.required],
      ),
      schoolClass: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl(
        {
          value: this.userData.gender && ['M', 'F'].indexOf(this.userData.gender) > -1 ? this.userData.gender : null,
          disabled: this.userData.gender && ['M', 'F'].indexOf(this.userData.gender) > -1,
        },
        [Validators.required],
      ),
    });

    //нужно, чтобы подтянулись города по муниципалитету
    this.f.region.value && !this.f.city.value ? this.onRegionChange() : null;

    // нужно, чтобы подтянулись школы по городу
    this.f.city.value && !this.f.school.value ? this.onCityChange() : null;

    // вызываем проверку возраста
    this.checkFormatDate(this.f.date.value);
  }

  onRegionChange() {
    this.f.city.reset();
    this.f.city.disabled ? this.f.city.enable() : null;
    this.onCityChange();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }
  onCityChange() {
    this.f.school.reset();
    this.f.school.disabled ? this.f.school.enable() : null;
    this.onSchoolChange();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  get isAccessAllowed() {
    return this.form.valid && this.isClassSelected;
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }
}
