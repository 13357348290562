<footer id="prfFooter" class="prf-footer">
  <div class="prf-footer-content">
    <div class="prf-footer-top">
      <prf-footer-logo [logo]="footerData.logo" />
      <prf-scroll-to-top-button />
    </div>

    <div class="prf-footer-middle">
      <prf-footer-contacts [title]="footerData.contacts.title" [phone]="footerData.contacts.phone" [ymGoal]="footerData.contacts.ymGoalForPhone" />
      <prf-footer-legal [legals]="footerData.legals" />
      <prf-footer-map class="prf-footer-map" [location]="footerData.location" />
    </div>
    <div class="prf-footer-bottom">
      <prf-footer-socials [socials]="footerData.socials" />
      <a
        [href]="'mailto:' + footerData.contacts.email"
        class="prf-footer-email"
        (click)="onClickFooterLink(footerData.contacts.ymGoalForEmail)"
        >{{ footerData.contacts.email }}</a
      >
      <a class="prf-footer-login" [routerLink]="footerData.login.href" (click)="onClickFooterLink(footerData.login.ymGoal)">
        {{ footerData.login.linkText }}</a
      >
    </div>
  </div>
</footer>
