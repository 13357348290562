import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const directorRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(directorRoutes)],
  exports: [RouterModule],
})
export class DirectorRoutingModule {}
