import { Routes } from '@angular/router';
import { SlideService } from 'app/shared/services/slide.service';
import { ConfirmEmailComponent } from './catalog/settings-page/confirm-email/confirm-email.component';
import { SendLinkComponent } from './catalog/settings-page/send-link/send-link.component';
import { AnketaComponent } from './player/anketa/anketa.component';
import { PageResultComponent } from './results/result-page/page-result.component';
import {
  ResultsProfessionPrestigeComponent,
} from './results/results-profession-prestige/results-profession-prestige.component';
import { ResultsSwitcherComponent } from './results/results-switcher/results-switcher.component';
import { PaymentFormComponent } from '../shared/dashboard/order/payment-form/payment-form.component';
import { ForParentResultsComponent } from './results/for-parent-results/forparentresults.component';
import { PromotestResultsComponent } from './results/promotest-results/promotest-results.component';
import { AllEventsPromotestComponent } from 'app/shared/dashboard/all-events-promotest/all-events-promotest.component';
import { PromoActionPageComponent } from 'app/shared/dashboard/promo-action-page/promo-action-page.component';
import { MotivationResultsPageComponent } from './results/motivation-results-page/motivation-results-page.component';
import {
  PromotestCourseDetailsComponent,
} from 'app/shared/dashboard/courses/promotest-courses/promotest-course-details/promotest-course-details.component';
import { ValuesResultsPageComponent } from './results/values-results-page/values-results-page.component';
import { ResultsOpenComponent } from './results/results-open/results-open.component';
import { TaskPageComponent } from './task-page/task-page.component';
import {
  MincifrydemotestResultsComponent,
} from 'app/pages/results/mincifrydemotest-results/mincifrydemotest-results.component';
import { AnketaBvbComponent } from './player/anketa-bvb/anketa-bvb.component';
import { ChooseProfessionComponent } from './choose-profession/choose-profession.component';
import { ProfessionsResolver } from 'app/shared/services/professions-resolver.service';
import {
  AnketaBvbVisuallyImpairedComponent,
} from 'app/pages/player/anketa-bvb-visually-impaired/anketa-bvb-visually-impaired.component';
import { AuthRoleGuard, BvbGuard, CanDeactivateGuard, MosruGuard, StartMincifryTestGuard } from '@profilum-library';
import { PlayerV2Component } from '@profilum-components/prf-tests/player/default/player-v2.component';

export const routeConfig: Routes = [
  {
    path: 'test-na-professiyu',
    component: AnketaComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'motivation-test',
    component: AnketaComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'test-na-professiyu/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'career-test/:ssid',
    loadChildren: () => import('app/pages/player-career-test/player-career-test.module').then(m => m.PlayerCareerTestModule),
  },
  {
    path: 'career-results/:ssid',
    loadChildren: () => import('app/pages/results/career-test-results/career-test-results.module').then(m => m.CareerTestResultsModule),
  },
  {
    path: 'career-results-with-cards',
    loadChildren: () =>
      import('app/pages/results/career-test-results-with-cards/career-test-results-with-cards.module').then(
        m => m.CareerTestResultsWithCardsModule,
      ),
  },
  {
    path: 'motivation-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'values-test',
    component: AnketaComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'values-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'choose-profession',
    resolve: {
      professions: ProfessionsResolver,
    },
    component: ChooseProfessionComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'mosrupromo-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'vorobievi-gori/mosrupromo-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'results/:ssid',
    component: PageResultComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher'] },
  },
  {
    path: 'results-switcher/:ssid',
    component: ResultsSwitcherComponent,
  },
  {
    path: 'forparentresults/:ssid',
    component: ForParentResultsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['director'] },
  },
  { path: 'results-open', component: ResultsOpenComponent },
  {
    path: 'promotest-results/:ssid',
    component: PromotestResultsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'promotest-results',
    component: PromotestResultsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'vorobievi-gori/promotest-results/:ssid',
    component: PromotestResultsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'vorobievi-gori/promotest-results',
    component: PromotestResultsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'partners-courses-all',
    component: AllEventsPromotestComponent,
    canActivate: [MosruGuard],
  },
  {
    path: 'vorobievi-gori/partners-courses-all',
    component: AllEventsPromotestComponent,
  },
  {
    path: 'vorobievi-gori/partners-courses-all/:hrid',
    component: PromotestCourseDetailsComponent,
  },
  { path: 'mosruabout', component: PromoActionPageComponent, canActivate: [MosruGuard] },
  {
    path: 'results-profession-prestige',
    component: ResultsProfessionPrestigeComponent,
  },
  {
    path: 'motivation-test-results/:ssid',
    component: MotivationResultsPageComponent,
  },
  {
    path: 'values-test-results/:ssid',
    component: ValuesResultsPageComponent,
  },
  {
    path: 'values-test-results',
    component: ValuesResultsPageComponent,
  },
  {
    path: 'order/payment',
    component: PaymentFormComponent,
  },
  {
    path: 'emailconfirmation',
    component: ConfirmEmailComponent,
  },
  {
    path: 'sendconfirmationlink',
    component: SendLinkComponent,
  },
  {
    path: 'qr_tasks/:fieldName/:authorName/:year/:class/:chapter/:paragraph',
    component: TaskPageComponent,
  },
  {
    path: 'bvb-test-visually-impaired',
    component: AnketaBvbVisuallyImpairedComponent,
    canActivate: [BvbGuard],
    pathMatch: 'full',
  },
  {
    path: 'bvb-test-visually-impaired/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
    pathMatch: 'full',
  },
  {
    path: 'bvb-test-visually-impaired-results/:ssid',
    loadChildren: () =>
      import('app/pages/results/bvb-visually-impaired-results/bvb-visually-impaired-results.module').then(
        m => m.BvbVisuallyImpairedResultsModule,
      ),
    pathMatch: 'full',
  },
  {
    path: 'bvb-test',
    component: AnketaBvbComponent,
    canActivate: [BvbGuard],
    pathMatch: 'full',
  },
  {
    path: 'bvb-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
    pathMatch: 'full',
  },
  {
    path: 'bvb-test-results/:ssid',
    loadChildren: () => import('app/pages/results/bvb-results/bvb-results.module').then(m => m.BvbResultsModule),
    pathMatch: 'full',
  },
  {
    path: 'mincifry-test',
    component: AnketaComponent,
    canActivate: [AuthRoleGuard, StartMincifryTestGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'mincifry-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
  },
  {
    path: 'mincifry-test-results/:ssid',
    loadChildren: () => import('app/pages/results/mincifry-test-results/mincifry-results.module').then(m => m.MincifryResultsModule),
  },
  {
    path: 'mincifrydemotest',
    component: AnketaComponent,
  },
  {
    path: 'mincifrydemotest/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
  },
  {
    path: 'mincifrydemotest-results/:ssid',
    component: MincifrydemotestResultsComponent,
  },
  {
    path: 'vk-test',
    component: AnketaComponent,
  },
  {
    path: 'vk-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
  },
  {
    path: 'vk-test-results/:ssid',
    loadChildren: () => import('app/pages/results/vk-results/vk-results.module').then(m => m.VkResultsModule),
  },
  {
    path: 'web-test',
    component: AnketaComponent,
  },
  {
    path: 'web-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
  },
  {
    path: 'web-test-results/:ssid',
    loadChildren: () => import('app/pages/results/vk-results/vk-results.module').then(m => m.VkResultsModule),
  },
  {
    path: 'camp-test',
    component: AnketaComponent,
  },
  {
    path: 'camp-test/:ssid',
    resolve: {
      slideData: SlideService,
    },
    component: PlayerV2Component,
    data: {},
  },
  {
    path: 'camp-test-results/:ssid',
    loadChildren: () => import('app/pages/results/camp-test-results/camp-test-results.module').then(m => m.CampTestResultsModule),
  },
  {
    path: 'industry-page/:id',
    loadChildren: () => import('app/pages/industry-page/industry-page.routes'),
  },
];
