import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { ApiUsersService, B2gSaasService, ISchool } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-director-panel',
  templateUrl: './director-panel.component.html',
  styleUrls: ['./director-panel.component.scss'],
})
export class DirectorPanelComponent extends UnsubscribeComponent implements OnInit {
  public title: string = '';
  public schoolTitle: string = '';
  public school: ISchool;
  public buttonName: string = '';
  public buttonUrl: string = '';

  constructor(private router: Router, private apiUsersService: ApiUsersService, private b2gSaasService: B2gSaasService) {
    super();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkTitle(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.b2gSaasService
      .getSchool()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(r => {
        this.school = r;
        this.schoolTitle = this.school?.number;
      });
  }

  private checkTitle(url): void {
    if (url == '/director') {
      this.title = 'Главная';
      this.buttonName = '';
      this.buttonUrl = '';
    } else if (url == '/director/profile') {
      this.title = 'Мой профиль';
      this.buttonName = '';
      this.buttonUrl = '';
    } else if (url == '/director/class') {
      this.title = 'Школьный класс';
      this.buttonName = 'Назад';
      this.buttonUrl = '/director';
    } else if (url.includes('/director/class/')) {
      const classId = url.substr(16);
      if (classId) {
        this.apiUsersService
          .getSchoolClassDirectors(classId)
          .pipe(take(1))
          .subscribe((schoolClass: any) => {
            this.title = 'Класс ' + schoolClass.className;
            this.buttonName = 'Назад';
            this.buttonUrl = '/director';
          });
      }
    } else if (url.includes('/director/pupil')) {
      this.title = 'Ученик';
      this.buttonName = '';
      this.buttonUrl = '';
    } else if (url == '/director/analytics') {
      this.title = 'Аналитика';
      this.buttonName = '';
      this.buttonUrl = '';
    } else if (url == '/director/slices') {
      this.title = 'Срезы';
      this.buttonName = '';
      this.buttonUrl = '';
    } else if (url == '/director/courses/all-courses' || '/director/courses/mine-courses') {
      this.title = 'Курсы';
      this.buttonName = '';
      this.buttonUrl = '';
    }
  }

  public checkClassMessageButton(): boolean {
    return this.router.url.includes('/director/class');
  }
}
