import { Component, Input } from '@angular/core';

export enum ProgressBarThemes {
  GREEN = 'green-theme',
  BLUE = 'blue-theme',
}

@Component({
  selector: 'prf-progress-bar[title][progress]',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() public title!: string;
  @Input() public progress!: number;
  @Input() public isWithProblem?: boolean = false;
  @Input() public theme?: string = ProgressBarThemes.GREEN;
  @Input() public additionalProgress?: number;
  @Input() public additionalProgressTheme?: string;
}
