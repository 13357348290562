import { Component, Input } from '@angular/core';

import { ClipboardService } from 'ngx-clipboard';

import { CourseMaterialsTheme, ICourseMaterials } from './model';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-course-materials',
  templateUrl: './course-materials.component.html',
  styleUrls: ['./course-materials.component.scss'],
})
export class CourseMaterialsComponent {
  public isLinkCopied: boolean = false;
  public successLinkCopied: string = 'ссылка скопирована в буфер обмена';

  @Input() public materials: ICourseMaterials;
  @Input() public noMaterialsText: string;
  @Input() public notificationText?: string;
  @Input() public isNotificationHided: boolean;
  @Input() public theme: CourseMaterialsTheme = CourseMaterialsTheme.DEFAULT;

  constructor(private clipboardService: ClipboardService, private webStorageService: WebStorageService) {}

  public hideNotification(): void {
    this.isNotificationHided = true;
    this.webStorageService.set(StorageKeys.IsUsefulTabTeacherNotificationHided, true);
  }

  public copyLinkToMaterial(linkToMaterial: string): void {
    this.clipboardService.copy(linkToMaterial);
    this.isLinkCopied = true;
    setTimeout(() => {
      this.isLinkCopied = false;
    }, 2000);
  }
}
