import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

import { ApiSchoolsService, IReloadClassesResponse } from '@profilum-library';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-elastic-courses',
  templateUrl: './elastic-courses.component.html',
  styleUrls: ['./elastic-courses.component.scss'],
})
export class ElasticCoursesComponent implements OnInit {
  public ActiveArchive: number = 1;

  constructor(private meta: Meta, private utilsService: UtilsService, private apiSchoolsService: ApiSchoolsService) {
    this.meta.updateTag({ name: 'og:title', content: 'Поисковый индекс' });
  }

  ngOnInit() {}

  //синхронизировать монго и эластик
  reloadClasses() {
    this.apiSchoolsService
      .reloadClasses()
      .pipe(take(1))
      .subscribe((response: IReloadClassesResponse) => {
        if (response.status != 'Success') {
          this.utilsService.openSnackBar(`Произошла ошибка синхронизации: ${response.comment}`, 'error');
        } else {
          this.utilsService.openSnackBar('Базы синхронизированы успешно', 'success');
        }
      });
  }
}
