import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionComponent } from '../../../components/description/description.component';
import { PrfInfoBlock } from '../../../components/info-block/info-block.types';
import { H3Component } from '../../../components/h3/h3.component';

@Component({
  selector: 'prf-info-block-item',
  standalone: true,
  imports: [CommonModule, DescriptionComponent, H3Component],
  templateUrl: './info-block-item.component.html',
  styleUrl: './info-block-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockItemComponent {
  @Input() infoBlockItem: PrfInfoBlock;
}
