<div class="swiper">
  <div class="swiper-viewport" #swiperViewport>
    <div class="swiper-container">
      @for (card of sliderCards; track card.id) {
        <div class="swiper-slide">
          <prf-diagnostics-of-interests-card [card]="card" class="swiper-item" />
        </div>
      }
    </div>
  </div>

  <div class="swiper-controls">
    <div class="swiper-progress">
      <div class="swiper-progress-bar" #swiperProgressBar></div>
    </div>
  </div>
</div>
