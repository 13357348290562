<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="44" height="44" rx="22" fill="white"></rect>
  <rect x="1" y="1" width="42" height="42" rx="21" fill="white"></rect>
  <rect x="1" y="1" width="42" height="42" rx="21" fill="#F8F7F4"></rect>
  <path
    d="M21.3993 29.9953L21.3979 29.9939C19.0617 27.685 17.1994 25.8417 15.9092 24.1225C14.6324 22.4211 14 20.9477 14 19.3951C14 16.8433 15.8096 15 17.95 15C19.1796 15 20.4022 15.6294 21.212 16.6658L22 17.6744L22.788 16.6658C23.5978 15.6294 24.8204 15 26.05 15C28.1904 15 30 16.8433 30 19.3951C30 20.9477 29.3676 22.4212 28.0906 24.124C26.8003 25.8445 24.9382 27.6899 22.6022 30.0036C22.6019 30.0039 22.6016 30.0042 22.6013 30.0045L22.0027 30.5939L21.3993 29.9953Z"
    stroke="#353535"
    stroke-opacity="0.6"
    stroke-width="2"
    class="svg-elem-4"
  ></path>
  <rect
    x="1"
    y="1"
    width="42"
    height="42"
    rx="21"
    stroke="#353535"
    stroke-opacity="0.6"
    stroke-width="2"
    [class.svg-elem-5]="deleting"
  ></rect>
</svg>
