import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms/';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, timer } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  ApiAuthService,
  ApiPlayerService,
  ApiUsersService,
  B2gSaasService,
  IDataSession,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { SessionStatusEnum } from '@profilum-collections/prf-anketa.collections';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { SlideService } from 'app/shared/services/slide.service';
import { ETestTypes, ETestTypesSteps } from 'app/shared/enums/testtypes.enum';
import { ResultsService } from 'app/pages/results/results.service';
import { IsAnonymousService } from '../../../ui-kit/services/utils-services/is-anonymous.service';
import { EAnketaStep, ETestType } from 'app/pages/player/anketa/anketa.enums';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';

@Component({
  selector: 'prf-anketa-bvb',
  templateUrl: './anketa-bvb.component.html',
  styleUrls: ['./anketa-bvb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnketaBvbComponent extends UnsubscribeComponent implements OnInit {
  public currentAnketaStep: number = 1;
  public steps = EAnketaStep;
  public forms: FormGroup[];
  public childInfoForm: FormGroup;
  public navigation: Subject<boolean>;
  public userRole: string = '';
  public userId: string = '';
  public testType: string = '';
  public testTypes = ETestTypes;
  public testTypesSteps = ETestTypesSteps;
  public showStartPage: boolean = false;
  public slidesNumber: number;

  private anketa = { testType: ETestType.Default };

  constructor(
    public anketaService: AnketaService,
    private router: Router,
    private slideService: SlideService,
    private overlayService: OverlayBusyService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private resultsService: ResultsService,
    private webStorageService: WebStorageService,
    private apiAuthService: ApiAuthService,
    private apiPlayerService: ApiPlayerService,
    private isAnonymousService: IsAnonymousService,
    private apiUsersService: ApiUsersService,
    private b2gSaasService: B2gSaasService,
  ) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.userId = this.webStorageService.get(StorageKeys.UserId);
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.setTestType();
    this.apiAuthService.checkUserSelectionBvb(this.userId).pipe(
      filter(navigateToProfile => {
        if (navigateToProfile) {
          this.router.navigate(['/discover-professions-mobile/bvb-profile']).then(() => this.overlayService.hide());
          return false;
        }

        return true;
      }),
      switchMap(() => this.b2gSaasService.getLastSession(this.userId)),
      takeUntil(this.unsubscribe),
    );
    this.b2gSaasService
      .getLastSession(this.userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ status, sessionId }) => {
        this.webStorageService.set(StorageKeys.TestSessionId, sessionId);
        // todo for PROD-70, remove later
        // if (Helper.isDemoUserBvb()) {
        //   this.webStorageService.clear(StorageKeys.UserId);
        //   this.router.navigate(['/discover-professions-mobile']).then(() => this.overlayService.hide());
        //   return;
        // }

        if (status === SessionStatusEnum.Success) {
          this.router.navigate(['/discover-professions-mobile']).then(() => this.overlayService.hide());
          return;
        }

        if (status === SessionStatusEnum.Uncompleted) {
          this.router.navigate(['/bvb-test', sessionId]).then(() => this.overlayService.hide());
          return;
        }

        this.overlayService.hide();
        this.showStartPage = true;
      });

    this.forms = this.anketaService.getForms();
    this.childInfoForm = this.anketaService.childInfoForm;
    this.navigation = new Subject();
    this.navigation
      .pipe(
        mergeMap(() => {
          this.anketa.testType = ETestType.BvbTest;
          return this.createSession();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        () => {
          this.overlayService.hide();
        },
        err => {
          this.overlayService.hide();
          this.handleSessionError();
        },
      );
  }

  public changeLanguage(event): void {
    if (event) {
      Object.assign(this.anketa, { language: event });
    }
    return;
  }

  private createSession(): Observable<any> {
    this.overlayService.show();
    let testRoute: string = '';

    const bvbData = {
      screeningTestId: TESTS_VARIANTS_IDS.bvbTest,
      userId: this.userId,
      data: {},
    };

    testRoute = '/bvb-test';
    this.webStorageService.set(StorageKeys.TestType, ETestTypes.BVB_TEST.toString());

    return this.createTestSession(bvbData, testRoute);
  }

  private createTestSession(testData: IDataSession, route: string): Observable<any> {
    let sessionId: string = '';
    return this.apiUsersService.createTestSessionPupils(testData).pipe(
      switchMap(sessionResult => {
        if (!sessionResult || sessionResult?.status !== 'Success') {
          this.handleSessionError();
          return of(null);
        }
        const slidesNumber = parseInt(sessionResult.comment.replace(/[^\d]/g, ''));
        this.webStorageService.set(StorageKeys.SlidesNumber, slidesNumber.toString());
        sessionId = sessionResult?.sessionId;
        return timer(1500);
      }),
      switchMap(() => {
        const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
        return this.apiPlayerService.getNextSlide(sessionId, isAnonymous);
      }),
      tap(value => {
        this.slideService.setCurrentSlide(sessionId, value);
        this.router.navigate([route, sessionId]).then(() => {
          this.overlayService.hide();
        });
      }),
    );
  }

  private setTestType(): void {
    this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
      url.map(segment => segment.path).includes('bvb-test')
        ? this.webStorageService.set(StorageKeys.TestType, ETestTypes.BVB_TEST.toString())
        : null;

      this.testType = this.webStorageService.get(StorageKeys.TestType);
      if (this.testType === null) {
        if (this.userRole === 'pupil') {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
        if (this.userRole === 'parent') {
          this.testType = ETestTypes.DEFAULT_360.toString();
        } else {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
      }
    });
  }

  public startTest(): void {
    this.navigation.next(true);
    this.slideService.changeSlideNumber(1);
  }

  private handleSessionError(): void {
    this.utilsService.openSnackBar('Неполадки на сервере, уже чиним', 'error');
    this.timer = setTimeout(() => {
      this.overlayService.hide();
      this.utilsService.closeSnackBar();
      return this.router.navigate(['/']);
    }, 3000);
  }
}
