import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { from } from 'rxjs';
import {
  ApiFavoritesService,
  ApiSchoolsService,
  FavoritesDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoritesComponent extends UnsubscribeComponent implements OnInit {
  public loaded: boolean = false;
  public userId: string = '';
  public userFavorites: any = [];

  constructor(
    private meta: Meta,
    private apiSchoolsService: ApiSchoolsService,
    private apiFavoritesService: ApiFavoritesService,
    private webStorageService: WebStorageService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Избранное' });
  }

  public ngOnInit() {
    this.getFavorites();
  }

  private getFavorites() {
    const userFavoriteClasses = this.favoritesDataHandlerService.getFavoriteClasses().getValue();
    const ids = userFavoriteClasses.map(el => el.productId);

    from(ids)
      .pipe(
        mergeMap((id: string) => {
          return this.apiSchoolsService.getClassById(id);
        }),
        toArray(),
        takeUntil(this.unsubscribe),
      )
      .subscribe(favoriteClasses => {
        favoriteClasses.forEach(favoriteClass => (favoriteClass ? this.userFavorites.push(favoriteClass) : ''));
        this.loaded = true;
      });
  }
}
