import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-bvb-test',
  templateUrl: './bvb-test.component.html',
  styleUrls: ['./bvb-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BvbTestComponent extends UnsubscribeComponent implements OnInit {
  constructor() {
    super();
  }

  public ngOnInit() {}
}
