import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { CityAdminRoutingModule } from './city-admin-routing.module';
import { CityAdminComponent } from './cityadmin.component';

@NgModule({
  declarations: [CityAdminComponent],
  imports: [CommonModule, CityAdminRoutingModule, SharedModule],
})
export class CityAdminModule {}
