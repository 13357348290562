import { Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SchoolClassesClass } from '../../../../../shared/classes/school-classes.class';
import { Gender } from '../../../../../shared/enums/gender.enums';
import { SCHOOL_NUMBERS } from '../../../../../shared/global-constants/school-numbers';
import { DateValidator } from '../../../../../shared/instruments/validators/dateValidator';
import {
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  EUserTags,
  IAddUserRequest,
  IAddUserResponse,
  ICity,
  StorageKeys,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-child-registration',
  templateUrl: './child-registration.component.html',
  styleUrls: ['./child-registration.component.scss'],
})
export class ChildRegistrationComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public dateMask = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public cities: ICity[] = [];
  public citiesByMunicipality: ICity[] = [];
  public genderChoice: string = Gender.Male;
  public role: string = 'pupil';
  public numbersClass: any = SCHOOL_NUMBERS;
  private appSettingsService = inject(AppSettingsService);
  public lettersClass: any = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
  public selectedSchools: any;
  public selectedNumber: any;
  public selectedLetter: any;
  public allSchools: any[] = [];
  public response: any = {};
  public isShow: boolean = false;
  showInfoPopup: boolean = false;

  parentRegionId: string = '';
  parentMunicipalityId: string = '';

  public selectedCity: ICity;
  public schoolsFiltered: any[] = [];
  public schoolName: string;
  public searchCity: string;
  public searchSchool: string;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  // выбор буквы и цифры класса
  public isClasses: boolean = false;
  public schoolID: string = '';
  public selectedSchoolClass: any;
  public checkBirthday: boolean = false;
  public schoolClass: string;
  public personalTerms: boolean = false;
  public invalidDate: boolean = false;

  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public credentials: IAddUserRequest = {
    firstName: null,
    lastName: null,
    middleName: null,
    birthday: null,
    role: 'pupil',
    gender: null,
    city: null,
    schoolId: null,
    schoolClassLetter: null,
    schoolClassNumber: null,
    tag: null,
    isAcceptPersonalInformation: false,
  };
  public date: string;
  public newDate: Date = new Date();
  public activeLocation = 'base';

  private _citiesView: any[] = [];
  private _schoolView: any[] = [];

  public dataFetched: boolean = false;

  public mosruUser: boolean = false;
  public mosruSchoolId: string;

  public regionIdQuery: string = '';
  public cityEnabled: boolean = true;
  public schoolEnabled: boolean = true;
  public classEnabled: boolean = true;

  @Output() updateParent = new EventEmitter<boolean>();
  @Output() closePage = new EventEmitter<boolean>();

  @ViewChild('cityFilter') public readonly cityFilter: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;

  @ViewChild('schoolFilter') public readonly schoolFilter: ElementRef;
  @ViewChild('schoolDrop') public readonly schoolDrop: ElementRef;
  @ViewChild('schoolField') public readonly schoolField: ElementRef;

  @ViewChild('numberFilter') public readonly numberFilter: ElementRef;
  @ViewChild('numberDrop') public readonly numberDrop: ElementRef;
  @ViewChild('numberField') public readonly numberField: ElementRef;

  @ViewChild('letterFilter') public readonly letterFilter: ElementRef;
  @ViewChild('letterDrop') public readonly letterDrop: ElementRef;
  @ViewChild('letterField') public readonly letterField: ElementRef;

  @ViewChild('classesFilter') public readonly classesFilter: ElementRef;
  @ViewChild('classesDrop') public readonly classesDrop: ElementRef;
  @ViewChild('classesField') public readonly classesField: ElementRef;

  constructor(
    private meta: Meta,
    private router: Router,
    private fb: UntypedFormBuilder,
    private apiLocationsService: ApiLocationsService,
    private apiSchoolsService: ApiSchoolsService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
    private b2gSaasService: B2gSaasService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Регистрация ребенка' });
    this.parentRegionId = this.webStorageService.get(StorageKeys.RegionId);
    this.parentMunicipalityId = this.webStorageService.get(StorageKeys.MunicipalityId);
    this.mosruSchoolId = AppSettingsService.settings.mosruSchoolId ? AppSettingsService.settings.mosruSchoolId : null;
  }

  ngOnInit() {
    this.getCurrentLocation();
    this.getData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.citiesView = this.parentMunicipalityId;
        this.initForm();
        this.dataFetched = true;
      });
  }

  initForm() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(this.credentials.lastName, [Validators.required]),
      firstName: new UntypedFormControl(this.credentials.firstName, [Validators.required]),
      middleName: new UntypedFormControl(this.credentials.middleName, [Validators.required]),
      date: new UntypedFormControl(this.credentials.birthday, [Validators.required, DateValidator()]),
      role: new UntypedFormControl(this.role, [Validators.required]),
      city: new UntypedFormControl(this.credentials.city, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      schoolClass: new UntypedFormControl(null, []),
    });

    if (this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.MosRu]) {
      this.mosruUser = true;
      const mosRuSchool = this.allSchools
        .filter(school => school.id === this.mosruSchoolId)
        .map(school => ({ name: school.viewValue, data: school }))[0];

      this.form.controls.school.setValue(mosRuSchool);
      this.form.controls.schoolClassNumber.setValue({ name: '7', data: { value: '7' } });
      this.form.controls.schoolClassLetter.setValue({ name: 'А', data: { value: 'А' } });
    }
    this.checkStorageRegistrationParams();
  }

  checkStorageRegistrationParams() {
    this.regionIdQuery = this.webStorageService.get(StorageKeys.RegionIdParam);
    const cityIdParam: string = this.webStorageService.get(StorageKeys.CityIdParam);
    if (cityIdParam) {
      const city = this.cities.find(city => city.id === cityIdParam);

      if (city) {
        this.form.get('city').setValue({ name: city.name, data: city });
        this.onCityChange();
        this.cityEnabled = false;

        const schoolIdParam = this.webStorageService.get(StorageKeys.SchoolIdParam);
        if (schoolIdParam) {
          const school = this.schoolView.find(school => school.data.id === schoolIdParam);
          if (school) {
            this.form.get('school').setValue(school);
            this.schoolEnabled = false;

            this.getSchoolClassBySchool(school.data.id)
              .pipe(
                takeUntil(this.unsubscribe),
                tap(response => {
                  const classIdParam = this.webStorageService.get(StorageKeys.ClassIdParam);

                  if (classIdParam) {
                    const schoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === classIdParam);
                    if (schoolClass) {
                      this.form.get('schoolClass').setValue(schoolClass);
                      this.isClasses = true;
                      this.classEnabled = false;
                    }
                  }
                }),
              )
              .subscribe();
          }
        }
      }
    }
  }

  getData(): Observable<any> {
    return this.apiLocationsService.getAllCities().pipe(
      switchMap(r => {
        this.cities = r;
        this.cities.forEach(city => {
          if (city.regionId === this.parentRegionId && city.municipalityId === this.parentMunicipalityId) {
            this.citiesByMunicipality.push(city);
          }
        });
        this.citiesView = this.citiesByMunicipality.map(city => ({ name: city.name, data: city }));
        return this.getSchools(this.citiesByMunicipality);
      }),
    );
  }

  getSchools(cities): Observable<any> {
    return this.apiSchoolsService.getSchools().pipe(
      tap(schools => {
        schools.forEach(school => {
          this.allSchools.push({
            city: school.city,
            cityId: school.cityId,
            value: school.number,
            viewValue: school.number,
            id: school.id,
          });
        });
      }),
    );
  }

  getCurrentLocation() {
    this.activeLocation = this.appSettingsService.currentLocation;
    this.getDocsRoute();
  }

  getDocsRoute() {
    switch (this.activeLocation) {
      case 'mo':
        return AppSettingsService.settings.docsPath.docsPathDefault;
      case 'kz':
        if (this.getKzRegionLanguage() === 'kz') {
          return AppSettingsService.settings.docsPath.docsPathRegion;
        } else if (this.getKzRegionLanguage() === 'ru') {
          return AppSettingsService.settings.docsPath.docsPathDefault;
        } else {
          return AppSettingsService.settings.docsPath.docsPathDefault;
        }
      default:
        return AppSettingsService.settings.docsPath.docsPathDefault;
    }
  }

  public getKzRegionLanguage(): string {
    const language: string = this.webStorageService.get(StorageKeys.SelectedLanguage);
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      return language;
    } else {
      return 'kz';
    }
  }

  get f() {
    return this.form.controls;
  }

  setCity(obj: any = {}) {
    this.selectedCity = obj;
    if (obj != this.selectedCity) {
      this.f.school.setValue('');
      this.f.schoolClassNumber.setValue('');
      this.f.schoolClassLetter.setValue('');
    }
    this.f.city.setValue(obj.name);
  }

  onCityChange() {
    // для mosru пользователя школа сетится сразу в initForm
    if (!this.mosruUser) {
      this.f.school.reset();
      if (this.f.city.value && this.f.city.value.data.name) {
        this.schoolView = this.f.city.value.data.name;
      }
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  getSchoolClassBySchool(id): Observable<any> {
    return this.b2gSaasService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  public tooggleSelectClassType() {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  set citiesView(municipalityId: any) {
    this._citiesView = municipalityId
      ? [...this.cities].filter(city => city.municipalityId == municipalityId).map(city => ({ name: city.name, data: city }))
      : [];
  }
  get citiesView() {
    return this._citiesView;
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.allSchools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }
  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }

  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }

  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }

  get currentClassLetter() {
    return this.form.value.schoolClassLetter ? this.form.value.schoolClassLetter.name : this.form.value.schoolClass.data.letter;
  }

  get currentClassNumber() {
    return this.form.value.schoolClassNumber ? this.form.value.schoolClassNumber.name : this.form.value.schoolClass.data.number;
  }

  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }

  public closeModal() {
    this.isShow = false;
  }

  public submit() {
    this.submitted = true;
    if (this.form.valid && this.validateAge()) {
      const birthday: string = DateHelper.toDayJsInUTC(this.f.date.value, 'DD/MM/YYYY').format();

      this.credentials = {
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        middleName: this.form.value.middleName,
        schoolId: this.form.value.school.data.id,
        role: this.form.value.role,
        schoolClassNumber: this.currentClassNumber,
        schoolClassLetter: this.currentClassLetter,
        birthday: birthday,
        gender: this.genderChoice,
        city: this.form.value.city.name,
        tag: this.webStorageService.get(StorageKeys.Tag),
        isAcceptPersonalInformation: this.personalTerms,
      };
      this.regionIdQuery ? Object.assign(this.credentials, { regionId: this.regionIdQuery }) : null;

      if (this.credentials) {
        this.registrationFailed = false;
        this.passFailed = false;
        this.b2gSaasService
          .addUser(this.credentials)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((registrationResult: IAddUserResponse) => {
            this.response = registrationResult;
            this.isShow = true;
            if (registrationResult) {
              if (registrationResult.userId == undefined) {
                if (registrationResult.status == 'Registration failed') {
                  // такой статус возвращается при неверном пароле
                  this.passFailed = true;
                } else {
                  this.registrationFailed = true;
                }
              } else {
                // Pupil succesfuly added
                this.yandexMetricsService.reachGoal(YmItems.ADD_PUPIL_BY_REG);
                this.failWaiting();
                this.bindingChild();
              }
            }
            this.removeWaiting();
            this.closePage.emit(true);
            this.clearStorageParams();
            // this.isShow = true; показать модальное окно с кодом, было решено пока отправлять в лк родителя
            this.router.navigate(['/parent']);
          });
      }
    } else {
      this.failWaiting();
    }
  }

  clearStorageParams() {
    this.webStorageService.clear(StorageKeys.RegionIdParam);
    this.webStorageService.clear(StorageKeys.CityIdParam);
    this.webStorageService.clear(StorageKeys.SchoolIdParam);
    this.webStorageService.clear(StorageKeys.ClassIdParam);
  }

  private bindingChild() {
    this.webStorageService.set(StorageKeys.SkipCaching, true);
    this.updateParent.emit(true);
  }

  animateLogin() {
    if (this.buttonWaiting) {
      return;
    }

    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  public setMale() {
    this.genderChoice = Gender.Male;
  }

  public setFemale() {
    this.genderChoice = Gender.Female;
  }

  public validateAge(): boolean {
    this.invalidDate = false;
    const date = DateHelper.toDayJs(this.form.value.date, 'DD-MM-YYYY').toDate();
    if (
      this.newDate.getTime() < date.getTime() ||
      date.getFullYear() < this.newDate.getFullYear() - AppSettingsService.settings.maximumChildAge
    ) {
      this.invalidDate = true;
      return false;
    }
    return true;
  }

  public checkFormatDate(event) {
    if (event) {
      return (this.checkBirthday = true);
    }
  }

  public isAccessAllowed() {
    return this.mosruUser
      ? this.form.value.lastName &&
          this.form.value.firstName &&
          this.form.value.middleName &&
          this.form.value.city &&
          this.personalTerms &&
          this.validateAge()
      : this.form.value.lastName &&
          this.form.value.firstName &&
          this.form.value.middleName &&
          this.form.value.city &&
          this.form.value.school &&
          (this.form.value.schoolClass || (this.form.value.schoolClassNumber && this.form.value.schoolClassLetter)) &&
          this.personalTerms &&
          this.validateAge();
  }
}
