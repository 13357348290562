import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'prf-professions-loader',
  standalone: true,
  templateUrl: './professions-loader.component.html',
  styleUrls: ['./professions-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf
  ]
})
export class ProfessionsLoaderComponent {
}
