import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Helper } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'prf-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public _showCaptcha: boolean = false;
  @Input() length: number = 6;
  @Input() fontFamily: string = 'Arial';
  @Input() fontSize: string = '45px';
  @Output() isValid = new EventEmitter<boolean>();
  @ViewChild('canvasEl') canvasEl: ElementRef;
  public validated: boolean = false;
  public captchaText: string;
  private width: number = 0;
  private height: number = 0;
  private readonly chars: string = 'abcdefghjklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private randomText: string;
  @Input() set showCaptcha(showCaptcha: boolean) {
    if (!showCaptcha) {
      return;
    }

    this._showCaptcha = showCaptcha;
    showCaptcha ? this.show() : this.hide();
  }

  constructor(private elementRef: ElementRef, private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      text: new FormControl<string | null>('', [Validators.required]),
    });

    this.formControls.text.valueChanges.pipe(this.unsubscribeOperator).subscribe(value => {
      this.captchaText = value;
    });
  }

  public ngAfterViewInit(): void {
    this.init();
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  private init(): void {
    if (this.canvasEl?.nativeElement) {
      this.createCanvas();
      this._showCaptcha ? this.show() : this.hide();
    }
  }

  private createCanvas(): void {
    this.canvas = document.createElement('canvas');
    this.height = Number.parseInt(this.fontSize, 10) + 5;
    this.canvas.height = this.height;
    this.context = this.canvas.getContext('2d');
    this.context.font = `${this.fontSize} ${this.fontFamily}`;
    this.canvas.classList.add('captcha-canvas');
    this.canvasEl.nativeElement.appendChild(this.canvas);
  }

  private createRandomText(): void {
    const randomIndexes = Helper.getRandomUniqueIndexes(this.chars.length, this.length);
    const randomChars: string[] = [];

    randomIndexes.forEach((index: number) => randomChars.push(this.chars[index]));
    this.randomText = randomChars.join('');
    this.context.clearRect(0, 0, this.width, this.height);
    this.width = Math.floor(this.context.measureText(this.randomText).width);
    this.canvas.width = this.width;
    this.context.font = `${this.fontSize} ${this.fontFamily}`;
    this.context.strokeText(this.randomText, 0, Number.parseInt(this.fontSize, 10) - 5);
    this.formControls.text.setValue('');
  }

  private show(): void {
    if (this.canvas) {
      this.createRandomText();
      this.canvas.classList.remove('hidden');
    }
  }

  private hide(): void {
    this.canvas?.classList.add('hidden');
  }

  public validate(): void {
    const isValid: boolean = this.captchaText === this.randomText;

    if (!isValid) {
      this.createRandomText();
      return;
    }

    this.validated = isValid;
    this.isValid.emit(isValid);

    // Helper.setPrioritizedValidators(this.formControls, 'text', {
    //   required: () => !this.captchaText,
    //   notValidText: () => !isValid,
    // });
  }

  public reloadImage(event: Event): void {
    event.stopPropagation();
    if (this.canvas) {
      this.createRandomText();
    }
  }

  public resetData(): void {
    this.formControls.text?.setValue('');
  }
}
