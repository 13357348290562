import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { ApiProfessionsService, ISpecialityData, StorageKeys, WebStorageService } from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-edit-specialty',
  templateUrl: './edit-specialty.component.html',
  styleUrls: ['./edit-specialty.component.scss'],
})
export class EditSpecialtyComponent implements OnInit {
  specialty: ISpecialityData;
  specialtyId: string;
  specialties: ISpecialityData[];
  createdDate: string;

  public submitted: boolean = false;

  public userRole: string;
  popUpConfirming: boolean = false;

  public form: UntypedFormGroup;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private apiProfessionsService: ApiProfessionsService,
    private webStorageService: WebStorageService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование специальности' });
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.overlayService.show();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.specialtyId = params['id'];
    });

    this.apiProfessionsService
      .getSpecialtyById(this.specialtyId)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(specialty => {
          this.specialty = specialty;
          this.createdDate = DateHelper.toDayJs(this.specialty.createdDate).format('D MMMM YYYY в HH:mm');
          this.form = this.fb.group({
            id: new UntypedFormControl(this.specialty.id, []),
            name: new UntypedFormControl(this.specialty.name, [Validators.required]),
            govCode: new UntypedFormControl(this.specialty.govCode, [Validators.required, Validators.pattern('^[0-9]*$')]),
            description: new UntypedFormControl(this.specialty.description, [Validators.required]),
          });
        }),
      )
      .subscribe(
        r => this.overlayService.hide(),
        err => {
          this.overlayService.hide();
        },
      );
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      const specialtyObject = {
        name: this.form.get('name').value,
        govCode: this.form.get('govCode').value,
        description: this.form.get('description').value,
      };

      this.apiProfessionsService
        .updateSpecialty(specialtyObject)
        .pipe(
          tap(r => {
            this.utilsService.openSnackBar(
              '👌 Специальность обновлёна, вы будете перенаправлены на страницу данной специальности',
              'success',
            );
            return setTimeout(_ => {
              this.router.navigate(['/admin/specialties/' + this.form.get('id').value]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  deleteSpecialty(id) {
    this.apiProfessionsService
      .deleteSpecialty(id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Специальность удалена`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/specialties']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
