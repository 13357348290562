<mat-card class="circle">
  <div>
    <canvas
      baseChart
      [data]="barChartData"
      [labels]="doughnutChartLabels"
      [type]="doughnutChartType"
      [options]="pieChartOptions"
      [legend]="pieChartLegend"
    >
    </canvas>
  </div>
  <ul class="panel__legends" *ngIf="institutionData.length > 0">
    <li *ngFor="let item of institutionData; let i = index" class="panel__legend">
      <mat-card class="panel__legend-color" [style.background]="colorSet[i]"></mat-card>
      <span class="panel__legend-name">{{ item.name }} - {{ getRound(item.percents) }}%</span>
    </li>
  </ul>
</mat-card>
