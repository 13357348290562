import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';

import { BreakpointsComponent } from 'app/shared/common-components/breakpoints/breakpoints.component';

@Component({
  selector: 'prf-test-breakpoints',
  templateUrl: './test-breakpoints.component.html',
  styleUrls: ['./test-breakpoints.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestBreakpointsComponent extends BreakpointsComponent implements OnInit {
  constructor(protected changeDetectorRef: ChangeDetectorRef, protected breakpointsService: BreakpointsService) {
    super(changeDetectorRef, breakpointsService);
  }

  public ngOnInit(): void {
    this.breakpointsService.isMobile.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => console.log(`Mobile: ${isMobile}`));

    this.breakpointsService.isTablet.pipe(takeUntil(this.unsubscribe)).subscribe(isTablet => console.log(`Tablet: ${isTablet}`));

    this.breakpointsService.isDesktop.pipe(takeUntil(this.unsubscribe)).subscribe(isDesktop => console.log(`Desktop: ${isDesktop}`));

    this.breakpointsService.getCurrentBreakpoint
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(currentBreakpoint => console.log(`Current Breakpoint: ${currentBreakpoint}`));
  }
}
