import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportTextComponent } from './support-text.component';

@NgModule({
  declarations: [SupportTextComponent],
  exports: [SupportTextComponent],
  imports: [CommonModule],
})
export class SupportTextModule {}
