import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HeaderDropdownMenuItemComponent,
} from '../../../components/header/header-dropdown-menu/header-dropdown-menu-item/header-dropdown-menu-item.component';
import { PrfLink } from '../../../components/footer/footer.types';
import { PrfNavSpecial } from '../../../components/header/header.types';

@Component({
  selector: 'prf-header-dropdown-menu',
  standalone: true,
  imports: [CommonModule, HeaderDropdownMenuItemComponent],
  templateUrl: './header-dropdown-menu.component.html',
  styleUrl: './header-dropdown-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDropdownMenuComponent {
  @Input() navItems: PrfLink[];
  @Input() specialNavItem: PrfNavSpecial;
}
