export enum CourseMaterialsTheme {
  DEFAULT,
  MODAL,
}

export interface ICourseMaterials {
  sections: ICourseMaterialsSection[];
}

export interface ICourseMaterialsSection {
  title: string;
  items: ICourseMaterialsSectionItem[];
}

export interface ICourseMaterialsSectionItem {
  type: string;
  name: string;
  location?: string;
  isNew?: boolean;
}
