<div class="course-material-block">
  <div class="course-material-info">
    <img
      [ngSrc]="'./assets/images/icons/control-panel/teacher/filetypes/' + FILETYPES_IMAGES[courseMaterialItem.type] + '.svg'"
      class="file-icon-type"
      width="24"
      height="24"
      alt="icon"
    />
    <div class="text-info">
      <span class="file-name" [tp]="tooltip" [tpData]="courseMaterialItem.name">{{ courseMaterialItem.name }}</span>
      <span *ngIf="courseMaterialItem?.isNew" class="status new">Новый</span>
    </div>
  </div>
  <div *ngIf="courseMaterialItem.location" class="buttons-wrapper">
    <svg (click)="copyLinkToMaterial(courseMaterialItem.location)" class="icon copy-icon" xmlns="http://www.w3.org/2000/svg">
      <use [attr.href]="'./profilum-components/icons/all-icons.svg#copy_link'"></use>
    </svg>
    <svg (click)="goToMaterial(courseMaterialItem.location)" class="icon" xmlns="http://www.w3.org/2000/svg">
      <use [attr.href]="'./profilum-components/icons/all-icons.svg#download'"></use>
    </svg>
  </div>
</div>

<ng-template #tooltip let-text="data">
  <prf-tooltip>{{ text }}</prf-tooltip>
</ng-template>
