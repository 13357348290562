import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prf-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent {
  @Output() closeModal = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Input() dualAcation: boolean = false;
  @Input() disableButton: boolean = false;
  @Input() loading: boolean = true;
  @Input() popupConfirm: boolean = false;
  @Input() loadingPopup: boolean = false;
  @Input() isTextHighlighted: boolean = false;
  @Input() succesIcon: boolean = false;
  public showPopup: boolean = true;
  public waiting: boolean = false;

  closePopup() {
    this.showPopup = !this.showPopup;
    this.closeModal.emit(this.showPopup);
  }

  confirmAction() {
    this.confirm.emit(true);
    if (!this.dualAcation) {
      this.waiting = true;
    }
  }

  cancelAction() {
    this.confirm.emit(false);
  }
}
