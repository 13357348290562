<div class="testing-progress-bar-block">
  <div class="testing-progress-bar" [ngStyle]="{ width: (counter * 100) / amount + '%' }"></div>
</div>
<header class="prof-navigation">
  <div class="prof-navigation__icon-wrapper">
    <a class="prof-navigation__icon" (click)="closeTinder()"></a>
  </div>
  <div class="prof-navigation__wrapper" *ngIf="!tinderType">
    <span class="prof-navigation__title" *ngIf="!hasNoTabs; else tabStyleTitle">{{ title }}</span>
  </div>
  <div *ngIf="!hasNoTabs" class="prof-navigation__tabs" [class.prof-navigation__one-tab]="hasOneTab">
    <ng-container *ngFor="let tab of _tabs | keyvalue : originalOrder">
      <prf-prof-tab
        [tab]="tab"
        [hasOneTab]="hasOneTab"
        (selectTab)="onSelectTab($event)"
        [class.prof-navigation__one-tab__tab]="hasOneTab"
      ></prf-prof-tab>
    </ng-container>
  </div>
</header>
<ng-template #tabStyleTitle>
  <div class="prof-tab_active prof-tab">
    <span class="prof-tab_text">{{ title }}</span>
    <ng-container *ngIf="amount">
      <span class="prof-tab__counter" [class.prof-tab__counter_completed]="!profCount">
        {{ profCount ? profCount : '' }}
      </span>
    </ng-container>
  </div>
</ng-template>
