<div *ngIf="pupilsLength >= 2; else oneOrNoPupils" class="copy-pupils-invitation">
  <span class="copy-pupils-invitation__title">Кого-то не хватает?</span>
  <p class="copy-pupils-invitation__description">
    Скопируйте приглашение и отправьте родителям. <br />
    Они зарегистрируют ваших {{ dictionary.PupilsGenitive }}.
  </p>
  <button *ngIf="!loading" (click)="openCopyInvitationPopup()" class="copy-pupils-invitation__button">Скопировать приглашение</button>
</div>

<ng-template #oneOrNoPupils>
  <div class="widget">
    <div class="widget-wrapper">
      <span class="widget-title"> Чтобы добавить {{ dictionary.PupilsGenitive }}, отправьте приглашение их родителям </span>
      <p class="widget-desc">
        Скопируйте приглашение и отправьте его в <br />
        «родительский» чат WhatsApp, «ВКонтакте», Viber, <br />
        Telegram или другим удобным способом.
      </p>
      <button *ngIf="!loading" class="button widget-button" (click)="openCopyInvitationPopup()">
        Скопировать приглашение для родителей
      </button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="showSharePopup && shortRegLink">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <ng-template [ngIf]="!linkCopied">
        <div class="popup-share">
          <div class="popup-share-header">
            <h3 class="popup-share-title">Приглашение для родителей</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">Здравствуйте, уважаемые родители {{ class }} {{ dictionary.ClassGenitive }}!</p>
            <p *ngIf="courseIds?.length; else classWithDiagnostic" class="popup-share-text">
              {{ dictionary.OurClass | titlecase }} {{ dictionary.Class }} {{ isUserFromSalesB2S ? texts.SALES_B2S : texts.DEFAULT }}
            </p>
            <ng-template #classWithDiagnostic>
              <p class="popup-share-text">
                Для {{ dictionary.OurClassGenitive }} {{ dictionary.ClassGenitive }} бесплатно проводят тест на профориентацию.
              </p>
              <p class="popup-share-text">
                В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете
                посмотреть результаты.
              </p>
              <p class="popup-share-text">
                ❗️ Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.
              </p>
            </ng-template>
            <p class="popup-share-text">{{ shortRegLink }}</p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" (click)="copyInvitation()">Скопировать приглашение</button>
            <button class="button cancel-btn" (click)="closePopup()">Отменить</button>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="successSend">
        <div class="popup-share done-popup">
          <div class="popup-share-header">
            <span class="popup-share-done">Готово</span>
            <h3 class="popup-share-title">Приглашение для {{ class }} {{ dictionary.ClassGenitive }} скопировано</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">
              Отправьте его в «родительский» чат {{ class }} {{ dictionary.ClassGenitive }} и дождитесь, когда
              {{ dictionary.Pupils }} появятся в списке
            </p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" type="button" (click)="closePopup('done')">Понятно</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
