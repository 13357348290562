import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { take } from 'rxjs/operators';

import { ApiInternshipsService, IInternship, StorageKeys, WebStorageService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

export const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-internships-list-archive',
  templateUrl: './internships-list-archive.component.html',
  styleUrls: ['./internships-list-archive.component.scss'],
})
export class InternshipsListArchiveComponent implements OnInit {
  @Input() viewValue;
  @Input() searches;
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  //filters:
  internship: IInternship;
  internships: any = [];
  dataFetched: boolean = false;
  titleName: string;
  currentInternships: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  loadStopper: boolean = false;

  constructor(
    private overlayService: OverlayBusyService,
    private apiInternshipsService: ApiInternshipsService,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }
  ngOnInit() {
    this.overlayService.show();
    this.currentInternships = [];
    this.loadStopper = false;
    this.fromTo.currentPage = 0;
    this.loadInternships();
    this.overlayService.hide();
  }
  loadInternships() {
    if (!this.loadStopper) {
      observableForkJoin([
        this.apiInternshipsService.getInternshipByID('e16db7aa-46e0-45ab-ac44-6d79268b636f'),
        this.apiInternshipsService.getInternshipByID('e858460a-7267-45ea-b5f9-2c443b76d605'),
      ])
        .pipe(take(1))
        .subscribe(([internship1, internship2]) => {
          this.currentInternships.push(internship1);
          this.currentInternships.push(internship2);
          this.loadStopper = !!(this.currentInternships.length == 0 || this.currentInternships.length < PAGE_LOAD);
        });
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      //this.loadMore();
      //.cardsToShow += PAGE_SIZE;
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadInternships();
  }
}
