import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ApiProfessionsService } from '@profilum-library';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'prf-add-specialty',
  templateUrl: './add-specialty.component.html',
  styleUrls: ['./add-specialty.component.scss'],
})
export class AddSpecialtyComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  public showInfoPopup: boolean = false;

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private apiProfessionsService: ApiProfessionsService,
    private utilsService: UtilsService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Добавление ВУЗа' });
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, Validators.required),
      govCode: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]*$')]),
      description: new UntypedFormControl(null, Validators.required),
    });
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const specialtyObject = {
        name: this.form.get('name').value,
        govCode: this.form.get('govCode').value,
        description: this.form.get('description').value,
      };

      this.apiProfessionsService
        .addSpecialty(specialtyObject)
        .pipe(
          tap((response: any) => {
            this.utilsService.openSnackBar(
              '👌 Специальность добавлена, вы будете перенаправлены на страницу данной специальности',
              'success',
            );
            return setTimeout(_ => {
              this.router.navigate(['/admin/specialties/' + response.id]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
