<div class="welcome-section-2 after-testing" *ngIf="testCompleted && testResults && testResults.length; else notPassedTest">
  <div class="welcome-row-2">
    <div class="welcome-column-stud after-testing">
      <div class="welc-num">2</div>
      <div>
        <h3 class="welcome-h3-stud">{{ 'SHARED.CHOOSE_OF_PROFESSION' | translate }}</h3>
        <p class="welcome-text-stud after-testing">
          {{ 'SHARED.SELECTION_OF_PROFESSION_NARRATIVE' | translate }}
        </p>
        <prf-recommended-professions [testResults]="testResults"></prf-recommended-professions>

        <div class="after-testing-line"></div>

        <div>
          <h4 class="recommended-classes-heading">Рекомендуемые классы обучения</h4>
          <prf-recommended-classes [testResults]="testResults"></prf-recommended-classes>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notPassedTest>
  <div class="welcome-section-2 no-active">
    <div class="welcome-row-2">
      <div class="welcome-column-stud no-active">
        <div class="welc-num no-active">2</div>
        <div>
          <h3 class="welcome-h3-stud">{{ 'SHARED.CHOOSE_PROFESSION' | translate }}</h3>
          <p class="welcome-text-stud">
            {{ 'SHARED.CHOOSE_PROFESSION_NARRATIVE' | translate }}
          </p>
          <div class="no-active-block-text">{{ 'SHARED.RETRY_TEST' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
