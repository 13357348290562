<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share">
      <a (click)="closePopup()" class="popup-share-close">
        <img src="./profilum-assets/images/common/close-popup.svg" alt="" />
      </a>
      <div class="popup-share-confirmed">
        <img class="popup-share-confirmed-icon" src="./profilum-assets/images/common/confirm-reset.svg" alt="" />
        <p class="popup-share-title-name">Приглашение скопировано! Отправьте его учителю</p>

        <p class="popup-share-text">Приглашение скопировалось в буфер обмена. Зайдите в чат с учителем и вставьте скопированный текст</p>
      </div>
      <div class="popup-share-buttons">
        <button class="button button-green" (click)="closePopup()">Продолжить</button>
      </div>
    </div>
  </div>
</div>
