<section class="internships">
  <div class="admin-section">
    <div class="tabs w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a
          (click)="tabLevel = 1"
          [class.w--current]="tabLevel === 1"
          [routerLink]="['./all-internships']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Все</div>
          <img src="./profilum-assets/images/icons/angle.png" width="20" class="angle" />
        </a>
        <a
          (click)="tabLevel = 2"
          [class.w--current]="tabLevel === 2"
          [routerLink]="['./mine-internships']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Мои</div>
          <img src="./profilum-assets/images/icons/angle2.png" width="20" class="angle _2" />
        </a>
      </div>
      <div class="tabs-content w-tab-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
