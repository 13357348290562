import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterLogoComponent } from '../../components/footer/footer-logo/footer-logo.component';
import { FooterContactsComponent } from '../../components/footer/footer-contacts/footer-contacts.component';
import { FooterLegalComponent } from '../../components/footer/footer-legal/footer-legal.component';
import { FooterMapComponent } from '../../components/footer/footer-map/footer-map.component';
import { FooterSocialsComponent } from '../../components/footer/footer-socials/footer-socials.component';
import { PrfFooter } from '../../components/footer/footer.types';
import { ScrollToTopButtonComponent } from '@profilum-components/scroll-to-top-button/scroll-to-top-button.component';
import { RouterLink } from '@angular/router';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-footer',
  standalone: true,
  imports: [
    CommonModule,
    FooterLogoComponent,
    FooterContactsComponent,
    FooterLegalComponent,
    FooterMapComponent,
    FooterSocialsComponent,
    ScrollToTopButtonComponent,
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() footerData: PrfFooter;

  private yandexMetricsService = inject(YandexMetricsService);

  public onClickFooterLink(ymGoal: string) {
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
