import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

import { ICity, IRegion, ISchool } from '@profilum-library';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { BreakpointsComponent } from '../../../../shared/common-components/breakpoints/breakpoints.component';


export enum Steps {
  Step0 = 0,
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
}

interface IStepData {
  title: string;
  orderNum: number;
}

export interface IParentRegistrationData {
  parent: {
    lastName: string;
    firstName: string;
    phoneNumber: string;
    password: string;
    email: string;
  };
  child: {
    lastName: string;
    firstName: string;
    phoneNumber: string;
    birthday: string;
    gender: string;
    registrationCode: string;
  };
  currentClass: {
    classNumber: number;
    classLetter: string;
    classId: string;
  };
  school: ISchool;
  region: IRegion;
  city: ICity;
  isConsentToMailing: boolean;
  isAcceptPersonalInformation: boolean;
  isDiagnostic: boolean;
}

@Component({
  selector: 'prf-open-registration-redesign',
  templateUrl: './open-registration-redesign.component.html',
  styleUrls: ['./open-registration-redesign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenRegistrationRedesignComponent extends BreakpointsComponent {
  public parentRegistrationData: IParentRegistrationData;
  public steps = Steps;
  public stepsCount = Object.keys(Steps).length / 2 - 1; // minus Step0
  public stepsData: Map<Steps, IStepData> = new Map<Steps, IStepData>([
    [Steps.Step0, null],
    [Steps.Step1, { title: 'Данные родителя', orderNum: 1 }],
    [Steps.Step2, { title: 'Данные подростка', orderNum: 2 }],
    [Steps.Step3, { title: 'Проверьте данные', orderNum: 3 }],
    [Steps.Step4, { title: 'Пригласите подростка', orderNum: 4 }],
  ]);
  public currentStep: Steps = Steps.Step0;

  constructor(protected breakpointsService: BreakpointsService, protected changeDetectorRef: ChangeDetectorRef, private meta: Meta) {
    super(changeDetectorRef, breakpointsService);
    this.init();
  }

  public toPreviousStep(): void {
    const newStep = this.currentStep - 1;

    if (newStep >= 0) {
      this.currentStep = newStep;
    }
  }

  private init(): void {
    const componentName: MetaDefinition = {
      name: 'profliner:title',
      content: 'Open Registration',
    };

    this.meta.addTag(componentName);

    this.parentRegistrationData = {
      parent: {
        lastName: '',
        firstName: '',
        phoneNumber: '',
        password: '',
        email: '',
      },
      child: {
        lastName: '',
        firstName: '',
        phoneNumber: '',
        birthday: '',
        gender: '',
        registrationCode: '',
      },
      currentClass: {
        classNumber: null,
        classLetter: '',
        classId: '',
      },
      school: null,
      region: null,
      city: null,
      isConsentToMailing: false,
      isAcceptPersonalInformation: false,
      isDiagnostic: false,
    };
  }

  protected readonly TemplateRef = TemplateRef;

  public setParentPhone(phone: string): void {
    this.parentRegistrationData.parent.phoneNumber = phone;
  }
}
