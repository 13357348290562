import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectorClassComponent } from './director-class/director-class.component';
import { DirectorStudentPageComponent } from './director-class/director-student-page/director-student-page.component';
import { DirectorTeacherComponent } from './director-class/director-teacher/director-teacher.component';
import { DirectorRoutingModule } from './director-routing.module';
import { DirectorSchoolComponent } from './director-school/director-school.component';
import { DirectorPanelComponent } from './director-panel.component';
import { DirectorAnalyticsComponent } from './director-analytics/director-analytics.component';
import { SharedModule } from 'app/shared/shared.module';
import { DirectorSlicesComponent } from './director-slices/director-slices.component';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';
import { FiltersComponent } from './director-analytics/filters/filters.component';
import { BaseChartDirective } from 'ng2-charts';

@NgModule({
  declarations: [
    DirectorClassComponent,
    DirectorTeacherComponent,
    DirectorStudentPageComponent,
    DirectorPanelComponent,
    DirectorSchoolComponent,
    DirectorAnalyticsComponent,
    DirectorSlicesComponent,
    FiltersComponent,
  ],
  imports: [CommonModule, DirectorRoutingModule, SharedModule, DashboardModule, BaseChartDirective],
})
export class DirectorModule {}
