import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ApiSearchService, ApiTemporaryService, UserDataHandlerService, WebStorageService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';

import { IResultsData } from 'app/pages/results/results.interface';
import { ResultsService } from 'app/pages/results/results.service';
import { ISchoolStatistic } from 'app/shared/interfaces/Ischoolstatistic.interface';
import { IAssigningCourseData } from '../../common-components/class-courses/interfaces/class-courses.interfaces';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-shared-class-results',
  templateUrl: './shared-class-results.component.html',
  styleUrls: ['./shared-class-results.component.scss'],
})
export class SharedClassResultsComponent extends UnsubscribeComponent implements OnInit {
  public schoolStatistic: ISchoolStatistic;
  public talentsData: IAnswerPercents[] = [];
  public skillsData: IAnswerPercents[] = [];
  public interestsData: IAnswerPercents[] = [];
  public fieldsData: IAnswerPercents[] = [];
  private urlsTalentImg: any = {};
  public navigationMenu = [
    { key: 'resultsInSection', name: '' },
    { key: 'education', name: 'Образование' },
    { key: 'profNavigation', name: 'Профнавигация' },
  ];
  public schoolStatisticsData: IAnswerPercents[];
  public profExpectData: IAnswerPercents[];
  public resultExpectationsData: IAnswerPercents[];
  public additionalEduData: IAnswerPercents[];
  public profileClassData: IAnswerPercents[];
  public selectedNavMenu: any = this.navigationMenu[0];
  public isKruzhkiVariant: boolean = false;
  public dictionary: DictionaryType;

  // Специальность в какой области ты хочешь получить?
  private readonly questionIdForResultExpectations: string = '39d18039-ac4f-445c-af07-dd53ae0bf57a';

  // Укажи, какие тематики дополнительного образования (кружки, секции и пр.) в настоящее время ты посещаешь?"
  private readonly questionIdForProfileClasses: string = '54aa24f2-436f-4ce9-8c1d-1522703992ce';
  private readonly sectionIdForProfileClasses: string = '6a513632-d19c-44c8-b0b4-2098c1ca1f76';

  // А что тебе хотелось бы и было бы интересно посещать?"
  private readonly questionIdForAdditionalEdu: string = '54aa24f2-436f-4ce9-8c1d-1522703992ce';
  private readonly sectionIdForAdditionalEdu: string = '95076ade-6f73-4d30-88b6-91ac42602183';

  // Насколько на данный момент ты чувствуешь, что определился с выбором дальнейшего образования и профессией?
  private readonly questionIdForProfExpect: string = 'abbda951-6157-4c9c-80aa-7234ff9f27e5';

  // Какое из перечисленных утверждений наиболее точно описывает твою ситуацию?
  private readonly questionIdForSchoolStatistics: string = 'c65c7c38-3662-4e81-9bbb-fb4b04cd2214';

  private readonly color_turquoise = '#13e5db';
  private readonly color_purple = '#9375fb';

  @Output() showResults = new EventEmitter();

  public schoolClassId: string;
  constructor(
    private apiTemporaryService: ApiTemporaryService,
    private resultService: ResultsService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayBusyService,
    private translateService: TranslateService,
    private rootScopeService: RootScopeService,
    private activatedRoute: ActivatedRoute,
    private apiSearchService: ApiSearchService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
    this.dictionary = rootScopeService.getDictionary();
    this.getTranslation('SHARED.CUTAWAY_RESULTS')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translation => (this.navigationMenu[0].name = translation));
  }

  ngOnInit(): void {
    this.overlayService.show();
    // todo короткий фикс для PROD-139, после добавления дропдауна школьному админу, нужно будет отрефакторить
    const userRole = this.userDataHandlerService.getUserInfo().role;
    if (userRole === 'teacher') {
      this.rootScopeService.currentCLass
        .pipe(
          filter(v => v !== null),
          switchMap((selectedClass: IAssigningCourseData) => {
            const selectedClassId = selectedClass.schoolClass.id;
            this.schoolClassId = selectedClassId;
            this.parseResults();
            return this.apiTemporaryService.getSchoolClassStatistic(this.schoolClassId);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(
          schoolStatistic => {
            this.schoolStatistic = schoolStatistic;
            this.schoolStatisticsData = this.getQuestionAnswerPercents(this.questionIdForSchoolStatistics);
            this.resultExpectationsData = this.getQuestionAnswerPercents(this.questionIdForResultExpectations);
            this.profExpectData = this.getQuestionAnswerPercents(this.questionIdForProfExpect);
            this.additionalEduData = this.getQuestionAnswerPercents(this.questionIdForAdditionalEdu, this.sectionIdForAdditionalEdu);
            this.overlayService.hide();
          },
          err => this.overlayService.hide(),
        );
    } else {
      this.activatedRoute.queryParams
        .pipe(
          switchMap(params => {
            this.schoolClassId = params['schoolClassId'];
            this.parseResults();
            return this.apiTemporaryService.getSchoolClassStatistic(this.schoolClassId);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(
          schoolStatistic => {
            this.schoolStatistic = schoolStatistic;

            this.schoolStatisticsData = this.getQuestionAnswerPercents(this.questionIdForSchoolStatistics);
            this.resultExpectationsData = this.getQuestionAnswerPercents(this.questionIdForResultExpectations);
            this.profExpectData = this.getQuestionAnswerPercents(this.questionIdForProfExpect);
            this.additionalEduData = this.getQuestionAnswerPercents(this.questionIdForAdditionalEdu, this.sectionIdForAdditionalEdu);
            this.overlayService.hide();
          },
          err => this.overlayService.hide(),
        );
    }
  }

  public selectNavMenu(menuItem: any): void {
    this.selectedNavMenu = menuItem;
  }

  public closeResults(): void {
    this.showResults.emit(false);
    this.router.navigate(['/teacher']);
  }

  public existResults(): boolean {
    return this.interestsData.length != 0 && this.skillsData.length != 0 && this.talentsData.length != 0;
  }

  // Parse data charts
  private parseResults(): void {
    this.apiTemporaryService
      .getSchoolClassPercentsPage(this.schoolClassId)
      .pipe(
        switchMap(response => {
          if (response) {
            const resultsData: IResultsData = response['resultsData'] ? response['resultsData'] : { results: response['aggregates'] };
            if (resultsData.results) {
              this.talentsData = this.getObjectTypeAnswerPercents(resultsData, 'Talent');
              this.skillsData = this.getObjectTypeAnswerPercents(resultsData, 'SkillAttainment');
              this.interestsData = this.getObjectTypeAnswerPercents(resultsData, 'TalentAttainment');
              this.fieldsData = this.getObjectTypeAnswerPercents(resultsData, 'Field');
              this.profileClassData = this.getObjectTypeAnswerPercents(resultsData, 'ProfilClass');
              // получаем урл картинок для талантов
              return this.apiSearchService.searchTalents();
            }
          } else {
            return of(null);
          }
        }),
        filter(el => el !== null),
        tap(data => {
          data.forEach(item => {
            this.urlsTalentImg[item.name] = item.icon;
          });
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private getObjectTypeAnswerPercents(resultsData: IResultsData, objectType: string): IAnswerPercents[] {
    const results: IAnswerPercents[] = [];
    const objectTypeResults = resultsData.results.find(d => d.objectType === objectType);
    if (objectTypeResults) {
      objectTypeResults.objects.forEach(ob => {
        //если Кружки - костылим склонения: меняем "ий" на "ая", "класс" на "группа"
        if (objectType === 'ProfilClass' && this.isKruzhkiVariant) {
          ob.name = ob.name.replaceAll(/ий|ый/gi, 'ая').replace('класс', 'группа');
        }

        results.push({ name: ob.name, percents: Math.round(ob.percents) });
      });
      const favoritesProfessionsIds = objectTypeResults.objects.map(el => el.productId);
    }
    return results;
  }

  // questionId - required, sectionId - optional
  public getQuestionAnswerPercents(questionId: string, sectionId?: string): IAnswerPercents[] {
    const answerPercents: IAnswerPercents[] = [];
    const question = this.getQuestion(questionId, sectionId);
    // нужно преобразовать количество ответов в проценты
    let totalPercents = 0;
    if (question !== undefined) {
      question?.answers.forEach(answer => {
        const item: IAnswerPercents = {
          name: this.getAnswerName(questionId, answer.answerName),
          percents: Math.round((100 * answer.count) / this.schoolStatistic.completedTestsCount),
        };
        totalPercents += item.percents;
        answerPercents.push(item);
      });
    }
    // добавить пункт - Пропустили вопрос
    if (totalPercents < 100) {
      const noAnswersItem: any = {
        name: 'Пропустили вопрос',
        percents: 100 - totalPercents,
      };
      answerPercents.push(noAnswersItem);
    }
    return answerPercents;
  }

  // questionId - required, sectionId - optional
  private getQuestion(questionId: string, sectionId?: string) {
    return sectionId
      ? this.schoolStatistic?.questions.find(q => q.questionId == questionId && q.sectionId == sectionId)
      : this.schoolStatistic?.questions.find(q => q.questionId == questionId);
  }

  private getAnswerName(questionId: string, answerName: string): string {
    if (questionId === this.questionIdForSchoolStatistics) {
      return this.getSchStatAnswerName(answerName);
    } else if (questionId === this.questionIdForProfExpect) {
      return this.getEdLevelAnswerName(answerName);
    } else {
      return answerName;
    }
  }

  private getSchStatAnswerName(answerName: string): string {
    let updatedAnswerName = answerName;
    if (answerName === 'Затрудняюсь ответить') {
      updatedAnswerName = 'Не определились';
    } else if (answerName === 'Я планирую окончить 11 классов и поступить в вуз') {
      updatedAnswerName = 'ВО';
    } else if (answerName === 'Я планирую окончить 9 классов и поступить в колледж') {
      updatedAnswerName = 'СПО';
    }
    return updatedAnswerName;
  }

  private getEdLevelAnswerName(answerName: string): string {
    let updatedAnswerName = answerName;
    const bracketIndex = updatedAnswerName.indexOf('(');
    if (bracketIndex !== -1) {
      updatedAnswerName = updatedAnswerName.substring(0, bracketIndex).trim();
    }
    return updatedAnswerName;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
