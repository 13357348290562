import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TestBreakpointsComponent,
} from 'app/shared/common-components/tests/test-breakpoints/test-breakpoints.component';

@NgModule({
  declarations: [TestBreakpointsComponent],
  imports: [CommonModule],
  exports: [TestBreakpointsComponent],
})
export class TestBreakpointsModule {}
