import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'prf-employer-panel',
  templateUrl: './employer-panel.component.html',
  styleUrls: ['./employer-panel.component.scss'],
})
export class EmployerPanelComponent implements OnInit, OnDestroy {
  public title: string = '';
  public currentUrl: string;

  constructor(private meta: Meta, private router: Router) {
    this.meta.updateTag({
      name: 'og:title',
      content: 'Страница работодателя',
    });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.checkTitle(event.url);
      }
    });
  }

  ngOnInit() {}

  checkTitle(url) {
    if (url == '/employer') {
      this.title = 'Главная';
    } else if (url == '/employer/internships') {
      this.title = 'Стажировки';
    } else if (url == '/employer/exersices') {
      this.title = 'Задания';
    } else if (url == '/employer/courses') {
      this.title = 'Курсы и мероприятия';
    }
  }

  ngOnDestroy() {}
}
