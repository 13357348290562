<div class="recommended-card">
  <div class="recommended-card__image">
    <img [ngSrc]="talent.imageUrl" fill alt="profession-icon" />
  </div>
  <h3 class="recommended-card__name">{{ talent.name }}</h3>
  <div class="recommended-card__more">
    <a class="recommended-card__more-button"> Подробнее </a>
    <div class="recommended-card__more-description">
      <p>{{ talent.description }}</p>
      <img ngSrc="./profilum-assets/images/icons/info-triangle.png" width="12" height="6" class="info-triangle" alt="" />
    </div>
  </div>
</div>
