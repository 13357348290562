import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

import { IFilterClasses } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';

@Component({
  selector: 'prf-search-courses',
  templateUrl: './search-courses.component.html',
  styleUrls: ['./search-courses.component.scss'],
})
export class SearchCoursesComponent extends UnsubscribeComponent implements OnInit {
  public form: UntypedFormGroup;
  public minSearchLength: number = 3;
  private filters: IFilterClasses;

  constructor(private filtersService: CoursesFiltersService, private fb: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.filtersService
      .getCoursesFilters()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((filters: IFilterClasses): void => {
        this.filters = filters;
        this.initSearchForm();
      });
  }

  public initSearchForm(): void {
    this.form = this.fb.group({
      search: new UntypedFormControl('', []),
    });

    this.form.controls['search'].valueChanges
      .pipe(
        debounceTime(400),
        filter((searchTerm: string): boolean => searchTerm?.length >= this.minSearchLength || !searchTerm?.length),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
      )
      .subscribe((searchParam: string): void => {
        this.filters.query = searchParam;
        this.filtersService.setCoursesFilter(this.filters);
      });
  }

  public clearSearch(): void {
    if (!this.form.controls['search'].getRawValue()) return;
    this.form.controls['search'].setValue(null);
    this.filters.query = null;
    this.filtersService.setCoursesFilter(this.filters);
  }
}
