<ng-template [ngIf]="showChapters">
  <h2 class="sections-title">Разделы</h2>
  <a
    *ngFor="let chapterData of chapters; let first = first; let i = index; trackBy: trackByFn"
    (click)="goToLessons(i)"
    class="ce-section-card"
    [ngClass]="{
      'ce-section-card_active': hasPupils && first && !hasPassedLessons,
      'chapter-disabled': !checkIsActiveChapter(i)
    }"
  >
    <div class="ce-section-card__details">
      <h3 class="ce-section-card__title ce-section-card_truncation">{{ chapterData[0] }}</h3>
      <span class="ce-section-card__info">{{ setLessonsInfo(chapterData[1]) }}</span>
    </div>
    <div class="ce-section-card__arrow"></div>
  </a>
</ng-template>
