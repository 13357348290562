import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IBubbleSectionData } from 'app/pages/player/section/section.interfaces';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ETestTypes, ETestTypesSteps } from 'app/shared/enums/testtypes.enum';
import { SlideService } from 'app/shared/services/slide.service';
import { StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { QUESTIONS_WITH_SUBSECTIONS } from 'app/pages/player/questions';

@Component({
  selector: 'prf-agile-bubble-box',
  templateUrl: './agile-bubble-box.component.html',
  styleUrls: ['./agile-bubble-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgileBubbleBoxComponent extends UnsubscribeComponent implements OnInit, AfterContentInit {
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() data: IBubbleSectionData;
  public slideNum;
  public sessionId;
  public visibleAnswers: any[] = [];
  public selectedAnswerNames: string[] = [];
  public userRole: string = '';
  public slidesNumber: string = '';

  public testType: string = '';
  public testTypesEnum = ETestTypes;
  public isBvbTest: boolean = false;
  public showSlideWithSubsections: boolean = false;
  public screenSections: any[] = [];
  public testTypesSteps = ETestTypesSteps;
  private questionId: string;
  private readonly questionsWithSubsections = QUESTIONS_WITH_SUBSECTIONS;

  constructor(
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public slideService: SlideService,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.testType = this.webStorageService.get(StorageKeys.TestType);
    this.isBvbTest = this.testType === ETestTypes.BVB_TEST.toString();

    if (this.testType == null) {
      if (this.userRole == 'pupil') {
        this.testType = ETestTypes.DEFAULT_STANDART.toString();
      }
      if (this.userRole == 'parent') {
        this.testType = ETestTypes.DEFAULT_360.toString();
      } else {
        this.testType = ETestTypes.DEFAULT_STANDART.toString();
      }
    }
  }

  ngOnInit(): void {
    this.slidesNumber = this.webStorageService.get(StorageKeys.SlidesNumber);
    this.slidesNumber = this.slidesNumber ? this.slidesNumber : this.testTypesSteps.DEFAULT_STANDART.toString();
    this.getTranslations([
      'THEMATICS.TECHNICAL_AND_TECHNOLOGY',
      'THEMATICS.SPORT_AND_MILITARY',
      'THEMATICS.CREATIVE',
      'THEMATICS.SOCIAL',
      'THEMATICS.SCIENCE',
      'THEMATICS.COUTURE',
      'THEMATICS.FINANCE',
      'THEMATICS.IT',
      'THEMATICS.LINGUISTIC',
      'THEMATICS.PHYSIC_AND_MATH',
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.screenSections = [
          // порядковый номер, название, все тематики, развернута ли секция
          {
            id: 1,
            name: translations['THEMATICS.TECHNICAL_AND_TECHNOLOGY'],
            fields: [],
            isOpened: false,
          },
          {
            id: 2,
            name: translations['THEMATICS.SPORT_AND_MILITARY'],
            fields: [],
            isOpened: false,
          },
          {
            id: 3,
            name: translations['THEMATICS.CREATIVE'],
            fields: [],
            isOpened: false,
          },
          { id: 4, name: translations['THEMATICS.SOCIAL'], fields: [], isOpened: false },
          {
            id: 5,
            name: translations['THEMATICS.SCIENCE'],
            fields: [],
            isOpened: false,
          },
          {
            id: 6,
            name: translations['THEMATICS.COUTURE'],
            fields: [],
            isOpened: false,
          },
          {
            id: 7,
            name: translations['THEMATICS.FINANCE'],
            fields: [],
            isOpened: false,
          },
          { id: 8, name: translations['THEMATICS.IT'], fields: [], isOpened: false },
          {
            id: 9,
            name: translations['THEMATICS.LINGUISTIC'],
            fields: [],
            isOpened: false,
          },
          {
            id: 10,
            name: translations['THEMATICS.PHYSIC_AND_MATH'],
            fields: [],
            isOpened: false,
          },
        ];
      });

    this.route.params.pipe(take(1)).subscribe(params => {
      this.sessionId = params['ssid'];
    });
    this.slideNum = this.slideService.getCurrenSlideNumber(this.data.sectionId, this.sessionId);

    if (this.questionsWithSubsections.includes(this.questionId)) {
      this.manageSlideWithSubsections();
      this.showSlideWithSubsections = true;
    } else {
      // Для теста престиж профессий из-за перестановок секций, нужно изменить порядок номеров вопросов
      switch (this.slideNum) {
        case 5:
          this.slideNum = 3;
          break;
        case 3:
          this.slideNum = 4;
          break;
        case 6:
          this.slideNum = 5;
          break;
        case 4:
          this.slideNum = 6;
          break;
      }
      this.visibleAnswers =
        this.data.sectionId !== '1770e836-93d7-1702-34b1-755a7ea2f5ec'
          ? this.data.elements.filter(e => e.sectionId === '1770e836-93d7-1702-34b1-755a7ea2f5ec' || e.sectionId === this.data.sectionId)
          : this.data.elements;
    }
  }

  ngAfterContentInit(): void {
    for (let i = 0; i < this.visibleAnswers.length; i++) {
      this.visibleAnswers[i].active = false;
    }
  }

  public onModelChangeElements(selectedElements): void {
    if (selectedElements) {
      this.updateElements(this.data.elements, selectedElements);
      this.updateElements(this.visibleAnswers, selectedElements);
    }
  }

  // актуализация sectionId
  private updateElements(collection, selectedNames): void {
    collection.forEach(e => {
      if (this.data.sectionId === '1770e836-93d7-1702-34b1-755a7ea2f5ec') {
        // первый слайд
        e.sectionId = selectedNames.indexOf(e.text) != -1 ? this.data.sectionId : null;
      } else {
        // остальные слайды - меняем значение только если ответ был выбран на 1-ом слайде (есть sectionId)
        if (e.sectionId) {
          e.sectionId = selectedNames.indexOf(e.text) != -1 ? this.data.sectionId : '1770e836-93d7-1702-34b1-755a7ea2f5ec';
        }
      }
    });
  }

  public checked(element): void {
    element.active = !element.active;
  }

  // Логика отображения слайда со сферами и тематиками внутри каждой из них //

  private manageSlideWithSubsections(): void {
    // группировка тематик по секциям
    this.screenSections.forEach(element => {
      element.fields = this.data.elements.filter(x => x.orderNumber == element.id);
    });
  }

  public selectSection(section: any): void {
    // скрытие-раскрытие секции
    this.screenSections.find(x => x.id == section.id).isOpened = !section.isOpened;
  }

  public checkedSubsection(element): void {
    // выбор-сброс тематики
    element.active = !element.active;
    const needSelect = !element.sectionId;
    if (needSelect) {
      element.sectionId = this.data.sectionId;
      element.sectionType = this.data.sectionType;
    } else {
      element.sectionId = undefined;
      element.sectionType = undefined;
    }
  }

  private getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }
}
