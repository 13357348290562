<div class="modals-wrapper">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="side-modal {{ animate | async }}">
    <button class="close-button" (click)="closeModal()">
      <svg class="close-icon" xmlns="http://www.w3.org/2000/svg">
        <use [attr.href]="'./profilum-components/icons/all-icons.svg#close'"></use>
      </svg>
    </button>
    <ng-container #sideModal></ng-container>
  </div>
</div>
