import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResultsService } from 'app/pages/results/results.service';
import { SharedModule } from 'app/shared/shared.module';
import { PageResultComponent } from './result-page/page-result.component';
import {
  ResultsProfessionPrestigeComponent,
} from './results-profession-prestige/results-profession-prestige.component';
import { ResultsSwitcherComponent } from './results-switcher/results-switcher.component';
import { ForParentResultsComponent } from './for-parent-results/forparentresults.component';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';
import { PromotestResultsComponent } from './promotest-results/promotest-results.component';
import { CommonModule } from '@angular/common';
import { MotivationResultsPageComponent } from './motivation-results-page/motivation-results-page.component';
import { ValuesResultsPageComponent } from './values-results-page/values-results-page.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { ResultsOpenComponent } from './results-open/results-open.component';
import { MincifrydemotestResultsComponent } from './mincifrydemotest-results/mincifrydemotest-results.component';
import { ChoicesProfessionsModule } from 'app/shared/common-components/choices-professions/choices-professions.module';
import { DiagnosticResultsModule } from 'app/shared/common-components/diagnostic-results/diagnostic-results.module';
import {
  TeacherWithNoClassesBannerModule,
} from 'app/shared/common-components/teacher-with-no-classes-banner/teacher-with-no-classes-banner.module';
import { YmReachGoalModule } from 'app/shared/directives/ym-reach-goal/ym-reach-goal.module';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';

@NgModule({
  declarations: [
    ResultsSwitcherComponent,
    ResultsProfessionPrestigeComponent,
    PageResultComponent,
    ForParentResultsComponent,
    PromotestResultsComponent,
    MotivationResultsPageComponent,
    ValuesResultsPageComponent,
    ResultsOpenComponent,
    MincifrydemotestResultsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DashboardModule,
    ChoicesProfessionsModule,
    DiagnosticResultsModule,
    TeacherWithNoClassesBannerModule,
    YmReachGoalModule,
    SwiperDirective,
    BaseChartDirective,
  ],
  providers: [ResultsService, provideCharts(withDefaultRegisterables())],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResultsModule {}
