import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-page-footer-kz',
  templateUrl: './page-footer-kz.component.html',
  styleUrls: ['./page-footer-kz.component.scss'],
})
export class PageFooterKzComponent implements OnInit {
  public yearNumber: number = null;
  public isKz: boolean = true;

  constructor(private translate: TranslateService, private webStorageService: WebStorageService) {}

  ngOnInit() {
    this.yearNumber = new Date().getFullYear();
    this.setLanguage();
  }

  public setLanguage() {
    const language: string = this.webStorageService.get<string>(StorageKeys.SelectedLanguage);
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz';
      this.translate.use(language);
    }
    return;
  }

  getDocsRoute(): string {
    if (this.isKz) {
      return AppSettingsService.settings.docsPath.docsPathRegion;
    } else {
      return AppSettingsService.settings.docsPath.docsPathDefault;
    }
  }
}
