import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys, WebStorageService } from '@profilum-library';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
})
export class TestResultComponent {
  @Input() skillsAttainmentData;
  @Input() weAreParent;

  public userRole: string = '';
  public sessionId: string = '';
  public professionTest: boolean = false;

  constructor(private router: Router, private webStorageService: WebStorageService,) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.sessionId = this.webStorageService.get(StorageKeys.TestSessionId);
  }

  // скрыл методы для прохождения Престижа Профессий
  // async ngOnInit() {
  //   let sessions = await this.pupilService.getTestingSessions(this.webStorageService.get(StorageKeys.UserId));
  //   sessions = sessions.filter(session => session.screeningTestId === this.projectVar.professionTestID);
  //   if (sessions.length === 0) {
  //     this.professionTest = true;
  //   }
  // }

  // async startProfessionPrestigeTest() {
  //   this.webStorageService.set(StorageKeys.TestType, ETestTypes.PROFESSIONPRESTIGE.toString());
  //   this.router.navigate(['/test-na-professiyu']);
  // }
  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
