import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITab, TabStatus } from '../choose-profession.interface';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'prf-prof-tab',
  templateUrl: './prof-tab.component.html',
  styleUrls: ['./prof-tab.component.scss'],
})
export class ProfTabComponent {
  @Input() public tab: KeyValue<string, ITab>;
  @Input() public hasOneTab: boolean = false;
  @Output() public selectTab: EventEmitter<string> = new EventEmitter<string>();

  public get isTabActive(): boolean {
    return this.tab.value.status === TabStatus.ACTIVE;
  }

  public get isTabEnabled(): boolean {
    return this.tab.value.status === TabStatus.ENABLED;
  }

  public get isTabDisabled(): boolean {
    return this.tab.value.status === TabStatus.DISABLED;
  }

  public get isTabCompleted(): boolean {
    return this.tab.value.counter === 0;
  }

  public get counter(): number {
    return this.tab.value.counter > 0 ? this.tab.value.counter : null;
  }

  public onClick(): void {
    this.selectTab.emit(this.tab.key);
  }
}
