import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BvbloginComponent } from 'app/landing/bvb/bvblogin/bvblogin.component';
import { BvbLoginRoutingModule } from 'app/landing/bvb/bvb-routing.module';

@NgModule({
  declarations: [BvbloginComponent],
  imports: [CommonModule, BvbLoginRoutingModule],
  exports: [BvbloginComponent],
})
export class BvbModule {}
