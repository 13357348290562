import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-success-add-popup',
  templateUrl: './success-add-popup.component.html',
  styleUrls: ['./success-add-popup.component.scss'],
})
export class SuccessAddPopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
