import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  InterestsAndSkills,
} from 'app/shared/common-components/prf-tests/interests-and-skills/interests-and-skills.constants';
import { MAX_DIFFERENT } from 'app/shared/dashboard/results/interests-progress-bar/interests-progress-bar.component';
import { CompareModalComponent } from 'app/shared/dashboard/results/compare-modal/compare-modal.component';
import { IInterestsAndSkills, IInterestsAndSkillsText } from './interests-and-skills.interface';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-interests-and-skills',
  templateUrl: './interests-and-skills.component.html',
  styleUrls: ['./interests-and-skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestsAndSkillsComponent implements OnInit {
  public hasChildResult: boolean = false;
  public openResults: boolean = false;
  public userRole: string = '';
  public interestsAndSkills = InterestsAndSkills;
  public testType: string;
  private interests: IInterestsAndSkills[];

  @Input() set interestsAndSkillsData(interestsAndSkillsData: IInterestsAndSkills[]) {
    if (interestsAndSkillsData?.length) {
      this.prepareData(interestsAndSkillsData);
    }
  }

  @Input() set textData(textData: IInterestsAndSkillsText) {
    if (textData) {
      this.interestsAndSkills = textData;
    }
  }

  get interestsData(): IInterestsAndSkills[] {
    return this.interests;
  }

  constructor(private dialog: MatDialog, private router: Router, private webStorageService: WebStorageService) {
    this.openResults = this.router.url.includes('results-open');
    this.testType = this.webStorageService.get(StorageKeys.TestType);
  }

  public ngOnInit(): void {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  public openDialog(skillData): void {
    if (skillData.canCompare) {
      // Выводить окно только если есть возможность сравнивать
      const dialogRef = this.dialog.open(CompareModalComponent);
      dialogRef.componentInstance.data = skillData;
    }
  }

  private prepareData(interestsAndSkillsData: IInterestsAndSkills[]): void {
    // const interests = interestsAndSkillsData.sort((a, b) => b.parentEstimate - a.parentEstimate);
    const interests = interestsAndSkillsData;
    // Вычислить расхождения если они есть
    interests.forEach(item => {
      item.isEqual = Math.abs(item.childEstimate - item.parentEstimate) < 1;
      item.totalEstimate = item.childEstimate ? Math.floor((item.parentEstimate + item.childEstimate) / 2) : item.parentEstimate;
      item.canCompare = item.childEstimate && item.parentEstimate !== item.childEstimate;
      item.problem = interests.length > 1 && Math.abs(item.childEstimate - item.parentEstimate) > MAX_DIFFERENT;

      if (!this.hasChildResult) {
        this.hasChildResult = item.childEstimate > 0;
      }
    });

    this.interests = interests;
  }
}
