<div class="tabs-content w-tab-content children-tabs-container">
  <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
    <div class="tab-pane parent-profile">
      <div>
        <prf-child-code-registration *ngIf="!showWelcomePage && child.id === emptyGuid" [child]="child"></prf-child-code-registration>

        <ng-container *ngIf="showWelcomePage && (!child.isTested || !isDiagnostic)">
          <prf-parent-welcome-page
            [registrationCode]="child.registrationCode"
            [onlyDiagnostic]="isDiagnostic"
            (copyChildCode)="onCopyChildCode($event)"
            [childInfo]="child"
          ></prf-parent-welcome-page>
        </ng-container>

        <ng-container *ngIf="child.id !== emptyGuid && child.isTested && isDiagnostic">
          <div class="parent-menu">
            <div class="parent-menu-row-2">
              <a
                *ngFor="let menuItem of parentMenu"
                (click)="selectParentMenu(menuItem)"
                [class.w--current]="menuItem === selectedParentMenu"
                class="professions-menu-link"
              >
                {{ menuItem.name }}
              </a>
            </div>
          </div>

          <div *ngIf="!childChanged">
            <section *ngIf="selectedParentMenu.key === 'testResult'">
              @if (disableTalentDiagnosticInParentLK) {
                <div class="widget_small">
                  <div class="widget-wrapper">
                    <span class="widget-title">Уважаемый родитель! Расписание и тематика занятий находятся в разработке и будут опубликованы позже </span>
                  </div>
                </div>
              } @else {
                <prf-parent-test-panel
                  *ngIf="resultsLoaded && pupilSessionStart"
                  [parentSessionComplete]="parentSessionComplete"
                  [sessionIDParent]="sessionIDParent"
                  [class.no-display]="!resultsLoaded || parentSessionComplete"
                  [pupilUserId]="child.userId"
                >
                </prf-parent-test-panel>
              }

              <prf-not-passed-test *ngIf="resultsLoaded && !pupilSessionComplete && !parentSessionComplete" [user]="child">
              </prf-not-passed-test>
              <prf-results
                [child]="child"
                [showSwitch]="true"
                [showResultMenu]="true"
                (loaded)="resultsLoaded = $event"
                (pupilSessionComplete)="pupilSessionComplete = $event"
                (parentSessionComplete)="parentSessionComplete = $event"
                (pupilSessionStart)="pupilSessionStart = $event"
              >
              </prf-results>
            </section>

            <section *ngIf="selectedParentMenu.key === 'motivation'">
              <ng-container *ngIf="motivationLastSessionId; else notPassedMotivation">
                <div class="motivation-container">
                  <prf-motivation-results
                    [userRole]="userRole"
                    [pupil]="childInfo"
                    [sessionId]="motivationLastSessionId"
                  ></prf-motivation-results>
                </div>
              </ng-container>

              <ng-template #notPassedMotivation>
                <div class="no-test-block">
                  <div>
                    <img src="./profilum-assets/images/icons/no-test-icon.png" width="80" class="no-profession-icon" />
                  </div>
                  <div class="empty-block-text">
                    {{ 'SHARED.NOT_PASSED_MOTIVATION_TEST' | translate }}
                  </div>
                </div>
              </ng-template>
            </section>

            <section *ngIf="selectedParentMenu.key === 'professions'">
              <prf-not-selected-profession *ngIf="favoriteProfessions.length === 0" [name]="child.firstName"> </prf-not-selected-profession>

              <prf-profession-menu
                *ngIf="favoriteProfessions.length > 0"
                [favoriteProfessions]="favoriteProfessions"
                (selectProfession)="selectedProfession = $event"
              >
              </prf-profession-menu>

              <prf-detailed-profession-card *ngIf="favoriteProfessions.length > 0" [profession]="selectedProfession">
              </prf-detailed-profession-card>
            </section>
            <section *ngIf="selectedParentMenu.key === 'favorites'">
              <prf-user-favorites [user]="child" [favoritesList]="favoritesClasses"></prf-user-favorites>
            </section>

            <section *ngIf="selectedParentMenu.key === 'profile'">
              <prf-parent-pupil-profile [child]="child"></prf-parent-pupil-profile>
            </section>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
