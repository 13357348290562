<section prfSuspensionMessage class="schooladmin">
  <prf-schooladmin-menu></prf-schooladmin-menu>

  <div class="content-section">
    <div class="container">
      <prf-page-header [schoolTitle]="schoolTitle" [title]="setTitleHeader()"> </prf-page-header>

      <router-outlet></router-outlet>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</section>
