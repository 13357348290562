<ng-container [ngSwitch]="userRole">
  <prf-schooladmin-menu *ngSwitchCase="'schooladmin'"></prf-schooladmin-menu>
  <prf-director-menu *ngSwitchCase="'director'"></prf-director-menu>
  <prf-menu *ngSwitchDefault></prf-menu>
</ng-container>

<ng-container #content></ng-container>

<ng-template #defaultTemplate>
  <div class="content-section">
    <div class="container container-1000">
      <prf-page-header [title]="'Мой профиль'" [showClassDropdown]="false"></prf-page-header>
      <div class="profile-section">
        <div class="profile-row" *ngIf="userInfo">
          <div class="profile-column _1">
            <div #nameContainer class="user-name-profile" (mouseenter)="checkTextOverflow()" (mouseleave)="isHovered = false">
              {{ userInfo.firstName }}
              {{ userInfo.middleName && userInfo.middleName.length > 0 ? ' ' + userInfo.middleName : '' }}
              {{ ' ' + userInfo.lastName }}
            </div>
            <div class="name-tooltip" *ngIf="isHovered">
              {{ userInfo.firstName }}
              {{ userInfo.middleName && userInfo.middleName.length > 0 ? ' ' + userInfo.middleName : '' }}
              {{ ' ' + userInfo.lastName }}
            </div>
            <div *ngIf="userClass" class="school">
              {{ userClass.number }} «{{ userClass.letter }}»,
              {{ school && school.number ? school.number : ('SHARED.SCHOOL_NUMBER_NOT_FOUND' | translate) }}
            </div>
            <div class="profile-row-2">
              <div *ngIf="userRole === 'pupil'" class="profile-code">
                <div>{{ 'SHARED.MY_CODE' | translate }}</div>
                <div class="code-text">{{ userInfo.registrationCode }}</div>
                <div class="info-div">
                  <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
                  <div class="info-popup" *ngIf="showInfoPopup">
                    <p>Этот код можно использовать для входа в личный кабинет до смены пароля</p>
                    <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                  </div>
                </div>
              </div>

              <div *ngIf="isParent" class="children-list-row">
                <div class="children-list-text">Дети:</div>
                <div class="children-list-column">
                  <ng-container *ngIf="!mosruUser; else mosruUserChildren">
                    <a
                      *ngFor="let child of userInfo.children"
                      routerLink="/parent/{{ child.userId }}/profile"
                      class="children-list-link w-inline-block"
                    >
                      <ng-container *ngTemplateOutlet="childItem; context: child"></ng-container>
                    </a>
                  </ng-container>
                </div>

                <ng-template #mosruUserChildren>
                  <a
                    *ngFor="let child of children"
                    [ngStyle]="{ color: child.istestedMosru ? none : 'rgba(53,53,53,0.5)' }"
                    [routerLink]="child.sessionId ? '/promotest-results/' + child.sessionId : '/parent/mosrupromo'"
                    class="children-list-link w-inline-block"
                  >
                    <ng-container *ngTemplateOutlet="childItem; context: child"></ng-container>
                  </a>
                </ng-template>
              </div>

              <div *ngIf="userRole === 'pupil'" class="profile-row-2-column">
                <ng-container *ngIf="false"
                  ><!-- Отключил блок, нет функционала -->
                  <div class="parent-row not-invited">
                    <div class="parent-photo"></div>
                    <div><a (click)="toggleInvite(true)">Пригласи родителя</a> для полноценной оценки твоих талантов 2</div>
                  </div>
                </ng-container>

                <ng-container *ngIf="parents.length > 0">
                  <div *ngFor="let parent of parents" class="parent-row not-invited">
                    <div class="parent-photo uploaded" [ngStyle]="{ 'background-image': '' }"></div>
                    <div>
                      <span class="parent-name-profile">
                        {{ parent.firstName }}
                        {{ parent.middleName && parent.middleName.length > 0 ? ' ' + parent.middleName : '' }}
                        {{ ' ' + parent.lastName }}
                      </span>
                      <br />
                      <span class="parent-email">{{ parent.email }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- блок со статичными данными ученика/юзера-->
            <div [class]="'user-data-form-block w-form ' + commonProfileCardAnimation">
              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.lastName }}</div>
                </div>
              </div>

              <div class="div-space-2"></div>

              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.firstName }}</div>
                </div>
              </div>

              <div class="div-space-2"></div>

              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.middleName }}</div>
                </div>
              </div>

              <div class="div-space-2"></div>

              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">{{ 'SHARED.FORM.PHONE' | translate }}:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.phoneNumber }}</div>
                </div>
              </div>

              <div class="div-space-2"></div>

              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">Email:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.email }}</div>
                </div>
              </div>

              <div class="div-space-2"></div>

              <div class="profile-data-row">
                <div>
                  <label class="user-data-label">Дата рождения:</label>
                  <div class="div-space"></div>
                </div>
                <div class="profile-field-div">
                  <div class="profile-data-text">{{ userInfo.birthday }}</div>
                </div>
              </div>

              <div class="profile-data-row gender-choice">
                <label class="login-label">Пол:</label>
                <div class="div-space"></div>
                <div class="gender-choice-row">
                  <a class="gender-button" [ngClass]="{ active: getMale() == true }"> М </a>
                  <a class="gender-button right" [ngClass]="{ active: getMale() !== true }"> Ж </a>
                </div>
              </div>

              <div class="div-space-2"></div>

              <button *ngIf="!userRestriction" (click)="enableEditProfile()"
                      class="white-button edit-profile-data-button w-button">{{
                'SHARED.EDIT_BUTTON' | translate
                }}
              </button>
            </div>

            <!-- блок с данными для редактирования-->
            <prf-edit-user-profile
              [class]="'left-margin ' + editProfileCardAnimation"
              (editProfile)="disableEditProfile()"
              [user]="userInfo"
            ></prf-edit-user-profile>
          </div>
          <div class="profile-column">
            <div class="user-avatar">
              <img src="{{ userAvatar }}" alt="" class="user-avatar_image" />
              <div class="user-avatar_change-background">
                <button type="button" (click)="upload.click()" class="user-avatar_button">Изменить</button>
                <input type="file" (change)="openImageEditor($event)" #upload hidden />
              </div>
            </div>

            <mat-error *ngIf="uploadImageError" class="upload-image-error">{{ uploadImageError }}</mat-error>
            <!-- блок для смены пароля -->
            <div class="profile-changePassword">
              <ng-container *ngTemplateOutlet="passwordChange"></ng-container>
            </div>
          </div>
        </div>
        <a (click)="logOut()" class="exit w-inline-block">
          <img src="./profilum-assets/images/icons/exit-icon.svg" class="exit-profile-icon" />
          <div>Выход</div>
        </a>
      </div>
      <div class="invite-parent-lb lb" [ngStyle]="{ display: isInviteVisible ? 'block' : 'none' }">
        <div class="lb-bg"></div>
        <div class="lb-block">
          <div class="invite-parent-row">
            <div class="invite-parent-column">
              <h3 class="h3-invite-parent">Пригласить родителя</h3>
              <p class="invite-parent-text">
                Для полноценной оценки твоих талантов пригласи родителя. Напиши email и родителю придет приглашение.
              </p>
              <div class="w-form">
                <form id="wf-form-Invite-Parent" name="wf-form-Invite-Parent" data-name="Invite Parent">
                  <label for="Parent-Email" class="invite-parent-label">Email родителя</label>
                  <input
                    type="email"
                    class="user-data-field invite-field w-input"
                    maxlength="70"
                    name="Parent-Email"
                    data-name="Parent Email"
                    placeholder="thealexganin@gmail.com"
                    id="Parent-Email"
                    required=""
                  />
                  <input
                    type="submit"
                    value="{{ 'SHARED.SEND_INVITE' | translate }}"
                    data-wait="Please wait..."
                    class="button invite-parent-button w-button"
                  />
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
            <img
              src="./profilum-assets/images/illustrations/invite-parent.png"
              width="279"
              srcset="
                ./profilum-assets/images/illustrations/invite-parent-p-500.png 500w,
                ./profilum-assets/images/illustrations/invite-parent.png       558w
              "
              sizes="(max-width: 767px) 100vw, 279px"
              class="invite-parent-image"
            />
          </div>
          <a (click)="toggleInvite(false)" class="close-lb w-inline-block">
            <img src="./profilum-assets/images/icons/close-lb.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <prf-page-footer></prf-page-footer>
</ng-template>

<ng-template #childItem let-lastName="lastName" let-firstName="firstName" let-imagePath="imagePath">
  <img src="{{ imagePath ? imagePath : './profilum-assets/images/icons/no-photo.svg' }}" alt="" class="child-avatar_image" />
  <div>{{ lastName }} {{ firstName }}</div>
</ng-template>
<ng-template #passwordChange>
  <div class="profile-changePassword-text" (click)="changePasswordFlag = !changePasswordFlag">
    {{ 'SHARED.CHANGE_PASSWORD' | translate }}
  </div>
  <div class="profile-changePassword-block" *ngIf="changePasswordFlag">
    <div class="overlay">
      <form id="form-change-pass" name="wf-form-User-Data" data-name="User Data" [formGroup]="form" autocomplete="off">
        <div class="changePass_box">
          <!-- поп-ап с полями паролей -->
          <div class="profile-data-row">
            <div>
              <label for="PasswordOld" class="user-data-label">
                <span class="password-label">{{ 'SHARED.OLD_PASSWORD' | translate }}:</span>
              </label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div">
              <input
                [type]="isMaskedPasswordOld ? 'password' : 'text'"
                class="user-data-field w-input"
                name="PasswordOld"
                data-name="PasswordOld"
                id="PasswordOld"
                formControlName="passwordOld"
                autocomplete="off"
              />
              <div
                [ngClass]="{
                  eye: !isMaskedPasswordOld,
                  eye__closed: isMaskedPasswordOld
                }"
                (click)="toggleMaskOld()"
              ></div>
              <div class="alertred" *ngIf="submitted">
                <mat-error *ngIf="errorOldPass">
                  {{ errorOldPass }}
                </mat-error>
              </div>
            </div>
          </div>

          <div class="div-space-2"></div>
          <div class="profile-data-row">
            <div>
              <label for="Password" class="user-data-label">
                <span class="password-label">{{ 'PASS_CHANGE.NEW_PASSWORD' | translate }}:</span>
              </label>
              <div class="div-space"></div>
            </div>
            <div class="profile-field-div" (focusout)="focusOutErrorChecking()" (focusin)="focusOutPasswordErrors = false">
              <input
                [type]="isMaskedPassword ? 'password' : 'text'"
                class="user-data-field w-input short-right"
                [class.error-field]="submitted && formControls.password.errors"
                maxlength="256"
                name="Password"
                data-name="Password"
                id="Password"
                formControlName="password"
                (ngModelChange)="testPassword($event)"
                (focus)="popupPassword.style.display = 'block'"
                (focusout)="popupPassword.style.display = 'none'"
                autocomplete="off"
              />

              <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
              <div
                class="info-popup recommendation w-hidden-small w-hidden-tiny hide-popup"
                #popupPassword
                [style.display]="errorChecking() ? 'block' : 'none'"
              >
                <p>Требования к паролю (не менее)</p>
                <ol class="info-exacting">
                  <li class="info-pswrd-icon info-popup-pswrd" [class.not-correct]="charactersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.6_CHARACTERS' | translate }}
                  </li>
                  <li class="info-pswrd-icon info-popup-letter" [class.not-correct]="letterError">строчные и заглавные латинские буквы</li>
                  <li class="info-pswrd-icon info-popup-digit" [class.not-correct]="numberError">1 цифра</li>
                </ol>
                <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
              </div>
              <div class="error-field-container" *ngIf="focusOutPasswordErrors">
                <div>
                  {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_FORMAT' | translate }}
                </div>
                <ul>
                  <li *ngIf="charactersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.LESS_6_CHARACTERS' | translate }}
                  </li>
                  <li *ngIf="letterError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_LOWERCASE_OR_UPPERCASE_LETTER' | translate }}
                  </li>
                  <li *ngIf="numberError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.NO_DIGIT' | translate }}
                  </li>
                  <li *ngIf="whiteSpaceError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.INCORRECT_CHARACTERS' | translate }}
                  </li>
                  <li *ngIf="rusLettersError">
                    {{ 'OPEN_REGISTRATION_KZ.FORM_ALERT.RUS_LETTERS' | translate }}
                  </li>
                </ul>
              </div>

              <ng-container *ngIf="submitted">
                <mat-error *ngIf="errorChangePass">
                  {{ errorChangePass }}
                </mat-error>
              </ng-container>
            </div>
          </div>

          <!-- кнопки -->
          <div class="profile-data-row">
            <div class="div-space-3"></div>
            <div class="edit-profile-data-buttons">
              <a (click)="submitChanges()" [ngClass]="{ disabled_: !isAccessAllowed() }" class="button save-profile-changes-button w-button"
                >Сохранить</a
              >
              <a class="white-button w-button" (click)="changePasswordFlag = !changePasswordFlag; cancelEdit(); clearPassError()"
                >Отменить</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #avatarEdit>
  <prf-pupil-mobile-edit-user-avatar
    (saveAvatar)="closeAvatarEdit(true)"
    (return)="closeAvatarEdit(false)"
  ></prf-pupil-mobile-edit-user-avatar>
</ng-template>
