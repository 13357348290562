import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderLoginLinkComponent } from '../../components/header/header-login-link/header-login-link.component';
import { HeaderLogoLinkComponent } from '../../components/header/header-logo-link/header-logo-link.component';
import {
  HeaderBurgerButtonComponent,
} from '../../components/header/header-burger-button/header-burger-button.component';
import { HeaderNavbarComponent } from '../../components/header/header-navbar/header-navbar.component';
import {
  HeaderDropdownMenuComponent,
} from '../../components/header/header-dropdown-menu/header-dropdown-menu.component';
import { PrfHeader } from '../../components/header/header.types';
import { StickyHeaderDirective } from '../../../../directives/sticky-header/sticky-header.directive';

@Component({
  selector: 'prf-header',
  standalone: true,
  imports: [
    CommonModule,
    StickyHeaderDirective,
    HeaderLoginLinkComponent,
    HeaderLogoLinkComponent,
    HeaderBurgerButtonComponent,
    HeaderNavbarComponent,
    HeaderDropdownMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() headerData: PrfHeader;
}
