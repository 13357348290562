import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COURSES_TYPES } from 'app/shared/global-constants/constants';
import { UtilsService } from '../../../backend-services/utils.service';
import { EUserTags, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-promotest-course-card',
  templateUrl: './promotest-course-card.component.html',
  styleUrls: ['./promotest-course-card.component.scss'],
})
export class PromotestCourseCardComponent implements OnInit {
  @Input() set course(val: any) {
    this._course = val;
  }
  get course() {
    return this._course;
  }
  private _course: any;
  public classesFormats = COURSES_TYPES;
  isVGuser: boolean = false;

  constructor(private utilsService: UtilsService, private router: Router, private webStorageService: WebStorageService) {
    this.isVGuser = this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString();
  }

  ngOnInit() {}

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  copyPromocode(textToCopy: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.utilsService.openSnackBar('Промокод скопирован', 'success', 2500);
  }

  checkCourseFormat(format: string) {
    return this.classesFormats.find(f => f.value === format).viewValue;
  }

  navigateCourse(url: string) {
    if (this.isVGuser) {
      this.router.navigate([`/vorobievi-gori/partners-courses-all/${this.course.hrid}`]);
      // url = 'www.mos.ru/pgu/ru/application/dogm/077060701/#step_1';
      // return this.router.navigate([]).then(result => {
      //   window.open('https://' + url, '_blank');
      // });
    } else {
      if (url && url.length) {
        return this.router.navigate([]).then(result => {
          window.open('https://' + url, '_blank');
        });
      }
    }
  }

  calculateRealPrice(course: any) {
    const discount = +course.unitDiscount;
    const price = +course.unitPrice;
    if (course.isPercent) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }

  getCrossingLineAngle(): number {
    const elementWidth = document.getElementById('id_button_undiscounted').offsetWidth;
    const elementHight = document.getElementById('id_button_undiscounted').offsetHeight;
    return (Math.atan(elementHight / elementWidth) * 180) / Math.PI;
  }
  getCrossingLineLength(): number {
    const elementWidth = document.getElementById('id_button_undiscounted').offsetWidth;
    const elementHight = document.getElementById('id_button_undiscounted').offsetHeight;
    const angle = Math.atan(elementHight / elementWidth);
    return elementWidth / Math.cos(angle);
  }
}
