<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share" [class.popup_confirm]="popupConfirm" [class.popup_loading]="loadingPopup">
      <a *ngIf="!loadingPopup" (click)="closePopup()" class="close-lb w-inline-block">
        <img src="./profilum-assets/images/landing/close-lb.svg" alt="" />
      </a>

      <ng-container *ngIf="succesIcon">
        <svg width="44" height="32" viewBox="0 0 44 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.45015 16C7.0845 16 6.74929 15.877 6.47505 15.6004L0.411372 9.48319C-0.137104 8.92987 -0.137104 8.06917 0.411372 7.51586C0.959849 6.96254 1.813 6.96254 2.36148 7.51586L7.48061 12.6494L19.6385 0.414982C20.187 -0.138332 21.0402 -0.138332 21.5887 0.414982C22.1371 0.968296 22.1371 1.82901 21.5887 2.38233L8.45566 15.6004C8.15096 15.877 7.8158 16 7.45015 16Z"
            transform="scale(1.5)"
            fill="#36CA75"
          />
        </svg>
      </ng-container>

      <div *ngIf="!loadingPopup" class="popup-share-header">
        <h3 class="popup-share-title">
          <ng-content select=".title"></ng-content>
        </h3>
      </div>
      <div *ngIf="!loadingPopup" class="popup-share-content">
        <p class="popup-share-text" [class.popup-share-text_highlighted]="isTextHighlighted">
          <ng-content select=".text"></ng-content>
        </p>
      </div>

      <ng-content select=".content"></ng-content>

      <div *ngIf="!loadingPopup" class="popup-share-buttons">
        <button
          (click)="confirmAction()"
          class="button"
          [class.button_secondary]="popupConfirm && dualAcation"
          [class.disabled]="disableButton || waiting"
        >
          <ng-container *ngIf="!waiting; else loader">
            <ng-content select=".button-content"></ng-content>
          </ng-container>
        </button>
        <button *ngIf="dualAcation" (click)="cancelAction()" class="button" [class.disabled]="disableButton">
          <ng-content select=".button-content-cancel"></ng-content>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loader>
  <div class="loader-wrapper button-content">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
