<section>
  <div>
    <div class="director" *ngIf="viewData">
      <ng-container *ngIf="!showPupilPage; else pupilPage">
        <form [formGroup]="form">
          <div class="director_content">
            <div class="section_filters">
              <div class="filters_row _1">
                <div class="dropdown_filter _talants">
                  <label for="_talantFilter">
                    <div class="filter-title">
                      {{ 'DIRECTOR.TALANT' | translate }}
                    </div>
                    <prf-dropdown id="_talantFilter" [itemsList]="talantsList" formControlName="talantFilter"></prf-dropdown>
                  </label>
                </div>
                <div class="dropdown_filter _class_profils">
                  <label for="_classProfilsFilter">
                    <div class="filter-title">
                      {{ 'DIRECTOR.CLASS_PROFIL' | translate }}
                    </div>
                    <prf-dropdown
                      id="_classProfilsFilter"
                      [itemsList]="classesProfilsList"
                      formControlName="classProfilFilter"
                    ></prf-dropdown>
                  </label>
                </div>
                <div class="filter-tabs-block _progress_filter">
                  <label for="_tabs_progress">
                    <div class="filter-title">
                      {{ 'DIRECTOR.PROGRESS' | translate }}
                    </div>
                    <div class="filter-tabs" id="_tabs_progress">
                      <button
                        class="form-button square-button inactive"
                        [class.w-current]="form.get('progressFilter').value === 'downside'"
                        [value]="'downside'"
                        (click)="setFormControl('progressFilter', $event.target['value'])"
                      >
                        <mat-icon svgIcon="icon_downside" class="progress_icon" placement="center"></mat-icon>
                      </button>
                      <button
                        class="form-button square-button inactive"
                        [class.w-current]="form.get('progressFilter').value === 'upside'"
                        [value]="'upside'"
                        (click)="setFormControl('progressFilter', $event.target['value'])"
                      >
                        <mat-icon svgIcon="icon_upside" class="progress_icon" placement="center"></mat-icon>
                      </button>
                    </div>
                  </label>
                </div>

                <div class="dropdown_filter _education_level">
                  <label for="_educationLevel">
                    <div class="filter-title">
                      {{ 'DIRECTOR.EDUCATION_LEVEL' | translate }}
                    </div>
                    <prf-dropdown id="_educationLevel" [itemsList]="educationLevelsList" formControlName="educationLevel"></prf-dropdown>
                  </label>
                </div>
                <div class="dropdown_filter _education_planning">
                  <label for="_educationPlanning">
                    <div class="filter-title">
                      {{ 'DIRECTOR.EDUCATION_PLANNING' | translate }}
                    </div>
                    <prf-dropdown
                      id="_educationPlanning"
                      [itemsList]="educationPlanningList"
                      formControlName="educationPlanning"
                    ></prf-dropdown>
                  </label>
                </div>
                <div class="filter-tabs-block _gender_filter">
                  <label for="_tabs_gender">
                    <div class="filter-title">{{ 'SHARED.GENDER' | translate }}</div>
                    <div class="filter-tabs" id="_tabs_gender">
                      <button
                        class="form-button square-button"
                        [class.w-current]="form.get('gender').value === 'M'"
                        [value]="'M'"
                        (click)="setFormControl('gender', $event.target['value'])"
                      >
                        {{ 'BUTTONS.GENDER.M' | translate }}
                      </button>
                      <button
                        class="form-button square-button"
                        [class.w-current]="form.get('gender').value === 'F'"
                        [value]="'F'"
                        (click)="setFormControl('gender', $event.target['value'])"
                      >
                        {{ 'BUTTONS.GENDER.F' | translate }}
                      </button>
                    </div>
                  </label>
                </div>
              </div>
              <div class="filters_row _2">
                <div class="dropdown_filter _subject_do">
                  <label for="_subjectDOfilter">
                    <div class="filter-title">
                      {{ 'DIRECTOR.SUBJECT_DO_INTERESTED' | translate }}
                    </div>
                    <prf-dropdown id="_subjectDOfilter" [itemsList]="subjectsDOList" formControlName="subjectDOfilter"></prf-dropdown>
                  </label>
                </div>
                <a class="clear-categories events-clear" [class.show]="checkFilters()" (click)="clearAllFilters()">{{
                  'SHARED.CLEAR_FILTERS' | translate
                }}</a>
                <div class="empty-tab"></div>
              </div>

              <div class="filter-tabs-block _classes_filter">
                <label for="_classesFilter">
                  <div class="filter-title">
                    <div class="filter-tabs" id="_classesFilter">
                      <button
                        class="form-button class-button"
                        [class.w-current]="form.get('classFilter').value === '7'"
                        [value]="7"
                        (click)="setFormControl('classFilter', $event.target['value'])"
                      >
                        7 {{ 'SHARED.CLASS_LOWERCASE' | translate }}
                      </button>
                      <button
                        class="form-button class-button"
                        [class.w-current]="form.get('classFilter').value === '8'"
                        [value]="'8'"
                        (click)="setFormControl('classFilter', $event.target['value'])"
                      >
                        8 {{ 'SHARED.CLASS_LOWERCASE' | translate }}
                      </button>
                      <button
                        class="form-button class-button"
                        [class.w-current]="form.get('classFilter').value === '9'"
                        [value]="'9'"
                        (click)="setFormControl('classFilter', $event.target['value'])"
                      >
                        9 {{ 'SHARED.CLASS_LOWERCASE' | translate }}
                      </button>
                      <button
                        class="form-button class-button"
                        [class.w-current]="form.get('classFilter').value === '10'"
                        [value]="'10'"
                        (click)="setFormControl('classFilter', $event.target['value'])"
                      >
                        10 {{ 'SHARED.CLASS_LOWERCASE' | translate }}
                      </button>
                      <button
                        class="form-button class-button"
                        [class.w-current]="form.get('classFilter').value === '11'"
                        [value]="'11'"
                        (click)="setFormControl('classFilter', $event.target['value'])"
                      >
                        11 {{ 'SHARED.CLASS_LOWERCASE' | translate }}
                      </button>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <a class="_button report-button" (click)="getDistribution()">{{ 'BUTTONS.GET_PUPILS_DISTRIBUTION' | translate }}</a>
            <div class="clear_float"></div>

            <div class="section_pupilslist">
              <div class="class-div" *ngIf="pupils && dataFetched; else emptyResults">
                <div class="class-header _2" [class.fixed]="isFixed">
                  <div class="class-row class-header-row-3">
                    <div class="class-column _9"></div>
                    <div class="class-column _4">
                      <div>{{ pupils.length + ' ' }}{{ 'SHARED.PUPILS_GENITIVE' | translate }}</div>
                    </div>
                    <div class="class-column _8">
                      <div>
                        {{ 'SHARED.PROFESSION' | translate }}
                      </div>
                    </div>
                    <div class="class-column _6">
                      <div>
                        {{ 'SHARED.TEST_HAS_COMPLETED' | translate }}
                      </div>
                      <div class="class-test-row">
                        <div class="class-test-column">
                          <div>
                            {{ 'SHARED.PARENT' | translate }}
                          </div>
                        </div>
                        <div class="class-test-column">
                          <div>
                            {{ 'SHARED.PUPIL' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pupils-list-block">
                  <div class="students-list scrollable-container">
                    <!--Pupil-->
                    <div
                      class="class-list-item-2"
                      *ngFor="let pupil of pupils; let i = index"
                      [ngClass]="pupils.length === i ? 'no-underline' : ''"
                    >
                      <div>
                        <div class="class-row-2">
                          <!--Number-->
                          <div class="class-column _9">
                            <div class="class-header-number _2">{{ i + 1 }}</div>
                          </div>

                          <!--Check registration-->
                          <!--if registrated-->
                          <div class="class-row-div">
                            <div class="class-column _7">
                              <div class="class-column _2 _10">
                                <!--Avatar-->
                                <div class="class-photo-column">
                                  <a
                                    class="class-photo w-inline-block"
                                    (click)="showSelectedPupil(pupil)"
                                    [ngStyle]="{
                                      'background-image': 'url(' + getImageUrl(pupil) + ')'
                                    }"
                                  ></a>
                                  <!-- <a class="class-photo w-inline-block"></a> -->
                                </div>
                              </div>
                              <!--details-->
                              <div class="class-column _4">
                                <div class="student-name">
                                  <a class="student-name-link" (click)="showSelectedPupil(pupil)">
                                    {{ pupil.fullName }}
                                  </a>
                                </div>
                                <!--<div class="student-online-status">Была только что???</div>-->
                              </div>
                            </div>
                            <!-- Professions -->
                            <div class="class-column _8">
                              <div class="prof-row">
                                <ng-container *ngIf="favoriteProfessions && pupil.favoriteProfessions.length > 0">
                                  <div class="prof-icon" *ngFor="let prof of pupil.favoriteProfessions | slice : 0 : 2">
                                    <img
                                      src="{{
                                        getImagePath(prof) ? getImagePath(prof) : './profilum-assets/images/illustrations/petard.png'
                                      }}"
                                      class="student-prof-icon"
                                      (mouseenter)="showElement($event)"
                                      (mouseleave)="hideElement($event)"
                                    />
                                    <div class="info-popup stud-prof">
                                      <p>{{ prof?.name }}</p>
                                      <img
                                        src="./profilum-assets/images/icons/info-triangle.png"
                                        width="12"
                                        class="info-triangle stud-prof"
                                      />
                                    </div>
                                  </div>
                                </ng-container>

                                <div class="prof-icon num" *ngIf="pupil.favoriteProfessions.length > 2">
                                  {{ '+' + (pupil.favoriteProfessions.length - 2) }}
                                </div>
                              </div>
                            </div>
                            <!--Test-->
                            <div class="class-column _6">
                              <div class="class-test-row">
                                <!--Parent-test-->
                                <div class="class-test-column test">
                                  <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                                </div>
                                <!--Pupil-Test-->
                                <div class="class-test-column test">
                                  <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                                </div>
                              </div>
                            </div>

                            <ng-template #testNotCompleted>
                              <div class="test-check"></div>
                            </ng-template>

                            <!--if last class class-line no-underline-->
                            <div class="class-line" [ngClass]="pupils.length === i ? 'no-underline' : ''"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #pupilPage>
  <prf-teacher-student-page [pupil]="selectedPupil"></prf-teacher-student-page>
</ng-template>

<ng-template #emptyResults>
  <div *ngIf="dataFetched" class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      Ничего не найдено.<br />
      Попробуйте изменить критерии поиска
    </div>
  </div>
</ng-template>
