import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { IData, UserActionsService, YmItems } from '@profilum-library';

import { IButtonOnbording, IMessageOnbording } from 'app/shared/interfaces/common/ondording.interface';
import { ConfettiComponent } from '@profilum-components/confetti/confetti.component';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-chat',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ConfettiComponent],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent extends UnsubscribeComponent implements OnChanges {
  @ViewChild('list') public list: ElementRef;
  @Input() public messages: IMessageOnbording[] = [];
  @Input() public confetti: boolean = false;
  @Input() public buttons: IButtonOnbording[] = [];
  @Input() public delay: number = 200;
  @Input() public endMessageNumber: number;
  @Input() public startMessageNumber: number = 0;
  @Output() private buttonAction: EventEmitter<IButtonOnbording> = new EventEmitter<IButtonOnbording>();

  public userMetricsData: Record<string, any>;
  public showedMessages: IMessageOnbording[] = [];
  private showMessagesTimer: ReturnType<typeof window.setTimeout>;

  constructor(private changeDetectorRef: ChangeDetectorRef, private userActionsService: UserActionsService) {
    super();
    this.userActionsService.setInitializationTime([YmItems.S_ChatBot_Complete]);
    this.prepareUserMetricsData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      for (let i = this.showedMessages?.length; i < this.messages?.length; i++) {
        this.showMessagesTimer = this.timer = setTimeout(() => {
          this.showedMessages.push(this.messages[i]);
          this.changeDetectorRef.detectChanges();
          this.scrollToBottom();
        }, (i - this.showedMessages.length + 1) * this.delay);
      }
      this.scrollToBottom();
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  public showActions(button: IButtonOnbording): void {
    clearTimeout(this.showMessagesTimer);
    this.showedMessages = this.messages;

    if (button.type === 'CLOSE') {
      this.sendMetrics('onFinish');
    } else {
      this.sendMetrics('onButtonClick', button.text);
    }

    this.buttonAction.emit(button);
  }

  public scrollToBottom(): void {
    const container: HTMLElement = document.getElementById('list');
    this.timer = setTimeout(() => {
      container.scroll({
        behavior: 'smooth',
        top: this.list.nativeElement.scrollHeight,
      });
    }, 0);
  }

  public prepareUserMetricsData(): void {
    this.userMetricsData = history.state;
    delete this.userMetricsData.navigationId;
    this.sendMetrics('onEnter');
  }

  public sendMetrics(type: 'onEnter' | 'onFinish' | 'onButtonClick', buttonText: string = ''): void {
    if (!Object.keys(this.userMetricsData).length) return;

    const uaParams: IData = {
      Diagnostic: this.userMetricsData,
    };
    const ymParams: IData = {
      event_label: this.userMetricsData,
    };

    delete ymParams.event_label.Question;
    delete ymParams.event_label.Answer;
    delete uaParams.Diagnostic.Question;
    delete uaParams.Diagnostic.Answer;

    switch (type) {
      case 'onEnter':
        this.userActionsService.log(YmItems.S_ChatBot_Open, uaParams, ymParams);
        break;

      case 'onFinish':
        this.userActionsService.log(YmItems.S_ChatBot_Complete, uaParams, ymParams);
        break;

      case 'onButtonClick':
        ymParams.event_label.Question = this.showedMessages[this.showedMessages.length - 1].text;
        ymParams.event_label.Answer = buttonText;

        uaParams.Diagnostic.Question = this.showedMessages[this.showedMessages.length - 1].text;
        uaParams.Diagnostic.Answer = buttonText;

        this.userActionsService.log(YmItems.S_ChatBot_PressButton, uaParams, ymParams);
        break;

      default:
        break;
    }
  }
}
