import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import {
  ApiCoursesMaterialsService,
  ApiUsersService,
  B2gSaasService,
  ICompletedTestsCount,
  ISchool,
  ISchoolClass,
  RamStorageService,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { IPupil } from 'app/shared/interfaces/ipupil.interface';
import { MenuService } from 'app/shared/dashboard/menus/menu/menu.service';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { MenuItems } from 'app/shared/enums/menu.enums';

@Component({
  selector: 'prf-teacher-panel',
  templateUrl: './teacher-panel.component.html',
  styleUrls: ['./teacher-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeacherPanelComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public pupilsFlag: boolean = false;
  public headerTitle: string = '';
  public schoolTitle: string;
  public isResults: boolean = false;
  public selectedClass: ISchoolClass;
  public classList: ISchoolClass[];
  private school: ISchool;
  private schoolClass: ISchoolClass;
  private schoolClassId: string;

  @Input() pupils: IPupil[] = [];

  constructor(
    private meta: Meta,
    private apiUsersService: ApiUsersService,
    private menuService: MenuService,
    private b2gSaasService: B2gSaasService,
    private overlayService: OverlayBusyService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private router: Router,
    private rootScopeService: RootScopeService,
    private ramStorageService: RamStorageService,
    private webStorageService: WebStorageService,
  ) {
    super();
    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Pupils);
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет учителя' });
  }

  ngOnInit(): void {
    // todo вынести класс на отдельный роут и компонент
    this.overlayService.show();
    this.menuService
      .getResultState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => (this.isResults = data.showResults));

    // запрашиваем информацию о школе учителя
    this.b2gSaasService
      .getSchool()
      .pipe(
        switchMap(school => {
          this.school = school;
          this.schoolTitle = school.number;
          school?.cityId ? this.webStorageService.set(StorageKeys.CityId, school.cityId) : null;

          return this.apiUsersService.getSchoolClassesTeachers();
        }),
        switchMap(scResp => {
          this.classList = scResp;
          this.schoolClass = scResp[0];
          this.schoolClassId = this.schoolClass.id;

          return this.apiUsersService.getCompletedTestsCountTeachers(this.school.id);
        }),
        tap((completedTestClasses: ICompletedTestsCount[]) => {
          this.classList.forEach((currentClass: ISchoolClass) => {
            const result = completedTestClasses.filter(completedTestClass => completedTestClass.schoolClassId === currentClass.id);

            result && result.length ? (currentClass.isTestedPupils = result[0].count) : '';
          });

          this.overlayService.hide();
        }),
        switchMap(() => {
          return combineLatest(
            this.classList.map(schoolClass => {
              return this.apiCoursesMaterialsService.getCourseByClass(schoolClass.id).pipe(
                map(course => {
                  return { course: course.courseIds[0] ?? null, schoolClass: schoolClass };
                }),
              );
            }),
          );
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    this.rootScopeService.currentCLass
      .pipe(
        filter(v => v !== null),
        takeUntil(this.unsubscribe),
      )
      .subscribe(selectedClass => {
        if (selectedClass) {
          this.router.navigate(['/teacher-class-results']);
        }
      });
  }

  public openClass(classInfo: ISchoolClass): void {
    this.webStorageService.set(StorageKeys.TeacherStatus, 'multi');
    this.router.navigate(['/teacher-class-results']);
  }
}
