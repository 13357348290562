import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import EmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { EventHandlerType } from 'embla-carousel/components/EventHandler';
import { PrfSliderCard } from '../../../components/diagnostics-of-interests/diagnostics-of-interests.types';
import {
  DiagnosticsOfInterestsCardComponent,
} from '../../../components/diagnostics-of-interests/diagnostics-of-interests-slider/diagnostics-of-interests-card/diagnostics-of-interests-card.component';

@Component({
  selector: 'prf-diagnostics-of-interests-slider',
  standalone: true,
  imports: [CommonModule, DiagnosticsOfInterestsCardComponent],
  templateUrl: './diagnostics-of-interests-slider.component.html',
  styleUrl: './diagnostics-of-interests-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsOfInterestsSliderComponent implements AfterViewInit, OnDestroy {
  @Input() sliderCards: PrfSliderCard[];
  @ViewChild('swiperViewport') swiperViewport: ElementRef;
  @ViewChild('swiperProgressBar') swiperProgressBar: ElementRef;
  private emblaEventsHandler: EventHandlerType;

  public ngAfterViewInit() {
    if (!this.swiperViewport?.nativeElement) return;

    const config: EmblaOptionsType = {
      slidesToScroll: 'auto',
      dragFree: true,
      active: true,
      breakpoints: { '(min-width: 1281px)': { active: false } },
    };
    const emblaApi = EmblaCarousel(this.swiperViewport.nativeElement, config, [WheelGesturesPlugin()]);

    if (this.swiperProgressBar?.nativeElement) {
      this.onScroll(emblaApi);
      this.emblaEventsHandler = emblaApi.on('reInit', this.onScroll).on('scroll', this.onScroll).on('slideFocus', this.onScroll);
    }
  }

  public ngOnDestroy() {
    if (this.emblaEventsHandler) {
      this.emblaEventsHandler.clear();
    }
  }

  private onScroll = (emblaApi: EmblaCarouselType) => {
    const progress = Math.max(0.3, Math.min(1, emblaApi.scrollProgress()));

    (this.swiperProgressBar.nativeElement as HTMLElement).style.transform = `translate3d(${progress * 100}%,0px,0px)`;
  };
}
