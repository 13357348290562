import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import {
  ApiCoursesMaterialsService,
  ApiFieldsService,
  ApiProfessionsService,
  B2gTinderService,
  IProfessionFilter,
  IStorageHelper,
  StorageHelper,
  StorageKeys,
  Storages,
  UserDataHandlerService,
} from '@profilum-library';

@Injectable({
  providedIn: 'root',
})
export class ProfessionsResolver {
  private storageHelper: IStorageHelper;

  constructor(
    private router: Router,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private userDataHandlerService: UserDataHandlerService,
    private apiProfessionsService: ApiProfessionsService,
    private apiFieldsService: ApiFieldsService,
    private tinderService: B2gTinderService,
  ) {
    this.storageHelper = new StorageHelper(Storages.Local);
  }

  resolve(route: ActivatedRouteSnapshot) {
    const resolver = new Subject();
    const fieldId = route.queryParams.fieldId;
    const lessonId = route.queryParams.lessonId;
    const regionId: string = this.userDataHandlerService.getUserInfo().regionId;
    const taskId = this.storageHelper.get(StorageKeys.TaskId);
    const tinderType: number = this.storageHelper.get(StorageKeys.TinderType);

    if (!lessonId || !fieldId) {
      this.router.navigate(['/pupil']);
      return null;
    }
    switch (tinderType) {
      case 1:
      case 2: {
        this.tinderService
          .getProfessions({ tinderType })
          .pipe(
            switchMap(professions => {
              if (professions.professions?.length) {
                resolver.next(professions?.professions);
                resolver.complete();
                return of(null);
              } else {
                return this.tinderService.fixProfessions(tinderType);
              }
            }),
          )
          .subscribe(response => {
            if (response) {
              resolver.next(null);
              resolver.complete();
            }
          });
        break;
      }
      default: {
        forkJoin([this.apiCoursesMaterialsService.getLessonsById(lessonId), this.apiFieldsService.getOneField(fieldId)])
          .pipe(
            switchMap(([lesson, fieldInfo]) => {
              const task = lesson?.tasks?.find(task => task.id === taskId);

              if (!task || !task.chooseProfessionsFromFieldTask?.fieldId) {
                this.router.navigate(['/pupil']);
                return of(null);
              }

              const filter: IProfessionFilter = { fields: [fieldInfo.field.name], regionId };

              if (task.chooseProfessionsFromFieldTask.professionIds?.length) {
                filter.ids = task.chooseProfessionsFromFieldTask.professionIds;
              }

              filter.isVisible = true;
              filter.fieldType = fieldInfo.field.type;

              return this.apiProfessionsService.getElasticProfessionsByFilters(filter);
            }),
            filter(professions => professions !== null),
            takeUntil(resolver),
          )
          .subscribe(professions => {
            resolver.next(professions);
            resolver.complete();
          });
        break;
      }
    }
    return resolver;
  }
}
