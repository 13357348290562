import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import {
  ApiProfilesService,
  AppSettingsService,
  IProfession,
  StorageKeys,
  UserActionsService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { take, takeUntil } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import {
  DiscoverIntroRecommendationsModule,
} from '../../../shared/common-components/discover-intro-recommendations/discover-intro-recommendations.module';
import {
  IVariant,
} from '../../../shared/common-components/discover-intro-recommendations/discover-intro-recommendations.component';
import { YmReachGoalModule } from '../../../shared/directives/ym-reach-goal/ym-reach-goal.module';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-profession-mobile',
  standalone: true,
  templateUrl: './profession-mobile.component.html',
  styleUrls: ['./profession-mobile.component.scss'],
  imports: [DiscoverIntroRecommendationsModule, SharedModule, YmReachGoalModule],
})
export class ProfessionMobileComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public userId: string;
  private userRole: string = '';
  @Input() public favorite: boolean = false;

  @Input() public profession: IProfession;
  @Input() public isGreyImage: boolean = false;
  @Input() public recommended: boolean = false;
  @Input() public capabilities: boolean = false;
  @Input() public professionTryingOnStage: boolean = false;
  @Input() public isScroll: boolean = true;
  @Output() public closeProfession: EventEmitter<null> = new EventEmitter<null>();
  @Output() public updateFavoriteProfession: EventEmitter<boolean> = new EventEmitter<boolean>();

  public contentMenu: string[] = [];
  public currentContent: string;
  public dataFetched: boolean = false;
  public videoList: any;
  public personsList: any;
  public hrid: string;
  public favorites: Array<any> = [];
  public showSalary: boolean = false;
  public ymItems = YmItems;

  public imgPath: string = '';
  public isBvb: boolean = false;
  public showBvbRecommendations: boolean = false;
  public selectedPrograms: any;
  public selectedSpecial: any;

  @ViewChild('profInfoMenu') infoMenu: ElementRef;

  constructor(
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private webStorageService: WebStorageService,
    private userActionsService: UserActionsService,
    private apiProfilesService: ApiProfilesService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Детали профессии' });
    this.isBvb = location.pathname.includes('bvb');
  }

  public ngOnInit(): void {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.prepareMenu();
    this.imgPath = this.imagePath();

    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
    this.userActionsService.setInitializationTime([YmItems.S_ProfessionsCard_Likes]);
  }

  private prepareMenu(): void {
    this.contentMenu = [];
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.contentMenu.push('Видео');
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.movies.length > 0) {
      this.contentMenu.push('Фильмы');
    }
    if (this.profession.persons.length > 0) {
      this.contentMenu.push('Профессия в лицах');
      this.personsList = this.profession.persons.slice(0, 2);
    }
    if (this.profession.articles.length > 0) {
      this.contentMenu.push('Книги и статьи');
    }
    this.currentContent = this.contentMenu[0];
    this.dataFetched = true;
  }

  public prepareData(): void {
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.persons.length > 0) {
      this.personsList = this.profession.persons.slice(0, 2);
    }
  }

  public showPrograms(special): void {
    this.selectedSpecial = special.special;
    this.selectedPrograms = special.programs;
  }
  public closePrograms(): void {
    this.selectedPrograms = null;
    this.selectedSpecial = null;
  }

  public scroll(): void {
    this.infoMenu.nativeElement.scrollIntoView();
  }

  public checkPopup(event, popup): void {
    if (!popup.contains(event.target)) {
      popup.style.display = 'block';
    }
  }

  public sanitizeUrl(url: string) {
    const trustedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return trustedUrl;
  }

  public createClassesFormat(variants: IVariant[]) {
    this.closeBvbRecommendation();
    const request = {
      classesFormatsForProfession: {
        userId: this.userId,
        professionId: this.profession.id,
        classFormats: variants.map(variant => variant.text),
      },
    };
    this.apiProfilesService.createClassesFormatsForProfessions(request).pipe(take(1), takeUntil(this.unsubscribe)).subscribe();
    this.favorite = true;
  }

  public closeBvbRecommendation(): void {
    this.showBvbRecommendations = false;
  }

  public updateFavorites(): void {
    this.updateFavoriteProfession.emit();
  }

  public imagePath(): string {
    const length = this.profession?.images?.length;
    return length ? this.profession.images[length - 1].path : '';
  }
}
