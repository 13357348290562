<div
  class="gender-choice"
  (click)="toggleNext()"
  [class.disabled]="genderLocked"
  (keydown.ArrowLeft)="toggleNext()"
  (keydown.ArrowRight)="toggleNext()"
  (keydown.Space)="toggleNext()"
  tabindex="1"
>
  <div class="gender-choice-row">
    <a
      class="change-gender"
      *ngFor="let g of _gendersView"
      (click)="switchGender(g.key); $event.stopPropagation()"
      [ngClass]="currentGender === g.key ? 'active' : ''"
      [class.right]="g.key == 'F'"
    >
      {{ g.name | translate }}
    </a>
  </div>
</div>
