import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { AppSettingsService, ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from '@profilum-library';

export const PAGE_SIZE: number = 1;

@Component({
  selector: 'prf-landing-base',
  templateUrl: './landing-base.component.html',
  styleUrls: ['./landing-base.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingBaseComponent implements AfterViewInit {
  public routeAnimationsElements: string = ROUTE_ANIMATIONS_ELEMENTS;
  public swiperConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    speed: 500,
  };
  public swiperIndex: number = 0;
  public nowDate: Date = new Date();
  public isPeopleChangeCountryBannerShown: boolean = false;

  @ViewChild('swiperComponent') private swiperComponent?: ElementRef;

  public isLoading: boolean = true;
  public isParentMeetingBannerShown: boolean;

  constructor(
    private meta: Meta,
    private router: Router,
    private appSettingsService: AppSettingsService,
  ) {
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });

    if (location.origin.includes('kruzhki')) {
      this.router.navigate(['login']);
    } else {
      this.isLoading = false;
    }

    this.isPeopleChangeCountryBannerShown = AppSettingsService.settings.peopleChangeCountryBannerLocationShown.includes(
      this.appSettingsService.currentLocation,
    );
    this.isParentMeetingBannerShown = AppSettingsService.settings.parentsMeetingBannerShown.includes(
      this.appSettingsService.currentLocation,
    );
  }

  public ngAfterViewInit(): void {
    Object.assign(this.swiperComponent.nativeElement, this.swiperConfig);
    this.swiperComponent.nativeElement.initialize();
  }

  public swiperIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  public toggleOverlayControls(goto: number): void {
    this.swiperComponent.nativeElement.swiper.slideTo(goto);
    this.swiperIndexChange(goto);
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
}
