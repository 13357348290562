export enum RestorePasswordStepsEnum {
  LOGIN_INPUT,
  RESET_PASSWORD_BY_SMS,
  RESET_PASSWORD_BY_PHONE_CALL_INFO_PAGE,
  RESET_PASSWORD_BY_PHONE_CALL_FORM,
  RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE,
}

export enum RestorePasswordByPhoneCallTextsEnum {
  ERROR = 'Неверный код',
  EXCEED_ATTEMPTS = 'Закончилось количество попыток. Восстановите пароль через e-mail или обратитесь в поддержку',
}

export enum ResetPasswordByPhoneEnum {
  SMS,
  PHONE_CALL,
}
