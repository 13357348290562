<div class="footer-section">
  <div class="footer-row">
    <div class="footer-text">© {{ yearNumber }} {{ 'LANDING_KZ.FOOTER.COPYRIGHT' | translate }}</div>

    <div class="footer-privacy">
      <div>
        <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
          'SHARED.AGREEMENT.PERSONAL_DATA' | translate
        }}</a>
      </div>
      <div>
        <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
          'SHARED.AGREEMENT.USER_AGREEMENT' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
