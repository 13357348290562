<div class="pupil-mobile-edit-profile">
  <prf-image-cropper-v2
    *ngIf="uploadedPhoto$ | async as uploadedPhoto"
    [uploadedImage]="uploadedPhoto"
    (croppedImage)="editUserPhoto($event)"
    (incorrectImage)="canselChanges()"
    [cropImageSubject]="cropImageSubject"
  ></prf-image-cropper-v2>
</div>
<prf-pupil-mobile-edit-profile-menu
  (saveProfileChanges)="submitChanges()"
  (cancelProfileChanges)="canselChanges()"
></prf-pupil-mobile-edit-profile-menu>
