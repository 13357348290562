import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionComponent } from '../../components/description/description.component';
import { H2Component } from '../../components/h2/h2.component';
import {
  ProfessionCatalogCardComponent,
} from '../../components/profession-catalog/profession-catalog-card/profession-catalog-card.component';
import { PrfProfessionCatalogData } from '../../components/profession-catalog/profession-catalog.types';
import { SectionComponent } from '../../components/section/section.component';

@Component({
  selector: 'prf-profession-catalog',
  standalone: true,
  imports: [CommonModule, DescriptionComponent, H2Component, ProfessionCatalogCardComponent, SectionComponent],
  templateUrl: './profession-catalog.component.html',
  styleUrl: './profession-catalog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionCatalogComponent {
  @Input() professionCatalogData: PrfProfessionCatalogData;
}
