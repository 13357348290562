<section class="section-wrapper">
  <h1>{{ 'SHARED.SETTINGS' | translate }}</h1>
  <a (click)="closeSettings()" class="return w-inline-block">
    <img src="./profilum-assets/images/icons/exit-icon.svg" class="exit-profile-icon" />
    <div>Выход</div>
  </a>
  <div class="settings">
    <div class="settings-photo">
      <p class="settings-toggle" (click)="_changePhoto = !_changePhoto">Изменить фотографию</p>
      <prf-photo-change [_userInfo]="_userInfo" *ngIf="_changePhoto"></prf-photo-change>
    </div>
    <div class="settings-password">
      <p class="settings-toggle" (click)="_changePassword = !_changePassword">
        {{ 'SHARED.CHANGE_PASSWORD' | translate }}
      </p>
      <prf-password-change *ngIf="_changePassword"></prf-password-change>
    </div>
    <div class="settings-email">
      <p class="settings-toggle" (click)="_changeEmail = !_changeEmail">Изменить почту</p>
      <prf-email-change *ngIf="_changeEmail"></prf-email-change>
    </div>
    <div class="settings-phone">
      <p class="settings-toggle" (click)="_changePhone = !_changePhone">Изменить телефон</p>
      <prf-phone-change *ngIf="_changePhone"></prf-phone-change>
    </div>
  </div>
</section>
