import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { B2gSaasService, StorageKeys, WebStorageService, YmItems } from '@profilum-library';

@Component({
  selector: 'prf-restore-password-kazahstan',
  templateUrl: './restore-password-kazahstan.component.html',
  styleUrls: ['./restore-password-kazahstan.component.scss'],
})
export class RestorePasswordKazahstanComponent implements OnInit {
  public form: UntypedFormGroup;
  public email: string = null;

  public firstStep: boolean = true;
  public secondStep: boolean = false;
  public errorResetToken: boolean = false;
  public ymItems = YmItems;

  private emailPattern: RegExp = REG_EXP.emailRegExp;

  public isKz: boolean = true;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private router: Router,
    private utilsService: UtilsService,
    private b2gSaasService: B2gSaasService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
  ) {
    this.getTranslation('SHARED.PASSWORD_RECOVERY')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );

    const language: string = this.webStorageService.get(StorageKeys.SelectedLanguage);
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz';
      this.translateService.use(language);
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.email, [Validators.required, Validators.pattern(this.emailPattern)]),
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  public next() {
    this.b2gSaasService
      .sendResetToken(this.form.value.email)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(token => {
        if (token.status === 'Success') {
          this.firstStep = false;
          this.secondStep = true;
        } else {
          return (this.errorResetToken = true);
        }
      });
  }

  public async back() {
    if (this.firstStep) {
      this.router.navigate(['/kz-login']);
    }
    if (this.secondStep) {
      this.firstStep = true;
      this.secondStep = false;
    }
  }

  public isSecondStepAllowed() {
    return this.secondStep;
  }

  public isAccessAllowed() {
    return this.isValid(this.form.value.email);
  }

  public isValid(email) {
    return this.emailPattern.test(email);
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
