import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/shared/modules/material.module';
import { routeConfig } from '../../../pages/pages.router.config';
import { NotificationComponent } from './notification/notification.component';
import { PageHeaderModernComponent } from './page-header-modern/page-header-modern.component';
import { PageHeaderComponent } from './page-header.component';
import { PageHeaderService } from './page-header.service';
import { DropdownModule } from '../../../ui-kit/components/dropdown/dropdown.module';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, RouterModule.forChild(routeConfig), DropdownModule],
  declarations: [PageHeaderComponent, NotificationComponent, PageHeaderModernComponent],
  providers: [PageHeaderService],
  exports: [PageHeaderComponent, PageHeaderModernComponent],
})
export class PageHeaderModule {}
