import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerRoutingModule } from './employer-routing.module';
import { EmployerPanelComponent } from './employer-panel.component';
import { SharedModule } from 'app/shared/shared.module';
import { CompanyProfileComponent } from './company/company-profile.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';

@NgModule({
  declarations: [EmployerPanelComponent, CompanyProfileComponent, EditCompanyComponent],
  imports: [CommonModule, EmployerRoutingModule, SharedModule],
})
export class EmployerModule {}
