<div class="restriction-banner">
  <div class="restriction-banner-text">
    <div class="restriction-banner-title">Увидимся в следующем учебном году</div>
    <div class="restriction-banner-desc">
      Если у вас {{ dictionary.MustBeClass }} быть {{ dictionary.Class }} или {{ dictionary.Classes }}, попросите школьного администратора
      назначить вас {{ dictionary.TeacherAblative }} для них
    </div>
  </div>
  <div class="restriction-banner-image"></div>
</div>
