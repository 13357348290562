<div class="prf-captcha">
  <div class="prf-captcha-canvas" #canvasEl></div>
  <span (click)="reloadImage($event)" class="prf-captcha-reload-button">Другая картинка</span>
  <form class="prf-captcha-form" [formGroup]="form">
    <div class="prf-captcha-form-row" [class.hidden]="!_showCaptcha">
      <prf-dynamic-label [prfFormControl]="formControls.text" [prfFormControlElement]="textInput">
        Строчные и прописные буквы
      </prf-dynamic-label>
      <input
        type="text"
        name="text"
        data-name="text"
        formControlName="text"
        id="capchaInputText"
        autocomplete="off"
        class="prf-form-input"
        [class.error-field]="validated && formControls.text.errors"
        #textInput
      />
      <prf-reset-button [prfFormControl]="formControls.text" [prfFormControlElement]="textInput"></prf-reset-button>
    </div>
    <ng-template [ngIf]="validated && formControls.text.errors">
      <mat-error *ngIf="formControls.text.errors.required">Введите текст с картинки</mat-error>
      <mat-error *ngIf="formControls.text.errors.notValidText"> Неправильно введен текст с картинки </mat-error>
    </ng-template>
  </form>
</div>
<div class="prf-login-frame">
  <button
    type="button"
    class="prf-btn-primary"
    [class.prf-btn-primary_disabled]="captchaText?.length < 6"
    (click)="validate()"
  >
    Продолжить
  </button>
</div>
