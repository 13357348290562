import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

import {
  ApiCoursesMaterialsService,
  ApiProfessionsService,
  ApiSearchService,
  ApiUsersService,
  B2gSaasService,
  IDirectorPupil,
  IDirectorPupilsObject,
  IFavoriteProfessionInterface,
  ISchool,
  PupilsByResultsObject,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { educationLevels } from 'app/shared/enums/institutiontypes.enum';
import { TeacherPanelService } from '../../teacher/teacher-panel.service';

@Component({
  selector: 'prf-director-slices',
  templateUrl: './director-slices.component.html',
  styleUrls: ['./director-slices.component.scss'],
})
export class DirectorSlicesComponent extends UnsubscribeComponent implements OnInit {
  public school: ISchool;
  public schoolTitle: string = '';
  pupils: IDirectorPupil[] = [];
  favoriteProfessions: any = [];

  showPupilPage: boolean = false;
  selectedPupil: IDirectorPupil;
  userProfile: any;

  public talantsList = [];
  public classesProfilsList = [];
  public subjectsDOList = [];
  public educationLevelsList = [];
  public educationPlanningList = [];
  public directionQuestionFilters = [
    {
      name: 'Математика и естественные науки (физика, химия, науки о земле, биология)',
      shortName: 'Математика и естественные науки',
    },
    {
      name: 'Гуманитарные науки (языкознание, история, философия)',
      shortName: 'Гуманитарные науки',
    },
    {
      name: 'Искусство и культура',
      shortName: 'Искусство и культура',
    },
    {
      name: 'Науки об обществе (экономика и управление, юриспруденция, психология, социология, средства массовой информации, сервис и туризм)',
      shortName: 'Науки об обществе',
    },
    {
      name: 'Информатика и компьютерные науки',
      shortName: 'Информатика и компьютерные науки',
    },
    {
      name: 'Здравоохранение и медицина',
      shortName: 'Здравоохранение и медицина',
    },
    {
      name: 'Сельское хозяйство',
      shortName: 'Сельское хозяйство',
    },
    {
      name: 'Образование и педагогические науки',
      shortName: 'Образование и педагогические науки',
    },
    {
      name: 'Инженерное дело, технологии и технические науки (архитектура и строительство, электроника, машиностроение, технологии материалов, технологии транспорта)',
      shortName: 'Инженерное дело и технические науки',
    },
  ];

  public viewData: boolean = false;

  dataFetched: boolean = false;

  public form = new UntypedFormGroup({
    talantFilter: new UntypedFormControl(),
    classProfilFilter: new UntypedFormControl(),
    progressFilter: new UntypedFormControl(),
    educationLevel: new UntypedFormControl(),
    educationPlanning: new UntypedFormControl(),
    gender: new UntypedFormControl(),
    subjectDOfilter: new UntypedFormControl(),
    classFilter: new UntypedFormControl(),
  });

  constructor(
    private b2gSaasService: B2gSaasService,
    private apiUsersService: ApiUsersService,
    private apiSearchService: ApiSearchService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private apiProfessionsService: ApiProfessionsService,
    private teacherPanelService: TeacherPanelService,
    private overlayService: OverlayBusyService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    super();
    matIconRegistry.addSvgIcon(
      'icon_downside',
      sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/arrows/icon_downside.svg'),
    );
    matIconRegistry.addSvgIcon(
      'icon_upside',
      sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/arrows/icon_upside.svg'),
    );
  }

  async ngOnInit() {
    this.viewData = false;

    observableForkJoin([this.b2gSaasService.getSchool(), this.getTalents(), this.getClassesProfiles(), this.getSubjectsDO()])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([school, talantsResponse, classesResponse, subjectResponse]) => {
        this.school = school;
        this.schoolTitle = this.school.number;
        this.setEducationLevels();
        this.setEducationPlanning();
        this.viewData = true;
      });

    this.teacherPanelService
      .getPupilPage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async data => {
        this.showPupilPage = data.showPupilPage;
      });
  }

  getTalents(): Observable<any> {
    return this.apiSearchService.searchTalents().pipe(
      tap(talants => {
        this.talantsList = talants.map(talant => Object.assign({}, { title: talant.name, value: talant.name }));
      }),
    );
  }

  getClassesProfiles(): Observable<any> {
    return this.apiSearchService.searchProfileClasses().pipe(
      tap(classesprofils => {
        this.classesProfilsList = classesprofils.map(profil => Object.assign({}, { title: profil.name, value: profil.name }));
      }),
    );
  }

  getSubjectsDO(): Observable<any> {
    return this.apiCoursesMaterialsService.getAllCourses().pipe(
      tap(subjectsDO => {
        this.subjectsDOList = subjectsDO
          .map(subject => Object.assign({}, { title: subject.name, value: subject.name }))
          .sort((a, b) => (a.title > b.title ? 1 : -1));
      }),
    );
  }

  setEducationPlanning() {
    this.educationPlanningList = this.directionQuestionFilters.map(item => Object.assign({}, { title: item.shortName, value: item.name }));
  }

  setFormControl(control, value) {
    if (control) {
      this.form.get(control).value === value ? this.form.get(control).setValue(null) : this.form.get(control).setValue(value);
    }
  }
  setEducationLevels() {
    this.educationLevelsList = Object.keys(educationLevels)
      .filter(key => typeof educationLevels[key] != 'number')
      .map(key =>
        Object.assign(
          {},
          {
            title: educationLevels[key],
            value: key.toString(),
          },
        ),
      );
  }

  getDistribution() {
    this.overlayService.show();
    const dataObject: PupilsByResultsObject = {};
    Object.assign(dataObject, { schoolId: this.school.id });
    this.form.get('talantFilter').value ? Object.assign(dataObject, { talent: this.form.get('talantFilter').value }) : null;
    this.form.get('classProfilFilter').value ? Object.assign(dataObject, { profilClass: this.form.get('classProfilFilter').value }) : null;
    this.form.get('educationLevel').value ? Object.assign(dataObject, { level: this.form.get('educationLevel').value }) : null;
    this.form.get('educationPlanning').value ? Object.assign(dataObject, { direction: this.form.get('educationPlanning').value }) : null;
    this.form.get('subjectDOfilter').value ? Object.assign(dataObject, { wantedDO: this.form.get('subjectDOfilter').value }) : null;
    this.form.get('gender').value ? Object.assign(dataObject, { gender: this.form.get('gender').value }) : null;
    this.form.get('classFilter').value ? Object.assign(dataObject, { class: this.form.get('classFilter').value }) : null;

    this.dataFetched = false;
    this.apiUsersService
      .getDistributionDirectors(dataObject)
      .pipe(
        take(1),
        switchMap((results: IDirectorPupilsObject) => {
          this.pupils = results.pupilsInfo;
          if (this.pupils) {
            return this.getFavoritesProfessions(this.pupils);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => {
        this.dataFetched = true;
        this.overlayService.hide();
      });
  }

  getFavoritesProfessions(pupils: any): Observable<any> {
    const studentsWithProfessions = pupils.filter(el => el.favoriteProfessions.length > 0);
    let professionsIds: string[] = studentsWithProfessions.map(el => el.favoriteProfessions.map(e => e.productId || e.id));
    professionsIds = professionsIds.reduce((a, b) => a.concat(b), []);
    const unique = [...new Set(professionsIds)];
    return this.apiProfessionsService.getElasticProfessionsByFilters({ ids: unique, isVisible: true }).pipe(
      tap(r => {
        this.favoriteProfessions = r;
        this.parseStudentsProfessions(this.pupils);
      }),
    );
  }

  private parseStudentsProfessions(pupils: any) {
    pupils.forEach((student, i) => {
      if (student.favoriteProfessions.length > 0) {
        const professionsIds = student.favoriteProfessions.map(e => e.productId || e.id);
        const unique = [...new Set(professionsIds)];
        const professions: IFavoriteProfessionInterface[] = [];
        unique.forEach(id => professions.push(this.favoriteProfessions.find(el => el.id === id)));
        this.pupils[i].favoriteProfessions = professions;
      }
    });
    this.dataFetched = true;
    this.overlayService.hide();
  }

  showSelectedPupil(pupil: any) {
    this.selectedPupil = pupil;
    this.teacherPanelService.showPupilPage();
  }

  checkFilters() {
    switch (true) {
      case this.form.controls.talantFilter.value && this.form.controls.talantFilter.value.length:
        return true;
      case this.form.controls.classProfilFilter.value && this.form.controls.classProfilFilter.value.length > 0:
        return true;
      case this.form.controls.progressFilter.value && this.form.controls.progressFilter.value.length > 0:
        return true;
      case this.form.controls.educationLevel.value && this.form.controls.educationLevel.value.length > 0:
        return true;
      case this.form.controls.educationPlanning.value && this.form.controls.educationPlanning.value.length > 0:
        return true;
      case this.form.controls.gender.value && this.form.controls.gender.value.length > 0:
        return true;
      case this.form.controls.subjectDOfilter.value && this.form.controls.subjectDOfilter.value.length > 0:
        return true;
      case this.form.controls.classFilter.value && this.form.controls.classFilter.value.length > 0:
        return true;
      default:
        return false;
    }
  }

  clearAllFilters() {
    this.form.reset();
  }

  getImagePath(job: any) {
    if (job?.mainImagePath) {
      return (job.mainImagePath = job.mainImagePath.replace(
        '/content/images/professions/default_icons/',
        './profilum-assets/images/profession/',
      ));
    }
  }

  showElement(event: any) {
    event.target.nextSibling.style.display = 'block';
  }
  hideElement(event: any) {
    event.target.nextSibling.style.display = 'none';
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.teacherPanelService.closePupilPage();
  }
}
