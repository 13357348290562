import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { ApiProfessionsService, IUniversity, StorageKeys, WebStorageService } from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-specialty-details',
  templateUrl: './specialty-details.component.html',
  styleUrls: ['./specialty-details.component.scss'],
})
export class SpecialtyDetailsComponent implements OnInit {
  userId: string;
  userRole: string = '';
  adminLevel: string = '';
  specialty: IUniversity;
  id: string;
  createdDate: string;

  popUpConfirming: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private apiProfessionsService: ApiProfessionsService,
    private utilsService: UtilsService,
    public route: ActivatedRoute,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Детали специальности' });
  }

  ngOnInit() {
    this.overlayService.show();

    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    if (this.userRole.includes('admin')) {
      this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    }

    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        mergeMap(params => {
          window.scrollTo(0, 0);
          this.id = params['id'];
          return this.loadSpecialty();
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadSpecialty(): Observable<any> {
    return this.apiProfessionsService.getSpecialtyById(this.id).pipe(
      tap(specialty => {
        if (specialty) {
          window.scrollTo(0, 0);
          this.createdDate = specialty.createdDate ? DateHelper.toDayJs(specialty.createdDate).format('D MMMM YYYY в HH:mm') : null;
          this.specialty = specialty;
        }
      }),
    );
  }

  deleteSpecialty(id) {
    this.apiProfessionsService
      .deleteSpecialty(id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Специальность удалена`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/specialties']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
