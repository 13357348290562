<ng-container>
  <div>
    <!-- SEARCH -->
    <!-- <prf-internship-search (searchesData)="dataSearch($event)"></prf-internship-search> -->

    <!-- FILTER -->
    <prf-internship-filter></prf-internship-filter>

    <div class="catalog-events-row" *ngIf="internshipListFiltered.length > 0; else emptyInternships">
      <prf-internship-card-new *ngFor="let internship of internshipListFiltered" [internship]="internship"></prf-internship-card-new>
    </div>
  </div>
</ng-container>

<ng-template #emptyInternships>
  <div class="event-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_TITLE_NAME' | translate : { titleName: titleName } }}
    </div>
  </div>
</ng-template>
