import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartType } from 'chart.js';
import { ResultsStatus } from 'app/shared/common-components/results-data-presentation/result-status.type';
import { Helper } from '@profilum-library';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { RootScopeService } from '../../../services/root-scope.service';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';

interface ITooltipOptions {
  autoPlacement: boolean;
  showDelay: number;
  animationDuration: number;
  theme: string;
  width: number;
  maxWidth: number;
}

@Component({
  selector: 'prf-results-objecttype-chart-and-bars',
  templateUrl: './results-objecttype-chart-and-bars.component.html',
  styleUrls: ['./results-objecttype-chart-and-bars.component.scss'],
})
export class ResultsObjecttypeChartAndBarsComponent implements OnInit, OnChanges {
  @Input() public data: IAnswerPercents[];
  @Input() public showByPupil: boolean = false;
  @Input() public set status(status: ResultsStatus) {
    if (status) {
      this.isNoResult = status === 'not-passed' || status === 'passed-not-started';
    }
  }
  public isNoResult: boolean;
  public dictionary: DictionaryType;
  // Doughnut
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartDatasets = [
    {
      data: [],
      backgroundColor: ['#2be8df', '#9e83fc', '#4ab5ff', '#fe7531', '#f856e2', '#ffd930', '#36ca75', '#3e60fd'],
    },
  ];
  public pieChartLegend: boolean = false;
  public chartColors: { backgroundColor: string[] }[] = [
    {
      backgroundColor: ['#2be8df', '#9e83fc', '#4ab5ff', '#fe7531', '#f856e2', '#ffd930', '#36ca75', '#3e60fd'],
    },
  ];
  public tooltipOptions: ITooltipOptions = {
    autoPlacement: true,
    showDelay: 500,
    animationDuration: 300,
    theme: 'light',
    width: 390,
    maxWidth: 390,
  };
  public color_gray: string = '#F2F2F2';
  public color_darkGray: string = '#BFBFBF';
  public pieChartOptions: any = {
    legend: {
      labels: {
        fontSize: 12,
      },
    },
    pointLabels: {
      fontSize: 12,
      fontColor: '#333',
      fontFamily: 'ALSSchlangesans, Arial',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data['labels'][tooltipItem[0]['index']];
        },
        label: function (tooltipItem, data) {
          return data['datasets'][0]['data'][tooltipItem['index']] + '%';
        },
      },
      backgroundColor: '#FFF',
      titleFontSize: 15,
      titleFontColor: '#353535',
      bodyFontColor: '#353535',
      bodyFontSize: 13,
      displayColors: false,
    },
  };
  public declOfNum = Helper.declOfNum;

  constructor(private rootScopeService: RootScopeService) {
    this.dictionary = this.rootScopeService.getDictionary();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const dataValue: IAnswerPercents[] = changes.data?.currentValue;
    if (dataValue) {
      this.doughnutChartLabels = [];
      this.doughnutChartData = [];

      dataValue.forEach(item => {
        this.doughnutChartLabels.push(item.name);
        this.doughnutChartData.push(item.percents);
      });
      this.doughnutChartDatasets[0].data = this.doughnutChartData;
    }
  }

  public ngOnInit(): void {
    if (this.isNoResult) {
      this.chartColors = [
        {
          backgroundColor: ['#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7'],
        },
      ];
      this.doughnutChartDatasets = [
        {
          data: [],
          backgroundColor: ['#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7', '#d7d7d7'],
        },
      ];
      this.pieChartOptions.titleFontColor =
        this.pieChartOptions.bodyFontColor =
        this.pieChartOptions.titleFontColor =
        this.pieChartOptions.pointLabels.fontColor =
          '#9A9A9A';
    }
  }
}
