<div
  #dropDown
  *ngIf="dropdownItems?.length && !isOnlyDisabledVariant; else dropdownWithoutOptions"
  class="dropdown"
  [ngClass]="{ 'with-sort': isSorting }"
>
  <button type="button" class="active-option" [ngClass]="dropdownClasses" (click)="toggleDropdown()">
    <span class="name">{{ selectedOption?.name }}</span>
    <span class="icon w-icon-dropdown-toggle"></span>
  </button>
  <div class="options-wrapper" *ngIf="isOpened">
    <div class="options-list">
      <div *ngIf="isSorting" class="clear-sort" (click)="selectOption()">{{ clearSortText }}</div>
      <span
        *ngFor="let item of dropdownItems; trackBy: trackByFn"
        [tp]="tooltipVisible ? tooltip : null"
        [tpData]="item.name"
        [tpPlacement]="'right'"
        (click)="selectOption(item.data)"
        class="option"
      >
        {{ item.name }}
      </span>
    </div>
  </div>
</div>

<ng-template #dropdownWithoutOptions>
  <div class="dropdown">
    <div class="active-option disabled">
      <span class="name">{{ placeholderText }}</span>
      <span class="icon w-icon-dropdown-toggle"></span>
    </div>
  </div>
</ng-template>

<ng-template #tooltip let-option="data">
  <prf-tooltip [limitedWidth]="false">{{ option }}</prf-tooltip>
</ng-template>
