import { Component } from '@angular/core';
import { ChatComponent } from '../../../../../shared/common-components/onbording/chat/chat.component';
import { takeUntil } from 'rxjs/operators';
import {
  ApiResultsService,
  ChatBotsService,
  IChatBotButton,
  IChatBotMessage,
  IChatBotSection,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ChatBotButtonTypeEnum, ChatBotButtonVisibleEnum, ChatBotMessageTypeEnum } from './chat-bot.enum';
import { IButtonOnbording } from '../../../../../shared/interfaces/common/ondording.interface';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { NumeralsPipe } from '../../../../../shared/pipes/numerals.pipe';

const LAST_BUTTONS_LENGTH = 2;

@Component({
  selector: 'prf-chat-bot',
  standalone: true,
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  imports: [
    ChatComponent,
    NgIf
  ]
})
export class ChatBotComponent extends UnsubscribeComponent {
  public messages: IChatBotMessage[] = [];
  public buttons: IButtonOnbording[];
  public currentStep: number = 0;
  public startMessageNumber: number = 0;
  public chatBotSections: IChatBotSection[];

  private regionId: string = '';
  private countStepsOnCurrentSection: number = 0;
  private buttonAfterOne: IChatBotButton;
  private testParameters = {$manager$: 1, $expert$: 3, $employer$: 9};

  constructor(private chatBotsService: ChatBotsService,
              private userDataHandlerService: UserDataHandlerService,
              private router: Router,
              private webStorageService: WebStorageService,
              private apiResultsService: ApiResultsService,
  ) {
    super();
    const lastSession: any = this.webStorageService.get(StorageKeys.IdDiagnosticByCareerType);
    this.regionId = this.userDataHandlerService.getUserInfo().regionId;
    this.apiResultsService.getResultsPage(lastSession)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        res.results.forEach(result => {
          switch (result.name) {
            case 'Управленец':
              this.testParameters.$manager$ = result.results[0].value;
              break;
            case 'Эксперт':
              this.testParameters.$expert$ = result.results[0].value;
              break;
            case 'Предприниматель':
              this.testParameters.$employer$ = result.results[0].value;
              break;
            default:
              break;
          }
        })
      });
    this.chatBotsService
      .getChatBotsByRegionId(this.regionId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(chat => {
        if (!chat.regionChatBots) {
          this.router.navigate(['/pupil']);
        } else {
          this.chatBotSections = chat.regionChatBots[0].sections;

          this.showMessagesByType(this.chatBotSections[this.currentStep].messages);

          this.buttons = [{
            text: this.chatBotSections[this.currentStep].buttons[0].name,
            type: this.chatBotSections[0].buttons[0].type
          }];
        }
      });

  }

  public buttonsActions(event: IButtonOnbording): void {

    this.startMessageNumber = this.messages.length;
    this.showPupilAnswer(event);

    switch (event.type) {
      case ChatBotButtonTypeEnum.NEXT_STEP: {

        this.currentStep = this.currentStep + 1;
        this.countStepsOnCurrentSection = 0;

        this.showMessagesByType(this.chatBotSections[this.currentStep].messages);

        this.buttons = [];
        this.checkButtonsVisible(this.chatBotSections[this.currentStep].buttons);

        break;
      }

      case ChatBotButtonTypeEnum.DISPOSABLE: {
        ++this.countStepsOnCurrentSection;

        if (this.buttonAfterOne && this.countStepsOnCurrentSection === 1) {
          this.buttons.push({text: this.buttonAfterOne.name, type: this.buttonAfterOne.type})
          this.buttonAfterOne = null;
        }

        const deleteButton: IButtonOnbording = this.buttons.find(button => button.text === event.text);
        this.buttons.splice(this.buttons.indexOf(deleteButton), 1);

        break;
      }

      case ChatBotButtonTypeEnum.CLOSE: {
        this.router.navigate(['/pupil']);
        break;
      }
      default: {
        break;
      }
    }
  }

  private showPupilAnswer(event): void {
    this.messages.push({text: event.text, position: 'right'});

    const currentButton: IChatBotButton = this.chatBotSections[this.currentStep].buttons.find(button => button.name === event.text);

    if (this.buttons.length === LAST_BUTTONS_LENGTH && event.type === ChatBotButtonTypeEnum.DISPOSABLE) {
      currentButton.messages?.pop()
    }

    this.showMessagesByType(currentButton.messages);
  }

  private showMessagesByType(messages: IChatBotMessage[]): void {
    if (messages) {
      const addedMessages = [];
      messages.forEach((message: IChatBotMessage) => {
        message.text = message.text.replace('</p><p><br></p>', '');

        switch (message.type) {

          case ChatBotMessageTypeEnum.DEFAULT: {
            addedMessages.push(message);
            break;
          }

          case ChatBotMessageTypeEnum.WITH_TEMPLATE_VARIABLES: {
            const messageWithParameters: IChatBotMessage = this.setMessageParameters(message);
            addedMessages.push(messageWithParameters);
            break;
          }

          case ChatBotMessageTypeEnum.SEVERAL_POINTS_EQUAL: {
            const checkedParameters = this.checkParameters(Object.values(this.testParameters));
            if (checkedParameters) {
              addedMessages.push(message);
            }
            break;
          }

          default: {
            break;
          }
        }
      })
      this.messages = [...this.messages, ...addedMessages];
    }
  }

  private checkButtonsVisible(buttons: IChatBotButton[]): void {
    if (buttons) {
      buttons.forEach((button: IChatBotButton) => {
        switch (button.visible) {
          case ChatBotButtonVisibleEnum.AFTER_ONE: {
            this.buttonAfterOne = button;
            break;
          }
          default: {
            this.buttons.push({text: button.name, type: button.type})
            break;
          }
        }
      })
    }
  }

  private setMessageParameters(message: IChatBotMessage): IChatBotMessage {
    const numeralsPipe = new NumeralsPipe();

    for (const key in this.testParameters) {
      message.text = message.text.replace(key, this.testParameters[key] + ' ' + numeralsPipe.transform(this.testParameters[key], ['балл', 'балла', 'баллов']))
    }
    return message;
  }

  private checkParameters(parameters: number[]) {
    return parameters.every(val => val === parameters[0]);
  }
}
