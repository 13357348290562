import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-teacher-with-no-classes-banner',
  templateUrl: './teacher-with-no-classes-banner.component.html',
  styleUrls: ['./teacher-with-no-classes-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherWithNoClassesBannerComponent {
  public dictionary: DictionaryType;
  constructor(private rootScopeService: RootScopeService) {
    this.dictionary = rootScopeService.getDictionary();
  }
}
