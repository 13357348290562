<div class="profession-mobile-card" *ngIf="profession" [ngClass]="{ deletingCard: deleting }">
  <div class="profession-mobile-card-recommended">
    <div class="profession-mobile-card-recommended-capabilities" *ngIf="capabilities">Способности</div>
    <div class="profession-mobile-card-recommended-interests" *ngIf="recommended">Интересы</div>
    <div class="profession-mobile-card-recommended-favourite" *ngIf="favourite && professionTryingOnStage">Избранное</div>
  </div>
  <div class="profession-mobile-card-left" *ngIf="!noColor" [ngStyle]="{ background: colorMap[profession?.fields[0]?.name] }"></div>
  <div class="profession-mobile-card-info" (click)="openProfession()">
    <div class="profession-mobile-card-info-industry">
      {{ profession?.fields[0]?.name }}
    </div>
    <div class="profession-mobile-card-info-body">
      <div class="profession-mobile-card-info-body-name">
        {{ profession?.name }}
      </div>
      <img class="profession-mobile-card-info-body-icon" src="./profilum-assets/images/common/prof-arrow.svg" />
    </div>
  </div>
  <ng-container *ngIf="!professionTryingOnStage">
    <ng-container *ngIf="!fromProfile; else profileIcon">
      <div (click)="addToFavourite()" class="profession-mobile-card-icon" [class.profession-mobile-card-icon_favourite]="favourite"></div>
    </ng-container>
  </ng-container>
  <ng-template #profileIcon>
    <div
      *ngIf="!deleting"
      (click)="addToFavourite()"
      class="profession-mobile-card-icon"
      [class.profession-mobile-card-icon_favourite]="favourite"
    ></div>
    <prf-favourite-icon
      *ngIf="deleting"
      class="profession-mobile-card-icon-deleting"
      (click)="addToFavourite()"
      [deleting]="deleting"
    ></prf-favourite-icon>
  </ng-template>
</div>
