<div class="chat-bot-page" *ngIf="chatBotSections">
  <div class="chat-bot">
    <prf-chat
      class="chat-bot__messages"
      [messages]="messages"
      [buttons]="buttons"
      [delay]="600"
      [startMessageNumber]="startMessageNumber"
      [confetti]="true"
      (buttonAction)="buttonsActions($event)"
    >
    </prf-chat>
  </div>
</div>
