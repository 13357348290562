import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-dynamic-label',
  templateUrl: './dynamic-label.component.html',
  styleUrls: ['./dynamic-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicLabelComponent extends UnsubscribeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() prfFormControl: AbstractControl;
  @Input() prfFormControlElement: HTMLInputElement;
  public toLabel: boolean = false;
  private hasValue: boolean = false;
  private focusedEl: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.toLabel = !!this.prfFormControl.value;
    this.prfFormControl?.valueChanges.pipe(this.unsubscribeOperator).subscribe(value => {
      this.hasValue = !!(value?.length ?? value);
      this.toLabel = this.focusedEl || this.hasValue;
      this.changeDetectorRef.detectChanges();
    });
  }

  public ngAfterViewInit(): void {
    if (this.prfFormControlElement) {
      this.prfFormControlElement.addEventListener('focus', this.onFocus);
      this.prfFormControlElement.addEventListener('blur', this.onBlur);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.prfFormControlElement) {
      this.prfFormControlElement.removeEventListener('focus', this.onFocus);
      this.prfFormControlElement.removeEventListener('blur', this.onBlur);
    }
  }

  private onFocus = (): void => {
    this.focusedEl = true;
    this.toLabel = this.focusedEl;
    this.changeDetectorRef.detectChanges();
  };

  private onBlur = (): void => {
    this.focusedEl = false;
    this.toLabel = this.hasValue || !!this.prfFormControl.value || this.focusedEl;
    this.changeDetectorRef.detectChanges();
  };
}
