import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAdminsService,
  ApiLocationsService,
  ApiSchoolsService,
  ApiUsersService,
  ApiUtilsService,
  EmptyGuid,
  IClass,
  IGetCourseByHridResponse,
  IMunicipality,
  IRegion,
  Stands,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { COMPLEXITYS, COURSES_TYPES, DELIVERY, PROMOCODE_TYPES } from '../add-course/add-course.component';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditCourseComponent implements OnInit {
  hrid: string;
  course: IClass;
  startDate: string;
  createdDate: string;
  publishedDate: string;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  public showInfoPopup: boolean = false;
  public form: UntypedFormGroup;
  public timeMask = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public selectedTalents: any;
  public selectedComplexity: any;
  public selectedPromoType: any;
  public submitted: boolean;
  public isRegular: boolean;
  public complexitys = COMPLEXITYS;
  public classesFormats = COURSES_TYPES;
  public promocodeTypes = PROMOCODE_TYPES;
  public selectedFormat: any;
  public delivery = DELIVERY;
  public selectedDelivery: any;
  public userRole: string;
  public selectedFiles: FileList;
  public image;
  public courseID: string;
  public title: string = 'Редактирование';
  public isCopy: boolean = false;
  public oldHrid: string;
  public checkhridObject: { result: boolean } = { result: false };

  //объекты территориальной привязки
  public allRegions$: Observable<IRegion[]>;
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  public municipalitiesByRegion$: Observable<IMunicipality[]>;
  public selectedMunicipality: IMunicipality = {
    id: EmptyGuid,
    hrid: '',
    name: '',
    regionId: '',
  };

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    public route: ActivatedRoute,
    private overlayService: OverlayBusyService,
    private apiLocationsService: ApiLocationsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private apiUtilsService: ApiUtilsService,
    private translateService: TranslateService,
    private apiUsersService: ApiUsersService,
    private webStorageService: WebStorageService,
    private apiSchoolsService: ApiSchoolsService,
    private apiAdminsService: ApiAdminsService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование курса' });

    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    if (route.snapshot.routeConfig.path == 'copy-course/:hrid') {
      this.isCopy = true;
      this.title = 'Копия курса';
    }
  }

  ngOnInit() {
    this.overlayService.show();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.hrid = params['hrid'];
    });
    this.apiSchoolsService
      .getCourseByHrid(this.hrid)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap((course: IGetCourseByHridResponse) => {
          this.course = course.class;
          this.oldHrid = this.course.hrid;
          this.selectedPromoType =
            this.course.isPercent != null ? (this.course.isPercent ? this.promocodeTypes[0] : this.promocodeTypes[1]) : null;
          if (this.course.startDate) {
            const startTime = DateHelper.toDayJs(this.course.startDate).format('HH:mm');
            if (startTime == '00:00') {
              this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY');
            } else {
              this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY в HH:mm');
              this.course.startTime = startTime;
            }
          }
          this.createdDate = DateHelper.toDayJs(this.course.createdDate).format('D MMMM YYYY в HH:mm');
          this.publishedDate = DateHelper.toDayJs(this.course.publishedDate).format('D MMMM YYYY в HH:mm');
          this.courseID = this.course.id;
          this.form = this.fb.group({
            id: new UntypedFormControl(this.course.id, []),
            createdDate: new UntypedFormControl(this.course.createdDate, []),
            publishedDate: new UntypedFormControl(this.course.publishedDate, []),
            name: new UntypedFormControl(this.course.name, [Validators.required]),
            institution: new UntypedFormControl(this.course.institution, [Validators.required]),
            shortDescription: new UntypedFormControl(this.course.shortDescription, [Validators.required]),
            description: new UntypedFormControl(this.course.description, [Validators.required]),
            hrid: new UntypedFormControl(this.course.hrid, [Validators.required]),
            unitPrice: new UntypedFormControl(this.course.unitPrice, [Validators.required]),
            metroStations: new UntypedFormControl(this.course.metroStations, []),
            talents: new UntypedFormControl(this.course.talents, []),
            unitDiscount: new UntypedFormControl(this.course.unitDiscount, []),
            isPercent: new UntypedFormControl(this.course.isPercent, []),
            promocode: new UntypedFormControl(this.course.promocode, []),
            startDate: new UntypedFormControl(this.course.startDate, []),
            startTime: new UntypedFormControl(this.course.startTime, []),
            minAgeAudience: new UntypedFormControl(this.course.minAgeAudience, []),
            maxAgeAudience: new UntypedFormControl(this.course.maxAgeAudience, []),
            address: new UntypedFormControl(this.course.address, []),
            url: new UntypedFormControl(this.course.url, []),
            mainImagePath: new UntypedFormControl(this.course.mainImagePath, []),
            deliveryMode: new UntypedFormControl(this.course.deliveryMode, []),
            complexity: new UntypedFormControl(this.course.complexity, []),
            courses: new UntypedFormControl(this.course.courses, []),
            classesFormat: new UntypedFormControl(this.course.classesFormat, []),
            region: new UntypedFormControl(this.course.regionId, [Validators.required]),
            municipality: new UntypedFormControl(this.course.municipalityId, []),
          });

          this.checkDataFromCourse();
          return this.getRegions().pipe(
            switchMap(regions => {
              const region = regions.find(r => r.id === this.course.regionId);
              return this.setRegion(region);
            }),
          );
        }),
      )
      .subscribe(
        r => this.overlayService.hide(),
        err => {
          this.overlayService.hide();
        },
      );
  }

  get f() {
    return this.form.controls;
  }

  setRegion(region): Observable<any> {
    return this.apiLocationsService.getRegion(region.id).pipe(
      switchMap(r => {
        this.selectedRegion = r;
        this.form.get('region').setValue(this.selectedRegion);
        this.municipalitiesByRegion$ = this.apiLocationsService.getMunicipalitiesByRegion(this.selectedRegion.id);
        return this.municipalitiesByRegion$.pipe(
          switchMap(r => {
            if (this.course.municipalityId) {
              return this.apiLocationsService.getMunicipality(this.course.municipalityId).pipe(
                tap(courseMunicipality => {
                  this.form.get('municipality').setValue(courseMunicipality);
                }),
              );
            } else {
              return of(null);
            }
          }),
        );
      }),
    );
  }

  getRegions(): Observable<any> {
    this.allRegions$ = this.apiLocationsService.getAllRegions().pipe(
      map(r => {
        // переименовываем дефолтный регион
        return r.map(r => {
          r.id === EmptyGuid ? (r['name'] = 'Дефолтный регион') : null;
          return r;
        });
      }),
    );

    return this.allRegions$;
  }

  selectRegion(region) {
    if (region) {
      if (region != this.selectedRegion) {
        this.clearMunicipality();
      }
      this.selectedRegion = region;
      this.municipalitiesByRegion$ = this.apiLocationsService.getMunicipalitiesByRegion(region.id);
    }
    return;
  }

  public clearMunicipality() {
    this.form.get('municipality').setValue(this.selectedMunicipality);
    return;
  }

  checkDataFromCourse() {
    if (this.course.classesFormat) {
      const classesFormat = this.classesFormats.filter(el => el.value === this.course.classesFormat);
      this.selectedFormat = classesFormat[0];
    }
    if (this.course.complexity) {
      const complexitys = this.complexitys.filter(el => el.value === this.course.complexity);
      this.selectedComplexity = complexitys[0];
    }
    if (this.course.deliveryMode) {
      const deliveryMode = this.delivery.filter(el => el.value === this.course.deliveryMode);
      this.selectedDelivery = deliveryMode[0];
    }
  }

  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  archive(course) {
    course.isArchived = true;
    const observables: Observable<any>[] = [];
    //mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.apiAdminsService.replaceClassDO(course));
    } else if (this.userRole == 'adminDO') {
      observables.push(this.apiAdminsService.replaceAdminDOClass(course));
    } else if (this.userRole == 'director') {
      observables.push(this.apiUsersService.replaceClassDODirectors(course));
    } else {
      //admin
      observables.push(this.apiSchoolsService.changeClassesDO(course));
    }
    observableForkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.utilsService.openSnackBar('👌 Курс добавлен в архив', 'success');
        this.addtoArchive = !this.addtoArchive;
      });
  }

  uploadLogoCourse(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.apiUtilsService
        .uploadImage(this.selectedFiles, this.course.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.mainImagePath.setValue(r);
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  dataMetro(data) {
    this.form.value.metroStations = data;
  }

  dataThemes(data) {
    this.form.value.courses = data;
  }

  dataTalents(data) {
    this.form.value.talents = data;
  }

  setComplexity(obj) {
    if (obj) {
      this.form.value.complexity = obj.value;
      this.selectedComplexity = obj;
    }
  }

  checkComplexityHeader() {
    if (this.selectedComplexity) {
      const town = this.complexitys.filter(el => el.value === this.selectedComplexity.value);
      return town[0].viewValue;
    } else {
      return 'Сложность курса';
    }
  }

  checkFormatsHeader() {
    if (this.selectedFormat) {
      const town = this.classesFormats.filter(el => el.value === this.selectedFormat.value);
      return town[0].viewValue;
    } else {
      return 'Формат курса';
    }
  }

  setFormat(obj) {
    if (obj) {
      this.selectedFormat = obj;
    }
  }

  setDelivery(obj) {
    if (obj) {
      this.form.value.deliveryMode = obj.value;
      this.selectedDelivery = obj;
    }
  }

  setPromoType(obj) {
    if (obj) {
      this.selectedPromoType = obj;
      this.form.get('promocode').setValidators([Validators.required]);
      if (this.selectedPromoType.value) {
        this.form.get('unitDiscount').setValidators([Validators.max(100), Validators.min(0), Validators.required]);
      } else {
        this.form.get('unitDiscount').setValidators([Validators.required]);
      }
    }
  }

  checkDeliveryHeader() {
    if (this.selectedDelivery) {
      const town = this.delivery.filter(el => el.value === this.selectedDelivery.value);
      return town[0].viewValue;
    } else {
      return 'Формат курса';
    }
  }

  checkPromocodeTypesHeader() {
    if (this.selectedPromoType) {
      const promoType = this.promocodeTypes.filter(el => el.value === this.selectedPromoType.value);
      return promoType[0].viewValue;
    } else {
      return 'Тип промокода';
    }
  }

  deselectPromoType() {
    this.selectedPromoType = null;
    this.form.get('unitDiscount').reset();
    this.form.get('promocode').reset();
    this.form.get('unitDiscount').setValidators(null);
    this.form.get('promocode').setValidators(null);
    this.form.get('promocode').updateValueAndValidity();
    this.form.get('unitDiscount').updateValueAndValidity();
  }

  checkHridAllow(): Observable<any> {
    if (this.router.url.includes('/edit-course/')) {
      if (this.oldHrid != this.form.value.hrid) {
        return this.apiSchoolsService.checkHrid(this.form.value.hrid);
      } else {
        this.checkhridObject.result = true;
        return of(this.checkhridObject);
      }
    } else if (this.router.url.includes('/copy-course/')) {
      return this.apiSchoolsService.checkHrid(this.form.value.hrid);
    }
    return;
  }

  submit() {
    this.submitted = true;
    this.checkHridAllow()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(checkhridResult => {
          if (this.form.valid && checkhridResult['result']) {
            const date = this.form.value.startDate ? DateHelper.toDayJs(this.form.value.startDate).format('YYYY-MM-DD') : null;
            const dateWithTime = date
              ? this.form.value.startTime
                ? DateHelper.toDayJs(date + 'T' + this.form.value.startTime).format()
                : DateHelper.toDayJs(date).format()
              : null;

            this.course = {
              id: this.form.value.id,
              hrid: this.form.value.hrid,
              address: this.form.value.address,
              classesFormat: this.form.value.classesFormat,
              description: this.form.value.description,
              institution: this.form.value.institution,
              maxAgeAudience: this.form.value.maxAgeAudience,
              minAgeAudience: this.form.value.minAgeAudience,
              name: this.form.value.name,
              shortDescription: this.form.value.shortDescription,
              startDate: dateWithTime,
              unitPrice: this.form.value.unitPrice,
              unitDiscount: this.form.get('unitDiscount').value ? this.form.value.unitDiscount.toString() : null,
              isPercent: this.selectedPromoType != null ? this.selectedPromoType.value : null,
              promocode: this.form.get('promocode').value ? this.form.value.promocode.toString() : '',
              url: this.form.value.url,
              deliveryMode: this.form.value.deliveryMode,
              mainImagePath: this.form.value.mainImagePath,
              talents: this.form.value.talents,
              courses: this.form.value.courses,
              metroStations: this.form.value.metroStations,
              isRegular: this.isRegular,
              complexity: this.form.value.complexity,
              publishedDate: this.form.value.publishedDate,
              regionId: this.form.value.region.id,
              municipalityId: this.form.value.municipality ? this.form.value.municipality.id : EmptyGuid,
              stand: Stands.Talent,
            };

            let currentObservable$: Observable<any>;
            if (this.isCopy) {
              //главный админ может делать копию всех курсов, остальные - только копию своих курсов
              this.course.id = EmptyGuid;
              if (this.userRole === 'adminDO') {
                currentObservable$ = this.apiAdminsService.createAdminDOClass(this.course);
              } else if (this.userRole === 'schooladmin') {
                Object.assign(this.course, { institutionId: this.webStorageService.get(StorageKeys.SchoolId) });
                currentObservable$ = this.apiAdminsService.createClassDO(this.course);
              } else if (this.userRole === 'director') {
                Object.assign(this.course, { institutionId: this.webStorageService.get(StorageKeys.SchoolId) });
                currentObservable$ = this.apiUsersService.createClassDODirectors(this.course);
              } else {
                //only admin
                currentObservable$ = this.apiSchoolsService.createClassesDO(this.course);
              }
            } else {
              if (this.userRole === 'adminDO') {
                currentObservable$ = this.apiAdminsService.replaceAdminDOClass(this.course);
              } else if (this.userRole === 'schooladmin') {
                Object.assign(this.course, { institutionId: this.webStorageService.get(StorageKeys.SchoolId) });
                currentObservable$ = this.apiAdminsService.replaceClassDO(this.course);
              } else if (this.userRole === 'director') {
                Object.assign(this.course, { institutionId: this.webStorageService.get(StorageKeys.SchoolId) });
                currentObservable$ = this.apiUsersService.replaceClassDODirectors(this.course);
              } else {
                //only admin
                currentObservable$ = this.apiSchoolsService.changeClassesDO(this.course);
              }
            }

            return currentObservable$.pipe(
              tap(r => {
                this.utilsService.openSnackBar('👌 Курс обновлён, вы будете перенаправлены на страницу данного мероприятия', 'success');
                return setTimeout(_ => {
                  this.router.navigate(['/events/' + this.form.value.hrid]);
                }, 2000);
              }),
            );
          } else {
            return this.getTranslation('SHARED.ERROR_MSG').pipe(tap(translation => this.utilsService.openSnackBar(translation, 'error')));
          }
        }),
      )
      .subscribe();
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  deleteCourse(courseID) {
    const observables: Observable<any>[] = [];
    //mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.apiAdminsService.deleteClassDO(courseID));
    } else if (this.userRole == 'director') {
      observables.push(this.apiUsersService.deleteClassDODirectors(courseID));
    } else if (this.userRole == 'adminDO') {
      observables.push(this.apiAdminsService.removeAdminDOClass(courseID));
    } else {
      //admin
      observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    }
    //elastic
    observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate([this.backButtonUrl()]);
      });
  }

  backButtonUrl() {
    switch (this.userRole) {
      case 'admin':
        return '/admin/courses';
      case 'schooladmin':
        return '/schooladmin/courses';
      case 'adminDO':
        return '/adminDO/courses';
      default:
        return '/courses';
    }
  }

  public compareItems(item1: any, item2: any) {
    return item1 && item2 && item1.id === item2.id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
