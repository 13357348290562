import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'app/shared/modules/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from 'app/pages/pages.module';
import { SharedModule } from 'app/shared/shared.module';
import { LoginProsvetComponent } from './login-prosvet/login-prosvet.component';
import { UpdatePupilProsvetComponent } from './login-prosvet/update-pupil-prosvet/update-pupil-prosvet.component';
import { ProsvetAuthRoutingModule } from './prosvet-auth-routing.module';
import {
  CodeRegistrationProsvetComponent,
} from './registration-prosvet/code-registration/code-registration-prosvet.component';
import {
  OpenRegistrationProsvetComponent,
} from './registration-prosvet/open-registration/open-registration-prosvet.component';
import { RegistrationProsvetComponent } from './registration-prosvet/registration-prosvet.component';
import {
  RegistrationPupilProsvetComponent,
} from './registration-prosvet/registration-pupil/registration-pupil-prosvet.component';

@NgModule({
  declarations: [
    LoginProsvetComponent,
    CodeRegistrationProsvetComponent,
    UpdatePupilProsvetComponent,
    OpenRegistrationProsvetComponent,
    RegistrationPupilProsvetComponent,
    RegistrationProsvetComponent,
  ],
  imports: [ProsvetAuthRoutingModule, CommonModule, MaterialModule, ReactiveFormsModule, PagesModule, SharedModule],
})
export class ProsvetAuthModule {}
