import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { EditUserProfileComponent } from './edit-user-profile/edit-user-profile.component';
import {
  EditUserProfileOutletComponent,
} from './user-profile-outlet/edit-user-profile-outlet/edit-user-profile-outlet.component';
import { UserProfileOutletComponent } from './user-profile-outlet/user-profile-outlet.component';
import { UserProfileComponent } from './user-profile.component';
import {
  PupilMobileEditUserAvatarComponent,
} from '../pupil-mobile-profile/pupil-mobile-edit-profile/pupil-mobile-edit-user-avatar/pupil-mobile-edit-user-avatar.component';

@NgModule({
  imports: [RouterModule, SharedModule, PupilMobileEditUserAvatarComponent],
  declarations: [UserProfileComponent, EditUserProfileComponent, UserProfileOutletComponent, EditUserProfileOutletComponent],
  bootstrap: [],
})
export class UserProfileModule {}
