<ng-container>
  <div class="results">
    <h1>{{ isKruzhkiVariant ? ('SHARED.GROUP_RESULTS' | translate) : ('SHARED.CLASS_RESULTS' | translate) }}</h1>

    <nav *ngIf="interestsData.length > 0 || skillsData.length > 0 || talentsData.length > 0">
      <div class="parent-menu" style="margin-top: 20px">
        <div class="parent-menu-row-2">
          <a
            *ngFor="let menuItem of navigationMenu"
            (click)="selectNavMenu(menuItem)"
            [class.w--current]="menuItem === selectedNavMenu"
            class="professions-menu-link"
          >
            {{ menuItem.name }}
          </a>
        </div>
      </div>

      <div class="divider"></div>
    </nav>

    <section *ngIf="selectedNavMenu.key === 'averageResults'">
      <div class="results-result">
        <h2 style="opacity: 0.5">В разработке</h2>
      </div>
    </section>

    <section *ngIf="selectedNavMenu.key === 'resultsInSection'">
      <div class="results-result" *ngIf="!existResults()">
        <h2 style="opacity: 0.5">{{ 'SHARED.NOBODY_YET' | translate }} {{ 'SHARED.NOT_TESTING' | translate }}</h2>
      </div>
      <div *ngIf="existResults()">
        <div class="results-result">
          <h2>{{ 'TEST_AND_RESULTS.SKILS.INTERESTS' | translate }}</h2>
          <div class="subtitle">Распределение интересов в {{ dictionary.ClassPrepositional }}</div>
          <prf-results-objecttype-chart-and-bars *ngIf="interestsData.length != 0" [data]="interestsData">
          </prf-results-objecttype-chart-and-bars>
          <div class="divider"></div>
        </div>
        <div class="results-result">
          <h2>Навыки и способности</h2>
          <div class="subtitle">Распределение навыков и способностей в {{ dictionary.ClassPrepositional }}</div>
          <prf-results-objecttype-chart-and-bars *ngIf="skillsData.length != 0" [data]="skillsData">
          </prf-results-objecttype-chart-and-bars>
          <div class="divider"></div>
        </div>
        <div class="results-result">
          <h2>Таланты</h2>
          <div class="subtitle">Распределение талантов в {{ dictionary.ClassPrepositional }}</div>
          <prf-results-objecttype-chart-and-bars *ngIf="talentsData.length != 0" [data]="talentsData">
          </prf-results-objecttype-chart-and-bars>
        </div>
      </div>
    </section>

    <section *ngIf="selectedNavMenu.key === 'education'">
      <div class="results-result" *ngIf="schoolStatistic?.completedTestsCount == 0">
        <h2 style="opacity: 0.5">{{ 'SHARED.NOBODY_YET' | translate }} {{ 'SHARED.NOT_TESTING' | translate }}</h2>
      </div>
      <div *ngIf="schoolStatistic?.completedTestsCount != 0">
        <div class="results-result">
          <h2>Уровни образования</h2>
          <div class="subtitle">Предпочитаемый уровень образования</div>
          <prf-results-objecttype-chart-and-bars [data]="schoolStatisticsData"> </prf-results-objecttype-chart-and-bars>
          <div class="divider"></div>
        </div>

        <div class="results-result">
          <h2>{{ 'SHARED.PROFESSIONAL_EXPECTATIONS' | translate }}</h2>
          <div class="subtitle">Куда хотят поступить</div>
          <prf-results-answers-bars [data]="resultExpectationsData" [color]="color_turquoise" [isSingleColor]="true">
          </prf-results-answers-bars>
          <div class="divider"></div>
        </div>

        <div class="results-result">
          <h2>
            {{
              isKruzhkiVariant
                ? ('SHARED.PROFESSIONAL_RECOMMENDATIONS_GROUPS' | translate)
                : ('SHARED.PROFESSIONAL_RECOMMENDATIONS' | translate)
            }}
          </h2>
          <div class="subtitle">Рекомендованные ученикам профили {{ dictionary.ClassesGenitive }} по результатам диагностики</div>
          <prf-results-answers-bars [data]="profileClassData" [color]="color_turquoise" [isSingleColor]="true"> </prf-results-answers-bars>
          <div class="divider"></div>
        </div>

        <!-- <div class="results-result">
          <h2>Профильные классы</h2>
          <div class="subtitle">
            Распределение рекомендованных профилей обучения / что посещает сейчас
          </div>
          <prf-results-answers-bars [data]="profileClassesData" [color]="color_purple">
          </prf-results-answers-bars>
          <div class="divider"></div>
        </div> -->

        <div class="results-result">
          <h2>{{ 'SHARED.ADDITIONAL_EDUCATION' | translate }}</h2>
          <div class="subtitle">
            {{ 'SHARED.DISTRIBUTION_RECOMMENDED_PROG_ADD_EDU' | translate }}
          </div>
          <prf-results-answers-bars [data]="additionalEduData" [color]="color_turquoise" [isSingleColor]="true"> </prf-results-answers-bars>
        </div>
      </div>
    </section>

    <section *ngIf="selectedNavMenu.key === 'profNavigation'">
      <div class="results-result" *ngIf="schoolStatistic?.completedTestsCount == 0">
        <h2 style="opacity: 0.5">{{ 'SHARED.NOBODY_YET' | translate }} {{ 'SHARED.NOT_TESTING' | translate }}</h2>
      </div>
      <div *ngIf="schoolStatistic?.completedTestsCount != 0">
        <div class="results-result">
          <h2>{{ 'SHARED.PROFESSIONAL_EXPECTATIONS' | translate }}</h2>
          <div class="subtitle">{{ 'SHARED.CHOICE_READINESS_LEVEL' | translate }}</div>
          <prf-results-objecttype-chart-and-bars [data]="profExpectData"></prf-results-objecttype-chart-and-bars>
          <div class="divider"></div>
        </div>
        <div *ngIf="existResults()">
          <div class="results-result">
            <h2>Профессиональные сферы</h2>
            <div class="subtitle">
              {{
                isKruzhkiVariant
                  ? ('SHARED.DISTRIBUTION_RECOMMENDED_SPHERES_GROUPS' | translate)
                  : ('SHARED.DISTRIBUTION_RECOMMENDED_SPHERES' | translate)
              }}
            </div>
            <prf-results-answers-bars *ngIf="fieldsData.length != 0" [data]="fieldsData" [color]="color_purple" [isSingleColor]="true">
            </prf-results-answers-bars>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
