<ng-container *ngFor="let holder of placeholders; index as i">
  <span class="code-input__field-wrapper" [class.code-hidden]="isCodeHidden">
    <input
      #input
      (click)="onClick($event)"
      (paste)="onPaste($event, i)"
      (input)="onInput($event, i)"
      (keydown)="onKeydown($event, i)"
      [type]="inputType"
      [disabled]="disabled"
      [attr.inputmode]="inputMode"
      [attr.autocapitalize]="autocapitalize"
      autocomplete="one-time-code"
      [class.error]="currentState === CodeInputStateEnum.ERROR"
      [class.disabled]="currentState === CodeInputStateEnum.DISABLED"
    />
  </span>
</ng-container>
