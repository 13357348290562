export enum institutionTypes {
  UNIVERSITY = 'Я планирую окончить 11 классов и поступить в вуз',
  COLLEGE = 'Я планирую окончить 9 классов и поступить в колледж',
  OTHER = 'Затрудняюсь ответить',
}

export enum educationLevels {
  'Высшее образование' = 1,
  'Среднее-профессиональное образование' = 2,
  'Не определился' = 3,
}
