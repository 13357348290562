import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { TippyDirective } from '@ngneat/helipopper';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, TooltipComponent, TippyDirective],
  exports: [DropdownComponent],
})
export class DropdownModule {}
