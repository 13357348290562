import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageHeaderService } from '../../page-header/page-header.service';
import { MenuService } from '../menu/menu.service';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-director-menu',
  templateUrl: './director-menu.component.html',
  styleUrls: ['./director-menu.component.scss'],
})
export class DirectorMenuComponent implements OnInit {
  @Input() innerRoute: boolean = false;
  @Input() professionActive: boolean = false;
  public href: string;
  public root: string = 'director';

  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';

  isMOStend: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private headerService: PageHeaderService,
    private webStorageService: WebStorageService,
  ) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = this.webStorageService.get(StorageKeys.ImagePath);
    this.firstName = this.webStorageService.get(StorageKeys.FirstName);
    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {}

  get mainActive(): boolean {
    return this.router.url === '/director' || this.router.url.includes('/director/class/');
  }

  public selectMain() {
    this.hideMobileMenu();
    return this.router.navigate(['/' + this.root]);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root + '/profile']);
  }
}
