import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAdminsService,
  ApiFieldsService,
  ApiLocationsService,
  ApiProfessionsService,
  ApiUtilsService,
  Company,
  EmptyGuid,
  IMunicipality,
  IRegion,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditCompanyComponent extends UnsubscribeComponent implements OnInit {
  hrid: string;
  company: Company;
  createdDate: string;
  updatedDate: string;
  public showInfoPopup: boolean = false;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public userRole: string;
  public selectedFiles: FileList;
  public companyId: string;
  public title: string = 'Редактирование';
  public checkhrid: boolean;
  public oldHrid: string;
  fields: any[];
  professions: any[];

  //объекты территориальной привязки
  public allRegions: IRegion[] = [];
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public selectedMunicipality: IMunicipality = {
    id: EmptyGuid,
    hrid: '',
    name: '',
    regionId: '',
  };

  constructor(
    private meta: Meta,
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private overlayService: OverlayBusyService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private apiAdminsService: ApiAdminsService,
    private apiLocationsService: ApiLocationsService,
    private apiProfessionsService: ApiProfessionsService,
    private apiUtilsService: ApiUtilsService,
    private apiFieldsService: ApiFieldsService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование компании' });

    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  public ngOnInit(): void {
    this.overlayService.show();

    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.companyId = params['companyId'];
    });
    this.apiAdminsService
      .getCompany(this.companyId)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(result => {
          if (result) {
            this.company = result.company;
            return this.createForm();
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  private createForm(): Observable<any> {
    this.oldHrid = this.company.hrid;
    this.createdDate = DateHelper.toDayJs(this.company.createdDate).format('D MMMM YYYY в HH:mm');
    this.updatedDate = DateHelper.toDayJs(this.company.updatedDate).format('D MMMM YYYY в HH:mm');
    this.form = this.fb.group({
      id: new UntypedFormControl(this.company.id, []),
      createdDate: new UntypedFormControl(this.company.createdDate, []),
      updatedDate: new UntypedFormControl(this.company.updatedDate, []),
      name: new UntypedFormControl(this.company.name, [Validators.required]),
      companyDescription: new UntypedFormControl(this.company.companyDescription, [Validators.required]),
      hrid: new UntypedFormControl(this.company.hrid, [Validators.required]),
      employeesCount: new UntypedFormControl(this.company.employeesCount, []),
      address: new UntypedFormControl(this.company.address, []),
      websiteLink: new UntypedFormControl(this.company.websiteLink, []),
      responsiblePerson: new UntypedFormControl(this.company.responsiblePerson, []),
      email: new UntypedFormControl(this.company.email, []),
      phone: new UntypedFormControl(this.company.phone, []),
      logoUrl: new UntypedFormControl(this.company.logoUrl, []),
      fieldNames: new UntypedFormControl(this.company.fieldNames, []),
      professions: new UntypedFormControl(this.company.professions, []),
      region: new UntypedFormControl(this.selectedRegion, [Validators.required]),
      municipality: new UntypedFormControl(this.selectedMunicipality, []),
      city: new UntypedFormControl(this.selectedMunicipality, []),
      vkontakteLink: new UntypedFormControl(this.company.vkontakteLink, []),
      facebookLink: new UntypedFormControl(this.company.facebookLink, []),
      instagramLink: new UntypedFormControl(this.company.instagramLink, []),
      okLink: new UntypedFormControl(this.company.okLink, []),
      youtubeLink: new UntypedFormControl(this.company.youtubeLink, []),
      yandexZenLink: new UntypedFormControl(this.company.yandexZenLink, []),
      news: new UntypedFormControl(this.company.news, []),
    });

    return this.getRegions().pipe(() => {
      return this.setRegion(this.company.regionId).pipe(
        switchMap(() => {
          return this.getObjectLists();
        }),
      );
    });
  }

  getObjectLists(): Observable<any> {
    return observableForkJoin([
      this.apiFieldsService.getAllFields(),
      this.apiProfessionsService.getProfessionsByFilters({ regionId: this.company.regionId }),
    ]).pipe(
      tap(([fields, professions]) => {
        this.fields = fields;
        this.fields.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.professions = professions;
        this.professions.sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
    );
  }

  get f() {
    return this.form.controls;
  }

  setRegion(regionId): Observable<any> {
    return this.apiLocationsService.getRegion(regionId).pipe(
      switchMap(r => {
        this.selectedRegion = r;
        this.form.get('region').setValue(this.selectedRegion);
        return this.apiLocationsService.getMunicipalitiesByRegion(this.selectedRegion.id).pipe(
          switchMap(r => {
            this.municipalitiesByRegion = r;
            return this.apiLocationsService.getMunicipality(this.company.municipalityId).pipe(
              tap(r => {
                this.form.get('municipality').setValue(r);
              }),
            );
          }),
        );
      }),
    );
  }

  getRegions(): Observable<any> {
    return this.apiLocationsService.getAllRegions().pipe(
      tap(r => {
        this.allRegions = r.filter(region => region.id != EmptyGuid);
      }),
    );
  }

  selectRegion(region) {
    if (region) {
      if (region != this.selectedRegion) {
        this.clearMunicipality();
      }
      this.selectedRegion = region;
      this.apiLocationsService
        .getMunicipalitiesByRegion(region.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => (this.municipalitiesByRegion = response));
    }
  }

  public clearMunicipality() {
    this.form.get('municipality').setValue(this.selectedMunicipality);
    return;
  }

  uploadLogo(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.apiUtilsService
        .uploadImage(this.selectedFiles, this.company.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(logoUrl => {
          this.form.value.logoUrl = logoUrl;
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.updatedDate = DateHelper.toDayJs(new Date()).format('YYYY-MM-DD');
      this.company = {
        id: this.form.value.id,
        hrid: this.form.value.hrid,
        address: this.form.value.address,
        name: this.form.value.name,
        companyDescription: this.form.value.companyDescription,
        employeesCount: this.form.value.employeesCount,
        responsiblePerson: this.form.value.responsiblePerson,
        email: this.form.value.email,
        phone: this.form.value.phone,
        websiteLink: this.form.value.websiteLink,
        logoUrl: this.form.value.logoUrl,
        fieldNames: this.form.value.fieldNames,
        professions: this.form.value.professions,
        vkontakteLink: this.form.value.vkontakteLink,
        facebookLink: this.form.value.facebookLink,
        instagramLink: this.form.value.instagramLink,
        okLink: this.form.value.okLink,
        youtubeLink: this.form.value.youtubeLink,
        yandexZenLink: this.form.value.yandexZenLink,
        news: this.form.value.news,
        updatedDate: this.form.value.updatedDate,
        createdDate: this.form.value.createdDate,
        regionId: this.form.value.region.id,
        municipalityId: this.form.value.municipality.id,
        cityId: this.form.value.city.id,
      };

      this.apiAdminsService
        .updateCompany(this.company)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result.status == 'Success') {
            this.utilsService.openSnackBar('👌 Информация о компании обновлена', 'success');
            return setTimeout(_ => {
              this.router.navigate(['/employer']);
            }, 2000);
          }
        });
    } else {
      this.getTranslation('SHARED.ERROR_MSG')
        .pipe(take(1))
        .subscribe(translation => this.utilsService.openSnackBar(translation, 'error'));
    }
  }

  public compareItems(item1: any, item2: any) {
    return item1 && item2 && item1.id === item2.id;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
