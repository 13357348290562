import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrfLegalItem } from '../../../../components/footer/footer.types';

@Component({
  selector: 'prf-footer-legal-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer-legal-item.component.html',
  styleUrl: './footer-legal-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLegalItemComponent {
  @Input() legal: PrfLegalItem;
}
