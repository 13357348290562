<div class="chat">
  <prf-confetti *ngIf="confetti"></prf-confetti>
  <div class="chat-section">
    <div class="chat-container" #list id="list">
      <div
        *ngFor="let message of showedMessages | slice : 0 : endMessageNumber; let i = index; trackBy: trackByFn"
        class="chat-message"
        [class.chat-message-right]="message.position ?? message.position === 'right'"
      >
        <div
          class="chat-message-text"
          [class.chat-message__with-icon]="message.icon"
          [class.right-position]="message.position ?? message.position === 'right'"
        >
          <div [innerHTML]="message.text"></div>
          <img *ngIf="message.image" [src]="message.image" alt="/" class="chat-message-image"/>
          <img *ngIf="message.icon" class="chat-message-icon" [ngSrc]="message.icon" width="20" height="20"
               alt="/"/>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-footer">
    <ng-container *ngFor="let button of buttons; trackBy: trackByFn">
      <button
        type="button"
        class="chat-footer-button"
        [class.chat-footer-button_white]="button.style === 'white'"
        (click)="showActions(button)"
      >
        {{ button.text }}
      </button>
    </ng-container>
  </div>
</div>
