import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IButtonOnbording, IMessageOnbording } from 'app/shared/interfaces/common/ondording.interface';
import { ProfessionTryingOnStage, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IProfessionsTryOnData } from 'app/shared/interfaces/iprofessionstryon.interface';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { StorageKeys as UserStorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';

export enum OnbordingButtonsTypes {
  Next = 'next',
  Close = 'close',
  More = 'more',
}

@Component({
  selector: 'prf-fitting-professions-onbording',
  templateUrl: './fitting-professions-onbording.component.html',
  styleUrls: ['./fitting-professions-onbording.component.scss'],
})
export class FittingProfessionsOnbordingComponent extends UnsubscribeComponent {
  public endMessageNumber: number = 1;
  public messages: IMessageOnbording[] = [
    {
      text:
        '<p class="left-position-text">Отлично! Список профессий собран, а теперь нужно отсеять менее интересные. ' +
        'В этом задании ты увидишь <span class="onbording-main-text">20 профессий</span>, подобранных на основе твоего избранного и результатов диагностики. ' +
        'Мы поможем тебе <span class="onbording-main-text">определиться с самыми интересными</span>.</p>',
    },
    {
      text: '<p class="right-position-text">Почему будет 20 профессий?</p>',
      position: 'right',
    },
    {
      text:
        '<p class="left-position-text">Потому что это наиболее эффективный способ отбора по интересам. ' +
        '<span class="onbording-main-text">Интересно</span> — это то, что вызывает у тебя ' +
        '<span class="onbording-main-text">чувство приятного, то, что ты хочешь делать еще и еще, что нравится тебе больше всего.</span></p>',
    },
  ];
  public buttons: IButtonOnbording[] = [{ text: 'Почему будет 20 профессий?', type: OnbordingButtonsTypes.Next }];

  constructor(private router: Router, private userStorageService: UserStorageService, private webStorageService: WebStorageService) {
    super();
  }

  public buttonsActions(event: string): void {
    switch (event) {
      case OnbordingButtonsTypes.Next: {
        this.endMessageNumber = 3;
        this.timer = setTimeout(() => {
          this.buttons = [{ text: 'Всё ясно, идём выбирать!', type: OnbordingButtonsTypes.Close }];
        }, 600);
        break;
      }
      case OnbordingButtonsTypes.Close: {
        const tryOnData: IProfessionsTryOnData = {
          nextStage: ProfessionTryingOnStage.FilteringByInterests,
          nextOnboarding: ProfessionTryingOnStage.FilteringByCapabilities,
        };
        this.userStorageService.set(UserStorageKeys.ProfessionTryingOnData, tryOnData);
        this.webStorageService.set(StorageKeys.ProfessionTryingOnData, tryOnData);

        this.router.navigate(['/interests-fitting-programs']);
        break;
      }
      default: {
        break;
      }
    }
  }
}
