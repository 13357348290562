export interface ITryingOnStageShowedSteps {
  teacherSteps?: number;
  pupilSteps?: number;
}

export interface IPassedTeacherTryingOnLesson {
  lessonId?: string;
  lessonNumber?: number;
}

export enum TryingOnSteps {
  step0 = 0,
  step1 = 1,
  step2 = 2,
  step3 = 3,
  step4 = 4,
}
