import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, IData, UserActionsService, WebStorageService, YmItems } from '@profilum-library';
import { ISlideViewInterface } from '../../shared/interfaces/islideview.interface';

@Injectable()
export class PlayerService {
  public currentTest: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentQuestion: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentAnswers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public answersForLastSection: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _isValidOpenAnswer: BehaviorSubject<boolean>;

  constructor(private http: HttpService, private webStorageService: WebStorageService, private userActionsService: UserActionsService) {
    this._isValidOpenAnswer = new BehaviorSubject<boolean>(true);
  }

  public set isValidOpenAnswer(disable: boolean) {
    this._isValidOpenAnswer.next(disable);
  }

  public get isValidOpenAnswerSubject(): Observable<boolean> {
    return this._isValidOpenAnswer.asObservable();
  }

  public setCurrentQuestionInfo(slideView: ISlideViewInterface, sectionNumber: number = 0): void {
    this.currentQuestion.next({
      name: slideView?.slide?.slideView?.bubbleView?.sections[sectionNumber]?.name,
      id: slideView?.slide?.questionId,
    });
  }

  public logDiagnosticExit(): void {
    const diagnostic = this.currentTest.getValue();
    const question = this.currentQuestion.getValue();

    const uaParams: IData = {
      Diagnostic: diagnostic,
      Question: question,
    };
    const ymParams: IData = {
      event_label: {
        Diagnostic_Name: diagnostic?.name,
        Diagnostic_Id: diagnostic?.id,
        Question: question?.name,
        Question_Id: question?.id,
      },
    };

    this.userActionsService.log(YmItems.S_Lessons_Diagnostics_CrossButton, uaParams, ymParams);
  }
}
