import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from '@profilum-library';

@Pipe({ name: 'translateByDefault' })
export class TranslateByDefaultPipe implements PipeTransform {
  constructor(private _translate: TranslateService, private appSettingsService: AppSettingsService) {}

  transform(key: string): string | any {
    return this.appSettingsService.getByDefaultLocale(key) || this._translate.instant(key);
  }
}
