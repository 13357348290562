import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  public formatDate(date: string): string {
    if (date === null) {
      return '';
    }
    const datePart: RegExpMatchArray = date.match(/\d+/g);
    const year: string = datePart[0];
    const month: string = datePart[2];
    const day: string = datePart[1];

    return day + '-' + month + '-' + year;
  }

  public isValidDateFormat(date: string): boolean {
    return new Date(date).toString() !== 'Invalid Date';
  }
}
