<form [formGroup]="form" autocomplete="off">
  <div class="profile-data-wrapper">
    <div class="profile-data-row">
      <div>
        <label for="lastName" class="login-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          prfNoAutocomplete
          class="login-field w-input"
          [class.error-field]="submitted && f.lastName.errors"
          [attr.disabled]="f.lastName.status === 'DISABLED' ? true : null"
          name="lastName"
          maxlength="25"
          data-name="lastName"
          id="lastName"
          formControlName="lastName"
        />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="firstName" class="login-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          prfNoAutocomplete
          class="login-field w-input"
          [class.error-field]="submitted && f.firstName.errors"
          [attr.disabled]="f.firstName.status === 'DISABLED' ? true : null"
          name="firstName"
          maxlength="25"
          data-name="firstName"
          id="firstName"
          formControlName="firstName"
        />
      </div>
    </div>

    <div class="profile-data-row" *ngIf="f.middleName.value">
      <div>
        <label for="middleName" class="login-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          prfNoAutocomplete
          class="login-field w-input"
          [class.error-field]="submitted && f.middleName.errors"
          [attr.disabled]="f.middleName.status === 'DISABLED' ? true : null"
          name="middleName"
          maxlength="25"
          data-name="middleName"
          id="middleName"
          formControlName="middleName"
        />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="date" class="login-label">Дата рождения:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          prfNoAutocomplete
          class="login-field w-input"
          [class.error-field]="submitted && f.date.errors"
          [attr.disabled]="f.date.status === 'DISABLED' ? true : null"
          [textMask]="{ mask: dateMask }"
          name="date"
          data-name="date"
          id="date"
          formControlName="date"
          (focusin)="checkBirthday = false"
          (focusout)="checkFormatDate($event)"
        />
        <ng-container *ngIf="checkBirthday">
          <mat-error *ngIf="f.date.errors">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.CHECK_DATA' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="profile-data-row">
      <label for="email" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}:</label>
      <div class="profile-field-div">
        <input
          type="email"
          autocomplete="off"
          prfNoAutocomplete
          class="login-field w-input"
          [class.error-field]="(submitted && f.email.errors) || (submitted && duplicateUserName)"
          [attr.disabled]="f.email.status === 'DISABLED' ? true : null"
          maxlength="70"
          name="email"
          data-name="email"
          id="email"
          formControlName="email"
          (focusout)="checkFormatEmail($event)"
        />
        <ng-container>
          <mat-error *ngIf="!checkEmail">
            {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
          </mat-error>
          <mat-error *ngIf="submitted && duplicateUserName">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.EMAIL_EXIST' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label class="login-label">Пол:</label>
        <div class="div-space"></div>
      </div>
      <prf-gender-toggle [formControl]="form.controls['gender']" [genderLocked]="f.gender.status === 'DISABLED'"></prf-gender-toggle>
    </div>

    <!-- <prf-dropdown-with-search
      *ngIf="citiesView && citiesView.length"
      formControlName="city"
      [label]="'SHARED.FORM.CITY' | translate"
      [options]="getCitiesView"
      (ngModelChange)="onCityChange()"
      [isError]="submitted && f.city.errors"
      [disabledInput]="f.city.status === 'DISABLED'"
    ></prf-dropdown-with-search> -->

    <!-- <prf-dropdown-with-search
    *ngIf="schoolView && schoolView.length"
      formControlName="school"
      [label]="'SHARED.FORM.SCHOOL' | translate"
      [options]="getSchoolView"
      (ngModelChange)="onSchoolChange()"
      [isError]="submitted && f.school.errors"
      [disabledInput]="f.school.status === 'DISABLED'"
    ></prf-dropdown-with-search> -->

    <prf-dropdown-with-search
      *ngIf="f.school.value || (f.city.value && getSchoolView && getSchoolView.length)"
      formControlName="school"
      [label]="'SHARED.FORM.SCHOOL' | translate"
      [options]="getSchoolView"
      (ngModelChange)="onSchoolChange()"
      [isError]="submitted && f.school.errors"
      [disabledInput]="f.school.status === 'DISABLED'"
    ></prf-dropdown-with-search>

    <div class="profile-data-row" *ngIf="f.school.value">
      <div>
        <label class="login-label">Класс:</label>
        <div class="div-space"></div>
      </div>
      <div class="dd-container">
        <prf-dropdown-with-search
          *ngIf="isClasses"
          class="dropdown-inline cat"
          formControlName="schoolClass"
          [label]="'Класс'"
          [type]="1"
          [options]="schoolClassView"
          [isError]="submitted && f.schoolClass.errors"
        ></prf-dropdown-with-search>

        <prf-dropdown-with-search
          *ngIf="!isClasses"
          class="dropdown-inline cat dd-padding-r"
          formControlName="schoolClassNumber"
          [label]="'Номер'"
          [type]="1"
          [options]="schoolClassNumber"
          [isError]="submitted && f.schoolClassNumber.errors"
        ></prf-dropdown-with-search>

        <prf-dropdown-with-search
          *ngIf="!isClasses"
          class="dropdown-inline cat dd-padding-l"
          formControlName="schoolClassLetter"
          [label]="'Класс'"
          [type]="1"
          [options]="schoolClassLetter"
          [isError]="submitted && f.schoolClassLetter.errors"
        ></prf-dropdown-with-search>

        <a class="dd-toggle-isclass" (click)="tooggleSelectClassType()">{{
          isClasses ? ('SHARED.FORM.CLASS_NOT_LISTED' | translate) : ('SHARED.FORM.SELECT_LISTED' | translate)
        }}</a>
      </div>
    </div>

    <div class="w-center-content">
      <a
        (click)="animateLogin()"
        [ngClass]="{ disabled: !isAccessAllowed }"
        [class.btn-waiting]="buttonWaiting"
        [class.btn-activated]="buttonActivated"
        [class.btn-activate]="!buttonActivate"
        class="button-animate btn-activate"
        id="btnActivation"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Обновлено">
          {{ 'SHARED.UPDATE' | translate }}
        </span>
      </a>
    </div>
  </div>
</form>
