<div class="download-pupils-accesses">
  <span class="download-pupils-accesses__title">Скачайте доступы учеников</span>
  <p class="download-pupils-accesses__description">
    По ссылке — документ, в котором инструкция и код для каждого ученика. Распечатайте и раздайте детям.
  </p>
  <button (click)="downloadAccesses()" class="download-pupils-accesses__button">
    <ng-template [ngIf]="!loading" [ngIfElse]="loader"> Скачать доступы </ng-template>
  </button>
</div>

<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>
