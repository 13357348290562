<div *ngIf="show" class="perform-task-lb lb" style="opacity: 1; display: block">
  <div class="lb-bg" (click)="close()"></div>
  <div class="lb-block task-lb">
    <a (click)="close()" class="close-lb w-inline-block">
      <img src="https://uploads-ssl.webflow.com/5b31859f1e52342926ff9cad/5b3e7f1a389771aacd3dcdf0_close-lb.svg" />
    </a>
    <div class="tasl-lb-row">
      <div class="task-lb-column">
        <h3 class="task-lb-heading">{{ staticTask.name }}</h3>
        <div class="task-description">
          {{ staticTask.description }}
          <h4 *ngIf="staticTask.subtasks && staticTask.subtasks.length">
            {{ 'SHARED.DEMANDS_FOR_TASK' | translate }}
          </h4>
          <p *ngFor="let task of staticTask.subtasks">{{ task }}</p>

          <ng-container *ngIf="staticTask.urls && staticTask.urls.length > 0">
            <h4>Ссылки:</h4>
            <ul class="w-list-unstyled">
              <li *ngFor="let url of staticTask.urls">
                <a href="{{ url }}">{{ url }}</a>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
      <div class="task-lb-column _2">
        <h4 class="task-lb-heading-result">{{ 'SHARED.RESULT.BASE' | translate }}</h4>
        <div class="task-result-text">{{ 'SHARED.TEST_DOC' | translate }}</div>

        <ul class="attached-files-list w-list-unstyled">
          <li *ngFor="let file of staticTask.files">
            <div *ngIf="file.id" class="attached-file-row _2">
              <div (click)="downloadFile(file)" class="attach-icon-div">
                <img src="./profilum-assets/images/icons/attached-file-icon-2.svg" />
              </div>
              <div (click)="downloadFile(file)" class="attached-file-title">{{ file.name }}</div>
              <div class="attached-file-column">
                <a (click)="deleteFile(file)" class="delete-attachment w-inline-block" [class.inactive]="!isAvailable">
                  <img src="./profilum-assets/images/icons/delete.svg" />
                </a>
              </div>
            </div>

            <div *ngIf="!file.id" class="attached-file-row _2">
              <div class="attach-icon-div border">
                <a class="w-inline-block">
                  <img src="./profilum-assets/images/icons/cancel-upload.svg" />
                </a>
              </div>
              <div class="attached-file-title">{{ file.name }}</div>
              <div class="attached-file-column">
                <div *ngIf="file.progress">{{ file.progress }}%</div>
              </div>
            </div>
          </li>
        </ul>

        <a (click)="selectFileButton()" class="button attach-file-btn w-button" [class.inactive]="!isAvailable">{{
          'SHARED.ATTACH_FILE' | translate
        }}</a>
        <input type="file" hidden #fileInput (change)="selectFile($event)" accept=".doc" />

        <mat-error *ngIf="errorMes !== ''" style="margin-top: 15px">{{ errorMes }}</mat-error>

        <div *ngIf="staticTask.files?.length" class="complete-message">
          <img src="./profilum-assets/images/icons/complete-icon.svg" class="complete-icon" />
          <div class="complete-text">{{ 'SHARED.TASK_COMPLETE' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
