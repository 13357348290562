<section class="answers-bars" [class.answers-bars_wide]="showByPupil">
  <div class="progress-bars">
    <ul *ngFor="let bar of data; let coloridx = index">
      <li class="bar-container">
        <div class="bar-name" [class.bar-name_no-result]="isNoResult">{{ bar.name }}</div>
        <div class="bar-wrapper">
          <div class="bar-section">
            <div class="bar-section-percent" [ngStyle]="{ 'width.%': bar.percents, 'background-color': getColor(coloridx) }"></div>
            <div class="bar-section-background"></div>
          </div>
          <ng-container *ngIf="!showByPupil; else showByPupilTemplate">
            <div
              class="percents"
              [ngStyle]="{
                'background-color': getColor(coloridx),
                color: bar.percents == 0 ? getColor(coloridx) : null
              }"
              [ngClass]="{ zero: bar.percents == 0 }"
            >
              {{ bar.percents | number : '1.0-0' }}%
            </div>
          </ng-container>
          <ng-template #showByPupilTemplate>
            <div
              class="percents percents_wide"
              [class.percents_no-result]="isNoResult"
              [ngStyle]="{
                'background-color': getColor(coloridx),
                color: bar.percents == 0 ? color_darkGray : null
              }"
              [ngClass]="{ zero: bar.percents == 0 }"
            >
              {{ bar.count | number : '1.0-0' }}
              {{ declOfNum(bar.count, [dictionary.Pupil, dictionary.PupilGenitive, dictionary.PupilsGenitive]) }}
            </div>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
</section>
