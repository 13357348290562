<div class="events-filters">
  <prf-dropdown-component
    [isSorting]="true"
    [dropdownClasses]="COURSES_TYPES_DATA_NEW.isActive && 'highlighted'"
    [selectedOption]="COURSES_TYPES_DATA_NEW.activeOption"
    [dropdownItems]="COURSES_TYPES_DATA_NEW.options"
    (updateValue)="handleSetType($event)"
  ></prf-dropdown-component>

  <prf-courses-group-filter></prf-courses-group-filter>

  <div class="filter-div cat" #ageFilter>
    <div class="professions-dropdown w-dropdown">
      <div
        class="professions-dropdown-toggle w-dropdown-toggle"
        (click)="ageDrop.classList.toggle('w--open'); ageFilter.classList.toggle('w--open')"
        [class.active]="filters.audience && filters.audience.length > 0"
      >
        <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
        <div>{{ checkAgeHeader() }}</div>
      </div>
      <nav class="dd-prof-filter-list right-position w-dropdown-list f-age" #ageDrop>
        <div class="dd-prof-filter-div _2 age-filter-div">
          <div class="filter-list-row">
            <div class="filter-list-heading">{{ 'PLAYER.ANKETA.TELL_ABOUT.AGE' | translate }}</div>
            <a
              class="clear-categories"
              [class.show]="filters.audience && filters.audience.length > 0"
              (click)="setFilter({ audience: null }); ageDrop.classList.remove('w--open')"
              >{{ 'SHARED.CLEAR_BUTTON' | translate }}</a
            >
          </div>
          <div class="age-div">
            <a
              class="event-age"
              *ngFor="let age of ageRangeData"
              [class.w--current]="age.value == filters.audience"
              (click)="setFilter({ audience: age.value }); ageDrop.classList.remove('w--open')"
              >{{ age.label }}</a
            >
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="filter-div cat" #priceFilter>
    <div class="professions-dropdown w-dropdown">
      <div
        class="professions-dropdown-toggle w-dropdown-toggle"
        (click)="priceDrop.classList.toggle('w--open')"
        [class.active]="filters && filters.maxPrice >= 0 && priceHeader !== ('SHARED.PRICE' | translate)"
      >
        <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
        <div>{{ priceHeader }}</div>
      </div>
      <nav class="dd-prof-filter-list right-position w-dropdown-list f-price" #priceDrop>
        <div class="dd-prof-filter-div _2 age-filter-div">
          <div class="filter-list-row">
            <div class="filter-list-heading">{{ 'SHARED.PRICE' | translate }}, {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
            <a
              class="clear-categories"
              [class.show]="filters.maxPrice > 0"
              (click)="setFilter({ maxPrice: null }); maxPrice = null"
              *ngIf="maxPrice"
              >{{ 'SHARED.CLEAR_BUTTON' | translate }}</a
            >
          </div>
          <div class="form-block-2 w-form">
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              (ngSubmit)="setFilter({ maxPrice: maxPrice }); checkBox.classList.remove('checked')"
            >
              <div class="filter-price-row">
                <div class="filter-price-column">
                  <div class="price-filter-text">
                    {{ 'SHARED.BEFORE' | translate | uppercaseFirst }}
                  </div>
                  <input
                    type="text"
                    class="price-field w-input"
                    maxlength="256"
                    name="name"
                    data-name="Name"
                    id="name"
                    autocomplete="off"
                    [(ngModel)]="maxPrice"
                    (keypress)="numberOnly($event)"
                    (keyup)="updatePriceByValue()"
                    [attr.disabled]="disabledMaxPrice ? true : null"
                  />
                </div>

                <div class="checkbox-price">
                  <a
                    class="checkbox-icon-price w-inline-block"
                    (click)="checkBox.classList.toggle('checked'); updatePriceByCheckbox(checkBox)"
                    #checkBox
                  ></a>
                  <div class="checkbox-text-price">
                    {{ 'SHARED.ONLY_FREE' | translate }}
                  </div>
                </div>
              </div>
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <prf-dropdown-component
    [isSorting]="true"
    [dropdownClasses]="COURSES_FORMAT_DATA_NEW.isActive && 'highlighted'"
    [selectedOption]="COURSES_FORMAT_DATA_NEW.activeOption"
    [dropdownItems]="COURSES_FORMAT_DATA_NEW.options"
    (updateValue)="handleSetFormat($event)"
  ></prf-dropdown-component>

  <a
    class="clear-categories events-clear"
    [class.show]="checkFilters()"
    (click)="clearAllFilters(); checkBox.classList.remove('checked')"
    >{{ 'SHARED.CLEAR_BUTTON' | translate }}</a
  >
</div>
