import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IResult } from '../results.interface';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { Observable, of } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import {
  ApiProfessionsService,
  ApiResultsService,
  ApiSchoolsService,
  ApiSearchService,
  IFilterClasses,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-forparentresults',
  templateUrl: './forparentresults.component.html',
  styleUrls: ['./forparentresults.component.scss'],
})
export class ForParentResultsComponent extends UnsubscribeComponent implements OnInit {
  public sessionId: string = '';
  public parentName: string = '';
  public childName: string = '';
  public childCode: string = '';
  public dataFetched: boolean = false;
  public schoolName: string = '';
  public schoolId: string = '';
  public regionId: string = '';
  public municipalityId: string = '';
  public directorName: string = '';

  public talents: IResult[] = [];
  public talentsData: any[] = [];
  private urlsTalentImg: any = {};

  public interests: IResult[] = [];
  public maxInterests: number = 3;

  public skillsAttainment: IResult[] = [];
  public maxSkills: number = 3;

  public recommendedCourses: any = [];
  private resultsCoursesCategories = ['Современные танцы', 'Литературоведение', 'Программирование'];
  public filters: IFilterClasses;
  public maxCourses: number = 3;
  public fromTo: any = { currentPage: 0, pageSize: this.maxCourses };

  public fieldsList: any[] = [];
  public maxFields: number = 3;

  public professionsAll: any[] = [];
  public professionsByResults: any[] = [];
  fieldsSeted = [
    {
      name: 'Агропромышленность',
      imageUrl: './profilum-assets/images/profession/agriculture@3x.png',
    },
    {
      name: 'Медицина и биотехнологии',
      imageUrl: './profilum-assets/images/profession/medicine@3x.png',
    },
    {
      name: 'Информационные технологии (IT)',
      imageUrl: './profilum-assets/images/profession/it@3x.png',
    },
  ];
  public maxProfessions: number = 3;

  public children: any[] = [];

  @ViewChild('pdfTable') pdfTable: ElementRef;

  constructor(
    public route: ActivatedRoute,
    private apiResultService: ApiResultsService,
    private apiProfessionService: ApiProfessionsService,
    private apiSearchService: ApiSearchService,
    private apiSchoolsService: ApiSchoolsService,
    private location: Location,
    private translate: TranslateService,
    private coursesFiltersService: CoursesFiltersService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.dataFetched = false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.parentName = params['parentName'];
      this.childName = params['childName'];
      this.childCode = params['code'];
      this.regionId = params['regionId'];
      this.municipalityId = params['municipalityId'];
      this.schoolName = params['schoolName'];
      this.schoolId = params['schoolId'];
      this.directorName = params['directorName'];
    });

    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(params => {
          this.sessionId = params['ssid'];
          return this.getResults(this.sessionId);
        }),
      )
      .subscribe(() => {
        this.dataFetched = true;
        //   this.htmltoPDF;
      });
  }

  getResults(sessionId): Observable<any> {
    if (sessionId) {
      const sessionIdsList = [];
      sessionIdsList.push(sessionId);
      return this.apiResultService.getResultsPage(sessionIdsList).pipe(
        switchMap(resultsData => {
          // запрашиваются только результаты ребенка
          this.talents = resultsData.results
            .filter(d => d.objectType === 'Talent')
            .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));
          this.talentsData = this.preTransformTalents(this.talents);

          // получаем урл картинок для талантов
          return this.apiSearchService.searchTalents().pipe(
            switchMap(data => {
              // преобразовываем массив с урлами талантов объект
              data.forEach(item => {
                this.urlsTalentImg[item.name] = item.icon;
              });
              this.interests = resultsData.results
                .filter(d => d.objectType === 'TalentAttainment')
                .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));

              this.skillsAttainment = resultsData.results
                .filter(d => d.objectType === 'SkillAttainment')
                .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));
              return observableForkJoin([this.getCourses(), this.getProfessions(resultsData.results)]);
            }),
          );
        }),
      );
    } else {
      return of(null);
    }
  }

  getCourses(): Observable<any> {
    this.recommendedCourses = [];
    const recommendedCoursesRequests$ = this.resultsCoursesCategories.map((category: any) => {
      return this.apiSchoolsService
        .getFilteredClasses({ courses: [category], from: this.fromTo.from, size: this.fromTo.size })
        .pipe(map(response => response.classes));
    });

    return observableForkJoin(recommendedCoursesRequests$).pipe(
      tap((recommendedCourses: any[]) => {
        recommendedCourses.forEach(result => {
          this.recommendedCourses = [...this.recommendedCourses, ...result];
        });
      }),
    );
  }

  getProfessions(testResults): Observable<any> {
    this.fieldsList = testResults
      .filter(d => d.objectType === 'Field')
      .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));
    const professions = testResults
      .filter(d => d.objectType === 'Profession' && d.results.length)
      .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));

    const professionsListByFields: Array<any> = [];

    for (let i = 0; i < 3; i++) {
      professionsListByFields.push({
        field: this.fieldsList[i],
        professions: professions.filter(p => this.FilterFields(p.data, this.fieldsList[i].name)),
      });
    }

    return this.apiProfessionService
      .getElasticProfessionsByFilters({ regionId: this.regionId, municipalityId: this.municipalityId, isVisible: true })
      .pipe(
        tap(professionsAll => {
          this.professionsAll = professionsAll;
          if (this.professionsAll) {
            this.professionsAll = this.professionsAll.map(p => {
              p.mainImagePath = p.mainImagePath ? './profilum-assets/images/profession/' + p.mainImagePath.substring(42) : null;
              return p;
            });
          }

          let professionsListByFieldsDetail: Array<any> = [];

          // фильтруем профессии, которые есть в списке всех professionsAll, заменяем данные профессии из professionAll
          professionsListByFields.forEach(plf => {
            const professions = plf.professions
              .filter(p => {
                const professionIndex = this.professionsAll.findIndex(pa => pa.id === p.id);
                return professionIndex >= 0 ? true : false;
              })
              .map(pFiltered => {
                const profession = this.professionsAll.filter(pa => pa.id === pFiltered.id);
                return profession && profession.length ? profession[0] : null;
              });

            professionsListByFieldsDetail.push({
              field: plf.field,
              professions: professions,
            });
          });

          //исключаем дубли по названию
          professionsListByFieldsDetail = professionsListByFieldsDetail.map(plfd => {
            return {
              field: plfd.field,
              professions: this.excludeDuplicateProfessions(plfd.professions),
            };
          });
          this.professionsByResults = professionsListByFieldsDetail;
        }),
      );
  }

  excludeDuplicateProfessions(array: any[]): any[] {
    if (array) {
      const arrayUniques = [];
      array.forEach(prof => {
        if (!arrayUniques.some(profUniq => profUniq.name === prof.name)) {
          arrayUniques.push(prof);
        }
      });
      return arrayUniques;
    } else {
      return [];
    }
  }

  private preTransformTalents(talents) {
    const shortDescriptionLen: number = 40;
    const talentsMaxShowed: number = 3;

    return talents
      .map(t => {
        return {
          name: t.name,
          description: t.description,
          shortDescription:
            t.description.length < shortDescriptionLen ? t.description : t.description.slice(0, shortDescriptionLen - 2) + '..',
          value: t.results.length ? t.results[0].transformedValue : 0,
          imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl || this.urlsTalentImg[t.name]),
          title: this.isEn() ? t.name : t.data.DativeCase,
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, talentsMaxShowed);
  }

  private FilterFields(dataFields, fieldName) {
    for (const i in dataFields.fieldNames) {
      if (dataFields.fieldNames[i] === fieldName) {
        return true;
      }
    }
    return false;
  }

  private isEn = () => this.translate.currentLang === 'en';
}
