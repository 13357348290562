import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrfSocial } from '../../../components/footer/footer.types';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-footer-socials',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer-socials.component.html',
  styleUrl: './footer-socials.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterSocialsComponent {
  @Input() socials: PrfSocial[];

  private yandexMetricsService = inject(YandexMetricsService);

  public onClickSocial(ymGoal: string) {
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
