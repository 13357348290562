import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IButtonOnbording, IMessageOnbording } from 'app/shared/interfaces/common/ondording.interface';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ConfettiComponent } from '@profilum-components/confetti/confetti.component';

@Component({
  selector: 'prf-show-messages',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ConfettiComponent],
  templateUrl: './show-messages.component.html',
  styleUrls: ['./show-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMessagesComponent extends UnsubscribeComponent implements OnChanges {
  @ViewChild('list') public list: ElementRef;
  @Input() public messages: IMessageOnbording[] = [];
  @Input() public confetti: boolean = false;
  @Input() public buttons: IButtonOnbording[] = [];
  @Input() public delay: number = 200;
  @Input() public endMessageNumber: number;
  @Input() public startMessageNumber: number = 0;
  @Output() private buttonAction: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.endMessageNumber) {
      this.scrollToBottom(this.endMessageNumber - this.startMessageNumber);
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  public showActions(type: string): void {
    this.buttonAction.emit(type);
  }

  public scrollToBottom(delay: number): void {
    const container: HTMLElement = document.getElementById('list');
    this.timer = setTimeout(() => {
      container.scroll({
        behavior: 'smooth',
        top: this.list.nativeElement.scrollHeight,
      });
    }, this.delay * delay);
  }
}
