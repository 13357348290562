import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, merge, Observable, Observer, of } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiNotificationsService,
  AppSettingsService,
  INotify,
  NotificationEvents,
  routeAnimations,
  StorageKeys,
  WebStorageService,
  YandexMetricsService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';

import { UtilsService } from './shared/dashboard/backend-services/utils.service';
import { ModalHandlerService } from './ui-kit/services/modal-handler/modal-handler.service';

declare let yaCounter32259284: any;
declare let gtag: any;

@Component({
  selector: 'prf-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent extends UnsubscribeComponent implements OnInit {
  private title = 'Profliner';
  private metrikaInitAttemp = 0;
  private maxMetrikaInitAttemp = 10;
  public isShowSideModal: boolean = false;
  public isShowCenterModal: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private utilsService: UtilsService,
    private breakpointsService: BreakpointsService,
    private webStorageService: WebStorageService,
    private apiNotificationsService: ApiNotificationsService,
    private yandexMetricsService: YandexMetricsService,
    private modalHandlerService: ModalHandlerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.yandexMetricsService.init({ enabled: environment.analytics, ymId: AppSettingsService.settings.yandexMetricCounterNumber });
    this.modalHandlerService.getShowSideModal().subscribe(isShowModal => {
      this.isShowSideModal = isShowModal;
      this.manageBodyScroll(isShowModal);
    });
    this.modalHandlerService.getShowCenterModal().subscribe(isShowModal => {
      this.isShowCenterModal = isShowModal;
      this.manageBodyScroll(isShowModal);
    });

    if (Worker) {
      const worker: Worker = new Worker(new URL('./ui-kit/web-workers/notifications-timer.worker', import.meta.url), { type: 'module' });

      worker.onmessage = ({ data }: { data: string }): void => {
        if (data) {
          this.apiNotificationsService.getNotifications().subscribe((notify: INotify[]) => {
            this.parseNotification(notify);
          });
        }
      };
    }

    this.setLocale().subscribe(() => this.initApp());

    this.checkOnline$()
      .pipe(
        mergeMap(isOnline => {
          if (!isOnline) {
            this.utilsService.openSnackBar('Интернет соединение разорвано', 'error', 4000);
            setTimeout(() => {
              this.unsubscribe.next(true);
            }, 5000);
            return this.checkOnline$().pipe(
              takeUntil(this.unsubscribe),
              tap(isOnline => {
                if (isOnline) {
                  this.utilsService.openSnackBar('Интернет соединение восстановлено', 'success', 3000);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();

    this.breakpointsService.getWindowSize.pipe(takeUntil(this.unsubscribe)).subscribe(windowSize => {
      document.documentElement.style.setProperty('--prf-app-height', `${windowSize.height}px`);
    });
  }

  private parseNotification(notify: INotify[]): void {
    notify.forEach((el: INotify) => {
      if (el.type === NotificationEvents.SPO_VO_ALLOW && !this.router.url.includes('onboarding-spo-vo')) {
        this.webStorageService.set(StorageKeys.IdOnboardingNotification, el.id);
        this.router.navigate(['./onboarding-spo-vo']);
        return;
      }

      if (el.type === NotificationEvents.HOLIDAYS_ALLOW && !this.router.url.includes('holidays-onboarding')) {
        this.webStorageService.set(StorageKeys.IdOnboardingNotification, el.id);
        this.router.navigate(['./holidays-onboarding']);
        return;
      }
    });
  }

  private setLocale(): Observable<any> {
    const url: URL = new URL(window.location.href);
    const firstLevelDomain: string = url.hostname?.split('.').pop() ?? 'ru';
    let locale: 'ru' | 'en' | 'kz';

    if (environment.eng) {
      locale = 'en';
    } else {
      locale = 'ru';
    }

    if (firstLevelDomain === 'kz') {
      locale = 'kz';
    }

    return this.translate.use(locale);
  }

  private initApp() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (environment.analytics) {
        this.yandexMetricsService.hit();
      }
    });
  }

  private checkOnline$() {
    // @ts-ignore
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }),
    );
  }

  private manageBodyScroll(fixate: boolean): void {
    fixate ? document.body.classList.add('fixed-body') : document.body.classList.remove('fixed-body');
  }
}
