import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private toggleMenu = new ReplaySubject<boolean>(1);

  public get toggleMenuSubject() {
    return this.toggleMenu.asObservable();
  }

  public closeMenu() {
    this.toggleMenu.next(false);
  }

  public openMenu() {
    this.toggleMenu.next(true);
  }
}
