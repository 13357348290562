import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService, LocationEnum } from '@profilum-library';

@Component({
  selector: 'prf-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  public yearNumber: number = null;
  public activeLocation: LocationEnum = LocationEnum.BASE;

  @Input()
  public isNoPaddingTop?: boolean = false;

  constructor(private appSettings: AppSettingsService) {}

  ngOnInit() {
    this.activeLocation = this.appSettings.currentLocation;
    this.yearNumber = new Date().getFullYear();
  }

  protected readonly LocationEnum = LocationEnum;
}
