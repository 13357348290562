<div class="pupil-welcome-page">
  <div class="pupil-welcome">
    <prf-show-messages
      [messages]="messages"
      [buttons]="buttons"
      [delay]="400"
      [endMessageNumber]="endMessageNumber"
      (buttonAction)="buttonsActions($event)"
    ></prf-show-messages>
  </div>
</div>
