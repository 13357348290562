import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YmReachGoalDirective } from 'app/shared/directives/ym-reach-goal/ym-reach-goal.directive';

@NgModule({
  declarations: [YmReachGoalDirective],
  imports: [CommonModule],
  exports: [YmReachGoalDirective],
})
export class YmReachGoalModule {}
