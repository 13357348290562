export enum AlertType {
  Success = 'success',
  Error = 'error',
  Pending = 'pending',
}

export enum BannerType {
  Success = 'success',
  Default = 'default',
  Pending = 'pending',
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  GhostProfilum = 'ghost-profilum',
  Ghost = 'ghost',
  LinkView = 'link-view',
  StrokedPrimary = 'stroked-primary',
  MosOblPrimary = 'mosobl-primary',
  MosOblStroked = 'mosobl-stroked',
  Eurochem = 'eurochem',
  LinkViewB2GRedesigned = 'link-view-b2g-redesigned',
  LinkViewB2GRedesignedGray = 'link-view-b2g-redesigned-gray',
  PrimaryB2GRedesigned = 'primary-b2g-redesigned',
  SecondaryB2GRedesigned = 'secondary-b2g-redesigned',
}

export enum ButtonHeightType {
  S = 'small-button-height',
  DEFAULT = 'default-button-height',
  XL = 'large-button-height',
  AUTO = 'auto-height',
}

export enum ButtonRadiusType {
  X6 = 'radius-x6',
  X4 = 'radius-x4',
}

export enum ModalType {
  Default = 'default',
  Notification = 'notification',
}

export enum CheckboxType {
  Primary = 'primary',
  Secondary = 'secondary',
  PrimaryB2G = 'primary-b2g',
}

export enum TabType {
  Primary = 'primary',
  Secondary = 'secondary',
}
