import { Directive, ElementRef, EventEmitter, HostListener, inject, Output } from '@angular/core';

@Directive({
  selector: '[prfClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  private _elementRef: ElementRef = inject(ElementRef);

  @Output()
  public prfClickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:touchstart', ['$event', '$event.target'])
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) return;

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.prfClickOutside.emit(event);
    }
  }
}
