import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISchoolClass } from '@profilum-library';

export interface IClass {
  className: string;
  id: string;
  letter: string;
  number: number;
  parentRegistrationLink: string;
  shortLink: string;
  schoolId: string;
  teacherId: string;
  createdDate?: string;
  pupilsCount?: number;
}

@Component({
  selector: 'prf-teacher-class-list',
  templateUrl: './teacher-class-list.component.html',
  styleUrls: ['./teacher-class-list.component.scss'],
})
export class TeacherClassListComponent {
  @Input() classList: ISchoolClass[];
  @Output() selectedClass = new EventEmitter<ISchoolClass>();

  public showElement(event: any): void {
    event.target.nextSibling.style.display = 'block';
  }

  public hideElement(event: any): void {
    event.target.nextSibling.style.display = 'none';
  }

  public selectClass(classItem: ISchoolClass): void {
    this.selectedClass.emit(classItem);
  }
}
