import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ApiAdminsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-schooladmin-access-banner',
  templateUrl: './schooladmin-access-banner.component.html',
  styleUrls: ['./schooladmin-access-banner.component.scss'],
})
export class SchooladminAccessBannerComponent extends UnsubscribeComponent {
  @Input() small: boolean = false;
  @Input() schoolClassId: string;
  public loading: boolean = false;

  constructor(private apiAdminsService: ApiAdminsService) {
    super();
  }

  getDataUnregistered() {
    this.loading = true;
    this.apiAdminsService
      .getDataUnregistered(this.schoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          const file = new File([blob], 'Доступы_для_учеников', {
            type: response.headers.get('content-type'),
          });
          saveAs(file);
          setTimeout(() => (this.loading = false), 500);
        },
        () => (this.loading = false),
      );
  }
}
