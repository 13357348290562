import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IAssigningCourseData,
  ICourseMaterialExt,
} from '../../../../shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { BadgeThemes, ClassCardTags, LABELS_ENUM } from './model';
import { NgClass, NgIf } from '@angular/common';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
  selector: 'prf-teacher-class-card',
  templateUrl: './teacher-class-card.component.html',
  styleUrls: ['./teacher-class-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, TooltipComponent, TippyDirective],
})
export class TeacherClassCardComponent implements OnInit {
  public badgeTheme: BadgeThemes = BadgeThemes.BLUE;
  public badgeName: string = '';
  public closerLessonDate?: string = '';
  public readonly LABELS: typeof LABELS_ENUM = LABELS_ENUM;

  @Input({ required: true }) public classData!: IAssigningCourseData;
  @Input() public isOneCard: boolean = false;

  @Output() private classClickEmitter: EventEmitter<any> = new EventEmitter<any>();

  public classClick(classData: IAssigningCourseData): void {
    this.classClickEmitter.emit(classData);
  }

  public ngOnInit(): void {
    this.setBadge();
    if (this.classData.course) {
      this.closerLessonDate = (this.classData.course as ICourseMaterialExt).nextLessonDate;
    }
  }

  public setBadge(): void {
    if (!this.classData.course) {
      this.badgeTheme = BadgeThemes.GREEN;
      this.badgeName = ClassCardTags.DIAGNOSTIC;
      return;
    }

    switch ((this.classData.course as ICourseMaterialExt).name) {
      case ClassCardTags.KG:
        this.badgeTheme = BadgeThemes.YELLOW;
        this.badgeName = ClassCardTags.KG;
        break;

      default:
        this.badgeTheme = BadgeThemes.BLUE;
        this.badgeName = (this.classData.course as ICourseMaterialExt).name;
    }
  }
}
