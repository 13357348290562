<div class="scheme-prof">
  <div *ngIf="isOpen === true" class="sheme-prof-div">
    <div class="scheme-info-block">
      <p class="scheme-info-block-text">
        Эта схема показывает типичные варианты развития внутри профессии. Нажав на тот или иной элемент траектории, ты можешь увидеть
        подробную информацию про каждый из них.
      </p>
    </div>
    <div *ngIf="schemeType === 'twoBranches'" class="scheme">
      <a
        (click)="currentStep = 'averageSchool'"
        [class.active]="currentStep === 'averageSchool'"
        class="scheme-link-3 active w-inline-block"
      >
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-center-block">
        <div class="scheme-center-div">
          <div class="scheme-line-column">
            <div class="scheme-line _2"></div>
            <div class="scheme-line _3"></div>
            <div class="scheme-line">
              <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'college'" [class.active]="currentStep === 'college'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'college'">
                <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-3.svg" />
              </div>
              <div>Колледж</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line r2"></div>
            <div class="scheme-line _3 r"></div>
            <div class="scheme-line _2 r">
              <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle _2" />
            </div>
            <div class="line-down">
              <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle _3" />
            </div>
          </div>
        </div>
        <div class="scheme-center-div">
          <div class="scheme-line-column">
            <div class="scheme-line _2 d"></div>
            <div class="scheme-line _3 d"></div>
            <div class="scheme-line">
              <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'highSchool'" [class.active]="currentStep === 'highSchool'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'highSchool'">
                <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-2.svg" class="high-school-icon" />
              </div>
              <div>Старшая школа</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line">
              <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'university'" [class.active]="currentStep === 'university'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'university'">
                <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-4.svg" />
              </div>
              <div>Вуз</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line r2"></div>
            <div class="scheme-line _3 r d"></div>
            <div class="scheme-line _2 r d"></div>
          </div>
        </div>
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div *ngIf="schemeType === 'withoutCollege'" class="scheme">
      <a (click)="currentStep = 'averageSchool'" [class.active]="currentStep === 'averageSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'highSchool'" [class.active]="currentStep === 'highSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'highSchool'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-2.svg" class="high-school-icon" />
          </div>
          <div>Старшая школа</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'university'" [class.active]="currentStep === 'university'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'university'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-4.svg" />
          </div>
          <div>Вуз</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div *ngIf="schemeType === 'withoutUniversity'" class="scheme">
      <a (click)="currentStep = 'averageSchool'" [class.active]="currentStep === 'averageSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'college'" [class.active]="currentStep === 'college'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'college'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-3.svg" />
          </div>
          <div>Колледж</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="./profilum-assets/images/icons/steps-trajectory/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="./profilum-assets/images/icons/steps-trajectory/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div [class.active]="currentStep === 'averageSchool'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Осознать свой интерес к этой профессии тебе помогут программы дополнительного образования. Ознакомься со своей подборкой курсов и
          начни дорогу к делу жизни:
        </p>
        <ul>
          <li *ngFor="let course of _profession.courses">
            <p>{{ course.name }}</p>
          </li>
        </ul>
      </div>
      <div class="scheme-info-block" hidden="hidden">
        <p>
          Осилить дорогу к этой профессии и поступить в профильное учебное заведение после 9–ого класса тебе помогут следующие школьные
          предметы:
        </p>
        <ul>
          <li><a href="#">Математика</a></li>
          <li><a href="#">Физика</a></li>
          <li><a href="#">Прототипирование и моделирование</a></li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'highSchool'" class="scheme-info active">
      <div class="scheme-info-block">
        <p>
          Дорога к любимой профессии начинается с интереса. Осваивай программы дополнительного образования. Ниже представлена подборка
          профильных курсов
        </p>
        <ul>
          <li *ngFor="let course of _profession.courses">
            <p>{{ course.name }}</p>
          </li>
        </ul>
      </div>
      <div class="scheme-info-block" hidden="hidden">
        <p>Впереди главные испытания этого маршрута: ЕГЭ и поступление в вуз. Чтобы пройти их достойно, налегай на следующие предметы:</p>
        <ul>
          <li><a href="#">Математика</a></li>
          <li><a href="#">Физика</a></li>
          <li><a href="#">Прототипирование и моделирование</a></li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'college'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Самый важный этап пути к делу жизни — обучение в заведении среднего или высшего профессионального образования. Получить
          представление о профильных специальностях тебе поможет наш список:
        </p>
        <ul>
          <li *ngFor="let special of specialsCollage">
            <p (click)="showPrograms(special)" [class.link-programs]="programsBySpecialityName[special.name]">{{ special.name }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'university'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Самый важный этап пути к делу жизни — обучение в заведении среднего или высшего профессионального образования. Получить
          представление о профильных специальностях тебе поможет наш список:
        </p>
        <ul>
          <li *ngFor="let special of specialsUniversity">
            <p (click)="showPrograms(special)" [class.link-programs]="programsBySpecialityName[special.name]"> {{ special.name }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'job'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Сегодня существует множество способов для трудоустройства. Ты можешь строить карьеру в компании или создать свой бизнес, причем
          иногда уже даже на этапе обучения в школе, колледже или вузе. Мы собрали для тебя перечень ведущих компаний в этой сфере, и тебе
          будет полезно поближе изучить их опыт.
        </p>
        <ul>
          <li *ngFor="let company of _profession.companies">
            <a href="{{ company.url }}" target="blank">{{ company.name }}</a>
          </li>
        </ul>
        <!-- <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-1.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-2.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-3.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-4.png" width="74" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-3.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-1.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-4.png" width="74" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-2.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-4.png" width="74" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-2.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-1.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
          <div class="scheme-column-logoes"><a href="#" class="job-logo-link w-inline-block"><img src="./assets/images/job-logo-3.png" width="100" class="job-logo"><div class="info-popup company-name" style="opacity: 0; display: none;"><p>Название компании</p><img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle"></div></a></div>
        </div> -->
      </div>
    </div>
  </div>

  <a *ngIf="hideButton && isOpen === true" (click)="isOpen = false" class="sheme-prof-button-hide"> Скрыть траекторию </a>
  <a *ngIf="hideButton && isOpen === false" (click)="isOpen = true" class="sheme-prof-button-show"> Показать траекторию </a>
</div>
<ng-template #programsSpoVpoTemplate>
  <div class="programs-spo-vpo scrollable-container">
    <div class="programs-spo-vpo__container">
      <div class="programs-spo-vpo__title">
        <div class="programs-spo-vpo__title-name">{{selectedSpecial.fields[1].name}}</div>
      </div>
      <div class="programs-spo-vpo__text" [innerHTML]="selectedSpecial.fields[1].description">
      </div>
      <div class="programs-spo-vpo__title-name">Учебные заведения, в которых открыт набор на 2024 год</div>
      <div class="programs-spo-vpo__links" *ngFor="let program of selectedPrograms">
        <img src="./profilum-assets/images/common/link-program.svg"/>
        <a class="programs-spo-vpo__links-text" [href]="program.educationalInstitutionLink"
           target="_blank">{{program.educationalInstitutionName}}</a>
      </div>
    </div>
  </div>
</ng-template>
