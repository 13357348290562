<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share">
      <a (click)="closePopup()" class="close-lb w-inline-block">
        <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия модального окна" />
      </a>
      <div class="popup-share-header">
        <h3 class="popup-share-title">Вставьте ссылку от {{ dictionary.TeacherGenitive }}</h3>
      </div>
      <div class="popup-share-content">
        <p class="popup-share-text">
          Для добавления подростка в класс скопируйте и вставьте ссылку, которую прислал его {{ dictionary.Teacher }} в общий чат
        </p>
      </div>

      <form class="popup-form" [formGroup]="singleForm">
        <div class="prf-form-control">
          <label class="prf-form-label" for="link" *ngIf="form.link.value"> Ссылка от {{ dictionary.TeacherGenitive }} </label>
          <input
            class="prf-form-input popup-input"
            type="text"
            [class.error-field]="form.link && form.link.touched && form.link.errors"
            name="link"
            data-name="link"
            id="link"
            formControlName="link"
            [placeholder]="'Ссылка от ' + dictionary.TeacherGenitive"
          />
        </div>
        <ng-container *ngIf="singleForm.controls['link'].touched && singleForm.controls['link'].errors">
          <mat-error *ngIf="singleForm.controls['link'].errors.unCorrect"> Данные не найдены. Попробуйте другую </mat-error>
        </ng-container>
      </form>

      <div class="popup-share-buttons">
        <button (click)="sendLink()" class="button" [class.disabled]="!singleForm.controls['link'].value || loading">
          <ng-container *ngIf="!loading; else loader">Продолжить</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Loader -->
<ng-template #loader>
  <div class="loader-wrapper button-content">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>
