<div class="user-data-form-block w-form">
  <form id="wf-form-User-Data" name="wf-form-User-Data" data-name="User Data" [formGroup]="form" autocomplete="off">
    <div class="profile-data-row">
      <div>
        <label for="lastName" class="user-data-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          maxlength="70"
          [class.error-field]="formControls.lastName.errors"
          name="lastName"
          data-name="lastName"
          id="lastName"
          formControlName="lastName"
          autocomplete="off"
        />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="firstName" class="user-data-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          maxlength="70"
          [class.error-field]="formControls.firstName.errors"
          name="firstName"
          data-name="firstName"
          id="firstName"
          formControlName="firstName"
          autocomplete="off"
        />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="middleName" class="user-data-label">{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          class="user-data-field w-input"
          maxlength="70"
          [class.error-field]="formControls.middleName.errors"
          name="middleName"
          data-name="middleName"
          id="middleName"
          formControlName="middleName"
        />
      </div>
    </div>
    <div class="profile-data-row">
      <div>
        <label for="phoneNumber" class="user-data-label">{{ 'SHARED.FORM.PHONE' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          class="user-data-field w-input"
          [class.error-field]="formControls.phoneNumber.errors"
          name="phoneNumber"
          data-name="phoneNumber"
          id="phoneNumber"
          formControlName="phoneNumber"
          (focusout)="checkPhone()"
        />
        <mat-error *ngIf="formControls.phoneNumber.errors?.required"> Обязательное поле </mat-error>
        <mat-error *ngIf="formControls.phoneNumber.errors?.pattern"> Номер телефона введен неверно </mat-error>
        <mat-error *ngIf="formControls.phoneNumber.errors?.alreadyRegistered">Введите другой номер телефона</mat-error>
      </div>
    </div>
    <div class="div-space-2"></div>
    <div class="profile-data-row">
      <div>
        <label for="Email" class="user-data-label">Email:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="formControls.email.errors"
          name="Email"
          data-name="Email"
          formControlName="email"
          id="Email"
          autocomplete="off"
          (focusout)="checkFormatEmail()"
        />
        <ng-container>
          <mat-error *ngIf="formControls.email.errors">
            {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="birthday" class="user-data-label">Дата рождения:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="formControls.birthday.errors"
          [textMask]="{ mask: dateMask }"
          name="date"
          data-name="birthday"
          id="birthday"
          formControlName="birthday"
          autocomplete="off"
          (focusout)="checkFormatBirthDate()"
        />
        <ng-container>
          <mat-error *ngIf="formControls.birthday.errors">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.CHECK_DATA' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="profile-data-row gender-choice">
      <div class="gender-choice-row">
        <label class="login-label">Пол:</label>
        <div class="div-space"></div>
      </div>
      <prf-gender-toggle formControlName="gender"></prf-gender-toggle>
    </div>

    <div class="div-space-2"></div>
    <div class="profile-data-row">
      <div class="div-space-3"></div>
      <div class="edit-profile-data-buttons">
        <a (click)="submitChanges()" class="button save-profile-changes-button w-button">Сохранить</a>
        <a class="white-button w-button" (click)="cancelEdit()">Отменить</a>
      </div>
    </div>
  </form>
</div>
