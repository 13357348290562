import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'director-report-comparison',
  templateUrl: './director-report-comparison.component.html',
  styleUrls: ['./director-report-comparison.component.scss'],
})
export class DirectorReportComparisonComponent implements OnInit {
  ngOnInit() {}
}
