import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { COURSES_TYPES } from '../../add-course/add-course.component';
import { MetroColors } from '../../add-course/select-metro/metro-colors';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import {
  ApiAdminsService,
  ApiSchoolsService,
  ApiUsersService,
  CalculationService,
  IClass,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-event-course-card',
  templateUrl: './event-course-card.component.html',
  styleUrls: ['./event-course-card.component.scss'],
})
export class EventCourseCardComponent implements OnInit, OnDestroy {
  @Input() course: IClass;
  @Input() viewValue: any;
  @Input() image: any;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;
  types = COURSES_TYPES;
  currentType: any;
  isFavorite: boolean = false;
  isCourse: boolean;
  subscription: any;
  favoritesCourses: any;
  userId: string;
  userRole: string;
  accessAllow: boolean = false;
  metroColors: MetroColors = new MetroColors();
  popUpConfirming: boolean = false;
  startDate: string;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private calculationService: CalculationService,
    private apiSchoolsService: ApiSchoolsService,
    private apiAdminsService: ApiAdminsService,
    private webStorageService: WebStorageService,
    private apiUsersService: ApiUsersService,
    private utilsService: UtilsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isCourse = this.course.classesFormat === 'ShortCourse' || this.course.classesFormat === 'LongCourse';
    this.currentType = this.getTypeCourse(this.course.classesFormat);
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.accessAllow = this.userRole === 'admin';
    // this.courseDetailsService
    //   .getFavoriteCourses()
    //   .pipe(takeUntil(this.ngUnsubscribe$))
    //   .subscribe(data => {
    //     this.favoritesCourses = data;
    //     if (this.favoritesCourses) {
    //       let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
    //       this.isFavorite = productIds.length > 0;
    //     }
    //   });
    this.checkAllow();
    const startTime = DateHelper.toDayJs(this.course.startDate).format('HH:mm');
    if (startTime == '00:00') {
      this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY');
    } else {
      this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY в HH:mm');
    }
  }

  checkAllow() {
    if (this.router.url == '/adminDO/mine-courses') {
      this.accessAllow = true;
    }
  }

  calculateRealPrice() {
    return this.calculationService.calculateRealPrice(this.course);
  }

  private getTypeCourse(value: string) {
    const type = this.types.filter(el => el.value === value);
    return type[0];
  }

  checkAction(event: any) {
    this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
  }

  deleteCourse(courseID) {
    const observables: Observable<any>[] = [];
    //mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.apiAdminsService.deleteClassDO(courseID));
    } else if (this.userRole == 'director') {
      observables.push(this.apiUsersService.deleteClassDODirectors(courseID));
    } else if (this.userRole == 'adminDO') {
      observables.push(this.apiAdminsService.removeAdminDOClass(courseID));
    } else {
      //admin
      observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    }
    //elastic
    observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        this.ngOnInit();
      });
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
