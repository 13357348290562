import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum BannerType {
  LINK,
}

@Component({
  selector: 'prf-basic-banner',
  templateUrl: './basic-banner.component.html',
  styleUrls: ['./basic-banner.component.scss'],
})
export class BasicBannerComponent {
  public linkUrl: string;
  public bannerType: BannerType;
  public bannerTypes = BannerType;

  @Input() set link(url: string) {
    if (url) {
      this.linkUrl = url;
      this.bannerType = BannerType.LINK;
    }
  }
  @Output() buttonClicked = new EventEmitter<void>();
}
