import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from '../../components/section/section.component';
import { H2Component } from '../../components/h2/h2.component';
import { DescriptionComponent } from '../../components/description/description.component';
import {
  PrfInformationForDifferentUsers,
} from '../../components/information-for-different-users/information-for-different-users.types';
import {
  InformationForDifferentUsersCardComponent,
} from '../../components/information-for-different-users/information-for-different-users-card/information-for-different-users-card.component';

@Component({
  selector: 'prf-information-for-different-users',
  standalone: true,
  imports: [CommonModule, SectionComponent, H2Component, DescriptionComponent, InformationForDifferentUsersCardComponent],
  templateUrl: './information-for-different-users.component.html',
  styleUrl: './information-for-different-users.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationForDifferentUsersComponent {
  @Input() informationForDifferentUsers: PrfInformationForDifferentUsers;
}
