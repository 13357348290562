<div class="professions-menu">
  <div class="professions-menu-row-2">
    <a
      *ngFor="let menuItem of recommendMenu"
      [class.w--current]="menuItem.selected && menuItem.active"
      [class.no-active]="!menuItem.active"
      (mouseover)="menuItem.showPopup = true"
      (mouseleave)="menuItem.showPopup = false"
      (click)="recommendMenuSelect(menuItem)"
      class="professions-menu-link"
    >
      <div>{{ menuItem.name }}</div>

      <div *ngIf="!menuItem.active && menuItem.showPopup" class="info-popup recommendation w-hidden-small w-hidden-tiny">
        <p>{{ 'SHARED.NOT_RECOMMENDATION_YET' | translate }}</p>
        <img src="./profilum-assets/images/icons/info-triangle.png" width="12" alt="" class="info-triangle" />
      </div>
    </a>
  </div>
</div>

<ng-container *ngIf="currentRecommendMenuItem.name === ('SHARED.RESULT.BY_TEST' | translate)">
  <ng-container *ngIf="userInfo.role === userRoles.pupil || userInfo.role === userRoles.parent">
    <prf-parent-test-panel
      *ngIf="sessionStatus !== 'Success' && userInfo.role !== 'teacher' && userInfo.role !== 'schooladmin' && userInfo.role !== 'parent'"
      [pupilUserId]="userInfo.userId"
      [isPupil]="true"
    >
    </prf-parent-test-panel>
  </ng-container>

  <ng-container *ngFor="let rpl of professionsByResults | slice : 0 : 3">
    <h2 class="professions-h2 rec">{{ rpl?.field?.name }}</h2>

    <p class="recommendations-text" [innerHTML]="rpl?.field?.description"></p>

    <div class="education-requirements-row">
      <div class="education-requirements-text">Необходимое образование:</div>

      <a
        *ngIf="rpl?.professions?.secondaryEducation.length"
        [class.active]="rpl?.tab === 'secondaryEducation'"
        (click)="rpl.tab = 'secondaryEducation'"
        class="education-requirements-link"
      >
        {{ 'SHARED.EDU.VOCATIONAL_SHORT' | translate }}
      </a>

      <a
        *ngIf="rpl?.professions.higherEducation.length"
        [class.active]="rpl?.tab === 'higherEducation'"
        (click)="rpl.tab = 'higherEducation'"
        class="education-requirements-link"
      >
        {{ 'SHARED.EDU.HIGHER_SHORT' | translate }}
      </a>
    </div>

    <div class="professions-row recom">
      <ng-container *ngIf="rpl?.professions?.higherEducation.length && rpl?.tab === 'higherEducation'">
        <div class="professions-column-2" *ngFor="let profession of rpl?.professions?.higherEducation | slice : 0 : 3">
          <div class="prof-municipality" style="position: absolute" *ngIf="checkProfession(profession)">
            <img
              src="./profilum-assets/images/icons/fire-profession.svg"
              class="prof-municipality-image"
              (mouseleave)="popup.style.display = 'none'"
              (mouseover)="popup.style.display = 'block'"
            />
            <div class="info-popup recommendation w-hidden-small w-hidden-tiny" #popup style="display: none">
              <p>
                {{ 'SHARED.PROFESSION_NARRATIVE' | translate }}
              </p>
              <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
          <a (click)="navigateProfessions(profession)" class="professions-card w-inline-block">
            <div class="professions-image"><img src="{{ profession?.mainImagePath }}" alt="" /></div>
            <div class="prof-column">
              <div class="professions-card-vertical-column">
                <h3 class="professions-h3">{{ profession?.name }}</h3>
                <p class="professions-description">{{ removeTags(profession?.shortDescription) }}</p>
              </div>
              <div class="professions-wage" *ngIf="showSalary">
                {{ profession?.minSalary | number }}–{{ profession?.maxSalary | number }}
                <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
              </div>
              <ng-container *ngIf="userInfo.role === 'pupil'">
                <div *ngIf="profession?.isGoal" (click)="removeFavorite(profession)" class="add-goal-button delete-goal">
                  <strong>Убрать</strong>
                </div>
                <div *ngIf="!profession?.isGoal" (click)="addToFavorite(profession)" class="add-goal-button">
                  <strong>{{ 'SHARED.ADD' | translate }}</strong>
                </div>
              </ng-container>
            </div>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="rpl?.professions?.secondaryEducation.length && rpl?.tab === 'secondaryEducation'">
        <div class="professions-column-2" *ngFor="let profession of rpl?.professions?.secondaryEducation | slice : 0 : 3">
          <a (click)="navigateProfessions(profession)" class="professions-card w-inline-block">
            <div class="professions-image"><img src="{{ profession?.mainImagePath }}" alt="" /></div>
            <div class="prof-column">
              <div class="professions-card-vertical-column">
                <h3 class="professions-h3">{{ profession?.name }}</h3>
                <p class="professions-description">{{ removeTags(profession?.shortDescription) }}</p>
              </div>
              <div class="professions-wage" *ngIf="showSalary">
                {{ profession?.minSalary | number }}–{{ profession?.maxSalary | number }}
                <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
              </div>
              <ng-container *ngIf="userInfo.role === 'pupil'">
                <div *ngIf="profession?.isGoal" (click)="removeFavorite(profession)" class="add-goal-button delete-goal">
                  <strong>Убрать</strong>
                </div>
                <div *ngIf="!profession?.isGoal" (click)="addToFavorite(profession)" class="add-goal-button">
                  <strong>{{ 'SHARED.ADD' | translate }}</strong>
                </div>
              </ng-container>
            </div>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="currentRecommendMenuItem.name === 'От родителей'">
  <div class="professions-row recom">
    <div class="professions-column-2" *ngFor="let profession of professionsByParent">
      <a (click)="navigateProfessions(profession)" class="professions-card w-inline-block">
        <div class="professions-image"><img src="{{ profession.mainImagePath }}" alt="" /></div>
        <div class="prof-column">
          <div class="professions-card-vertical-column">
            <h3 class="professions-h3">{{ profession.name }}</h3>
            <p class="professions-description">{{ removeTags(profession.shortDescription) }}</p>
          </div>
          <div class="professions-wage" *ngIf="showSalary">
            {{ profession.minSalary | number }}–{{ profession.maxSalary | number }}
            <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
          </div>
          <ng-container *ngIf="userInfo.role === 'pupil'">
            <div *ngIf="profession.isGoal" (click)="removeFavorite(profession)" class="add-goal-button delete-goal">
              <strong>Убрать</strong>
            </div>
            <div *ngIf="!profession.isGoal" (click)="addToFavorite(profession)" class="add-goal-button">
              <strong>{{ 'SHARED.ADD' | translate }}</strong>
            </div>
          </ng-container>
        </div>
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentRecommendMenuItem.name === 'От учителя'">
  <div class="professions-row recom">
    <div class="professions-column-2" *ngFor="let profession of professionsByTeacher">
      <a (click)="navigateProfessions(profession)" class="professions-card w-inline-block">
        <div class="professions-image"><img src="{{ profession.mainImagePath }}" alt="" /></div>
        <div class="prof-column">
          <div class="professions-card-vertical-column">
            <h3 class="professions-h3">{{ profession.name }}</h3>
            <p class="professions-description">{{ removeTags(profession.shortDescription) }}</p>
          </div>
          <div class="professions-wage" *ngIf="showSalary">
            {{ profession.minSalary | number }}–{{ profession.maxSalary | number }}
            <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
          </div>
          <ng-container *ngIf="userInfo.role === 'pupil'">
            <div *ngIf="profession.isGoal" (click)="removeFavorite(profession)" class="add-goal-button delete-goal">
              <strong>Убрать</strong>
            </div>
            <div *ngIf="!profession.isGoal" (click)="addToFavorite(profession)" class="add-goal-button">
              <strong>{{ 'SHARED.ADD' | translate }}</strong>
            </div>
          </ng-container>
        </div>
      </a>
    </div>
  </div>
</ng-container>
