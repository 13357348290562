import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-test-switch',
  templateUrl: './test-switch.component.html',
  styleUrls: ['./test-switch.component.scss'],
})
export class TestSwitchComponent extends UnsubscribeComponent {
  @Input() user: any;
  @Input() checkPupil: boolean = false;
  @Input() checkParent: boolean = false;
  @Input() isCompleteTestPupil: boolean = false;
  @Input() isCompleteTestParent: boolean = false;

  @Output() clickPupil = new EventEmitter<boolean>();
  @Output() clickParent = new EventEmitter<boolean>();

  public userRole: string = '';
  translations: string[];

  constructor(private translateService: TranslateService, private webStorageService: WebStorageService) {
    super();
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    this.getTranslations([
      'SHARED.TEST_NOT_PASSED',
      'SHARED.PARENT',
      'SHARED.NOT_TESTING',
      'SHARED.TEST_PASSED',
      'SHARED.TEST_NOT_PASSED_PARENT',
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.translations = translations;
      });
  }

  public onClickPupil() {
    if (this.isCompleteTestPupil) {
      this.checkPupil = true;
      this.checkParent = false;

      this.clickPupil.emit(this.checkPupil);
      this.clickParent.emit(this.checkParent);
    }
  }

  public onClickParent() {
    if (this.isCompleteTestParent) {
      this.checkParent = true;
      this.checkPupil = false;

      this.clickParent.emit(this.checkParent);
      this.clickPupil.emit(this.checkPupil);
    }
  }

  public onClickAll() {
    if (this.isCompleteTestPupil && this.isCompleteTestParent) {
      this.checkPupil = true;
      this.checkParent = true;

      this.clickPupil.emit(this.checkPupil);
      this.clickParent.emit(this.checkParent);
    }
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  public getCompletedStatusDescription() {
    return !this.isCompleteTestParent && !this.isCompleteTestPupil
      ? this.translations['SHARED.TEST_NOT_PASSED'].toLowerCase()
      : !this.isCompleteTestParent && this.isCompleteTestPupil
      ? this.translations['SHARED.TEST_NOT_PASSED_PARENT'].toLowerCase()
      : this.isCompleteTestParent && !this.isCompleteTestPupil
      ? this.user.firstName + ' ' + (this.user.gender == 'F' ? 'не прошла тест' : 'не прошел тест')
      : this.translations['SHARED.TEST_PASSED'];
  }
}
