import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ExtracurricularActivityCardComponent,
} from '../../../components/extracurricular-activities/extracurricular-activity-cards/extracurricular-activity-card/extracurricular-activity-card.component';
import { DescriptionComponent } from '../../../components/description/description.component';
import {
  PrfExtraCurricularActivityCard,
} from '../../../components/extracurricular-activities/extracurricular-activity-cards.types';
import { H3Component } from '../../../components/h3/h3.component';
import { ImageComponent } from '../../../components/image/image.component';

@Component({
  selector: 'prf-extracurricular-activity-cards',
  standalone: true,
  imports: [CommonModule, ExtracurricularActivityCardComponent, DescriptionComponent, H3Component, NgOptimizedImage, ImageComponent],
  templateUrl: './extracurricular-activity-cards.component.html',
  styleUrl: './extracurricular-activity-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtracurricularActivityCardsComponent {
  @Input() cards: PrfExtraCurricularActivityCard[];
}
