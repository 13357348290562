<ng-container *ngIf="dataFetched">
  <div>
    <!-- SEARCH -->
    <prf-courses-search></prf-courses-search>

    <!-- FILTER -->
    <prf-courses-filter></prf-courses-filter>
    <ng-container *ngIf="!isLoading && requestError; else defaultPageTemplate">
      <ng-container *ngTemplateOutlet="requestErrorTemplate"></ng-container>
    </ng-container>

    <ng-template #defaultPageTemplate>
      <ng-container *ngIf="(!filteredCourses || (filteredCourses && filteredCourses.length == 0)) && isLoading; else elasticCoursesPage">
        <!-- плейсхолдер при подгрузке курсов -->
        <div class="loader-container">
          <prf-admin-content-loader></prf-admin-content-loader>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>

<ng-template #elasticCoursesPage>
  <div class="catalog-events-row scrollable-container" *ngIf="filteredCourses && filteredCourses.length > 0; else emptyCourses">
    <prf-course-card *ngFor="let course of filteredCourses" [course]="course" [favouritesAccess]="checkFavouriteAccess()"></prf-course-card>
  </div>
</ng-template>

<ng-template #emptyCourses>
  <div class="event-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" *ngIf="isCourses" />
      <img src="./profilum-assets/images/menu/grey/Events.svg" width="80" class="no-profession-icon" *ngIf="!isCourses" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_TITLE_NAME' | translate : { titleName: titleName } }}
    </div>
  </div>
</ng-template>

<ng-template #requestErrorTemplate>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">{{ textError }}</div>
  </div>
</ng-template>

<ng-template #incompletedTest>
  <div class="results-empty-block">
    <div>
      <img src="./profilum-assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Ваш ребенок пока не прошел тест</div>
  </div>
</ng-template>
