import { Component, Input } from '@angular/core';
import { IDiagnosticResults } from '../diagnostic-results.interface';

@Component({
  selector: 'prf-diagnostic-results-accordion',
  templateUrl: './diagnostic-results-accordion.component.html',
  styleUrls: ['./diagnostic-results-accordion.component.scss'],
})
export class DiagnosticResultsAccordionComponent {
  @Input() public result: IDiagnosticResults;

  public isOpened: boolean = false;
  private readonly color_turquoise = '#02C5BC';

  public toggle(): void {
    if (this.result?.data[0]?.percents) {
      this.isOpened = !this.isOpened;
    }
  }
}
