import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppSettingsService, LocationEnum, routeAnimations } from '@profilum-library';

@Component({
  selector: 'prf-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent {
  public activeLocation: LocationEnum = LocationEnum.BASE;

  constructor(
    private meta: Meta,
    private appSettings: AppSettingsService,
  ) {
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });
    this.activeLocation = this.appSettings.currentLocation;
  }

  protected readonly LocationEnum = LocationEnum;
}
