import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-pupil-mobile-edit-profile-menu',
  templateUrl: './pupil-mobile-edit-profile-menu.component.html',
  styleUrls: ['./pupil-mobile-edit-profile-menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PupilMobileEditProfileMenuComponent {
  @Output() saveProfileChanges = new EventEmitter<boolean>();
  @Output() cancelProfileChanges = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  public cancel(event: MouseEvent): void {
    this.cancelProfileChanges.emit(true);
  }

  public save(event: MouseEvent): void {
    this.saveProfileChanges.emit(true);
  }
}
