import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FiltersPromotestCoursesService } from '../filters-promotest-courses.service';
import { ApiSearchService, IFilterClasses } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-filters-talents',
  templateUrl: './filters-talents.component.html',
  styleUrls: ['./filters-talents.component.scss'],
})
export class FiltersTalentsComponent extends UnsubscribeComponent implements OnInit {
  filters: IFilterClasses = {};

  talents: any[] = [];
  selectedTalents: any[] = [];
  filterOpen: boolean = false;

  @ViewChild('talentsFilter') talentsFilter: ElementRef;

  constructor(private apiSearchService: ApiSearchService, private filtersService: FiltersPromotestCoursesService) {
    super();
  }

  ngOnInit() {
    this.apiSearchService
      .searchTalents()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((talents: any[]) => {
          this.talents = talents.map(talent => talent.name);
          return this.filtersService.getCoursesFilters().pipe(
            tap(filters => {
              this.filters = filters;
              this.selectedTalents = filters.talents ? filters.talents : [];
            }),
          );
        }),
      )
      .subscribe();
  }

  setTalentsFilter(talents: string[]) {
    // выбор ограничен одним талантом
    this.filters.talents = talents;
    // if (!this.filters.talents) {
    //   this.filters.talents = talents;
    // } else {
    //   talents.forEach(talent => {
    //     let index = this.selectedTalents.indexOf(talent);
    //     index !== -1 ? this.filters.talents.splice(index, 1) : this.filters.talents.push(talent);
    //   });
    // }
    this.filterOpen = !this.filterOpen;
    this.filtersService.setCoursesFilter(this.filters);
  }

  deselectTalents() {
    this.filters.talents = [];
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader(): string {
    if (this.filters.talents && this.filters.talents.length) {
      const talents = this.talents.filter(el => el === this.selectedTalents[0]);

      return talents[0];
    } else {
      return 'Талант';
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.talentsFilter && !this.talentsFilter?.nativeElement.contains(target)) {
      this.filterOpen = false;
    }
  }
}
