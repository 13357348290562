import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApiResultsService, ErrorHandlerService } from '@profilum-library';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResult } from './results.interface';
import { CapabilitiesMap, InterestsMap } from './results.constants';

@Injectable()
export class ResultsService {
  PROGRAMS_AMOUNT: number;
  TALENTS_TO_CHOOSE: number;
  programmsImages = {
    'Финансы и менеджмент': './assets/images/talants/fin_i_man.png',
    'Спорт и военное дело': './assets/images/talants/sport_i_voen.png',
    'Естественные науки': './assets/images/talants/est_nauk.png',
    'Информационные технологии': './assets/images/talants/inf_tec.png',
    'Языки и лингвистика': './assets/images/talants/yaziki_i_lingv.png',
    'Гуманитарные науки и общество': './assets/images/talants/gum_i_ob.png',
    'Культурология и туризм': './assets/images/talants/kult_i_tour.png',
    'Техника и технологии': './assets/images/talants/tech_i_technol.png',
    'Творчество и искусство': './assets/images/talants/tvorch.png',
    'Физико-математические науки': './assets/images/talants/fiz_mat.png',
    'Общее развитие': './assets/images/talants/obsh_razv.png',
  };
  private isCompleteTestPupil$: BehaviorSubject<boolean>;

  constructor(private apiResultsService: ApiResultsService, private errorHandler: ErrorHandlerService) {
    this.isCompleteTestPupil$ = new BehaviorSubject<boolean>(false);
  }

  set isCompleteTestPupil(isCompleteTestPupil: boolean) {
    this.isCompleteTestPupil$.next(isCompleteTestPupil);
  }

  get pupilSessionComplete(): Observable<boolean> {
    return this.isCompleteTestPupil$.asObservable();
  }

  resolve(route: ActivatedRouteSnapshot) {
    this.TALENTS_TO_CHOOSE = 3;
    this.PROGRAMS_AMOUNT = 6;
    const session = route.params.ssid;
    return this.apiResultsService.getResultsPage(session);
  }

  public transformInterests(interests: IResult[], isKruzhki: boolean = false) {
    return interests.map(i => {
      return {
        title: i.name,
        description: isKruzhki ? this.getKrujkiInterestDescription(i.name) : i.description,
        parentEstimate: i.results.length ? Math.round(i.results[0].transformedValue) : 0,
        childEstimate: i.results.length === 2 ? Math.round(i.results[1].transformedValue) : 0,
      };
    });
  }

  public getKrujkiInterestDescription(name: string): string {
    return InterestsMap[name] ?? '';
  }

  public transformSkills(skills: IResult[], sessionId: string, isKruzhki: boolean = false) {
    const part1 = skills.map(s => {
      const data = s.results.filter(r => r.sessionId === sessionId)[0];
      return {
        axis: s.name,
        value: data ? Math.round(data.transformedValue) / 100 : 0,
        description: isKruzhki ? this.getKrujkiCapabilitiesDescription(s.name) : s.description,
      };
    });
    let part2 = [];
    if (skills[0] && skills[0].results.length === 2) {
      part2 = skills.map(s => {
        const data = s.results.filter(r => r.sessionId !== sessionId)[0];
        return {
          axis: s.name,
          value: Math.round(data.transformedValue) / 100,
          description: s.description,
        };
      });
    }
    return [part1, part2];
  }

  public getKrujkiCapabilitiesDescription(name: string): string {
    return CapabilitiesMap[name] ?? '';
  }
}
