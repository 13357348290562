import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IUserInterface } from '../../../interfaces/iuser.interface';
import { take } from 'rxjs/operators';
import { ApiUtilsService, B2gSaasService, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-photo-change',
  templateUrl: './photo-change.component.html',
  styleUrls: ['./photo-change.component.scss'],
})
export class PhotoChangeComponent implements OnInit {
  @Input() _userInfo: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  _selectedFiles: File;
  _userId: any;
  _user: IUserInterface = {
    imagePath: null,
  };

  constructor(
    private apiUtilsService: ApiUtilsService,
    private b2gSaasService: B2gSaasService,
    private webStorageService: WebStorageService,
  ) {}

  ngOnInit() {
    this._user.imagePath = this._userInfo.imagePath;
    this._userId = this.webStorageService.get(StorageKeys.UserId);
  }

  public uploadAvatar(): void {
    const event = new MouseEvent('click', { bubbles: false });
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  selectFile(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this._selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  updateUrl() {
    this._user.imagePath = null;
  }

  // Update user info
  onSubmit(valid: boolean) {
    if (valid) {
      this.apiUtilsService
        .uploadImage(this._selectedFiles, this._userId)
        .pipe(take(1))
        .subscribe(image => {
          this._user.imagePath = image;
          this.b2gSaasService.updateUserInfo(this._user);
        });
    }
  }
}
