<section class="prf-profession-catalog-card" [ngClass]="card.theme">
  <prf-h3 class="prf-profession-catalog-card-title">{{ card.title }}</prf-h3>
  <ul class="prf-profession-catalog-card-list">
    @for (listItem of card.list; track listItem) {
      <li class="prf-profession-catalog-card-list-item">{{ listItem }}</li>
    }
  </ul>
  <div class="prf-profession-catalog-card-image-container">
    <img
      class="prf-profession-catalog-card-image"
      [ngSrc]="card.image.src"
      [alt]="card.image.alt"
      [width]="card.image.width"
      [height]="card.image.height"
    />
  </div>
</section>
