<div class="data-container">
  <div class="data-list-item">
    <div class="item-value">20%</div>
    <div class="item-pictogram">
      <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 6L7 1L1 6" stroke="#FF5722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <rect x="8" y="17" width="2" height="15" rx="1" transform="rotate(-180 8 17)" fill="#FF5722" />
      </svg>
    </div>
  </div>
</div>
