import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipTextComponent } from 'app/shared/common-components/tooltip-text/tooltip-text.component';

/*TODO DEPRECATED*/
@NgModule({
  declarations: [TooltipTextComponent],
  imports: [CommonModule],
  exports: [TooltipTextComponent],
})
export class TooltipTextModule {}
