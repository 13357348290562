import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-student-test-results',
  templateUrl: './student-test-results.component.html',
  styleUrls: ['./student-test-results.component.scss'],
})
export class StudentTestResultsComponent implements OnInit {
  @Input() pupil: any;
  sessionId: string = '';
  showTestResults: boolean = false;
  pupilSessionComplete: boolean = false;
  resultLoaded: boolean = false;

  constructor() {}

  ngOnInit() {}
}
