import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

@Injectable()
export class UtilsService {
  constructor(private snackBar: MatSnackBar) {}

  // Генератор guid
  newGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  deleteItemFromStringArray(collection: string[], item: string) {
    let index: number = collection.findIndex(d => d === item);
    if (index > -1) {
      collection.splice(index, 1);
    }
  }

  deleteItemFromCollectionByName(collection: any[], item: any) {
    let index: number = collection.findIndex(d => d.name === item.name);
    if (index > -1) {
      collection.splice(index, 1);
    }
  }

  addStringValueToCollection(collection: string[], item: string) {
    if (item) {
      let indexItem: number = collection.findIndex(d => d === item);
      if (indexItem === -1) {
        collection.push(item);
      }
    }
  }

  openSnackBar(message: string, kindof: string, time?: number) {
    let cfg = new MatSnackBarConfig();
    cfg.horizontalPosition = 'right';
    cfg.verticalPosition = 'top';
    cfg.duration = time ? time : 9000;

    cfg.panelClass = kindof;
    this.snackBar.open(message, null, cfg);
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }
}
