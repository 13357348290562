import { ETestTypes } from '../../../shared/enums/testtypes.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IsAnonymousService {
  //TODO временный сервис, для плавного переезда сервисов - нужно в целом поменять логику получения сессий в тестах
  public isAnonymous(): boolean {
    const testType = localStorage.getItem('testType');
    const isAuthorized = localStorage.getItem('isAuthorized');
    return (
      !isAuthorized &&
      (testType == ETestTypes.MINCIFRYDEMO_TEST.toString() ||
        testType == ETestTypes.VK_TEST.toString() ||
        testType == ETestTypes.CAMP_TEST.toString() ||
        testType == ETestTypes.WEB_TEST.toString())
    );
  }
}
