<div class="prf-popup-overlay">
  <div class="popup-wrapper">
    <div class="popup-share">
      <a (click)="closePopup()" class="close-lb w-inline-block">
        <img src="./profilum-assets/images/landing/close-lb.svg" alt="" />
      </a>
      <div class="popup-share-header">
        <h3 class="popup-share-title">Вставьте ссылку от учителя</h3>
      </div>
      <div class="popup-share-content">
        <p class="popup-share-text">
          Для добавления подростка в класс скопируйте и вставьте ссылку, которую прислал его учитель в общий чат
        </p>
      </div>

      <div class="popup-share-buttons">
        <button (click)="confirm(true)" class="button">Да</button>

        <button (click)="confirm(false)" class="button">Нет</button>
      </div>
    </div>
  </div>
</div>
