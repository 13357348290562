<ng-container [ngSwitch]="userRole">
  <prf-schooladmin-menu *ngSwitchCase="userRoles.schooladmin"></prf-schooladmin-menu>
  <prf-menu *ngSwitchDefault></prf-menu>
</ng-container>

<ng-template [ngIf]="!urlSessionId && (userRole === userRoles.teacher || userRole === userRoles.schooladmin) && dataFetched">
  <div *ngIf="courseIds?.length; else withoutCourses" class="content-section">
    <div class="container content-wrapper">
      <prf-page-header [title]="''"></prf-page-header>

      <div *ngIf="schoolClassLoaded" class="page-results">
        <h1 class="page-results__title">Результаты</h1>
        <ng-container *ngIf="(schoolClass.pupilsCount ?? this.schoolClass.totalPupilsCount) <= 1; else results">
          <div class="empty-class-widget">
            <div class="empty-class-widget__wrapper">
              <div class="empty-class-widget__text">
                В вашем классе недостаточно {{ dictionary.PupilsGenitive }} для того, чтобы раздел «Результаты» заработал. Скопируйте и
                отправьте приглашение родителям — они добавят {{ dictionary.PupilsGenitive }}.
              </div>
              <button [disabled]="userRestriction" class="empty-class-widget__button" (click)="copyPupilRegLink()">
                Скопировать приглашение
              </button>
            </div>
          </div>
        </ng-container>

        <ng-template #results>
          <ng-container *ngIf="(schoolClass.pupilsCount ?? this.schoolClass.totalPupilsCount) >= 2 && !isAnyLessonGiven">
            <div class="empty-results-widget">
              <div class="empty-results-widget__wrapper">
                <div class="empty-results-widget__icon">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.0023 0.398438C6.04711 0.398438 0.402344 6.04321 0.402344 12.9984C0.402344 19.9535 6.04711 25.5983 13.0023 25.5983C19.9574 25.5983 25.6022 19.9535 25.6022 12.9984C25.6022 6.04321 19.9574 0.398438 13.0023 0.398438ZM14.2641 19.2943H11.7441V11.7344H14.2641V19.2943ZM11.7441 9.2153H14.2641V6.69531H11.7441V9.2153Z"
                      fill="#F87C23"
                    />
                  </svg>
                </div>
                <div class="empty-results-widget__text">
                  На этой странице вы сможете отслеживать выполнение заданий: кто из {{ dictionary.PupilsGenitive }} не начинал задание,
                  приступил к нему и завершил
                </div>
              </div>
            </div>
          </ng-container>

          <div class="page-results__body">
            <prf-choices-professions
              *ngIf="alreadyClicksResultsButtons.indexOf(resultsButtons.Choices) !== -1 || currentActiveButton === resultsButtons.Choices"
              [lessons]="lessons"
              [schoolClass]="schoolClass"
              [fieldId]="fieldId"
              [class.hidden]="currentActiveButton !== resultsButtons.Choices"
            ></prf-choices-professions>
            <prf-teachers-journal
              *ngIf="alreadyClicksResultsButtons.indexOf(resultsButtons.Journal) !== -1 || currentActiveButton === resultsButtons.Journal"
              [lessons]="lessons"
              (goToResults)="seeResults($event)"
              [class.hidden]="currentActiveButton !== resultsButtons.Journal"
            ></prf-teachers-journal>
            <!--            Диагностика-->
            <prf-diagnostic-results
              *ngIf="
                alreadyClicksResultsButtons.indexOf(resultsButtons.Diagnostics) !== -1 || currentActiveButton === resultsButtons.Diagnostics
              "
              [lessons]="lessons"
              [class.hidden]="currentActiveButton !== resultsButtons.Diagnostics"
            >
            </prf-diagnostic-results>
          </div>
        </ng-template>
      </div>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>

  <ng-template #withoutCourses>
    <div class="content-section">
      <div class="container content-wrapper">
        <prf-page-header [title]="''"></prf-page-header>

        <prf-shared-class-results *ngIf="schoolClassLoaded"></prf-shared-class-results>

        <prf-page-footer></prf-page-footer>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-container *ngIf="!urlSessionId && userRole === 'pupil'">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section">
    <div class="container container-1000">
      <prf-page-header [title]="'Тест'"></prf-page-header>

      @if (disableTalentDiagnosticInPupilLK) {
        <div class="widget_small">
          <div class="widget-wrapper">
            <span class="widget-title">Уважаемый ученик! Расписание и тематика занятий находятся в разработке и будут опубликованы позже </span>
          </div>
        </div>
      } @else {
        <div class="professions-section">
         <prf-parent-test-panel *ngIf="sessionStatus !== 'Success'" [pupilUserId]="userId" [isPupil]="true"> </prf-parent-test-panel>
       </div>
      }

      <div class="professions-section" *ngIf="sessionStatus === 'Success'" >
        <prf-results [child]="profile" [showSwitch]="true" [showResultMenu]="false"> </prf-results>
      </div>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>

<div *ngIf="urlSessionId" class="result-container">
  <ng-container *ngIf="!isHeaderHidden">
    <div class="testing-progress-bar-block">
      <div class="testing-progress-bar full"></div>
    </div>

    <div class="header-testing">
      <a routerLink="/{{ userRole }}" class="testing-logo-link w-inline-block">
        <img src="{{ profilumLogoUrl }}" alt="Логотип Профилум" />
      </a>
      <div class="header-testing-div">
        <div class="test-title">{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</div>
      </div>
    </div>
  </ng-container>

  <div class="content-section testing-content-2">
    <!-- SITE-2839 -->
    <!-- <a (click)="printPage()" class="button white-button print-btn-2 w-button">Распечатать</a> -->
    <div class="container container-900">
      <prf-results></prf-results>
    </div>
  </div>
</div>

<ng-container *ngIf="userRole === 'teacher' && (teacherHasNoClasses$ | async) === true" class="content-section">
  <prf-menu [root]="userRole"></prf-menu>
  <div class="content-section">
    <div class="container content-wrapper">
      <prf-page-header [title]="''"></prf-page-header>
      <div class="page-results">
        <h1 class="page-results__title">Результаты {{ dictionary.PupilsGenitive }}</h1>
        <div class="page-results__buttons">
          <button (click)="$event.preventDefault()" class="page-results__buttons__button page-results__buttons_disabled" [disabled]="true">
            Выборы
          </button>
          <button (click)="$event.preventDefault()" class="page-results__buttons__button page-results__buttons_disabled" [disabled]="true">
            Журнал
          </button>
          <button (click)="$event.preventDefault()" class="page-results__buttons__button page-results__buttons_disabled" [disabled]="true">
            Диагностика
          </button>
        </div>
        <div class="page-results__body">
          <div class="teacher-with-no-classes-banner">
            <prf-teacher-with-no-classes-banner></prf-teacher-with-no-classes-banner>
          </div>
        </div>
      </div>
      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>

<ng-template [ngIf]="popup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <div class="popup-share">
        <a (click)="handlePopup(false)" class="close-lb w-inline-block">
          <img src="./profilum-assets/images/landing/close-lb.svg" alt="" />
        </a>
        <div class="popup-share-header">
          <span class="icon-done"></span>
          <h3 class="popup-share-title">Приглашение для {{ schoolClass.className }} {{ dictionary.ClassGenitive }} скопировано</h3>
        </div>
        <div class="popup-share-content">
          <p class="popup-share-text">Здравствуйте, уважаемые родители {{ schoolClass.className }} {{ dictionary.ClassGenitive }}!</p>
          <p class="popup-share-text">
            {{ dictionary.OurClass | titlecase }} {{ dictionary.Class }} {{ isUserFromSalesB2S ? texts.SALES_B2S : texts.DEFAULT }}
          </p>
          <p class="popup-share-text">
            {{ schoolClass.shortLink ? schoolClass.shortLink : schoolClass.parentRegistrationLink }}
          </p>
        </div>
        <div class="popup-share-buttons">
          <button type="button" class="button green-btn" (click)="handlePopup(false)">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
