import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FooterLegalItemComponent,
} from '../../../components/footer/footer-legal/footer-legal-item/footer-legal-item.component';
import { PrfLegalItem } from '../../../components/footer/footer.types';

@Component({
  selector: 'prf-footer-legal',
  standalone: true,
  imports: [CommonModule, FooterLegalItemComponent],
  templateUrl: './footer-legal.component.html',
  styleUrl: './footer-legal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLegalComponent {
  @Input() legals: PrfLegalItem[];
}
