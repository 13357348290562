<div class="course-material-wrapper">
  <div class="title-with-download" *ngIf="courseMaterialSectionData.title">
    <span class="title"
      >{{ courseMaterialSectionData.title }}
      <!--({{ courseMaterialSectionData.items.length }})--></span
    >
    <!--<div *ngIf="theme !== CourseMaterialsTheme.MODAL" (click)="downloadAllMaterials(courseMaterialSectionData)" class="download-all">
      <span class="description">Скачать все ({{ courseMaterialSectionData.items.length }})</span>
      <svg class="icon" xmlns="http://www.w3.org/2000/svg">
        <use [attr.href]="'./profilum-components/icons/all-icons.svg#download'"></use>
      </svg>
    </div>-->
  </div>
  <div class="course-material-blocks-wrapper">
    <prf-course-material-block
      *ngFor="let courseMaterialItem of courseMaterialSectionData.items"
      [class.modal-theme]="theme === CourseMaterialsTheme.MODAL"
      [courseMaterialItem]="courseMaterialItem"
      (copyLinkEmitter)="copyLinkToMaterial($event)"
    ></prf-course-material-block>
  </div>
</div>
