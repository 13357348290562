import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { ApiUtilsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { RootScopeService } from '../../../../../shared/services/root-scope.service';
import { DictionaryType } from '../../../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-add-link-popup',
  templateUrl: './add-link-popup.component.html',
  styleUrls: ['./add-link-popup.component.scss'],
})
export class AddLinkPopupComponent extends UnsubscribeComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Output() dataLinks = new EventEmitter();

  public singleForm: UntypedFormGroup;
  public dictionary: DictionaryType;

  private regDataPupil: {
    regionId: string;
    cityId: string;
    schoolId: string;
    classId: string;
    namePupil?: string;
  } = null;

  public loading: boolean = false;

  constructor(private fb: UntypedFormBuilder, private apiUtilsService: ApiUtilsService, private rootScopeService: RootScopeService) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.singleForm = this.fb.group({
      link: new UntypedFormControl(''),
    });
  }

  get form(): any {
    return this.singleForm.controls;
  }

  public sendLink(): void {
    this.loading = true;
    this.apiUtilsService
      .getParamsFromShortLink(this.singleForm.controls['link'].value)
      .pipe(
        tap(params => {
          if (params.status === 'Failed') {
            return this.singleForm.controls['link'].setErrors({ unCorrect: true });
          }

          this.regDataPupil = {
            regionId: params.regionId,
            cityId: params.cityId,
            schoolId: params.schoolId,
            classId: params.classId,
          };

          this.dataLinks.emit(this.regDataPupil);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  public closePopup(): void {
    this.closeModal.emit();
  }
}
