import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import {
  B2gSaasService,
  ISchool,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { PageHeaderService } from '../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-schooladmin-panel',
  templateUrl: './schooladmin-panel.component.html',
  styleUrls: ['./schooladmin-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchoolAdminPanelComponent implements OnInit {
  school: ISchool;
  userInfo: IUserInfo;
  isProfile: boolean = false;
  schoolTitle: string;
  schoolClassProfile: any = [];
  subscription: any;
  home: string = '';
  homeURL: string = 'classes';

  constructor(
    private meta: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayBusyService,
    private headerService: PageHeaderService,
    private b2gSaasService: B2gSaasService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет школьного администратора' });
    this.subscription = this.headerService.getProfileState().subscribe(data => {
      this.isProfile = data.showProfile;
    });

    this.userInfo = userDataHandlerService.getUserInfo();
    this.getTranslation('SHARED.GENERAL')
      .pipe(take(1))
      .subscribe(translation => (this.home = translation));
  }

  ngOnInit() {
    this.overlayService.show();

    this.b2gSaasService
      .getSchool()
      .pipe(take(1))
      .subscribe(school => {
        this.school = school;
        this.webStorageService.set(StorageKeys.CityId, school.cityId);
        this.schoolTitle = this.school.number;
        this.schoolClassProfile = this.school;
        this.overlayService.hide();
      });
  }

  setTitleHeader(): string {
    const url = this.router.url;
    switch (url) {
      case '/director/school':
        return this.home;
      case '/schooladmin/courses/all-courses':
        return 'Курсы';
      case '/schooladmin/events/all-events':
        return 'Мероприятия';
      case '/schooladmin/profile':
        return 'Профиль';
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
