<section class="tabs-professions" *ngIf="favoritesList.length > 0; else emptyBlock">
  <article class="tab-menu-favorites">
    <a
      *ngFor="let filter of favoritesMenu"
      (click)="setFilter(filter)"
      [class.w--current]="filter === currentFilter"
      class="tabs-professions-link"
      >{{ filter.name }}</a
    >
  </article>

  <article class="tab-pane-favorites" [ngSwitch]="currentFilter.key">
    <ng-container *ngSwitchCase="'all'">
      <ng-container *ngTemplateOutlet="eventsBlock"></ng-container>
      <ng-container *ngTemplateOutlet="coursesBlock"></ng-container>
      <ng-container *ngTemplateOutlet="classesBlock"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'events'">
      <ng-container *ngIf="events.length > 0; then eventsBlock; else emptyBlock"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'courses'">
      <ng-container *ngIf="courses.length > 0; then coursesBlock; else emptyBlock"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'classes'">
      <ng-container *ngIf="classes.length > 0; then classesBlock; else emptyBlock"></ng-container>
    </ng-container>
  </article>
</section>

<ng-template #eventsBlock>
  <ng-container *ngIf="events.length">
    <h4 class="favorites-heading">Мероприятия</h4>
    <section class="catalog-events-row">
      <prf-course-card [course]="event" [favouritesAccess]="false" *ngFor="let event of events; let i = index; trackBy: trackByFn">
      </prf-course-card>
    </section>
  </ng-container>
</ng-template>

<ng-template #coursesBlock>
  <ng-container *ngIf="courses.length">
    <h4 class="favorites-heading">Курсы</h4>
    <section class="catalog-events-row">
      <prf-course-card [course]="course" [favouritesAccess]="false" *ngFor="let course of courses; let i = index; trackBy: trackByFn">
      </prf-course-card>
    </section>
  </ng-container>
</ng-template>

<ng-template #classesBlock>
  <ng-container *ngIf="classes.length">
    <h4 class="favorites-heading">Предметы</h4>
    <section class="catalog-events-row">
      <div *ngFor="let one_class of classes; let i = index; trackBy: trackByFn">
        <div class="favorites-block yellow">{{ one_class.productId }}</div>
      </div>
    </section>
  </ng-container>
</ng-template>

<ng-template #allBlock>
  <ng-container *ngTemplateOutlet="eventsBlock"></ng-container>
  <ng-container *ngTemplateOutlet="coursesBlock"></ng-container>
  <ng-container *ngTemplateOutlet="classesBlock"></ng-container>
</ng-template>

<ng-template #emptyBlock>
  <div class="empty-block">
    <div>
      <img src="./assets/images/icons/favourites.svg" alt="избранное" width="80" class="no-profession-icon" />
    </div>
    <div class="empty-block-text">{{ user.firstName }} {{ 'SHARED.USER_NOT_ADD_EVENT_TO_FAVORITE' | translate }}</div>
  </div>
</ng-template>
