import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationChangePasswordComponent } from './registration-change-password.component';
import { SharedModule } from 'app/shared/shared.module';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [RegistrationChangePasswordComponent],
  imports: [CommonModule, SharedModule, RouterLink],
  exports: [RegistrationChangePasswordComponent],
})
export class RegistrationChangePasswordModule {}
