import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import {
  RestorePasswordKazahstanComponent,
} from './restore-password/restore-password-kazahstan/restore-password-kazahstan.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { SendLinkComponent } from './send-link/send-link.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  ChangePasswordKazahstanComponent,
} from './change-password/change-password-kazahstan/change-password-kazahstan.component';
import { YmReachGoalModule } from 'app/shared/directives/ym-reach-goal/ym-reach-goal.module';
import { DynamicLabelModule } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.module';
import { ResetButtonModule } from 'app/shared/common-components/prf-form/reset-button/reset-button.module';
import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    TranslateModule,
    YmReachGoalModule,
    DynamicLabelModule,
    ResetButtonModule,
    CodeInputComponent,
    MatProgressSpinnerModule,
  ],
  declarations: [
    ChangePasswordComponent,
    RestorePasswordComponent,
    ConfirmEmailComponent,
    SendLinkComponent,
    RestorePasswordKazahstanComponent,
    ChangePasswordKazahstanComponent,
  ],
})
export class SettingsModule {}
