import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ApiFavoritesService,
  ApiProfessionsService,
  AppSettingsService,
  FavoritesDataHandlerService,
  IFavoriteProfessionInterface,
  IProfession,
  IUserInfo,
  ProfessionsService,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import {
  AddFirstProfessionModalService,
} from 'app/shared/dashboard/professions/welcome-choise-profession/add-first-profession-modal/add-first-profession-modal.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Component({
  selector: 'prf-profession-details',
  templateUrl: './profession-details.component.html',
  styleUrls: ['./profession-details.component.scss'],
})
export class ProfessionDetailsComponent extends UnsubscribeComponent implements OnInit {
  public userInfo: IUserInfo;
  public adminLevel: string = '';
  public profession: IProfession;
  public contentMenu: string[] = [];
  public currentContent: string;
  public dataFetched: boolean = false;
  public videoList: any;
  public personsList: any;
  public isGoal: boolean = false;
  public testCompleted: boolean = false;
  public showTooltipAdd: boolean = false;
  public showSalary: boolean = false;
  private favorites: Array<any> = [];
  private currentFavoriteIds: any = [];
  private hrid: string;

  @ViewChild('profInfoMenu') public infoMenu: ElementRef;

  constructor(
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private goalsService: GoalsService,
    private professionService: ProfessionsService,
    public route: ActivatedRoute,
    private router: Router,
    private apiFavoriteService: ApiFavoritesService,
    private addFirstProfessionModalService: AddFirstProfessionModalService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private apiProfessionsService: ApiProfessionsService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.testCompleted = this.webStorageService.get(StorageKeys.SessionStatus) === 'Success';
    this.meta.updateTag({ name: 'og:title', content: 'Детали профессии' });
  }

  public ngOnInit(): void {
    this.overlayService.show();

    if (this.userInfo.role?.includes('admin')) {
      this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    }

    this.hrid = this.route.snapshot.paramMap.get('hrid');
    this.apiProfessionsService
      .getProfessionByHridMongo(this.hrid)
      .pipe(
        switchMap(professionResponse => {
          if (professionResponse && this.userInfo.role) {
            this.profession = professionResponse;
            return this.favoritesDataHandlerService.getFavoriteProfessions().asObservable();
          }

          return of(null);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((favoriteProfessionsOrNull: IFavoriteProfessionInterface[] | null) => {
        if (favoriteProfessionsOrNull?.length) {
          this.favorites = favoriteProfessionsOrNull;
        }
        this.checkGoal();
        this.overlayService.hide();
      });

    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
  }

  private checkGoal(): void {
    window.scrollTo(0, 0);
    this.prepareMenu();
    this.professionService.setCurrentProfession(this.profession);
    if (this.favorites.length > 0) {
      // Проверка текущей профессии добавлена ли она в избранное
      this.isGoal = this.favorites.map(el => el.productId).indexOf(this.profession.id) > -1;
      // Получаем Ids избранных с текущей профессией (могут быть дубляжи)
      const filteredFavorites = this.favorites.filter(el => el.productId === this.profession.id);
      this.currentFavoriteIds = filteredFavorites.map(el => el.id);
    }
  }

  public addToGoals(): void {
    this.apiFavoriteService
      .addToFavorites(this.profession.id, 'Profession', this.userInfo.userId)
      .pipe(take(1))
      .subscribe(response => {
        this.favoritesDataHandlerService.addToFavorites({ productId: this.profession.id, id: response.id });
        if (this.favorites?.length) {
          this.router.navigate(['/pupil/' + this.profession.id]);
        } else {
          this.addFirstProfessionModalService.open('/pupil/' + this.profession.id);
        }
      });
  }

  public removeGoal(): void {
    if (this.currentFavoriteIds) {
      this.currentFavoriteIds.forEach((favoriteId, index) => {
        this.apiFavoriteService
          .deleteFromFavorites(favoriteId)
          .pipe(take(1))
          .subscribe(
            () => {
              this.favoritesDataHandlerService.deleteFavoriteProfession(favoriteId);
              //перенаправляем только после того, как удалились все дубликаты профессии (если они были)
              if (index + 1 == this.currentFavoriteIds.length) {
                this.router.navigate(['/' + this.userInfo.role]);
              }
            },
            () => {
              this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте снова', 'error');
            },
          );
      });
    }
  }

  public checkProfession(profession: any): boolean {
    if (profession) {
      return (
        profession.regionId === this.webStorageService.get(StorageKeys.RegionId) &&
        profession.municipalityId === this.webStorageService.get(StorageKeys.MunicipalityId)
      );
    }
  }

  private prepareMenu(): void {
    this.contentMenu = [];
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.contentMenu.push('Видео');
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.movies.length > 0) {
      this.contentMenu.push('Фильмы');
    }
    if (this.profession.persons.length > 0) {
      this.contentMenu.push('Профессия в лицах');
      this.personsList = this.profession.persons.slice(0, 2);
    }
    if (this.profession.articles.length > 0) {
      this.contentMenu.push('Книги и статьи');
    }
    this.currentContent = this.contentMenu[0];
    this.dataFetched = true;
  }

  public prepareData(): void {
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.persons.length > 0) {
      this.personsList = this.profession.persons.slice(0, 2);
    }
  }

  public scroll(): void {
    this.infoMenu.nativeElement.scrollIntoView();
  }

  public checkPopup(event, popup): void {
    if (!popup.contains(event.target)) {
      popup.style.display = 'block';
    }
  }

  public checkHeaderButton(): boolean {
    return ['pupil', 'parent', 'teacher'].includes(this.userInfo.role);
  }

  public sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
