<ng-container *ngIf="formDataArray['controls'] as pupils">
  <div class="class-section">
    <div class="class-section-title">Редактировать список учеников</div>
    <div class="class-section-class">{{ schoolClass.number }} {{ schoolClass.letter }}</div>
    <div class="w-form">
      <form id="email-form" [formGroup]="pupilsForm">
        <div class="class-div">
          <div class="class-header">
            <div class="class-row class-header-row-2">
              <div class="class-column _1"></div>
              <div class="class-column _2">
                <div class="class-name-row">
                  <div class="class-name-column w-hidden-medium w-hidden-small w-hidden-tiny">
                    <div class="class-name">{{ pupils.length }} {{ pupils.length | numerals : ['ученик', 'ученика', 'учеников'] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="class-list-block">
            <div class="students-list">
              <div class="class-list-item" formArrayName="users" *ngFor="let pupil of pupils; let i = index; trackBy: trackByFn">
                <div class="class-li-wrapper">
                  <div class="class-row">
                    <div class="class-column _1">
                      <div class="class-header-number">{{ i + 1 }}</div>
                    </div>

                    <div class="class-column _2">
                      <div *ngIf="pupil.value.isActivated == null">
                        <div class="class-name-row" [formGroupName]="i">
                          <div class="class-name-column">
                            <ng-container *ngFor="let error of errors">
                              <div class="error-text-field" *ngIf="error == i">
                                Произошла ошибка при сохранении данного ученика. Обратитесь к администратору
                              </div>
                            </ng-container>
                            <input
                              type="text"
                              formControlName="lastName"
                              class="class-field w-input"
                              [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.lastName.errors ? 'error' : ''"
                              maxlength="256"
                              data-name="{{ 'SHARED.FORM.FAMILY' | translate }}"
                              placeholder="{{ 'SHARED.FORM.FAMILY' | translate }}"
                            />

                            <div
                              class="error-text-field"
                              *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.lastName.errors"
                            >
                              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                            </div>
                          </div>

                          <div class="class-name-column">
                            <input
                              type="text"
                              formControlName="firstName"
                              class="class-field w-input"
                              [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.firstName.errors ? 'error' : ''"
                              maxlength="256"
                              data-name="{{ 'SHARED.FORM.NAME' | translate }}"
                              placeholder="{{ 'SHARED.FORM.NAME' | translate }}"
                            />

                            <div
                              class="error-text-field"
                              *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.firstName.errors"
                            >
                              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                            </div>
                          </div>

                          <div class="class-name-column">
                            <input
                              type="text"
                              formControlName="middleName"
                              class="class-field w-input"
                              [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.middleName.errors ? 'error' : ''"
                              maxlength="256"
                              data-name="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                              placeholder="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                            />
                            <div
                              class="error-text-field"
                              *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.middleName.errors"
                            >
                              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="pupil.value.isActivated != null">
                        <div class="class-name-row">
                          <div class="class-name-column">{{ pupil.value.lastName }} {{ pupil.value.firstName }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="class-column _3">
                      <a class="delete-button w-inline-block" (click)="openConfirmDelete(i)">Удалить</a>
                      <div class="transfer-button-wrapper">
                        <a
                          class="transfer-button"
                          (click)="showClassesList($event)"
                          [class.disable-button]="!pupil.value.email && !pupil.value.phone"
                        >
                          Перевести в другой класс
                        </a>
                        <div class="classes-wrapper">
                          <ul class="classes">
                            <li *ngFor="let class of classList" class="classes-item" (click)="openConfirmTransfer(pupil.value, class, i)">
                              {{ class }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <a (click)="addPupil()" class="button add-student w-button">Добавить еще ученика</a> -->
          </div>
        </div>
      </form>
      <div class="w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div class="w-form-fail">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  </div>

  <div class="buttons-wrapper">
    <a (click)="submit()" class="button class-edit-button w-button">Назад</a>
  </div>
</ng-container>

<ng-template #modal>
  <mat-dialog-content>
    <h3 class="modal-title">Перенос ученика</h3>
    <div class="modal-desc">
      <p>Вы уверены, что хотите перенести ученика в другой класс?</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Нет, не переносить ученика</button>
    <button mat-button color="warn" mat-dialog-close (click)="transferToAnotherClass()">Да, перенести ученика</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #modalDelete>
  <mat-dialog-content>
    <h3 class="modal-title">Удаление ученика</h3>
    <div class="modal-desc">
      <p>Вы уверены, что хотите удалить ученика?</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Нет, не удалять ученика</button>
    <button mat-button color="warn" mat-dialog-close (click)="removePupil()">Да, удалить ученика</button>
  </mat-dialog-actions>
</ng-template>
