<div [class]="'progress-bar-wrapper ' + theme" [ngClass]="additionalProgressTheme ? 'additional-' + additionalProgressTheme : ''">
  <div
    class="progressbar-title"
    [ngClass]="{
      'with-problem': isWithProblem
    }"
  >
    {{ title }}
  </div>
  <div class="results">
    <div class="bar">
      <div [style.width]="progress + '%'" class="line-progress">
        <div class="oval"></div>
      </div>
      <div *ngIf="additionalProgress" [style.margin-left]="additionalProgress + '%'" class="oval additional-oval"></div>
    </div>
    <div class="num">
      {{ progress }}
    </div>
    <div *ngIf="additionalProgress" class="num additional-num">
      {{ additionalProgress }}
    </div>
  </div>
</div>
