import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionComponent } from '../../components/description/description.component';
import { H2Component } from '../../components/h2/h2.component';
import {
  ProfessionCatalogCardComponent,
} from '../../components/profession-catalog/profession-catalog-card/profession-catalog-card.component';
import {
  DiagnosticsOfInterestsSliderComponent,
} from '../../components/diagnostics-of-interests/diagnostics-of-interests-slider/diagnostics-of-interests-slider.component';
import { PrfDiagnosticsOfInterests } from '../../components/diagnostics-of-interests/diagnostics-of-interests.types';
import { InfoBlockComponent } from '../../components/info-block/info-block.component';
import { SectionComponent } from '../../components/section/section.component';

@Component({
  selector: 'prf-diagnostics-of-interests',
  standalone: true,
  imports: [
    CommonModule,
    DescriptionComponent,
    H2Component,
    ProfessionCatalogCardComponent,
    DiagnosticsOfInterestsSliderComponent,
    InfoBlockComponent,
    SectionComponent,
  ],
  templateUrl: './diagnostics-of-interests.component.html',
  styleUrl: './diagnostics-of-interests.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsOfInterestsComponent {
  @Input() diagnosticsOfInterestsData: PrfDiagnosticsOfInterests;
}
