// promotest is for parents
export enum ETestTypes {
  DEFAULT_STANDART,
  DEFAULT_360,
  PROFESSIONPRESTIGE,
  MOTIVATION_TEST,
  VALUES_TEST,
  PROMO_TEST,
  MINCIFRYDEMO_TEST,
  BVB_TEST,
  VK_TEST,
  CAMP_TEST,
  MINCIFRY_TEST,
  BVB_TEST_VISUALLY_IMPAIRED,
  WEB_TEST,
  INTERESTS_TEST,
  CAPABILITIES_TEST,
  CAREER_LITERACY_TEST,
  MOBILE_TEST,
  DIAGNOSTIC_CAREER_TYPE,
  OUTPUT_DIAGNOSTIC,
}

// todo кол-во вопросов в тесте может меняться, возможно, нужен метод для запроса..
export enum ETestTypesSteps {
  DEFAULT_STANDART = 47,
  DEFAULT_360 = 18,
  PROFESSIONPRESTIGE = 7,
  MOTIVATION_TEST = 6,
  VALUES_TEST = 15,
  PROMO_TEST = 12,
  BVB_TEST = 34,
  MINCIFRYDEMO_TEST = 55,
  VK_TEST = 44,
  CAMP_TEST = 58,
  MINCIFRY_TEST = 42,
  BVB_TEST_VISUALLY_IMPAIRED = 30,
  WEB_TEST = 44,
}

export enum ETestTime {
  BVB_TEST = 40,
  BVB_TEST_VISUALLY_IMPAIRED = 40,
}
