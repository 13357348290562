import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { IFilterClasses } from '@profilum-library';
import {
  InternshipsFiltersService,
} from '../../../internship-page/internship-catalog/internship-filter/internships-filter.service';

@Component({
  selector: 'prf-elastic-active-courses',
  templateUrl: './elastic-active-courses.component.html',
  styleUrls: ['./elastic-active-courses.component.scss'],
})
export class ElasticActiveCoursesComponent implements OnInit {
  public courses: any = [];
  public classesCount: string;
  public classesSearchCount: string;
  public viewValue: boolean = false;
  filters: IFilterClasses;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(private meta: Meta, private internshipsFiltersService: InternshipsFiltersService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  // счетчик из фильтров и при ngOnInit
  public countCourses(coursesLength: number, searchLength: number = 0) {
    const complexLength = coursesLength + searchLength;
    const courseTitles = ['курс', 'курса', 'курсов'];
    const title = this.internshipsFiltersService.getTitleByNumber(complexLength, courseTitles);
    return (this.classesCount = complexLength + ' ' + title);
  }
}
