import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ApiUniversitiesService, IUniversity, StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-university-card',
  templateUrl: './university-card.component.html',
  styleUrls: ['./university-card.component.scss'],
})
export class UniversityCardComponent implements OnInit {
  @Input() university: IUniversity;

  userId: string;
  userRole: string;
  popUpConfirming: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private apiUniversitiesService: ApiUniversitiesService,
    private utilsService: UtilsService,
    private router: Router,
    private webStorageService: WebStorageService,
  ) {}

  ngOnInit() {
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  deleteUniversity(id) {
    this.apiUniversitiesService
      .deleteUniversity(id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 ВУЗ удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/universities']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
