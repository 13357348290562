<li class="prf-header-dropdown-menu-item prf-header-dropdown-menu-item-opened">
  <a
    [href]="linkHref"
    (click)="toggleMenu(ymGoal)"
    [ngClass]="{ 'prf-header-dropdown-menu-item-link-special': isSpecial }"
    class="prf-header-dropdown-menu-item-link"
  >
    {{ linkText }}
  </a>
</li>
