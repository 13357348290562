import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrfLink } from '../../../components/footer/footer.types';
import { YandexMetricsService } from '@profilum-library';

@Component({
  selector: 'prf-header-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-navbar.component.html',
  styleUrl: './header-navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavbarComponent {
  @Input() navItems: PrfLink[];
  private yandexMetricsService = inject(YandexMetricsService);

  public onClickNavItem(ymGoal: string) {
    this.yandexMetricsService.reachGoal(ymGoal);
  }
}
