import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  COURSES_TYPES,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';

import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ApiFavoritesService,
  FavoritesDataHandlerService,
  IInternship,
  ProductTypes,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-internship-card-new',
  templateUrl: './internship-card-new.component.html',
  styleUrls: ['./internship-card-new.component.scss'],
})
export class InternshipCardNewComponent {
  @Input() internship: IInternship;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isFavorite: boolean = false;
  favoritesInternships: any;
  userId: string;
  isToggleFavorite: boolean = false;
  startDate: string;

  public userRole: string = '';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private router: Router,
    private apiFavoritesService: ApiFavoritesService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {}

  public ngOnInit() {
    const userInfo = this.userDataHandlerService.getUserInfo();

    this.userRole = userInfo.role;
    this.userId = userInfo.userId;

    this.startDate = DateHelper.toDayJs(this.internship.startDate).format('D MMMM YYYY');
  }

  public toggleFavorite() {
    this.isToggleFavorite = true;

    if (this.isFavorite) {
      this.removeFavorite();
      this.isFavorite = false;
    } else {
      this.addToFavorite();
      this.isFavorite = true;
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  loadFavorites(): Observable<any> {
    return of(() => {
      this.favoritesInternships = this.favoritesDataHandlerService.getFavoriteClasses().getValue();
    });
  }

  addToFavorite() {
    this.apiFavoritesService
      .addToFavorites(this.internship.id, 'Internship', this.userId)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(() => this.favoritesDataHandlerService.needsUpdate.next(ProductTypes.Class)),
        switchMap(r => this.loadFavorites()),
      )
      .subscribe();
  }

  removeFavorite() {
    if (this.favoritesInternships) {
      const filteredFavorites = this.favoritesInternships.filter(el => el.productId === this.internship.id);
      const internshipFavoriteIds = filteredFavorites.map(el => el.id);
      if (internshipFavoriteIds) {
        internshipFavoriteIds.forEach(favoriteId => this.apiFavoritesService.deleteFromFavorites(favoriteId).subscribe());
        this.isFavorite = false;
      }
    }
  }

  checkAction(event: any) {
    if (!this.isToggleFavorite) {
      switch (true) {
        case this.heart && this.heart.nativeElement.contains(event.target):
          this.addToFavorite();
          break;
        case this.heartActive && this.heartActive.nativeElement.contains(event.target):
          this.removeFavorite();
          break;
        default:
          this.router.navigate(['/internships', this.internship.hrid]);
          break;
      }
    } else {
      this.favoritesInternships = false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
