import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { H3Component } from '../../../components/h3/h3.component';
import { ProfessionCatalogCard } from '../../../components/profession-catalog/profession-catalog.types';

@Component({
  selector: 'prf-profession-catalog-card',
  standalone: true,
  imports: [CommonModule, H3Component, NgOptimizedImage],
  templateUrl: './profession-catalog-card.component.html',
  styleUrl: './profession-catalog-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionCatalogCardComponent {
  @Input() card: ProfessionCatalogCard;
}
