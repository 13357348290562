<div class="title-with-download">
  <span class="title">Тема {{ lesson.number }}. {{ lesson.name }}</span>
  <div class="download-all">
    <span class="description">Скачать все ({{ materialsCount }})</span>
    <svg class="icon" xmlns="http://www.w3.org/2000/svg">
      <use [attr.href]="'./profilum-components/icons/all-icons.svg#download'"></use>
    </svg>
  </div>
</div>

<prf-course-materials
  [theme]="CourseMaterialsTheme.MODAL"
  [materials]="lesson.materials"
  [isNotificationHided]="false"
></prf-course-materials>
