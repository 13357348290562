import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

export enum TooltipPosition {
  Top,
  Bottom,
  Left,
}

@Component({
  selector: 'prf-tooltip-text',
  templateUrl: './tooltip-text.component.html',
  styleUrls: ['./tooltip-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipTextComponent {
  public tooltipPosition = TooltipPosition;
  public position: TooltipPosition;
  public show: boolean = false;

  // точка, после которой тултипы помещаются вверх в большинстве случаев
  private heightPoint: number = 150;
  @Input() accentColor: boolean = false;
  @Input() useMouseEventsOutsideComponent: boolean = false;
  @Input() buttonText: string = '';
  @Input() width: number = 390;
  @Input() set showTooltip(showTooltip: boolean) {
    if (typeof showTooltip === 'boolean') {
      this.show = showTooltip;
    }
  }
  @Input() preferredPosition: TooltipPosition;

  @HostListener('mouseenter', ['$event']) onMouseEnter(event) {
    if (this.preferredPosition) {
      this.position = this.preferredPosition;
    } else {
      const diffX = event.view.innerWidth - event.clientX;

      if (diffX < this.width) {
        this.position = TooltipPosition.Left;
      } else if (event.clientY < this.heightPoint) {
        this.position = TooltipPosition.Bottom;
      } else {
        this.position = TooltipPosition.Top;
      }
    }
  }
}
