import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ClipboardService } from 'ngx-clipboard';
import {
  ApiCoursesMaterialsService,
  ApiTemporaryService,
  AppSettingsService,
  B2gSaasService,
  IExtendedPupil,
  IGetCourseByClassResponse,
  ISchoolClass,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ButtonType } from '@profilum-collections/common.collections';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { AdminClassService } from 'app/shared/services/admin-class.service';
import { UserRoles } from 'app/shared/enums/userroles.enum';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { ICourseMaterialExt } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { ICurrentCLassData } from 'app/pages/control-panel/teacher/teacher-dashboard/teacher-dashboard.component';
import { MOSRED_ID } from 'app/shared/interfaces/regions-id.interface';
import {
  InvitationInstructionService,
} from '../../../../../ui-kit/services/invitation-instruction/invitation-instruction.service';
import { ModalHandlerService } from 'app/ui-kit/services/modal-handler/modal-handler.service';
import { ModalConfig } from '../../../../../ui-kit/components/modal/center-modal';

@Component({
  selector: 'prf-schooladmin-class',
  templateUrl: './schooladmin-class.component.html',
  styleUrls: ['./schooladmin-class.component.scss'],
})
export class SchooladminClassComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() isUserFromSalesB2S: boolean = false;

  @Output() teacherEvent = new EventEmitter<ITeacher>();
  @Output() schoolClassEvent = new EventEmitter<ISchoolClass>();
  @Output() pupilsEvent = new EventEmitter<IExtendedPupil[]>();
  @Output() editClassEvent = new EventEmitter<boolean>();
  @Output() editPupilsEvent = new EventEmitter<boolean>();

  public schoolClass: ISchoolClass;
  public currentClassData: ICurrentCLassData;
  public teacher: ITeacher;
  public pupils: IExtendedPupil[] = [];
  public pupil: IPupilsInClass;
  public selectedPupil: IExtendedPupil;
  public resetPasswordPopup: boolean = false;
  public isDiagnosticClass: boolean = false;
  public showPupilPage: boolean = false;
  public dataFetched: boolean = false;
  public userRole: string = '';
  public userRoles = UserRoles;
  public selectedPupilId: string = '';
  public parentsLink: string = '';
  public showCreatedClassPopup: boolean = false;
  public course: ICourseMaterialExt;
  public testedPupilExists: boolean = false;
  public isMOStend: boolean = false;
  public successPopup: boolean = false;

  private mosregId = MOSRED_ID;
  public copyTextGreetings = 'Здравствуйте! ';
  public copyTextClass = 'Ваш класс ';
  public copyTextTabulation = '\n\n';
  public copyTextDiagnostic = ' будет проходить диагностику. Зарегистрируйтесь по ссылке и добавьте учеников ';
  public copyTextCareerLiteracy = ` будет проходить профориентационный курс «Россия - мои горизонты» с региональным компонентом. Чтобы получить доступ к платформе с учебными материалами и списком учеников, вам нужно зарегистрироваться по ссылке `;
  public copyTextCareerLiteracyB2S: string = ` будет проходить курс «Карьерная грамотность». Чтобы получить доступ к платформе с учебными материалами и списком учеников, вам нужно зарегистрироваться по ссылке `;

  private regLinkData: {
    regionId: string;
    cityId: string;
    schoolId: string;
    classId: string;
  } = {
    regionId: null,
    cityId: null,
    schoolId: null,
    classId: null,
  };

  @ViewChild('inviteTeacher') public inviteTeacher: TemplateRef<any>;
  @ViewChild('editClassButton') public editClassButton: TemplateRef<any>;

  public inviteText: string;
  private modalContent = {
    titleText: 'Регистрация учителей на курс «Россия - мои горизонты» через платформу Профилум.',
    notification: `Для того чтобы учителя могли получить доступ к курсу «Россия — мои горизонты» c региональным компонентом, необходимо активировать их профессиональные аккаунты. <b>Ваша роль здесь ключевая</b>. Пожалуйста, выполните следующие шаги:`,
    steps: [
      {
        name: 'Отправка приглашения: ',
        text: 'у вас уже есть готовое приглашение со ссылкой для учителя. Просто скопируйте и отправьте его учителю - по почте или как удобно.',
      },
      {
        name: 'Регистрация учителя: ',
        text: 'Учитель должен перейти по полученной ссылке, ввести свой номер телефона и создать пароль для последующего доступа в систему Профилум.',
      },
      {
        name: 'Доступ для родителей: ',
        text: 'После активации аккаунта учитель получит в своем личном кабинете специальную ссылку для родителей. Необходимо убедиться, что учитель передаст эту ссылку родителям для их последующей регистрации.',
      },
      {
        name: 'Завершение регистрации: ',
        text: 'Родители, используя полученную ссылку, зарегистрируют себя и своих детей, что позволит начать процесс обучения на платформе.',
      },
    ],
    inviteTextTitle: 'Текст приглашения:',
    buttonText: 'Скопировать текст приглашения',
    buttonType: ButtonType.Primary,
  };
  private actions = {
    openSideModal: this.copyTeacherRegLink,
    editClass: this.showEditClass,
  };
  public userRestriction: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private b2gSaasService: B2gSaasService,
    private apiTemporaryService: ApiTemporaryService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private adminClassService: AdminClassService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private clipboardService: ClipboardService,
    private invitationInstructionService: InvitationInstructionService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private modalHandlerService: ModalHandlerService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Школьный класс' });
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.userRestriction = userDataHandlerService.isUserRestricted();
  }

  ngOnInit(): void {
    let showStatus: boolean;
    this.isMOStend = this.mosregId === this.webStorageService.get(StorageKeys.RegionId);
    this.modalHandlerService
      .getConfirmButton()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isConfirm => {
        switch (isConfirm) {
          case 'openSideModal':
            this.copyTeacherRegLink();
            break;
          case 'editClass':
            this.showEditClass();
            break;
          default:
            break;
        }
      });

    combineLatest([this.b2gSaasService.getSchool(), this.getSchoolClass(), this.getCourse()])
      .pipe(
        switchMap(([school, schoolClass, course]) => {
          this.regLinkData.cityId = school.cityId;
          this.course = course;

          if (schoolClass) {
            this.regLinkData.schoolId = schoolClass.schoolId;
            this.regLinkData.classId = schoolClass.id;
            return this.adminClassService.getPupilPage();
          } else {
            return of(null);
          }
        }),
        switchMap(data => {
          showStatus = data.showPupilPage;
          if (!this.showPupilPage) {
            return this.getDashboardData();
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: () => {
          this.showPupilPage = showStatus;
          this.overlayService.hide();
          setTimeout(() => this.scrollToLastSelected(), 0);
        },
        error: () => {
          this.overlayService.hide();
        },
      });
  }

  private showCenterModal(): void {
    const config: ModalConfig = {
      content: `<span>Учитель: </span>${this.teacher?.lastName + ' ' + this.teacher?.firstName + ' ' + this.teacher?.middleName}</br>
      <span>Курс: </span>${this.course?.name}`,
      secondaryButtonTitle: 'Редактировать',
      additionalAction: 'editClass',
      buttonType: ButtonType.Secondary,
      title: `${this.schoolClass.number + ' «' + this.schoolClass.letter}» создан!`,
    };

    if (!this.teacher.isActivated) {
      Object.assign(config, {
        buttonTitle: 'Пригласить учителя',
        action: 'openSideModal',
      });
    }

    this.modalHandlerService.setCenterModalConfig(config);
    this.modalHandlerService.toggleCenterModal(true);
  }

  public getSchoolClass(): Observable<any> {
    this.overlayService.show();
    const classID = this.route.snapshot.paramMap.get('classId');
    return this.apiTemporaryService.getSchoolClass(classID).pipe(
      tap(schoolClass => {
        if (schoolClass === 'CANT_FIND') {
          const userRole = this.webStorageService.get(StorageKeys.UserRole);
          switch (userRole) {
            case 'admin':
              const schoolId = this.schoolClass.schoolId;
              this.router.navigate([`/admin/schools/${schoolId}`]);
              break;
            default:
              this.router.navigate(['/']);
              break;
          }

          return this.utilsService.openSnackBar('👎 Данный класс не был найден', 'error');
        } else {
          this.parentsLink = schoolClass.shortLink ? schoolClass.shortLink : schoolClass.parentRegistrationLink;

          this.schoolClass = {
            className: schoolClass.className,
            id: schoolClass.id,
            letter: schoolClass.letter,
            number: schoolClass.number,
            pupilsCount: schoolClass.pupilsCount,
            schoolId: schoolClass.schoolId,
            teacherId: schoolClass.teacherId,
          };

          this.currentClassData = {
            classId: schoolClass.id,
            className: schoolClass.className,
            parentLink: schoolClass.shortLink ? schoolClass.shortLink : schoolClass.parentRegistrationLink,
          };

          this.schoolClassEvent.emit(this.schoolClass);
          return this.schoolClass;
        }
      }),
      catchError(err => {
        this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        return throwError(err);
      }),
    );
  }

  private getCourse(): Observable<ICourseMaterialExt> {
    this.overlayService.show();
    const classID = this.route.snapshot.paramMap.get('classId');
    return this.apiCoursesMaterialsService.getCourseByClass(classID).pipe(
      switchMap((course: IGetCourseByClassResponse) => {
        return !course.courseIds[0] ? of(null) : this.apiCoursesMaterialsService.getOneCourseMaterial(course.courseIds[0]);
      }),
      catchError(err => {
        this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        return throwError(err);
      }),
    );
  }

  private getDashboardData(): Observable<any> {
    let tempExtendedPupils;
    // получаем учителя класса
    return this.apiTemporaryService.getTeacher(this.schoolClass.id, this.schoolClass.schoolId).pipe(
      switchMap(teacher => {
        // получаем расширенный список учеников
        this.teacher = teacher;
        const showCreatedClassPopupInfo: string = this.webStorageService.get(StorageKeys.ShowCreatedClassPopupInfo);
        this.webStorageService.clear(StorageKeys.ShowCreatedClassPopupInfo);
        if (this.userRole === this.userRoles.schooladmin && showCreatedClassPopupInfo) {
          this.showCenterModal();
        }

        return this.apiTemporaryService.getExtendedPupils(this.schoolClass.id);
      }),
      switchMap(extendedPupils => {
        // получаем полный список учеников
        tempExtendedPupils = extendedPupils;
        return this.apiTemporaryService.getPupils(this.schoolClass.id);
      }),
      tap(pupils => {
        this.teacherEvent.emit(this.teacher);
        pupils.map(pupil => {
          const matchedExtendedPupil = tempExtendedPupils.find((extendedPupil: IExtendedPupil) => extendedPupil.userId === pupil.userId);
          this.pupils.push({
            ...pupil,
            favoriteProfessions: matchedExtendedPupil?.favoriteProfessions || [],
            lastSessionId: matchedExtendedPupil?.lastSessionId || null,
            lastTestDate: matchedExtendedPupil?.lastTestDate || null,
            lastSessionCompleted: matchedExtendedPupil?.lastSessionCompleted || false,
            lastParentSessionId: matchedExtendedPupil?.lastParentSessionId || null,
            lastParentSessionCompleted: matchedExtendedPupil?.lastParentSessionCompleted || false,
          });
        });
        this.testedPupilExists = this.pupils.findIndex(pupil => pupil.isTested) !== -1;
        this.pupilsEvent.emit(this.pupils);
        this.dataFetched = true;
      }),
    );
  }

  public showEditPupils(): void {
    this.editPupilsEvent.emit(true);
  }

  public showEditClass(): void {
    this.modalHandlerService.setConfirmButton('');
    this.editClassEvent.emit(true);
  }

  public showElement(event: any, width?: number): void {
    if (width) {
      const startPosition: number = 85;
      event.target.nextElementSibling.style.left = width - startPosition + 'px';
    }
    event.target.nextElementSibling.style.display = 'block';
  }

  public hideElement(event: any): void {
    event.target.nextElementSibling.style.display = 'none';
  }

  public showSelectedPupil(pupil: any): void {
    this.selectedPupil = pupil;
    this.selectedPupilId = pupil.userId;
    this.adminClassService.showPupilPage();
  }

  private isTestOld(lastTestDate: Date): boolean {
    const dateCheck = new Date();
    const dateCompare = new Date(lastTestDate);
    dateCheck.setMonth(dateCheck.getMonth() - 3);
    return dateCompare <= dateCheck;
  }

  private scrollToLastSelected(): void {
    if (this.selectedPupilId) {
      const lastSelected = document.querySelector('.last-selected');
      lastSelected?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public copyCode(pupil: IExtendedPupil, event: any): void {
    const tooltip = event.target.nextSibling;
    const currentElement = event.target;
    const path = AppSettingsService.settings.copyLink;
    const textMessage = `${pupil.firstName} ${pupil.lastName}, добро пожаловать в Профилум! \r\n 1. Перейди по ссылке: ${path} \r\n 2. Введи свой e-mail \r\n 3. Введи код: ${pupil.registrationCode}`;

    this.clipboardService.copy(textMessage);
    tooltip.classList.add('show');
    currentElement.textContent = 'Скопировано';

    this.timer = setTimeout(() => {
      tooltip.classList.remove('show');
      currentElement.textContent = 'Скопировать';
    }, 2000);
  }

  public changeResetPasswordPopup(event?: IExtendedPupil): void {
    if (event) {
      this.pupil = this.invitationInstructionService.createInvitationCardData(event);
    }
    this.resetPasswordPopup = !this.resetPasswordPopup;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.adminClassService.closePupilPage();
  }

  public copyTeacherRegLink(): void {
    this.inviteText =
      this.copyTextGreetings +
      this.teacher.lastName +
      ' ' +
      this.teacher.firstName +
      this.copyTextTabulation +
      this.copyTextClass +
      this.schoolClass.number +
      ' ' +
      this.schoolClass.letter +
      (!this.course ? this.copyTextDiagnostic : this.isUserFromSalesB2S ? this.copyTextCareerLiteracyB2S : this.copyTextCareerLiteracy) +
      location.origin +
      '/code-registration?code=' +
      this.teacher.registrationCode;
    this.modalHandlerService.sideModalTemplate.next(this.inviteTeacher);
    this.modalHandlerService.setConfirmButton('');
    this.modalHandlerService.toggleSideModal(true);
  }

  public closeSuccessPopup(): void {
    this.successPopup = false;
  }

  public showInviteCopyConfirmation(): void {
    const config = {
      title: 'Приглашение скопировано! Отправьте его учителю',
      buttonTitle: 'OK',
      content: 'Приглашение скопировалось в буфер обмена. Зайдите в чат с учителем и вставьте скопированный текст',
    };
    this.modalHandlerService.setCenterModalConfig(config);
    this.modalHandlerService.toggleCenterModal(true);
  }
}
