import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreakpointsComponent extends UnsubscribeComponent implements AfterViewInit, OnDestroy {
  private _isMobile = false;
  private _isTablet = false;
  private _isDesktop = false;

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected breakpointsService: BreakpointsService) {
    super();
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  get isTablet(): boolean {
    return this._isTablet;
  }

  get isDesktop(): boolean {
    return this._isDesktop;
  }

  public ngAfterViewInit(): void {
    this.breakpointsService.getCurrentBreakpoint.pipe(takeUntil(this.unsubscribe)).subscribe((currentBreakpoint: string) => {
      this._isMobile = BreakpointsService.isMobile(currentBreakpoint);
      this._isTablet = BreakpointsService.isTablet(currentBreakpoint);
      this._isDesktop = BreakpointsService.isDesktop(currentBreakpoint);
      this.changeDetectorRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
