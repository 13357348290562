import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { consultsMotivationData } from 'app/shared/global-constants/consults-data';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { tap } from 'rxjs/operators';

import { IResult, IResultsData } from '../../results.interface';
import { ResultsService } from '../../results.service';
import { B2gSaasService, StorageKeys, WebStorageService } from '@profilum-library';

export enum motivationTypes {
  AMOTIVATION,
  INNER_MOTIVATION,
  OUTER_MOTIVATION,
}

export enum motivationRatio {
  LOW,
  MEDIUM,
  HIGH,
}

@Component({
  selector: 'prf-motivation-results',
  templateUrl: './motivation-results.component.html',
  styleUrls: ['./motivation-results.component.scss'],
})
export class MotivationResultsComponent implements OnInit {
  @Input() pupil: any;
  @Input() userRole: string = 'pupil';
  @Input() set sessionId(val: string) {
    if (val && this.pupil) {
      this.getSessionResults(val, this.pupil);
    }
  }

  motivationTypes = motivationTypes;
  motivationRatio = motivationRatio;
  public results: any;

  consultsMotivationData = consultsMotivationData;

  // some logic constants
  lowFactorRatio = 27;
  highFactorRatio = 73;
  maximumSymbolsShortRecommends = 700;

  motivationObjects: IResult[] = [];
  factorsObjects: IResult[] = [];

  motivationData: any[] = [];
  factorsData: any[] = [];

  innerMotivationFactors = [
    {
      name: 'Любознательность',
      value: '',
      descriptions: {
        low: 'Тебе не очень интересна учеба: ты не получаешь удовольствия от изучения нового предмета и редко стремишься узнавать что-то новое. Скорее всего в учебе тебя мотивирует что-то другое.',
        medium:
          'Иногда тебе нравится учиться: возможность узнать что-то новое вызывает у тебя энтузиазм. Правда, любопытство проявляется не всегда, а только в отдельно интересных тебе предметах. Но если ты нашел интересное для себя дело, то нередко увлеченно работаешь и чувствуешь уверенность в себе.',
        high: 'Ты очень любознателен: учеба вызывает у тебя интерес и энтузиазм. Тебе нравится узнавать новое как в школе, так и за ее пределами: ты активно стремишься понять окружающий мир. Обычно во время занятий ты сосредоточен и уверен в себе – это помогает тебе чувствовать вовлеченность в процесс и мотивацию к решению новых задач. Благодаря такой мотивации ты можешь добиваться высоких результатов в учебе.',
      },
    },
    {
      name: 'Развитие навыков',
      value: '',
      descriptions: {
        low: 'В учебе для тебя не так важно развивать свои навыки. Возможно, тебя больше мотивируют другие хобби, и ты стремишься развиваться в них.',
        medium:
          'Отдельные предметы тебя по-настоящему увлекают – ты стремишься развивать свои навыки и становиться лучше. В этих случаях тебе важно чувствовать свою компетентность и уверенность в себе.',
        high: 'В учебе тебе важно развивать свои навыки и способности: ты хочешь быть мастером своего дела. Ты получаешь удовольствие, когда тебе удается становиться лучше и компетентнее в выбранной сфере.',
      },
    },
    {
      name: 'Продуктивность',
      value: '',
      descriptions: {
        low: 'Тебе не приносит удовольствия решать сложные задачи только ради их решения. Достижения в учебе для тебя – не главное.',
        medium:
          'Иногда при решении сложных задач ты испытываешь настоящее удовольствие: стремишься добиваться высоких результатов по некоторым предметам. Однако, чаще всего ты останавливаешься на среднем результате.',
        high: 'Ты стремишься добиваться высоких результатов в учебе и получаешь удовольствие от решения самых сложных заданий. В любимом деле ты стараешься быть перфекционистом и доводить начатое до конца. ',
      },
    },
  ];

  outerMotivationFactors = [
    {
      name: 'Награды',
      value: '',
      descriptions: {
        low: 'Твой интерес к учебе не зависит от возможной похвалы или другой награды. Обычно тебя не мотивирует возможность получить хорошую оценку, и ты не готов активнее работать на уроке, чтобы тебя заметил учитель.',
        medium:
          'Иногда бывает, что учиться тебя может заставить только возможная похвала со стороны взрослого. Бывают ситуации, когда у тебя нет внутреннего желания учиться: ты можешь выполнить домашнее задание, чтобы избежать проблем или чтобы получить одобрение от учителя. Такая мотивация не всегда может привести к высоким результатам.',
        high: 'Во время учебы тебя часто посещает чувство тревожности по поводу своих способностей. Хорошо учиться тебя мотивируют действия других людей: ты часто делаешь задания, чтобы избежать проблем или чтобы получить одобрение, например, наказание со стороны родителей или похвала от учителя. С таким типом мотивации фокус внимания уходит не на собственные желания, а не внешние факторы, из-за чего добиваться высоких результатов может быть сложнее.',
      },
    },
    {
      name: 'Правила',
      value: '',
      descriptions: {
        low: 'Обычно твой уровень мотивации не зависит от того, что ждут от тебя другие: если ты терпишь неудачу, то тебя вряд ли будет мучить чувство вины, но и гордости за свои победы ты тоже не испытываешь. Тебя сложно мотивировать учиться фразами “ты должен учиться хорошо”.',
        medium:
          'Иногда единственное, что может заставить тебя учиться – это чувство долга. Ты испытываешь чувство вины или стыда, если терпишь неудачу. Но если получается выполнить задуманное, то очень гордишься результатами. Бывает, что ты ориентируешься на правила, которые диктуют определенную манеру поведения. В таких случаях сложно постоянно показывать высокие результаты, ведь ты зависишь от внешних факторов.',
        high: 'Во время учебы ты часто испытываешь чувство тревоги: хорошо учиться тебя мотивируют желание выполнить намеченное и не потерпеть неудачу. Часто ты стараешься учиться хорошо из чувства долга: не обязательно, чтобы взрослые мотивировали тебя возможной похвалой, зачастую ты уже сам способен оценить результаты своих действий. Такая мотивация сильно зависит от твоей самооценки и внешних факторов, поэтому не очень устойчива. ',
      },
    },
    {
      name: 'Убеждения',
      value: '',
      descriptions: {
        low: 'Тебе кажется, что школа – не самое важное в жизни. Поэтому тебе сложно заставить себя вовлекаться в учебу и выполнять домашние задания.',
        medium:
          'Тебе легко даются некоторые предметы, и ты готов выполнять домашние задания по собственному желанию: ты понимаешь ценность учебы и преимуществ, которые она тебе может дать. Такой тип мотивации стоит на стыке внешней и внутренней – тебе будет легко найти состояние, когда интересом к учебе начнет двигать собственное желание.',
        high: 'Хорошо учиться — это твой собственный выбор. Ты считаешь образование важным и ценным: стараешься делаешь уроки и быть активным в классе. Однако нельзя сказать, что ты учишься ради удовольствия. Такая мотивация очень близка к внутренней, поэтому тебе будет легко перейти на следующий уровень.',
      },
    },
  ];

  public motivationsTextData = {
    amotivation: {
      low: 'Амотивация – это отсутствие мотивации к учебе. С твоими показателями, ты редко чувствуешь полное отсутствие мотивации и всегда находишь стимул для обучения чему-то новому.',
      medium:
        'Амотивация – это отсутствие мотивации к учебе. Твои показатели говорят о том, что иногда ты сталкиваешься с такой проблемой, как полное отсутствие мотивации к учебе. В такие моменты ты перестаешь видеть связь между усилиями, которые ты прикладываешь и результатом, который получаешь.',
      high: 'Амотивация – это отсутствие мотивации к учебе. Твои показатели говорят о том, что ты часто сталкиваешься с такой проблемой, как полное отсутствие мотивации к учебе. В такие моменты ты перестаешь видеть связь между усилиями, которые ты прикладываешь и результатом, который получаешь. ',
    },
    outerMotivation: {
      low: 'При внешней мотивации мы делаем что-то ради того, чтобы получить награду или похвалу со стороны другого человека. Судя по твоим показателям, внешние факторы в учебе не влияют на твой к ней интерес.',
      medium:
        'Внешняя мотивация основывается на принципе “получить вознаграждение или избежать наказания”. Судя по твоим показателям, в учебе тебя периодически мотивируют внешние факторы, такие как признание, похвала или наказания.',
      high: 'Внешняя мотивация основывается на принципе “получить вознаграждение или избежать наказания”. Судя по твоим показателям, в учебе тебя сильно мотивируют внешние факторы, такие как признание, похвала или наказания.',
    },
    innerMotivation: {
      low: 'Внутренняя мотивация основывается на желании учиться самостоятельно и получении удовольствия от процесса. Похоже, что ты редко занимаешься учебой из любопытства или интереса – она не приносит тебе большого удовольствия.',
      medium:
        'Внутренняя мотивация основывается на желании учиться самостоятельно и получении удовольствия от процесса. Похоже, что иногда тебе все-таки нравится процесс учебы – тебе знакомо состояние “потока”, когда учеба увлекает настолько, что все остальное отходит на второй план.',
      high: 'Внутренняя мотивация основывается на желании учиться самостоятельно и получении удовольствия от процесса. Похоже, ты учишься ради удовольствия, которое получаешь от процесса. Ты часто занимаешься в состоянии “потока”, когда учеба увлекает тебя настолько, что все остальное отходит на второй план.',
    },
  };

  public recommendationsTextData = {
    curiosity: {
      low: 'Любознательность: Попробуй открывать для себя новые горизонты: составь для себя список вещей, которые тебе всегда хотелось попробовать. И попробуй выполнять по одному пункту в день! Но не берись сразу за что-то трудное, начни с простый вещей, которые тебе точно под силу – так ты сможешь почувствовать уверенность в себе.',
      medium:
        'Любознательность: Попробуй открывать для себя новые горизонты: составь для себя список вещей, которые тебе всегда хотелось попробовать. И попробуй выполнять по одному пункту в день! Но не берись сразу за что-то трудное, начни с простый вещей, которые тебе точно под силу – так ты сможешь почувствовать уверенность в себе.',
      high: 'Любознательность: Так держать! Продолжай проявлять интерес к новым открытиям и знаниям. Это поможет тебе постоянно развиваться и выбрать профессию, которая точно будет по душе.',
    },
    skillsDevelopment: {
      low: 'Развитие навыков: Залог успешного выполнения дела – это чувство компетентности в нем. Если тебе трудно сразу взяться за большую задачу, попробуй разбивать ее на маленькие дела, выполняя которые ты почувствуешь прилив сил и уверенность в себе.',
      medium:
        'Развитие навыков: Залог успешного выполнения дела – это чувство компетентности в нем. Если тебе трудно сразу взяться за большую задачу, попробуй разбивать ее на маленькие дела, выполняя которые ты почувствуешь прилив сил и уверенность в себе.',
      high: 'Развитие навыков: Рекомендуем тебе сохранять интерес к саморазвитию, ведь это отличный актив для построения успешной карьеры.',
    },
    productivity: {
      low: 'Продуктивность: Подумай, в каком занятии, предмете или секции, ты чувствуешь себя как рыба в воде. В сфере, где ты чувствуешь себя уверенно и компетентно, ты легко можешь запланировать достижение амбициозной цели. Попробуй сфокусироваться на ее достижении.',
      medium:
        'Продуктивность: Подумай, в каком занятии, предмете или секции,  ты чувствуешь себя как рыба в воде. В сфере, где ты чувствуешь себя уверенно и компетентно, ты легко можешь запланировать достижение амбициозной цели. Попробуй сфокусироваться на ее достижении.',
      high: 'Продуктивность: Тебе удается поддерживать в себе интерес к решению трудных задач. Продолжай в том же духе!',
    },
    awards: {
      low: 'Награды: Иногда бывают случаи, когда внешняя мотивация, основанная на наградах, может пригодиться. Например, в краткосрочных задачах поощрение может помочь тебе быстро ее выполнить: ты можешь договориться с собой, что порадуешь себя чем-то за, например, хорошую оценку. Но не используй такую мотивацию слишком часто – так ты можешь забыть то, что тебе по-настоящему нравится, а достигать целей только ради награды.',
      medium:
        'Награды: Попробуй не ориентироваться на похвалу родителей и учителей: такая мотивация не очень нестабильна, ведь тебе приходится зависеть от их мнения других, а людей контролировать сложно. Воспринимай оценки как инструмент для обратной связи: они нужны для того, чтобы показать тебе, где скрыты пробелы в твоих знаниях, но они ничего не говорят о тебе как о личности. Попробуй сфокусироваться на том, что тебе интересно и что у тебя хорошо получается.',
      high: 'Награды: Попробуй не ориентироваться на похвалу родителей и учителей: такая мотивация не очень нестабильна, ведь тебе приходится зависеть от их мнения других, а людей контролировать сложно. Воспринимай оценки как инструмент для обратной связи: они нужны для того, чтобы показать тебе, где скрыты пробелы в твоих знаниях, но они ничего не говорят о тебе как о личности. Попробуй сфокусироваться на том, что тебе интересно и что у тебя хорошо получается.',
    },
    rules: {
      low: 'Правила: Такая мотивация может быть полезна для решения краткосрочных задач: чтобы помочь себе добиться поставленной цели, расскажи о ней своим друзьям и сформируй вокруг себя поддерживающее пространство, которое будет подбадривать тебя и давать силы добиваться намеченного. Однако следует учитывать, что для долгосрочных задач такая мотивация не подходит, потому что сложно всегда зависеть от других людей. Лучше фокусироваться на внутреннем чувстве уверенности от занятия, которое у тебя хорошо получается.',
      medium:
        'Правила: Попробуй проанализировать, почему в случае неудачи ты испытываешь сильные негативные чувства, возможно, стыд или вину. Стремись воспринимать провалы как часть процесса на пути к достижению результата – любому открытию или результату предшествует неудачные опыты. Вряд ли Эйнштейн сделал свои открытия с первого раза.',
      high: 'Правила: Попробуй проанализировать, почему в случае неудачи ты испытываешь сильные негативные чувства, возможно, стыд или вину. Стремись воспринимать провалы как часть процесса на пути к достижению результата – любому открытию или результату предшествует неудачные опыты. Вряд ли Эйнштейн сделал свои открытия с первого раза.',
    },
    beliefs: {
      low: 'Убеждения: Попробуй использовать такую мотивацию как инструмент для достижения глобальных целей. Например, ты можешь не любить математику, но у тебя есть мечта стать инженером – хорошие оценки и знания помогут тебе поступить на выбранный факультет.',
      medium:
        'Убеждения: Ты часто делаешь то, что надо, а не то, что хочешь. Давай подумаем, что тебе доставляет радость? Можешь ли ты привнести это в учебный процесс? Понаблюдай за собой в течение недели, обращая внимания на собственные ощущения и переживания. По результатам этого анализа ты можешь составить чек-лист из приятных вещей, которые связаны с учебой. Постарайся расширить этот список со временем.',
      high: 'Убеждения: Ты часто делаешь то, что надо, а не то, что хочешь. Давай подумаем, что тебе доставляет радость? Можешь ли ты привнести это в учебный процесс? Понаблюдай за собой в течение недели, обращая внимания на собственные ощущения и переживания. По результатам этого анализа ты можешь составить чек-лист из приятных вещей, которые связаны с учебой. Постарайся расширить этот список со временем.',
    },
  };

  public amotivationRecommendations = {
    low: [
      'Классный результат! Ты стараешься фокусироваться на том, что тебе интересно и что у тебя хорошо получается. Так держать! Продолжай развиваться в этих сферах.',
      'Если у тебя высокая внешняя мотивация, есть смысл стремиться уменьшать ее долю и увеличивать процент внутренней мотивации. Как это сделать? Смотри в рекомендациях к ней.',
      'Если у тебя высокая внутренняя мотивация – так держать! Продолжай заниматься тем, что тебе нравится и развивайся в выбранном занятии.',
    ],
    medium: [
      'Попробуй подумать, что поддерживает тебя в учебе и дает силы двигаться дальше: составь список занятий, что тебе нравится (например, какие предметы у тебя любимые), и что у тебя хорошо получается. Сфокусируйся на этих сферах – изучай больше информации по этим предметам и развивай свои навыки.',
      'Если у тебя высокая внешняя мотивация, есть смысл стремиться уменьшать ее долю и увеличивать процент внутренней мотивации. Как это сделать? Смотри в рекомендациях к ней.',
      'Если у тебя высокая внутренняя мотивация – так держать! Продолжай заниматься тем, что тебе нравится и развивайся в выбранном занятии.',
    ],
    high: [
      'Амотивация: Скорее всего твои базовые потребности в независимости, компетентности и принятии тебя другими заблокированы. Трудно чувствовать себя замотивированным, когда на протяжении долгого времени не удается чувствовать себя уверенным самостоятельным и принятым. Для того, чтобы снова обрести желание двигаться дальше, попробуй проделать такое упражнение: <br>1) Выпиши, что для тебя наиболее ценно, что тебя волнует. 2) Основываясь на этом поставь себе цель. 3) Сделай ее предельно ясной и понятной по технологии SMART (воспользуйся нашей шпаргалкой). 4) Представь, что будет, если ты достигнешь эту цель: можно даже нарисовать картинку того, как все будет. 5) Определись с первыми небольшими шагами. 6) Подумай над тем, как ты будешь оценивать прогресс и отпразднуешь достижение цели.',
    ],
  };

  @ViewChild('swiperMotivComponent') swiperMotivComponent?: ElementRef;
  public swiperMotiv?: Swiper;
  public swiperMotivationsConfig: SwiperOptions  = {
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  };

  @ViewChild('swiperDescriptionInMotElement') swiperDescriptionInMotElement?: ElementRef;
  public swiperDescriptionIn?: Swiper;
  public swiperDescriptionInMotConfig: SwiperOptions  = {
    navigation: {
      nextEl: '.swiper-descrIn-next',
      prevEl: '.swiper-descrIn-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };
  @ViewChild('swiperRecommendationsInMotElement')
  swiperRecommendationsInMotElement?: ElementRef;
  public swiperRecommendationIn?: Swiper;
  public swiperRecommendationsInMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-recomIn-next',
      prevEl: '.swiper-recomIn-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };

  @ViewChild('swiperDescriptionOutMotElement') swiperDescriptionOutMotElement?: ElementRef;
  public swiperDescriptionOut?: Swiper;
  public swiperDescriptionOutMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-descrOut-next',
      prevEl: '.swiper-descrOut-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };
  @ViewChild('swiperRecommendationsOutMotElement')
  swiperRecommendationsOutMotElement?: ElementRef;
  public swiperRecommendationOut?: Swiper;
  public swiperRecommendationsOutMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-recomOut-next',
      prevEl: '.swiper-recomOut-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };

  dataLoaded: boolean = false;
  public nullResults: boolean = false;
  public isMobile: boolean = false;
  mobileSlideNumber: number = null;
  mobileOpened: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private resultService: ResultsService,
    private b2gSaasService: B2gSaasService,
    public element: ElementRef,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private webStorageService: WebStorageService,
  ) {
    this.isMobile = window.innerWidth <= 450;
    this.webStorageService.clear(StorageKeys.TestType);

    matIconRegistry.addSvgIcon(
      'next',
      sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/results/motivation-results/swiper-next.svg'),
    );
    matIconRegistry.addSvgIcon(
      'prev',
      sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/results/motivation-results/swiper-prev.svg'),
    );
  }

  ngOnInit() {}

  getSessionResults(sessionId, user) {
    this.dataLoaded = false;
    const getResultsObject = {
      regionId: user.regionId,
      municipalityId: user.municipalityId,
      sessionIds: [sessionId],
      role: this.userRole,
    };

    return this.b2gSaasService
      .getResultPage(getResultsObject)
      .pipe(
        tap(results => {
          if (results) {
            this.results = results;
            this.parseResults(this.results);
          }
        }),
      )
      .subscribe(() => {
        this.dataLoaded = true;
        if (!this.isMobile) {
          setTimeout(() => {
            this.changeMotivSection(0);
          }, 50);
        }
      });
  }

  private parseResults(results: IResultsData) {
    let resultsData;
    if (!results || !results.results || results.results.length === 0) {
      this.nullResults = true;
      return;
    }

    resultsData = JSON.parse(JSON.stringify(results));

    if (resultsData.results[0].results.length === 0) {
      return;
    }

    this.motivationObjects = resultsData.results.filter(d => d.objectType === 'Motivation');
    this.factorsObjects = resultsData.results.filter(d => d.objectType === 'MotivationSevenScales');

    this.motivationData = this.motivationObjects.map(i => {
      return {
        title: i.name,
        value: i.results.length ? Math.round(i.results[0].transformedValue) : 0,
      };
    });

    // меняем местами 2-ой и 3-ий элемента массива
    this.motivationData = this.swapElements(this.motivationData, 1, 2);

    this.factorsData = this.factorsObjects.map(i => {
      return {
        title: i.name,
        value: i.results.length ? Math.round(i.results[0].transformedValue) : 0,
      };
    });

    // матчинг факторов по названию
    this.innerMotivationFactors = this.innerMotivationFactors.map((factor, i) => {
      return {
        name: this.innerMotivationFactors[i].name,
        value: this.factorsData.find(fd => fd.title == this.innerMotivationFactors[i].name).value,
        descriptions: this.innerMotivationFactors[i].descriptions,
      };
    });
    this.outerMotivationFactors = this.outerMotivationFactors.map((factor, j) => {
      return {
        name: this.outerMotivationFactors[j].name,
        value: this.factorsData.find(fd => fd.title == this.outerMotivationFactors[j].name).value,
        descriptions: this.outerMotivationFactors[j].descriptions,
      };
    });
  }

  swapElements(arr = [], start = 0, end = 0) {
    const result = new Map();
    arr.map((_, index) => {
      switch (index) {
        case start:
          return result.set(index, arr[end]);
        case end:
          return result.set(index, arr[start]);
        default:
          return result.set(index, arr[index]);
      }
    });
    return Array.from(result.values());
  }

  changeMotivSection(number: number) {
    if (this.isMobile) {
      this.mobileOpened = !this.mobileOpened;
      this.mobileSlideNumber = number;
      window.scrollTo(0, 0);
    } else {
      this.swiperMotiv.activeIndex = number;
    }
  }
  closeMobileSlide() {
    this.mobileOpened = !this.mobileOpened;
  }

  changeInMotDescriptionText(index: number) {
    this.swiperDescriptionIn.activeIndex = index;
  }

  changeOutMotDescriptionText(index: number) {
    this.swiperDescriptionOut.activeIndex = index;
  }

  checkFactorRatio(factorValue: number) {
    switch (true) {
      case factorValue < this.lowFactorRatio:
        return 'low';
      case factorValue > this.highFactorRatio:
        return 'high';
      default:
        return 'medium';
    }
  }

  isSliderForRecom(motivationype: motivationTypes) {
    if (this.isMobile) {
      return false;
    } else {
      if (motivationype === this.motivationTypes.INNER_MOTIVATION) {
        const summaryInSymbols =
          this.recommendationsTextData.curiosity[this.checkFactorRatio(parseInt(this.innerMotivationFactors[0].value))].length +
          this.recommendationsTextData.skillsDevelopment[this.checkFactorRatio(parseInt(this.innerMotivationFactors[1].value))].length +
          this.recommendationsTextData.productivity[this.checkFactorRatio(parseInt(this.innerMotivationFactors[2].value))].length;
        return summaryInSymbols > this.maximumSymbolsShortRecommends;
      } else if (motivationype === this.motivationTypes.OUTER_MOTIVATION) {
        const summaryOutSymbols =
          this.recommendationsTextData.awards[this.checkFactorRatio(parseInt(this.outerMotivationFactors[0].value))].length +
          this.recommendationsTextData.rules[this.checkFactorRatio(parseInt(this.outerMotivationFactors[1].value))].length +
          this.recommendationsTextData.beliefs[this.checkFactorRatio(parseInt(this.outerMotivationFactors[2].value))].length;
        return summaryOutSymbols > this.maximumSymbolsShortRecommends;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }
}
