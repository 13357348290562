import { Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiFavoritesService,
  ApiPlayerService,
  ApiProfessionsService,
  ApiProfilesService,
  ApiSchoolsService,
  B2gSaasService,
  EmptyGuid,
  ISharedResultsResponse,
  ITestInfo,
  IUserInfo,
  ProductTypes,
  RamStorageService,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { ResultsService } from 'app/pages/results/results.service';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { Gender } from 'app/shared/enums/gender.enums';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { IChildInfo, IParentTestInfo } from './parent.interface';
import { ModalHandlerService } from '../../../ui-kit/services/modal-handler/modal-handler.service';

interface IRegDataPupil {
  regionId: string;
  cityId: string;
  schoolId: string;
  classId: string;
  namePupil?: string;
}

@Component({
  selector: 'prf-parent-panel',
  templateUrl: './parent-panel.component.html',
  styleUrls: ['./parent-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParentPanelComponent extends UnsubscribeComponent implements OnInit {
  public userData: IUserInfo;
  public dataLoaded: boolean = false;
  public userProfile: any = {};
  public favoriteProfessions: Array<any> = [];
  public showProfile: boolean = false;
  public childrenRegister: Array<any> = [];
  public isShowChildReg: boolean = false;
  public sessionIDParent: string = '';
  public sessionIDLoaded: boolean = false;
  public addChildForm: UntypedFormGroup;
  public addChildPopup: boolean = false;
  public personalTerms: boolean = false;
  public advertisingTerms: boolean = false;
  public pupilSessionComplete$: Observable<boolean>;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public userRestriction: boolean = false;
  public defaultParentTestInfoChild: IParentTestInfo = {
    isParentCompleteTest: false,
    parentTestSession: '',
    isParentFromPskov: false,
  };
  public currentClass: {
    classNumber: number;
    classLetter: string;
    classId: string;
  };
  public currentSchoolName: string;
  public genderChoice: string = Gender.Male;
  public isFemale: boolean = false;
  public isMale: boolean = false;
  public dateMask: (RegExp | string)[] = [/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  public linkPopupShow: boolean = false;
  public linkChecked: boolean = false;
  public copyLinkRegister: string = '';
  public mask: (string | RegExp)[] = [
    '+',
    '7',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];
  public regDataPupil: IRegDataPupil = null;
  public regionId: string;
  private currentCityName: string;
  private newChildFirstName: string = '';
  private newChildLastName: string = '';
  private newChildBirthdate: string = '';
  private newChildPhoneNumber: string = '';
  private _selectedChild: IChildInfo = {};
  @ViewChild('popupOnPage', { read: ViewContainerRef })
  private popup: ViewContainerRef;

  constructor(
    private meta: Meta,
    private goalsService: GoalsService,
    private apiScoolsService: ApiSchoolsService,
    private resultService: ResultsService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private modalHandlerService: ModalHandlerService,
    private webStorageService: WebStorageService,
    private ramStorageService: RamStorageService,
    private b2gSaasService: B2gSaasService,
    private apiFavoritesService: ApiFavoritesService,
    private apiPlayerService: ApiPlayerService,
    private apiProfessionsService: ApiProfessionsService,
    private overlayBusyService: OverlayBusyService,
    private apiProfilesService: ApiProfilesService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Страница родителя' });
    this.userRestriction = userDataHandlerService.isUserRestricted();
  }

  public get selectedChild(): IChildInfo {
    return this._selectedChild;
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
    this.dataLoaded = false;
    this.userData = this.userDataHandlerService.getUserInfo();
    this.copyLinkRegister = location.origin;
    this.loadUserProfile();
    this.pupilSessionComplete$ = this.resultService.pupilSessionComplete;
    this.initFields();
  }

  public copyChildCode(): void {
    this.getTranslation(['PARENT_PANEL.INVITATION_TEXT'], {
      childName: this.selectedChild.firstName,
    })
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe(translations => {
        const message = translations['PARENT_PANEL.INVITATION_TEXT'];
      });
  }

  public onSelectChild(child: IChildInfo): void {
    this.selectedChild = child;
    this.copyLinkRegister = location.origin + '/code-registration?code=' + this.selectedChild.registrationCode;
  }

  public get f(): Record<string, AbstractControl> {
    return this.addChildForm.controls;
  }

  public sharedResults(): void {
    this.apiProfilesService
      .getSharedResults(this.userData.userId)
      .pipe(
        switchMap((sharedResults: ISharedResultsResponse[]) => {
          if (sharedResults.length > 0) {
            return this.apiPlayerService.regionTestInfo(this.userProfile.regionId).pipe(
              tap((testInfo: ITestInfo) => {
                const sessionID: ISharedResultsResponse[] = sharedResults.filter(
                  (result: ISharedResultsResponse) =>
                    result.sessionId === testInfo.screeningTestId && result.refferalUserId === this._selectedChild.userId,
                );
                if (sessionID.length > 0) {
                  this.sessionIDParent = sessionID[0].sessionId;
                } else {
                  this.sessionIDParent = null;
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: () => {
          this.sessionIDLoaded = true;
        },
        error: () => {
          this.sessionIDLoaded = true;
        },
      });
  }

  public loadUserProfile(): void {
    this.dataLoaded = false;
    this.b2gSaasService
      .getUserInfo()
      .pipe(
        switchMap(userInfo => {
          this.ramStorageService.set(StorageKeys.UserInfo, userInfo);
          return this.ramStorageService.get(StorageKeys.UserInfo);
        }),
        this.unsubscribeOperator,
      )
      .subscribe(userInfo => {
        this.userProfile = userInfo;
        this.dataLoaded = true;
        this.parseDataProfile();
        this.closeModal();
      });
  }

  public getParentTestResults(chosenChild: IChildInfo): void {
    this.overlayBusyService.show();
    this.apiProfilesService
      .getSharedResults(this.userProfile.userId)
      .pipe(
        switchMap((sharedResults: any) => {
          return this.apiPlayerService.regionTestInfo(this.userProfile.regionId).pipe(
            takeUntil(this.unsubscribe),
            map((testInfo: ITestInfo) => {
              return sharedResults.filter(
                (result: any) => result.refferalUserId === chosenChild.userId && result.screeningTestId === testInfo.screeningTestId,
              );
            }),
          );
        }),
        switchMap((testSessions: any) => {
          if (testSessions.length) {
            return this.apiPlayerService.getSession(testSessions[0].sessionId);
          } else {
            return of(null);
          }
        }),
        switchMap((result: any): Observable<IParentTestInfo> => {
          return of(
            result
              ? {
                  isParentCompleteTest: result.completed,
                  parentTestSession: result.sessionId,
                  isParentFromPskov: this.userProfile.regionId === '2beb4790-b106-4de9-bf31-7a87d2dbab5b',
                }
              : {
                  isParentCompleteTest: false,
                  parentTestSession: '',
                  isParentFromPskov: this.userProfile.regionId === '2beb4790-b106-4de9-bf31-7a87d2dbab5b',
                },
          );
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((result: IParentTestInfo) => {
        const childWithParentTestResults: IChildInfo = Object.assign({}, chosenChild);
        childWithParentTestResults.parentTestInfo = result;
        this.chooseChild(childWithParentTestResults);
        this.overlayBusyService.hide();
      });
  }

  public parseDataProfile(): void {
    this.childrenRegister = this.userProfile.children.filter(el => el.id != EmptyGuid);
    if (this.userProfile.children && this.userProfile.children.length) {
      this.selectedChild = this.userProfile.children[0];
    }
    !this.userProfile.children.length ? this.showAddLinkPopup(true) : null;
    this.dataLoaded = true;
  }

  public showAddLinkPopup(isVisibleModal: boolean): void {
    this.modalHandlerService.setStaticBody();
    this.linkPopupShow = isVisibleModal;
  }

  public confirmClass(event: IRegDataPupil): void {
    this.regDataPupil = event;
    this.getClassAndSchool(this.regDataPupil.schoolId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.showAddLinkPopup(false);
        this.linkChecked = true;
      });
  }

  public confirmAdding(confirm: boolean): void {
    if (!confirm) {
      this.showAddLinkPopup(true);
      this.linkChecked = false;
      return;
    }

    this.linkChecked = false;
    this.addChildPopup = true;
  }

  public setMale(): void {
    this.genderChoice = Gender.Male;
    this.isFemale = false;
    this.isMale = true;
  }

  public setFemale(): void {
    this.genderChoice = Gender.Female;
    this.isFemale = true;
    this.isMale = false;
  }

  public get isAccessAllowed(): boolean {
    return this.addChildForm.valid && this.personalTerms;
  }

  private set selectedChild(child: IChildInfo) {
    if (this.userProfile.regionId === '2beb4790-b106-4de9-bf31-7a87d2dbab5b' && Object.keys(this._selectedChild).length) {
      this.getParentTestResults(child);
    } else {
      const childWithoutParentTestInfo: IChildInfo = Object.assign({}, child);
      childWithoutParentTestInfo.parentTestInfo = this.defaultParentTestInfoChild;

      this.chooseChild(childWithoutParentTestInfo);
    }
  }

  private chooseChild(child: IChildInfo): void {
    this._selectedChild = child;
    this.favoriteProfessions = [];
    this.loadFavoritesProfessions(this._selectedChild.userId);
    this.sharedResults();
  }

  private initFields(): void {
    const addChildPopupShouldBeOpened: boolean =
      this.webStorageService.get(StorageKeys.AddChildPopupShouldBeOpened) === 'true' ||
      this.webStorageService.get(StorageKeys.FirstNameChild) !== null;

    this.webStorageService.clear(StorageKeys.AddChildPopupShouldBeOpened);

    if (!addChildPopupShouldBeOpened) {
      return;
    }

    this.newChildFirstName = this.webStorageService.get(StorageKeys.FirstNameChild) ?? '';
    this.newChildLastName = this.webStorageService.get(StorageKeys.LastNameChild) ?? '';
    this.newChildBirthdate = this.webStorageService.get(StorageKeys.BirthdayChild) ?? '';
    this.newChildPhoneNumber = this.webStorageService.get(StorageKeys.PhoneChild) ?? '';
    this.genderChoice = this.webStorageService.get(StorageKeys.GenderChild) ?? '';
    this.genderChoice === 'F' ? this.setFemale() : this.setMale();

    this.showAddLinkPopup(true);
  }

  private loadFavoritesProfessions(userId: string = null): void {
    this.apiFavoritesService
      .getFavorites(userId, ProductTypes.Profession)
      .pipe(
        switchMap(favorites => {
          if (favorites.length) {
            const favoritesProfessionsIds: string[] = favorites.map(el => el.productId);
            return this.apiProfessionsService.getElasticProfessionsByFilters({ ids: favoritesProfessionsIds, isVisible: true }).pipe(
              tap(favoriteProfessions => {
                this.favoriteProfessions = favoriteProfessions;
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private getClassAndSchool(schoolId: string): Observable<any> {
    return this.getSchoolClassBySchool(schoolId).pipe(
      switchMap(() => {
        return this.apiScoolsService.getSchoolById(this.regDataPupil.schoolId);
      }),
      tap(school => {
        this.currentSchoolName = school.number;
        this.currentCityName = school.city;
      }),
      takeUntil(this.unsubscribe),
    );
  }

  private getSchoolClassBySchool(id: string): Observable<any> {
    return this.b2gSaasService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        const currentClassId: string = this.regDataPupil.classId;
        classes.filter(schoolClass => {
          if (schoolClass.id === currentClassId) {
            this.currentClass = {
              classNumber: schoolClass.number,
              classLetter: schoolClass.letter,
              classId: schoolClass.id,
            };
          }
        });
      }),
    );
  }

  private getTranslation(keys: string[], interpolateParams: Object): Observable<any> {
    return this.translateService.get(keys, interpolateParams);
  }

  public closeModal(): void {
    this.linkChecked = false;
    this.linkPopupShow = false;
    this.addChildPopup = false;
    this.modalHandlerService.removeStaticBody();
  }
}
