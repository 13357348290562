import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { InternshipCardNewComponent } from './internship-card-new/internship-card-new.component';
import { InternshipCardsComponent } from './internship-cards.component';
import { InternshipCatalogComponent } from './internship-catalog/internship-catalog.component';
import { InternshipFilterComponent } from './internship-catalog/internship-filter/internship-filter.component';
import {
  InternshipGroupFilterComponent,
} from './internship-catalog/internship-filter/internship-group-filter/internship-group-filter.component';
import { InternshipsFiltersService } from './internship-catalog/internship-filter/internships-filter.service';
import { InternshipSearchComponent } from './internship-catalog/internship-search/internship-search.component';
import { InternshipContentLoaderComponent } from './internship-content-loader/internship-content-loader.component';
import { InternshipDetailsComponent } from './internship-details/internship-details.component';
import { InternshipRecommendationsComponent } from './internship-recommendations/internship-recommendations.component';

@NgModule({
  imports: [RouterModule, SharedModule],
  declarations: [
    InternshipCardsComponent,
    InternshipCatalogComponent,
    InternshipDetailsComponent,
    InternshipFilterComponent,
    InternshipSearchComponent,
    InternshipContentLoaderComponent,
    InternshipRecommendationsComponent,
    InternshipCardNewComponent,
    InternshipGroupFilterComponent,
  ],
  providers: [InternshipsFiltersService],
})
export class InternshipsModule {}
