import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { CatalogModule } from 'app/pages/catalog/catalog.module';
import { TeacherAddStudentsComponent } from './teacher-add-students/teacher-add-students.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { TeacherPanelComponent } from './teacher-panel.component';
import { TeacherPanelService } from './teacher-panel.service';
import { TeacherRoutingModule } from './teacher-routing.module';
import { TeacherWelcomeComponent } from './teacher-welcome/teacher-welcome.component';
import { TeacherShareBlockComponent } from './teacher-share-block/teacher-share-block.component';
import { TeacherStartPageComponent } from './teacher-start-page/teacher-start-page.component';
import { TeacherClassListComponent } from './teacher-class-list/teacher-class-list.component';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import {
  DownloadPupilsAccessesModule,
} from 'app/shared/common-components/download-pupils-accesses/download-pupils-accesses.module';
import {
  CopyPupilsInvitationModule,
} from 'app/shared/common-components/copy-pupils-invitation/copy-pupils-invitation.module';
import { PupilsTableModule } from 'app/shared/common-components/pupils-table/pupils-table.module';
import { TestBreakpointsModule } from 'app/shared/common-components/tests/test-breakpoints/test-breakpoints.module';
import {
  TeacherWithNoClassesBannerModule,
} from 'app/shared/common-components/teacher-with-no-classes-banner/teacher-with-no-classes-banner.module';
import { TeacherWelcomePopupComponent } from './teacher-welcome-popup/teacher-welcome-popup.component';
import { TeacherGroupCardComponent } from './teacher-group-card/teacher-group-card.component';
import {
  TeacherGroupsBannerComponent,
} from '../../../shared/common-components/teacher-groups-banner/teacher-groups-banner.component';
import { TeacherClassCardComponent } from './teacher-class-card/teacher-class-card.component';
import { CourseMaterialsModule } from '../../../shared/common-components/course-materials/course-materials.module';
import { ButtonComponent } from '@profilum-components/button/button.component';
import { TippyDirective } from '@ngneat/helipopper';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import {
  RestrictionBannerComponent,
} from '../../../shared/common-components/restriction-banner/restriction-banner.component';
import { SuspensionMessageDirective } from '../../../shared/directives/suspension-message/suspension-message.directive';

@NgModule({
  declarations: [
    TeacherPanelComponent,
    TeacherDashboardComponent,
    TeacherWelcomeComponent,
    TeacherAddStudentsComponent,
    TeacherShareBlockComponent,
    TeacherStartPageComponent,
    TeacherClassListComponent,
  ],
  providers: [TeacherPanelService, TeacherClassCoursesService],
  imports: [
    CommonModule,
    TeacherRoutingModule,
    CatalogModule,
    SharedModule,
    DownloadPupilsAccessesModule,
    CopyPupilsInvitationModule,
    PupilsTableModule,
    TestBreakpointsModule,
    TeacherWithNoClassesBannerModule,
    TeacherWelcomePopupComponent,
    TeacherGroupCardComponent,
    TeacherGroupsBannerComponent,
    NgOptimizedImage,
    CourseMaterialsModule,
    ButtonComponent,
    TippyDirective,
    TooltipComponent,
    TeacherClassCardComponent,
    RestrictionBannerComponent,
    SuspensionMessageDirective,
  ],
})
export class TeacherModule {}
