<mat-card class="circle">
  <ng-container *ngIf="!isEmpty; else empty">
    <circle-progress
      [percent]="percents"
      [backgroundStrokeWidth]="16"
      [backgroundPadding]="0"
      [radius]="95"
      [space]="-6"
      [maxPercent]="maxPercents"
      [showSubtitle]="false"
      [outerStrokeWidth]="35"
      [outerStrokeColor]="color"
      [innerStrokeColor]="color"
      [titleColor]="color"
      [unitsColor]="color"
      [outerStrokeLinecap]="'inherit'"
      [innerStrokeWidth]="6"
      [animationDuration]="200"
      [clockwise]="false"
      [responsive]="true"
    ></circle-progress>
  </ng-container>
  <span>{{ name }}</span>
  <ng-template #empty>
    <span class="empty-message">{{ 'ALERTS.NOT_RESEARCH' | translate }}</span>
  </ng-template>
</mat-card>

<!-- <div class="data-container">
  <div class="data-list-item">
    <div class="item-value">20%</div>
    <div class="item-pictogram">
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 6L7 1L1 6"
          stroke="#FF5722"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="8"
          y="17"
          width="2"
          height="15"
          rx="1"
          transform="rotate(-180 8 17)"
          fill="#FF5722"
        />
      </svg>
    </div>
  </div>
</div> -->
