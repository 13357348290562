<prf-menu [root]="userRole"></prf-menu>

<div class="content-section">
  <div class="container container-motiv-reslts">
    <prf-page-header [title]="'Результаты теста'"></prf-page-header>

    <div class="professions-section">
      <prf-motivation-results [userRole]="userRole" [pupil]="pupil" [sessionId]="urlSessionId"></prf-motivation-results>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>
