import { IProfession } from '@profilum-library';

export enum TabStatus {
  ACTIVE,
  DISABLED,
  ENABLED,
}

export interface ITab {
  amount: number;
  counter: number;
  status: TabStatus;
}

export interface IProcessedProfession extends IProfession {
  processed?: boolean;
  favourite?: boolean;
  state?: 'default' | 'like' | 'dislike';
}

export interface ITaskActivityResponse {
  chooseProfessionsFromFieldTaskActivity: ITaskActivity;
  status?: string;
  comment?: string;
  senderService?: string;
}

export interface ITaskActivity {
  courseId: string;
  lessonId: string;
  taskId: string;
  userId: string;
  processedProfessions: [];
}

export interface IProfessionsTaskActivity {
  courseId: string;
  lessonId: string;
  taskId: string;
  userId: string;
  processedProfessions: IProcessedProfessions;
}

export type IProcessedProfessions = Record<string, boolean>;

export interface IProfessionCard {
  title: string;
  image: string;
  content: string;
  isFavourite: boolean;
}
