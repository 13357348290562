<div class="container">
  <div class="profile-section" *ngIf="company">
    <a [routerLink]="['/employer/edit-company', company.id]" class="button edit-event-page-btn w-button">{{
      'SHARED.EDIT_BUTTON' | translate
    }}</a>

    <div class="profile-row-2">
      <div class="user-name-profile">
        {{ company.name }}
      </div>
    </div>

    <a href="{{ company.websiteLink }}" target="_blank">{{ company.websiteLink }}</a>

    <div
      class="company-logo"
      [ngStyle]="{
        'background-image': company.logoUrl ? 'url(' + company.logoUrl + ')' : ''
      }"
    ></div>

    <div class="line"></div>

    <div class="data-row">
      <h4 class="data-label">Адрес</h4>
      <div class="profile-data-text">{{ company.address }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Контакты</h4>
      <div class="profile-data-text">{{ company.phone }}</div>
      <div class="profile-data-text">{{ company.email }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Привязка к сфере</h4>
      <div class="profile-data-text">{{ company.fieldNames }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Привязка к профессии</h4>
      <div class="profile-data-text">{{ companyProfessionNames }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Ценности и миссия компании, описание деятельности компании</h4>
      <div class="profile-data-text">{{ company.companyDescription }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Количество сотрудников</h4>
      <div class="profile-data-text">{{ company.employeesCount }}</div>
    </div>

    <div class="data-row">
      <h4 class="data-label">Ссылки</h4>
      <div class="profile-data-text">
        Сайт работодателя:
        <a href="{{ company.websiteLink }}" target="_blank">{{ company.websiteLink }}</a>
      </div>

      <div *ngIf="company.yandexZenLink" class="profile-data-text">
        Блог на дзене:
        <a href="{{ company.yandexZenLink }}" target="_blank">{{ company.yandexZenLink }}</a>
      </div>

      <div *ngIf="company.news" class="profile-data-text">Новости: {{ company.news }}</div>
    </div>

    <div class="data-row">
      <a *ngIf="company.youtubeLink" href="{{ company.youtubeLink }}">
        <img src="./profilum-assets/images/icons/social/youtube-circle.png" class="social-link" />
      </a>

      <a *ngIf="company.okLink" href="{{ company.okLink }}">
        <img src="./profilum-assets/images/icons/social/ok-circle.png" class="social-link" />
      </a>

      <a *ngIf="company.facebookLink" href="{{ company.facebookLink }}">
        <img src="./profilum-assets/images/icons/social/fb-circle.png" class="social-link" />
      </a>

      <a *ngIf="company.instagramLink" href="{{ company.instagramLink }}">
        <img src="./profilum-assets/images/icons/social/instagram-circle.png" class="social-link" />
      </a>

      <a *ngIf="company.vkontakteLink" href="{{ company.vkontakteLink }}">
        <img src="./profilum-assets/images/icons/social/vk-circle.png" class="social-link" />
      </a>
    </div>
  </div>
</div>
