import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetButtonComponent } from 'app/shared/common-components/prf-form/reset-button/reset-button.component';

@NgModule({
  declarations: [ResetButtonComponent],
  imports: [CommonModule],
  exports: [ResetButtonComponent],
})
export class ResetButtonModule {}
