<div
  class="prof-tab"
  [class.prof-tab_active]="isTabActive"
  [class.prof-tab_enabled]="isTabEnabled"
  [class.prof-tab_disabled]="isTabDisabled"
  [class.prof-tab_completed]="isTabCompleted"
  (click)="onClick()"
>
  <span class="prof-tab__text" [class.prof-tab__text__one-tab]="hasOneTab">{{ tab.key }}</span>
  <ng-container *ngIf="!isTabDisabled">
    <span class="prof-tab__counter" [class.prof-tab__counter_completed]="isTabCompleted">
      {{ counter }}
    </span>
  </ng-container>
</div>
