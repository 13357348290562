<ng-container *ngIf="materials?.sections?.length; else noMaterialsTemplate">
  <prf-notification-course-materials
    *ngIf="!isNotificationHided"
    [text]="notificationText"
    (closeNotificationEmitter)="hideNotification()"
  ></prf-notification-course-materials>

  <prf-course-material-section
    *ngFor="let courseMaterialSectionData of materials.sections"
    [theme]="theme"
    [courseMaterialSectionData]="courseMaterialSectionData"
    (copyLinkEmitter)="copyLinkToMaterial($event)"
  ></prf-course-material-section>
</ng-container>

<ng-template #noMaterialsTemplate>
  <div class="no-materials-block">
    <img ngSrc="./assets/images/control-panel/teacher/no-materials.svg" class="image" width="200" height="145" alt="no materials image" />
    <p class="text">{{ noMaterialsText }}</p>
  </div>
</ng-template>

<div class="success-copy-link" [ngClass]="{ active: isLinkCopied }">
  <img class="icon" src="./profilum-assets/images/common/passed-task.svg" alt="" />
  <span class="text">{{ successLinkCopied }}</span>
</div>
