import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Helper } from '@profilum-library';

@Component({
  selector: 'prf-support-text',
  templateUrl: './support-text.component.html',
  styleUrls: ['./support-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportTextComponent {
  public openOnlineConsultantWidget(event: Event): void {
    Helper.openOnlineConsultantWidget(event);
  }
}
