import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-universities',
  templateUrl: './universities.component.html',
  styleUrls: ['./universities.component.scss'],
})
export class UniversitiesComponent implements OnInit {
  public tabLevel: number = 1;

  adminLevel: string = null;

  constructor(private meta: Meta, private router: Router, private webStorageService: WebStorageService) {
    this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    this.meta.updateTag({ name: 'og:title', content: 'Страница ВУЗов' });
  }

  ngOnInit() {}
}
