<div id="childInfoForm" class="login-section">
  <div class="login-block">
    <div class="reg-form">
      <form [formGroup]="form" name="wf-form-Login" autocomplete="off">
        <div class="reg-form_child">
          <div class="login-form-block">
            <div class="login-input-container">
              <label [class.topped-out]="classLabel" for="class" class="login-label" [class.error-field]="validField(formControls.class)">
                Класс*
              </label>

              <div class="input-wrapper">
                <input
                  class="login-input w-input"
                  [class.error-field]="validField(formControls.class)"
                  [class.fill-field]="formControls.class.touched && formControls.class.valid"
                  id="class"
                  type="number"
                  min="1"
                  max="11"
                  autocomplete="off"
                  prfNoAutocomplete
                  formControlName="class"
                  (focusin)="classLabel = true"
                  (focusout)="form.get('class').value ? null : (classLabel = false)"
                />
              </div>

              <mat-error *ngIf="formControls.class.hasError('required')">{{ 'SHARED.FORM.FILL_INPUT' | translate }}</mat-error>
            </div>

            <div class="login-input-container _gender">
              <label class="login-label_gender">Пол</label>
              <div class="gender-choice">
                <div class="gender-choice-row">
                  <a class="gender-button active" (click)="setMale()" [class.active]="isMale"> М </a>
                  <a class="gender-button right" (click)="setFemale()" [class.active]="isFemale"> Ж </a>
                </div>
              </div>
            </div>
            <mat-error *ngIf="formControls.gender.hasError('required')">Выберите пол</mat-error>

            <div class="login-input-container">
              <label [class.topped-out]="cityLabel" for="city" class="login-label" [class.error-field]="validField(formControls.city)">
                Город*
              </label>

              <div class="input-wrapper">
                <input
                  class="login-input w-input"
                  [class.error-field]="validField(formControls.city)"
                  [class.fill-field]="formControls.city.touched && formControls.city.valid"
                  id="city"
                  type="text"
                  autocomplete="off"
                  prfNoAutocomplete
                  formControlName="city"
                  (focusin)="cityLabel = true"
                  (focusout)="form.get('city').value ? null : (cityLabel = false)"
                />
              </div>

              <mat-error *ngIf="formControls.city.hasError('required')">{{ 'SHARED.FORM.FILL_INPUT' | translate }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
