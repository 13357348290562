import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prf-static-notification',
  templateUrl: './static-notification.component.html',
  styleUrls: ['./static-notification.component.scss'],
})
export class StaticNotificationComponent {
  @Input()
  public title!: string;
  @Input()
  public text!: string;
  @Input()
  public buttonText!: string;
  @Input()
  public additionalText?: string;
  @Input()
  public subtitle?: string;
  @Input()
  public tooltipText?: string = '';
  @Input()
  public tooltipTimer?: number = 3000;

  @Output()
  public buttonClick: EventEmitter<any> = new EventEmitter<any>();

  public showTooltip: boolean = false;

  public onButtonCLick(): void {
    this.buttonClick.emit();

    if (this.tooltipText) {
      this.showTooltip = true;
      setTimeout(() => (this.showTooltip = false), this.tooltipTimer);
    }
  }
}
