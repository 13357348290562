import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  TeachersJournalService,
} from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import { AppSettingsService, IPassedLessons, UserDataHandlerService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import {
  ILessonResultsCount,
  IMappedLessonData,
  IMappedLessonWithCourseData,
  IPassedLessonData,
} from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { ResultsButtons } from 'app/pages/results/result-page/page-result.component';
import { LessonStatus } from '../collections/collections';
import { RootScopeService } from '../../../services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { TooltipPosition } from '../../../common-components/tooltip-text/tooltip-text.component';

interface ITooltipDataState {
  show: boolean;
  index: number;
}

@Component({
  selector: 'prf-teachers-journal',
  templateUrl: './teachers-journal.component.html',
  styleUrls: ['./teachers-journal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeachersJournalComponent extends UnsubscribeComponent {
  public showJournal: boolean = false;
  public mappedLessons: IMappedLessonData[];
  public showArrows: boolean = false;
  public minIndex: number = 0;
  public maxIndex: number = 0;
  public maxPossibleIndex: number = 0;
  public showActiveLeftArrow: boolean = false;
  public showActiveRightArrow: boolean = false;
  public tooltipDataState: ITooltipDataState = {
    show: false,
    index: null,
  };
  public lessonResultsCount: ILessonResultsCount;
  public passedLessons: IPassedLessons[];
  public tooltipWidth: number = 236;
  public tooltipPosition = TooltipPosition;
  public dictionary: DictionaryType;
  public isUserFromBashkortostan: boolean = false;
  private readonly countOfLessonsInOnePage: number = 11;
  private readonly countOfLessonsInOneStep: number = 5;
  protected readonly AppSettingsService = AppSettingsService;
  @Output() goToResults = new EventEmitter<[ResultsButtons, string]>();

  @Input('lessons') set lessons(lessons: IMappedLessonWithCourseData) {
    if (lessons) {
      this.loadLessonsData(lessons);
    }
  }

  constructor(
    private teachersJournalService: TeachersJournalService,
    private rootScopeService: RootScopeService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.maxIndex = this.countOfLessonsInOnePage - 1;
    this.isUserFromBashkortostan =
      this.userDataHandlerService.getUserInfo().regionId === AppSettingsService.settings.bashkortostanClosedLesson.regionId;
  }

  public scrollRight(): void {
    if (this.showActiveRightArrow) {
      this.maxIndex = this.maxIndex + this.countOfLessonsInOneStep;
      this.minIndex = this.minIndex + this.countOfLessonsInOneStep;

      if (this.maxIndex > this.maxPossibleIndex) {
        const diff = this.maxIndex - this.maxPossibleIndex;

        this.maxIndex = this.maxPossibleIndex;
        this.minIndex = this.minIndex - diff;
      }

      this.showActiveRightArrow = this.maxIndex < this.maxPossibleIndex;
      this.showActiveLeftArrow = true;
    }
  }

  public scrollLeft(): void {
    if (this.showActiveLeftArrow) {
      this.maxIndex = this.maxIndex - this.countOfLessonsInOneStep;
      this.minIndex = this.minIndex - this.countOfLessonsInOneStep;

      if (this.minIndex < 0) {
        const diff = 0 - this.minIndex;

        this.maxIndex = this.maxIndex + diff;
        this.minIndex = 0;
      }

      this.showActiveLeftArrow = this.minIndex > 0;
      this.showActiveRightArrow = true;
    }
  }

  public showTooltip(index: number): void {
    this.tooltipDataState.show = true;
    this.tooltipDataState.index = index;
  }

  public hideTooltip(): void {
    this.tooltipDataState.show = false;
    this.tooltipDataState.index = null;
  }

  public openResults(lessonData: IPassedLessonData): void {
    if (this.lessonResultsCount[lessonData?.lesson?.lessonMaterialId]) {
      lessonData.isDiagnostic
        ? this.goToResults.next([ResultsButtons.Diagnostics, ''])
        : this.goToResults.next([ResultsButtons.Choices, lessonData?.lesson?.tasks[0]?.chooseProfessionsFromFieldTask?.fieldId]);
    }
  }

  public getLessonStatus(lessonData: IPassedLessonData): LessonStatus {
    if (!lessonData.tasksExist) {
      return LessonStatus.NO_TASK;
    }
    const lessonMaterialId = lessonData?.lesson?.lessonMaterialId;
    const pupilCount = this.mappedLessons.length;
    const lessonPassedCounts = this.lessonResultsCount[lessonMaterialId];
    const passingInfo = this.passedLessons.find(lesson => lesson.id === lessonMaterialId);
    if (lessonPassedCounts) {
      for (const lessonPassedCount of lessonPassedCounts) {
        if (lessonPassedCount === pupilCount) {
          return LessonStatus.COMPLETE;
        } else if (lessonPassedCount < pupilCount && lessonPassedCount > 0) {
          return LessonStatus.IN_PROGRESS;
        } else if (passingInfo?.isPassed) {
          return LessonStatus.GIVEN_OUT;
        } else {
          return LessonStatus.NOT_GIVEN_OUT;
        }
      }
    }
  }

  public getLessonStatusText(lessonData: IPassedLessonData): string {
    const status = this.getLessonStatus(lessonData);

    switch (status) {
      case LessonStatus.NO_TASK:
        return '';
      case LessonStatus.NOT_GIVEN_OUT:
        return 'не выдано';
      case LessonStatus.GIVEN_OUT:
        return 'выдано';
      case LessonStatus.IN_PROGRESS:
        return 'выполняется';
      case LessonStatus.COMPLETE:
        return 'выполнено';
      default:
        break;
    }
  }

  private loadLessonsData(lessons: IMappedLessonWithCourseData) {
    if (!lessons?.mappedLessonData?.length) return;
    this.mappedLessons = lessons.mappedLessonData;
    const lessonsCount = this.mappedLessons[0].lessonData.length;

    this.maxPossibleIndex = lessonsCount - 1;
    this.showArrows = this.showActiveRightArrow = lessonsCount > this.countOfLessonsInOnePage;
    this.showJournal = true;

    this.teachersJournalService.lessonResultsCount$.pipe(takeUntil(this.unsubscribe)).subscribe(lessonResultsCount => {
      this.lessonResultsCount = lessonResultsCount;
      this.passedLessons = lessons.course.passedLessons;
    });
  }
}
