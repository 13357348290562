import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChildInfoFormComponent } from 'app/pages/player/camp-test/child-info-form/child-info-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import {
  DropdownWithFilterModule,
} from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.module';

@NgModule({
  declarations: [ChildInfoFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, DropdownWithFilterModule],
  exports: [ChildInfoFormComponent],
})
export class CampTestModule {}
