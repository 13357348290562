<div class="prf-login-page">
  <div *ngIf="isKruzhkiPage" class="partners-logo hide-mobile">
    <a class="logo" href="https://www.mos.ru/" target="_blank">
      <img src="./assets/images/registration/depkult_gerb.svg" alt="logo" />
    </a>
    <a class="logo" href="https://mos-razvitie.ru/" target="_blank">
      <img src="./assets/images/registration/mosrazvitie.svg" alt="logo" />
    </a>
  </div>
  <div class="prf-login" [class.captcha-frame]="showCaptchaFrame">
    <ng-container *ngIf="showProfilumLogo(); then profilumWelcome; else kruzhkiWelcome"></ng-container>

    <ng-template [ngIf]="!showCaptchaFrame" [ngIfElse]="captchaFrame">
      <form [formGroup]="form" (keyup.enter)="onClickLogin()" autocomplete="off">
        <div class="login-form-row prf-login-form-row">
          <prf-dynamic-label [prfFormControl]="loginForm.login" [prfFormControlElement]="login">
            {{ isKruzhkiPage ? ('SHARED.FORM.EMAIL' | translate) : ('SHARED.FORM.PHONE_OR_EMAIL' | translate) }}
          </prf-dynamic-label>
          <div class="login-field-column prf-login-field-column">
            <input
              type="text"
              autocomplete="false"
              class="prf-form-input prf-login-form-input"
              maxlength="70"
              name="login"
              data-name="login"
              id="login"
              [class.error-field]="!loginForm.login['focused'] && isLoginInvalid"
              (focusin)="loginForm.login['focused'] = true"
              (focusout)="checkLogin()"
              (ngModelChange)="passFailed = false"
              formControlName="login"
              #login
            />
            <ng-container *ngIf="loginForm.login.touched && loginForm.login.errors">
              <mat-error class="prf-login-error">
                @switch (true) {
                  @case (loginForm.login.errors.required) {
                    {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                  }
                  @case (loginForm.login.errors.pattern) {
                    Проверьте написание
                  }
                  @case (loginForm.login.errors.isNotUsed) {
                    {{ 'SHARED.FORM.PHONE_IS_NOT_REGISTERED' | translate }}
                  }
                  @case (loginForm.login.errors.emailIsNotUsed) {
                    {{ 'SHARED.FORM.EMAIL_IS_NOT_REGISTERED' | translate }}
                  }
                }
              </mat-error>
            </ng-container>
          </div>
        </div>
        <div class="login-form-row prf-login-form-row">
          <prf-dynamic-label [prfFormControl]="loginForm.password" [prfFormControlElement]="password">
            {{ 'SHARED.FORM.PASSWORD' | translate }}
          </prf-dynamic-label>
          <div class="login-field-column prf-login-field-column">
            <input
              type="password"
              autocomplete="false"
              class="prf-form-input prf-login-form-input"
              maxlength="25"
              name="password"
              data-name="password"
              id="password"
              [class.error-field]="passFailed"
              (focusin)="loginForm.password['focused'] = true"
              (focusout)="loginForm.password['focused'] = false"
              (ngModelChange)="passFailed = false"
              formControlName="password"
              #password
            />
            <div class="eye" (click)="showPassword($event)"></div>
            <div class="prf-login-error-box">
              <mat-error *ngIf="passFailed" class="prf-login-error"> Неверный пароль </mat-error>
              <div class="prf-login-forgot-password">
                <a class="recovery-link" [routerLink]="['/recovery_password']" [routerLinkActive]="['w--current']"> Не помню пароль </a>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="prf-login-frame">
        <button
          type="button"
          class="prf-btn-primary"
          [class.prf-btn-primary_disabled]="isAccessDenied || isAttemptsExceeded() || passFailed"
          [disabled]="isAccessDenied || isAttemptsExceeded() || passFailed"
          (click)="onClickLogin()"
        >
          Войти
        </button>
        <button *ngIf="!isKruzhkiPage" type="button" class="prf-btn-link" (click)="openWidget($event)">Не получается войти</button>
      </div>
    </ng-template>
    <ng-template #captchaFrame>
      <div class="prf-login-captcha">
        <p class="prf-login-captcha-title">Пожалуйста, подтвердите, что вы не робот</p>
        <p class="prf-login-captcha-text">Запросы с вашего устройства похожи на автоматические. Введите код с картинки.</p>
        <prf-captcha [showCaptcha]="true" (isValid)="continueToLogin($event)"></prf-captcha>
      </div>
    </ng-template>
  </div>
  <span *ngIf="isKruzhkiPage" class="prf-login-buttons-footer">
    Проводится на базе платформы
    <a [routerLink]="['/']" target="_blank">
      <img src="./profilum-assets/images/registration/profilum_logo_small.svg" alt="PROFILUM" />
    </a>
  </span>
  <prf-page-footer class="prf-footer"></prf-page-footer>
</div>

<ng-template #profilumWelcome>
  <a href="https://profilum.ru" target="_blank" class="prf-login-logo">
    <img src="./profilum-assets/images/registration/profilum_logo_big.svg" alt="PROFILUM" />
  </a>
</ng-template>

<ng-template #kruzhkiWelcome>
  <h2 *ngIf="!showCaptchaFrame" class="kruzki-text__header">
    <span class="kruzki-text__paragraph">Кружок:</span>
    «Творчество как профессия»
  </h2>
</ng-template>
