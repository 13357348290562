import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'prf-success-link-popup',
  templateUrl: './success-link-popup.component.html',
  styleUrls: ['./success-link-popup.component.scss'],
})
export class SuccessLinkPopupComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Output() confirmClass = new EventEmitter();

  ngOnInit(): void {}

  confirm(isConfim: boolean) {
    this.confirmClass.emit(isConfim);
  }

  closePopup() {
    this.closeModal.emit();
  }
}
