import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import {
  ApiInternshipsService,
  EmptyGuid,
  IFilterInternships,
  IInternship,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import {
  InternshipsFiltersService,
} from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-internships-list',
  templateUrl: './internships-list.component.html',
  styleUrls: ['./internships-list.component.scss'],
})
export class InternshipsListComponent implements OnInit {
  @Input() searches;
  @Input() viewValue;
  @Output() countInternships = new EventEmitter<any>();
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  filters: IFilterInternships;
  internship: IInternship;
  internships: any = [];
  dataFetched: boolean = false;
  titleName: string;
  currentInternships: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  loadStopper: boolean = false;

  constructor(
    private overlayService: OverlayBusyService,
    private router: Router,
    private filtersService: InternshipsFiltersService,
    private apiInternshipsService: ApiInternshipsService,
    private webStorageService: WebStorageService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();
    this.filtersService.setInternshipsFilter({});
    this.filtersService.getInternshipsFilters().subscribe(data => {
      this.filters = data;
      // Блок для избежания ошибок с повторными запросами и фильтрами
      this.currentInternships = [];
      this.loadStopper = false;
      this.fromTo.currentPage = 0;

      this.loadInternships();
    });
    this.overlayService.hide();
  }

  loadInternships() {
    const path = this.router.routerState.snapshot.url;
    const filters = Object.assign({}, this.filters);
    this.filtersService.setRegionMunicipalityFilters(filters);
    const internshipsCount = this.apiInternshipsService
      .getInternshipsCount(filters)
      .pipe(take(1))
      .subscribe(data => {
        this.countInternships.emit(data);
      });

    if (!this.loadStopper) {
      filters.from = this.fromTo.currentPage;
      filters.size = this.fromTo.pageSize;
      const subscription = this.apiInternshipsService
        .getFilteredInternships(filters)
        .pipe(take(1))
        .subscribe(data => {
          let internships = data;
          this.loadStopper = !!(internships.length == 0 || internships.length < PAGE_LOAD);

          if (this.url === '/admin/internships/mine-internships') {
            internships = internships.filter(d => d.institutionId === EmptyGuid);
          }
          if (internships) {
            for (const item in internships) {
              if (internships) {
                this.currentInternships.push(internships[item]);
              }
            }
          }
          this.dataFetched = true;
        });
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    // условие на то, что это не список из поиска
    if (this.searches.length == 0) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.loadMore();
        this.cardsToShow += PAGE_SIZE;
      }
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadInternships();
  }
}
