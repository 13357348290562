import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  InternshipsFiltersService,
} from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  ApiLocationsService,
  EmptyGuid,
  IFilterInternships,
  IMunicipality,
  IRegion,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-internships-filter-territory',
  templateUrl: './internships-filter-territory.component.html',
  styleUrls: ['./internships-filter-territory.component.scss'],
})
export class InternshipsFilterTerritoryComponent extends UnsubscribeComponent implements OnInit {
  filters: IFilterInternships = {};
  // types = COURSES_TYPES_DATA;

  //объекты территориальной привязки
  public defaultGuid: string = EmptyGuid;
  public allRegions: IRegion[] = [];
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  userRegionId: string = '';
  userMunicipalityId: string = '';

  dataLoaded: boolean = false;
  adminLevel: any;
  regionDisabled: boolean = false;
  municipalityDisabled: boolean = false;

  municipalityOpen: boolean = false;
  regionOpen: boolean = false;

  regionFilterHeader: string = '';

  classes: any = [];
  selectType: string;
  region: any;
  municipality: any;
  @ViewChild('regionFilter') regionFilter: ElementRef;
  @ViewChild('municipalityFilter') municipalityFilter: ElementRef;

  constructor(
    private filtersService: InternshipsFiltersService,
    private apiLocationsService: ApiLocationsService,
    private webStorageService: WebStorageService,
    private translateService: TranslateService,
  ) {
    super();
    this.getTranslation('SHARED.REGION')
      .pipe(take(1))
      .subscribe(translation => (this.regionFilterHeader = translation));
  }

  ngOnInit() {
    this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    this.loadTerritory();
  }

  setRegionFilter(region: any) {
    if (region != this.selectedRegion) {
      this.selectedRegion = region;
      this.filters.regionId = region.id;
      this.filters.municipalityId = null;
      this.resetMunicipality();

      //записываем дефолтный муниципалитет при выборе дефолтного региона
      if (this.filters.regionId === this.defaultGuid) {
        this.filters.municipalityId = this.defaultGuid;
        this.selectedMunicipality = {
          id: this.defaultGuid,
          hrid: '',
          name: 'Дефолтный муниципалитет',
          regionId: this.defaultGuid,
        };
      } else {
        this.apiLocationsService
          .getMunicipalitiesByRegion(this.selectedRegion.id)
          .pipe(
            takeUntil(this.unsubscribe),
            tap(municipalities => {
              this.municipalitiesByRegion = municipalities;
              this.selectedMunicipality = {
                id: '',
                hrid: '',
                name: '',
                regionId: '',
              };
            }),
          )
          .subscribe();
      }
      this.filtersService.setInternshipsFilter(this.filters);
      this.regionOpen = false;
    } else {
      this.resetRegion();
    }
  }

  setMunicipalityFilter(municipality: any) {
    this.selectedMunicipality = municipality;
    this.filters.municipalityId = municipality.id;
    this.filtersService.setInternshipsFilter(this.filters);
    this.municipalityOpen = false;
  }

  public resetRegion() {
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.selectedMunicipality = { id: '', hrid: '', name: '', regionId: '' };
    this.municipalitiesByRegion = [];
    this.filters.regionId = null;
    this.filters.municipalityId = null;
    this.filtersService.setInternshipsFilter(this.filters);
  }

  public resetMunicipality() {
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.filters.municipalityId = null;
    this.municipalityDisabled = false;
    this.filtersService.setInternshipsFilter(this.filters);
  }

  loadTerritory() {
    this.dataLoaded = false;
    this.userRegionId = this.webStorageService.get(StorageKeys.RegionId);
    this.userMunicipalityId = this.webStorageService.get(StorageKeys.MunicipalityId);

    this.apiLocationsService
      .getAllRegions()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(regions => {
          this.allRegions = regions;

          let currentObservable$: Observable<any> = of(null);

          //todo вероятно, нужны проверки для других ролей: шк. админ, админДО
          switch (this.adminLevel) {
            case AdminAccessLevel.GLOBAL:
              {
                // переименовываем дефолтный регион
                this.allRegions = regions.map(r => {
                  r.id === this.defaultGuid ? (r['name'] = 'Дефолтный регион') : null;
                  return r;
                });
              }
              break;
            case AdminAccessLevel.REGION:
              {
                this.filters.regionId = this.userRegionId;
                this.selectedRegion = regions.find(r => r.id === this.userRegionId);
                this.regionDisabled = true;

                currentObservable$ = this.apiLocationsService.getMunicipalitiesByRegion(this.selectedRegion.id).pipe(
                  tap(municipalities => {
                    this.municipalitiesByRegion = municipalities;
                    this.selectedMunicipality = {
                      id: '',
                      hrid: '',
                      name: '',
                      regionId: '',
                    };
                  }),
                );
              }
              break;
            case AdminAccessLevel.MUNICIPALITY:
              {
                this.filters.regionId = this.userRegionId;
                this.selectedRegion = regions.find(r => r.id === this.userRegionId);
                this.regionDisabled = true;

                currentObservable$ = this.apiLocationsService.getMunicipalitiesByRegion(this.selectedRegion.id).pipe(
                  tap(municipalities => {
                    this.municipalitiesByRegion = municipalities;
                    this.filters.municipalityId = this.userMunicipalityId;
                    this.selectedMunicipality = municipalities.find(r => r.id === this.userMunicipalityId);
                    this.municipalityDisabled = true;
                  }),
                );
              }
              break;

            default:
              currentObservable$ = of(null);
          }
          return currentObservable$;
        }),
      )
      .subscribe(_ => (this.dataLoaded = true));
  }

  public checkRegionFilterHeader() {
    if (this.selectedRegion && this.selectedRegion.name) {
      return this.selectedRegion.name;
    } else {
      return this.regionFilterHeader;
    }
  }

  public checkMunicipalityFilterHeader() {
    if (this.selectedMunicipality && this.selectedMunicipality.name) {
      return this.selectedMunicipality.name;
    } else {
      return 'Муниципалитет';
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.regionFilter && !this.regionFilter?.nativeElement.contains(target)) {
      this.regionOpen = false;
    }
    if (this.municipalityFilter && !this.municipalityFilter?.nativeElement.contains(target)) {
      this.municipalityOpen = false;
    }
  }
}
