import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { NgZoneService } from '@profilum-logic-services/ng-zone-service/ng-zone-service.service';

import { UtilsService } from '../../../shared/dashboard/backend-services/utils.service';
import { ICropperSettings } from './image-cropper-v2.interface';
import { BreakpointsComponent } from '../../../shared/common-components/breakpoints/breakpoints.component';

@Component({
  selector: 'prf-image-cropper-v2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-cropper-v2.component.html',
  styleUrls: ['./image-cropper-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperV2Component extends BreakpointsComponent implements AfterViewInit, OnDestroy {
  @Input() public diameter: number = 150;
  @Input() public cropImageSubject: Observable<boolean>;
  @Output() public incorrectImage: EventEmitter<string> = new EventEmitter<string>();
  @Output() public croppedImage: EventEmitter<FileList> = new EventEmitter<FileList>();
  @ViewChild('cropperContainer') public cropperContainer: ElementRef;
  @ViewChild('cropperFrame') public cropperFrame: ElementRef;
  public readonly reader: FileReader = new FileReader();
  private image: HTMLImageElement;
  private cropperSettings: ICropperSettings;
  @Input() public set uploadedImage(uploadedImage: FileList) {
    if (uploadedImage) {
      this.loadImage(uploadedImage);
    }
  }

  constructor(
    private utilsService: UtilsService,
    protected breakpointsService: BreakpointsService,
    protected changeDetectorRef: ChangeDetectorRef,
    private ngZoneService: NgZoneService,
  ) {
    super(changeDetectorRef, breakpointsService);
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (!this.cropperContainer?.nativeElement || !this.cropperFrame?.nativeElement) {
      return;
    }

    this.cropImageSubject?.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.cropImage());

    this.reader.onload = (e: ProgressEvent<FileReader>): void => {
      this.image = document.createElement('img');
      this.image.src = e.target.result as string;
      (this.cropperContainer.nativeElement as HTMLElement).appendChild(this.image);
      (this.cropperSettings as any) = { frame: { radius: this.diameter / 2 } };

      this.image.onload = (): void => {
        if (this.image.naturalWidth < this.diameter || this.image.naturalHeight < this.diameter) {
          const errorMessage: string = `Размер аватара должен быть не менее ${this.diameter} на ${this.diameter}`;

          this.utilsService.openSnackBar(errorMessage, 'error');
          this.incorrectImage.emit(errorMessage);

          return;
        }

        this.breakpointsService.getWindowSize.pipe(takeUntil(this.unsubscribe)).subscribe({
          next: () => {
            this.alignImage();
            this.alignFrame();
          },
          error: (error: any) => {
            console.error(error);
          },
        });
      };
    };
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    const frame: HTMLElement = this.cropperFrame.nativeElement;
    const cropper: HTMLElement = this.cropperContainer.nativeElement;

    frame.removeEventListener('mousedown', this.onMouseDown);
    frame.removeEventListener('touchstart', this.onMouseDown);
    cropper.removeEventListener('mouseup', this.onMouseUp);
    cropper.removeEventListener('touchend', this.onMouseUp);
    cropper.removeEventListener('mouseleave', this.onMouseUp);
    cropper.removeEventListener('mousemove', this.move);
    cropper.removeEventListener('touchmove', this.move);
    cropper.removeEventListener('mousemove', this.resize);
    cropper.removeEventListener('touchmove', this.resize);
  }

  private cropImage(): void {
    const sizer: number = this.scalePreserveAspectRatio(
      this.image.width,
      this.image.height,
      this.image.naturalWidth,
      this.image.naturalHeight,
    );
    const originalRadius: number = Math.floor(this.cropperSettings.frame.radius * sizer);
    const originalDiameter: number = originalRadius * 2;
    const originalX: number = Math.floor((this.cropperSettings.frame.xPos - this.cropperSettings.frame.radius) * sizer);
    const originalY: number = Math.floor((this.cropperSettings.frame.yPos - this.cropperSettings.frame.radius) * sizer);
    const croppedCanvas: HTMLCanvasElement = document.createElement('canvas');
    const croppedCanvasCtx: CanvasRenderingContext2D = croppedCanvas.getContext('2d');
    const originalImageCanvas: HTMLCanvasElement = document.createElement('canvas');
    const originalImageCanvasCtx: CanvasRenderingContext2D = originalImageCanvas.getContext('2d');

    originalImageCanvas.height = this.image.naturalHeight;
    originalImageCanvas.width = this.image.naturalWidth;
    originalImageCanvasCtx.drawImage(this.image, 0, 0);

    originalImageCanvasCtx.save();
    originalImageCanvasCtx.globalCompositeOperation = 'destination-in';
    originalImageCanvasCtx.fillStyle = '#000';
    originalImageCanvasCtx.beginPath();
    originalImageCanvasCtx.arc(originalX + originalRadius, originalY + originalRadius, originalRadius, 0, 2 * Math.PI, false);
    originalImageCanvasCtx.fill();
    originalImageCanvasCtx.restore();

    croppedCanvas.width = originalDiameter;
    croppedCanvas.height = originalDiameter;

    croppedCanvasCtx.fillStyle = 'rgba(255,255,255,0)';
    croppedCanvasCtx.beginPath();
    croppedCanvasCtx.rect(0, 0, croppedCanvas.width, croppedCanvas.height);
    croppedCanvasCtx.closePath();
    croppedCanvasCtx.fill();
    croppedCanvasCtx.drawImage(originalImageCanvas, 0 - originalX, 0 - originalY);

    fetch(croppedCanvas.toDataURL('image/png', 1.0))
      .then((r: Response) => r.blob())
      .then((blobFile: Blob) => {
        const file: File = new File([blobFile], 'avatar', { type: 'image/png' });
        const dataTransfer: DataTransfer = new DataTransfer();

        dataTransfer.items.add(file);
        this.croppedImage.emit(dataTransfer.files);
      });
  }

  private alignFrame(): void {
    const frame: HTMLElement = this.cropperFrame.nativeElement;
    const cropper: HTMLElement = this.cropperContainer.nativeElement;

    frame.style.width = frame.style.height = `${this.cropperSettings.frame.radius * 2}px`;

    this.ngZoneService.runOutsideZone(() => {
      frame.addEventListener('mousedown', this.onMouseDown);
      frame.addEventListener('touchstart', this.onMouseDown);
      cropper.addEventListener('mouseup', this.onMouseUp);
      cropper.addEventListener('touchend', this.onMouseUp);
      cropper.addEventListener('mouseleave', this.onMouseUp);
    });
  }

  private onMouseUp = (event: MouseEvent | TouchEvent): void => {
    event.preventDefault();
    this.cropperContainer.nativeElement.removeEventListener('mousemove', this.move);
    this.cropperContainer.nativeElement.removeEventListener('touchmove', this.move);
    this.cropperContainer.nativeElement.removeEventListener('mousemove', this.resize);
    this.cropperContainer.nativeElement.removeEventListener('touchmove', this.resize);
  };

  private onMouseDown = (event: MouseEvent | TouchEvent): void => {
    event.preventDefault();

    const isCorner: boolean = (event.target as HTMLElement).classList.contains('corner');

    if (!isCorner) {
      this.cropperContainer.nativeElement.addEventListener('mousemove', this.move);
      this.cropperContainer.nativeElement.addEventListener('touchmove', this.move);
    } else {
      this.cropperContainer.nativeElement.addEventListener('mousemove', this.resize);
      this.cropperContainer.nativeElement.addEventListener('touchmove', this.resize);
    }
  };

  private resize = (event: MouseEvent | TouchEvent): void => {
    const isCursorInsideCroppingFrame: boolean = this.isCursorInsideSquare(
      this.getClientX(event),
      this.getClientY(event),
      this.cropperSettings.frame.radius * 2,
      this.cropperSettings.frame.xPos,
      this.cropperSettings.frame.yPos,
    );
    const isCursorOutsideCroppingFrame: boolean = this.isCursorOutsideSquare(
      this.getClientX(event),
      this.getClientY(event),
      this.cropperSettings.frame.radius * 2,
      this.cropperSettings.frame.xPos,
      this.cropperSettings.frame.yPos,
    );

    if (isCursorInsideCroppingFrame && this.cropperSettings.frame.radius > this.cropperSettings.minRadius) {
      this.cropperSettings.frame.radius -= 1;
      this.redrawFrame(this.cropperSettings.frame.xPos, this.cropperSettings.frame.yPos);
    }

    if (isCursorOutsideCroppingFrame && this.cropperSettings.frame.radius <= this.cropperSettings.maxRadius) {
      this.cropperSettings.frame.radius += 1;
      this.redrawFrame(this.cropperSettings.frame.xPos, this.cropperSettings.frame.yPos);
    }
  };

  private move = (event: MouseEvent | TouchEvent): void => {
    this.redrawFrame(this.getClientX(event), this.getClientY(event));
  };

  private redrawFrame(x: number, y: number): void {
    const frame: HTMLElement = this.cropperFrame.nativeElement;

    this.cropperSettings.frame.xPos = this.getXCoordinate(x);
    this.cropperSettings.frame.yPos = this.getYCoordinate(y);
    frame.style.left = `${this.cropperSettings.frame.xPos}px`;
    frame.style.top = `${this.cropperSettings.frame.yPos}px`;
    frame.style.height = frame.style.width = `${this.cropperSettings.frame.radius * 2}px`;
  }

  private alignImage(): void {
    const cropper: HTMLElement = this.cropperContainer.nativeElement;
    const sizer: number = this.scalePreserveAspectRatio(this.image.width, this.image.height, cropper.offsetWidth, cropper.offsetHeight);

    this.cropperSettings.height = Math.floor(this.image.height * sizer);
    this.cropperSettings.width = Math.floor(this.image.width * sizer);
    this.image.style.height = cropper.style.height = `${this.cropperSettings.height}px`;
    this.image.style.width = cropper.style.width = `${this.cropperSettings.width}px`;
    this.cropperSettings.minRadius = Math.floor(
      (this.diameter *
        this.scalePreserveAspectRatio(
          this.image.naturalWidth,
          this.image.naturalHeight,
          this.cropperSettings.width,
          this.cropperSettings.height,
        )) /
        2,
    );
    this.cropperSettings.maxRadius = Math.floor(Math.min(this.cropperSettings.height, this.cropperSettings.width) / 2);
    this.cropperSettings.frame.xPos = this.getXCoordinate(Math.floor(this.cropperSettings.width / 2));
    this.cropperSettings.frame.yPos = this.getYCoordinate(Math.floor(this.cropperSettings.height / 2));
  }

  private scalePreserveAspectRatio(imgWidth: number, imgHeight: number, maxWidth: number, maxHeight: number): number {
    return Math.min(maxWidth / imgWidth, maxHeight / imgHeight);
  }

  private loadImage(uploadedImage: FileList): void {
    const uploadedImageFile: File = uploadedImage?.item(0);

    if (!uploadedImageFile.type.match('image.*')) {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');

      return;
    }

    this.reader.readAsDataURL(uploadedImageFile);
  }

  private getXCoordinate(xPos: number): number {
    return xPos + this.cropperSettings.frame.radius > this.cropperSettings.width
      ? this.cropperSettings.width - this.cropperSettings.frame.radius - 2
      : xPos - this.cropperSettings.frame.radius < 0
      ? this.cropperSettings.frame.radius + 2
      : xPos;
  }

  private getYCoordinate(yPos: number): number {
    return yPos + this.cropperSettings.frame.radius > this.cropperSettings.height
      ? this.cropperSettings.height - this.cropperSettings.frame.radius - 2
      : yPos - this.cropperSettings.frame.radius < 0
      ? this.cropperSettings.frame.radius + 2
      : yPos;
  }

  private getClientX = (event: MouseEvent | TouchEvent): number => {
    const boundingClientRect: DOMRect = this.cropperContainer.nativeElement.getBoundingClientRect();
    const clientX: number = (event as TouchEvent).touches?.[0].clientX || (event as MouseEvent).clientX || 0;

    return clientX - boundingClientRect.left;
  };

  private getClientY = (event: MouseEvent | TouchEvent): number => {
    const boundingClientRect: DOMRect = this.cropperContainer.nativeElement.getBoundingClientRect();
    const clientY: number = (event as TouchEvent).touches?.[0].clientY || (event as MouseEvent).clientY || 0;

    return clientY - boundingClientRect.top;
  };

  private isCursorInsideSquare(posX: number, posY: number, side: number, x: number, y: number): boolean {
    const startX: number = x - this.cropperSettings.frame.radius;
    const startY: number = y - this.cropperSettings.frame.radius;

    return posX >= startX && posX <= startX + side - 5 && posY >= startY && posY <= startY + side - 5;
  }

  private isCursorOutsideSquare(posX: number, posY: number, side: number, x: number, y: number): boolean {
    const startX: number = x - this.cropperSettings.frame.radius;
    const startY: number = y - this.cropperSettings.frame.radius;

    return posX < startX || (posX > startX + side + 5 && posY < startY) || posY > startY + side + 5;
  }
}
