<ng-template [ngIf]="userRole" [ngIfElse]="anonymousMenu">
  <div class="navbar">
    <div class="logo-div">
      <a
        (click)="selectMain()"
        class="home-link w-inline-block"
        [class.mo-logo]="isMOStend"
        [class.disabled_link]="isVGuser || userRole === 'teacher'"
      ></a>
    </div>

    <div class="menu" style="overflow-y: auto">
      <a
        *ngIf="showMosruAbout && !isVGuser"
        (click)="selectPromoAction()"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/menu/redHat_grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/menu/redHat.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div>Узнайте <br />свои таланты</div>

        <div class="menu-separator"></div>
      </a>
      <a
        *ngIf="isVGuser"
        routerLink="/vorobievi-gori/mosrupromo"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Test-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Test-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div>
          Тест<br />
          на таланты
        </div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="userRole === 'teacher' && showLessonsMenuItem"
        (click)="selectMainTeacher()"
        [class.w--current]="selectedMenuItem === menuItems.Lessons"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/lessons.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/lessons-active.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Занятия</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="userRole !== 'parent' || (userRole === 'parent' && !mosruUser && !isVGuser)"
        (click)="selectMain()"
        [class.w--current]="(currentUrl === '/' + root && userRole !== 'teacher') || selectedMenuItem === menuItems.Pupils"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Main-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Main-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">
          <ng-container *ngIf="userRole === 'pupil'">Мои цели</ng-container>
          <ng-container *ngIf="userRole === 'parent'">Главная</ng-container>
          <ng-container *ngIf="userRole === 'teacher'">{{ dictionary.Pupils | titlecase }}</ng-container>
        </div>
        <div class="menu-separator"></div>
      </a>
      <a
        *ngIf="userRole !== 'parent'"
        (click)="selectResults()"
        [class.w--current]="currentUrl === '/results' || selectedMenuItem === menuItems.Results"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Test-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Test-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">
          {{ userRole === 'teacher' ? 'Результаты' : 'Тест' }}
        </div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="isB2cPupil"
        (click)="routingMotivationTest()"
        [class.w--current]="currentUrl.includes('/motivation-test') || currentUrl.includes('/motivation-test-results')"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/menu/grey/Motiv_Test.svg" alt="Motivation test" class="menu-img" />
          <img src="./profilum-assets/images/menu/violet/Motiv_Test.svg" alt="Motivation test" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Мотивация</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="isB2cPupil && isTestReady"
        (click)="routingValuesTest()"
        [class.w--current]="currentUrl.includes('/values-test') || currentUrl.includes('/values-test-results')"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/menu/grey/Motiv_Test.svg" alt="Values test" class="menu-img" />
          <img src="./profilum-assets/images/menu/violet/Motiv_Test.svg" alt="Values test" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Тест на ценности</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="!isVGuser"
        (click)="selectProfessions()"
        [class.w--current]="currentUrl === '/professions'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Professions-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Professions-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">{{ 'HEADER.PROF' | translate }}</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="showCourses"
        (click)="selectCourses()"
        [class.w--current]="currentUrl === '/courses'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Course_Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Course_VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Курсы</div>
        <div class="menu-separator"></div>
      </a>

      <a *ngIf="showEvents" (click)="selectEvents()" [class.w--current]="currentUrl === '/events'" class="menu-link-block w-inline-block">
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Events-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Events-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Мероприятия</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="showInternships && isInternshipsReady"
        (click)="selectInternships()"
        [class.w--current]="currentUrl === '/internships'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Course_Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Course_VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Стажировки</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="userRole === 'pupil'"
        (click)="selectPortfolio()"
        [class.w--current]="currentUrl === '/portfolio'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Portfolio-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Portfolio-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Портфолио</div>
        <div class="menu-separator"></div>
      </a>

      <a *ngIf="false" (click)="selectNews()" [class.w--current]="currentUrl === '/news'" class="menu-link-block w-inline-block">
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/News-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/News-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Новости</div>
        <div class="menu-separator"></div>
      </a>

      <a
        *ngIf="userRole === 'pupil'"
        (click)="selectHistory()"
        [class.w--current]="currentUrl === '/pupil-tests-history'"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/News-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/News-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">История тестов</div>
        <div class="menu-separator"></div>
      </a>
    </div>
    <div class="mobile-menu-button">
      <div class="mobile-menu-icon-div">
        <div class="menu-line"></div>
        <div class="menu-line"></div>
        <div class="menu-line"></div>
      </div>
    </div>

    <div style="display: none" class="mobile-menu-button fixed">
      <div class="mobile-menu-icon-div">
        <div class="menu-line"></div>
        <div class="menu-line"></div>
        <div class="menu-line"></div>
      </div>
    </div>
  </div>

  <div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
    <div
      [ngStyle]="{
        transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
      }"
      class="mobile-menu-container"
      style="transform-style: preserve-3d"
    >
      <div class="mobile-menu-header">
        <div class="mobile-menu-button" (click)="hideMobileMenu()">
          <div class="mobile-menu-icon-div">
            <div
              class="menu-line"
              style="
                transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            ></div>
            <div
              class="menu-line"
              style="
                transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            ></div>
          </div>
        </div>
        <div class="mobile-menu-logo-div">
          <a
            (click)="selectMain()"
            class="mobile-menu-logo-link w-inline-block w--current"
            [class.mo-logo]="isMOStend"
            [class.disabled_link]="isVGuser || userRole === 'teacher'"
          >
            <img
              src="{{
                isMOStend ? './profilum-assets/images/logos/profilum-logo.svg' : './profilum-assets/images/dashboard/profilum-new-logo.svg'
              }}"
              class="profilum-mobile-logo"
              alt="Profilum logo"
            />
          </a>
        </div>
      </div>
      <div class="mobile-user-div">
        <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
          <div class="user-dropdown-toggle">
            <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
            <div class="user-name mob">
              {{ firstName && userRole !== 'teacher' ? firstName : 'Мой профиль' }}
            </div>
          </div>
        </a>
      </div>
      <div>
        <ng-container *ngIf="showHeaderButtons">
          <div class="header_buttons">
            <a
              class="header_button _button"
              [class.active]="url.includes(item.url)"
              *ngFor="let item of headerButtonsArray"
              (click)="hideMobileMenu()"
              routerLink="{{ item.url }}"
              >{{ item.name }}</a
            >
          </div>
        </ng-container>
        <a
          *ngIf="showMosruAbout && !isVGuser"
          (click)="selectPromoAction()"
          [class.w--current]="currentUrl === '/mosruabout'"
          class="menu-link mosruabout w-inline-block"
        >
          <div>Узнайте <br />свои таланты</div>
        </a>
        <a
          *ngIf="isVGuser"
          routerLink="/vorobievi-gori/mosrupromo"
          [class.w--current]="currentUrl === '/mosruabout'"
          class="menu-link mosruabout w-inline-block"
        >
          <div>
            Тест<br />
            на таланты
          </div>
        </a>
        <a
          *ngIf="userRole === 'teacher' && showLessonsMenuItem"
          (click)="selectMainTeacher()"
          [class.w--current]="selectedMenuItem === menuItems.Lessons"
          class="menu-link w-inline-block"
        >
          <div>Занятия</div>
        </a>
        <a
          *ngIf="!isVGuser && !mosruUser"
          (click)="selectMain()"
          [class.w--current]="(currentUrl === '/' + root && !isAnalytics) || selectedMenuItem === menuItems.Pupils"
          class="menu-link w-inline-block"
        >
          <ng-template [ngIf]="userRole === 'teacher'" [ngIfElse]="notTeacher">Ученики</ng-template>
          <ng-template #notTeacher>
            <div>{{ 'SHARED.GENERAL' | translate }}</div>
          </ng-template>
        </a>

        <a
          *ngIf="userRole !== 'parent'"
          (click)="selectResults()"
          [class.w--current]="currentUrl === '/results' || selectedMenuItem === menuItems.Results"
          class="menu-link test w-inline-block"
        >
          <div>
            {{ userRole === 'teacher' ? 'Результаты' : 'Тест' }}
          </div>
        </a>
        <a
          *ngIf="isB2cPupil"
          (click)="routingMotivationTest()"
          [class.w--current]="currentUrl.includes('/motivation-test') || currentUrl.includes('/motivation-test-results')"
          class="menu-link test w-inline-block"
        >
          <div>Мотивация</div>
        </a>
        <a
          *ngIf="isB2cPupil && isTestReady"
          (click)="routingValuesTest()"
          [class.w--current]="currentUrl.includes('/values-test') || currentUrl.includes('/values-test-results')"
          class="menu-link test w-inline-block"
        >
          <div>Тест на ценности</div>
        </a>
        <a
          *ngIf="!isVGuser"
          (click)="selectProfessions()"
          [class.w--current]="currentUrl === '/professions' || selectedMenuItem === menuItems.Professions"
          class="menu-link professions w-inline-block"
        >
          <div>{{ 'HEADER.PROF' | translate }}</div>
        </a>
        <a
          *ngIf="showCourses"
          (click)="selectCourses()"
          [class.w--current]="currentUrl === '/courses'"
          class="menu-link courses w-inline-block"
        >
          <div>Курсы</div>
        </a>
        <a
          *ngIf="showEvents"
          (click)="selectEvents()"
          [class.w--current]="currentUrl === '/events'"
          class="menu-link events w-inline-block"
        >
          <div>Мероприятия</div>
        </a>
        <a
          *ngIf="userRole === 'pupil'"
          (click)="selectPortfolio()"
          [class.w--current]="currentUrl === '/portfolio'"
          class="menu-link portfolio w-inline-block"
        >
          <div>Портфолио</div>
        </a>
        <a *ngIf="false" (click)="selectNews()" [class.w--current]="currentUrl === '/news'" class="menu-link news w-inline-block">
          <div>Новости</div>
        </a>
        <a
          *ngIf="userRole === 'pupil'"
          (click)="selectHistory()"
          [class.w--current]="currentUrl === '/pupil-tests-history'"
          class="menu-link news w-inline-block"
        >
          <div>История тестов</div>
        </a>
        <a
          *ngIf="showInternships && isInternshipsReady"
          (click)="selectInternships()"
          [class.w--current]="currentUrl === '/internships'"
          class="menu-link internships w-inline-block"
        >
          <div>Стажировки</div>
        </a>
      </div>
    </div>
    <div class="mobile-menu-bg" (click)="hideMobileMenu()"></div>
  </div>
</ng-template>

<ng-template #anonymousMenu>
  <div class="navbar">
    <div class="logo-div">
      <a (click)="selectMainAnonymous()" class="home-link w-inline-block" [class.mo-logo]="isMOStend" [class.disabled_link]="isVGuser"></a>
    </div>

    <div class="menu" style="overflow-y: auto">
      <a
        *ngIf="!isVGuser"
        (click)="selectProfessions()"
        [class.w--current]="currentUrl === '/professions' || selectedMenuItem === menuItems.Professions"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-separator"></div>
        <div class="menu-icon-div">
          <img src="./profilum-assets/images/icons/Professions-Grey.svg" alt="" class="menu-img" />
          <img src="./profilum-assets/images/icons/Professions-VIolet.svg" alt="" class="menu-img active" />
        </div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">{{ 'HEADER.PROF' | translate }}</div>
        <div class="menu-separator"></div>
      </a>
    </div>
    <div class="mobile-menu-button">
      <div class="mobile-menu-icon-div">
        <div class="menu-line"></div>
        <div class="menu-line"></div>
        <div class="menu-line"></div>
      </div>
    </div>

    <div style="display: none" class="mobile-menu-button fixed">
      <div class="mobile-menu-icon-div">
        <div class="menu-line"></div>
        <div class="menu-line"></div>
        <div class="menu-line"></div>
      </div>
    </div>
  </div>

  <div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
    <div
      [ngStyle]="{
        transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
      }"
      class="mobile-menu-container"
      style="transform-style: preserve-3d"
    >
      <div class="mobile-menu-header">
        <div class="mobile-menu-button" (click)="hideMobileMenu()">
          <div class="mobile-menu-icon-div">
            <div
              class="menu-line"
              style="
                transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            ></div>
            <div
              class="menu-line"
              style="
                transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
              "
            ></div>
          </div>
        </div>
        <div class="mobile-menu-logo-div">
          <a
            (click)="selectMainAnonymous()"
            class="mobile-menu-logo-link w-inline-block w--current"
            [class.mo-logo]="isMOStend"
            [class.disabled_link]="isVGuser"
          >
            <img
              src="{{
                isMOStend ? './profilum-assets/images/logos/profilum-logo.svg' : './profilum-assets/images/dashboard/profilum-new-logo.svg'
              }}"
              class="profilum-mobile-logo"
              alt="Profilum logo"
            />
          </a>
        </div>
      </div>
      <div>
        <ng-container *ngIf="showHeaderButtons">
          <div class="header_buttons">
            <a
              class="header_button _button"
              [class.active]="url.includes(item.url)"
              *ngFor="let item of headerButtonsArray"
              (click)="hideMobileMenu()"
              routerLink="{{ item.url }}"
              >{{ item.name }}</a
            >
          </div>
        </ng-container>
        <a
          *ngIf="!isVGuser"
          (click)="selectProfessions()"
          [class.w--current]="currentUrl === '/professions'"
          class="menu-link professions w-inline-block"
        >
          <div>{{ 'HEADER.PROF' | translate }}</div>
        </a>
      </div>
    </div>
    <div class="mobile-menu-bg" (click)="hideMobileMenu()"></div>
  </div>
</ng-template>
