import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/shared/modules/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { SpecialtiesComponent } from './specialties.component';
import { AllSpecialtiesComponent } from './all-specialties/all-specialties.component';
import { EditSpecialtyComponent } from './edit-specialty/edit-specialty.component';
import { AddSpecialtyComponent } from './add-specialty/add-specialty.component';
import { SpecialtyCardComponent } from './all-specialties/specialty-card/specialty-card.component';
import { SpecialtyDetailsComponent } from './all-specialties/specialty-details/specialty-details.component';

@NgModule({
  declarations: [
    SpecialtiesComponent,
    AllSpecialtiesComponent,
    EditSpecialtyComponent,
    AddSpecialtyComponent,
    SpecialtyCardComponent,
    SpecialtyDetailsComponent,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, MaterialModule, SharedModule],
})
export class SpecialtiesModule {}
