import { Component, Input } from '@angular/core';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-filters-courses',
  templateUrl: './filters-courses.component.html',
  styleUrls: ['./filters-courses.component.scss'],
})
export class FiltersCoursesComponent {
  @Input() territoryFilters: boolean = true;

  public userRole: string;
  constructor(private webStorageService: WebStorageService) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }
}
