<div class="results-check-row">
  <div class="results-check-text">{{ 'SHARED.RESULT.S' | translate }}:</div>
  <div class="results-check-column">
    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickPupil()"
          [class.checked]="checkPupil && isCompleteTestPupil && !checkParent"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkPupil && isCompleteTestPupil && !checkParent">
            <ng-container>{{ userRole === 'pupil' ? ('SHARED.MY' | translate) : ('SHARED.CHILD' | translate) }}</ng-container>
          </div>
          <div class="results-checkbox-text" [class.not-done]="!isCompleteTestPupil">
            <span>{{
              isCompleteTestPupil ? ('SHARED.TEST_PASSED' | translate | lowercase) : ('SHARED.TEST_NOT_PASSED' | translate | lowercase)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickParent()"
          [class.checked]="checkParent && isCompleteTestParent && !checkPupil"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkParent && isCompleteTestParent && !checkPupil">
            <span>Родителя</span>
          </div>
          <div class="results-checkbox-text" [class.not-done]="!isCompleteTestParent">
            <span>{{
              isCompleteTestParent
                ? ('SHARED.TEST_PASSED' | translate | lowercase)
                : ('SHARED.TEST_NOT_PASSED_PARENT' | translate | lowercase)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickAll()"
          [class.checked]="checkParent && isCompleteTestParent && checkPupil && isCompleteTestPupil"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkParent && isCompleteTestParent && checkPupil && isCompleteTestPupil">
            <span>{{ 'SHARED.JOINT' | translate }}</span>
          </div>
          <div class="results-checkbox-text" [class.not-done]="!isCompleteTestParent">
            <span>{{ getCompletedStatusDescription() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
