import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { of } from 'rxjs';
import {
  ApiAuthService,
  AppSettingsService,
  EUserTags,
  IBvbRegisterData,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-bvblogin',
  templateUrl: './bvblogin.component.html',
  styleUrls: ['./bvblogin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BvbloginComponent extends UnsubscribeComponent implements OnInit {
  public invalidURLFormat: boolean = false;
  private _authCode: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private apiAuthService: ApiAuthService,
    private utilsService: UtilsService,
    private router: Router,
    private webStorageService: WebStorageService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  private set setAuthCode(authCode: string) {
    this._authCode = authCode;
  }

  public get authCode(): string {
    return this._authCode;
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((queryParams: Params) => {
          return <string>queryParams['code'];
        }),
        tap((authCode: string | undefined) => {
          this.invalidURLFormat = !authCode?.length;

          if (this.invalidURLFormat) {
            this.handleErrors();
          }
        }),
        filter(() => !this.invalidURLFormat),
        switchMap((authCode: string) => {
          this.setAuthCode = authCode;

          return this.apiAuthService.getBvbLoginData(this.authCode);
        }),
        catchError(() => of(null)),
        filter((response: IBvbRegisterData | null) => {
          if (response?.status !== 'Success') {
            this.handleErrors();
            return false;
          }

          return true;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(({ comment, userId, email }: IBvbRegisterData) => {
        const isVisuallyImpairedVersion: boolean = comment === 'Visually impaired';

        this.userStorageService.setUserId = userId;
        this.webStorageService.set(StorageKeys.Tag, EUserTags[EUserTags.Bvb].toString());
        this.webStorageService.set(StorageKeys.UserId, userId);
        this.webStorageService.set(StorageKeys.Email, email);
        this.webStorageService.set(StorageKeys.IsVisuallyImpairedVersion, isVisuallyImpairedVersion.toString());

        isVisuallyImpairedVersion ? this.router.navigate(['/bvb-test-visually-impaired']) : this.router.navigate(['/bvb-test']);
      });
  }

  public handleErrors(): void {
    window.location.href = AppSettingsService.settings.bvbLkErrorTimeoutUrl;
  }
}
