import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'prf-teacher-groups-banner',
  standalone: true,
  templateUrl: './teacher-groups-banner.component.html',
  styleUrls: ['./teacher-groups-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgOptimizedImage
  ]
})
export class TeacherGroupsBannerComponent {
}
