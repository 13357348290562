export enum UserRoles {
  admin = 'admin',
  adminDO = 'adminDO',
  schooladmin = 'schooladmin',
  director = 'director',
  teacher = 'teacher',
  employer = 'employer',
  tutor = 'tutor',
  pupil = 'pupil',
  parent = 'parent',
}
