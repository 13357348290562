import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';
import { RootScopeService } from '../../services/root-scope.service';

@Component({
  selector: 'prf-restriction-banner',
  templateUrl: './restriction-banner.component.html',
  styleUrls: ['./restriction-banner.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage],
})
export class RestrictionBannerComponent {
  public dictionary: DictionaryType;
  constructor(private rootScopeService: RootScopeService) {
    this.dictionary = rootScopeService.getDictionary();
  }
}
