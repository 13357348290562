import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginMosOblComponent } from './login-mosobl/login-mosobl.component';
import { AuthRoutingComponent } from './auth-routing/auth-routing.component';

const routes: Routes = [
  { path: 'mo-login', component: LoginMosOblComponent, pathMatch: 'full' },
  { path: 'auth-routing', component: AuthRoutingComponent, pathMatch: 'full' },
  {
    path: 'mo-registration',
    redirectTo: 'mo-login',
    pathMatch: 'full',
  },
  {
    path: 'mo-registration-parent',
    redirectTo: 'mo-login',
    pathMatch: 'full',
  },
  {
    path: 'mo-registration-pupil',
    redirectTo: 'mo-login',
    pathMatch: 'full',
  },
  {
    path: 'mo-registration-teacher',
    redirectTo: 'mo-login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingMosoblRoutingModule {}
