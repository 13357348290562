import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { NgZoneService } from '@profilum-logic-services/ng-zone-service/ng-zone-service.service';

@Directive({
  selector: '[prfPhoneMask]',
})
export class PhoneMaskDirective extends UnsubscribeComponent implements AfterViewInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() htmlInputElement: HTMLInputElement;
  private readonly pattern: string = '+7 ___ ___ __ __';
  private readonly placeholderChar: string = '_';
  private readonly separator: string = ' ';

  constructor(private ngZoneService: NgZoneService) {
    super();
  }

  public ngAfterViewInit(): void {
    if (this.control && this.htmlInputElement) {
      this.ngZoneService.runOutsideZone(() => {
        this.htmlInputElement.addEventListener('input', this.mask);
        this.htmlInputElement.addEventListener('focus', this.onFocusInputElement);
        this.htmlInputElement.addEventListener('blur', this.onBlurInputElement);
        this.htmlInputElement.addEventListener('paste', this.onPasteInputElement);
        this.htmlInputElement.addEventListener('click', this.onClickInputElement);
      });
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.htmlInputElement) {
      this.htmlInputElement.removeEventListener('input', this.mask);
      this.htmlInputElement.removeEventListener('focus', this.onFocusInputElement);
      this.htmlInputElement.removeEventListener('blur', this.onBlurInputElement);
      this.htmlInputElement.removeEventListener('paste', this.onPasteInputElement);
      this.htmlInputElement.removeEventListener('click', this.onClickInputElement);
    }
  }

  private mask = (): void => {
    this.setValue(this.control.value);
  };

  private onFocusInputElement = (): void => {
    this.timer = window.setTimeout(() => {
      this.setValue(this.control.value);
    }, 100);
  };

  private onBlurInputElement = (): void => {
    const numbers = this.getNumbers(this.control.value);

    if (!numbers.length) {
      this.control.reset();
      this.control.setErrors({ required: true });
    }
  };

  private onClickInputElement = (): void => {
    if (!this.getNumbers(this.control.value).length) {
      this.setCaretPosition(3);
    }
  };

  private setCaretPosition(pos: number): void {
    const position = pos >= 3 ? pos : 3;

    this.htmlInputElement.setSelectionRange(position, position);
  }

  private onPasteInputElement = (event: ClipboardEvent): void => {
    const pastedValue = (event.clipboardData || (<any>window).clipboardData).getData('text')?.replace(/\s+/g, '');
    this.setValue(this.getNumbers(pastedValue).join());
  };

  private setValue(value: string): void {
    if (!value) {
      this.control.setValue(this.pattern);
      this.setCaretPosition(0);
      return;
    }

    const numbers = this.getNumbers(value);
    const currentCaretPosition = this.getCaretPosition(value);
    const regexp = new RegExp(`${this.placeholderChar}`, 'g');
    let newCaretPosition: number = 0;
    let index = 0;

    const newValue = this.pattern.replace(regexp, (char, i) => {
      const number = numbers[index];

      if (number) {
        newCaretPosition = i + 1;
      }

      index += 1;

      return number ?? char;
    });

    this.control.setValue(newValue);
    this.setCaretPosition(
      currentCaretPosition !== -1 && currentCaretPosition <= newCaretPosition ? currentCaretPosition : newCaretPosition,
    );

    const phone = this.control.value?.replace(/\D/g, '');

    // Helper.setPrioritizedValidatorsForControl(this.control, {
    //   lessThan11Numbers: () => phone.length < 11,
    //   pattern: () => !Helper.isValidPhoneNumber(phone),
    // });
  }

  private getNumbers(value: string): string[] {
    const chars = value?.split('') ?? [];

    if (chars[0] === '+' && chars[1] === '7') {
      chars[0] = '';
      chars[1] = '';
    }

    if (chars.length === 11 && (chars[0] === '8' || chars[0] === '7')) {
      chars[0] = '';
    }

    return chars.filter(char => Number.isInteger(Number.parseInt(char, 10)));
  }

  private getCaretPosition(value: string): number {
    const nextSelectionChar = value[this.htmlInputElement.selectionEnd];
    const isNumberNextSelectionChar = Number.isInteger(Number.parseInt(nextSelectionChar, 10));

    if (nextSelectionChar === this.separator && value[this.htmlInputElement.selectionEnd + 1] === this.placeholderChar) {
      return -1;
    }

    return nextSelectionChar === this.separator || isNumberNextSelectionChar ? this.htmlInputElement.selectionEnd : -1;
  }
}
