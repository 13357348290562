import { Component, ElementRef, ViewChild } from '@angular/core';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of } from 'rxjs';

import {
  ApiProfessionsService,
  ApiResultsService,
  B2gSaasService,
  IUserInfo,
  UserDataHandlerService,
} from '@profilum-library';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { RootScopeService } from '../../../../../shared/services/root-scope.service';
import { DictionaryType } from '../../../../../landing/base/landing-base.dictionary';

export const PAGE_SIZE: number = 3;

@Component({
  selector: 'prf-profession-recommendations',
  templateUrl: './profession-recommendations.component.html',
  styleUrls: ['./profession-recommendations.component.scss'],
})
export class ProfessionRecommendationsComponent extends UnsubscribeComponent {
  public userInfo: IUserInfo;
  public loaded: boolean = false;
  public professionMenu: string = 'testResults';
  public professionIdList: Array<string> = [];
  public resultProfessionIdList: Array<string> = [];
  public parentProfessionIdList: Array<string> = [];
  public teacherProfessionIdList: Array<string> = [];
  public professionListBySource: Array<any> = [];
  public pageCurrent: number = 0;
  public showMenuPopupResult: boolean = false;
  public showMenuPopupParent: boolean = false;
  public showMenuPopupTeacher: boolean = false;
  public dictionary: DictionaryType;
  public swiperConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    speed: 500,
  };

  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;

  constructor(
    private professionsService: ApiProfessionsService,
    private b2gSaasService: B2gSaasService,
    private rootScopeService: RootScopeService,
    private apiResultsService: ApiResultsService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.getRecommended();
  }

  public setProfessionMenu(value: string) {
    let isSetVal: boolean = false;
    if (value === this.professionMenu) {
      return;
    }

    if (value === 'testResults') {
      this.professionIdList = this.resultProfessionIdList;
      isSetVal = true;
    }

    if (value === 'fromParents' && this.parentProfessionIdList.length > 0) {
      this.professionIdList = this.parentProfessionIdList;
      isSetVal = true;
    }

    if (value === 'fromTeacher' && this.teacherProfessionIdList.length > 0) {
      this.professionIdList = this.teacherProfessionIdList;
      isSetVal = true;
    }

    if (isSetVal) {
      this.professionListBySource = [];
      this.professionMenu = value;
      this.pageCurrent = 0;
      this.swiper.update();
      this.getProfessions().pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  public nextPage() {
    if (this.isEnabledNext()) {
      this.pageCurrent++;
      this.swiper.slideNext();
      this.getProfessions().pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  public prevPage() {
    if (this.isEnabledPrev()) {
      this.pageCurrent--;
      this.swiper.slidePrev();
    }
  }

  public isEnabledNext() {
    return this.pageCurrent + 1 < this.professionIdList.length / PAGE_SIZE;
  }

  public isEnabledPrev() {
    return this.pageCurrent > 0;
  }

  private getProfessions(): Observable<any> {
    const resultByPage = this.professionIdList.slice(
      this.professionListBySource.length,
      this.professionListBySource.length + PAGE_SIZE * 2,
    );

    if (resultByPage.length === 0) {
      return;
    }

    return this.professionsService.getElasticProfessionsByFilters({ ids: resultByPage, isVisible: true }).pipe(
      tap(_profList => {
        _profList
          .filter(_pl1 => !this.professionListBySource.some(_pl2 => _pl2.id === _pl1.id))
          .forEach(_prof => {
            this.professionListBySource.push(_prof);
          });
      }),
    );
  }

  private getRecommended() {
    this.b2gSaasService
      .getLastSession(this.userInfo.userId)
      .pipe(
        switchMap(lastSession => {
          const lastSessionId = lastSession.status == 'Success' ? lastSession.sessionId : '';

          let resultsObservable$: Observable<any>;
          if (lastSessionId) {
            resultsObservable$ = this.apiResultsService.getResultsPage([lastSessionId]).pipe(
              switchMap(_resp => {
                let currentObs$: Observable<any>;
                if (_resp && _resp.results && _resp.results.length > 0) {
                  this.resultProfessionIdList = _resp.results
                    .filter(item => item.objectType === 'Profession')
                    .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1))
                    .map(item => item.id);

                  this.professionIdList = this.resultProfessionIdList;
                  currentObs$ = this.getProfessions();
                } else {
                  currentObs$ = of(null);
                }
                return currentObs$;
              }),
            );
          } else {
            resultsObservable$ = of(null);
          }

          return resultsObservable$.pipe(
            mergeMap(() => {
              this.loaded = true;
              return observableForkJoin([
                this.b2gSaasService.getUserRecommendations({ recommendationType: 'Profession', addresserRole: 'Parent' }),
                this.b2gSaasService.getUserRecommendations({ recommendationType: 'Profession', addresserRole: 'Teacher' }),
              ]).pipe(
                tap(([parentRecommendations, teacherRecommendations]) => {
                  this.parentProfessionIdList = parentRecommendations;
                  this.teacherProfessionIdList = teacherRecommendations;
                }),
              );
            }),
          );
        }),
      )
      .subscribe();
  }
}
