import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherWithNoClassesBannerComponent } from './teacher-with-no-classes-banner.component';

@NgModule({
  declarations: [TeacherWithNoClassesBannerComponent],
  imports: [CommonModule],
  exports: [TeacherWithNoClassesBannerComponent],
})
export class TeacherWithNoClassesBannerModule {}
