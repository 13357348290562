<!-- todo разделить на контейнеры -->
<ng-container *ngIf="!access">
  <div class="column-left-header" [class.small]="showSmall">
    <div class="column-header-text" [class.hide]="status === 'childrenDiagnosed' && showSmall">
      <div class="column-header-title">
        {{ content.title }}
      </div>
      <div class="column-header-desc">
        {{ content.desc }}
      </div>
    </div>
    <div class="column-header-button">
      <button
        class="button green-btn"
        (click)="showSharePopup = !showSharePopup"
        [routerLink]="status === 'childrenDiagnosed' && !showSmall ? ['/results'] : []"
      >
        {{ content.button }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="access">
  <div class="column-left-header column-left-header_access" [class.small]="showSmall">
    <div class="column-header-text" [class.hide]="status === 'childrenDiagnosed'">
      <div class="column-header-title">Скачайте доступы учеников</div>
      <div class="column-header-desc">
        По ссылке — документ, в котором инструкция и код для каждого ученика. Скачайте, распечатайте и раздайте детям.
      </div>
    </div>
    <div class="column-header-button">
      <button class="button purple-btn" (click)="getDataUnregistered()" [class.inactiv]="loading">
        <ng-container *ngIf="!loading; else loader">Скачать доступы</ng-container>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showSharePopup">
  <div class="prf-popup-overlay">
    <div class="popup-wrapper">
      <ng-container *ngIf="!linkCopied">
        <div class="popup-share">
          <div class="popup-share-header">
            <h3 class="popup-share-title">Приглашение для родителей</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">Здравствуйте, уважаемые родители!</p>
            <p class="popup-share-text">Для нашего класса бесплатно проводят тест на профориентацию.</p>
            <p class="popup-share-text">
              В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете
              посмотреть результаты.
            </p>
            <p class="popup-share-text">
              ❗️ Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.
            </p>
            <p class="popup-share-text">{{ shortRegLink }}</p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" (click)="copyPupilRegLink()">Скопировать приглашение</button>
            <button class="button cancel-btn" (click)="closePopup()">Отменить</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="successSend">
        <div class="popup-share done-popup">
          <div class="popup-share-header">
            <span class="popup-share-done">Готово</span>
            <h3 class="popup-share-title">Приглашение скопировано</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-share-text">
              Отправьте его в «родительский» чат и<br />
              дождитесь, когда ученики появятся в списке
            </p>
          </div>
          <div class="popup-share-buttons">
            <button class="button green-btn" (click)="closePopup('done')">Понятно</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Loader -->
<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>
